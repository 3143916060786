<form *ngIf="!errorMessage" [formGroup]="orderSearchForm" (ngSubmit)="searchOrders(true)" fxLayout="column">
  <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
    <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="100" (click)="accountFieldClicked()">
      <mat-label>Accounts</mat-label>
      <mat-error *ngIf="orderSearchForm.get('accounts').invalid">{{formValidator.getErrorMessages(orderSearchForm.get('accounts'))}}</mat-error>
      <input upperCase matInput formControlName="accounts" trim="blur">
    </mat-form-field>

    <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="100" (click)="brokerCodeFieldClicked()">
      <mat-label>Broker Code</mat-label>
      <input matInput formControlName="brokerCodes" trim="blur">
      <mat-error *ngIf="orderSearchForm.get('brokerCodes').invalid">{{formValidator.getErrorMessages(orderSearchForm.get('brokerCodes'))}}</mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
    <mat-form-field class="example-full-width" fxFlex.gt-xs="25" fxFlex.xs="100">
      <mat-label>Commodity</mat-label>
      <input type="text"
             placeholder="Commodity"
             aria-label="Commodity"
             matInput
             formControlName="symbol"
             [matAutocomplete]="auto"
             trim="blur">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCommodity">
        <mat-option *ngFor="let commodity of filteredCommodities | async" [value]="commodity">
          {{displayCommodity(commodity)}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div *ngIf="searchFields.includes('date')" fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center">
    <mat-form-field fxFlex="45">
      <input matInput [matDatepicker]="startDatePicker" placeholder="Updated Date (Start)" formControlName="startDate" [max]="orderSearchForm.get('endDate').value">
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
      <mat-error *ngIf="orderSearchForm.get('startDate').invalid">{{ getErrorMessage(orderSearchForm.get('startDate')) }}</mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="45">
      <input matInput [matDatepicker]="endDatePicker" placeholder="Updated Date (End)" formControlName="endDate" [min]="orderSearchForm.get('startDate').value">
      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
      <mat-error *ngIf="orderSearchForm.get('endDate').invalid">{{ getErrorMessage(orderSearchForm.get('endDate')) }}</mat-error>
    </mat-form-field>
  </div>
</div>

  <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
    <mat-form-field atomMonthYearPicker fxFlex.gt-xs="25" fxFlex.xs="100" >
      <input matInput [matDatepicker]="orderYearMonthPicker" placeholder="Month/Year"
        formControlName="contractMonthYear">
      <mat-datepicker-toggle matSuffix [for]="orderYearMonthPicker"></mat-datepicker-toggle>
      <mat-datepicker #orderYearMonthPicker startView="year" (monthSelected)="selectMonthYear($event)"
        panelClass="month-year-picker"></mat-datepicker>
    </mat-form-field>

    <div fxFlex.gt-xs="45" fxFlex.xs="100" fxFlex="100" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center">
      <mat-form-field fxFlex="45">
        <input matInput placeholder="Strike (min)" formControlName="strikePriceMin" maxlength="10">
        <mat-error *ngIf="orderSearchForm.get('strikePriceMin').invalid">{{formValidator.getErrorMessages(orderSearchForm.get('strikePriceMin'))}}</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="45">
        <input matInput placeholder="Strike (max)" formControlName="strikePriceMax" maxlength="10">
        <mat-error *ngIf="orderSearchForm.get('strikePriceMax').invalid">{{formValidator.getErrorMessages(orderSearchForm.get('strikePriceMax'))}}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <span class="check-radio-label">Side</span>
  <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
    <mat-radio-group formControlName="side" fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center">
      <mat-radio-button  *ngFor="let side of orderSides" [value]="side" labelPosition="after">
        {{ side | replace | titlecase }}
      </mat-radio-button>
      <button id='clear-side' mat-button *ngIf="orderSearchForm.get('side').value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSide()" matTooltip="Clear Side">
        <mat-icon>close</mat-icon>
      </button>
    </mat-radio-group>
</div>

<div class="checkbox-group">
  <span class="check-radio-label">Type</span>
  <mat-checkbox #subTypeBoxes *ngFor="let type of orderSubTypes" (change)="onSubTypeChange($event)" [value]="type" labelPosition="after">
    {{ type | replace | titlecase }}
  </mat-checkbox>
  <mat-checkbox #securityBox *ngFor="let future of ['FUTURE']" (change)="onTypeChange($event)" [value]="future" labelPosition="after">
    Future
  </mat-checkbox>
</div>

<div *ngIf="searchFields.includes('status')" fxFlex.gt-xs="90" class="checkbox-group">
  <span class="check-radio-label">Status</span>
  <mat-checkbox #statusBoxes *ngFor="let status of orderStatuses" (change)="onStatusChange($event)" [value]="status" labelPosition="after">
    {{ status | replace | titlecase }}
  </mat-checkbox>
</div>

  <div *ngIf="!isSearching" fxLayout="row" fxLayoutGap="10px">
    <button class="search-button" id="submit-order-search" [disabled]="disableSubmit(orderSearchForm)" aria-label="Search" type="submit"
      mat-raised-button color="accent">Search</button>
    <button class="search-button" id="clear-order-search" *ngIf="!orderSearchForm.pristine" aria-label="Reset" (click)="reset()"
      mat-stroked-button color="accent">Reset</button>
  </div>
  <div *ngIf="isSearching" fxLayout="row" fxLayoutGap="10px">
    <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
  </div>
</form>

<!-- Display Orders list screen below OrderSearch -->
<div *ngIf="showOrders && !errorMessage" class="order-list">
<atom-orders (orderListChange)="handleOrderListChange($event)" (orderSearchError)="handleOrderListError($event)" (isSearching)="handleIsSearching($event)"
  [selectedOrders$]="selectedOrders$" [initialTableState]="tableState" [selectedParameters]="selectedParameters"></atom-orders>
</div>

