import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { of, Observable, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { BankAccountService } from '@advance-trading/angular-ops-data';
import { ClientService } from '../../../services/client-service';
import { BankAccount, BankAccountType } from '@advance-trading/ops-data-lib';
import { ConfirmDialogComponent } from 'src/app/utilities/ui/confirm-dialog/confirm-dialog.component';

const CLIENT_UPDATE_ROLE = 'AccountAdmin';

@Component({
  selector: 'atom-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.css']
})
export class BankAccountComponent implements OnChanges, OnInit, OnDestroy {
  @Input() account: BankAccount;
  @Input() editMode = false;

  accountForm = new FormGroup({
    type: new FormControl('', [Validators.required]),
    accountNumber: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.maxLength(100)]),
  });

  clientDocId: string;
  bankDocId: string;
  accountNumber$: Observable<{value: string}>;
  canViewAccountNumber = false;
  canEditAccountNumber = false;
  inProgressAccount = new BankAccount();
  updateComplete = true;
  private unsubscribe$: Subject<void> = new Subject<void>();

  accountTypes = Object.keys(BankAccountType);

  constructor(
    private auth0Service: Auth0AuthzService,
    private bankAccountService: BankAccountService,
    private clientService: ClientService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe$)).subscribe((params: ParamMap) => {
      this.clientDocId = params.get('clientDocId');
      this.bankDocId = params.get('bankDocId');
    });
    this.setupAccountForm();
    this.canViewAccountNumber = this.auth0Service.currentUserHasRole(CLIENT_UPDATE_ROLE);
    this.canEditAccountNumber = this.auth0Service.currentUserHasRole(CLIENT_UPDATE_ROLE);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editMode']) {
      if (this.editMode) {
        this.accountForm.enable();
        if (this.account) {
          this.disableAccountNumber();
        } else {
          this.enableAccountNumber();
        }
      } else {
        this.accountForm.disable();
      }
    }
  }

  revealAccountNumber() {
    this.accountNumber$ = this.clientService.decrypt(this.clientDocId, this.inProgressAccount.encryptedAccountNumberKey).pipe(
      catchError(e => {
        this.accountNumber$ = undefined;
        console.log('caught', e);
        this.openSnackBar(`Access Denied`, 'DISMISS', false);
        return of({value: ''});
      })
    );
  }

  hideAccountNumber() {
    this.accountNumber$ = undefined;
  }

  enableAccountNumber() {
    this.accountForm.get('accountNumber').setValue('');
    this.accountForm.get('accountNumber').enable();
  }

  disableAccountNumber() {
    this.accountForm.get('accountNumber').setValue('********');
    this.accountForm.get('accountNumber').disable();
  }

  handleAccountNumberKeypress(e) {
    // Need to prevent <ENTER> from triggering action
    if (e.which === 13) {
      e.preventDefault();
      e.target.blur();
    }
  }

  removeAccount(bankAccount: BankAccount) {
    const title = 'Delete Bank Account';
    const message = 'This will permanently delete this bank account, do you want to continue?';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title, message },
      height: '250px',
      width: '400px',
    });

    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(confirm => {
      if (!confirm) {
        return;
      } else {
        this.bankAccountService.deleteBankAccount(this.clientDocId, this.bankDocId, bankAccount);
      }
    });
  }

  async saveAccountForm() {
    this.updateComplete = false;
    const accountRaw = this.accountForm.getRawValue();
    const isNewAccount = !this.account;
    this.inProgressAccount.type = accountRaw.type;
    if (accountRaw.description) {
      this.inProgressAccount.description = accountRaw.description;
    }

    // Save
    if (this.accountForm.get('accountNumber').enabled) {
      try {
        console.log('Saving account number...');
        await this.clientService.encrypt(
            this.clientDocId, this.inProgressAccount.encryptedAccountNumberKey, this.accountForm.get('accountNumber').value);
        console.log('Complete');
      } catch (e) {
        this.openSnackBar(`Error: Could not save Account Number`, 'DISMISS', false);
        return;
      }
    }

    const serviceFunc = (isNewAccount ?
        this.bankAccountService.createBankAccount :
        this.bankAccountService.updateBankAccount)
      .bind(this.bankAccountService);

    const newAccount = (!this.account) ? this.inProgressAccount : this.inProgressAccount.getPlainObject();

    serviceFunc(this.clientDocId, this.bankDocId, newAccount)
      .then(() => {
        if (!this.account) {
          this.accountForm.reset();
        } else {
          this.accountForm.markAsPristine();
        }
        this.updateComplete = true;
        this.openSnackBar('Bank Account Saved', 'DISMISS', true);
      })
      .catch(e => {
        this.updateComplete = true;
        console.log('Could not save Bank Account', e);
        this.openSnackBar('Error Saving Bank Account', 'DISMISS', false);
      });
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('maxlength')) {
      return 'Input exceeds maximum length';
    }
    return 'Unknown error';
  }

  private setupAccountForm() {
    if (this.account) {
      Object.assign(this.inProgressAccount, this.account);
      this.accountForm.get('type').setValue(this.inProgressAccount.type);
      this.accountForm.get('description').setValue(this.inProgressAccount.description);
    } else {
      this.enableAccountNumber();
    }
    this.accountForm.markAsPristine();
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }
}
