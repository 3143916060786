import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Commodity, CommodityMap, Order, SecurityType, Side } from '@advance-trading/ops-data-lib';
import { OperationsDataService, OrderFill } from '@advance-trading/angular-ops-data';
import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { ExecutionReportService } from '../../services/execution-report-service';
import { OrderFillDisplay } from '../../utilities/order-fill-display';
import { OrderService } from '../../services/order-service';

@Component({
  selector: 'atom-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent implements OnInit {

  isLoading = false;
  errorMessage: string;

  order$: Observable<Order>;
  fillDataSource = new ObservableDataSource<OrderFillDisplay>();
  fillColumnsToDisplay = [];
  hasLeg = false;

  orderDocId: string;
  commodityMap: CommodityMap;
  private orderAccountDocId: string;
  private commodity: Commodity;

  constructor(
    private activatedRoute: ActivatedRoute,
    private executionReportService: ExecutionReportService,
    private operationsDataService: OperationsDataService,
    private orderService: OrderService
  ) { }


  ngOnInit() {

    this.isLoading = true;
    this.order$ = this.activatedRoute.paramMap.pipe(
      switchMap((paramMap: ParamMap) => {
        this.orderAccountDocId = paramMap.get('accountDocId');
        this.orderDocId = paramMap.get('orderDocId');
        return this.operationsDataService.getCommodityMap();
      }),
      switchMap((commodityMap: CommodityMap) => {
        this.commodityMap = commodityMap;
        return this.orderService.getOrderByDocId(this.orderAccountDocId, this.orderDocId);
      }),
      tap((order: Order) => {
        // initialize commodity if needed (only used for OPTION orders)
        this.commodity = order.securityType === SecurityType.OPTION ?
          Object.values(this.commodityMap.commodities).find(cmd => cmd.electronicOptionsSymbol === order.symbol) :
          undefined;
        this.fillDataSource.data$ = this.executionReportService.getOrderFillsByOrderDocId(order.accountDocId, order.docId).pipe(
          map((fills: OrderFill[]) => {
            return fills.map(fill => {
              if (fill.legFills) {
                const orderLegFills = [];
                const orderLegFillKeys = Object.keys(fill.legFills);
                orderLegFillKeys.forEach(legFillKey => {
                  const matchingLeg = order.legs.find(leg =>
                    leg.security + leg.side === fill.legFills[legFillKey].security + fill.legFills[legFillKey].side);
                  orderLegFills.push({
                    isSpread: true,
                    type: matchingLeg.securityType,
                    symbol: matchingLeg.symbol,
                    security: fill.legFills[legFillKey].security,
                    quantity: fill.fillQuantity,
                    side: fill.legFills[legFillKey].side,
                    orderFillPrice: fill.legFills[legFillKey].fillPrice,
                  });
                });
                return orderLegFills;
              } else {
                return {
                  quantity: fill.fillQuantity,
                  orderSymbol: order.symbol,
                  orderFillPrice: fill.fillPrice,
                } as OrderFillDisplay;
              }

            });
          }),
          map(fills => fills.flat()),
          tap((fills: OrderFillDisplay[]) => {
            if (fills.find(fill => fill['isSpread'] === true)) {
              // Spread order displayed column
              this.fillColumnsToDisplay = ['security', 'quantity', 'side', 'legFillPrice'];

              this.hasLeg = true;

            } else {
              // Future order displayed column
              this.fillColumnsToDisplay = ['quantity', 'orderFillPrice'];

              this.hasLeg = false;
            }
          })
        );
        this.isLoading = false;
      }),
      catchError(err => {
        this.errorMessage = 'Error retrieving order data; please try again later';
        console.error(`Error retrieving order data: ${err}`);
        return of(undefined);
      })
    );
  }

  getDisplayPrice(price: number, securityType, symbol) {
    if (!price) {
      return undefined;
    }
    let priceDivisor;
    if (securityType === SecurityType.OPTION || securityType === SecurityType.UDS) {
      const commodity = Object.values(this.commodityMap.commodities).find(cmd => cmd.electronicOptionsSymbol === symbol);
      priceDivisor = commodity ? commodity.orderOptionPriceDivisor : 1;
    } else {
      priceDivisor = this.commodityMap.commodities[symbol] ? this.commodityMap.commodities[symbol].orderFuturePriceDivisor : 1;
    }
    return price / priceDivisor;
  }

  getDisplayStrike(price: number, order: Order) {
    if (!price) {
      return undefined;
    }
    let priceDivisor;
    const commodity = Object.values(this.commodityMap.commodities).find(cmd =>
      cmd.electronicOptionsSymbol === order.symbol || cmd.electronicFuturesSymbol === order.symbol);
    priceDivisor = commodity ? commodity.strikePriceDivisor : 1;
    return price / priceDivisor;
  }

  getQuantityDisplay(quantity: number) {
    return `${quantity} contract${quantity === 1 ? '' : 's'}`;
  }

  getTraderNameOrId(order: Order, creator: boolean) {
    let trader = '';
    if (creator) {
      if (order.creatorName) {
        trader = ` by ${order.creatorName}`;
      } else if (order.creatorTraderId) {
        trader = ` by ${order.creatorTraderId}`;
      }
    } else {
      if (order.lastUpdatedByTraderName) {
        trader = ` by ${order.lastUpdatedByTraderName}`;
      } else if (order.lastUpdatedByTraderId) {
        trader = ` by ${order.lastUpdatedByTraderId}`;
      }
    }

    return trader;
  }

  private getLegFillSymbol(security: string, order: Order) {
    if (order.securityType === SecurityType.OPTION) {
      const splitSecurity = security.split(' ');
      return splitSecurity[0].substring(0, splitSecurity.length - 2);
    } else {
      return security.substring(0, security.length - 2);
    }
  }
}
