<div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="10">
  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
      <h6 *ngIf="filteredBy" class="card-brow">{{ filteredBy | uppercase }}</h6>

      <div *ngIf="errorMessage">
        <h2 class="card-title">Error Performing Client Search</h2>
        <p [innerHTML]="errorMessage"></p>
        <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
      </div>

      <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="card-title">Clients</h2>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <button id="add-new-client" *ngIf="accountAdmin" aria-label="Add New Client" (click)="addNewClient()"
            mat-mini-fab color="accent" matTooltip="Add New Client">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>

      <form *ngIf="!errorMessage" [formGroup]="clientSearchForm" (ngSubmit)="searchClients(true)" fxLayout="column">
        <div fxLayout="row Wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <div fxLayout="column" fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap="5">
            <mat-form-field fxFlex.gt-xs="100" fxFlex.xs="70" (click)="accountNumberFieldClicked()">
              <mat-label>Search Account Number</mat-label>
              <input matInput formControlName="accountNumber" trim="blur" maxLength="5" upperCase mask="A*">
              <mat-error *ngIf="clientSearchForm.get('accountNumber').invalid" >{{ getAccountNumberErrorMessage(clientSearchForm.get('accountNumber')) }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="70" (click)="brokerCodeFieldClicked()">
              <mat-label>Search Broker Code</mat-label>
              <input matInput formControlName="brokerCode" trim="blur" upperCase maxLength="6" mask="A*">
              <mat-error *ngIf="clientSearchForm.get('brokerCode').invalid" >{{ getBrokerCodeErrorMessage(clientSearchForm.get('brokerCode')) }}</mat-error>
            </mat-form-field>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex.gt-xs="45" fxFlex.xs="70" fxLayoutGap="5">
              <mat-spinner *ngIf="isSearchingClientNames" [diameter]="30"></mat-spinner>
              <mat-form-field *ngIf="!isSearchingClientNames" fxFlex="100" (click)="clientNameFieldClicked()">
                <input #clientNameSearch matInput type="text" placeholder="Search Client Name" formControlName="clientName" [matAutocomplete]="autoClient">
                <mat-error *ngIf="clientSearchForm.get('clientName').invalid">You must select a valid client name</mat-error>
              </mat-form-field>
              <mat-autocomplete #autoClient="matAutocomplete" [displayWith]="displayClient">
                <mat-option *ngFor="let client of filteredClients$ | async" [value]="client">{{ displayClient(client) }}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
          <div fxLayout="column" fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap="5">
            <mat-form-field fxFlex.gt-xs="100" fxFlex.xs="70">
              <mat-label>Managing Pod</mat-label>
              <mat-select [disableOptionCentering]="true" formControlName="clientPod">
                <mat-option value="ALL">All</mat-option>
                <mat-option *ngFor="let pod of podNames" [value]="pod"> {{pod | replace | titlecase}}</mat-option>
            </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex.gt-xs="100" fxFlex.xs="70">
              <mat-label>Client Status</mat-label>
              <mat-select [disableOptionCentering]="true" formControlName="clientStatus">
                <mat-option value="ALL">All</mat-option>
                <mat-option value="ACTIVE">Active</mat-option>
                <mat-option value="INACTIVE">Inactive</mat-option>
            </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="!isSearching" fxLayout="row" fxLayoutGap="10px">
          <button id="submit-client-search" [disabled]="clientSearchForm.invalid" aria-label="Search" type="submit"
            mat-raised-button color="accent">Search</button>
          <button id="clear-client-search" *ngIf="!clientSearchForm.pristine"  aria-label="Reset" (click)="reset()"
            mat-stroked-button color="accent">Reset</button>
        </div>

        <div *ngIf="isSearching" fxLayout="row" fxLayoutGap="10px">
          <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
        </div>

      </form>

      <div *ngIf="showClients && !errorMessage" class="client-list">
        <atom-clients (clientSearchError)="handleClientError($event)" (isSearching)="handleIsSearching($event)"
        [selectedClients$]="selectedClients$"></atom-clients>
      </div>

    </mat-card>
  </div>
</div>
