import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { AccountSeriesService, SalesCodeService } from '@advance-trading/angular-ops-data';
import { AccountSeries, Status } from '@advance-trading/ops-data-lib';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

const ACCOUNT_ADMIN_ROLE = 'AccountAdmin';

@Component({
  selector: 'atom-account-series-search',
  templateUrl: './account-series-search.component.html',
  styleUrls: ['./account-series-search.component.css']
})
export class AccountSeriesSearchComponent implements OnInit, AfterViewChecked {

  accountSeriesSearchForm: FormGroup = this.formBuilder.group({
    salesCode: [''],
    status: ['']
  });

  filteredAccountSeries$: Observable<AccountSeries>;

  selectedAccountSeries$: Observable<AccountSeries[]>;

  tableState: { [key: string]: string | number } = {};

  private queryParams: Params;

  constructor(
    private activatedRoute: ActivatedRoute,
    private accountSeriesService: AccountSeriesService,
    private authzService: Auth0AuthzService,
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private salesCodeService: SalesCodeService,
    private snackBar: MatSnackBar
  ) {
  }

  errorMessage: string;
  isSearching = false;
  showAccountSeries = false;
  filteredBy = '';

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(ACCOUNT_ADMIN_ROLE)) {
      this.errorMessage = 'You do not have permission to search account series';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.queryParams = Object.assign({}, params);

      this.accountSeriesSearchForm.get('salesCode').setValue(this.queryParams.salesCode);

      if (this.queryParams.status === undefined) {
        this.queryParams.status = 'ALL';
        this.accountSeriesSearchForm.get('status').setValue(this.queryParams.status);
      } else {
        this.accountSeriesSearchForm.get('status').setValue(this.queryParams.status);
      }

      if (Object.keys(params).length) {
        this.searchAccountSeries();
      }
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  get accountAdmin() {
    return this.authzService.currentUserHasRole(ACCOUNT_ADMIN_ROLE);
  }

  reset() {
    this.accountSeriesSearchForm.get('salesCode').setValue('');
    this.accountSeriesSearchForm.get('status').setValue('ALL');

    this.filteredBy = '';
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });

    this.accountSeriesSearchForm.enable();
    this.accountSeriesSearchForm.markAsPristine();
    this.clearQueryParams();
    this.showAccountSeries = false;
  }

  searchAccountSeries(searchButtonClicked: boolean = false) {
    this.accountSeriesSearchForm.markAsDirty();
    if (searchButtonClicked) {
      this.clearQueryParams();
      this.tableState = {};
      this.tableState.pageIndex = 0;
    } else {
      const sortDir = this.queryParams.sortDir;
      const sortColName = this.queryParams.sortColName;
      const pageSize = this.queryParams.pageSize;
      const pageIndex = this.queryParams.pageIndex;
      const filter = this.queryParams.filter;
      this.tableState = {
        sortDir,
        sortColName,
        pageSize,
        pageIndex,
        filter
      };
    }
    this.showAccountSeries = false;
    this.changeDetector.detectChanges();
    this.selectedAccountSeries$ = this.chooseQuery();
    this.showAccountSeries = true;
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('maxlength')) {
      return 'Must be 3 characters';
    } else {
      return 'Unknown error';
    }
  }

  handleAccountSeriesListChange(tableState: { [key: string]: string | number }) {
    if (tableState.sortDir && tableState.sortColName) {
      this.queryParams.sortDir = tableState.sortDir;
      this.queryParams.sortColName = tableState.sortColName;
    } else if (this.queryParams.sortDir && this.queryParams.sortColName) {
      delete this.queryParams.sortDir;
      delete this.queryParams.sortColName;
    }
    if (tableState.pageSize) {
      this.queryParams.pageSize = tableState.pageSize;
    }
    if (tableState.pageIndex !== undefined) {
      this.queryParams.pageIndex = tableState.pageIndex;
    }
    if (tableState.filter) {
      this.queryParams.filter = tableState.filter;
    } else if (this.queryParams.filter) {
      delete this.queryParams.filter;
    }
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  handleAccountSeriesError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.changeDetector.detectChanges();
  }

  salesCodeFieldClicked() {
    this.accountSeriesSearchForm.get('status').setValue('ALL');
    this.accountSeriesSearchForm.get('status').disable();
    this.accountSeriesSearchForm.get('salesCode').enable();
  }

  statusFieldClicked() {
    this.accountSeriesSearchForm.get('salesCode').setValue('');
    this.accountSeriesSearchForm.get('salesCode').disable();
    this.accountSeriesSearchForm.get('status').enable();
  }

  private chooseQuery(): Observable<AccountSeries[]> {

    if (!this.authzService.currentUserHasRole(ACCOUNT_ADMIN_ROLE)) {
      this.errorMessage = 'You do not have permission to search account series';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    const salesCodeValue = this.accountSeriesSearchForm.get('salesCode').value;
    const statusValue = this.accountSeriesSearchForm.get('status').value;

    this.queryParams = this.tableState as Params;

    if (salesCodeValue) {
      this.queryParams.salesCode = salesCodeValue;
    }

    if (statusValue) {
      this.queryParams.status = statusValue;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });

    if (salesCodeValue) {
      this.filteredBy = `Sales Code: ${this.queryParams.salesCode}`;
      return this.salesCodeService.getSalesCodeDocumentBySalesCode(salesCodeValue).pipe(
        switchMap((salesCode) => {
          if (!salesCode) {
            this.openSnackBar('No account series associated with this sales code', 'DISMISS', true);
            return of([]);
          }
          return this.accountSeriesService.getAccountSeriesBySalesCode(salesCode.docId).pipe(
            tap((salesCodes) => {
              if (salesCodes.length === 0) {
                this.openSnackBar('No account series associated with this sales code', 'DISMISS', true);
              }
            })
          );
        })
      );
    } else if (statusValue && statusValue !== 'ALL') {
      return this.accountSeriesService.getAccountSeriesByStatus(statusValue);
    } else {
      return this.accountSeriesService.getAllAccountSeries();
    }
  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }

  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

}

