// Angular
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Angular Fire
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';

// Angular Material
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

// Apollo Angular
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';

// External libraries
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatIntlTelInputModule } from '@advance-trading/ngx-mat-intl-tel-input';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { NgxUpperCaseDirectiveModule } from 'ngx-upper-case-directive';
import { MatTableExporterModule } from 'mat-table-exporter';

// Internal libraries
import { AtiSecurityModule } from '@advance-trading/angular-ati-security';
import { CommonServicesModule } from '@advance-trading/angular-common-services';

// Local
import { AtomRoutingModule } from './atom.routing.module';
import { BirthDatePickerDirective } from './clients/contact-detail/birth-date-picker.directive';
import { DisplayFcmPipe } from './utilities/display-fcm.pipe';
import { DisplayNamePipe } from './utilities/display-name.pipe';
import { environment } from '../environments/environment';
import { MonthYearPickerDirective } from './utilities/month-year-picker.directive';
import { OrderSecurityPipe } from './utilities/order-security-pipe';
import { UserStatusService } from './auth/user-status.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
// Components
import { AboutDialogComponent } from './utilities/ui/about-dialog/about-dialog.component';
import { AccountDetailComponent } from './accounts/account-detail.component';
import { AccountsComponent } from './accounts/accounts.component';
import { AccountSearchComponent } from './accounts/account-search/account-search.component';
import { AccountSeriesComponent } from './broker-codes/account-series/account-series.component';
import { AccountSeriesDetailComponent } from './broker-codes/account-series/account-series-detail.component';
import { AccountSeriesSearchComponent } from './broker-codes/account-series/account-series-search/account-series-search.component';
import { AdjustmentsComponent } from './transactions/adjustments/adjustments.component';
import { AdjustmentDetailComponent } from './transactions/adjustments/adjustment-detail/adjustment-detail.component';
import { AdjustmentSearchComponent } from './transactions/adjustments/adjustment-search/adjustment-search.component';
import { AtomComponent } from './atom.component';
import { BankAccountComponent } from './clients/bank/bank-account/bank-account.component';
import { BankComponent } from './clients/bank/bank.component';
import { BrokerDetailComponent } from './brokers/broker-detail.component';
import { BrokerRelationshipsComponent } from './broker-codes/broker-relationships/broker-relationships.component';
import { BrokerRelationshipsSearchComponent } from './broker-codes/broker-relationships/broker-relationships-search/broker-relationships-search.component';
import { BrokerRelationshipDetailComponent } from './broker-codes/broker-relationships/broker-relationship-detail/broker-relationship-detail.component';
import { BrokerRequestDetailComponent } from './brokers/broker-request-detail.component';
import { BrokerRequestsComponent } from './brokers/broker-requests.component';
import { BrokersComponent } from './brokers/brokers.component';
import { CallbackComponent } from './callback/callback.component';
import { CashActivityComponent } from './transactions/cash-activity/cash-activity.component';
import { CashActivitySearchComponent } from './transactions/cash-activity/cash-activity-search/cash-activity-search.component';
import { CashActivityDetailComponent } from './transactions/cash-activity/cash-activity-detail/cash-activity-detail.component';
import { ClientDetailComponent } from './clients/client-detail.component';
import { ClientSearchComponent } from './clients/client-search/client-search.component';
import { ClientsComponent } from './clients/clients.component';
import { CommissionRateDialogComponent } from './rate-codes/commission-rate-dialog/commission-rate-dialog.component';
import { CommissionRateTableComponent } from './rate-codes/commission-rate-table/commission-rate-table.component';
import { CommissionScheduleDetailComponent } from './rate-codes/commission-schedules/commission-schedule-detail.component';
import { CommissionSchedulesComponent } from './rate-codes/commission-schedules/commission-schedules.component';
import { ConfirmDialogComponent } from './utilities/ui/confirm-dialog/confirm-dialog.component';
import { ContactDetailComponent } from './clients/contact-detail/contact-detail.component';
import { ContactsComponent } from './clients/contacts/contacts.component';
import { FcmAuthorizedSignerComponent } from './clients/fcm-setup/fcm-authorized-signer/fcm-authorized-signer.component';
import { FcmSetupComponent } from './clients/fcm-setup/fcm-setup.component';
import { HmsUserSettingsComponent } from './users/hms-user-settings/hms-user-settings.component';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { MarginsComponent } from './margins/margins.component';
import { MarginDetailComponent } from './margins/margin-detail/margin-detail.component';
import { MarginSearchComponent } from './margins/margin-search/margin-search.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderDetailComponent } from './orders/order-detail/order-detail.component';
import { OrderLegComponent } from './orders/order-leg/order-leg.component';
import { OrderSearchComponent } from './orders/order-search/order-search.component';
import { OrderSearchTabComponent } from './orders/order-search/order-search-tab/order-search-tab.component';
import { PayCodeDetailComponent } from './brokers/pay-code-detail/pay-code-detail.component';
import { PayCodeSplitComponent } from './brokers/pay-code-detail/pay-code-split/pay-code-split.component';
import { PositionsComponent } from './positions/positions.component';
import { PositionDetailComponent } from './positions/position-detail/position-detail.component';
import { PositionSearchComponent } from './positions/position-search/position-search.component';
import { RegistrationComponent } from './registration/registration.component';
import { SalesCodeDetailComponent } from './broker-codes/sales-codes/sales-code-detail.component';
import { SalesCodesComponent } from './broker-codes/sales-codes/sales-codes.component';
import { TradesComponent } from './transactions/trades/trades.component';
import { TradeDetailComponent } from './transactions/trades/trade-detail/trade-detail.component';
import { TradeSearchComponent } from './transactions/trades/trade-search/trade-search.component';
import { UserDetailComponent } from './users/user-detail.component';
import { UsersComponent } from './users/users.component';

// Service that will be moved to common library
import { ExportService } from './services/export.service';


@NgModule({
  declarations: [
    AboutDialogComponent,
    AccountDetailComponent,
    AccountsComponent,
    AccountSearchComponent,
    AccountSeriesComponent,
    AccountSeriesDetailComponent,
    AccountSeriesSearchComponent,
    AdjustmentsComponent,
    AdjustmentDetailComponent,
    AdjustmentSearchComponent,
    AtomComponent,
    BankAccountComponent,
    BankComponent,
    BirthDatePickerDirective,
    BrokerDetailComponent,
    BrokerRelationshipsComponent,
    BrokerRelationshipDetailComponent,
    BrokerRelationshipsSearchComponent,
    BrokerRequestDetailComponent,
    BrokerRequestsComponent,
    BrokersComponent,
    CallbackComponent,
    CashActivityComponent,
    CashActivitySearchComponent,
    CashActivityDetailComponent,
    ClientDetailComponent,
    ClientSearchComponent,
    ClientsComponent,
    CommissionRateDialogComponent,
    CommissionRateTableComponent,
    CommissionScheduleDetailComponent,
    CommissionSchedulesComponent,
    ConfirmDialogComponent,
    ContactDetailComponent,
    ContactsComponent,
    DisplayFcmPipe,
    DisplayNamePipe,
    FcmAuthorizedSignerComponent,
    FcmSetupComponent,
    HmsUserSettingsComponent,
    HomeComponent,
    LogoutComponent,
    MarginsComponent,
    MarginDetailComponent,
    MarginSearchComponent,
    MonthYearPickerDirective,
    NotFoundComponent,
    OrdersComponent,
    OrderDetailComponent,
    OrderLegComponent,
    OrderSearchComponent,
    OrderSearchTabComponent,
    OrderSecurityPipe,
    PayCodeDetailComponent,
    PayCodeSplitComponent,
    PositionsComponent,
    PositionDetailComponent,
    PositionSearchComponent,
    RegistrationComponent,
    SalesCodeDetailComponent,
    SalesCodesComponent,
    TradesComponent,
    TradeDetailComponent,
    TradeSearchComponent,
    UserDetailComponent,
    UsersComponent,
  ],
  entryComponents: [
    AboutDialogComponent,
    CommissionRateDialogComponent,
    ConfirmDialogComponent
  ],
  imports: [
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    ApolloModule,
    AtiSecurityModule.configure(environment),
    AtomRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonServicesModule.configure(environment),
    FlexLayoutModule,
    HttpClientModule,
    LayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatChipsModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,

    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableExporterModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxMaskModule.forRoot(),
    NgxMatIntlTelInputModule,
    NgxTrimDirectiveModule,
    NgxUpperCaseDirectiveModule,
    ReactiveFormsModule
  ],
  providers: [
    UserStatusService,
    ExportService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true }
    },
    {
      provide: 'Window',
      useValue: window
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.services.httpOpsSQLSearch,
          }),
        };
      },
     deps: [HttpLink],
    }
  ],
  bootstrap: [AtomComponent]
})
export class AtomModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIcon('cash-activity', domSanitizer.bypassSecurityTrustResourceUrl('./assets/cash-multiple.svg')),
    matIconRegistry.addSvgIcon('text-box-multiple', domSanitizer.bypassSecurityTrustResourceUrl('./assets/text-box-multiple.svg'));
  }
 }
