import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map, take, takeUntil, tap } from 'rxjs/operators';

import { Auth0AuthzService, AuthService } from '@advance-trading/angular-ati-security';
import { ObservableDataSource, StorageService } from '@advance-trading/angular-common-services';
import { BrokerRequestService } from '@advance-trading/angular-ops-data';
import { BrokerRequest, BrokerType } from '@advance-trading/ops-data-lib';
import { ExportService } from '../services/export.service';

const STATUS_BADGES = [
  {
    description: 'Branch Committee',
    targetField: 'isBranchCommitteeApproved',
    brokerTypes: [BrokerType.BRANCH],
  },
  {
    description: 'Board of Directors',
    targetField: 'isBoardApproved',
    brokerTypes: [...Object.values(BrokerType)],
  },
  {
    description: 'Human Resources',
    targetField: 'isHrApproved',
    brokerTypes: [BrokerType.BRANCH, BrokerType.COMMERCIAL],
  },
  {
    description: 'Compliance',
    targetField: 'isComplianceApproved',
    brokerTypes: [...Object.values(BrokerType)],
  }
];

const PAGE_SIZE_KEY = 'atom.brokerRequestsPageSize';

@Component({
  selector: 'atom-broker-requests',
  templateUrl: './broker-requests.component.html',
  styleUrls: ['./broker-requests.component.css'],
  providers: [BreakpointObserver]
})
export class BrokerRequestsComponent implements AfterViewInit, OnInit, OnDestroy {

  errorMessage: string;
  dataSource = new ObservableDataSource<BrokerRequest>();
  filterValue = new FormControl();
  isBrokerRequester = this.authzService.currentUserHasRole('BrokerRequester');
  columnsToDisplay = ['name', 'type', 'status', 'badges'];
  isScreenXSmall = false;
  exportable = false;

  private queryParams: Params;
  private unsubscribe$: Subject<void> = new Subject<void>();

  private readonly SHEET_NAME = 'ATOM Broker Requests';

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private brokerRequestService: BrokerRequestService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private changeDetector: ChangeDetectorRef,
    public exportService: ExportService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    const isBrokerRequestViewer = this.authzService.currentUserHasRole('BrokerRequestViewer');
    const isBranchCommitteeApprover = this.authzService.currentUserHasRole('BranchCommitteeApprover');
    let brokerRequests$: Observable<BrokerRequest[]>;

    if (isBrokerRequestViewer) {
      brokerRequests$ = this.brokerRequestService.getAllBrokerRequests();
    } else {
      const currentUser = this.authService.userProfile.app_metadata.firestoreDocId;
      const originatorRequests$ = this.brokerRequestService.getBrokerRequestsByOriginator(currentUser);

      if (isBranchCommitteeApprover) {
        // Combine branch requests and originator requests, removing duplicates
        const branchRequests$ = this.brokerRequestService.getBrokerRequestsByType(BrokerType.BRANCH);

        brokerRequests$ = combineLatest([originatorRequests$, branchRequests$]).pipe(
          map(([originatorRequests, branchRequests]) => {
            return originatorRequests.concat(
              branchRequests.filter(request => !originatorRequests.find(originatorRequest => originatorRequest.docId === request.docId))
            );
          })
        );
      } else {
        brokerRequests$ = originatorRequests$;
      }
    }

    this.filterValue.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((filter: string) => {
      if (filter) {
        this.queryParams.filter = filter.trim();
      } else if (this.queryParams.filter) {
        delete this.queryParams.filter;
      }

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        replaceUrl: true,
        queryParams: this.queryParams
      });
    });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
      this.queryParams = Object.assign({}, params);
    }));

    this.dataSource.data$ = brokerRequests$
      .pipe(
        map((brokerRequests: BrokerRequest[]) => {
          return brokerRequests.filter(brokerRequest => this.shouldShowRequest(brokerRequest))
            .map(brokerRequest => Object.assign(brokerRequest, {badges: this.getStatusBadges(brokerRequest)}));
        }),
        tap(brokerRequests => {
          this.setTableState();
          this.exportable = brokerRequests.length > 0;
        }),
        catchError(err => {
          this.errorMessage = 'Error retrieving broker requests; please try again later';
          console.error(`Error retrieving broker requests: ${err}`);
          return of([]);
        })
      );

    this.breakpointObserver.observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.matches) {
          this.isScreenXSmall = true;
          this.columnsToDisplay = ['name', 'status'];
        } else {
          this.isScreenXSmall = false;
          this.columnsToDisplay = ['name', 'type', 'status', 'badges'];
        }
      });

    const filterVal = this.queryParams.filter;
    if (filterVal) {
      this.filterValue.setValue(filterVal);
      this.applyFilter(this.filterValue.value);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    if(this.authzService.currentUserHasRole('BrokerRequestViewer')) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.paginator.pageSize = this.storageService.localStorage.get(PAGE_SIZE_KEY).value() || 10;
      // Special sorting accessor to handle the combined first and last name field
      this.dataSource.sortingDataAccessor = (data, sortHeaderId): string | number => {
        if (sortHeaderId === 'name') {
          return data.firstName + ' ' + data.lastName;
        } else if (sortHeaderId === 'status') {
          // Sort pending requests by number of approvals
          return data.status + data['badges'].reduce((accumulator, val) => {
            return val.isApproved ? accumulator + 1 : accumulator;
          }, 0);
        } else {
          return (data as {[key: string]: any})[sortHeaderId];
        }
      };
      this.dataSource.sort = this.sort;
      this.filter.nativeElement.focus();
      this.changeDetector.detectChanges();
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  selectBrokerRequest(brokerRequest: BrokerRequest) {
    this.router.navigate(['/brokerrequests', brokerRequest.docId]);
  }

  addNewBroker() {
    this.router.navigate(['/brokers/new']);
  }

  handlePageChange() {
    this.storageService.localStorage.set(PAGE_SIZE_KEY, this.paginator.pageSize);
    this.queryParams.pageIndex = this.paginator.pageIndex;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  handleSortChange() {
    this.queryParams.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.queryParams.sortColName = this.queryParams.sortDir ? this.sort.active : undefined;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  private setTableState() {
    if (this.filter) {
      const pageIndex = this.queryParams.pageIndex as number;
      if (pageIndex) {
        this.dataSource.paginator.pageIndex = pageIndex;
      }

      const sortDir = this.queryParams.sortDir as SortDirection;
      const sortColName = this.queryParams.sortColName as string;
      if (sortDir && sortColName) {
        this.dataSource.sort.direction = sortDir;
        this.dataSource.sort.active = sortColName;
      }
    }
  }

  private shouldShowRequest(brokerRequest: BrokerRequest) {
    const currentUser = this.authService.userProfile.app_metadata.firestoreDocId;

    const isBranchCommitteeApprover = this.authzService.currentUserHasRole('BranchCommitteeApprover');
    const isBrokerRequestViewer = this.authzService.currentUserHasRole('BrokerRequestViewer');
    const isOriginator = brokerRequest.originator === currentUser;

    return (
      isOriginator || isBrokerRequestViewer ||
      (isBranchCommitteeApprover && brokerRequest.type === BrokerType.BRANCH)
    );
  }

  private getStatusBadges(brokerRequest) {
    return STATUS_BADGES
      .filter(badgeConfig => badgeConfig.brokerTypes.includes(brokerRequest.type))
      .map(badgeConfig => ({
        ...badgeConfig,
        isApproved: brokerRequest[badgeConfig.targetField]
      }));
  }

}
