import { TradeService } from '../../../services/trade-service';
import { Adjustment, CommodityMap, Trade } from '@advance-trading/ops-data-lib';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, tap, take, takeUntil } from 'rxjs/operators';
import { ObservableDataSource, StorageService } from '@advance-trading/angular-common-services';
import { OperationsDataService } from '@advance-trading/angular-ops-data';
import { MatPaginator } from '@angular/material/paginator';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TradeSearchCriteria } from 'src/app/services/service-interfaces/trade-search-interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdjustmentService } from 'src/app/services/adjustment-service';
import { AccountService } from 'src/app/services/account-service';

const PAGE_SIZE_KEY = 'atom.nonAssignedTrades';

@Component({
  selector: 'atom-adjustment-detail',
  templateUrl: './adjustment-detail.component.html',
  styleUrls: ['./adjustment-detail.component.css']
})
export class AdjustmentDetailComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('assignedPaginator', { static: false }) assignedPaginator: MatPaginator;
  @ViewChild('unassignedPaginator', { static: false }) unassignedPaginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  editMode = false;
  exportable = false;
  isLoading = false;
  assignedTradesLoading = false;
  unassignedTradesLoading = false;
  updateComplete = true;
  accountDocId: string;
  adjustmentDocId: string;
  commodityMap: CommodityMap;
  errorMessage: string;
  columnsToDisplay = [];

  private updatedTrades = [];
  private unsubscribe$: Subject<void> = new Subject<void>();
  adjustment: Adjustment;
  adjustment$: Observable<Adjustment>;
  assignedTrades$: Observable<Trade[]>;
  unassignedTrades$: Observable<Trade[]>;
  assignedTrades: Trade[] = [];
  unassignedTrades: Trade[] = [];
  assignedDataSource = new ObservableDataSource<Trade>();
  unassignedDataSource = new ObservableDataSource<Trade>();

  constructor(
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private adjustmentService: AdjustmentService,
    private breakpointObserver: BreakpointObserver,
    private changeDetector: ChangeDetectorRef,
    private operationsDataService: OperationsDataService,
    private router: Router,
    public snackBar: MatSnackBar,
    private storageService: StorageService,
    private tradeService: TradeService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.assignedTradesLoading = true;
    this.unassignedTradesLoading = true;

    this.adjustment$ = this.activatedRoute.paramMap.pipe(
      switchMap((paramMap: ParamMap) => {
        this.accountDocId = paramMap.get('accountDocId');
        this.adjustmentDocId = paramMap.get('adjustmentDocId');
        return this.adjustmentService.getAdjustmentByDocId(this.accountDocId, this.adjustmentDocId);
      }),
      tap(adjustment => {
        this.adjustment = adjustment;
        this.isLoading = false;

        const searchCriteria: TradeSearchCriteria = {};
        const sixMonthsAgo = new Date(adjustment.businessDate);
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

        this.assignedTrades$ = this.accountService.getAccount(this.accountDocId).pipe(
          switchMap(account => {
            return this.tradeService.getTradesByAccountNumbers([account.number], {});
          })
        ).pipe(
          map(trades => trades.filter(trade => adjustment.linkedTradeDocIds.includes(trade.docId))),
          tap(() => this.assignedTradesLoading = false)
        );


        this.unassignedTrades$ = this.accountService.getAccount(this.accountDocId).pipe(
          switchMap(account => {
            return this.tradeService.getTradesByAccountNumbers([account.number], {});
          })
        ).pipe(
            map(trades => trades.filter(trade => new Date(trade.tradeDate).getTime() >= sixMonthsAgo.getTime()
              && !adjustment.linkedTradeDocIds.includes(trade.docId)
              && !(adjustment.commission === 0 ? trade.linkedCashAdjustmentDocId : trade.linkedCommissionAdjustmentDocId)
              && !(trade.linkedCashAdjustmentDocId && trade.linkedCommissionAdjustmentDocId))),
            tap(() => this.unassignedTradesLoading = false)
          );

        this.operationsDataService.getCommodityMap().pipe(
          switchMap((doc: CommodityMap) => {
            this.commodityMap = doc;
            return this.assignedTrades$;
          })
        ).pipe(take(1)).subscribe(val => {
          // create an array of copies of the trades so their fields can be edited (object fields from graphQL are read-only)
          const editableTrades = [];
          val.forEach(trade => {
            const tradeCopy = {...trade} as Trade;
            editableTrades.push(tradeCopy);
          });
          this.assignedDataSource.data = editableTrades;
        });

        this.operationsDataService.getCommodityMap().pipe(
          switchMap((doc: CommodityMap) => {
            this.commodityMap = doc;
            return this.unassignedTrades$;
          })
        ).pipe(take(1)).subscribe(val => {
          // create an array of copies of the trades so their fields can be edited (object fields from graphQL are read-only)
          const editableTrades = [];
          val.forEach(trade => {
            const tradeCopy = {...trade} as Trade;
            editableTrades.push(tradeCopy);
          });
          this.unassignedDataSource.data = editableTrades;
        });
      }),
      catchError(err => {
        this.errorMessage = 'Error retrieving adjustment data; please try again later';
        console.error(`Error retrieving adjustment data: ${err}`);
        return of(undefined);
      })
    );

    this.breakpointObserver.observe(
      [
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium
      ]
    ).pipe(takeUntil(this.unsubscribe$)).subscribe(state => {
      if (state.breakpoints[Breakpoints.XSmall]) {
        this.columnsToDisplay = [
          'transactionDate', 'tradeDate', 'commodityId'
        ];
      } else if (state.breakpoints[Breakpoints.Small]) {
        this.columnsToDisplay = [
          'transactionDate', 'tradeDate', 'brokerCode', 'accountNumber', 'side', 'quantity', 'contractYearMonth', 'strikePrice', 'commodityId',
        ];
      } else if (state.breakpoints[Breakpoints.Medium]) {
        this.columnsToDisplay = [
          'transactionDate', 'tradeDate', 'brokerCode', 'accountNumber', 'side', 'quantity', 'contractYearMonth', 'strikePrice', 'commodityId', 'securitySubType', 'commission',
        ];
      } else {
        this.columnsToDisplay = [
          'transactionDate', 'tradeDate', 'brokerCode', 'accountNumber', 'side', 'quantity', 'contractYearMonth', 'strikePrice', 'commodityId', 'securitySubType', 'price', 'spreadCode', 'commission', 'clearingFee', 'exchangeFee', 'nfaFee', 'transactionRecordType'
        ];
      }
    });
  }

  ngAfterViewInit() {
    this.assignedDataSource.paginator = this.assignedPaginator;
    this.unassignedDataSource.paginator = this.unassignedPaginator;
    this.unassignedDataSource.paginator.pageSize = this.storageService.localStorage.get(PAGE_SIZE_KEY).value() || 10;
    this.unassignedDataSource.sort = this.sort;
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getCommodityDisplayName(symbol: string) {
    if (!symbol) {
      return '';
    }
    const commodity = Object.values(this.commodityMap.commodities).find(cmd => cmd.id === symbol);
    if (commodity) {
      return commodity.name;
    } else {
      return '';
    }
  }

  assignTrade($event: MatCheckboxChange, trade: Trade) {
    if ($event.checked) {
      if (!this.adjustment.linkedTradeDocIds.includes(trade.docId)) {
        if (this.adjustment.commission === 0) {
          trade.linkedCashAdjustmentDocId = this.adjustment.docId;
        } else {
          trade.linkedCommissionAdjustmentDocId = this.adjustment.docId;
        }

        this.updatedTrades.push(trade);
        this.adjustment.linkedTradeDocIds.push(trade.docId);
      }
    } else {
      this.updatedTrades = this.updatedTrades.filter(assignedTrade => assignedTrade.docId !== trade.docId);
      this.adjustment.linkedTradeDocIds = this.adjustment.linkedTradeDocIds.filter(tradeDocId => trade.docId !== tradeDocId);
    }
  }

  unassignTrade($event: MatCheckboxChange, trade: Trade) {
    if (!$event.checked) {
      if (this.adjustment.commission === 0) {
        trade.linkedCashAdjustmentDocId = '';
      } else {
        trade.linkedCommissionAdjustmentDocId = '';
      }

      this.updatedTrades.push(trade);
      this.adjustment.linkedTradeDocIds = this.adjustment.linkedTradeDocIds.filter(tradeDocId => trade.docId !== tradeDocId);
    } else {
      this.updatedTrades = this.updatedTrades.filter(assignedTrade => assignedTrade.docId !== trade.docId);
      if (!this.adjustment.linkedTradeDocIds.includes(trade.docId)) {
        this.adjustment.linkedTradeDocIds.push(trade.docId);
      }
    }
  }

  selectTrade(trade: Trade) {
    this.router.navigate(['/accounts', trade.accountDocId, 'trades', trade.docId]);
  }

  reset() {
    this.setEditMode(false);
    this.updatedTrades = [];
  }

  setEditMode(mode: boolean) {
    this.editMode = mode;
    if (this.editMode) {
      this.columnsToDisplay = ['assignment'].concat(this.columnsToDisplay);
    } else if (this.columnsToDisplay[0] === 'assignment') {
      this.columnsToDisplay.shift();
    }
  }

  handlePageChange() {
    this.storageService.localStorage.set(PAGE_SIZE_KEY, this.unassignedPaginator.pageSize);
  }

  async submit() {
    this.updateComplete = false;
    this.adjustmentService.updateAdjustment(this.accountDocId, this.adjustment).then(response => {
      const promise = Promise.all(
        this.updatedTrades.map((trade: Trade) => {
          return this.tradeService.updateTrade(this.accountDocId, trade);
        })
      );
      return promise;
    }).then(response => {
      console.log('Adjustment successfully updated');
      this.setEditMode(false);
      this.updateComplete = true;
      this.updatedTrades = [];
      this.openSnackBar('Adjustment successfully updated', 'DISMISS', true);
      return response;
    }).catch(err => {
      this.updateComplete = true;
      console.error('Adjustment update failed: ' + JSON.stringify(err));
      let errorMessage = '';
      switch (err.code) {
        case 'permission-denied':
          errorMessage = 'Insufficient permissions';
          break;
        default:
          errorMessage = 'Unknown error occured';
      }
      this.openSnackBar('Adjustment update failed: ' + errorMessage, 'DISMISS', false);
    });
  }

  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }
}
