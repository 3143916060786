<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Clients List</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="column" fxFlex="100">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="clients-filter-field" #filter>
        <button mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <button *ngIf="exportable" aria-label="Export" id="export-clients" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
      <mat-menu #exportMenu="matMenu">
        <button mat-menu-item (click)="exportService.exportXlsx(getXlsxExportItems(), 'Clients')">Excel</button>
        <button mat-menu-item (click)="exportService.exportCsv(getExportableItems(), 'Clients')">CSV</button>
        <button mat-menu-item (click)="exportService.exportJson(getExportableItems(), 'Clients')">JSON</button>
      </mat-menu>

      <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" class="mat-elevation-z0" (matSortChange)="handleSortChange()">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="70" fxFlex.gt-xs="20">Name</mat-header-cell>
          <mat-cell *matCellDef="let client" fxFlex.xs="70" fxFlex.gt-xs="20">{{ client.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="location">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="15">Location</mat-header-cell>
          <mat-cell *matCellDef="let client" fxFlex.gt-xs="">{{ displayLocation(client) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="phoneNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="15">Phone Number(s)</mat-header-cell>
          <mat-cell *matCellDef="let client" fxFlex.gt-xs="15">{{ client.phoneNumbers && client.phoneNumbers.length ? displayPhoneNumbers(client.phoneNumbers) : '' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="15">Type</mat-header-cell>
          <mat-cell *matCellDef="let client" fxFlex.xs="30" fxFlex.gt-xs="15">{{ client.type === 'ATI' ? 'ATI' : client.type | titlecase }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="marginType">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="15">Margin Type</mat-header-cell>
          <mat-cell *matCellDef="let client" fxFlex.gt-xs="15">{{ client.marginType | titlecase }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="managingPod">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="10">Managing Pod</mat-header-cell>
          <mat-cell *matCellDef="let client" fxFlex.gt-xs="10">{{ client.managingPod | replace | titlecase }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="10">Status</mat-header-cell>
          <mat-cell *matCellDef="let client" fxFlex.gt-xs="10">{{ client.status | titlecase }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row (click)="selectClient(client)" *matRowDef="let client; columns: columnsToDisplay"></mat-row>
      </mat-table>
      <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50, 100]" (page)="handlePageChange()"></mat-paginator>
    </div>
</div>
