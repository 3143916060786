import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { NgxMatIntlTelInputComponent } from '@advance-trading/ngx-mat-intl-tel-input';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { filter, map, mergeMap, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AuthService, Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommonValidators } from '@advance-trading/angular-common-services';
import { ClientService, UserService } from '@advance-trading/angular-ops-data';
import { AccountService } from '../services/account-service';
import { Account, Client, MarketDataFrequency, PhoneNumberType, User, UserType } from '@advance-trading/ops-data-lib';

interface Auth0Group {
  _id?: string;
  name?: string;
  description?: string;
}
const ALL_USERS_ADMIN_ROLE = 'UserAdmin';
const CLIENT_USER_ADMIN_ROLE = 'ClientUserAdmin';

@Component({
  selector: 'atom-user',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit, OnDestroy {
  profileForm = new FormGroup({
    firstName: new FormControl('', [
      Validators.required,
      Validators.maxLength(100)]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.maxLength(100)]),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.maxLength(200)]),
    mobileNumber: new FormControl('', Validators.required),
    officeNumber: new FormControl(''),
    tollFreeNumber: new FormControl(''),
    faxNumber: new FormControl(''),
    group: new FormControl(),
    account: new FormControl(),
    broker: new FormControl(),
    status: new FormControl('', Validators.required),
    marketDataFrequency: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    atomEnabled: new FormControl(),
    hmsEnabled: new FormControl(),
    prosperEnabled: new FormControl(),
    atiMobileEnabled: new FormControl(),
    salesforceEnabled: new FormControl(),
    client: new FormControl('', [Validators.required, CommonValidators.objectValidator]),
    brokerActivationDate: new FormControl()
  });

  adminAccounts: string[];
  adminBrokers: User[];
  userGroups: Auth0Group[];
  userAccounts: string[];
  userBrokers: User[];
  user$: Observable<User>;
  user: User;
  filteredClients$: Observable<Client[]>;
  filteredGroups$: Observable<Auth0Group[]>;
  filteredAccounts$: Observable<string[]>;
  filteredBrokers$: Observable<User[]>;
  editMode = false;
  updateComplete = true;
  errorMessage: string;
  addOnBlur = false;
  separatorKeyCodes: number[] = [ENTER, SPACE];

  private adminUser: User;
  private clients: Client[];
  private adminClient: Client;
  private userClient: Client;
  private adminGroups: Auth0Group[];
  private originalUserGroups: Auth0Group[]; // Master copy to revert uncommited changes
  private unsubscribe$: Subject<void> = new Subject<void>();

  private removeGroupIdList: string[] = [];
  private addGroupIdList: string[] = [];

  @ViewChild('groupauto', { static: false }) private groupMatAuto: MatAutocomplete;
  @ViewChild('groupInput', { static: false }) private groupInput: ElementRef<HTMLInputElement>;
  @ViewChild('accountInput', { static: false }) private accountInput: ElementRef<HTMLInputElement>;
  @ViewChild('brokerauto', { static: false }) private brokerMatAuto: MatAutocomplete;
  @ViewChild('brokerInput', { static: false }) private brokerInput: ElementRef<HTMLInputElement>;
  @ViewChild('mobileNumber', { static: false }) mobileNumber: NgxMatIntlTelInputComponent;
  @ViewChild('officeNumber', { static: false }) officeNumber: NgxMatIntlTelInputComponent;
  @ViewChild('tollFreeNumber', { static: false }) tollFreeNumber: NgxMatIntlTelInputComponent;
  @ViewChild('faxNumber', { static: false }) faxNumber: NgxMatIntlTelInputComponent;

  constructor(
    public snackBar: MatSnackBar,
    private accountService: AccountService,
    private auth0Service: Auth0AuthzService,
    private authService: AuthService,
    private userService: UserService,
    private clientService: ClientService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.profileForm.disable();

    // Fetch the groups that logged in user can administer
    if (this.userCanAdminister()) {
      this.fetchAdminGroups();
    } else {
      this.adminGroups = [];
    }

    // Get info on the user to change
    this.fetchUserAndClient();

    // Setup the autocomplete filter for Clients
    this.filteredClients$ = this.profileForm.get('client').valueChanges
      .pipe(
        startWith<string | Client>(''),
        filter(value => typeof value === 'string'),
        map((clientName: string) => {
          if (!this.clients) {
            return [];
          } else if (clientName) {
            return this.clients.filter(client => client.name.toLowerCase().includes(clientName.toLowerCase()));
          } else {
            return this.clients;
          }
        })
      );

    // Setup the autocomplete filter for Groups
    this.filteredGroups$ = this.profileForm.get('group').valueChanges
      .pipe(
        startWith<string | Auth0Group>(''),
        filter(value => typeof value === 'string'),
        map((groupName: string) => {
          if (!this.adminGroups) {
            return [];
          } else if (groupName) {
            return this.adminGroups.filter(group => {
              return group.name.toLowerCase().includes(groupName.toLowerCase()) &&
                !this.userGroups.find((value) => group.name === value.name);
            });
          } else {
            return this.adminGroups.filter(group => !this.userGroups.find((value) => group.name === value.name));
          }
        })
      );

    // Setup the autocomplete filter for Accounts
    this.filteredAccounts$ = this.profileForm.get('account').valueChanges
      .pipe(
        startWith<string>(''),
        map(name => {
          if (!this.adminAccounts) {
            return [];
          } else if (name) {
            return this.adminAccounts.filter(account => {
              return account.toLowerCase().includes(name.toLowerCase()) &&
                !this.userAccounts.find(value => account === value);
            });
          } else {
            return this.adminAccounts.filter(account => !this.userAccounts.find(value => account === value));
          }
        })
      );

    this.filteredBrokers$ = this.profileForm.get('broker').valueChanges
      .pipe(
        startWith<string | User>(''),
        filter(value => typeof value === 'string'),
        map((brokerName: string) => {
          if (!this.adminBrokers) {
            return [];
          } else if (brokerName) {
            return this.adminBrokers.filter(broker => {
              return this.matchUserName(brokerName, broker) &&
                !this.userBrokers.find(value => this.matchUserName(brokerName, value));
            });
          } else {
            return this.adminBrokers.filter(broker => !this.userBrokers.find(value => broker.docId === value.docId));
          }
        })
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('validatePhoneNumber')) {
      return 'Invalid phone number';
    } else if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('email')) {
      return 'Invalid email';
    }
    return 'Unknown error';
  }

  getMarketDataFrequencyDisplayText(value: MarketDataFrequency) {
    switch (value) {
      case MarketDataFrequency.NONE:
        return 'None';
      case MarketDataFrequency.ON_DEMAND:
        return 'On-Demand';
      case MarketDataFrequency.REALTIME:
        return 'Real-Time';
      case MarketDataFrequency.DELAY_10:
        return '10-Minute-Delay';
      default:
        return 'None';
    }
  }

  clearPhoneNumber(control: FormControl, input: NgxMatIntlTelInputComponent) {
    control.setValue(undefined);
    input.phoneNumber = undefined;
    this.profileForm.markAsDirty();
  }

  displayClient(client?: Client) {
    if (client) {
      return client.physicalAddress ? `${client.name} (${client.physicalAddress.city}, ${client.physicalAddress.region})` : client.name;
    }
    return '';
  }

  displayGroup(group?: Auth0Group) {
    return group ? group.name : '';
  }

  displayBroker(broker?: User) {
    return broker ? `${broker.firstName} ${broker.lastName}` : '';
  }

  displayPhoneNumber(numberType: string) {
    if (numberType === 'mobile' && this.mobileNumber && this.mobileNumber.numberInstance) {
      return this.mobileNumber.numberInstance.formatInternational();
    } else if (numberType === 'office' && this.officeNumber && this.officeNumber.numberInstance) {
      return this.officeNumber.numberInstance.formatInternational();
    } else if (numberType === 'tollfree' && this.tollFreeNumber && this.tollFreeNumber.numberInstance) {
      return this.tollFreeNumber.numberInstance.formatInternational();
    } else if (numberType === 'fax' && this.faxNumber && this.faxNumber.numberInstance) {
      return this.faxNumber.numberInstance.formatInternational();
    } else {
      return '-';
    }
  }

  updateUser() {
    this.updateComplete = false;
    // Update fields in user
    const formValues = this.profileForm.getRawValue();
    this.user.firstName = formValues.firstName;
    this.user.lastName = formValues.lastName;
    this.user.email = formValues.email;
    this.updatePhoneNumbers(formValues);
    this.user.status = formValues.status;
    this.user.marketDataFrequency = formValues.marketDataFrequency;
    this.user.type = formValues.type;
    this.user.atomEnabled = formValues.atomEnabled;
    this.user.hmsEnabled = formValues.hmsEnabled;
    this.user.prosperEnabled = formValues.prosperEnabled;
    this.user.atiMobileEnabled = formValues.atiMobileEnabled;
    this.user.salesforceEnabled = formValues.salesforceEnabled;
    if (this.user.salesforceEnabled === undefined) {
      this.user.salesforceEnabled = false;
    }
    this.user.clientDocId = formValues.client.docId;
    this.user.accounts = this.userAccounts;

    if (this.user.isBroker) {
      const actDate = new Date(formValues.brokerActivationDate).toISOString();
      this.user.brokerActivationDate = actDate;
    }
    // If the user type is set to customer, set the brokers array to []
    if (this.user.type === UserType.CUSTOMER) {
      this.user.brokers = [];
    } else {
      this.user.brokers = this.userBrokers.map(user => user.docId);
    }

    // Reset this.originalUserGroups to this.userGroups after update
    this.originalUserGroups = this.userGroups;

    // TODO need to chain together all these calls and understand how to handle errors from one or both of these updates
    if (this.addGroupIdList.length > 0) {
      of(this.addGroupIdList)
        .pipe(
          mergeMap(
            groupIds => forkJoin(groupIds.map(groupId => this.auth0Service.addUserToGroup(groupId, this.user.authId)))
          ),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((response) => {
          console.log('Group add successful: ' + JSON.stringify(response));
        }, err => {
          console.error('Group add failed: ' + JSON.stringify(err));
        });
    }

    if (this.removeGroupIdList.length > 0) {
      of(this.removeGroupIdList)
        .pipe(
          mergeMap(
            groupIds => forkJoin(groupIds.map(groupId => this.auth0Service.removeUserFromGroup(groupId, this.user.authId)))
          ),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((response) => {
          console.log('Group remove successful: ' + JSON.stringify(response));
        }, err => {
          console.error('Group remove failed: ' + JSON.stringify(err));
        });
    }

    // Update the User object in Firestore
    this.userService.updateUser(this.user, this.userOnlyHasClientAdmin(), this.authService.accessToken)
      .then(response => {
        console.log('User successfully updated');
        this.updateComplete = true;
        this.setEditMode(false);
        this.openSnackBar('User successfully updated', 'DISMISS', true);
      })
      .catch(err => {
        this.updateComplete = true;
        console.error('User update failed: ' + JSON.stringify(err));
        let errorMessage = '';
        switch (err.code) {
          case 'permission-denied':
            errorMessage = 'Insufficient permissions';
            break;
          default:
            errorMessage = 'Unknown error occurred';
        }
        this.openSnackBar('User update failed: ' + errorMessage, 'DISMISS', false);
      });
  }

  // Determine if the logged in user can administer the selected user
  userCanAdminister(): boolean {
    return this.userHasUserAdmin() ||
      this.userOnlyHasClientAdmin();
  }

  userHasUserAdmin(): boolean {
    return this.auth0Service.currentUserHasRole(ALL_USERS_ADMIN_ROLE);
  }

  userIsBroker(): boolean {
    if (this.user) {
      if (this.user.isBroker) {
        return true;
      }
    }
    return false;
  }

  isSelf(): boolean {
    return this.user && this.user.docId === this.authService.userProfile.app_metadata.firestoreDocId;
  }

  setEditMode(mode) {
    this.editMode = mode;
    if (this.editMode) {
      this.profileForm.enable();
      if (this.isSelf() && !this.userCanAdminister()) {
        this.profileForm.get('client').disable();
        this.profileForm.get('type').disable();
        this.profileForm.get('marketDataFrequency').disable();
      }
    } else {
      this.profileForm.disable();
      this.setupUserForm();
      this.userGroups = this.originalUserGroups;
    }
  }

  groupRemovable(groupName: string) {
    const removable = this.adminGroups && this.adminGroups.filter(group => group.name === groupName).length > 0;
    return this.editMode && removable && this.userCanAdminister();
  }

  accountRemovable(accountNum: string) {
    const removable = this.adminAccounts && this.adminAccounts.filter(account => account === accountNum).length > 0;
    return this.editMode && removable && this.userCanAdminister();
  }

  brokerRemovable(brokerDocId: string) {
    const removable = this.adminBrokers && this.adminBrokers.filter(broker => broker.docId === brokerDocId).length > 0;
    return this.editMode && removable && this.userCanAdminister();
  }

  addGroup(event: MatChipInputEvent) {
    let group: Auth0Group = this.profileForm.get('group').value;
    if (typeof group === 'string') {
      const matOption = this.groupMatAuto.options.find(value => value.value.name === group);
      group = matOption ? matOption.value : undefined;
    }
    if (!group) {
      return;
    }
    this.userGroups = this.userGroups.filter(removeGroup => removeGroup._id !== group._id);
    this.userGroups.push(group);
    // Reset the group value to ensure we get a dirty form
    this.groupInput.nativeElement.value = '';
    this.profileForm.get('group').setValue('');

    if (this.removeGroupIdList.includes(group._id)) {
      this.removeGroupIdList = this.removeGroupIdList.filter(remGroupId => remGroupId !== group._id);
    } else if (!this.addGroupIdList.includes(group._id)) {
      this.addGroupIdList.push(group._id);
    }
  }

  removeGroup(group: Auth0Group) {
    this.userGroups = this.userGroups.filter(removeGroup => removeGroup._id !== group._id);
    // Reset the group value to ensure we get a dirty form
    this.profileForm.get('group').markAsDirty();

    if (this.addGroupIdList.includes(group._id)) {
      this.addGroupIdList = this.addGroupIdList.filter(addGroupId => addGroupId !== group._id);
    } else if (!this.removeGroupIdList.includes(group._id)) {
      this.removeGroupIdList.push(group._id);
    }
  }

  addAccount(event: MatChipInputEvent) {
    const account = this.profileForm.get('account').value;
    if (!account) { return; }

    this.userAccounts = this.userAccounts.filter(removeAccount => removeAccount !== account);
    this.userAccounts.push(account);
    // Reset the account value to ensure we get a dirty form
    this.accountInput.nativeElement.value = '';
    this.profileForm.get('account').setValue('');
  }

  removeAccount(account: string) {
    this.userAccounts = this.userAccounts.filter(removeAccount => removeAccount !== account);
    // Reset the account value to ensure we get a dirty form
    this.profileForm.get('account').markAsDirty();
  }

  addBroker(event: MatChipInputEvent) {
    let broker = this.profileForm.get('broker').value;
    if (typeof broker === 'string') {
      const matOption = this.brokerMatAuto.options.find(userBroker => this.matchUserName(broker, userBroker.value));
      broker = matOption ? matOption.value : undefined;
    }
    if (!broker) {
      return;
    }
    this.userBrokers = this.userBrokers.filter(removeBroker => removeBroker.docId !== broker.docId);
    this.userBrokers.push(broker);
    // Reset the group value to ensure we get a dirty form
    this.brokerInput.nativeElement.value = '';
    this.profileForm.get('broker').setValue('');
  }

  removeBroker(brokerDocId: string) {
    this.userBrokers = this.userBrokers.filter(removeBroker => removeBroker.docId !== brokerDocId);
    // Reset the broker value to ensure we get a dirty form
    this.profileForm.get('broker').markAsDirty();
  }

  appSettingsVisible() {
    return (this.profileForm.get('hmsEnabled').value || this.profileForm.get('atiMobileEnabled').value) &&
      (this.isSelf() || this.userCanAdminister());
  }

  handleSettingsUpdated(settingsUpdated: boolean) {
    if (settingsUpdated) {
      this.profileForm.markAsDirty();
    } else if (!this.editMode) {
      this.profileForm.markAsPristine();
    }
  }

  // Fetch the groups that can be assigned by the admin user
  private fetchAdminGroups() {
    const adminGroups$ = this.auth0Service.getGroups(true) as Observable<Auth0Group[]>;
    adminGroups$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(groups => {
        this.adminGroups = groups;
      });
  }

  private fetchUserAndClient() {
    const clients$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        // Fetch the User to change
        return this.user$ = this.userService.getUserByDocId(params.get('docId'));
      }),
      switchMap(user => {
        this.user = user;
        // Fetch the User to change Client
        if (this.user.clientDocId) {
          return this.clientService.getClient(user.clientDocId);
        } else {
          return of(undefined);
        }
      }),
      switchMap(client => {
        this.userClient = client;
        if (this.user.docId === this.authService.userProfile.app_metadata.firestoreDocId) {
          return of(this.user);
        }
        return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId);

      }),
      switchMap(loggedInUser => {
        this.adminUser = loggedInUser;
        if (this.userHasUserAdmin()) {
          // Fetch all the clients
          return this.clientService.getAllClients();
        } else if (this.user.docId === this.adminUser.docId) {
          // Don't fetch any additional clients
          this.adminClient = this.userClient;
          return of([this.adminClient]);
        } else if (this.userOnlyHasClientAdmin()) {
          // Fetch the client for the admin user
          return this.clientService.getClient(this.adminUser.clientDocId)
            .pipe(
              switchMap(adminClient => {
                this.adminClient = adminClient;
                return of([adminClient]);
              })
            );
        } else {
          // Don't fetch any additional clients
          this.adminClient = this.userClient;
          return of([this.adminClient]);
        }
      }),
      tap((clients: Client[]) => {
        this.setupUserForm();
        // do not retrieve groups for non-registered users (i.e. non-employee brokers)
        if (this.user.authId && this.userCanAdminister()) {
          const userGroups$: Observable<Auth0Group[]> = this.auth0Service.getUserGroups(this.user.authId) as Observable<Auth0Group[]>;
          userGroups$.pipe(takeUntil(this.unsubscribe$)).subscribe(groups => {
            this.userGroups = groups;
            this.originalUserGroups = groups;
          });
        }
        this.fetchAdminAccounts();
        this.fetchAdminBrokers();
        this.fetchUserBrokers();
      }),
      takeUntil(this.unsubscribe$)
    );
    clients$.subscribe(clients => {
      this.clients = clients;
    }, err => {
      this.errorMessage = 'This user either does not exist or you do not have permission to view the information.';
      console.error('Error occurred fetching user and client info: ' + JSON.stringify(err));
    });
  }

  private fetchAdminAccounts() {
    let accounts$: Observable<Account[]>;

    if (this.userHasUserAdmin()) {
      // TODO retreive all account numbers in the system
      accounts$ = this.accountService.getAllAccounts();
    } else if (this.userOnlyHasClientAdmin()) {
      // TODO retrieve all account numbers for the client
      accounts$ = this.accountService.getAllAccountsByClientId(this.adminClient.docId);
    } else {
      // set to the user's account numbers
      this.adminAccounts = this.user.accounts;
      return;
    }
    accounts$.pipe(
      map(accounts => {
        return accounts.map(account => account.number);
      })).subscribe(
        accounts => {
          this.adminAccounts = accounts;
        }, err => {
          console.error('Problem retrieving accounts: ' + JSON.stringify(err));
          this.adminAccounts = [];
        }
      );
  }

  private fetchAdminBrokers() {
    this.userService.getBrokerUsersByClientDocId(this.adminUser.clientDocId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(brokers => {
        this.adminBrokers = brokers;
      }, err => {
        this.adminBrokers = [];
        console.warn('Unable to fetch brokers: ' + JSON.stringify(err));
      });
  }

  private fetchUserBrokers() {
    if (this.user.brokers.length > 0) {
      combineLatest(this.user.brokers.map(brokerDocId => this.userService.getUserByDocId(brokerDocId)))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(users => {
          this.userBrokers = users;
        }, err => {
          this.userBrokers = [];
          console.warn('Unable to fetch the User objects for brokers: ' + JSON.stringify(err));
        });
    } else {
      this.userBrokers = [];
    }
  }

  private matchUserName(searchTerm: string, user: User) {
    const searchValue = searchTerm.toLowerCase();
    const first = user.firstName.toLowerCase();
    const last = user.lastName.toLowerCase();

    const fullName = `${first} ${last}`;
    const lastFirst = `${last} ${first}`;
    const lastCommaFirst = `${last}, ${first}`;
    return fullName.includes(searchValue) || lastFirst.includes(searchValue) || lastCommaFirst.includes(searchValue);
  }

  private userOnlyHasClientAdmin(): boolean {
    return !this.auth0Service.currentUserHasRole(ALL_USERS_ADMIN_ROLE) &&
      this.auth0Service.currentUserHasRole(CLIENT_USER_ADMIN_ROLE) &&
      this.user && this.adminUser &&
      (this.user.clientDocId === this.adminUser.clientDocId ||
        this.user.clientDocId === ''
      );
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  // Initialized the FormControls with values from Firestore
  // TODO consider whether we want to receive updates from Firestore in realtime; could be a problem for edits
  private setupUserForm() {
    this.profileForm.get('firstName').setValue(this.user.firstName);
    this.profileForm.get('lastName').setValue(this.user.lastName);
    this.profileForm.get('email').setValue(this.user.email);
    this.setupPhoneNumbers();
    this.profileForm.get('status').setValue(this.user.status);
    this.profileForm.get('marketDataFrequency').setValue(this.user.marketDataFrequency);
    this.profileForm.get('type').setValue(this.user.type);
    this.profileForm.get('atomEnabled').setValue(this.user.atomEnabled);
    this.profileForm.get('hmsEnabled').setValue(this.user.hmsEnabled);
    this.profileForm.get('prosperEnabled').setValue(this.user.prosperEnabled);
    this.profileForm.get('atiMobileEnabled').setValue(this.user.atiMobileEnabled);
    this.profileForm.get('salesforceEnabled').setValue(this.user.salesforceEnabled);
    this.profileForm.get('client').setValue(this.userClient);
    this.profileForm.get('group').setValue('');
    this.profileForm.get('account').setValue('');
    this.profileForm.get('broker').setValue('');
    if (this.user.isBroker) {
      this.profileForm.get('brokerActivationDate').setValue(this.user.brokerActivationDate);
    }
    this.userAccounts = this.user.accounts;

    // Reset the form to pristine so we only allow actual changes to be sent
    this.profileForm.markAsPristine();
    // Reset the group change tracking arrays
    this.addGroupIdList = [];
    this.removeGroupIdList = [];
  }

  private setupPhoneNumbers() {
    const mobileFromUser = this.user.phoneNumbers.find(phoneNumber => phoneNumber.type === PhoneNumberType.MOBILE);
    const officeFromUser = this.user.phoneNumbers.find(phoneNumber => phoneNumber.type === PhoneNumberType.OFFICE);
    const tollFreeFromUser = this.user.phoneNumbers.find(phoneNumber => phoneNumber.type === PhoneNumberType.TOLL_FREE);
    const faxFromUser = this.user.phoneNumbers.find(phoneNumber => phoneNumber.type === PhoneNumberType.FAX);

    if (mobileFromUser) {
      this.mobileNumber.phoneNumber = mobileFromUser.number;
      const mobileRaw = parsePhoneNumberFromString('+' + mobileFromUser.countryCode + mobileFromUser.number);
      this.mobileNumber.selectedCountry = this.mobileNumber.allCountries.find(
        country => country.iso2.toLowerCase() === mobileRaw.country.toLowerCase());
      this.mobileNumber.onPhoneNumberChange();
    } else {
      this.mobileNumber.reset();
      this.mobileNumber.onPhoneNumberChange();
    }

    if (officeFromUser) {
      this.officeNumber.phoneNumber = officeFromUser.number;
      const officeRaw = parsePhoneNumberFromString('+' + officeFromUser.countryCode + officeFromUser.number);
      this.officeNumber.selectedCountry = this.officeNumber.allCountries.find(
        country => country.iso2.toLowerCase() === officeRaw.country.toLowerCase());
      this.officeNumber.onPhoneNumberChange();
    } else {
      this.officeNumber.reset();
      this.officeNumber.onPhoneNumberChange();
    }

    if (tollFreeFromUser) {
      this.tollFreeNumber.phoneNumber = tollFreeFromUser.number;
      const tollFreeRaw = parsePhoneNumberFromString('+' + tollFreeFromUser.countryCode + tollFreeFromUser.number);
      this.tollFreeNumber.selectedCountry = this.tollFreeNumber.allCountries.find(
        country => country.iso2.toLowerCase() === tollFreeRaw.country.toLowerCase());
      this.tollFreeNumber.onPhoneNumberChange();
    } else {
      this.tollFreeNumber.reset();
      this.tollFreeNumber.onPhoneNumberChange();
    }

    if (faxFromUser) {
      this.faxNumber.phoneNumber = faxFromUser.number;
      const faxRaw = parsePhoneNumberFromString('+' + faxFromUser.countryCode + faxFromUser.number);
      this.faxNumber.selectedCountry = this.faxNumber.allCountries.find(
        country => country.iso2.toLowerCase() === faxRaw.country.toLowerCase());
      this.faxNumber.onPhoneNumberChange();
    } else {
      this.faxNumber.reset();
      this.faxNumber.onPhoneNumberChange();
    }
  }

  private updatePhoneNumbers(formValues) {
    this.user.phoneNumbers = [];
    if (formValues.mobileNumber) {
      this.user.phoneNumbers.push({
        countryCode: this.mobileNumber.selectedCountry.dialCode,
        number: String(this.mobileNumber.phoneNumber),
        type: PhoneNumberType.MOBILE
      });
    }
    if (formValues.officeNumber) {
      this.user.phoneNumbers.push({
        countryCode: this.officeNumber.selectedCountry.dialCode,
        number: String(this.officeNumber.phoneNumber),
        type: PhoneNumberType.OFFICE
      });
    }
    if (formValues.tollFreeNumber) {
      this.user.phoneNumbers.push({
        countryCode: this.tollFreeNumber.selectedCountry.dialCode,
        number: String(this.tollFreeNumber.phoneNumber),
        type: PhoneNumberType.TOLL_FREE
      });
    }
    if (formValues.faxNumber) {
      this.user.phoneNumbers.push({
        countryCode: this.faxNumber.selectedCountry.dialCode,
        number: String(this.faxNumber.phoneNumber),
        type: PhoneNumberType.FAX
      });
    }
  }
}
