import { FormControl } from '@angular/forms';

export class BankValidators {

  /**
   * Validates the routing number or swift code for a bank.
   */
  static routingSwiftNumberValidator(formControl: FormControl): {[key: string]: boolean} | null {
    const routingNumber = formControl.value;
    const swiftCodeRegex = /^([A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?)$/;
    const routingNumberRegex = /^([0-9]{9})$/;

    if (!routingNumber) {
      return null;
    }

    switch (routingNumber.length) {
      case 9:
        return routingNumberRegex.test(routingNumber) ? null : { invalidRoutingNumber: true };
      case 8:
      case 11:
        return swiftCodeRegex.test(routingNumber) ? null : { invalidSwiftCode: true };
      default:
        return { invalidRoutingOrSwift: true };
    }
  }
}
