import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { parsePhoneNumber } from 'libphonenumber-js';
import { NgxMatIntlTelInputComponent } from '@advance-trading/ngx-mat-intl-tel-input';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { BankService, BankAccountService } from '@advance-trading/angular-ops-data';
import { Bank, Client, PhoneNumberType, BankAccount } from '@advance-trading/ops-data-lib';
import { ClientService } from '../../services/client-service';
import { ConfirmationDialogComponent } from '@advance-trading/angular-common-services';

import { BankValidators } from './bank.validator';

const UPDATE_ROLE = 'AccountAdmin';

@Component({
  selector: 'atom-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.css']
})
export class BankComponent implements OnInit, OnDestroy {

  clientBankForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(100)
    ]),
    routingNumber: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(11),
      BankValidators.routingSwiftNumberValidator
    ]),
    phoneNumberNumber: new FormControl(''),
    phoneNumberExtension: new FormControl('', [
      Validators.maxLength(9)
    ]),
    address: new FormGroup({
      street1: new FormControl('', [
        Validators.required,
        Validators.maxLength(200)
      ]),
      street2: new FormControl('', [
        Validators.maxLength(200)
      ]),
      city: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      region: new FormControl('', [
        Validators.required
      ]),
      postalCode: new FormControl('', [
        Validators.required
      ]),
      country: new FormControl('', [
        Validators.required
      ]),
    })
  });

  client$: Observable<Client>;
  bank$: Observable<Bank>;
  bankAccounts$: Observable<BankAccount[]>;
  clientDocId: string;
  bank: Bank; // Used to store original from Firestore

  errorMessage: string;
  updateComplete = true;
  editMode = false;
  createMode = false;
  canUpdate = false;

  // Indicators for hide/show
  enableAddress = false;
  deleteAddress = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  @ViewChild('phoneNumber', { static: false }) phoneNumber: NgxMatIntlTelInputComponent;

  constructor(
    private auth0Service: Auth0AuthzService,
    private clientService: ClientService,
    private bankAccountService: BankAccountService,
    private bankService: BankService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.canUpdate = this.auth0Service.currentUserHasRole(UPDATE_ROLE);

    this.route.paramMap.pipe(takeUntil(this.unsubscribe$)).subscribe((params: ParamMap) => {
      this.editMode = !params.has('bankDocId') || params.get('bankDocId') === 'new';
      this.createMode = !params.has('bankDocId') || params.get('bankDocId') === 'new';
      if (params.get('bankDocId') !== 'new') {
        this.clientBankForm.disable();
      } else {
        // Disable the address form initially even in createMode
        this.clientBankForm.get('address').disable();
      }

      if (params.get('bankDocId') === 'new' && !this.canUpdate) {
        this.errorMessage = 'You do not have permission to create a new Bank for this Client.';
      }
    });

    this.client$ = this.route.paramMap.pipe(
      filter((params: ParamMap) => params.has('clientDocId')),
      switchMap((params: ParamMap) => {
        this.clientDocId = params.get('clientDocId');
        return this.clientService.getClient(params.get('clientDocId'));
      })
    );

    this.bank$ = this.route.paramMap.pipe(
      filter((params: ParamMap) => params.has('bankDocId') && params.get('bankDocId') !== 'new'),
      switchMap((params: ParamMap) => {
        return this.bankService.getBankByDocId(params.get('clientDocId'), params.get('bankDocId'));
      }),
      tap(newBank => this.handleBankObject(newBank))
    );

    this.bankAccounts$ = this.route.paramMap.pipe(
      filter((params: ParamMap) => params.has('bankDocId') && params.has('clientDocId')),
      switchMap((params: ParamMap) => {
        return this.bankAccountService.getAllBankAccountsByBankDocId(params.get('clientDocId'), params.get('bankDocId'));
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setEditMode(mode) {
    this.editMode = mode;
    if (this.editMode) {
      this.clientBankForm.enable();
      if (!this.bank.address && !this.enableAddress) {
        this.clientBankForm.get('address').disable();
      }
    } else { // Abandon an update
      if (!this.clientBankForm.get('phoneNumberNumber').value) {
        this.phoneNumber.reset();
        this.phoneNumber.onPhoneNumberChange();
      }
      this.clientBankForm.disable();
      this.deleteAddress = false;
      this.handleBankObject(this.bank);
    }
  }

  reset() {
    this.clientBankForm.reset();
  }

  async saveForm() {
    this.updateComplete = false;
    const isNewBank = !this.bank;
    const bank = Object.assign(isNewBank ? new Bank() : this.bank, this.clientBankForm.value);

    if (this.phoneNumber.phoneNumber) {
      const cleanPhoneNumber = this.phoneNumber.phoneNumber || '';
      bank.phoneNumber = {
        countryCode: this.phoneNumber.selectedCountry.dialCode || '',
        number: cleanPhoneNumber.replace(/[^0-9]/g, ''),
        type: PhoneNumberType.OFFICE,
        extension: this.clientBankForm.get('phoneNumberExtension').value || ''
      };
    } else {
      delete bank.phoneNumber;
    }

    if (this.deleteAddress) {
      delete bank.address;
      this.deleteAddress = false;
    }

    // Remove the fields that don't match the Bank interface
    delete bank.phoneNumberExtension;
    delete bank.phoneNumberNumber;

    if (isNewBank) {
      this.bankService.createBank(this.clientDocId, bank)
        .then(response => {
          console.log('Bank successfully created');
          this.updateComplete = true;
          this.router.navigate(['../', bank.docId], { relativeTo: this.route, replaceUrl: true });
          this.openSnackBar('Bank successfully created', 'DISMISS', true);
        })
        .catch(err => {
          this.updateComplete = true;
          console.error('Bank creation failed: ' + JSON.stringify(err));
          let errorMessage = '';
          switch (err.code) {
            case 'permission-denied':
              errorMessage = 'Insufficient permissions';
              break;
            default:
              errorMessage = 'Unknown error occurred';
          }
          this.openSnackBar('Bank creation failed: ' + errorMessage, 'DISMISS', false);
        });
    } else {
      this.bankService.updateBank(this.clientDocId, bank)
        .then(response => {
          console.log('Bank successfully updated');
          this.updateComplete = true;
          this.setEditMode(false);
          this.openSnackBar('Bank successfully updated', 'DISMISS', true);
        })
        .catch(err => {
          this.updateComplete = true;
          console.error('Bank update failed: ' + JSON.stringify(err));
          let errorMessage = '';
          switch (err.code) {
            case 'permission-denied':
              errorMessage = 'Insufficient permissions';
              break;
            default:
              errorMessage = 'Unknown error occurred';
          }
          this.openSnackBar('Bank update failed: ' + errorMessage, 'DISMISS', false);
        });
    }
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('validatePhoneNumber')) {
      return 'Invalid phone number';
    } else if (control.hasError('minlength')) {
      return 'Does not meet minimum length';
    } else if (control.hasError('maxlength')) {
      return 'Input exceeds maximum length';
    } else if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('invalidRoutingNumber')) {
      return 'Invalid Routing Number';
    } else if (control.hasError('invalidSwiftCode')) {
      return 'Invalid Swift Code/BIC';
    } else if (control.hasError('invalidRoutingOrSwift')) {
      return 'Invalid Routing Number/Swift Code Length';
    }
    return 'Unknown error';
  }

  clearPhoneNumber(control: FormControl, input: NgxMatIntlTelInputComponent) {
    control.setValue('');
    input.phoneNumber = undefined;
    this.clientBankForm.markAsDirty();
  }

  toggleAddressForm() {
    this.enableAddress = !this.enableAddress;

    if (this.enableAddress) {
      this.clientBankForm.get('address').enable();
    } else {
      this.clientBankForm.get('address').disable();
    }
  }

  clearAddress() {
    this.clientBankForm.get('address').reset();
    this.toggleAddressForm();
    this.deleteAddress = true;
    this.clientBankForm.markAsDirty();
  }

  displayPhoneNumber() {
    if (this.phoneNumber && this.phoneNumber.numberInstance) {
      return this.phoneNumber.numberInstance.formatInternational();
    }
    return '-';
  }

  deleteBank(bank: Bank) {
    const title = 'Delete Bank';
    const message = 'This will permanently delete this bank and all associated bank accounts, do you want to continue?';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { title, message },
      height: '250px',
      width: '400px',
    });

    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(confirm => {
      if (!confirm) {
        return;
      } else {
        this.router.navigate(['clients', this.clientDocId]).then(() => {
          this.bankService.deleteBank(this.clientDocId, bank);
        });
      }
    });
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private handleBankObject(bank: Bank) {
    this.bank = Object.assign({}, bank);
    if (bank.address) {
      this.enableAddress = true;
    }

    this.clientBankForm.reset();
    // Check necessary to prevent any race condition in our test suite
    if (this.phoneNumber) {
      this.phoneNumber.phoneNumber = '';
    }
    this.clientBankForm.patchValue(bank);

    // Set the street2 to empty string if it is null or undefined
    if (!this.clientBankForm.get('address').get('street2').value) {
      this.clientBankForm.get('address').get('street2').setValue('');
    }

    // Handle phone number
    if (bank.phoneNumber) {
      let countryCodeWithPlus = '';
      if (bank.phoneNumber.countryCode) {
        countryCodeWithPlus = '+' + bank.phoneNumber.countryCode;
      }
      try {
        const numberRaw = parsePhoneNumber(countryCodeWithPlus + bank.phoneNumber.number);
        this.phoneNumber.selectedCountry = this.phoneNumber.allCountries.find(
          country => country.iso2.toLowerCase() === numberRaw.country.toLowerCase());
        this.phoneNumber.writeValue(countryCodeWithPlus + bank.phoneNumber.number);
        this.clientBankForm.get('phoneNumberNumber').setValue(countryCodeWithPlus + bank.phoneNumber.number);
      } catch (err) {
        this.phoneNumber.phoneNumber = bank.phoneNumber.number;
        this.phoneNumber.onPhoneNumberChange();
        this.clientBankForm.get('phoneNumberNumber').markAsTouched();
        console.log(`Error parsing phone number: ${err.message}`);
      }
      this.clientBankForm.get('phoneNumberExtension').setValue(bank.phoneNumber.extension);
    }
    // Mark form pristine after setting values
    this.clientBankForm.markAsPristine();
  }
}
