import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { Position, CommodityMap, TransactionSecurityType } from '@advance-trading/ops-data-lib';
import { OperationsDataService } from '@advance-trading/angular-ops-data';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ObservableDataSource, StorageService } from '@advance-trading/angular-common-services';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ExportService } from '../services/export.service';
import { CommodityDisplayUtility } from '../utilities/commodity-display-utility';

const maxRows = 20000;
const PAGE_SIZE_KEY = 'atom.positionsPageSize';

@Component({
  selector: 'atom-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.css']
})
export class PositionsComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {

  @Input() initialTableState: { [key: string]: string | number };
  @Input() selectedPositions$: Observable<Position[]>;

  columnsToDisplay = [];
  dataSource = new ObservableDataSource<Position>();
  errorMessage: string;
  commodityMap: CommodityMap;
  exportable = false;
  filterValue = new FormControl();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  @Output() positionListChange: EventEmitter<any> = new EventEmitter();
  @Output() positionSearchError: EventEmitter<string> = new EventEmitter();
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();

  private tableState: { [key: string]: string | number } = {};
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private operationsDataService: OperationsDataService,
    private snackBar: MatSnackBar,
    public exportService: ExportService,
    private router: Router,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.isSearching.emit(true);

    this.filterValue.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((filter: string) => {
      if (filter) {
        this.tableState.filterValue = filter.trim();
        this.positionListChange.emit(this.tableState);
      } else if (this.tableState.filterValue) {
        delete this.tableState.filterValue;
        this.positionListChange.emit(this.tableState);
      }
    });
    this.breakpointObserver.observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.matches) {
          this.columnsToDisplay = [
            'accountNumber', 'commodityId', 'contractYearMonth', 'totalQuantity'
          ];
        } else {
          this.columnsToDisplay = [
            'salesCode', 'accountNumber', 'clientName', 'side', 'totalQuantity', 'contractYearMonth', 'strikePrice', 'commodityId', 'securitySubType'
          ];
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = this.storageService.localStorage.get(PAGE_SIZE_KEY).value() || 10;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (position, column) => {
      switch (column) {
        case 'commodityId':
          return this.getCommodityDisplayName(position);
        default:
          return position[column];
      }
    };
    if (this.filter) {
      this.filter.nativeElement.focus();
    }
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialTableState'] && changes['selectedPositions$']) {
      this.tableState = Object.assign({}, this.initialTableState);

      // detect MatSort and MatPaginator so it is defined
      this.changeDetector.detectChanges();

      const sortDir = this.initialTableState.sortDir as SortDirection;
      const sortColName = this.initialTableState.sortColName as string;
      if (sortDir && sortColName) {
        this.sort.direction = sortDir;
        this.sort.active = sortColName;
      }
      if (this.initialTableState.filterValue) {
        this.filterValue.setValue(this.initialTableState.filterValue);
        this.applyFilter(this.filterValue.value);
      }

      // initialize table
      this.dataSource.data$ = this.operationsDataService.getCommodityMap().pipe(
        switchMap((doc: CommodityMap) => {
          this.commodityMap = doc;
          return this.selectedPositions$;
        })
      ).pipe(
        tap((positions => {
          this.exportable = !!positions.length;
          this.isSearching.emit(false);

          // initialize pagination state when the datasource exist
          const pageIndex = this.initialTableState.pageIndex as number;

          if (pageIndex) {
            this.paginator.pageIndex = pageIndex;
          }

          if (positions.length === 0) {
            this.openSnackBar('There are no positions that match this search', 'DISMISS', true);
          } else if (positions.length === maxRows) {
            this.openSnackBar(`Reached maximum number of rows (${maxRows.toLocaleString()})`, 'DISMISS', true);
          } else {
            catchError(err => {
              this.errorMessage = 'Error retrieving positions; please try again later';
              this.positionSearchError.emit(this.errorMessage);
              this.isSearching.emit(false);
              console.error(`Error retrieving positions: ${err}`);
              return of([]);
            });
          }
        })),
        map(positions => {
          return [...positions].sort((a, b) =>
            new Date(b.lastUpdatedTimestamp).getTime() - new Date(a.lastUpdatedTimestamp).getTime());
        })
      );
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  getCommodityDisplayName(position: Position) {
    return CommodityDisplayUtility.getCommodityDisplayNameOnPosition(position, this.commodityMap);
  }

  handleSortChange() {
    this.tableState.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.tableState.sortColName = this.tableState.sortDir ? this.sort.active : undefined;
    this.positionListChange.emit(this.tableState);
  }

  handlePageChange() {
    this.storageService.localStorage.set(PAGE_SIZE_KEY, this.paginator.pageSize);
    this.tableState.pageIndex = this.paginator.pageIndex;
    this.positionListChange.emit(this.tableState);
  }

  selectPosition(position: Position) {
    this.router.navigate(['/accounts', position.accountDocId, 'positions', position.docId]);
  }
}

// export class PositionsComponent implements AfterViewInit, OnInit {

//   positionsForm: FormGroup = this.formBuilder.group({
//     client: this.formBuilder.control({value: '', disabled: false}),
//     account: this.formBuilder.control({value: '', disabled: false})
//   });

//   columnsToDisplay = [];
//   filteredBy = '';
//   account$: Observable<Account>;
//   dataSource = new ObservableDataSource<Position>();
//   activeAccounts$: Observable<Account[]>;
//   filteredAccounts$: Observable<Account[]>;
//   activeClients$: Observable<Client[]>;
//   filteredClients$: Observable<Client[]>;
//   isLoading = true;
//   errorMessage: string;
//   showData = false;

//   @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
//   @ViewChild(MatSort, { static: false }) sort: MatSort;
//   @ViewChild('filter', { static: false }) filter: ElementRef;

//   constructor(
//     private route: ActivatedRoute,
//     private accountService: AccountService,
//     private clientService: ClientService,
//     private positionService: PositionService,
//     private changeDetector: ChangeDetectorRef,
//     private breakpointObserver: BreakpointObserver,
//     private formBuilder: FormBuilder
//   ) { }

//   ngOnInit() {
//     this.prepForAccountSelection();
//     this.prepForClientSelection();

//     this.dataSource.data$ = this.route.queryParamMap
//       .pipe(
//         switchMap((queryParams: ParamMap) => {

//           this.filteredBy = '';
//           if (queryParams.get('accountDocId')) {
//             this.showData = true;
//             this.breakpointObserver.observe([Breakpoints.XSmall])
//               .subscribe(state => {
//                 if (state.matches) {
//                   this.columnsToDisplay = [
//                     'accountNumber', 'commodityId', 'totalQuantity', 'strikePrice'
//                   ];
//                 } else {
//                   this.columnsToDisplay = [
//                     'accountNumber', 'commodityId', 'contractYearMonth', 'totalQuantity', 'strikePrice'
//                   ];
//                 }
//               });
//             return this.accountService.getAccount(queryParams.get('accountDocId')).pipe(
//               switchMap(account => {
//                 this.filteredBy = `Account - ${account.name}`;
//                 return this.positionService.getAllPositionsByAccountDocId(account.docId);
//               })
//             );
//           } else if (queryParams.get('brokerCode')) {
//             this.showData = true;
//             this.filteredBy = `Broker Code - ${queryParams.get('brokerCode')}`;
//             this.breakpointObserver.observe([Breakpoints.XSmall])
//               .subscribe(state => {
//                 if (state.matches) {
//                   this.columnsToDisplay = [
//                     'commodityId', 'contractYearMonth', 'securitySubType', 'totalQuantity'
//                   ];
//                 } else {
//                   this.columnsToDisplay = [
//                     'commodityId', 'contractYearMonth', 'securitySubType', 'totalQuantity', 'strikePrice', 'accountNumber'
//                   ];
//                 }
//               });
//             return this.positionService.findAllPositionsByBrokerCode(queryParams.get('brokerCode'));
//           } else if (queryParams.get('clientDocId')) {
//             this.showData = true;
//             return this.clientService.getClient(queryParams.get('clientDocId')).pipe(
//               switchMap(client => {
//                 this.filteredBy = `Client - ${client.name}`;
//                 this.breakpointObserver.observe([Breakpoints.XSmall])
//                   .subscribe(state => {
//                     if (state.matches) {
//                       this.columnsToDisplay = [
//                         'accountNumber', 'commodityId', 'totalQuantity', 'strikePrice'
//                       ];
//                     } else {
//                       this.columnsToDisplay = [
//                         'accountNumber', 'commodityId', 'contractYearMonth', 'totalQuantity', 'strikePrice'
//                       ];
//                     }
//                   });
//                 return this.positionService.findAllPositionsByClientDocId(queryParams.get('clientDocId'));
//               })
//             );
//           } else {
//             return of([]);
//           }
//         }),
//         tap(positions => this.isLoading = false),
//         catchError(err => {
//           this.isLoading = false;
//           this.errorMessage = 'Error retrieving positions; please try again later';
//           console.error(`Error retrieving positions: ${err}`);
//           return of([]);
//         })
//       );
//   }

//   ngAfterViewInit() {
//     this.dataSource.paginator = this.paginator;
//     this.dataSource.sort = this.sort;
//     this.changeDetector.detectChanges();
//   }

//   // Grab correct data from Firestore for display after a field is enter
//   // and search button is pressed
//   displayData() {
//     this.showData = true;
//     if (this.positionsForm.get('account').value) {
//       const accountData = this.positionsForm.get('account').value;
//       this.filteredBy = `Account - ${accountData.name}`;
//       this.breakpointObserver.observe([Breakpoints.XSmall])
//         .subscribe(state => {
//           if (state.matches) {
//             this.columnsToDisplay = [
//               'accountNumber', 'commodityId', 'contractYearMonth', 'totalQuantity'
//             ];
//           } else {
//             this.columnsToDisplay = [
//               'accountNumber', 'commodityId', 'contractYearMonth', 'totalQuantity', 'strikePrice'
//             ];
//           }
//         });
//       this.dataSource.data$ = this.accountService.getAccount(accountData.docId).pipe(
//         switchMap(account => {
//           return this.positionService.getAllPositionsByAccountDocId(account.docId);
//         })
//       );
//     } else {
//       const clientData = this.positionsForm.get('client').value;
//       this.filteredBy = `Client - ${clientData.name}`;
//       this.breakpointObserver.observe([Breakpoints.XSmall])
//         .subscribe(state => {
//           if (state.matches) {
//             this.columnsToDisplay = [
//               'accountNumber', 'commodityId', 'contractYearMonth', 'totalQuantity'
//             ];
//           } else {
//             this.columnsToDisplay = [
//               'accountNumber', 'commodityId', 'contractYearMonth', 'totalQuantity', 'strikePrice'
//             ];
//           }
//         });
//       this.dataSource.data$ = this.clientService.getClient(clientData.docId).pipe(
//         switchMap(client => {
//           return this.positionService.findAllPositionsByClientDocId(client.docId);
//         })
//       );
//     }
//     this.resetFields();
//   }

//   /**
//    * Tooltip displaying each segment including the quantity, price, and date
//    */
//   getTooltip(position: Position) {

//     return Object.values(position.segments).reduce((accumulator, segmentEntry) => {
//       const segment = segmentEntry instanceof Array ? segmentEntry[0] : segmentEntry;

//       return `${accumulator}${this.getSegmentString(segment)}`;
//     }, `SEGMENTS\n\n`);
//   }

//   /**
//    * Displays name, city, and region for client autocomplete input field
//    */
//   displayClient(client?: Client) {
//     if (client) {
//       return client.physicalAddress ? `${client.name} (${client.physicalAddress.city}, ${client.physicalAddress.region})` : client.name;
//     }
//     return '';
//   }

//   /**
//    *
//    * Displays account name for account autocomplete input field
//    */
//   displayAccount(account?: Account) {
//     if (account) {
//       return account.name;
//     }
//     return '';
//   }

//   // Clears the value and disables client field and enables account field
//   accountFieldClicked() {
//     this.positionsForm.get('client').setValue('');
//     this.positionsForm.get('client').disable();
//     this.positionsForm.get('account').enable();
//   }

//   // Clears the value and disables account field and enables client field
//   clientFieldClicked() {
//     this.positionsForm.get('account').setValue('');
//     this.positionsForm.get('account').disable();
//     this.positionsForm.get('client').enable();
//   }



//   // ******* Client autocomplete *******

//   private prepForClientSelection() {
//     this.getActiveClients();
//     this.filterClients();
//   }

//   // Gets active clients from Firestore
//   private getActiveClients() {
//     if (!this.activeClients$) {
//       this.activeClients$ = this.clientService.getClientsByStatus(Status.ACTIVE)
//         .pipe(
//           shareReplay(1)
//         );
//     }
//   }

//   // Consumes a stream of input changes from the form to populate a list of matching autocomplete
//   // options for selecting a client
//   private filterClients() {
//     this.filteredClients$ = this.positionsForm.controls.client.valueChanges.pipe(
//       debounceTime(300),
//       distinctUntilChanged(),
//       startWith<string | Client>(''),
//       switchMap(term => typeof term === 'string' ? this.filterClientsBySearchTerm(term) : of([term]))
//     );
//   }

//   // Filters clients from Firestore using the user input search term
//   private filterClientsBySearchTerm(searchTerm: string): Observable<Client[]> {
//     return this.activeClients$.pipe(
//       map(clients => clients.filter(client => client.name.toLowerCase().includes(searchTerm.toLowerCase()))),
//     );
//   }

//   // ******* Account autocomplete *******

//   private prepForAccountSelection() {
//     this.getActiveAccounts();
//     this.filterAccounts();
//   }

//   // Get all active accounts from Firestore
//   private getActiveAccounts() {
//     if (!this.activeAccounts$) {
//       this.activeAccounts$ = this.accountService.getAllActiveAccounts()
//         .pipe(
//           shareReplay(1)
//         );
//     }
//   }

//   // Consumes a stream of input changes from the form to populate a list of matching autocomplete
//   // options for selecting an account
//   private filterAccounts() {
//     this.filteredAccounts$ = this.positionsForm.controls.account.valueChanges.pipe(
//       debounceTime(300),
//       distinctUntilChanged(),
//       startWith<string | Account>(''),
//       switchMap(term => typeof term === 'string' ? this.filterAccountBySearchTerm(term) : of([term])),
//     );
//   }

//   // Filters accounts from Firestore using the user input search term
//   private filterAccountBySearchTerm(searchTerm: string): Observable<Account[]> {
//     return this.activeAccounts$.pipe(
//       map(accounts => accounts.filter(account => account.name.toLowerCase().includes(searchTerm.toLowerCase())))
//     );
//   }

//   private getSegmentString(segment: PositionSegment) {
//     return `Quantity: ${segment.quantity}\nPrice: ${segment.tradePrice.toFixed(2)}\nDate: ${segment.tradeDate}\n\n`;
//   }

//   // Reset the text fields back to their original state
//   private resetFields() {
//     this.positionsForm.get('account').setValue('');
//     this.positionsForm.get('account').enable();
//     this.positionsForm.get('client').setValue('');
//     this.positionsForm.get('client').enable();
//   }
// }
