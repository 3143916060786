import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class AdjustmentQueries {

  static getAdjustmentsByAccounts = gql<any,any>`
  query getAdjustmentsByAccounts($accounts: [String!], $adjustmentQuery: AdjustmentQuery) {
    adjustmentsByAccounts(accounts: $accounts, adjustmentQuery: $adjustmentQuery) {
      docId,
      accountDocId,
      transactionDate,
      businessDate,
      officeCode,
      salesCode,
      accountNumber,
      commission,
      amount,
      clearingFee,
      exchangeFee,
      nfaFee,
      otherCharges,
      securityDescription
      linkedTradeDocIds
    }
  }
  `;

  static getAdjustmentsByBrokerCodes = gql<any,any>`
  query getAdjustmentsByBrokerCodes($brokerCodes: [String!], $adjustmentQuery: AdjustmentQuery) {
    adjustmentsByBrokerCodes(brokerCodes: $brokerCodes, adjustmentQuery: $adjustmentQuery) {
      docId,
      accountDocId,
      transactionDate,
      businessDate,
      officeCode,
      salesCode,
      accountNumber,
      commission,
      amount,
      clearingFee,
      exchangeFee,
      nfaFee,
      otherCharges,
      securityDescription
      linkedTradeDocIds
    }
  }
  `;
}
