<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <h6 *ngIf="userClient" class="client-brow">{{ userClient?.name | uppercase }}</h6>
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Users List</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="column">
      <h2 class="card-title">Users</h2>
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" #filter>
        <button mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <button *ngIf="exportable" aria-label="Export" id="export-users" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
      <mat-menu #exportMenu="matMenu">
        <button mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Users'))">Excel</button>
        <button mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Users'))">CSV</button>
        <button mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Users'))">JSON</button>
      </mat-menu>

      <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort class="mat-elevation-z0" (matSortChange)="handleSortChange()">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="75" fxFlex.gt-xs="25">Name</mat-header-cell>
          <mat-cell *matCellDef="let user" fxFlex.xs="75" fxFlex.gt-xs="25">{{ user.firstName }} {{ user.lastName }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="40">Email</mat-header-cell>
          <mat-cell *matCellDef="let user" fxFlex="40">{{ user.email }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="20">Type</mat-header-cell>
          <mat-cell *matCellDef="let user" fxFlex.gt-xs="20">{{ user.type | titlecase }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.gt-xs="15">Status</mat-header-cell>
          <mat-cell *matCellDef="let user" fxFlex.xs="25" fxFlex.gt-xs="15">{{ user.status | titlecase }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row (click)="selectUser(user)" *matRowDef="let user; columns: columnsToDisplay"></mat-row>
      </mat-table>
      <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50, 100]" (page)="handlePageChange()"></mat-paginator>
    </div>
  </mat-card>
</div>
