<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Margin Details</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>

    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="loading-spinner">Margin Details</h2>
      <mat-spinner class="loading-spinner" *ngIf="isLoading" diameter="40" color="accent"></mat-spinner>
    </div>

    <div *ngIf="!errorMessage && margin$ | async as margin" fxLayout="column" fxLayoutGap="15px">

      <!-- Client -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Client Name" [data]="margin.clientName"></ati-labeled-data>
      </div>

      <!-- Account, Cash Account, Margin Account -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Account" [data]="margin.accountNumber ? margin.accountNumber : margin['account']"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Cash Account" [data]="margin.moneyAccount"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Margin Account" [data]="margin.marginAccount"></ati-labeled-data>
      </div>

      <!-- Broker Code, Account Balance, Security Balance -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Broker Code" [data]="margin.officeCode + margin.salesCode"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Account Balance" [data]="margin.accountBalance | number: '1.2'"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Security Balance" [data]="margin.longMarketValue | number: '1.2'"></ati-labeled-data>
      </div>

      <!-- Total Equity, Open Trade Equity, Net Liq Val -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Total Equity" [data]="margin.totalEquity | number: '1.2'"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Open Trade Equity" [data]="margin.openTradeEquity | number: '1.2'"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Net Liq Value" [data]="margin.netLiq | number: '1.2'"></ati-labeled-data>
      </div>

      <!-- Initial Margin, Margin Req, Margin Excess Deficit -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Initial Margin Req" [data]="margin.initialMarginRequirement | number: '1.2'"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Maintenance Margin Req" [data]="margin.maintenanceMarginRequirement | number: '1.2'"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Margin Excess Deficit" [data]="margin.excessDeficit | number: '1.2'"></ati-labeled-data>
      </div>

      <!-- Owed, Withdrawable, Total Call -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Owed" [data]="marginService.calculateOwed(margin) | number: '1.2'"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Withdrawable" [data]="margin.withdrawable | number: '1.2'"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Total Call" [data]="margin.totalCall | number: '1.2'"></ati-labeled-data>
      </div>

      <!-- Long Option, Short Option, Net Option -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Long Option Value" [data]="margin.longOptionValue | number: '1.2'"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Short Option Value" [data]="margin.shortOptionValue | number: '1.2'"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Net Option Value" [data]="margin.longOptionValue + margin.shortOptionValue | number: '1.2'"></ati-labeled-data>
      </div>

      <!-- Age, Debit Age, Margin Type -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Age" [data]="margin.marginCallAge"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Debit Age" [data]="margin.marginCallRecordsCount"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Margin Type" [data]="margin.marginType"></ati-labeled-data>
      </div>

      <!-- Funds Transfer Type -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Funds Type" [data]="margin.fundsTransferType"></ati-labeled-data>
      </div>

      <!-- Process Date -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Business Date" [data]="margin.processDate | date: 'MM/dd/yy'"></ati-labeled-data>
      </div>
    </div>

  </mat-card>
</div>

