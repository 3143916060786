import { Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Observable, Subject } from 'rxjs';

import { CommodityMap } from '@advance-trading/ops-data-lib';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'atom-commission-rate-dialog',
  templateUrl: './commission-rate-dialog.component.html',
  styleUrls: ['./commission-rate-dialog.component.css']
})
export class CommissionRateDialogComponent implements OnInit, OnChanges, OnDestroy {

  existingCommodities: {[key: string]: FormGroup[]} = {};
  rateForm = this.fb.group({
    exchange: ['', Validators.required],
    commodity: [{value: '', disabled: true}, Validators.required],
    futureHedge: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
    futureSpread: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
    optionHedgeOpen: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
    optionHedgeClose: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
    optionSpreadOpen: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
    optionSpreadClose: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
    level: ['1'],
    rangeBottom: [''],
  });
  commodities$: Observable<CommodityMap>;
  private unsubscribe$: Subject<void> = new Subject<void>();

  get isSlidingScale() {
    return this.data.isSlidingScale;
  }

  constructor(
    public dialogRef: MatDialogRef<CommissionRateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {[key: string]: any},
    private fb: FormBuilder
  ) {  }

  get knownCommodities() {
    if (this.data && this.data.knownCommodities) {
      return this.data.knownCommodities;
    }

    return [];
  }

  get knownExchanges() {
    if (this.data && this.data.knownExchanges) {
      return this.data.knownExchanges;
    }

    return [];
  }

  validateRangeBottom = (control: FormControl) => {
    if (!this.data.rangeBottomArr || this.data.rangeBottomArr.indexOf(control.value) === -1) {
      return;
    }

    return { rangeBottomTaken: true };
  }

  onSave() {
    const exchanges = this.rateForm.get('exchange').value;

    if (typeof exchanges === 'string') {
      this.rateForm.patchValue(this.rateForm.getRawValue());
      this.dialogRef.close(this.rateForm);
    } else {
      const commodities = this.rateForm.get('commodity').value;
      const newCommodities = Array.from(commodities);

      for (const commodityCode of commodities) {
        // Add like-commodities from among all selected exchanges (eg "Coffee" exists on multiple exchanges)
        const commodity = this.knownCommodities.find(entry => entry.id === commodityCode);
        const additionalCommodities = this.knownCommodities.filter(entry =>
          entry.displayName === commodity.displayName && entry.exchange !== commodity.exchange && exchanges.includes(entry.exchange)
        );
        newCommodities.splice(-1, 0, ...additionalCommodities.map(entry => entry.id));
      }

      this.dialogRef.close(newCommodities.map(commodityCode => {
        const commodity = this.knownCommodities.find(entry => entry.id === commodityCode);
        const formGroup = this.fb.group({
          exchange: ['', Validators.required],
          commodity: ['', Validators.required],
          futureHedge: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
          futureSpread: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
          optionHedgeOpen: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
          optionHedgeClose: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
          optionSpreadOpen: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
          optionSpreadClose: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
          level: ['1'],
          rangeBottom: [''],
        });
        formGroup.patchValue(this.rateForm.value);
        formGroup.get('exchange').setValue(commodity.exchange);
        formGroup.get('commodity').setValue(commodityCode);
        return formGroup;
      }));
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.setExistingRate(this.data.existingRate);
    this.setRatesByExchange(this.data.ratesByExchange);
    this.handleRangeBottom();
    this.commodities$ = this.data.commodities$;

    this.rateForm.get('exchange').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(nextValue => {
      if (typeof nextValue === 'string') {
        this.rateForm.get('commodity').setValue('');
      } else {
        this.clearInvalidCommodities(nextValue);
      }

      if (nextValue) {
        this.rateForm.get('commodity').enable();
      } else {
        this.rateForm.get('commodity').disable();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setExistingRate(changes.data.currentValue.existingRate);
    this.setRatesByExchange(changes.data.currentValue.ratesByExchange);
    this.data = changes.data.currentValue;
    this.commodities$ = changes.data.currentValue.commodities$;
    this.handleRangeBottom();
  }

  /**
   * Removes commodities for exchanges that are no longer selected
   */
  clearInvalidCommodities(nextExchange) {
    const commodities = this.rateForm.get('commodity').value;
    for (const commodityCode of commodities) {
      const commodity = this.knownCommodities.find(entry => entry.id === commodityCode);

      // Determine if exchange is still selected. If not, determine if a selected exchange has the same commodity. If not, deselect
      if (!nextExchange.includes(commodity.exchange)) {
        const relatedCommodity = this.knownCommodities.find(entry =>
          entry.exchange !== commodity.exchange && entry.displayName === commodity.displayName && nextExchange.includes(entry.exchange)
        );

        const newValue = Array.from(this.rateForm.get('commodity').value);
        const oldIndex = newValue.indexOf(commodityCode);
        if (oldIndex > -1) {
          // Deselect commodity
          newValue.splice(oldIndex, 1);
        }
        if (relatedCommodity) {
          newValue.push(relatedCommodity.id);
        }
        this.rateForm.get('commodity').setValue(newValue);
      }
    }
  }

  handleRangeBottom() {
    if (this.data.rangeBottomArr) {
      this.rateForm.get('rangeBottom').setValidators([Validators.required, Validators.min(0), this.validateRangeBottom]);

      if (!this.data.existingRate) {
        this.rateForm.get('level').setValue('1');
      }
    }
  }

  setRatesByExchange(ratesByExchange: {[key: string]: FormArray[]}) {
    if (!ratesByExchange) {
      return;
    }

    this.existingCommodities = Object.keys(ratesByExchange).reduce((accumulator, exchangeCode) => {
      for (const rate of ratesByExchange[exchangeCode]) {
        accumulator[rate.at(0).get('commodity').value] = rate;
      }
      return accumulator;
    }, {});
  }

  setExistingRate(existingRate: FormGroup) {
    if (!existingRate) {
      this.rateForm.get('exchange').enable();
      this.rateForm.get('commodity').enable();
      this.rateForm.get('commodity').setValidators(Validators.required);
      return;
    }

    this.rateForm.get('exchange').disable();
    this.rateForm.get('commodity').disable();

    this.rateForm.patchValue({
      exchange: existingRate.get('exchange').value,
      commodity: existingRate.get('commodity').value,
      futureHedge: existingRate.get('futureHedge').value,
      futureSpread: existingRate.get('futureSpread').value,
      optionHedgeOpen: existingRate.get('optionHedgeOpen').value,
      optionHedgeClose: existingRate.get('optionHedgeClose').value,
      optionSpreadOpen: existingRate.get('optionSpreadOpen').value,
      optionSpreadClose: existingRate.get('optionSpreadClose').value,
      level: existingRate.get('level').value,
      rangeBottom: existingRate.get('rangeBottom').value,
    });
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'You must enter a value';
    } else if (control.hasError('rangeBottomTaken')) {
      return 'Value already used for existing tier';
    }

    return 'Unknown error';
  }

  shouldDisplayCommodity(commodity, index, commodities) {
    if (typeof this.rateForm.get('exchange').value === 'string') {
      return (
        commodity.exchange === this.rateForm.get('exchange').value &&
        (this.data.existingRate || !this.existingCommodities[commodity.id])
      );
    } else {
      return (
        this.rateForm.get('exchange').value.includes(commodity.exchange) &&
        (this.data.existingRate || !this.existingCommodities[commodity.id]) &&
        !commodities.slice(0, index).find(entry =>
          entry.name === commodity.name && this.rateForm.get('exchange').value.includes(entry.exchange)
        )
      );
    }
  }

}
