import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MarginSearchFormValidator } from './margin-search-form-validator';
import { combineLatest, Observable, of } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';
import { DefaultDatePicker } from '../../utilities/defaultDatePicker';

import { Account, AccountPurpose, Margin } from '@advance-trading/ops-data-lib';
import { MarginSearchCriteria } from 'src/app/services/service-interfaces/margin-search-interface';
import { MarginService } from 'src/app/services/margin-service';
import * as moment from 'moment';
import { AccountService } from 'src/app/services/account-service';

const DEFAULT_END_DATE = new Date('12/31/2099').toISOString();
const DEFAULT_START_DATE = new Date('1/1/2000').toISOString();

@Component({
  selector: 'atom-margin-search',
  templateUrl: './margin-search.component.html',
  styleUrls: ['./margin-search.component.css']
})
export class MarginSearchComponent implements OnInit, AfterViewChecked {

  public formValidator = new MarginSearchFormValidator();

  marginSearchForm: FormGroup = this.formBuilder.group({
    brokerCodes: this.formBuilder.control({ value: '', disabled: false}, this.formValidator.brokerCodesValidator()),
    accounts: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.accountValidator()),
    startDate: this.formBuilder.control({ value: DEFAULT_START_DATE, disabled: false }),
    endDate: this.formBuilder.control({ value: DEFAULT_END_DATE, disabled: false }),
    option: this.formBuilder.control({ value: '', disabled: false })
  });

  tableState: { [key: string]: string | number };
  filteredBy = '';
  errorMessage: string;
  isSearching = false;
  showMargins = false;
  selectedMargins$: Observable<Margin[]>;
  optionStatuses = ['Accounts on Call', 'Accounts with Withdrawable Funds'];

  private queryParams: Params;
  private lastBusinessDay = DefaultDatePicker.getLastBusinessDay();

  constructor(
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private marginService: MarginService,
    private router: Router,
    private snackBar: MatSnackBar,
    private accountService: AccountService
  ) { }

  ngOnInit() {

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.queryParams = Object.assign({}, params);
      this.marginSearchForm.get('accounts').setValue(this.queryParams.accounts);
      this.marginSearchForm.get('brokerCodes').setValue(this.queryParams.brokerCodes);
      this.marginSearchForm.get('option').setValue(this.queryParams.option);
      if (this.queryParams.startDate) {
        this.marginSearchForm.get('startDate').setValue(moment(this.queryParams.startDate).toISOString());
      } else {
        this.marginSearchForm.get('startDate').setValue(this.lastBusinessDay);
      }
      if (this.queryParams.endDate) {
        this.marginSearchForm.get('endDate').setValue(moment(this.queryParams.endDate).toISOString());
      } else {
        this.marginSearchForm.get('endDate').setValue(this.lastBusinessDay);
      }

      if (Object.keys(params).length) {
        // Mark form as dirty so rese button appears
        this.marginSearchForm.markAsDirty();
        this.searchMargins();
      }
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  reset() {
    this.marginSearchForm.reset();
    this.clearQueryParams();
    this.marginSearchForm.get('startDate').setValue(this.lastBusinessDay);
    this.marginSearchForm.get('endDate').setValue(this.lastBusinessDay);
    this.marginSearchForm.get('option').setValue('');
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });

    this.filteredBy = '';
    this.marginSearchForm.enable();
    this.marginSearchForm.markAsPristine();
    this.showMargins = false;
  }

  searchMargins(searchButtonClicked: boolean = false) {
    this.snackBar.dismiss();
    if (searchButtonClicked) {
      this.clearQueryParams();
      this.tableState = {};
    } else {
      // Set initial table state from query params if the user is back navigating
      const sortDir = this.queryParams.sortDir;
      const sortColName = this.queryParams.sortColName;
      const pageSize = this.queryParams.pageSize;
      const pageIndex = this.queryParams.pageIndex;
      const filterValue = this.queryParams.filterValue;
      this.tableState = {
        sortDir,
        sortColName,
        pageSize,
        pageIndex,
        filterValue,
      };
    }

    this.showMargins = false;
    this.changeDetector.detectChanges();
    this.selectedMargins$ = this.chooseQuery();
    this.showMargins = true;
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMax')) {
      return 'Value Invalid';
    } else {
      return 'Unknown Error';
    }
  }

  handleMarginListChange(tableState: { [key: string]: string | number }) {
    if (tableState.sortDir && tableState.sortColName) {
      this.queryParams.sortDir = tableState.sortDir;
      this.queryParams.sortColName = tableState.sortColName;
    } else if (this.queryParams.sortDir && this.queryParams.sortColName) {
      // Remove sorted direction and column in query param if there's no sort
      delete this.queryParams.sortDir;
      delete this.queryParams.sortColName;
    }

    if (tableState.pageSize) {
      this.queryParams.pageSize = tableState.pageSize;
    }

    if (tableState.pageIndex !== undefined) {
      this.queryParams.pageIndex = tableState.pageIndex;
    }

    if (tableState.filterValue) {
      this.queryParams.filterValue = tableState.filterValue;
    } else if (this.queryParams.filterValue) {
      // remove filter query param if there's no filter applied
      delete this.queryParams.filterValue;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  handleMarginListError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.changeDetector.detectChanges();
  }

  // Clears the value and disables client field and enables account field
  accountFieldClicked() {
    this.marginSearchForm.get('brokerCodes').setValue('');
    this.marginSearchForm.get('brokerCodes').disable();
    this.marginSearchForm.get('accounts').enable();
  }

  // Clears the value and disables account field and enables brokerCode field
  brokerCodeFieldClicked() {
    this.marginSearchForm.get('accounts').setValue('');
    this.marginSearchForm.get('accounts').disable();
    this.marginSearchForm.get('brokerCodes').enable();
  }

  private chooseQuery(): Observable<Margin[]> {
    const accountValues = this.marginSearchForm.get('accounts').value;
    const brokerCodeValues = this.marginSearchForm.get('brokerCodes').value;
    const optionValue = this.marginSearchForm.get('option').value;
    let startDate = this.marginSearchForm.get('startDate').value;
    let endDate = this.marginSearchForm.get('endDate').value;
    this.queryParams = this.tableState as Params;

    if (startDate) {
      startDate = moment(startDate).format('YYYY-MM-DD');
    } else {
      startDate = DEFAULT_START_DATE;
    }
    this.queryParams.startDate = startDate;

    if (endDate) {
      endDate = moment(endDate).format('YYYY-MM-DD');
    } else {
      endDate = DEFAULT_END_DATE;
    }
    this.queryParams.endDate = endDate;

    if (optionValue) {
      this.queryParams.option = optionValue;
    }

    const marginSearchCriteria: MarginSearchCriteria = {
      startDate,
      endDate,
      owed: optionValue === 'Accounts on Call',
      withdrawable: optionValue === 'Accounts with Withdrawable Funds'
    };

    let chosenMargin$: Observable<Margin[]>;
    if (accountValues) {
      const splitAccounts = this.splitAccounts(accountValues);
      this.queryParams.accounts = accountValues;
      this.filteredBy = 'ACCOUNT - ' + splitAccounts.join(', ');
      chosenMargin$ = this.marginService.getMarginsByAccountNumbers(splitAccounts, marginSearchCriteria);
    } else if (brokerCodeValues) {
      const splitCodes = this.splitBrokerCodes(brokerCodeValues);
      this.queryParams.brokerCodes = brokerCodeValues;
      this.filteredBy = 'BROKER CODE - ' + splitCodes.join(', ');
      chosenMargin$ = this.marginService.searchMarginsByBrokerCode(splitCodes, marginSearchCriteria);
    } else {
      chosenMargin$ = this.marginService.getMarginsByAccountNumbers([], marginSearchCriteria);
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });

    return chosenMargin$;
  }

  private splitAccounts(accountValue: string): string[] {
    const accountNumbers = accountValue.split(',').map((accountNum) => accountNum.trim());
    // filter duplicate account Numbers
    const accountNumbersFiltered = [...new Set(accountNumbers)];
    this.filteredBy = `Account Number - ${accountNumbersFiltered.join(', ')}`;
    return accountNumbersFiltered;
  }

  private splitBrokerCodes(brokerCodes: string): string[] {
    const brokerCodeValues = brokerCodes.split(',').map((code) => code.trim());
    let brokerCodesFiltered = [];

    // filter duplicate brokerCodes
    brokerCodesFiltered = [...new Set(brokerCodeValues)];
    this.filteredBy = `Broker Code - ${brokerCodesFiltered.join(', ')}`;
    return brokerCodesFiltered;
  }

  clearOption() {
    this.marginSearchForm.get('option').setValue('');
  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom',
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom',
      });
    }
  }
}

