<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Account Series List</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="column" fxFlex="100">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" #filter>
        <button mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <button *ngIf="exportable" aria-label="Export" id="export-account-series" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
      <mat-menu #exportMenu="matMenu">
        <button mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Account Series'))">Excel</button>
        <button mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Account Series'))">CSV</button>
        <button mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Account Series'))">JSON</button>
      </mat-menu>

      <mat-table matTableExporter #exporter="matTableExporter" [hiddenColumns]="[4]" [dataSource]="dataSource" matSort class="mat-elevation-z0" (matSortChange)="handleSortChange()">
        <ng-container matColumnDef="range">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="55" fxFlex.gt-xs="30">Range</mat-header-cell>
          <mat-cell *matCellDef="let accountSeries" fxFlex.xs="55" fxFlex.gt-xs="30">{{ accountSeries.start }} - {{ accountSeries.end }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="size">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="20">Size</mat-header-cell>
          <mat-cell *matCellDef="let accountSeries" fxFlex.gt-xs="20">{{ accountSeries.size }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="20">Status</mat-header-cell>
          <mat-cell *matCellDef="let accountSeries" fxFlex.gt-xs="20">{{ displayStatus(accountSeries.status) | titlecase }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="salesCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.gt-xs="20">Sales Code</mat-header-cell>
          <mat-cell *matCellDef="let accountSeries" fxFlex.xs="25" fxFlex.gt-xs="20">{{ accountSeries.salesCode }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="icon">
          <mat-header-cell *matHeaderCellDef fxFlex.xs="20" fxFlex.gt-xs="10">Accounts</mat-header-cell>
          <mat-cell *matCellDef="let accountSeries" fxFlex.xs="20" fxFlex.gt-xs="10" >
            <div *ngIf="accountSeries.status !== 'NEW'" (click)="$event.stopPropagation()">
              <mat-icon (click)="selectAccountsIcon(accountSeries)" color="primary" matTooltip="Account Series Accounts">folder</mat-icon>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row [ngClass]="{ 'assigned-account-series' : accountSeries.status !== 'NEW' }"  (click)="selectAccountSeries(accountSeries)" *matRowDef="let accountSeries; columns: columnsToDisplay"></mat-row>
      </mat-table>
      <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50, 100]" (page)="handlePageChange()"></mat-paginator>
    </div>
</div>
