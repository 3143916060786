import { Component, OnInit } from '@angular/core';
import { CashActivity} from '@advance-trading/ops-data-lib';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CashActivityService } from '../../../services/cash-activity-service';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'atom-cash-activity-detail',
  templateUrl: './cash-activity-detail.component.html',
  styleUrls: ['./cash-activity-detail.component.css']
})
export class CashActivityDetailComponent implements OnInit {
  isLoading = false;
  errorMessage: string;

  cashActivity$: Observable<CashActivity>;

  cashActivityDocId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cashActivityService: CashActivityService,
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.cashActivity$ = this.activatedRoute.paramMap.pipe(
      switchMap((paramMap: ParamMap) => {
        const cashActivityAccountDocId = paramMap.get('accountDocId');
        const cashActivityDocId = paramMap.get('cashActivityDocId');
        return this.cashActivityService.getCashActivityByDocId(cashActivityAccountDocId, cashActivityDocId);
      }),
      tap(cashActivity => {
        if (!cashActivity) {
          this.errorMessage = 'Error retrieving cash activity data; this cash activity either does not exist or you do not have permission to view this information';
        }
        this.isLoading = false;
      }),
      catchError(err => {
        this.errorMessage = 'Error retrieving cash activity data; please try again later';
        console.error(`Error retrieving cash activity data: ${err}`);
        return of(undefined);
      })
    );
  }
}

