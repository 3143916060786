<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <h6 *ngIf="filteredBy" class="card-brow">{{ filteredBy | uppercase }}</h6>
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Trades List</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">Trades</h2>
    </div>
    <div *ngIf="!errorMessage" fxLayout="column">
    <form [formGroup]="tradesForm" (ngSubmit)="searchTrades(true)">
      <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100" fxLayoutGap.gt-xs="25px">
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap.gt-xs="5">
          <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="45" (click)="accountFieldClicked()">
            <input upperCase matInput placeholder="Accounts" formControlName="accounts">
            <mat-error *ngIf="tradesForm.get('accounts').invalid">{{formValidator.getErrorMessages(tradesForm.get('accounts'))}}</mat-error>
          </mat-form-field>
        <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="45" (click)="clientFieldClicked()">
          <input matInput placeholder="Broker Codes" formControlName="brokerCodes">
          <mat-error *ngIf="tradesForm.get('brokerCodes').invalid">{{formValidator.getErrorMessages(tradesForm.get('brokerCodes'))}}</mat-error>
        </mat-form-field>
      </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap.gt-xs="5">
          <mat-form-field class="example-full-width" fxFlex.gt-xs="25" fxFlex.xs="100">
            <mat-label>Commodity</mat-label>
            <input type="text"
                   placeholder="Commodity"
                   aria-label="Commodity"
                   matInput
                   formControlName="symbol"
                   [matAutocomplete]="auto"
                   trim="blur">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCommodity">
              <mat-option *ngFor="let commodity of filteredCommodities | async" [value]="commodity">
                {{displayCommodity(commodity)}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div fxLayout="row wrap" fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center">
            <mat-form-field fxFlex.xs="100" fxFlex="45">
              <input matInput placeholder="Strike (min)" formControlName="minStrike">
              <mat-error *ngIf="tradesForm.get('minStrike').invalid">{{formValidator.getErrorMessages(tradesForm.get('minStrike'))}}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex.xs="100" fxFlex="45">
              <input matInput placeholder="Strike (max)" formControlName="maxStrike">
              <mat-error *ngIf="tradesForm.get('maxStrike').invalid">{{formValidator.getErrorMessages(tradesForm.get('maxStrike'))}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap.gt-xs="5">
          <mat-form-field atomMonthYearPicker fxFlex.gt-xs="25" fxFlex.xs="100">
           <input matInput [matDatepicker]="yearMonthPicker" placeholder="Month/Year" formControlName="contractMonthYear">
           <mat-datepicker-toggle matSuffix [for]="yearMonthPicker"></mat-datepicker-toggle>
           <mat-datepicker #yearMonthPicker startView="year" (monthSelected)="selectYearMonth($event)" panelClass="month-year-picker"></mat-datepicker>
          </mat-form-field>
          <div fxLayout="row wrap" fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center">
            <mat-form-field fxFlex.xs="100" fxFlex="45">
              <input matInput [matDatepicker]="startDatePicker" placeholder="Trade Date (Start)" formControlName="startTradeDate" [max]="tradesForm.get('endTradeDate').value">
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
              <mat-error *ngIf="tradesForm.get('startTradeDate').invalid">{{ 'Invalid Date' }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex.xs="100" fxFlex="45">
              <input matInput [matDatepicker]="endDatePicker" placeholder="Trade Date (End)" formControlName="endTradeDate" [min]="tradesForm.get('startTradeDate').value">
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
              <mat-error *ngIf="tradesForm.get('endTradeDate').invalid">{{ 'Invalid Date' }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100">
          <div fxLayout="column">
            <span class="check-radio-label">Side</span>
            <mat-radio-group formControlName="side" fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap.gt-xs="25px"  fxLayoutGap.xs="10px" fxLayoutAlign.gt-xs="start center">
              <mat-radio-button  *ngFor="let side of tradeSides" [value]="side" labelPosition="after">
                {{ side | replace | titlecase }}
              </mat-radio-button>
              <button id='clear-side' mat-button *ngIf="tradesForm.get('side').value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSide()" matTooltip="Clear Side">
                <mat-icon>close</mat-icon>
              </button>
            </mat-radio-group>
          </div>
        <div fxLayout="row wrap" fxLayoutAlign.gt-xs="start center" fxFlex.xs="100" fxFlex="45" fxLayoutGap.gt-xs="25px">
          <mat-form-field fxFlex.xs="100" fxFlex="45">
            <input matInput [matDatepicker]="startBusinessDatePicker" placeholder="Business Date (Start)" formControlName="startBusinessDate" [max]="tradesForm.get('endBusinessDate').value">
            <mat-datepicker-toggle matSuffix [for]="startBusinessDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startBusinessDatePicker></mat-datepicker>
            <mat-error *ngIf="tradesForm.get('startBusinessDate').invalid">{{ 'Invalid Date' }}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex.xs="100" fxFlex="45">
            <input matInput [matDatepicker]="endBusinessDatePicker" placeholder="Business Date (End)" formControlName="endBusinessDate" [min]="tradesForm.get('startBusinessDate').value">
            <mat-datepicker-toggle matSuffix [for]="endBusinessDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endBusinessDatePicker></mat-datepicker>
            <mat-error *ngIf="tradesForm.get('endBusinessDate').invalid">{{ 'Invalid Date' }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <span class="check-radio-label">Type</span>
      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap.gt-xs="5">
        <div class="checkbox-group" fxFlex.xs="100" fxFlex.gt-xs="45">
          <mat-checkbox #subTypeBoxes *ngFor="let subType of tradeSubTypes" (change)="onSubTypeChange($event)" [value]="subType" labelPosition="after">
            {{ subType | replace | titlecase }}
          </mat-checkbox>
          <mat-checkbox #typeBoxes *ngFor="let type of ['FUTURE', 'BOND']" (change)="onTypeChange($event)" [value]="type" labelPosition="after">
            {{ type | replace | titlecase }}
          </mat-checkbox>
        </div>
      </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap.gt-xs="5">
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <button id="submit-trade-search" aria-label="Search" mat-raised-button [disabled]="!tradesForm.valid" color="accent" type="submit">Search</button>
          <button id="clear-trade-search" *ngIf="!tradesForm.pristine" aria-label="Reset" (click)="reset()" mat-stroked-button color="accent">Reset</button>
        </div>
      </div>
    </form>
    <div *ngIf="isSearching" fxLayout="row" fxLayoutGap="10px">
      <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
    </div>
    <div *ngIf="showData && !errorMessage" class="trade-list">
      <atom-trades (tradeListChange)="handleTradeListChange($event)" (tradeSearchError)="handleTradeListError($event)" (isSearching)="handleIsSearching($event)" [selectedTrades$]="selectedTrades$" [initialTableState]="tableState"></atom-trades>
    </div>
    </div>
  </mat-card>
</div>
