<div fxFlex="100">
  <div>
    <div *ngIf="!errorMessage" fxLayout="column">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="trades-filter-field" #filter>
        <button mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <button *ngIf="exportable && selectedTrades$ | async" aria-label="Export" id="export-trades" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
      <mat-menu #exportMenu="matMenu">
        <button mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Trades'))">Excel</button>
        <button mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Trades'))">CSV</button>
        <button mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Trades'))">JSON</button>
      </mat-menu>

    <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort class="mat-elevation-z0" matSortActive="tradeDate" matSortDirection="desc" (matSortChange)="handleSortChange()">

      <ng-container matColumnDef="accountNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="5">Account</mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="5">{{ trade.accountNumber }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="transactionDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="8">Business Date </mat-header-cell>
          <mat-cell *matCellDef="let trade" fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="8">{{ trade.transactionDate | date: 'shortDate' }}</mat-cell>
          </ng-container>
        <ng-container matColumnDef="tradeDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="5">Trade Date </mat-header-cell>
          <mat-cell *matCellDef="let trade" fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="5">{{ trade.tradeDate | date: 'shortDate' }}</mat-cell>
          </ng-container>
      <ng-container matColumnDef="contractYearMonth">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="7">Month/Year</mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="25" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="7">{{ getYearMonthDisplay(trade.contractYearMonth) | date: 'MMM YY'}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="side">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="3">Side</mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="25" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="3">{{ trade.side | titlecase }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="commission">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.md="10" fxFlex.gt-xs="8">Commission</mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.md="10" fxFlex.gt-xs="8">{{ trade.commission | number : '1.2' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="securitySubType">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.md="10" fxFlex.gt-xs="5">Put/Call</mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.md="10" fxFlex.gt-xs="5">{{ trade.securitySubType ? (trade.securitySubType | titlecase) : '' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="clearingFee">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="5">Clearing Fee </mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.gt-xs="5">{{ trade.clearingFee | number : '1.2' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="nfaFee">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="5">NFA Fee </mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.gt-xs="5">{{ trade.nfaFee | number : '1.2' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="exchangeFee">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="5">Exchange Fee</mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.gt-xs="5">{{ trade.exchangeFee | number : '1.2' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="brokerCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="5">Broker Code</mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="5">{{ trade.officeCode + trade.salesCode }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="3">Qty</mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="25" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="3">{{ trade.quantity | number : '1.0-2'}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="commodity">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="6">Commodity</mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="33" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="6">{{ getCommodityDisplayName(trade.commodityId) }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="strikePrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="4">Strike</mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="4">{{ trade.strikePrice != 0 ? (trade.strikePrice | number : '1.2-5') : '' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="transactionRecordType">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="4">Type</mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="4">{{ trade.transactionRecordType | titlecase }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="spreadCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="5">Outright/<br>Spread</mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="5">{{ trade.spreadCode == 'S' ? 'Spread' : 'Outright' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="5">Fill</mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="5">{{ trade.price | number : '1.2-9' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="openCloseCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="4">Status</mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="4">{{ trade.openCloseCode | titlecase}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="cashAdjustment">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="4">Cash Adj</mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="4">{{ trade.cashAdjustment | number : '1.2-9' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="commissionAdjustment">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="4">Comm Adj</mat-header-cell>
        <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="4">{{ trade.commissionAdjustment | number : '1.2-9' }}</mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row  *matRowDef="let trade; columns: columnsToDisplay" (click)="selectTrade(trade)"></mat-row>
    </mat-table>
    <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50, 100]" (page)="handlePageChange()"></mat-paginator>
  </div>
  </div>
</div>
