<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Accounts List</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="column" fxFlex="100">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="accounts-filter-field" #filter>
        <button mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px">
        <button *ngIf="exportable" aria-label="Export" id="export-accounts" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
        <mat-menu #exportMenu="matMenu">
          <button mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Accounts'))">Excel</button>
          <button mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Accounts'))">CSV</button>
          <button mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Accounts'))">JSON</button>
        </mat-menu>
        <div *ngIf="canMoveAccounts && !editMode" fxLayout="row" fxLayoutAlign="start center">
          <span class="section-label">Move Accounts</span>
          <button aria-label="Move" id="move-accounts" type="button" mat-icon-button color="primary" matTooltip="Move Accounts" (click)="setEditMode(true)">
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
      </div>

      <form [formGroup]="accountMoveForm">
        <div class="move-form" *ngIf="editMode" fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="10">
          <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="25">
            <mat-label>New Broker Code</mat-label>
            <input matInput formControlName="brokerCode" [matAutocomplete]="autoBrokerCode" required>
            <mat-error *ngIf="accountMoveForm.get('brokerCode').invalid">You must select a valid broker code</mat-error>
          </mat-form-field>
          <mat-autocomplete #autoBrokerCode="matAutocomplete" [displayWith]="displayBrokerRel">
            <mat-option *ngFor="let brokerRel of filteredBrokerRels$ | async" [value]="brokerRel">{{ displayBrokerRel(brokerRel) }}</mat-option>
          </mat-autocomplete>

          <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="25">
            <mat-select [disabledOptionCentering]="true" required placeholder="New Managing Pod" formControlName="managingPod">
              <mat-option *ngFor="let pod of podNames" [value]="pod">{{ pod | replace | titlecase }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="25">
            <input matInput placeholder="New Lead Broker" formControlName="leadBroker" [matAutocomplete]="autoLeadBroker" required>
            <mat-error *ngIf="accountMoveForm.get('leadBroker').invalid">You must select a valid broker</mat-error>
            <button id="clear" aria-label="Clear" mat-button *ngIf="accountMoveForm.get('leadBroker').value && editMode && accountMoveForm.get('leadBroker').enabled" matSuffix mat-icon-button aria-label="Clear Lead Broker" matTooltip="Clear Lead Broker" (click)="accountMoveForm.get('leadBroker').setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoLeadBroker="matAutocomplete" [displayWith]="displayBroker" (optionSelected)="prepForLeadBrokerSelection()">
            <mat-option *ngFor="let broker of filteredBrokers$ | async" [value]="broker">{{ displayBroker(broker) }}</mat-option>
          </mat-autocomplete>

          <div fxLayout="row" fxLayoutGap="10px">
            <button id="submit-account-move" aria-label="Submit" *ngIf="editMode" [disabled]="!accountMoveForm.valid || !accountsToMove.length" (click)="submit()" mat-mini-fab color="accent" matTooltip="Submit Account Move">
              <mat-icon>done</mat-icon>
            </button>
            <button id="cancel-account-move" aria-label="Cancel" *ngIf="editMode" (click)="reset()" mat-mini-fab color="" matTooltip="Discard Account Move">
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </div>
      </form>

      <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort matSortActive="number" matSortDirection="asc" class="mat-elevation-z0" (matSortChange)="handleSortChange()">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="70" fxFlex.gt-xs="15">Name</mat-header-cell>
          <mat-cell *matCellDef="let account" fxFlex.xs="70" fxFlex.gt-xs="15">{{ account.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="20" fxFlex.gt-xs="7">Number</mat-header-cell>
          <mat-cell *matCellDef="let account" fxFlex.xs="20" fxFlex.gt-xs="7">{{ account.number }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="brokerCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="10">Broker Code</mat-header-cell>
          <mat-cell *matCellDef="let account" fxFlex.gt-xs="10">{{ displayBrokerCode(account) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="brokerNames">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="20">Brokers</mat-header-cell>
          <mat-cell *matCellDef="let account" fxFlex.gt-xs="20">{{ account.brokerNames }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="managingPod">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="7">Managing Pod</mat-header-cell>
          <mat-cell *matCellDef="let account" fxFlex.gt-xs="7">{{ account.managingPod | replace | titlecase }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="commissionRateCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="7">Rate Code</mat-header-cell>
          <mat-cell *matCellDef="let account" fxFlex.gt-xs="7">{{ account.commissionRateCode }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="purpose">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="7">Purpose</mat-header-cell>
          <mat-cell *matCellDef="let account" fxFlex.gt-xs="7">{{ account.purpose | replace | titlecase }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="fundsTransfer">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="7">Funds Type</mat-header-cell>
          <mat-cell *matCellDef="let account" fxFlex.gt-xs="7">{{ displayFundsTransferType(account.fundsTransfer) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="7">Status</mat-header-cell>
          <mat-cell *matCellDef="let account" fxFlex.gt-xs="7">{{ account.status | titlecase }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="activationDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="8">Activation Date</mat-header-cell>
          <mat-cell *matCellDef="let account" fxFlex.gt-xs="8"><span class="forced-wrap">{{ account.activationDate | date: 'MM/dd/yyyy hh:mm:ss&nbsp;a' }}</span></mat-cell>
        </ng-container>

        <ng-container matColumnDef="move">
          <mat-header-cell *matHeaderCellDef fxFlex.gt-xs="7" fxFlex.xs="10">Move</mat-header-cell>
          <mat-cell *matCellDef="let account" fxFlex.gt-xs="7" fxFlex.xs="10">
            <div (click)="$event.stopPropagation()">
              <mat-checkbox [checked]="isChecked(account)" (change)="prepareAccountMove($event, account)"></mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row (click)="!editMode && selectAccount(account)" *matRowDef="let account; columns: columnsToDisplay"></mat-row>
      </mat-table>
      <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50, 100]" (page)="handlePageChange()"></mat-paginator>
    </div>
</div>
