import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, FormArray, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Auth0AuthzService, AuthService } from '@advance-trading/angular-ati-security';
import { BrokerRelationship, Broker, PodEnum, User } from '@advance-trading/ops-data-lib';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { filter, switchMap, tap, catchError, shareReplay, debounceTime, startWith, map, take, takeUntil } from 'rxjs/operators';
import { BrokerRelationshipService, UserService } from '@advance-trading/angular-ops-data';
import { SplitValidators } from '../../sales-codes/split.validator';
import { SplitCharacterValidators } from '../../sales-codes/split-character.validator';

const VIEW_ROLE = 'BrokerCodeViewer';
const ACCOUNT_ADMIN_ROLE = 'AccountAdmin';
const COMMISSIONS_VIEWER_ROLE = 'CommissionsViewer';
const ACCOUNTING_ADMIN_ROLE = 'AccountingAdmin';

@Component({
  selector: 'atom-broker-relationship-detail',
  templateUrl: './broker-relationship-detail.component.html',
  styleUrls: ['./broker-relationship-detail.component.css']
})
export class BrokerRelationshipDetailComponent implements OnInit, OnDestroy {

  brokerRelForm: FormGroup = this.formBuilder.group({
    brokerCode: new FormControl(''),
    brokers: this.formBuilder.array([], [this.leadBrokerValidator]),
    fcm: new FormControl(''),
    officeCode: new FormControl(''),
    originator: new FormControl(''),
    salesCode: new FormControl(''),
    splits: new FormControl(''),
    equalSplits: new FormControl(false),
    status: new FormControl(''),
    managingPod: new FormControl(''),
    isFcmApproved: [false],
    isPlatformsSetupComplete: [false],
    hasErrorAndAccommodationAccounts: [false],
    extendedCode: ['', [Validators.minLength(5), Validators.maxLength(5)]],
    isSecondary: [false],
    hasErrorAccountLimits: [false],
    secondaryLabel: ['']
  }, {
    validators: [this.brokerPodValidator, this.splitsValidator]
  });

  errorMessage: string;
  updateComplete = true;
  editMode = false;
  createMode = false;
  canView = false;
  canUpdate = false;
  brokers = [];
  equalSplits = false;
  originalBrokerRel: BrokerRelationship; // master copy to revert to when cancelling changes
  podNames = Object.keys(PodEnum);

  filteredBrokers: Observable<User[]>[] = [];
  brokerRelationships: BrokerRelationship[];
  splitsBrokerRel: BrokerRelationship;
  brokerRel$: Observable<BrokerRelationship>;
  brokerRel: BrokerRelationship;

  private activeBrokers$: Observable<User[]>;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private equalSplitsTotal = 0;

  constructor(
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private authzService: Auth0AuthzService,
    private authService: AuthService,
    private brokerRelationshipService: BrokerRelationshipService,
    private formBuilder: FormBuilder,
    private userService: UserService,
  ) { }

  ngOnInit() {
    const brokerCodeViewer = this.authzService.currentUserHasRole(VIEW_ROLE);

    if (!brokerCodeViewer) {
      this.errorMessage = 'You do not have permission to view this information';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.editMode = !params.has('brokerrelationshipDocId');
      if (params.has('brokerrelationshipDocId')) {
        this.brokerRelForm.disable();
      }
    });

    this.brokerRel$ = this.route.paramMap.pipe(
      filter((params: ParamMap) => params.has('brokerrelationshipDocId')),
      switchMap((params: ParamMap) => {
        return this.brokerRelationshipService.getBrokerRelationshipByDocId(params.get('brokerrelationshipDocId')).pipe(
          tap(newBrokerRel => {
            this.brokers = [];
            if (newBrokerRel) {
              newBrokerRel.splits.forEach(split => {
                this.userService.getUserByDocId(split.brokerDocId).pipe(take(1)).subscribe(broker => {
                  this.brokers.push({
                    broker,
                    split: split.split
                  });
                });
              });
              this.handleBrokerRelObject(newBrokerRel);
            } else {
              this.errorMessage = 'This broker relationship either does not exist or you do not have permission to view the information.';
              console.error('Error occurred fetching broker relationship info');
              return this.brokerRel$;
            }
          }),
          catchError((err) => {
            this.errorMessage = 'This broker relationship either does not exist or you do not have permission to view the information.';
            console.error('Error occurred fetching broker relationship info: ' + err);
            return this.brokerRel$;
          })
        );
      }),
      takeUntil(this.unsubscribe$)
    );
    this.getActiveBrokers();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get brokerForms() {
    return this.brokerRelForm.get('brokers') as FormArray;
  }

  get commissionsViewer() {
    return this.authzService.currentUserHasRole(COMMISSIONS_VIEWER_ROLE);
  }

  get brokerRelEditor() {
    return this.authzService.currentUserHasRole(ACCOUNT_ADMIN_ROLE) || this.authzService.currentUserHasRole(ACCOUNTING_ADMIN_ROLE);
  }

  get accountingAdmin() {
    return this.authzService.currentUserHasRole(ACCOUNTING_ADMIN_ROLE);
  }

  setEditMode(mode) {
    this.editMode = mode;
    if (this.editMode) {
      this.originalBrokerRel = this.brokerRel;
      this.brokerRelForm.enable();
      const equalSplits = this.brokerRelForm.get('equalSplits').value === true;
      if (equalSplits) {
        this.brokerForms.controls.forEach(element => {
          element.get('split').disable();
        });
      }

    }
    this.brokerRelForm.markAsPristine();
  }

  cancelChanges() {
    this.brokerRelForm.reset();
    this.filteredBrokers.length = 0;
    this.brokerRelForm.disable();
    this.handleBrokerRelObject(this.originalBrokerRel);
    this.brokerRelForm.markAsPristine();
    this.editMode = false;
  }

  handleBrokerRelObject(brokerRel: BrokerRelationship) {
    this.brokerRel = Object.assign({}, brokerRel);

    this.brokerRelForm.reset();
    while (this.brokerForms.length !== 0) {
      this.brokerForms.removeAt(0);
    }
    this.brokerRelForm.patchValue(brokerRel);
    brokerRel.splits.forEach(split => {
      this.userService.getUserByDocId(split.brokerDocId).pipe(
        take(1)
      ).subscribe(broker => {
        const brokerForm = this.formBuilder.group({
          brokerUser: [broker, [Validators.required, this.brokerValidator]],
          leadBroker: [this.isLeadBroker(split.brokerDocId)],
          split: [parseFloat((split.split * 100).toFixed(8)), [
            Validators.required,
            Validators.min(0.01),
            Validators.max(100.00),
            SplitValidators.splitNumberValidator,
            SplitCharacterValidators.splitCharacterValidator
          ]]
        });
        this.brokerForms.push(brokerForm);
        this.filteredBrokers.push(this.filterBrokers(this.brokerForms.at(this.brokerForms.length - 1).get('brokerUser')));
      });
    });
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('invalidCharacter')) {
      return 'Error: invalid character';
    } else if (control.hasError('min')) {
      return 'Error: less than 0.01';
    } else if (control.hasError('max')) {
      return 'Error: greater than 100';
    } else if (control.hasError('invalidSplitNumber')) {
      return 'Error: 8 decimals';
    } else if (control.hasError('required')) {
      return 'Error: Value required';
    } else {
      return 'Unknown error';
    }
  }

  // This function works like toFixed() but does not round up the last number displayed
  displaySplit(split: number) {
    const calcDec = Math.pow(10,2);
    return Math.trunc((split * 100) * calcDec) / calcDec;
  }

  async saveForm() {
    this.updateComplete = false;
    const isNewBrokerRel = !this.brokerRel;

    const brokerData = this.getBrokerData();

    const brokerRel = Object.assign(isNewBrokerRel ? new BrokerRelationship() : {}, this.brokerRel, this.brokerRelForm.value);
    brokerRel.brokers = brokerData.brokers;
    brokerRel.splits = brokerData.splits;
    brokerRel.leadBrokerDocId = brokerData.leadBroker;

    this.brokerRelationshipService.updateBrokerRelationship(brokerRel)
      .then(response => {
        console.log('Broker Relationship successfully updated');
        this.updateComplete = true;
        this.setEditMode(false);
        this.handleBrokerRelObject(brokerRel);
        this.openSnackBar('Broker Relationship successfully updated', 'DISMISS', true);
      })
      .catch(err => {
        this.updateComplete = true;
        console.error('Broker Relationship update failed: ' + JSON.stringify(err));
        let errorMessage = '';
        switch (err.code) {
          case 'permission-denied':
            errorMessage = 'Insufficient permissions';
            break;
          default:
            errorMessage = 'Unknown error occurred';
        }
        this.openSnackBar('Broker Relationship update failed: ' + errorMessage, 'DISMISS', false);
      });
  }


  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  displayBroker(broker?: User) {
    if (broker) {
      return broker.firstName + ' ' + broker.lastName;
    } else {
      return '';
    }
  }

  /**
   * Adds a new broker FormGroup to the brokers FormArray when the Add icon is clicked by the user.
   * Adjusts the splits for all brokers if the equal splits checkbox is checked.
   * Marks the main form dirty to have the Discard Changes icon available to reset the form.
   */
  addBroker() {
    this.brokerForms.push(this.createBroker(0));
    if (this.brokerRelForm.get('equalSplits').value === true) {
      this.brokerForms.at(this.brokerForms.length - 1).get('split').disable();
      this.setEqualSplitValues();
    }
    this.filteredBrokers.push(this.filterBrokers(this.brokerForms.at(this.brokerForms.length - 1).get('brokerUser')));
    this.brokerRelForm.markAsDirty();
  }

  // creates and returns a new broker FormGroup for the brokers FormArray
  // containing brokerUser and split FormControls for individual brokers
  private createBroker(split: number): FormGroup {
    return this.formBuilder.group({
      brokerUser: ['', [Validators.required]],
      leadBroker: false,
      split: [split, [
        Validators.required,
        Validators.min(0.01),
        Validators.max(100.00),
      ]]
    });
  }

  // sets the split value for each broker to be an equal split based on the number of brokers
  private setEqualSplitValues() {
    const split = 100 / this.brokerForms.length;
    // convert split value to whole number
    const newSplit = Math.trunc(split * 100000000);
    this.equalSplitsTotal = 0;
    this.brokerForms.controls.forEach(element => {
      element.get('split').setValue(this.roundEqualSplits(newSplit));
    });
  }

  // makes equal-split-values equal 100
  private roundEqualSplits(split: number) {

    // finds difference between split whole number total and 10000
    const percentagesTotal = split * this.brokerForms.length;
    const testValue = 10000000000 - percentagesTotal;

    let newSplit = 0;

    // if split total is less than difference, add 1 to current split
    if (this.equalSplitsTotal < testValue) {
      newSplit = (split + 1);
      this.equalSplitsTotal++;
      // revert split percentage to decimal format and return
      return newSplit / 100000000;
    }
    this.equalSplitsTotal++;
    return split / 100000000;
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting brokers
  private filterBrokers(brokerForm: AbstractControl) {
    return brokerForm.valueChanges
      .pipe(
        debounceTime(300),
        startWith<string | User>(''),
        switchMap(term => typeof term === 'string' ? this.filterBrokersBySearchTerm(term) : of([term]))
      );
  }

  // Filters possible brokers using the user input search term
  private filterBrokersBySearchTerm(searchTerm: string): Observable<User[]> {
    return this.activeBrokers$
      .pipe(
        map(brokers => brokers.filter(
          // filter out brokers already selected
          broker => this.brokerForms.controls.find(control => control.get('brokerUser').value.docId === broker.docId) ? false : true)),
        map(brokers => brokers.filter(broker => this.matchName(searchTerm, broker)))
      );
  }

  // Checks for a partial match on user/mentor first or last name
  private matchName(searchTerm: string, user: User) {
    const searchValue = searchTerm.toLowerCase();
    const firstName = user.firstName.toLowerCase();
    const lastName = user.lastName.toLowerCase();

    const fullName = `${firstName} ${lastName}`;
    const lastFirst = `${lastName} ${firstName}`;
    const lastCommaFirst = `${lastName}, ${firstName}`;
    return fullName.includes(searchValue) || lastFirst.includes(searchValue) || lastCommaFirst.includes(searchValue);
  }

  // Gets active brokers from Firestore
  private getActiveBrokers() {
    this.activeBrokers$ = this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId)
      .pipe(
        switchMap((loggedInUser: User) => {
          return this.userService.getBrokerUsersByClientDocId(loggedInUser.clientDocId);
        }),
        shareReplay(1)
      );
  }

  removeBroker(i) {
    this.brokerForms.removeAt(i);
    // adjust splits if equalSplits selected and # of brokers changes
    if (this.equalSplits) {
      this.setEqualSplitValues();
    }
    this.filteredBrokers.splice(i, 1);
    if (this.brokerRelForm.get('equalSplits').value === true) {
      this.brokerForms.at(this.brokerForms.length - 1).get('split').disable();
      this.setEqualSplitValues();
    }
    // auto-populate split as 100 if only one broker is left
    if (this.brokerForms.length === 1) {
      this.equalSplits = false;
      this.brokerForms.at(0).get('split').setValue(100);
      this.brokerForms.at(0).get('split').enable();
      this.brokerRelForm.get('equalSplits').setValue(false);
    }
    this.refreshAvailableBrokers();
    this.brokerRelForm.markAsDirty();
  }

  /**
   * Called on blur of any brokerUser FormControl.
   * Simply triggers valueChanges for each brokerUser control in order to properly update
   * the filtered list of available brokers.
   */
  refreshAvailableBrokers() {
    this.brokerForms.controls.forEach(element => {
      const value = element.get('brokerUser').value;
      element.get('brokerUser').setValue(value);
    });
  }

  /**
   * When the equal splits checkbox is checked, the split FormControls are disabled
   * and the split values are set to be equal for each broker.
   * The split FormControls are enabled when the checkbox is unchecked.
   */
  onEqualSplitsChange($event: MatCheckboxChange) {
    if ($event.checked) {
      this.equalSplits = true;
      this.brokerForms.controls.forEach(element => {
        element.get('split').disable();
      });
      this.setEqualSplitValues();
    } else {
      this.equalSplits = false;
      this.brokerForms.controls.forEach(element => {
        element.get('split').enable();
        element.get('split').setValue('');
      });
    }
  }

  // get brokers and broker splits from form data
  private getBrokerData() {
    const brokers = [];
    const splits = [];
    let leadBroker = '';
    this.brokerForms.controls.forEach(broker => {
      const brokerDocId = broker.get('brokerUser').value.docId;
      brokers.push(brokerDocId);
      // manipulates split value to limit decimal digits in database
      splits.push({ brokerDocId, split: (Math.trunc(broker.get('split').value * 100000000) / 10000000000) });
      if (broker.get('leadBroker').value === true) {
        leadBroker = brokerDocId;
      }
    });
    if (brokers.length === 1) {
      leadBroker = brokers[0];
    }
    return { brokers, splits, leadBroker };
  }

  isLeadBroker(brokerDocId: string) {
    return brokerDocId === this.brokerRel.leadBrokerDocId;
  }

  navigateBroker(broker: Broker) {
    this.router.navigate([`/brokers/${broker.docId}`]);
  }

  /**
   * Is the logged in user a Commissions Viewer or a broker on the current broker relationship
   */
  canUserViewSplits() {
    return this.commissionsViewer ||
      this.brokerRel.splits.find(split => split.brokerDocId === this.authService.userProfile.app_metadata.firestoreDocId);
  }

  // The broker value is invalid if it's a string/partial search term.
  // The value becomes valid once a User object is selected.
  private brokerValidator(control: FormControl) {
    return typeof control.value !== 'string' ? null : { invalidBroker: true };
  }

  // Brokers FormArray value is invalid if the split(s) don't equal 100
  private splitsValidator(control: FormControl) {
    const brokerForms = control.get('brokers') as FormArray;
    const isHouse = control.get('managingPod').value === 'HOUSE';
    let splitTotal = 0.00;
    let revisedSplit = 0.00;
    brokerForms.controls.forEach(element => {
      const split = Number(element.get('split').value) * 100;
      splitTotal += split;
      revisedSplit = splitTotal / 100;
    });
    return (revisedSplit === 100.00 || (!brokerForms.length && isHouse)) ? null : { invalidSplits: true };
  }

  private leadBrokerValidator(brokerForms: FormArray) {
    const leads = [];
    if (brokerForms.controls.length === 1) {
      leads.push(brokerForms.controls[0]);
    } else {
      brokerForms.controls.forEach(element => {
        if (element.get('leadBroker').value === true) {
          leads.push(element);
        }
      });
    }
    return (leads.length === 1 || !brokerForms.controls.length) ? null : { invalidLeadBroker: true };
  }

  private brokerPodValidator(control: FormControl) {
    const brokerForms = control.get('brokers') as FormArray;
    const isHouse = control.get('managingPod').value === 'HOUSE';
    return !(!brokerForms.controls.length && !isHouse) ? null : { invalidBrokerPod: true };
  }

  selectAccountsIcon(brokerCodeVal: string) {
    this.router.navigate(['/accounts'], { queryParams: { brokerCode: brokerCodeVal } });
  }
}

