import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of, combineLatest } from 'rxjs';

import { Account, Position, AccountPurpose } from '@advance-trading/ops-data-lib';
import { PositionSearchCriteria } from './service-interfaces/position-search-interface';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { PositionQueries } from '../services/graphql-queries/position-queries';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '@advance-trading/angular-ati-security';
import { AccountService } from './account-service';

@Injectable({
  providedIn: 'root'
})
export class PositionService {

  constructor(
    private db: AngularFirestore,
    private apollo: Apollo,
    private authService: AuthService,
    private accountService: AccountService
  ) { }

  // Queries Using GET

  /**
   * Return all Position documents for the specified Account
   * @param accountDocId The docId of the Account containing the positions to return
   */
  getAllPositionsByAccountDocId(accountDocId: string): Observable<Position[]> {
    return this.db.collection<Position>(`${Account.getDataPath(accountDocId)}/${Position.getDataPath()}`).get()
      .pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Position) as Position[])
      );
  }

  /**
   * Return the specified Position document
   * @param accountDocId the docId of the Account containing the position to  return
   * @param positionDocId the docId of the Position to return
   */
  getPositionByDocId(accountDocId: string, positionDocId: string): Observable<Position> {
    return this.db.doc<Position>(`${Account.getDataPath(accountDocId)}/${Position.getDataPath(positionDocId)}`)
      .valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Queries across all Accounts to return the specified Position documents by brokerCode
   * @param brokerCode the brokerCode of the Positions to return
   */
  getAllPositionsByBrokerCode(brokerCode: string): Observable<Position[]> {
    return this.db.collectionGroup<Position>(Position.getDataPath(), ref => ref.where('officeCode', '==', brokerCode.slice(0, 3))
      .where('salesCode', '==', brokerCode.slice(3, 6))).get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Position) as Position[])
      );
  }

  /**
   * Queries across all Accounts to return the specified Position documents by clientDocId
   * @param clientDocId the clientDocId of the positions to return
   */
  getAllPositionsByClientDocId(clientDocId: string): Observable<Position[]> {
    return this.db.collectionGroup<Position>(Position.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId))
      .get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Position) as Position[])
      );
  }

  // Queries Using ValueChanges

  /**
   * Return all Position documents for the specified Account
   * @param accountDocId The docId of the Account containing the positions to return
   */
  findAllPositionsByAccountDocId(accountDocId: string): Observable<Position[]> {
    return this.db.collection<Position>(`${Account.getDataPath(accountDocId)}/${Position.getDataPath()}`).valueChanges()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Queries across all Accounts to return the specified Position documents by brokerCode
   * @param brokerCode the brokerCode of the Positions to return
   */
  findAllPositionsByBrokerCode(brokerCode: string): Observable<Position[]> {
    return this.db.collectionGroup<Position>(Position.getDataPath(), ref => ref.where('officeCode', '==', brokerCode.slice(0, 3))
      .where('salesCode', '==', brokerCode.slice(3, 6))).valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Queries across all Accounts to return the specified Position documents by clientDocId
   * @param clientDocId the clientDocId of the positions to return
   */
  findAllPositionsByClientDocId(clientDocId: string): Observable<Position[]> {
    return this.db.collectionGroup<Position>(Position.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId))
      .valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  // GraphQL queries

  /**
   * Returns Position objects by accounts or accounts associated with a margin group account and other search criteria specified
   * @param gqlParams an interface that has been populated with all search query criteria
   * @param accountNumbers array of account numbers to return positions for
   */
  getPositionsByAccountNumbers(accountNumbers: string[], gqlParams: any): Observable<Position[]> {
    if (accountNumbers.length === 0) {
      return this.getPositionsByAccountsQuery(accountNumbers, gqlParams);
    }
    return combineLatest(
      accountNumbers.map((accountNumber) => {
        return this.accountService.retrieveActiveAccountsByNumber(accountNumber);
      })
    ).pipe(map((accounts) => accounts.flat()))
      .pipe(switchMap((accounts) => {
        if (accounts.length === 0) {
          return of([]);
        }
        const observableAccounts = [];
        accounts.forEach((account: Account) => {
          // For margin group accounts fetch all accounts that are associated with the margin group account
          if (account.purpose === AccountPurpose.MARGIN_GROUP) {
            observableAccounts.push(this.accountService.retrieveAccountsByMarginGroupAccountDocId(account.marginGroupAccountDocId));
          }
        });

        if (observableAccounts.length) {
          return combineLatest(observableAccounts).pipe(
            map(accts => accts.flat()),
            switchMap((accts: Account[]) => {

              // Remove duplicate accounts before getting positions for each account
              const duplicates = [];
              accts = accts.filter(acct => duplicates.includes(acct.docId) ? false : duplicates.push(acct.docId));
              const acctNumbers = accts.map((account: Account): string => account.number);
              return this.getPositionsByAccountsQuery(acctNumbers, gqlParams);
            })
          );
        }
        accountNumbers = accounts.map(account => account.number);
        return this.getPositionsByAccountsQuery(accountNumbers, gqlParams);
      }));
  }

  /**
   * Returns selected fields of Position objects by accounts and other search criteria specified by a graphql query
   * @param positionQuery an interface that has been populated with all search query criteria
   */
  private getPositionsByAccountsQuery(accounts: string[], positionQuery: PositionSearchCriteria) {
    return this.apollo.query({
      query: PositionQueries.getPositionsByAccountsQuery,
      variables: {
        accounts,
        positionQuery
      },
      context: {
        withCredentials: true,
        headers: new HttpHeaders({ Authorization: `Bearer ${this.authService.accessToken}` })
      }
    }).pipe(
      map(results => {
        return results.data.positionsByAccounts;
      })
    );
  }

  /**
   * Returns selected fields of Position objects by brokerCodes and other search criteria specified
   * @param positionQuery an interface that has been populated with all search query criteria
   */
  getPositionsByBrokerCodes(brokerCodes: string[], positionQuery: PositionSearchCriteria) {
    return this.apollo.query({
      query: PositionQueries.getPositionsByBrokerCodesQuery,
      variables: {
        brokerCodes,
        positionQuery
      },
      context: {
        withCredentials: true,
        headers: new HttpHeaders({ Authorization: `Bearer ${this.authService.accessToken}` })
      }
    }).pipe(
      map(results => {
        return results.data.positionsByBrokerCodes;
      })
    );
  }
}
