import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { debounceTime, map, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { Broker, BrokerRelationship, FCM, PodEnum, SalesCode, Status, User } from '@advance-trading/ops-data-lib';
import { Auth0AuthzService, AuthService } from '@advance-trading/angular-ati-security';
import { BrokerRelationshipService, BrokerService, SalesCodeService, UserService } from '@advance-trading/angular-ops-data';
import { SplitValidators } from './split.validator';
import { SplitCharacterValidators } from './split-character.validator';


const ACCOUNT_ADMIN_ROLE = 'AccountAdmin';
const BROKER_CODE_REQUESTER_ROLE = 'BrokerCodeRequester';
const BROKER_CODE_VIEWER_ROLE = 'BrokerCodeViewer';
const COMMISSIONS_VIEWER_ROLE = 'CommissionsViewer';

@Component({
  selector: 'atom-sales-code-detail',
  templateUrl: './sales-code-detail.component.html',
  styleUrls: ['./sales-code-detail.component.css'],
  providers: [BreakpointObserver]
})

export class SalesCodeDetailComponent implements OnDestroy, OnInit {

  // navigation
  createMode: boolean;
  editMode: boolean;
  addFcmMode: boolean;

  updateComplete = true;
  errorMessage: string;
  equalSplits = false;

  salesCodeForm: FormGroup = this.formBuilder.group({
    brokers: this.formBuilder.array([this.createBroker(100)], [Validators.required, this.leadBrokerValidator]),
    equalSplits: this.formBuilder.control(false),
    fcmRCG: this.formBuilder.control(false),
    fcmRJO: this.formBuilder.control(false),
    fcmEDF: this.formBuilder.control(false),
    fcmSF: this.formBuilder.control(false),
    fcmMac: this.formBuilder.control(false),
    fcmCun: this.formBuilder.control(false),
    managingPod: this.formBuilder.control('', [Validators.required]),
    brokerRels: this.formBuilder.array([]),
    salesCode: this.formBuilder.control('', [Validators.required, Validators.minLength(3), Validators.maxLength(3)])
  },
    {
      validators: [this.fcmValidator, this.splitsValidator]
    });

  // form data
  filteredBrokers: Observable<User[]>[] = [];
  brokerRelationships: BrokerRelationship[];
  splitsBrokerRel: BrokerRelationship; // ensure a primary (not secondary) broker rel is used to display and set brokers and splits for now
  brokers: User[];
  salesCode: SalesCode;
  podNames = Object.keys(PodEnum);

  secondaryLabel$: Observable<string>;
  secondaryLabel: string;
  private officeCode: string;

  hasRCGBrokerRel = false;
  hasRJOBrokerRel = false;
  hasEDFBrokerRel = false;
  hasSFBrokerRel = false;
  hasMacBrokerRel = false;
  hasCunBrokerRel = false;
  hasAllFcms = true;  // avoid showing + icon briefly before all broker relationships are mapped
  brokerRelBrokers = [];

  // data collections
  private activeBrokers$: Observable<User[]>;

  private unsubscribe$: Subject<void> = new Subject<void>();

  private equalSplitsTotal = 0;

  @ViewChild('formDirective', { static: false }) private formDirective: NgForm;

  // these are the office codes currently being used when a new broker relationship is established at a specific FCM
  // these values are not all-inclusive for office codes at a given FCM
  // the value will need to be updated if/when we move to a new office code for a FCM in the future
  private fcmOfficeCodes = {
    [FCM.RCG]: '277',
    [FCM.RJO]: '286',
    [FCM.EDF_MAN]: 'UAO',
    [FCM.STRAITS]: '131',
    [FCM.MACQUARIE]: '523',
    [FCM.CUNNINGHAM]: '421'
  };
  brokerName$: Observable<User>;
  list: any[];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private brokerRelationshipService: BrokerRelationshipService,
    private salesCodeService: SalesCodeService,
    private userService: UserService,
    private brokerService: BrokerService,
    private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.createMode = false;
    this.setEditMode(false);

    this.secondaryLabel$ = this.route.queryParamMap.pipe(
      switchMap((params: ParamMap) => {
        if (params.get('officeCode')) {
          this.officeCode = params.get('officeCode');
        } else {
          this.officeCode = '';
        }

        if (params.get('secondaryLabel')) {
          this.secondaryLabel = params.get('secondaryLabel');

        } else {
          this.secondaryLabel = '';
        }
        return of(undefined);
      })
    );

    const brokerUsers$: Observable<User[]> = this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          // display existing sales code detail and broker relationships if any for /salescodes/{docId}
          if (params.get('docId')) {
            if (!this.authzService.currentUserHasRole(BROKER_CODE_VIEWER_ROLE)) {
              return throwError('Broker Relationships data can only be viewed by users with the BrokerCodeViewer role');
            }
            return this.salesCodeService.getSalesCodeByDocId(params.get('docId'));
          }
          // display blank New Broker Relationship form for /brokerrelationships/new
          if (!this.brokerCodeRequester) {
            return throwError('New broker relationships can only be submitted by users with the BrokerCodeRequester role');
          }
          this.createMode = true;
          this.setEditMode(true);
          return of(undefined);
        }),
        switchMap((salesCode: SalesCode) => {
          this.salesCode = salesCode;
          if (salesCode) {
            return this.brokerRelationshipService.getBrokerRelationshipsBySalesCode(salesCode.docId);
          }
          return of([]);
        }),
        // retrieve user docs for brokers of existing broker relationships
        switchMap((brokerRelationships: BrokerRelationship[]) => {
          this.brokerRelationships = brokerRelationships;
          if (brokerRelationships.length > 0) {
            this.brokerRelationships.forEach(brokerRel => {
              this.brokerRelBrokers.push(this.getbrokersForBrokerRel(brokerRel));
            });
            if (this.secondaryLabel && this.officeCode) {
              this.splitsBrokerRel = brokerRelationships.find(
                brokerRel => brokerRel.secondaryLabel === this.secondaryLabel && brokerRel.officeCode === this.officeCode
              );
            } else {
              this.splitsBrokerRel = brokerRelationships.find(brokerRel => !brokerRel.isSecondary);
            }

            if (this.splitsBrokerRel.brokers.length > 0) {
              return combineLatest(this.splitsBrokerRel.brokers.map(brokerDocId => this.userService.getUserByDocId(brokerDocId)));
            }
            return of([]);
          }
          return of([]);
        }),
        // retrieve broker docs for brokers of existing broker relationships (needed to set broker type for inactive brokers)
        switchMap((brokerUsers: User[]) => {
          if (brokerUsers.length > 0) {
            return combineLatest(this.splitsBrokerRel.brokers.map(brokerDocId => this.brokerService.getBrokerByDocId(brokerDocId))).pipe(
              map((brokers: Broker[]) => {
                brokers.forEach((broker: Broker, index: number) => {
                  brokerUsers[index].brokerType = broker.type;
                });
                return brokerUsers;
              })
            );
          }
          return of([]);
        }),
        takeUntil(this.unsubscribe$)
      );

    brokerUsers$.subscribe((brokers: User[]) => {
      this.brokers = brokers;
      if (this.createMode) {
        this.prepForNewBrokerRelationship();
      } else {
        this.mapRetrievedBrokerRelationships();
      }
    }, err => {
      this.errorMessage = 'This broker relationship either does not exist or you do not have permission to view the information.';
      console.error(`Error occurred fetching broker relationship info: ${JSON.stringify(err)}`);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get brokerForms() {
    return this.salesCodeForm.get('brokers') as FormArray;
  }

  get brokerRelForms() {
    return this.salesCodeForm.get('brokerRels') as FormArray;
  }

  get commissionsViewer() {
    return this.authzService.currentUserHasRole(COMMISSIONS_VIEWER_ROLE);
  }

  get brokerCodeRequester() {
    return this.authzService.currentUserHasRole(BROKER_CODE_REQUESTER_ROLE);
  }

  /**
   * Sets editMode and enables or disables broker relationship form controls
   */
  setEditMode(mode: boolean) {
    this.editMode = mode;
    this.salesCodeForm.disable();
    if (this.createMode) {
      this.salesCodeForm.get('brokers').enable();
      this.salesCodeForm.get('equalSplits').enable();
      this.salesCodeForm.get('fcmRCG').enable();
      this.salesCodeForm.get('fcmRJO').enable();
      this.salesCodeForm.get('fcmEDF').enable();
      this.salesCodeForm.get('fcmSF').enable();
      this.salesCodeForm.get('fcmMac').enable();
      this.salesCodeForm.get('fcmCun').enable();
      this.salesCodeForm.get('managingPod').enable();
    } else if (this.editMode) {
      this.brokerRelForms.enable();
    }
    this.salesCodeForm.markAsPristine();
  }

  /**
   * Resets the broker relationship form.
   * When called during a new broker relationship, form is reset to default values.
   * When called while adding new broker relationships at additional FCMs, the FCM checkboxes are reset to the original values.
   * When called while editing an existing broker relationship, sets editMode to false and
   * reloads the form with original values retrieved from Firestore for the current broker relationship.
   */
  reset() {
    if (this.createMode) {
      this.equalSplits = false;
      while (this.brokerForms.length !== 0) {
        this.brokerForms.removeAt(0);
      }
      this.addBroker();
      this.brokerForms.at(0).get('split').setValue(100.00);
      // reset the filtering for the broker autocomplete
      this.filteredBrokers.length = 0;
      this.filteredBrokers.push(this.filterBrokers(this.brokerForms.at(0).get('brokerUser')));
      this.salesCodeForm.get('managingPod').setValue('');
      this.salesCodeForm.markAsPristine();
    } else if (this.addFcmMode) {
      this.addFcmMode = false;
      this.salesCodeForm.get('fcmRCG').setValue(false);
      this.salesCodeForm.get('fcmRJO').setValue(false);
      this.salesCodeForm.get('fcmEDF').setValue(false);
      this.salesCodeForm.get('fcmSF').setValue(false);
      this.salesCodeForm.get('fcmMac').setValue(false);
      this.salesCodeForm.get('fcmCun').setValue(false);
      this.mapExistingFcms();
    } else {
      this.setEditMode(false);
      this.mapRetrievedBrokerRelationships();
    }
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('invalidCharacter')) {
      return 'Error: invalid character';
    } else if (control.hasError('min')) {
      return 'Error: less than 0.01';
    } else if (control.hasError('max')) {
      return 'Error: greater than 100';
    } else if (control.hasError('invalidSplitNumber')) {
      return 'Error: more than 8 decimals';
    } else if (control.hasError('required')) {
      return 'Error: Value required';
    } else {
      return 'Unknown error';
    }
  }

  /**
   * Is the logged in user a Commissions Viewer or a broker on the current broker relationship
   */
  canUserViewSplits() {
    return this.commissionsViewer ||
      this.splitsBrokerRel.brokers.find(broker => broker === this.authService.userProfile.app_metadata.firestoreDocId);
  }

  /**
   * Is the logged in user an Account Admin and is any broker relationship in a status that allows an update
   */
  canUserEditBrokerRel() {
    if (this.authzService.currentUserHasRole(ACCOUNT_ADMIN_ROLE) && this.brokerRelationships) {
      return this.brokerRelationships.some(
        brokerRel => brokerRel.status === Status.PENDING || brokerRel.status === Status.ACTIVE || brokerRel.status === Status.INACTIVE);
    }
    return false;
  }

  /**
   * Is the logged in user an Account Admin and are there any broker relationships in a pending or active status
   */
  canAddNewAccountSeries() {
    if (this.authzService.currentUserHasRole(ACCOUNT_ADMIN_ROLE) && this.brokerRelationships) {
      return this.brokerRelationships.some(brokerRel => brokerRel.status === Status.PENDING || brokerRel.status === Status.ACTIVE);
    }
    return false;
  }

  /**
   * Displays "firstName lastName" for broker autocomplete input field
   */
  displayBroker(user?: User | string): string | undefined {
    if (typeof user === 'string') {
      return user;
    }
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  /**
   * Adds a new broker FormGroup to the brokers FormArray when the Add icon is clicked by the user.
   * Adjusts the splits for all brokers if the equal splits checkbox is checked.
   * Marks the main form dirty to have the Discard Changes icon available to reset the form.
   */
  addBroker() {
    this.brokerForms.push(this.createBroker(0));
    if (this.equalSplits) {
      this.brokerForms.at(this.brokerForms.length - 1).get('split').disable();
      this.setEqualSplitValues();
    }
    this.filteredBrokers.push(this.filterBrokers(this.brokerForms.at(this.brokerForms.length - 1).get('brokerUser')));
    this.salesCodeForm.markAsDirty();
  }

  /**
   * Removes a broker FormGroup from the FormArray if removed by the user.
   * Adjusts the splits for the remaining brokers if the equal splits checkbox is checked.
   * Sets the split to 100 if only one broker is remaining.
   */
  removeBroker(i) {
    this.brokerForms.removeAt(i);
    // adjust splits if equalSplits selected and # of brokers changes
    if (this.equalSplits) {
      this.setEqualSplitValues();
    }
    this.filteredBrokers.splice(i, 1);
    // auto-populate split as 100 if only one broker is left
    if (this.brokerForms.length === 1) {
      this.equalSplits = false;
      this.brokerForms.at(0).get('split').setValue(100);
      this.brokerForms.at(0).get('split').enable();
      this.salesCodeForm.get('equalSplits').setValue(false);
    }
  }

  /**
   * Called on blur of any brokerUser FormControl.
   * Simply triggers valueChanges for each brokerUser control in order to properly update
   * the filtered list of available brokers.
   */
  refreshAvailableBrokers() {
    this.brokerForms.controls.forEach(element => {
      const value = element.get('brokerUser').value;
      element.get('brokerUser').setValue(value);
    });
  }

  /**
   * When the equal splits checkbox is checked, the split FormControls are disabled
   * and the split values are set to be equal for each broker.
   * The split FormControls are enabled when the checkbox is unchecked.
   */
  onEqualSplitsChange($event: MatCheckboxChange) {
    if ($event.checked) {
      this.equalSplits = true;
      this.brokerForms.controls.forEach(element => {
        element.get('split').disable();
      });
      this.setEqualSplitValues();
    } else {
      this.equalSplits = false;
      this.brokerForms.controls.forEach(element => {
        element.get('split').enable();
        element.get('split').setValue('');
      });
    }
  }

  /**
   * Called on submit of Create/Update Broker Relationship form
   */
  submit() {
    this.updateComplete = false;
    this.setEditMode(false);
    if (this.createMode) {
      this.createNewBrokerRelationshipAndAssignSalesCode();
    } else if (this.addFcmMode) {
      this.addFcmMode = false;
      this.createNewBrokerRelationship();
    } else {
      this.updateBrokerRelationship();
    }
  }

  addFcm() {
    this.addFcmMode = true;
    if (!this.hasRCGBrokerRel) {
      this.salesCodeForm.get('fcmRCG').enable();
    }
    if (!this.hasRJOBrokerRel) {
      this.salesCodeForm.get('fcmRJO').enable();
    }
    if (!this.hasEDFBrokerRel) {
      this.salesCodeForm.get('fcmEDF').enable();
    }
    if (!this.hasSFBrokerRel) {
      this.salesCodeForm.get('fcmSF').enable();
    }
    if (!this.hasMacBrokerRel) {
      this.salesCodeForm.get('fcmMac').enable();
    }
    if (!this.hasCunBrokerRel) {
      this.salesCodeForm.get('fcmCun').enable();
    }
  }

  selectAccountsIcon(brokerCodeVal: string) {
    this.router.navigate(['/accounts'], { queryParams: { brokerCode: brokerCodeVal } });
  }

  getBrokerRelAtIndex(index): FormGroup {
    const brokerRel = this.brokerRelationships[index];
    const formGroup: FormGroup = this.formBuilder.group({
      extendedCode: [brokerRel.extendedCode, [Validators.minLength(5), Validators.maxLength(5)]],
      status: [brokerRel.status, [Validators.required]],
      isFcmApproved: [brokerRel.isFcmApproved],
      isPlatformsSetupComplete: [brokerRel.isPlatformsSetupComplete],
      hasErrorAndAccommodationAccounts: [brokerRel.hasErrorAndAccommodationAccounts],
      hasErrorAccountLimits: [brokerRel.hasErrorAccountLimits]
    });
    formGroup.disable();
    return formGroup;
  }

  onManagingPodChange(event) {
    if (event.value === 'HOUSE') {
      while (this.brokerForms.length !== 0) {
        this.brokerForms.removeAt(0);
      }
      this.salesCodeForm.get('brokers').setValidators(this.leadBrokerValidator);
    } else {
      this.salesCodeForm.get('brokers').setValidators([Validators.required, this.leadBrokerValidator]);
    }
    this.salesCodeForm.get('brokers').updateValueAndValidity();
  }

  // This function works like toFixed() but does not round up the last number displayed
  displaySplit(split: number) {
    const calcDec = Math.pow(10,2);
    return Math.trunc((split*100) * calcDec) / calcDec;
  }

  // creates and returns a new broker FormGroup for the brokers FormArray
  // containing brokerUser and split FormControls for individual brokers
  private createBroker(split: number): FormGroup {
    return this.formBuilder.group({
      brokerUser: ['', [Validators.required, this.brokerValidator]],
      leadBroker: false,
      split: [split, [
        Validators.required,
        Validators.min(0.01),
        Validators.max(100.00),
        SplitValidators.splitNumberValidator,
        SplitCharacterValidators.splitCharacterValidator
      ]]
    });
  }

// sets the split value for each broker to be an equal split based on the number of brokers
private setEqualSplitValues() {
  const split = 100 / this.brokerForms.length;
  // convert split value to whole number
  const newSplit = Math.trunc(split * 100000000);
  this.equalSplitsTotal = 0;
  this.brokerForms.controls.forEach(element => {
    element.get('split').setValue(this.roundEqualSplits(newSplit));
  });
}

// makes equal-split-values equal 100
private roundEqualSplits(split: number) {

  // finds difference between split whole number total and 10000
  const percentagesTotal = split * this.brokerForms.length;
  const testValue = 10000000000 - percentagesTotal;

  let newSplit = 0;

  // if split total is less than difference, add 1 to current split
  if (this.equalSplitsTotal < testValue) {
    newSplit = (split + 1);
    this.equalSplitsTotal++;
    // revert split percentage to decimal format and return
    return newSplit / 100000000;
  }
  this.equalSplitsTotal++;
  return split / 100000000;
}

  // Gets available brokers (active brokers) for autocomplete
  private prepForNewBrokerRelationship() {
    this.getActiveBrokers();
    this.filteredBrokers.push(this.filterBrokers(this.brokerForms.at(0).get('brokerUser')));
  }

  // Gets active brokers from Firestore
  private getActiveBrokers() {
    this.activeBrokers$ = this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId)
      .pipe(
        switchMap((loggedInUser: User) => {
          return this.userService.getBrokerUsersByClientDocId(loggedInUser.clientDocId);
        }),
        shareReplay(1)
      );
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting brokers
  private filterBrokers(brokerForm: AbstractControl) {
    return brokerForm.valueChanges
      .pipe(
        debounceTime(300),
        startWith<string | User>(''),
        switchMap(term => typeof term === 'string' ? this.filterBrokersBySearchTerm(term) : of([term]))
      );
  }

  // Filters possible brokers using the user input search term
  private filterBrokersBySearchTerm(searchTerm: string): Observable<User[]> {
    return this.activeBrokers$
      .pipe(
        map(brokers => brokers.filter(
          // filter out brokers already selected
          broker => this.brokerForms.controls.find(control => control.get('brokerUser').value.docId === broker.docId) ? false : true)),
        map(brokers => brokers.filter(broker => this.matchName(searchTerm, broker)))
      );
  }

  // Checks for a partial match on user/mentor first or last name
  private matchName(searchTerm: string, user: User) {
    const searchValue = searchTerm.toLowerCase();
    const firstName = user.firstName.toLowerCase();
    const lastName = user.lastName.toLowerCase();

    const fullName = `${firstName} ${lastName}`;
    const lastFirst = `${lastName} ${firstName}`;
    const lastCommaFirst = `${lastName}, ${firstName}`;
    return fullName.includes(searchValue) || lastFirst.includes(searchValue) || lastCommaFirst.includes(searchValue);
  }

  // Maps values from broker relationship form to new BrokerRelationship(s),
  // determines the next available sales code, updates that sales code status, and
  // creates new broker relationship(s) in Firestore, and routes to sales code URL
  private createNewBrokerRelationshipAndAssignSalesCode() {
    const fcms = this.getRequestedFcms();
    const brokerData = this.getBrokerData();
    const managingPod = this.salesCodeForm.get('managingPod').value;
    const brokerRels: BrokerRelationship[] = fcms.map(fcm => {
      const brokerRel = new BrokerRelationship();
      brokerRel.status = Status.NEW;
      brokerRel.officeCode = this.fcmOfficeCodes[fcm];
      brokerRel.fcm = fcm;
      brokerRel.brokers = brokerData.brokers;
      brokerRel.splits = brokerData.splits;
      brokerRel.managingPod = managingPod;
      brokerRel.leadBrokerDocId = brokerData.leadBroker;
      brokerRel.originator = this.authService.userProfile.app_metadata.firestoreDocId;
      brokerRel.equalSplits = this.equalSplits;
      return brokerRel;
    });

    this.brokerRelationshipService.createBrokerRelationshipAndAssignSalesCode(brokerRels)
      .then(() => {
        this.updateComplete = true;
        this.openSnackBar('Broker relationship successfully created', 'DISMISS', true);
        this.router.navigate([`/brokerrelationships`], { queryParams: { brokerCode: this.officeCode + this.salesCode } });
      })
      .catch(err => {
        this.updateComplete = true;
        console.error('Broker Relationship(s) creation failed: ' + (err.code ? JSON.stringify(err) : err));
        let errorMessage = '';
        if (err.code === 'permission-denied') {
          errorMessage = 'Insufficient permissions';
        } else if (err.message === 'No NEW sales code available!') {
          errorMessage = 'No unassigned sales code available';
        } else if (err.message === 'Sales code is already assigned') {
          errorMessage = 'Please try again';
        } else {
          errorMessage = 'Unknown error occurred';
        }
        this.openSnackBar(`Broker relationship creation failed: ${errorMessage}`, 'DISMISS', false);
      });
  }

  // Maps sales code, brokers, and splits values from existing broker relationships to new BrokerRelationship(s)
  // for newly selected FCM(s) and creates new broker relationship(s) in Firestore
  private createNewBrokerRelationship() {
    const fcms = this.getRequestedFcms();
    const brokerRels: BrokerRelationship[] = fcms.map(fcm => {
      const brokerRel = new BrokerRelationship();
      brokerRel.status = Status.NEW;
      brokerRel.officeCode = this.fcmOfficeCodes[fcm];
      brokerRel.fcm = fcm;
      brokerRel.salesCode = this.splitsBrokerRel.salesCode;
      brokerRel.salesCodeDocId = this.splitsBrokerRel.salesCodeDocId;
      brokerRel.brokerCode = brokerRel.officeCode + brokerRel.salesCode;
      brokerRel.brokers = this.splitsBrokerRel.brokers;
      brokerRel.splits = this.splitsBrokerRel.splits;
      brokerRel.originator = this.authService.userProfile.app_metadata.firestoreDocId;
      return brokerRel;
    });

    Promise.all(
      // create a new broker relationship doc for each newly requested FCM
      brokerRels.map((brokerRel: BrokerRelationship) => {
        return this.brokerRelationshipService.createBrokerRelationship(brokerRel);
      })
    )
      .then(() => {
        this.updateComplete = true;
        this.openSnackBar('Broker relationship successfully created', 'DISMISS', true);
      })
      .catch(err => {
        console.error(`Broker Relationship(s) creation failed: ${JSON.stringify(err)}`);
        this.updateComplete = true;
        let errorMessage = '';
        switch (err.code) {
          case 'permission-denied':
            errorMessage = 'Insufficient permissions';
            break;
          default:
            errorMessage = 'Unknown error occurred';
        }
        this.openSnackBar(`Broker relationship creation failed: ${errorMessage}`, 'DISMISS', false);
      });
  }

  // determine which FCMs were selected on form when creating new broker relationship(s)
  private getRequestedFcms() {
    const fcms = [];
    if (!this.hasRCGBrokerRel && this.salesCodeForm.value.fcmRCG) {
      fcms.push(FCM.RCG);
    }
    if (!this.hasRJOBrokerRel && this.salesCodeForm.value.fcmRJO) {
      fcms.push(FCM.RJO);
    }
    if (!this.hasEDFBrokerRel && this.salesCodeForm.value.fcmEDF) {
      fcms.push(FCM.EDF_MAN);
    }
    if (!this.hasSFBrokerRel && this.salesCodeForm.value.fcmSF) {
      fcms.push(FCM.STRAITS);
    }
    if (!this.hasMacBrokerRel && this.salesCodeForm.value.fcmMac) {
      fcms.push(FCM.MACQUARIE);
    }
    if (!this.hasCunBrokerRel && this.salesCodeForm.value.fcmCun) {
      fcms.push(FCM.CUNNINGHAM);
    }
    return fcms;
  }

  // get brokers and broker splits from form data when creating a new broker relationship
  private getBrokerData() {
    const brokers = [];
    const splits = [];
    let leadBroker = '';
    this.brokerForms.controls.forEach(broker => {
      const brokerDocId = broker.get('brokerUser').value.docId;
      brokers.push(brokerDocId);
      // manipulates split value to limit decimal digits in database
      splits.push({ brokerDocId, split: (Math.trunc(broker.get('split').value * 100000000) / 10000000000) });
      if (broker.get('leadBroker').value === true) {
        leadBroker = brokerDocId;
      }
    });
    if (brokers.length === 1) {
      leadBroker = brokers[0];
    }
    return { brokers, splits, leadBroker };
  }

  // Maps all data retrieved from Firestore for current broker relationships to values in the broker relationship form
  private mapRetrievedBrokerRelationships() {
    if (this.brokerRelationships.length > 0) {
      this.mapExistingFcms();
      this.mapExistingBrokerRels();
    }
  }

  // set FCM form values for existing broker relationships
  private mapExistingFcms() {
    this.brokerRelationships.forEach(brokerRel => {
      if (brokerRel.fcm === FCM.RCG) {
        this.hasRCGBrokerRel = true;
        this.salesCodeForm.get('fcmRCG').setValue(true);
      }
      if (brokerRel.fcm === FCM.RJO) {
        this.hasRJOBrokerRel = true;
        this.salesCodeForm.get('fcmRJO').setValue(true);
      }
      if (brokerRel.fcm === FCM.EDF_MAN) {
        this.hasEDFBrokerRel = true;
        this.salesCodeForm.get('fcmEDF').setValue(true);
      }
      if (brokerRel.fcm === FCM.STRAITS) {
        this.hasSFBrokerRel = true;
        this.salesCodeForm.get('fcmSF').setValue(true);
      }
      if (brokerRel.fcm === FCM.MACQUARIE) {
        this.hasMacBrokerRel = true;
        this.salesCodeForm.get('fcmMac').setValue(true);
      }
      if (brokerRel.fcm === FCM.CUNNINGHAM) {
        this.hasCunBrokerRel = true;
        this.salesCodeForm.get('fcmCun').setValue(true);
      }
    });
    this.hasAllFcms = this.hasRCGBrokerRel && this.hasRJOBrokerRel
      && this.hasEDFBrokerRel && this.hasSFBrokerRel && this.hasMacBrokerRel
      && this.hasCunBrokerRel;
  }

  // adds a new broker relationship FormGroup to the brokerRels FormArray for each retrieved BrokerRelationship
  private mapExistingBrokerRels() {
    this.brokerRelForms.controls = [];
    this.brokerRelationships.forEach(brokerRel => {
      this.brokerRelForms.push(this.getBrokerRelFormGroup(brokerRel));
    });
  }

  // returns a new FormGroup containg FormControls with values for an individual broker relationship
  private getBrokerRelFormGroup(brokerRel: BrokerRelationship): FormGroup {
    const formGroup: FormGroup = this.formBuilder.group({
      extendedCode: [brokerRel.extendedCode, [Validators.minLength(5), Validators.maxLength(5)]],
      status: [brokerRel.status, [Validators.required]],
      isFcmApproved: [brokerRel.isFcmApproved],
      isPlatformsSetupComplete: [brokerRel.isPlatformsSetupComplete],
      hasErrorAndAccommodationAccounts: [brokerRel.hasErrorAndAccommodationAccounts],
      hasErrorAccountLimits: [brokerRel.hasErrorAccountLimits]
    });
    formGroup.disable();
    return formGroup;
  }

  private updateBrokerRelationship() {
    const updatedBrokerRels: BrokerRelationship[] = [];
    this.brokerRelationships.forEach((brokerRel: BrokerRelationship, index) => {
      const formValues = (this.brokerRelForms.at(index) as FormGroup).getRawValue();
      if (brokerRel.status === Status.PENDING) {
        // only set extended code for RCG broker relationships
        if (brokerRel.fcm === FCM.RCG) {
          brokerRel.extendedCode = formValues.extendedCode || '';
        }
        brokerRel.isFcmApproved = formValues.isFcmApproved;
        brokerRel.isPlatformsSetupComplete = formValues.isPlatformsSetupComplete;
        brokerRel.hasErrorAndAccommodationAccounts = formValues.hasErrorAndAccommodationAccounts;
        brokerRel.hasErrorAccountLimits = formValues.hasErrorAccountLimits;
        updatedBrokerRels.push(brokerRel);
      }
      // only status updates to handle for non-pending broker relationships
      if (brokerRel.status !== formValues.status) {
        brokerRel.status = formValues.status;
        if (brokerRel.status === Status.ACTIVE) {
          brokerRel.activationDate = new Date().toISOString();
        }
        if (brokerRel.status === Status.INACTIVE) {
          brokerRel.inactivationDate = new Date().toISOString();
        }
        if (brokerRel.status === Status.CLOSED) {
          brokerRel.closureDate = new Date().toISOString();
        }
        updatedBrokerRels.push(brokerRel);
      }
    });

    Promise.all(
      // update each existing broker relationship doc with new form values
      updatedBrokerRels.map((brokerRel: BrokerRelationship) => {
        return this.brokerRelationshipService.updateBrokerRelationship(brokerRel);
      })
    )
      .then(() => {
        this.updateComplete = true;
        this.openSnackBar('Broker relationship successfully updated', 'DISMISS', true);
      })
      .catch(err => {
        console.error(`Broker Relationship(s) update failed: ${JSON.stringify(err)}`);
        this.updateComplete = true;
        let errorMessage = '';
        switch (err.code) {
          case 'permission-denied':
            errorMessage = 'Insufficient permissions';
            break;
          default:
            errorMessage = 'Unknown error occurred';
        }
        this.openSnackBar(`Broker relationship update failed: ${errorMessage}`, 'DISMISS', false);
      });
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  // The broker value is invalid if it's a string/partial search term.
  // The value becomes valid once a User object is selected.
  private brokerValidator(control: FormControl) {
    return typeof control.value !== 'string' ? null : { invalidBroker: true };
  }

  // Brokers FormArray value is invalid if the split(s) don't equal 100
  private splitsValidator(control: FormControl) {
    const brokerForms = control.get('brokers') as FormArray;
    const isHouse = control.get('managingPod').value === 'HOUSE';
    let splitTotal = 0.00;
    let revisedSplit = 0.00;
    brokerForms.controls.forEach(element => {
      const split = Number(element.get('split').value) * 100;
      splitTotal += split;
      revisedSplit = splitTotal / 100;
    });
    return (revisedSplit === 100.00 || (!brokerForms.length && isHouse)) ? null : { invalidSplits: true };
  }

  private leadBrokerValidator(brokerForms: FormArray) {
    const leads = [];
    if (brokerForms.controls.length === 1) {
      leads.push(brokerForms.controls[0]);
    } else {
      brokerForms.controls.forEach(element => {
        if (element.get('leadBroker').value === true) {
          leads.push(element);
        }
      });
    }
    return (leads.length === 1 || !brokerForms.controls.length) ? null : { invalidLeadBroker: true};
  }

  // The broker relationship form is invalid if at least one FCM is not selected
  private fcmValidator(control: FormControl) {
    const fcmSelected = control.get('fcmRCG').value || control.get('fcmRJO').value
      || control.get('fcmEDF').value || control.get('fcmSF').value || control.get('fcmMac').value
      || control.get('fcmCun').value;
    return fcmSelected ? null : { invalidFCM: true };
  }

  isLeadBroker(broker: Broker) {
    if (broker.docId === this.splitsBrokerRel.leadBrokerDocId) {
      return true;
    }
  }

  navigateBroker(broker: Broker) {
    this.router.navigate([`/brokers/${broker.docId}`]);
  }

  navigateBrokerRelationship(brokerRel: BrokerRelationship) {
    this.router.navigate([`/brokerrelationships/${brokerRel.docId}`]);
  }

  getbrokersForBrokerRel(brokerRel: BrokerRelationship) {
    return combineLatest(brokerRel.brokers.map(broker => {
      return this.userService.getUserByDocId(broker);
    }));
  }
}
