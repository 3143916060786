<div fxFlex="100">
  <div>
    <div *ngIf="!errorMessage" fxLayout="column">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="adjustments-filter-field" #filter>
        <button mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <button *ngIf="exportable && selectedAdjustments$ | async" aria-label="Export" id="export-adjustments" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
      <mat-menu #exportMenu="matMenu">
        <button mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Trades'))">Excel</button>
        <button mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Trades'))">CSV</button>
        <button mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Trades'))">JSON</button>
      </mat-menu>

      <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort matSortActive="transactionDate" matSortDirection="desc" class="mat-elevation-z0" (matSortChange)="handleSortChange()">

        <ng-container matColumnDef="transactionDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.gt-sm="10">Business Date</mat-header-cell>
          <mat-cell *matCellDef="let adjustment" fxFlex.xs="33" fxFlex.gt-sm="10">{{ adjustment.transactionDate | date: 'shortDate' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="businessDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.gt-sm="9">Trade Date</mat-header-cell>
          <mat-cell *matCellDef="let adjustment" fxFlex.xs="33" fxFlex.gt-sm="9">{{ adjustment.businessDate | date: 'shortDate' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="brokerCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-sm="9">Broker Code</mat-header-cell>
          <mat-cell *matCellDef="let adjustment" fxFlex.xs="30" fxFlex.gt-sm="9">{{ adjustment.officeCode + adjustment.salesCode}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="accountNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.gt-sm="9">Account</mat-header-cell>
          <mat-cell *matCellDef="let adjustment" fxFlex.xs="33" fxFlex.gt-sm="9">{{ adjustment.accountNumber }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="commission">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-sm="9">Commission</mat-header-cell>
          <mat-cell *matCellDef="let adjustment" fxFlex.xs="30" fxFlex.gt-sm="9">{{ adjustment.commission ? (adjustment.commission | number : '1.2-5') : 0 }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.gt-sm="9">Amount</mat-header-cell>
          <mat-cell *matCellDef="let adjustment" fxFlex.xs="33" fxFlex.gt-sm="9">{{ adjustment.amount ? (adjustment.amount | number: '1.2-5') : 0 }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="clearingFee">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-sm="8">Clearing Fee</mat-header-cell>
          <mat-cell *matCellDef="let adjustment" fxFlex.xs="30" fxFlex.gt-sm="8">{{ adjustment.clearingFee ? (adjustment.clearingFee | number : '1.2-5') : 0}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="exchangeFee">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-sm="9">Exchange Fee</mat-header-cell>
          <mat-cell *matCellDef="let adjustment" fxFlex.xs="30" fxFlex.gt-sm="9">{{ adjustment.exchangeFee ? (adjustment.exchangeFee | number : '1.2-5') : 0 }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="nfaFee">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-sm="9">NFA Fee</mat-header-cell>
          <mat-cell *matCellDef="let adjustment" fxFlex.xs="30" fxFlex.gt-sm="9">{{ adjustment.nfaFee ? (adjustment.nfaFee | number : '1.2-5') : 0}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="securityDescription">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-sm="10">Detail</mat-header-cell>
          <mat-cell *matCellDef="let adjustment" fxFlex.gt-sm="10">{{ adjustment.securityDescription }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="otherCharges">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-sm="9">Other Charges</mat-header-cell>
          <mat-cell *matCellDef="let adjustment" fxFlex.xs="30" fxFlex.gt-sm="9">{{ adjustment.otherCharges ? (adjustment.otherCharges | number : '1.2-5') : 0}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row  *matRowDef="let adjustment; columns: columnsToDisplay" (click)="selectAdjustment(adjustment)"></mat-row>

      </mat-table>
      <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50, 100]" (page)="handlePageChange()"></mat-paginator>
    </div>
  </div>
</div>

