import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpHeaders } from '@angular/common/http';

import { AuthService } from '@advance-trading/angular-ati-security';
import { Account, AccountPurpose, Margin, MarginType } from '@advance-trading/ops-data-lib';

import { Apollo } from 'apollo-angular';
import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { AccountService } from './account-service';
import { MarginSearchCriteria } from './service-interfaces/margin-search-interface';
import { MarginQueries } from './graphql-queries/margin-queries';

@Injectable({
  providedIn: 'root'
})
export class MarginService {

  constructor(
    private accountService: AccountService,
    private apollo: Apollo,
    private authService: AuthService,
    private db: AngularFirestore,
  ) { }

  /**
   * Calculates Owed value for a Margin
   *
   * @param margin The Margin object
   */
   calculateOwed(margin: Margin): number {
    if (!margin.withdrawable) {
      if (margin.marginType === MarginType.COMMERCIAL) {
        if (margin.marginCallAge === 0 && margin.marginCallRecordsCount === 0
          && margin.excessDeficit > 0 && margin.totalEquity < 0) {
          return 0;
        } else if (margin.marginCallAge > 1) {
          return Math.min(margin.excessDeficit, margin.totalCall);
        } else {
          return margin.excessDeficit;
        }
      } else {
        return Math.min(margin.totalCall, margin.excessDeficit, margin.totalEquity);
      }
    }
    return 0;
  }

  // Queries Using GET

  /**
   * Retrieves Margin from marginDocId
   * @param accountDocId The docId of the Account containing the Margin
   * @param marginDocId The docId of the Margin
   */
  findMarginByDocId(accountDocId: string, marginDocId: string): Observable<Margin> {
    return this.db.doc<Margin>(`${Account.getDataPath(accountDocId)}/${Margin.getDataPath(marginDocId)}`).valueChanges()
      .pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Returns all Margin documents for the specified Account
   * @param accountDocId The docId of the Account containing the Margin
   */
  getMarginsForAccount(accountDocId: string, searchParams: MarginSearchCriteria): Observable<Margin[]> {
    return this.db.collection<Margin>(`${Account.getDataPath(accountDocId)}/${Margin.getDataPath()}`, ref => {
      let finalRef = ref as firebase.default.firestore.Query<firebase.default.firestore.DocumentData>;

      if (searchParams.withdrawable) {
        finalRef = finalRef.where('withdrawable', '>', 0);
      }
      return finalRef;
    }).get().pipe(
      map(querySnap => querySnap.docs.map(doc => doc.data() as Margin) as Margin[])
    );
  }

  /**
   * Retrieve all Margin documents for a given accountNumber
   * @param accountNumber The Account number of the Account containing the Margin
   */
  getMarginsByAccountNumber(accountNumber: string, searchParams: MarginSearchCriteria): Observable<Margin[]> {
    return this.accountService.retrieveAccountsByNumber(accountNumber)
      .pipe(
        switchMap((accounts: Account[]) => {
          if (accounts.length > 0) {
            const observableSubQueries = [];
            for (const account of accounts) {
              observableSubQueries.push(this.getMarginsForAccount(account.docId, searchParams));
            }
            return combineLatest(observableSubQueries).pipe(
              map(margins => (margins as Margin[][]).flat())
            ).pipe(
              map(margins => {
                if (searchParams.owed) {
                  margins = margins.filter(margin => this.calculateOwed(margin) < 0);
                }

                if (searchParams.startDate) {
                  margins = margins.filter(margin =>
                    moment(margin.processDate).isSameOrAfter(moment(searchParams.startDate)));
                }

                if (searchParams.endDate) {
                  margins = margins.filter(margin =>
                    moment(margin.processDate).isSameOrBefore(moment(searchParams.endDate)));
                }
                return margins;
              })
            );
          } else {
            return of([]);
          }
        })
      );
  }

  /**
   * Retrieve all Margin documents for a given brokerCode
   * @param brokerCode The combined office code and sales code associated with a margin
   */
  getMarginsByBrokerCode(brokerCode: string, searchParams: MarginSearchCriteria): Observable<Margin[]> {
    return this.accountService.retrieveAccountsByBrokerCode(brokerCode)
    .pipe(
      switchMap((accounts: Account[]) => {
        if (accounts.length > 0) {
          // If ADTR account only show its margins and not margins for rest of accounts
          // within the margin group
          accounts = accounts.filter(acct => acct.purpose === AccountPurpose.MARGIN_GROUP || !acct.marginGroupAccountDocId);

          const observableSubQueries = [];
          for (const account of accounts) {
            observableSubQueries.push(this.getMarginsForAccount(account.docId, searchParams));
          }
          return combineLatest(observableSubQueries).pipe(
            map(margins => (margins as Margin[][]).flat())
          ).pipe(
            map(margins => {
              if (searchParams.owed) {
                margins = margins.filter(margin => this.calculateOwed(margin) < 0);
              }

              if (searchParams.startDate) {
                margins = margins.filter(margin =>
                  moment(margin.processDate).isSameOrAfter(moment(searchParams.startDate)));
              }

              if (searchParams.endDate) {
                margins = margins.filter(margin =>
                  moment(margin.processDate).isSameOrBefore(moment(searchParams.endDate)));
              }
              return margins;
            })
          );
        } else {
          return of([]);
        }
      })
    );
  }

  // Queries Using ValueChanges

  /**
   * Retrieves all Accounts and all Margins associated with those Accounts
   */
  findAllMargins(): Observable<Margin[]> {
    return this.accountService.getAllAccounts().pipe(
      switchMap((accounts: Account[]) => {
        if (accounts.length > 0) {
          const observableSubQueries = [];
          for (const account of accounts) {
            observableSubQueries.push(this.findMarginsForAccount(account.docId));
          }
          return combineLatest(observableSubQueries).pipe(
            map(margins => (margins as Margin[][]).flat()),
          );
        } else {
          return of([]);
        }
      })
    );
  }

  /**
   * Returns all Margin documents for the specified Account
   * @param accountDocId The docId of the Account containing the Margin
   */
  findMarginsForAccount(accountDocId: string): Observable<Margin[]> {
    return this.db.collection<Margin>(`${Account.getDataPath(accountDocId)}/${Margin.getDataPath()}`).valueChanges()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Retrieve all Margin documents for a given accountNumber
   * @param accountNumber The Account number of the Account containing the Margin
   */
  findMarginsByAccountNumber(accountNumber: string): Observable<Margin[]> {
    return this.accountService.getAccountsByNumber(accountNumber).pipe(
      switchMap((accounts: Account[]) => {
        if (accounts.length > 0) {
          const observableSubQueries = [];
          for (const account of accounts) {
            observableSubQueries.push(this.findMarginsForAccount(account.docId));
          }
          return combineLatest(observableSubQueries).pipe(
            map(margins => (margins as Margin[][]).flat())
          );
        } else {
          return of([]);
        }
      })
    );
  }

  /**
   * Retrieve all Margin documents for a given brokerCode
   * @param brokerCode The combined office code and sales code associated with a margin
   */
  findMarginsByBrokerCode(brokerCode: string): Observable<Margin[]> {
    return this.accountService.getAccountsByBrokerCode(brokerCode).pipe(
      switchMap((accounts: Account[]) => {
        if (accounts.length > 0) {
          // If ADTR account only show its margins and not margins for rest of accounts
          // within the margin group
          accounts = accounts.filter(acct => acct.purpose === AccountPurpose.MARGIN_GROUP || !acct.marginGroupAccountDocId);

          const observableSubQueries = [];
          for (const account of accounts) {
            observableSubQueries.push(this.findMarginsForAccount(account.docId));
          }
          return combineLatest(observableSubQueries).pipe(
            map(margins => (margins as Margin[][]).flat())
          );
        } else {
          return of([]);
        }
      })
    );
  }

  // GraphQL Queries

  /**
   * Gets all of the Margins for given accounts or accounts associated with a margin group account and search parameters
   * @param accountNumbers The Account numbers of the Accounts containing the Margins
   * @param gqlParams an interface populated with search criteria
   */
  getMarginsByAccountNumbers(accountNumbers: string[], gqlParams: any): Observable<Margin[]> {
    if (accountNumbers.length === 0) {
      return this.getMarginsByAccountsQuery(accountNumbers, gqlParams);
    }
    return combineLatest(
      accountNumbers.map((accountNumber) => {
        return this.accountService.retrieveActiveAccountsByNumber(accountNumber);
      })
    ).pipe(map((accounts) => accounts.flat()))
      .pipe(switchMap((accounts) => {
        if (accounts.length === 0) {
          return of([]);
        }
        const observableAccounts = [];
        accounts.forEach((account: Account) => {
          // For margin group accounts fetch all accounts that are associated with the margin group account
          if (account.purpose === AccountPurpose.MARGIN_GROUP) {
            observableAccounts.push(this.accountService.retrieveAccountsByMarginGroupAccountDocId(account.marginGroupAccountDocId));
          }
        });

        if (observableAccounts.length) {
          return combineLatest(observableAccounts).pipe(
            map(accts => accts.flat()),
            switchMap((accts: Account[]) => {

              // Remove duplicate accounts before getting margins for each account
              const duplicates = [];
              accts = accts.filter(acct => duplicates.includes(acct.docId) ? false : duplicates.push(acct.docId));
              const acctNumbers = accts.map((account: Account): string => account.number);
              return this.getMarginsByAccountsQuery(acctNumbers, gqlParams);
            })
          );
        }
        accountNumbers = accounts.map(account => account.number);
        return this.getMarginsByAccountsQuery(accountNumbers, gqlParams);
      }));
  }

  /**
   * Gets all of the Margins for given accounts and search parameters by a graphql query
   * @param accounts The Account numbers of the Accounts containing the Margins
   */
  private getMarginsByAccountsQuery(accounts: string[], searchParams: MarginSearchCriteria): Observable<Margin[]> {
    const marginQuery = {
      businessDateStart: searchParams.startDate,
      businessDateEnd: searchParams.endDate,
      withdrawable: searchParams.withdrawable
    };
    return this.apollo.query({
      query: MarginQueries.getMarginsByAccountsQuery,
      variables: {
        accounts,
        marginQuery
      },
      context: {
        withCredentials: true,
        headers: new HttpHeaders({ Authorization: `Bearer ${this.authService.accessToken}` })
      }
    }).pipe(
      map(results => {
        let margins = results.data.marginsByAccounts;
        if (searchParams.owed) {
          margins = margins.filter(margin => this.calculateOwed(margin) < 0);
        }
        return margins;
      })
    );
  }

  /**
   * Gets all Margins for given broker codes and search parameters
   * @param brokerCodes The combined office codes and sales codes associated with the margins
   */
  searchMarginsByBrokerCode(brokerCodes: string[], searchParams: MarginSearchCriteria) {
    const marginQuery = {
      businessDateStart: searchParams.startDate,
      businessDateEnd: searchParams.endDate,
      withdrawable: searchParams.withdrawable
    };
    return this.apollo.query({
      query: MarginQueries.getMarginsByBrokerCodesQuery,
      variables: {
        brokerCodes,
        marginQuery
      },
      context: {
        withCredentials: true,
        headers: new HttpHeaders({ Authorization: `Bearer ${this.authService.accessToken}` })
      }
    }).pipe(
      map(results => {
        let margins = results.data.marginsByBrokerCodes;
        if (searchParams.owed) {
          margins = margins.filter(margin => this.calculateOwed(margin) < 0);
        }
        return margins;
      })
    );
  }
}
