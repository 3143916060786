import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable, of, Subject, throwError, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, take, takeUntil, catchError } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import {
  AccountSeriesService,
  BankAccountService,
  BankService,
  BrokerRelationshipService,
  CommissionScheduleService,
  UserService,
} from '@advance-trading/angular-ops-data';
import { ClientService } from '../services/client-service';
import {
  Account,
  AccountPurpose,
  AccountSeries,
  Bank,
  BankAccount,
  BankAccountType,
  BrokerRelationship,
  Client,
  ClientType,
  CommissionSchedule,
  PodEnum,
  User,
  Status,
} from '@advance-trading/ops-data-lib';
import { AccountService } from '../services/account-service';

import { AccountNumberErrorMatcher, AccountValidators } from './account.validator';
import { CommonValidators } from '@advance-trading/angular-common-services';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { BrokerService } from '../services/broker-service';

const ACCOUNT_ADMIN_ROLE = 'AccountAdmin';
const BROKER_CODE_VIEWER_ROLE = 'BrokerCodeViewer';

const bankAcctTypes = {
  [BankAccountType.CHECKING]: 'Checking',
  [BankAccountType.SAVINGS]: 'Savings',
  [BankAccountType.LOAN_ACCOUNT]: 'Loan Account',
  [BankAccountType.OTHER]: 'Other'
};

@Component({
  selector: 'atom-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss']
})
export class AccountDetailComponent implements OnDestroy, OnInit {

  // navigation
  createMode: boolean;
  editMode: boolean;
  enableFundsTransfer: boolean;
  updateComplete = true;
  errorMessage: string;

  accountForm: FormGroup = this.formBuilder.group({
    client: this.formBuilder.control('', [Validators.required, CommonValidators.objectValidator]),
    brokerRel: this.formBuilder.control('', [Validators.required, CommonValidators.objectValidator]),
    commSchedule: this.formBuilder.control('', [CommonValidators.objectValidator]),
    marginGroupAcct: this.formBuilder.control('', [CommonValidators.objectValidator]),
    status: this.formBuilder.control(Status.NEW, [Validators.required]),
    number: this.formBuilder.control('', [Validators.required]),
    name: this.formBuilder.control('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
    purpose: this.formBuilder.control('', [Validators.required]),
    isLeadAcct: this.formBuilder.control(false),
    isMoneyAcct: this.formBuilder.control(false),
    isGiveUpAcct: this.formBuilder.control(false),
    isOtcAcct: this.formBuilder.control(false),
    ssLevel: this.formBuilder.control('', [Validators.minLength(1), Validators.maxLength(1)]),
    ssDate: this.formBuilder.control(''),
    pod: this.formBuilder.control('', [Validators.required]),
    isDtTOverridden: this.formBuilder.control(false),
    interestPercent: this.formBuilder.control(0, [Validators.min(0), Validators.max(100)]),
    fundsTransferType: this.formBuilder.control('', [Validators.required]),
    intermediateBank: this.formBuilder.control(''),
    destinationBank: this.formBuilder.control('', [Validators.required, CommonValidators.objectValidator]),
    destinationBankAcct: this.formBuilder.control('', [Validators.required, CommonValidators.objectValidator]),
    fundsTransferNotes: this.formBuilder.control('', [Validators.maxLength(400)]),
    leadBroker: this.formBuilder.control('')
  }, { validators: [AccountValidators.accountNumberValidator, AccountValidators.leadBrokerValidator] });

  // form data
  filteredClients$: Observable<Client[]>;
  filteredBrokerRelationships$: Observable<BrokerRelationship[]>;
  filteredBrokers$: Observable<User[]>;
  filteredCommissionSchedules$: Observable<CommissionSchedule[]>;
  filteredMarginGroupAccounts$: Observable<Account[]>;
  filteredIntermediateBanks$: Observable<Bank[]>;
  filteredDestinationBanks$: Observable<Bank[]>;
  filteredDestinationBankAccts$: Observable<BankAccount[]>;
  account: Account;
  client: Client;
  leadBroker: User;
  brokers$: Observable<User[]>;
  brokerRel: BrokerRelationship;
  commSchedule: CommissionSchedule;
  marginGroupAccount: Account;
  intermediateBank: Bank;
  destinationBankAcct: BankAccount;
  destinationBank: Bank;

  acctPurpose = Object.keys(AccountPurpose);
  podNames = Object.keys(PodEnum);

  // matcher for form validation error messages
  numberErrorMatcher = new AccountNumberErrorMatcher();

  // data collections
  private activeClients$: Observable<Client[]>;
  private activeBrokerRels$: Observable<BrokerRelationship[]>;
  private activeBrokers$: Observable<User[]>;
  private activeCommSchedules$: Observable<CommissionSchedule[]>;
  private marginGroupAccts$: Observable<Account[]>;
  private intermediateBanks$: Observable<Bank[]>;
  private destinationBanks$: Observable<Bank[]>;
  private destinationBankAccts$: Observable<BankAccount[]>;

  private unsubscribe$: Subject<void> = new Subject<void>();
  private bankAccounts$: Observable<BankAccount[]>;

  @ViewChild('ssResetDatePicker', { static: false }) ssResetDateRef;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private accountSeriesService: AccountSeriesService,
    private accountService: AccountService,
    private bankAccountService: BankAccountService,
    private bankService: BankService,
    private brokerRelService: BrokerRelationshipService,
    private clientService: ClientService,
    private commScheduleService: CommissionScheduleService,
    private userService: UserService,
    private brokerService: BrokerService,
    private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.createMode = false;
    this.enableFundsTransfer = false;
    this.setEditMode(false);
    this.accountForm.addValidators(this.validateStatus);
    this.accountForm.setAsyncValidators([this.validateUniqueAccountNumber, this.validateAccountSeries]);

    const desinationBank$ = this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          // display existing account for /accounts/{docId}
          if (params.get('docId')) {
            return this.accountService.getAccount(params.get('docId'));
          }
          // display blank New Account form for /accounts/new
          if (!this.accountAdmin) {
            return throwError('New accounts can only be submitted by users with the AccountAdmin role');
          }
          this.createMode = true;
          this.setEditMode(true);
          return of(undefined);
        }),
        switchMap((acct: Account) => {
          this.account = acct;
          if (this.account && this.account.brokers.length > 0) {
            return this.brokers$ = combineLatest(this.account.brokers.map(broker => {
              return this.userService.getUserByDocId(broker);
            }));
          }
          return of([]);
        }),
        switchMap((brokers: User[]) => {
          let leadBroker: User;
          brokers.forEach(broker => {
            if (broker.docId === this.account.leadBrokerDocId) {
              leadBroker = broker;
            }
          });

          if (this.account && !leadBroker && this.account.leadBrokerDocId) {
            return this.userService.getUserByDocId(this.account.leadBrokerDocId);
          } else {
            return of(leadBroker);
          }
        }),
        switchMap((leadBroker: User) => {
          this.leadBroker = leadBroker;
          return this.route.queryParamMap;
        }),
        switchMap((queryParams: ParamMap) => {
          if (this.account && this.account.clientDocId) {
            return this.clientService.getClient(this.account.clientDocId).pipe(
              catchError(err => {
                console.log('Error retrieving client: ', err);
                return of(undefined);
              })
            );
          } else if (this.createMode && queryParams.get('clientDocId')) {
            return this.clientService.getClient(queryParams.get('clientDocId'));
          }
          return of(undefined);
        }),
        switchMap((client: Client) => {
          this.client = client;
          if (this.brokerCodeViewer && this.account && this.account.brokerRelDocId) {
            return this.brokerRelService.getBrokerRelationshipByDocId(this.account.brokerRelDocId);
          }
          return of(undefined);
        }),
        switchMap((brokerRel: BrokerRelationship) => {
          this.brokerRel = brokerRel;
          if (this.brokerCodeViewer && this.account && this.account.commissionScheduleDocId) {
            return this.commScheduleService.getCommissionScheduleByDocId(this.account.commissionScheduleDocId);
          }
          return of(undefined);
        }),
        switchMap((commSchedule: CommissionSchedule) => {
          this.commSchedule = commSchedule;
          if (this.account && this.account.marginGroupAccountDocId) {
            return this.accountService.getAccount(this.account.marginGroupAccountDocId).pipe(
              catchError(err => {
                console.log('Error retrieving margin group account: ', err);
                return of(undefined);
              })
            );
          }
          return of(undefined);
        }),
        switchMap((marginGroupAcct: Account) => {
          this.marginGroupAccount = marginGroupAcct;
          if (this.account && this.account.fundsTransfer && this.account.fundsTransfer.intermediateBankDocId) {
            return this.bankService.getBankByDocId(this.account.clientDocId, this.account.fundsTransfer.intermediateBankDocId).pipe(
              catchError(err => {
                console.log('Error retrieving intermediate bank: ', err);
                return of(undefined);
              })
            );
          }
          return of(undefined);
        }),
        switchMap((intermediateBank: Bank) => {
          this.intermediateBank = intermediateBank;
          if (this.account && this.account.fundsTransfer && this.account.fundsTransfer.destinationBankAccountDocId) {
            return this.bankService.getAllBanksByClientDocId(this.client.docId).pipe(switchMap(
              banks => {
                banks.forEach(bank => {
                  this.bankAccounts$ = this.bankAccountService.getAllBankAccountsByBankDocId(this.client.docId, bank.docId);
                  this.bankAccounts$.pipe(take(1)).subscribe(bankAccounts => {
                    bankAccounts.forEach(bankAccount => {
                      if (bankAccount.docId === this.account.fundsTransfer.destinationBankAccountDocId) {
                        this.destinationBank = bank;
                        this.destinationBankAcct = bankAccount;
                      }
                    });
                  });
                });
                return this.bankAccounts$.pipe(
                  catchError(err => {
                    console.log('Error retrieving destination bank and bank account: ', err);
                    return of(undefined);
                  })
                );
              }
            ));
          }
          return of(undefined);
        }),
        takeUntil(this.unsubscribe$)
      );
    desinationBank$.subscribe(() => {
      if (this.createMode) {
        this.prepForNewAccount();
      } else {
        this.mapRetrievedAccount();
      }
    });

    this.onClientChanges();
    this.onBrokerRelChanges();
    this.onDestinationBankChanges();
    this.onCommissionScheduleChanges();
    this.onPurposeChanges();
    this.onStatusChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get accountAdmin() {
    return this.authzService.currentUserHasRole(ACCOUNT_ADMIN_ROLE);
  }
  get brokerCodeViewer() {
    return this.authzService.currentUserHasRole(BROKER_CODE_VIEWER_ROLE);
  }


  get isPending() {
    return this.account && this.account.status === Status.PENDING;
  }

  get isActive() {
    return this.account && this.account.status === Status.ACTIVE;
  }

  get isSlidingScale() {
    return this.accountForm.get('commSchedule').value && Array.isArray(this.accountForm.get('commSchedule').value.defaultRate);
  }

  get showAddFundsTransferButton() {
    return this.editMode &&
      !this.enableFundsTransfer &&
      (this.accountForm.get('purpose').value === AccountPurpose.SPEC || this.accountForm.get('purpose').value === AccountPurpose.HEDGE
      || this.accountForm.get('isOtcAcct').value) &&
      ((this.account && !this.account.fundsTransfer &&
        (this.account.status === Status.ACTIVE || this.accountForm.get('status').value === Status.ACTIVE || this.account.status === 'PENDING')) || this.createMode);
  }

  get showDeleteFundsTransferButton() {
    return !this.createMode &&
      this.editMode &&
      this.account.fundsTransfer &&
      (this.accountForm.get('purpose').value === AccountPurpose.SPEC || this.accountForm.get('purpose').value === AccountPurpose.HEDGE
      || this.accountForm.get('isOtcAcct').value) &&
      (this.account &&
        (this.account.status === Status.ACTIVE || this.accountForm.get('status').value === Status.ACTIVE || this.account.status === 'PENDING'));
  }

  get showFundsTransferHeader() {
    return (this.accountForm.get('purpose').value === AccountPurpose.SPEC ||
    this.accountForm.get('purpose').value === AccountPurpose.HEDGE ||
    this.accountForm.get('isOtcAcct').value) &&
    (this.createMode || this.account && this.account.fundsTransfer ||
      (this.editMode &&
        (this.account.status === 'PENDING' ||
        this.account.status === Status.ACTIVE ||
        this.accountForm.get('status').value === Status.ACTIVE)));
  }

  selectSSResetDate(e: MatDatepickerInputEvent<Date>) {
    if (e.target.validate) {
      this.accountForm.get('ssDate').setValue(e.value);
      this.ssResetDateRef.close();
    }
  }

  /**
   * Sets editMode and enables or disables account form controls
   */
  setEditMode(mode: boolean) {
    this.editMode = mode;
    this.accountForm.disable();
    if (this.createMode && this.editMode) {
      this.accountForm.enable();
      this.hideFundsTransfer();
    } else if (this.editMode) {
      // if account can be edited, the status is always enabled
      this.accountForm.get('status').enable();

      // Prep form data for autocomplete fields
        this.prepForLeadBrokerSelection();
        this.prepForBrokerRelSelection();
        this.prepForCommScheduleSelection();
        this.prepForMarginGroupAccountSelection();

      // if pending, all fields except lead broker (depending on broker relationship) can be edited
      if (this.isPending) {
        this.accountForm.enable();
        if (this.brokerRel.brokers && this.brokerRel.brokers.length === 0) {
          this.accountForm.get('leadBroker').disable();
        }
        if (this.enableFundsTransfer) {
          this.showFundsTransfer();
        } else {
          this.hideFundsTransfer();
        }
        // if active, limit enabled fields
      } else if (this.isActive) {
        this.accountForm.get('brokerRel').enable();
        this.accountForm.get('leadBroker').enable();
        this.accountForm.get('marginGroupAcct').enable();
        this.accountForm.get('name').enable();
        if (this.accountForm.get('name').invalid) {
          // ensure missing account name is evident to users for updating migrated accounts without a name
          this.accountForm.get('name').markAsTouched();
        }
        this.accountForm.get('isLeadAcct').enable();
        this.accountForm.get('isMoneyAcct').enable();
        this.accountForm.get('isOtcAcct').enable();
        this.accountForm.get('pod').enable();
        this.accountForm.get('isDtTOverridden').enable();
        this.accountForm.get('interestPercent').enable();
        const purpose = this.account.purpose;
        // accounts with error, accommodation, margin group, or controller purpose should not be updated
        if (this.isPending || purpose === AccountPurpose.SPEC || purpose === AccountPurpose.HEDGE || purpose === AccountPurpose.OTHER) {
          this.accountForm.get('purpose').enable();
        }
        if (purpose !== AccountPurpose.MARGIN_GROUP) {
          this.accountForm.get('commSchedule').enable();
        }
        if (this.enableFundsTransfer) {
          this.showFundsTransfer();
        }
        if (this.isSlidingScale) {
          this.accountForm.get('ssLevel').enable();
          this.accountForm.get('ssDate').enable();
        }
      }
    }
    this.accountForm.markAsPristine();
  }

  navigateBrokers(broker: User) {
    this.router.navigate([`brokers/${broker.docId}`]);
  }

  navigateClient() {
    if (this.account.clientDocId) {
      this.router.navigate(['/clients', this.account.clientDocId]);
    } else {
      this.openSnackBar('Client does not exist on this account', 'DISMISS', false);
    }
  }

  isLeadBroker(broker: User) {
    if (broker && this.account) {
      if (broker.docId === this.account.leadBrokerDocId) {
        return true;
      }
    }
  }

  /**
   * Resets the account form
   */
  reset() {
    this.enableFundsTransfer = false;
    if (this.createMode) {
      this.accountForm.reset({
        status: Status.NEW,
        client: this.client || '',
        brokerRel: '',
        commSchedule: '',
        interestPercent: 0,
        isLeadAcct: false,
        isMoneyAcct: false,
        isOtcAcct: false,
        isGiveUpAcct: false,
        isDtTOverridden: false
      });
      this.accountForm.get('leadBroker').disable();
      this.accountForm.markAsPristine();
    } else {
      this.setEditMode(false);
      this.mapRetrievedAccount();
    }
  }

  /**
   * Called on submit of Create/Update Account form
   */
  submit() {
    this.updateComplete = false;
    this.setEditMode(false);
    if (this.createMode) {
      this.createAccount();
    } else {
      this.updateAccount();
    }
  }

  canUpdateAccount() {
    return this.accountAdmin && this.account && this.client && (this.account.status === Status.INACTIVE ||
      this.account.status === Status.ACTIVE || this.account.status === Status.PENDING ||
      (this.account.status === Status.CLOSED && this.account.officeCode));
  }

  /**
   * Displays name, city, and region for client autocomplete input field
   */
  displayClient(client: Client) {
    if (client) {
      return client.physicalAddress ? `${client.name} (${client.physicalAddress.city}, ${client.physicalAddress.region})` : client.name;
    }
    return '';
  }

  /**
   * Returns the url to use in ati-labeled-data component link field
   * @returns string
   */
  getClientLink() {
    if (this.client) {
      return this.router.createUrlTree(['/clients', this.client.docId]).toString();
    }
    return '';
  }

  /**
   * Displays broker code and secondary label if present for broker relationship autocomplete input field
   */
  displayBrokerRel(brokerRel?: BrokerRelationship) {
    if (brokerRel && brokerRel.brokerCode) {
      return brokerRel.secondaryLabel ? `${brokerRel.brokerCode} ${brokerRel.secondaryLabel}` : brokerRel.brokerCode;
    }
    // allow for dislaying the broker code for migrated, closed accounts with a broker code but no broker relationship
    return brokerRel || '';
  }

  displayBroker(broker: User, shouldDisplayLeadString: boolean = false) {
    if (shouldDisplayLeadString && broker) {
      return `${broker.firstName} ${broker.lastName} ${this.isLeadBroker(broker) ? '(Lead Broker)' : ''}`;
    } else if (broker) {
      return broker.firstName + ' ' + broker.lastName;
    } else {
      return '';
    }
  }
  /**
   * Displays rate code for commission schedule autocomplete input field
   */
  displayCommSchedule(commSchedule?: CommissionSchedule) {
    return commSchedule ? commSchedule.rateCode : '';
  }

  /**
   * Displays account number and name for margin group account autocomplete input field
   */
  displayAccount(acct?: Account) {
    return acct ? `${acct.officeCode}${acct.number} ${acct.name}` : '';
  }

  /**
   * Displays bank name for bank autocomplete input field
   */
  displayBank(bank?: Bank) {
    return bank ? bank.name : '';
  }

  /**
   * Displays bank account type and description if present for bank account autocomplete input field
   */
  displayBankAccount(bankAcct?: BankAccount) {
    if (bankAcct) {
      const type = bankAcctTypes[bankAcct.type] || 'Unknown Type';
      const descr = bankAcct.description ? ` : ${bankAcct.description}` : '';
      return `${type}${descr}`;
    }
    return '';
  }

  displayFundsTransferType(type: string) {
    switch (type) {
      case 'WIRE':
        return 'Wire';
      case 'ACH_PULL':
        return 'ACH Pull';
      case 'CHECK':
        return 'Check';
      default:
        return '-';
    }
  }

  /**
   * Show checkbox for Give Up Account if acount has spec, hedge, or other purpose
   */
  showGiveUpAccount() {
    const purpose = this.accountForm.get('purpose').value;
    return purpose === AccountPurpose.SPEC || purpose === AccountPurpose.HEDGE || purpose === AccountPurpose.OTHER;
  }

  /**
   * Show checkbox for DtT Override if client has discretionToTrade set to true
   */
  showDiscretionToTradeOverride() {
    return this.accountForm.get('client').value && this.accountForm.get('client').value.discretionToTrade;
  }

  /**
   * Show checkbox for OTC Account if account has other purpose
   */
  showOtcAccount() {
    const purpose = this.accountForm.get('purpose').value;
    return purpose === AccountPurpose.SPEC || purpose === AccountPurpose.HEDGE || purpose === AccountPurpose.OTHER;
  }

  showFundsTransfer() {
    this.enableFundsTransfer = true;
    this.accountForm.get('fundsTransferType').enable();
    this.accountForm.get('intermediateBank').enable();
    this.accountForm.get('destinationBank').enable();
    this.accountForm.get('destinationBankAcct').enable();
    this.accountForm.get('fundsTransferNotes').enable();
    this.prepForIntermediateBankSelection();
    this.prepForDestinationBankSelection();
    this.prepForDestinationBankAcctSelection();
  }

  hideFundsTransfer() {
    this.enableFundsTransfer = false;
    this.accountForm.get('fundsTransferType').disable();
    this.accountForm.get('intermediateBank').disable();
    this.accountForm.get('destinationBank').disable();
    this.accountForm.get('destinationBankAcct').disable();
    this.accountForm.get('fundsTransferNotes').disable();
  }

  deleteFundsTransfer() {
    this.hideFundsTransfer();
    this.accountForm.markAsDirty();
  }

  removeMarginGroupAcct() {
    this.accountForm.get('marginGroupAcct').setValue('');
    this.accountForm.get('marginGroupAcct').markAsDirty();
  }

  removeIntermediateBank() {
    this.accountForm.get('intermediateBank').setValue('');
    this.accountForm.get('intermediateBank').markAsDirty();
  }

  removeLeadBroker() {
    this.accountForm.get('leadBroker').setValue('');
    this.accountForm.get('leadBroker').markAsDirty();
  }

  removeBrokerRel() {
    this.accountForm.get('brokerRel').setValue('');
    this.accountForm.get('brokerRel').markAsDirty();
  }

  /**
   * Disable all purpose options besides for spec and hedge for active accounts
   */
  disablePurpose(purpose: AccountPurpose) {
    return !this.createMode && !this.isPending && purpose !== AccountPurpose.SPEC && purpose !== AccountPurpose.HEDGE;
  }

  getErrorMessage(formControl: FormControl) {
    if (formControl.hasError('required')) {
      return 'Account number is required';
    }
    if (this.accountForm.hasError('length')) {
      return 'Number must be 5 characters';
    }
    if (this.accountForm.hasError('invalidGiveUp')) {
      return 'Give up format must be G**** or all alpha';
    }
    if (this.accountForm.hasError('invalidController')) {
      return 'Controller format must be CT***';
    }
    if (this.accountForm.hasError('invalidRJOMarginGroup')) {
      return 'Margin group format must be M****';
    }
    if (this.accountForm.hasError('invalidMarginGroup')) {
      return 'Margin group format must be R****';
    }
    if (this.accountForm.hasError('accountNumberTaken')) {
      return 'Office code / number not available';
    }
    if (this.accountForm.hasError('invalidAccountSeries')) {
      return 'Must be in assigned account series';
    }
    if (this.accountForm.hasError('invalidOtcNumber')) {
      return 'Invalid OTC Account Number';
    }
    if (this.accountForm.hasError('invalidStatus')) {
      if (this.account.status === Status.INACTIVE) {
        return 'Inactive accounts must be reactivated or closed to submit changes';
      } else if (this.account.status === Status.CLOSED) {
        return 'Closed accounts must be reactivated to submit changes';
      }


    }
    return 'Unknown Error';
  }

   // For dates that can be deleted from the Account, this prevents the delete function from being passed to the template date pipe
   filterDateStrings(date) {
    if (typeof date !== 'string') {
      return undefined;
    } else {
      return date;
    }
  }

  private prepForNewAccount() {
    this.prepForClientSelection();
    this.prepForBrokerRelSelection();
    this.prepForCommScheduleSelection();
    this.accountForm.get('leadBroker').disable();
    this.accountForm.get('client').setValue(this.client);
  }

  // Maps all data retrieved from Firestore for current account to values in the account form
  private mapRetrievedAccount() {
    this.accountForm.get('client').setValue(this.client);
    this.accountForm.get('brokerRel').setValue(this.brokerRel || this.account.brokerCode);
    this.accountForm.get('commSchedule').setValue(this.commSchedule);
    this.accountForm.get('marginGroupAcct').setValue(this.marginGroupAccount);
    // prevent triggering value changes for status when mapping form
    this.accountForm.get('status').setValue(this.account.status, {emitEvent: false});
    this.accountForm.get('number').setValue(this.account.number);
    this.accountForm.get('name').setValue(this.account.name);
    this.accountForm.get('purpose').setValue(this.account.purpose);
    this.accountForm.get('isLeadAcct').setValue(this.account.isLeadAccount);
    this.accountForm.get('isMoneyAcct').setValue(this.account.isMoneyAccount);
    this.accountForm.get('isGiveUpAcct').setValue(this.account.isGiveUpAccount);
    this.accountForm.get('isOtcAcct').setValue(this.account.isOTCAccount);
    this.accountForm.get('ssLevel').setValue(this.account.slidingScaleLevel);
    this.accountForm.get('pod').setValue(this.account.managingPod);
    this.accountForm.get('isDtTOverridden').setValue(this.account.isDiscretionToTradeOverridden);
    this.accountForm.get('interestPercent').setValue(this.account.interestPercentToClient * 100);
    this.leadBroker ? this.accountForm.get('leadBroker').setValue(this.leadBroker) : this.accountForm.get('leadBroker').setValue('');
    if (this.account.slidingScaleResetDate) {
      this.accountForm.get('ssDate').patchValue(moment(this.account.slidingScaleResetDate, '--MM-DD'));
    }
    if (this.account.fundsTransfer) {
      this.enableFundsTransfer = true;
      this.accountForm.get('fundsTransferType').setValue(this.account.fundsTransfer.type);
      this.accountForm.get('intermediateBank').setValue(this.intermediateBank);
      this.accountForm.get('destinationBank').setValue(this.destinationBank);
      this.accountForm.get('destinationBankAcct').setValue(this.destinationBankAcct);
      this.accountForm.get('fundsTransferNotes').setValue(this.account.fundsTransfer.notes);
    }
  }

  // Maps values from account form to new account,
  // creates a new Account in Firestore, and routes to accounts list
  private createAccount() {
    const formValues = this.accountForm.getRawValue();
    const newAccount = new Account();
    newAccount.status = Status.NEW;
    newAccount.name = formValues.name;
    newAccount.number = formValues.number;
    newAccount.clientDocId = formValues.client.docId;
    newAccount.brokerRelDocId = formValues.brokerRel.docId;
    newAccount.purpose = formValues.purpose;
    newAccount.isLeadAccount = formValues.isLeadAcct;
    newAccount.isMoneyAccount = formValues.isMoneyAcct;
    newAccount.isGiveUpAccount = formValues.isGiveUpAcct;
    newAccount.isOTCAccount = formValues.isOtcAcct;
    newAccount.isDiscretionToTradeOverridden = formValues.isDtTOverridden;
    newAccount.managingPod = formValues.pod;
    if (formValues.leadBroker && formValues.leadBroker.docId) {
      newAccount.leadBrokerDocId = formValues.leadBroker.docId;
    } else {
      newAccount.leadBrokerDocId = '';
    }

    // Set marginGroupAccountDocId based on account purpose
    if ((newAccount.purpose === AccountPurpose.SPEC || newAccount.purpose === AccountPurpose.HEDGE)
      && formValues.marginGroupAcct) {
      newAccount.marginGroupAccountDocId = formValues.marginGroupAcct.docId;
    } else if (newAccount.purpose === AccountPurpose.MARGIN_GROUP) {
      newAccount.marginGroupAccountDocId = newAccount.docId;
    }

    // Do not set commission schedule for margin group accounts
    if (newAccount.purpose !== AccountPurpose.MARGIN_GROUP) {
      newAccount.commissionScheduleDocId = formValues.commSchedule.docId;
    }

    // If OTC account set commission schedule if there; else set empty
    if (newAccount.isOTCAccount) {
      newAccount.commissionScheduleDocId = formValues.commSchedule.docId || '';
    }

    // Sliding scale commission schedule
    if (this.isSlidingScale) {
      newAccount.slidingScaleResetDate = moment(formValues.ssDate).format('--MM-DD');
      newAccount.slidingScaleLevel = '1';
    }

    // Interest % for commercial clients only
    if (formValues.client.type === ClientType.COMMERCIAL) {
      newAccount.interestPercentToClient = formValues.interestPercent / 100;
    }

    if (this.enableFundsTransfer) {
      newAccount.fundsTransfer = {
        type: formValues.fundsTransferType,
        intermediateBankDocId: formValues.intermediateBank ? formValues.intermediateBank.docId : '',
        destinationBankAccountDocId: formValues.destinationBankAcct.docId,
        notes: formValues.fundsTransferNotes || ''
      };
    }

    // insert new account data into Firestore
    this.accountService.createAccount(newAccount)
      .then(() => {
        console.log('Account successfully created: ' + JSON.stringify(newAccount));
        this.updateComplete = true;
        this.openSnackBar('Account successfully created', 'DISMISS', true);
        this.router.navigate(['/accounts',], { replaceUrl: true, queryParams: { accountSearchNumber: newAccount.number } });
      })
      .catch(err => {
        this.updateComplete = true;
        console.error('Account creation failed: ' + JSON.stringify(err));
        let errorMessage = '';
        switch (err.code) {
          case 'permission-denied':
            errorMessage = 'Insufficient permissions';
            break;
          default:
            errorMessage = 'Unknown error occurred';
        }
        this.openSnackBar('Account creation failed: ' + errorMessage, 'DISMISS', false);
      });
  }

  // Maps all values from account form for existing account and updates Account in Firestore
  private updateAccount() {
    const formValues = this.accountForm.getRawValue();
    this.account.name = formValues.name;
    this.account.number = formValues.number;
    this.account.clientDocId = formValues.client.docId;
    this.account.brokerRelDocId = formValues.brokerRel.docId;
    this.account.purpose = formValues.purpose;
    this.account.isLeadAccount = formValues.isLeadAcct;
    this.account.isMoneyAccount = formValues.isMoneyAcct;
    this.account.isGiveUpAccount = formValues.isGiveUpAcct;
    this.account.isOTCAccount = formValues.isOtcAcct || false;
    this.account.isDiscretionToTradeOverridden = formValues.isDtTOverridden;
    this.account.managingPod = formValues.pod;
    this.account.interestPercentToClient = formValues.interestPercent / 100;
    if (formValues.leadBroker && formValues.leadBroker.docId) {
      this.account.leadBrokerDocId = formValues.leadBroker.docId;
    } else {
      this.account.leadBrokerDocId = '';
    }

    if ((formValues.purpose === AccountPurpose.SPEC || formValues.purpose === AccountPurpose.HEDGE)
      && formValues.marginGroupAcct) {
      this.account.marginGroupAccountDocId = formValues.marginGroupAcct.docId;
    } else if (formValues.purpose === AccountPurpose.MARGIN_GROUP) {
      this.account.marginGroupAccountDocId = this.account.docId;
    } else if (this.account.marginGroupAccountDocId) {
      this.account.marginGroupAccountDocId = '';
    }

    // If not margin group set commission schedule
    if (this.account.purpose !== AccountPurpose.MARGIN_GROUP && formValues.commSchedule) {
      this.account.commissionScheduleDocId = formValues.commSchedule.docId;
      // Clear commission schedule and rate code that may have been previously set
    } else {
      this.account.commissionScheduleDocId = '';
      this.account.commissionRateCode = '';
    }

    // If sliding scale after update
    if (this.isSlidingScale) {
      this.account.slidingScaleResetDate = moment(formValues.ssDate).format('--MM-DD');
      this.account.slidingScaleLevel = formValues.ssLevel;
      // If not a sliding scale rate code but was previously - clear level and reset date
    } else if (!this.isSlidingScale && this.account.slidingScaleResetDate) {
      this.account.slidingScaleLevel = '';
      this.account.slidingScaleResetDate = '';
    }

    if (formValues.client.type === ClientType.COMMERCIAL) {
      this.account.interestPercentToClient = formValues.interestPercent / 100;
    }

    if (this.account.status !== formValues.status) {
      const oldStatus = this.account.status;
      const newStatus = formValues.status;
      if (newStatus === Status.INACTIVE) {
        this.account.inactivationDate = new Date().toISOString();
        this.account.reactivationDate = undefined;
      }
      if (newStatus === Status.CLOSED) {
        this.account.closureDate = new Date().toISOString();
        this.account.inactivationDate = undefined;
      }
      if (newStatus === Status.ACTIVE) {
        if (oldStatus === Status.PENDING) {
          this.account.activationDate = new Date().toISOString();
        }
        if (oldStatus === Status.INACTIVE) {
          this.account.reactivationDate = new Date().toISOString();
          this.account.inactivationDate = undefined;
        }
        if (oldStatus === Status.CLOSED) {
          this.account.reactivationDate = new Date().toISOString();
          this.account.inactivationDate = undefined;
          this.account.closureDate = undefined;
        }
      }
      this.account.status = formValues.status;
    }

    if (this.enableFundsTransfer) {
      this.account.fundsTransfer = {
        type: formValues.fundsTransferType,
        intermediateBankDocId: formValues.intermediateBank ? formValues.intermediateBank.docId : '',
        destinationBankAccountDocId: formValues.destinationBankAcct.docId,
        notes: formValues.fundsTransferNotes || ''
      };
    } else {
      delete this.account.fundsTransfer;
    }


    // update existing account data in Firestore
    this.accountService.updateAccount(this.account)
      .then(() => {
        console.log('Account successfully updated: ' + JSON.stringify(this.account));
        this.updateComplete = true;
        this.createMode = false;
        this.setEditMode(false);
        this.openSnackBar('Account successfully updated', 'DISMISS', true);
      })
      .catch(err => {
        this.updateComplete = true;
        console.error('Account update failed: ' + JSON.stringify(err));
        let errorMessage = '';
        switch (err.code) {
          case 'permission-denied':
            errorMessage = 'Insufficient permissions';
            break;
          default:
            errorMessage = 'Unknown error occurred';
        }
        this.openSnackBar('Account update failed: ' + errorMessage, 'DISMISS', false);
      });
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  // if client changes clear values for margin group account and funds transfer bank fields
  // call to get updated list for autocomplete fields if valid client selected
  private onClientChanges() {
    this.accountForm.get('client').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if (this.createMode && this.editMode) {
        this.clearClientDependentFields();
        this.prepForMarginGroupAccountSelection();
        this.prepForIntermediateBankSelection();
        this.prepForDestinationBankSelection();
      } else if (this.editMode && (!value || !value.docId)) {
        this.clearClientDependentFields();
        this.prepForClientSelection();
      } else if (this.editMode && value.docId && this.accountForm.get('client').dirty) {
        this.clearClientDependentFields();
        this.prepForClientSelection();
        this.prepForMarginGroupAccountSelection();
        this.prepForIntermediateBankSelection();
        this.prepForDestinationBankSelection();
      }
    });
  }

  private clearClientDependentFields() {
    this.accountForm.get('marginGroupAcct').setValue('');
    this.accountForm.get('intermediateBank').setValue('');
    this.accountForm.get('destinationBank').setValue('');
    this.accountForm.get('destinationBankAcct').setValue('');
  }

  // if broker relationship changes call to get new list of margin group accounts and clear current value
  private onBrokerRelChanges() {
    this.accountForm.get('brokerRel').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if (this.createMode && this.editMode) {
        this.accountForm.get('marginGroupAcct').setValue('');
        this.prepForMarginGroupAccountSelection();
      } else if (this.editMode && !value) {
        this.accountForm.get('marginGroupAcct').setValue('');
      } else if (this.editMode && value.docId && this.accountForm.get('brokerRel').dirty) {
        this.accountForm.get('marginGroupAcct').setValue('');
        this.prepForMarginGroupAccountSelection();
      }
      if (this.editMode && value.docId && this.accountForm.get('brokerRel').dirty) {
        this.accountForm.get('leadBroker').setValue('');
        this.accountForm.get('leadBroker').enable();
        this.prepForLeadBrokerSelection();
      }
    });
  }

  private onDestinationBankChanges() {
    this.accountForm.get('destinationBank').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if (this.createMode && this.editMode) {
        this.accountForm.get('destinationBankAcct').setValue('');
        this.prepForDestinationBankAcctSelection();
      } else if (this.editMode && !value) {
        this.accountForm.get('destinationBankAcct').setValue('');
      } else if (this.editMode && value.docId && this.accountForm.get('destinationBank').dirty) {
        this.accountForm.get('destinationBankAcct').setValue('');
        this.prepForDestinationBankAcctSelection();
      }
    });
  }

  private onCommissionScheduleChanges() {
    this.accountForm.get('commSchedule').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if (this.isSlidingScale && this.editMode && (this.isPending || this.isActive || this.createMode)) {
        this.accountForm.get('ssLevel').enable();
        this.accountForm.get('ssDate').enable();
        this.accountForm.get('ssDate').markAsTouched();
        this.accountForm.get('ssLevel').markAsTouched();
      } else {
        this.accountForm.get('ssLevel').disable();
        this.accountForm.get('ssDate').disable();
      }
    });
  }

  private onPurposeChanges() {
    this.accountForm.get('purpose').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if (value !== AccountPurpose.MARGIN_GROUP && this.editMode &&
        this.accountForm.get('status').value !== Status.INACTIVE &&
        this.accountForm.get('status').value !== Status.CLOSED) {
        this.accountForm.get('commSchedule').enable();
        if (!this.createMode) {
          this.accountForm.get('commSchedule').markAsTouched();
        }
        if (value !== AccountPurpose.SPEC && value !== AccountPurpose.HEDGE) {
          this.deleteFundsTransfer();
        }
      } else if (value === AccountPurpose.MARGIN_GROUP) {
        this.deleteFundsTransfer();
        this.accountForm.get('commSchedule').disable();
      } else {
        this.accountForm.get('commSchedule').disable();
      }
    });
  }

  private onStatusChanges() {
    // when an inactive or closed account form is clicked to active, enable fields
    this.accountForm.get('status').valueChanges.subscribe(value => {
      if (this.account && (this.account.status === Status.INACTIVE || this.account.status === Status.CLOSED)) {
        if (value === Status.ACTIVE) {
          this.accountForm.disable({ emitEvent: false });
          this.accountForm.get('status').enable({ emitEvent: false });
          if (this.editMode) {
            this.accountForm.get('brokerRel').enable();
            this.accountForm.get('leadBroker').enable();
            this.accountForm.get('marginGroupAcct').enable();
            this.accountForm.get('name').enable();
            if (this.accountForm.get('name').invalid) {
              // ensure missing account name is evident to users for updating migrated accounts without a name
              this.accountForm.get('name').markAsTouched();
            }
            this.accountForm.get('isLeadAcct').enable();
            this.accountForm.get('isMoneyAcct').enable();
            this.accountForm.get('isOtcAcct').enable();
            this.accountForm.get('pod').enable();
            this.accountForm.get('isDtTOverridden').enable();
            this.accountForm.get('interestPercent').enable();
            const purpose = this.account.purpose;
            // accounts with error, accommodation, margin group, or controller purpose should not be updated
            if (this.isPending || purpose === AccountPurpose.SPEC || purpose === AccountPurpose.HEDGE || purpose === AccountPurpose.OTHER) {
              this.accountForm.get('purpose').enable();
            }
            if (purpose !== AccountPurpose.MARGIN_GROUP) {
              this.accountForm.get('commSchedule').enable();
            }
            if (this.enableFundsTransfer) {
              this.showFundsTransfer();
            }
            if (this.isSlidingScale) {
              this.accountForm.get('ssLevel').enable();
              this.accountForm.get('ssDate').enable();
            }
          }
          // if that same form is then clicked back to inactive or closed we reset the form and keep fields disabled
        } else if (value === Status.INACTIVE || Status.CLOSED) {
          this.mapRetrievedAccount();
          this.accountForm.get('status').setValue(value, { emitEvent: false });
          this.accountForm.disable({ emitEvent: false });
          this.accountForm.get('status').enable({ emitEvent: false });
        }
      }
    });
  }

  private validateUniqueAccountNumber = (formGroup: FormGroup) => {
    const accountNumber = formGroup.value.number;
    const officeCode = formGroup.value.brokerRel ? formGroup.value.brokerRel.officeCode : '';
    const docId = this.account ? this.account.docId : '';

    if (officeCode && accountNumber) {
      return this.accountService.getAccountsByNumber(accountNumber)
        .pipe(
          debounceTime(200),
          map((accounts: Account[]) => {
            const match = accounts.find(acct => {
              if (!acct.officeCode) {
                // skip office code check and match on any number with no office code to handle migrated accounts
                return acct.docId !== docId && acct.status !== Status.DENIED;
              }
              return acct.docId !== docId && acct.status !== Status.DENIED && acct.officeCode === officeCode;
            });
            return match ? { invalidNumber: true, accountNumberTaken: true } : undefined;
          }),
          take(1)
        );
    } else {
      return of(undefined);
    }
  }

  private validateAccountSeries = (formGroup: FormGroup) => {
    const accountNumber = formGroup.value.number;
    const salesCodeDocId = formGroup.value.brokerRel ? formGroup.value.brokerRel.salesCodeDocId : '';
    const giveUp = formGroup.value.isGiveUpAcct;
    const purpose = formGroup.value.purpose;
    const isOtcAcct = formGroup.value.isOtcAcct;

    if (giveUp || isOtcAcct || (purpose === AccountPurpose.MARGIN_GROUP || purpose === AccountPurpose.CONTROLLER)) {
      return of(undefined);
    } else if (salesCodeDocId && accountNumber) {
      return this.accountSeriesService.getAccountSeriesBySalesCode(salesCodeDocId)
        .pipe(
          debounceTime(200),
          map((acctSeries: AccountSeries[]) => {
            const match = acctSeries.find(series => accountNumber >= series.start && accountNumber <= series.end);
            return match ? undefined : { invalidNumber: true, invalidAccountSeries: true };
          }),
          take(1)
        );
    } else {
      return of(undefined);
    }
  }

  private validateStatus = (formGroup: FormGroup) => {
    // Enforces that a closed must be set to active to submit changes
    if (this.account && (this.account.status === Status.CLOSED)) {
      return formGroup.value.status === Status.ACTIVE ? undefined : { invalidStatus: true };
      // Enforces that an invactive account can only be changed to closed or activated
    } else if (this.account && this.account.status === Status.INACTIVE) {
      return (formGroup.value.status === Status.ACTIVE || formGroup.value.status === Status.CLOSED) ? undefined : { invalidStatus: true };
    } else {
      return undefined;
    }
  }

  // ************** Lots of duplicate logic for autocomplete fields **************

  // ******* Client autocomplete *******

  private prepForClientSelection() {
    this.getActiveClients();
    this.filterClients();
  }

  // Gets active clients from Firestore
  private getActiveClients() {
    if (!this.activeClients$) {
      this.activeClients$ = this.clientService.getClientsByStatus(Status.ACTIVE)
        .pipe(
          shareReplay(1)
        );
    }
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting a client for the account
  private filterClients() {
    this.filteredClients$ = this.accountForm.controls.client.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        startWith<string | Client>(''),
        switchMap(term => typeof term === 'string' ? this.filterClientsBySearchTerm(term) : of([term]))
      );
  }

  // Filters clients from Firestore using the user input search term
  private filterClientsBySearchTerm(searchTerm: string): Observable<Client[]> {
    return this.activeClients$
      .pipe(
        map(clients => clients.filter(client => client.name.toLowerCase().includes(searchTerm.toLowerCase())))
      );
  }

  // ******* Broker relationship autocomplete *******

  private prepForBrokerRelSelection() {
    this.getActiveBrokerRels();
    this.filterBrokerRels();
  }

  // Gets active broker relationships from Firestore
  private getActiveBrokerRels() {
    if (!this.activeBrokerRels$) {
      this.activeBrokerRels$ = this.brokerRelService.getBrokerRelationshipsByStatus(Status.ACTIVE)
        .pipe(
          shareReplay(1)
        );
    }
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting a broker relationship for the account
  private filterBrokerRels() {
    this.filteredBrokerRelationships$ = this.accountForm.controls.brokerRel.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        startWith<string | BrokerRelationship>(''),
        switchMap(term => typeof term === 'string' ? this.filterBrokerRelsBySearchTerm(term) : of([term]))
      );
  }

  // Filters broker relationships from Firestore using the user input search term
  private filterBrokerRelsBySearchTerm(searchTerm: string): Observable<BrokerRelationship[]> {
    return this.activeBrokerRels$
      .pipe(
        map((brokerRels: BrokerRelationship[]) => brokerRels.filter(brokerRel => {
          // if editing existing, non-pending account, only allow update to broker code with same office code
          if (this.createMode || this.isPending) {
            return true;
          }
          return brokerRel.officeCode === this.brokerRel.officeCode;
        })),
        map(brokerRels => brokerRels.filter(brokerRel => this.matchBrokerRel(searchTerm, brokerRel)))
      );
  }

  // Checks for a partial match on broker code or secondary label
  private matchBrokerRel(searchTerm: string, brokerRel: BrokerRelationship) {
    const searchValue = searchTerm.toLowerCase();
    const brokerCode = brokerRel.brokerCode.toLowerCase();
    const secondaryLabel = brokerRel.secondaryLabel ? brokerRel.secondaryLabel.toLowerCase() : '';
    return brokerCode.includes(searchValue) || secondaryLabel.includes(searchValue);
  }

  // ******* Lead Broker autocomplete *******

  private prepForLeadBrokerSelection() {
    this.getActiveBrokers();
    this.filterBrokers();
  }

  // Gets active brokers from Firestore
  private getActiveBrokers() {
    const brokerRel: BrokerRelationship = this.accountForm.get('brokerRel').value;
    // the 'house' brokerRels have no brokers so will disable lead broker
    if (brokerRel && brokerRel.brokers && brokerRel.brokers.length) {
      this.activeBrokers$ = combineLatest(brokerRel.brokers.map(broker => {
        return this.userService.getUserByDocId(broker);
      }));
    } else {
      this.activeBrokers$ = this.brokerService.getActiveBrokers().pipe(
        switchMap(brokers => {
          return combineLatest(brokers.map(broker => {
            return this.userService.getUserByDocId(broker.docId);
          }));
        })
      );
    }
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting a lead broker for the account
  private filterBrokers() {
    this.filteredBrokers$ = this.accountForm.controls.leadBroker.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        startWith<string | User>(''),
        switchMap(term => typeof term === 'string' ? this.filterBrokersBySearchTerm(term) : of([term]))
      );
  }

  // Filters Users from Firestore using the user input search term
  private filterBrokersBySearchTerm(searchTerm: string): Observable<User[]> {
    return this.activeBrokers$
      .pipe(
        map(brokers => brokers.filter(broker => this.matchBroker(searchTerm, broker)))
      );
  }

  // Checks for a partial match on brokers first name or last name
  private matchBroker(searchTerm: string, broker: User) {
    const searchValue = searchTerm.toLowerCase();
    const firstName = broker.firstName.toLowerCase();
    const lastName = broker.lastName.toLowerCase();
    return firstName.includes(searchValue) || lastName.includes(searchValue);
  }

  // ******* Commission schedule autocomplete *******

  private prepForCommScheduleSelection() {
    this.getActiveCommSchedules();
    this.filterCommSchedules();
  }

  // Gets active broker relationships from Firestore
  private getActiveCommSchedules() {
    if (!this.activeCommSchedules$) {
      this.activeCommSchedules$ = this.commScheduleService.getCommissionSchedulesByStatus(Status.ACTIVE)
        .pipe(
          shareReplay(1)
        );
    }
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting a commission schedule for the account
  private filterCommSchedules() {
    this.filteredCommissionSchedules$ = this.accountForm.controls.commSchedule.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        startWith<string | CommissionSchedule>(''),
        switchMap(term => typeof term === 'string' ? this.filterCommSchedulesBySearchTerm(term) : of([term]))
      );
  }

  // Filters commission schedules from Firestore using the user input search term
  private filterCommSchedulesBySearchTerm(searchTerm: string): Observable<CommissionSchedule[]> {
    return this.activeCommSchedules$
      .pipe(
        map(commSchedules => commSchedules.filter(commSchedule => commSchedule.rateCode.toLowerCase().includes(searchTerm.toLowerCase())))
      );
  }

  // ******* Margin group account autocomplete *******

  private prepForMarginGroupAccountSelection() {
    if (this.accountForm.get('client').value && this.accountForm.get('client').value.docId
      && this.accountForm.get('brokerRel').value && this.accountForm.get('brokerRel').value.fcm) {
      this.getMarginGroupAccounts();
      this.filterMarginGroupAccounts();
    }
  }

  // Gets margin group accounts for selected client and FCM from Firestore
  private getMarginGroupAccounts() {
    this.marginGroupAccts$ = this.accountService.getMarginGroupAccountsByClientFCM(
      this.accountForm.get('client').value.docId, this.accountForm.get('brokerRel').value.fcm)
      .pipe(
        shareReplay(1)
      );
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting a margin group account for the account
  private filterMarginGroupAccounts() {
    this.filteredMarginGroupAccounts$ = this.accountForm.controls.marginGroupAcct.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        startWith<string | Account>(''),
        switchMap(term => typeof term === 'string' ? this.filterMarginGroupAcctsBySearchTerm(term) : of([term]))
      );
  }

  // Filters margin group accounts from Firestore using the user input search term
  private filterMarginGroupAcctsBySearchTerm(searchTerm: string): Observable<Account[]> {
    return this.marginGroupAccts$
      .pipe(
        map(accts => accts.filter(acct => this.matchAcct(searchTerm, acct)))
      );
  }

  // Checks for a partial match on account name or number
  private matchAcct(searchTerm: string, acct: Account) {
    const searchValue = searchTerm.toLowerCase();
    const name = acct.name.toLowerCase();
    const accountNumber = acct.number.toLowerCase();
    return name.includes(searchValue) || accountNumber.includes(searchValue);
  }

  // ******* Intermediate bank autocomplete *******

  private prepForIntermediateBankSelection() {
    if (this.accountForm.get('client').value && this.accountForm.get('client').value.docId) {
      this.getIntermediateBanks();
      this.filterIntermediateBanks();
    }
  }

  // Gets client banks from Firestore
  private getIntermediateBanks() {
    this.intermediateBanks$ = this.bankService.getAllBanksByClientDocId(this.accountForm.get('client').value.docId)
      .pipe(
        shareReplay(1)
      );
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting an intermediate bank for the account
  private filterIntermediateBanks() {
    this.filteredIntermediateBanks$ = this.accountForm.controls.intermediateBank.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        startWith<string | Bank>(''),
        switchMap(term => typeof term === 'string' ? this.filterIntermediateBanksBySearchTerm(term) : of([term]))
      );
  }

  // Filters intermediate banks from Firestore using the user input search term
  private filterIntermediateBanksBySearchTerm(searchTerm: string): Observable<Bank[]> {
    return this.intermediateBanks$
      .pipe(
        map(banks => banks.filter(bank => bank.name.toLowerCase().includes(searchTerm.toLowerCase())))
      );
  }

  // ******* Destination bank autocomplete *******

  private prepForDestinationBankSelection() {
    if (this.accountForm.get('client').value && this.accountForm.get('client').value.docId) {
      this.getDestinationBanks();
      this.filterDestinationBanks();
    }
  }

  // Gets client banks from Firestore
  private getDestinationBanks() {
    this.destinationBanks$ = this.bankService.getAllBanksByClientDocId(this.accountForm.get('client').value.docId)
      .pipe(
        shareReplay(1)
      );
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting a destination bank for the account
  private filterDestinationBanks() {
    this.filteredDestinationBanks$ = this.accountForm.controls.destinationBank.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        startWith<string | Bank>(''),
        switchMap(term => typeof term === 'string' ? this.filterDestinationBanksBySearchTerm(term) : of([term]))
      );
  }

  // Filters destination banks from Firestore using the user input search term
  private filterDestinationBanksBySearchTerm(searchTerm: string): Observable<Bank[]> {
    return this.destinationBanks$
      .pipe(
        map(banks => banks.filter(bank => bank.name.toLowerCase().includes(searchTerm.toLowerCase())))
      );
  }

  // ******* Destination bank account autocomplete *******

  private prepForDestinationBankAcctSelection() {
    if (this.accountForm.get('client').value && this.accountForm.get('client').value.docId
      && this.accountForm.get('destinationBank').value && this.accountForm.get('destinationBank').value.docId) {
      this.getDestinationBankAccts();
      this.filterDestinationBankAccts();
    }
  }

  // Gets bank accounts for the selected destination bank from Firestore
  private getDestinationBankAccts() {
    this.destinationBankAccts$ = this.bankAccountService.getAllBankAccountsByBankDocId(
      this.accountForm.get('client').value.docId, this.accountForm.get('destinationBank').value.docId)
      .pipe(
        shareReplay(1)
      );
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting a destination bank account for the account
  private filterDestinationBankAccts() {
    this.filteredDestinationBankAccts$ = this.accountForm.controls.destinationBankAcct.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        startWith<string | BankAccount>(''),
        switchMap(term => typeof term === 'string' ? this.filterDestinationBankAcctsBySearchTerm(term) : of([term]))
      );
  }

  // Filters destination bank accounts from Firestore using the user input search term
  private filterDestinationBankAcctsBySearchTerm(searchTerm: string): Observable<BankAccount[]> {
    return this.destinationBankAccts$
      .pipe(
        map(accts => accts.filter(acct => this.matchBankAcct(searchTerm, acct)))
      );
  }

  // Checks for a partial match on type or description
  private matchBankAcct(searchTerm: string, bankAcct: BankAccount) {
    const searchValue = searchTerm.toLowerCase();
    const type = bankAcct.type ? bankAcct.type.toLowerCase() : '';
    const description = bankAcct.description ? bankAcct.description.toLowerCase() : '';
    return type.includes(searchValue) || description.includes(searchValue);
  }

}
