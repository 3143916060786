<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Account Details</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title" *ngIf="createMode">New Account</h2>
      <h2 class="card-title" *ngIf="!createMode && account">{{ account.officeCode }}{{ account.number }} {{ account.name }}</h2>
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <button id="edit-account" aria-label="Edit Account" *ngIf="!editMode && !createMode && updateComplete && canUpdateAccount()" (click)="setEditMode(true)" mat-mini-fab color="accent" matTooltip="Edit Account">
          <mat-icon>edit</mat-icon>
        </button>
        <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
        <button id="update-account" aria-label="Update Account" *ngIf="!createMode && editMode && updateComplete" [disabled]="!accountForm.valid || accountForm.pristine" (click)="submit()" mat-mini-fab color="accent" matTooltip="Update Account">
          <mat-icon>done</mat-icon>
        </button>
        <button id="create-account" aria-label="Create Account" *ngIf="createMode && updateComplete" [disabled]="!accountForm.valid" (click)="submit()" mat-mini-fab color="accent" matTooltip="Create Account">
          <mat-icon>done</mat-icon>
        </button>
        <button id="cancel-edit-account" aria-label="Discard Changes" *ngIf="updateComplete && editMode && (!createMode || (createMode && !accountForm.pristine))" (click)="reset()" mat-mini-fab color="" matTooltip="Discard Changes">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <form *ngIf="!errorMessage" [formGroup]="accountForm" (ngSubmit)="submit()" fxLayout="column">
      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="10">
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100">
          <ati-labeled-data *ngIf="!createMode && !editMode" label="Client" [data]="displayClient(client)" [link]="getClientLink()" fxFlex.xs="100" fxFlex.gt-xs="45"></ati-labeled-data>
          <div *ngIf="(createMode || isPending) && editMode" fxFlex.xs="100" fxFlex.gt-xs="45">
            <mat-form-field fxFlex="100">
              <input matInput placeholder="Client" formControlName="client" [matAutocomplete]="autoClient" required>
              <mat-error *ngIf="accountForm.get('client').invalid">You must select a valid client</mat-error>
              <button mat-button *ngIf="accountForm.get('client').value && editMode" matSuffix mat-icon-button aria-label="Clear Client" matTooltip="Clear Client" (click)="accountForm.get('client').setValue('')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-autocomplete #autoClient="matAutocomplete" [displayWith]="displayClient">
              <mat-option *ngFor="let client of filteredClients$ | async" [value]="client">{{ displayClient(client) }}</mat-option>
            </mat-autocomplete>
          </div>
          <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45">
            <input matInput placeholder="Account Name" formControlName="name" trim="blur" required>
            <mat-error *ngIf="accountForm.get('name').invalid">Account name must be 3 - 100 characters</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="!editMode" fxFlex="100" fxLayout.gt-xs="row wrap" fxLayout.xs="column" fxLayoutGap="5">
          <ati-labeled-data *ngIf="!editMode" fxFlex.xs="100" fxFlex.gt-xs="25" label="Broker Code" data="{{ displayBrokerRel(brokerRel) }}"></ati-labeled-data>
          <ati-labeled-data *ngIf="!editMode && leadBroker" fxFlex.xs="40" fxFlex.gt-xs="25" label="Lead Broker" data="{{ displayBroker(leadBroker) }}"></ati-labeled-data>
          <ati-labeled-data *ngIf="!editMode" fxFlex.xs="40" fxFlex.gt-xs="25" label="Managing Pod" data="{{ accountForm.get('pod').value | replace | titlecase  }}"></ati-labeled-data>
          <ati-labeled-data *ngIf="!editMode" fxFlex.xs="40" fxFlex.gt-xs="25" label="Rate Code" data="{{ commSchedule?.rateCode }}"></ati-labeled-data>
        </div>
        <div *ngIf="editMode" fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="10">
          <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="25">
            <input matInput placeholder="Broker Code" formControlName="brokerRel" [matAutocomplete]="autoBrokerRel" required>
            <mat-error *ngIf="accountForm.get('brokerRel').invalid">You must select a valid broker code</mat-error>
            <button mat-button *ngIf="accountForm.get('brokerRel').value && editMode && accountForm.get('brokerRel').enabled" matSuffix mat-icon-button aria-label="Clear Broker Code" matTooltip="Clear Broker Code" (click)="removeBrokerRel()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoBrokerRel="matAutocomplete" [displayWith]="displayBrokerRel">
            <mat-option *ngFor="let brokerRel of filteredBrokerRelationships$ | async" [value]="brokerRel">{{ displayBrokerRel(brokerRel) }}</mat-option>
          </mat-autocomplete>
          <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="25">
            <input matInput placeholder="Lead Broker" formControlName="leadBroker" [matAutocomplete]="autoLeadBroker">
            <mat-error *ngIf="accountForm.get('leadBroker').invalid">Non-house broker codes require a valid lead broker</mat-error>
            <button mat-button *ngIf="accountForm.get('leadBroker').value && editMode && accountForm.get('leadBroker').enabled" matSuffix mat-icon-button aria-label="Clear Lead Broker" matTooltip="Clear Lead Broker" (click)="removeLeadBroker()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoLeadBroker="matAutocomplete" [displayWith]="displayBroker">
            <mat-option *ngFor="let broker of filteredBrokers$ | async" [value]="broker">{{ displayBroker(broker) }}</mat-option>
          </mat-autocomplete>
          <mat-form-field fxFlex.xs="40" fxFlex.gt-xs="15">
            <mat-select [disableOptionCentering]="true" placeholder="Managing Pod" formControlName="pod" required>
              <mat-option *ngFor="let pod of podNames" [value]="pod">{{ pod | replace | titlecase }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="accountForm.get('purpose').value !== 'MARGIN_GROUP'" fxFlex.xs="40" fxFlex.gt-xs="10">
            <mat-form-field fxFlex="100">
              <input matInput placeholder="Rate Code" formControlName="commSchedule" [matAutocomplete]="autoCommSchedule" [required]="!accountForm.get('isOtcAcct').value">
              <mat-error *ngIf="accountForm.get('commSchedule').invalid">You must select a valid rate code</mat-error>
              <button mat-button *ngIf="accountForm.get('commSchedule').value && editMode && accountForm.get('commSchedule').enabled" matSuffix mat-icon-button aria-label="Clear Rate Code" matTooltip="Clear Rate Code" (click)="accountForm.get('commSchedule').setValue('')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-autocomplete #autoCommSchedule="matAutocomplete" [displayWith]="displayCommSchedule">
              <mat-option *ngFor="let commSchedule of filteredCommissionSchedules$ | async" [value]="commSchedule">{{ commSchedule?.rateCode }}</mat-option>
            </mat-autocomplete>
          </div>
        </div>
        <ati-labeled-data *ngIf="!editMode" label="Account Status" data="{{ accountForm.get('status').value | titlecase }}"></ati-labeled-data>
        <mat-radio-group *ngIf="!createMode && editMode" formControlName="status" required>
          <span class="check-radio-label">Account Status</span>
          <mat-radio-button [disabled]="account?.status !== 'PENDING'" value="PENDING">Pending</mat-radio-button>
          <mat-radio-button value="ACTIVE">Active</mat-radio-button>
          <mat-radio-button [disabled]="account?.status === 'PENDING' || account?.status === 'CLOSED'" value="INACTIVE">Inactive</mat-radio-button>
          <mat-radio-button [disabled]="account?.status === 'PENDING'" value="CLOSED">Closed</mat-radio-button>
          <mat-radio-button [disabled]="account?.status === 'ACTIVE' || account?.status === 'INACTIVE' || account?.status === 'CLOSED'" value="DENIED">Denied</mat-radio-button>
          <mat-error *ngIf="accountForm.hasError('invalidStatus') && !accountForm.pristine">{{ getErrorMessage(accountForm.get('status')) }}</mat-error>
        </mat-radio-group>
        <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100" fxLayoutGap="10">
          <ati-labeled-data *ngIf="account?.activationDate"  fxLayout="column" fxFlex.xs="45" fxFlex.gt-xs="25" label="Activation Date" data="{{ filterDateStrings(account.activationDate) | date }}"></ati-labeled-data>
          <ati-labeled-data *ngIf="account?.inactivationDate && account.status === 'INACTIVE'" fxLayout="column" fxFlex.xs="45" fxFlex.gt-xs="25" label="Inactivation Date" data="{{ filterDateStrings(account.inactivationDate) | date }}"></ati-labeled-data>
          <ati-labeled-data *ngIf="account?.reactivationDate && account.status === 'ACTIVE'" fxLayout="column" fxFlex.xs="45" fxFlex.gt-xs="25" label="Reactivation Date" data="{{ filterDateStrings(account.reactivationDate) | date }}"></ati-labeled-data>
          <ati-labeled-data *ngIf="account?.closureDate && account.status === 'CLOSED'" fxLayout="column" fxFlex.xs="45" fxflex.gt-xs="15" label="Closure Date" data="{{ filterDateStrings(account.closureDate) | date }}"></ati-labeled-data>
        </div>
        <ati-labeled-data *ngIf="!editMode" label="Account Purpose" data="{{ accountForm.get('purpose').value | replace | titlecase }}"></ati-labeled-data>
        <mat-radio-group *ngIf="editMode" formControlName="purpose" required>
          <span class="check-radio-label">Account Purpose</span>
          <mat-radio-button *ngFor="let purpose of acctPurpose" [disabled]="disablePurpose(purpose)" [value]="purpose">{{ purpose | replace | titlecase }}</mat-radio-button>
        </mat-radio-group>
        <div class="checkbox-group" fxFlex="100" fxLayout.gt-xs="row wrap" fxLayout.xs="column" fxLayoutGap="5">
          <span *ngIf="editMode" class="check-radio-label">Account Indicators</span>
          <div fxLayout.xs="column" fxLayoutAlign="start space-between" fxFlex="100">
            <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="Lead Account" data="{{ accountForm.get('isLeadAcct').value | yesNo }}"></ati-labeled-data>
            <ati-labeled-data *ngIf="!editMode" label="Money Account" fxFlex.gt-xs="25" data="{{ accountForm.get('isMoneyAcct').value | yesNo }}"></ati-labeled-data>
            <ati-labeled-data *ngIf="!editMode && showGiveUpAccount()" fxFlex.gt-xs="25" label="Give Up Account" data="{{ accountForm.get('isGiveUpAcct').value | yesNo }}"></ati-labeled-data>
            <ati-labeled-data *ngIf="!editMode && showDiscretionToTradeOverride()" fxFlex.gt-xs="25" label = "Discretion to Trade Override" data="{{ accountForm.get('isDtTOverridden').value | yesNo }}"></ati-labeled-data>
            <ati-labeled-data *ngIf="!editMode && showOtcAccount()" fxFlex.gt-xs="25" label="OTC Account" [data]="accountForm.get('isOtcAcct').value | yesNo"></ati-labeled-data>
          </div>
          <mat-checkbox *ngIf="editMode" formControlName="isLeadAcct">Lead Account</mat-checkbox>
          <mat-checkbox *ngIf="editMode" formControlName="isMoneyAcct">Money Account</mat-checkbox>
          <mat-checkbox *ngIf="editMode && showGiveUpAccount()" formControlName="isGiveUpAcct">Give Up Account</mat-checkbox>
          <mat-checkbox *ngIf="editMode && showDiscretionToTradeOverride()" formControlName="isDtTOverridden">Discretion to Trade Override</mat-checkbox>
          <mat-checkbox *ngIf="editMode && showOtcAccount()" formControlName="isOtcAcct">OTC Account</mat-checkbox>
        </div>
        <ati-labeled-data *ngIf="(createMode || isPending) && !editMode" fxFlex.xs="100" fxFlex.gt-xs="25" label="Account Number" data="{{ accountForm.get('number').value }}"></ati-labeled-data>
        <mat-form-field *ngIf="(createMode || isPending) && editMode" fxFlex.xs="100" fxFlex.gt-xs="25">
          <input matInput placeholder="Account Number" formControlName="number" upperCase [errorStateMatcher]="numberErrorMatcher" required>
          <mat-error *ngIf="accountForm.get('number').invalid || accountForm.hasError('invalidNumber')">{{ getErrorMessage(accountForm.get('number')) }}</mat-error>
        </mat-form-field>
        <div *ngIf="!editMode" fxLayout="row wrap" fxFlex="100">
          <ati-labeled-data *ngIf="accountForm.get('purpose').value === 'SPEC' || accountForm.get('purpose').value === 'HEDGE'" label="Margin Group Account" fxFlex.xs="100" fxFlex.gt-xs="25" data="{{ displayAccount(accountForm.get('marginGroupAcct').value) }}"></ati-labeled-data>
          <ati-labeled-data *ngIf="accountForm.get('client').value && accountForm.get('client').value.type === 'COMMERCIAL'" fxFlex.xs="100" fxFlex.gt-xs="25" label="Interest % To Client" data="{{ accountForm.get('interestPercent').value }}"></ati-labeled-data>
        </div>
        <div *ngIf="editMode" fxFlex="100">
        <mat-form-field *ngIf="accountForm.get('purpose').value === 'SPEC' || accountForm.get('purpose').value === 'HEDGE'" fxFlex.xs="100" fxFlex.gt-xs="25">
            <input matInput placeholder="Margin Group Account" formControlName="marginGroupAcct" [matAutocomplete]="autoMarginGroupAcct">
            <mat-error *ngIf="accountForm.get('marginGroupAcct').invalid">You must select a valid margin group account</mat-error>
            <button mat-button *ngIf="accountForm.get('marginGroupAcct').value && editMode && accountForm.get('marginGroupAcct').enabled" matSuffix mat-icon-button aria-label="Clear Margin Group Account" matTooltip="Clear Margin Group Account" (click)="removeMarginGroupAcct()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoMarginGroupAcct="matAutocomplete" [displayWith]="displayAccount">
            <mat-option *ngFor="let acct of filteredMarginGroupAccounts$ | async" [value]="acct">{{ displayAccount(acct) }}</mat-option>
          </mat-autocomplete>
        </div>
        <div *ngIf="editMode" fxFlex="100">
          <mat-form-field *ngIf="accountForm.get('client').value && accountForm.get('client').value.type === 'COMMERCIAL'" fxFlex.xs="100" fxFlex.gt-xs="25">
            <input matInput placeholder="Interest % To Client" formControlName="interestPercent" mask="099" required>
            <mat-error *ngIf="accountForm.get('interestPercent').invalid">% must be between 0 - 100</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="isSlidingScale" fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100">
          <ati-labeled-data *ngIf="!editMode && !createMode" fxFlex.xs="100" fxFlex.gt-xs="25" label="Sliding Scale Level" data="{{ accountForm.get('ssLevel').value }}"></ati-labeled-data>
          <div *ngIf="editMode || createMode" fxFlex.xs="100" fxFlex.gt-xs="20">
          <mat-form-field>
            <input matInput placeholder="Sliding Scale Level" formControlName="ssLevel" mask="0" required>
            <mat-error *ngIf="accountForm.get('ssLevel').invalid">Level must be between 1 - 9</mat-error>
          </mat-form-field>
          </div>
          <ati-labeled-data *ngIf="!editMode && account?.slidingScaleResetDate" fxLayout="column" fxFlex.xs="100" fxFlex.gt-xs="25" label="Sliding Scale Reset Date" data="{{ account?.slidingScaleResetDate | date: 'MMM d' }}"></ati-labeled-data>
          <div *ngIf="editMode || createMode" fxFlex.xs="100" fxFlex.gt-xs="40">
            <mat-form-field *ngIf="editMode || createMode" atomBirthDatePicker fxFlex.xs="100" fxFlex.gt-xs="45">
              <input matInput [matDatepicker]="ssResetDatePicker" placeholder="Sliding Scale Reset Date" formControlName="ssDate" (dateChange)="selectSSResetDate($event)" required>
              <mat-datepicker-toggle matSuffix [for]="ssResetDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #ssResetDatePicker startView="year"></mat-datepicker>
              <mat-error *ngIf="accountForm.get('ssDate').invalid">Invalid Date</mat-error>
            </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
          <h4 *ngIf="showFundsTransferHeader">Funds Transfer</h4>
          <button *ngIf="showAddFundsTransferButton" [disabled]="accountForm.disabled" id="add-funds-transfer" type="button" aria-label="Add Funds Transfer" matTooltip="Add Funds Transfer" color="primary" mat-icon-button (click)="showFundsTransfer()">
            <mat-icon>add_circle</mat-icon>
          </button>
          <button *ngIf="enableFundsTransfer && !account?.fundsTransfer" [disabled]="accountForm.disabled" id="cancel-funds-transfer" type="button" aria-label="Cancel Funds Transfer" matTooltip="Cancel Funds Transfer" color="accent" mat-icon-button (click)="hideFundsTransfer()">
            <mat-icon>cancel</mat-icon>
          </button>
          <button *ngIf="showDeleteFundsTransferButton" [disabled]="accountForm.disabled" id="delete-funds-transfer" type="button" aria-label="Delete Funds Transfer" matTooltip="Delete Funds Transfer" color="warn" mat-icon-button (click)="deleteFundsTransfer()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div *ngIf="enableFundsTransfer" fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100" fxLayoutGap="10">
          <div fxFlex="100">
            <ati-labeled-data *ngIf="!editMode" label="Type" data="{{ displayFundsTransferType(accountForm.get('fundsTransferType').value) }}"></ati-labeled-data>
            <mat-radio-group *ngIf="editMode" formControlName="fundsTransferType" required>
              <span class="check-radio-label">Type</span>
              <mat-radio-button value="WIRE">Wire</mat-radio-button>
              <mat-radio-button value="ACH_PULL">ACH Pull</mat-radio-button>
              <mat-radio-button value="CHECK">Check</mat-radio-button>
            </mat-radio-group>
          </div>
          <div fxFlex="100">
            <ati-labeled-data *ngIf="!editMode" fxFlex.xs="100" fxFlex.gt-xs="45" label="Intermediate Bank" data="{{ displayBank(intermediateBank) }}"></ati-labeled-data>
            <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45">
              <input matInput placeholder="Intermediate Bank" formControlName="intermediateBank" [matAutocomplete]="autoIntermediateBank">
              <mat-error *ngIf="accountForm.get('intermediateBank').invalid">You must select a valid intermediate bank</mat-error>
              <button mat-button *ngIf="accountForm.get('intermediateBank').value && editMode && accountForm.get('intermediateBank').enabled" matSuffix mat-icon-button aria-label="Clear Intermediate Bank" matTooltip="Clear Intermediate Bank" (click)="removeIntermediateBank()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-autocomplete #autoIntermediateBank="matAutocomplete" [displayWith]="displayBank">
              <mat-option *ngFor="let bank of filteredIntermediateBanks$ | async" [value]="bank">{{ bank?.name }}</mat-option>
            </mat-autocomplete>
          </div>
          <ati-labeled-data *ngIf="!editMode" fxflex.xs="100" fxFlex.gt-xs="25" label="Destination Bank" data="{{ displayBank(destinationBank) }}"></ati-labeled-data>
          <ati-labeled-data *ngIf="!editMode" fxFlex.xs="100" fxFlex.gt-xs="50" label="Destination Bank Account" data="{{ displayBankAccount(destinationBankAcct) }}"></ati-labeled-data>
          <div *ngIf="editMode" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between start">
          <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="45">
            <input matInput placeholder="Destination Bank" formControlName="destinationBank" [matAutocomplete]="autoDestinationBank" required>
            <mat-error *ngIf="accountForm.get('destinationBank').invalid">You must select a valid destination bank</mat-error>
            <button mat-button *ngIf="accountForm.get('destinationBank').value && accountForm.get('destinationBank').enabled && editMode" matSuffix mat-icon-button aria-label="Clear Destination Bank" matTooltip="Clear Destination Bank" (click)="accountForm.get('destinationBank').setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoDestinationBank="matAutocomplete" [displayWith]="displayBank">
            <mat-option *ngFor="let bank of filteredDestinationBanks$ | async" [value]="bank">{{ bank?.name }}</mat-option>
          </mat-autocomplete>
          <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="45">
            <input matInput placeholder="Destination Bank Account" formControlName="destinationBankAcct" [matAutocomplete]="autoDestinationBankAcct" required>
            <mat-error *ngIf="accountForm.get('destinationBankAcct').invalid">You must select a valid destination bank account</mat-error>
            <button mat-button *ngIf="accountForm.get('destinationBankAcct').value && accountForm.get('destinationBankAcct').enabled && editMode" matSuffix mat-icon-button aria-label="Clear Destination Bank Account" matTooltip="Clear Destination Bank Account" (click)="accountForm.get('destinationBankAcct').setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoDestinationBankAcct="matAutocomplete" [displayWith]="displayBankAccount">
            <mat-option *ngFor="let bankAcct of filteredDestinationBankAccts$ | async" [value]="bankAcct">{{ displayBankAccount(bankAcct) }}</mat-option>
          </mat-autocomplete>
        </div>
          <ati-labeled-data *ngIf="!editMode" fxFlex="100" label="Notes" data="{{ accountForm.get('fundsTransferNotes').value }}"></ati-labeled-data>
          <mat-form-field *ngIf="editMode" fxFlex="100">
            <input matInput placeholder="Notes" formControlName="fundsTransferNotes" trim="blur">
            <mat-error *ngIf="accountForm.get('fundsTransferNotes').invalid">Notes must not exceed 400 characters</mat-error>
          </mat-form-field>
        </div>
          <mat-expansion-panel *ngIf="(brokers$ | async) && !createMode && !editMode" class="mat-elevation-z0" fxFlex.gt-xs="33">
            <mat-expansion-panel-header>
              <mat-panel-title>Brokers</mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let broker of brokers$ | async">
              <div fxLayout="row" fxLayoutAlign="start start">
                <a (click)="navigateBrokers(broker)">{{displayBroker(broker, true)}}</a>
              </div>
            </div>
          </mat-expansion-panel>
      </div>
    </form>
  </mat-card>
</div>
