import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit,
         ChangeDetectorRef,
         Component,
         ElementRef,
         OnInit,
         Input,
         ViewChild,
         Output,
         EventEmitter,
         OnChanges,
         SimpleChanges,
         OnDestroy} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Observable, of, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ObservableDataSource, StorageService } from '@advance-trading/angular-common-services';
import { Client, PhoneNumber, PhoneNumberType } from '@advance-trading/ops-data-lib';
import { ExportService } from '../services/export.service';

const ACCOUNT_ADMIN_ROLE = 'AccountAdmin';
const PAGE_SIZE_KEY = 'atom.clientsPageSize';

@Component({
  selector: 'atom-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css'],
  providers: [BreakpointObserver]
})
export class ClientsComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {

  columnsToDisplay = [];
  accountAdmin = false;
  errorMessage: string;
  dataSource = new ObservableDataSource<Client>();
  filterValue = new FormControl();
  isLoading = true;
  exportable = false;

  private queryParams: Params;
  private unsubscribe$: Subject<void> = new Subject<void>();

  private readonly SHEET_NAME = 'ATOM Clients';

  @Input() selectedClients$: Observable<Client[]>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  @Output() clientListChange: EventEmitter<any> = new EventEmitter();
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    public exportService: ExportService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.isSearching.emit(true);
    this.breakpointObserver.observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        // display only name and type columns for xsmall screens
        if (state.matches) {
          this.columnsToDisplay = [
            'name', 'type'
          ];
          // display name, type, and status for larger screens
        } else {
          this.columnsToDisplay = [
            'name', 'location', 'phoneNumber', 'type', 'marginType', 'managingPod', 'status'
          ];
        }
      });

    this.accountAdmin = this.authzService.currentUserHasRole(ACCOUNT_ADMIN_ROLE);

    this.filterValue.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((filter: string) => {
      if (filter) {
        this.queryParams.filter = filter.trim();
      } else if (this.queryParams.filter) {
        delete this.queryParams.filter;
      }

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        replaceUrl: true,
        queryParams: this.queryParams
      });
    });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
      this.queryParams = Object.assign({}, params);
    }));

    const filterVal = this.queryParams.filter;
    if (filterVal) {
      this.filterValue.setValue(filterVal);
      this.applyFilter(this.filterValue.value);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedClients$']) {
      this.dataSource.data$ = this.selectedClients$
        .pipe(
          tap((clients) => {
            this.isSearching.emit(false);
            this.isLoading = false;
            this.setTableState();
            this.exportable = clients.length > 0;
          }),
          catchError(err => {
            this.isLoading = false;
            this.isSearching.emit(false);
            this.errorMessage = 'Error retrieving clients; you may not have permission to view these clients, please try again later';
            console.error(`Error retrieving clients: ${err}`);
            return of([]);
          })
        );
    } else {
      this.isSearching.emit(false);
      this.errorMessage = 'You do not have permission to view this information.';
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = this.storageService.localStorage.get(PAGE_SIZE_KEY).value() || 10;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId): string | number => {
      if (sortHeaderId === 'location') {
        return data.physicalAddress.city.toLowerCase();
      } else {
        return (data as {[key: string]: any})[sortHeaderId];
      }
    };
    this.dataSource.sort = this.sort;
    if (this.filter) {
      this.filter.nativeElement.focus();
    }
    this.changeDetector.detectChanges();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  selectClient(client: Client) {
    this.router.navigate(['/clients', client.docId]);
  }

  addClient() {
    this.router.navigate(['/clients/new']);
  }

  handlePageChange() {
    this.storageService.localStorage.set(PAGE_SIZE_KEY, this.paginator.pageSize);
    this.queryParams.pageIndex = this.paginator.pageIndex;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  handleSortChange() {
    this.queryParams.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.queryParams.sortColName = this.queryParams.sortDir ? this.sort.active : undefined;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  getXlsxExportItems() {
    const exportableItems: { [sheetName: string]: any[] } = {};
    exportableItems[this.SHEET_NAME] = this.getExportableItems();
    return exportableItems;
  }

  getExportableItems() {
    return this.dataSource.data.map(client => {
      const exportableRow = {};
      // populate rows
      exportableRow['Name'] = client.name;
      exportableRow['Type'] = client.type;
      exportableRow['Status'] = client.status;
      exportableRow['Managing Pod'] = client.managingPod.replace('_', ' ');
      if (client.phoneNumbers) {
        const phoneNumbers = [];
        client.phoneNumbers.forEach(phoneNumber => {
          phoneNumbers.push(phoneNumber.countryCode + phoneNumber.number);
        });
        exportableRow['Phone Numbers'] = `${phoneNumbers.join(', ')}`;
      }
      exportableRow['Mailing Address'] = client.mailingAddress.street1;
      exportableRow['City'] = client.mailingAddress.city;
      exportableRow['State'] = client.mailingAddress.region;
      exportableRow['Zip'] = client.mailingAddress.postalCode;
      return exportableRow;
    });
  }

  displayLocation(client: Client) {
    let location = '';
    if (client.physicalAddress && client.physicalAddress.city && client.physicalAddress.region) {
      location = client.physicalAddress.city + ', ' + client.physicalAddress.region;
    }
    return location;
  }

  displayPhoneNumbers(phoneNumbers: PhoneNumber[]) {
    const reducer = (result: string, phoneNumber: PhoneNumber) => `${result}${phoneNumber.number} (${this.displayType(phoneNumber.type)}),`;
    const newPhoneNumbers = phoneNumbers.reduce(reducer, '').slice(0,-1).split(',');
    return newPhoneNumbers.slice(0,4).join(', ');
  }

  private displayType(type: PhoneNumberType) {
    const newType = type.replace(/_/g, ' ');
    return newType.replace(
      /\w\S*/g,
      (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      }
    );
  }

  private setTableState() {
    if (this.filter) {
      const pageIndex = this.queryParams.pageIndex as number;
      if (pageIndex) {
        this.dataSource.paginator.pageIndex = pageIndex;
      }

      const sortDir = this.queryParams.sortDir as SortDirection;
      const sortColName = this.queryParams.sortColName as string;
      if (sortDir && sortColName) {
        this.dataSource.sort.direction = sortDir;
        this.dataSource.sort.active = sortColName;
      }
    }
  }
}
