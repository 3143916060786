import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { CommodityMap, Trade } from '@advance-trading/ops-data-lib';
import { Observable, of, Subject } from 'rxjs';
import { catchError, tap, switchMap, map, take, takeUntil, finalize } from 'rxjs/operators';
import { ObservableDataSource, StorageService } from '@advance-trading/angular-common-services';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OperationsDataService } from '@advance-trading/angular-ops-data';
import { ExportService } from '../../services/export.service';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AdjustmentService } from 'src/app/services/adjustment-service';
import { TradeDisplay } from 'src/app/utilities/trade-display';

const maxRows = 10000;
const PAGE_SIZE_KEY = 'atom.tradesPageSize';

@Component({
  selector: 'atom-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.css']
})
export class TradesComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {

  @Input() initialTableState: { [key: string]: string | number };
  @Input() selectedTrades$: Observable<Trade[]>;

  columnsToDisplay = [];
  dataSource = new ObservableDataSource<Trade>();
  errorMessage: string;
  commodityMap: CommodityMap;
  exportable = false;
  filterValue = new FormControl();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  @Output() tradeListChange: EventEmitter<any> = new EventEmitter();
  @Output() tradeSearchError: EventEmitter<string> = new EventEmitter();
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();

  private tableState: { [key: string]: string | number } = {};
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private operationsDataService: OperationsDataService,
    private adjustmentService: AdjustmentService,
    private snackBar: MatSnackBar,
    public exportService: ExportService,
    private router: Router,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.isSearching.emit(true);

    this.filterValue.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((filter: string) => {
      if (filter) {
        this.tableState.filterValue = filter.trim();
        this.tradeListChange.emit(this.tableState);
      } else if (this.tableState.filterValue) {
        delete this.tableState.filterValue;
        this.tradeListChange.emit(this.tableState);
      }
    });
    this.breakpointObserver.observe(
      [
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
      ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.breakpoints[Breakpoints.XSmall]) {
          this.columnsToDisplay = [
            'transactionDate', 'tradeDate', 'accountNumber', 'commodity'
          ];
        } else if (state.breakpoints[Breakpoints.Small]) {
          this.columnsToDisplay = [
            'transactionDate', 'tradeDate', 'brokerCode', 'accountNumber', 'side', 'quantity', 'contractYearMonth', 'strikePrice', 'commodity',
          ];
        } else if (state.breakpoints[Breakpoints.Medium]) {
          this.columnsToDisplay = [
            'transactionDate', 'tradeDate', 'brokerCode', 'accountNumber', 'side', 'quantity', 'contractYearMonth', 'strikePrice', 'commodity', 'securitySubType', 'commission',
          ];
        } else {
          this.columnsToDisplay = [
            'transactionDate', 'tradeDate', 'brokerCode', 'accountNumber', 'side', 'quantity', 'contractYearMonth', 'strikePrice', 'commodity', 'securitySubType', 'price', 'spreadCode',
            'commission', 'clearingFee', 'exchangeFee', 'nfaFee', 'transactionRecordType', 'openCloseCode', 'cashAdjustment', 'commissionAdjustment'
          ];
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = this.storageService.localStorage.get(PAGE_SIZE_KEY).value() || 10;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (trade, column) => {
      switch(column) {
        case 'brokerCode':
          return trade.officeCode + trade.salesCode;
        case 'commodity':
          return this.getCommodityDisplayName(trade.commodityId);
        default:
          return trade[column];
      }
    };
    if (this.filter) {
      this.filter.nativeElement.focus();
    }
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialTableState'] && changes['selectedTrades$']) {
      this.tableState = Object.assign({}, this.initialTableState);

      // detect MatSort and MatPaginator so it is defined
      this.changeDetector.detectChanges();

      const sortDir = this.initialTableState.sortDir as SortDirection;
      const sortColName = this.initialTableState.sortColName as string;
      if (sortDir && sortColName) {
        this.sort.direction = sortDir;
        this.sort.active = sortColName;
      }
      if (this.initialTableState.filterValue) {
        this.filterValue.setValue(this.initialTableState.filterValue);
        this.applyFilter(this.filterValue.value);
      }

      // initialize table
      this.dataSource.data$ = this.operationsDataService.getCommodityMap().pipe(
        switchMap((doc: CommodityMap) => {
          this.commodityMap = doc;
          return this.selectedTrades$;
        })
      ).pipe(
        catchError(err => {
          console.log(err);
          this.isSearching.emit(false);
          return of([]);
        }),
        map(trades => {
          const tradeDisplays = [];
          trades.forEach((trade: TradeDisplay) => {
            const tradeDisplay = {...trade};
            if (trade.linkedCashAdjustmentDocId) {
              this.adjustmentService.getAdjustmentByDocId(trade.accountDocId, trade.linkedCashAdjustmentDocId)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(val => {
                  tradeDisplay.cashAdjustment = val.amount;
                });
            }
            if (trade.linkedCommissionAdjustmentDocId) {
              this.adjustmentService.getAdjustmentByDocId(trade.accountDocId, trade.linkedCommissionAdjustmentDocId)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(val => {
                  tradeDisplay.commissionAdjustment = val.commission;
                });
            }
              tradeDisplays.push(tradeDisplay);
          });
          return tradeDisplays;
        }),
        tap((trades => {
          this.exportable = !!trades.length;
          this.isSearching.emit(false);

          // initialize pagination state when the datasource exist
          const pageIndex = this.initialTableState.pageIndex as number;
          if (pageIndex) {
            this.paginator.pageIndex = pageIndex;
          }

          if (trades.length === 0) {
            this.openSnackBar('There are no trades that match this search', 'DISMISS', true);
          } else if (trades.length === maxRows) {
            this.openSnackBar(`Reached maximum number of rows (${maxRows.toLocaleString()})`, 'DISMISS', true);
          } else {
            catchError(err => {
              this.errorMessage = 'Error retrieving trades; please try again later';
              this.tradeSearchError.emit(this.errorMessage);
              this.isSearching.emit(false);
              console.error(`Error retrieving trades: ${err}`);
              return of([]);
            });
          }
        }))
      );
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  getCommodityDisplayName(symbol: string) {
    if (!symbol) {
      return '';
    }
    const commodity = Object.values(this.commodityMap.commodities).find(cmd => cmd.id === symbol);
    if (commodity) {
      return commodity.name;
    } else {
      return '';
    }
  }

  getYearMonthDisplay(yearMonth: string) {
    return moment(yearMonth, 'YYYYMM').toISOString();
  }

  handleSortChange() {
    this.tableState.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.tableState.sortColName = this.tableState.sortDir ? this.sort.active : undefined;
    this.tradeListChange.emit(this.tableState);
  }

  handlePageChange() {
    this.storageService.localStorage.set(PAGE_SIZE_KEY, this.paginator.pageSize);
    this.tableState.pageIndex = this.paginator.pageIndex;
    this.tradeListChange.emit(this.tableState);
  }
  selectTrade(trade: Trade) {
    this.router.navigate(['/accounts', trade.accountDocId, 'trades', trade.docId]);
  }
}
