import { gql } from 'apollo-angular';

export class CashActivityQueries {

  static getCashActivityByAccountsQuery = gql<any, any>`
  query CashActivityByAccounts($accounts: [String!], $cashActivityQuery: CashActivityQuery) {
    cashActivityByAccounts(accounts: $accounts, cashActivityQuery: $cashActivityQuery) {
      docId
      accountDocId
      accountNumber
      clientDocId
      clientName
      fcm
      officeCode
      amount
      salesCode
      securityDescription
      businessDate
      transactionConversionJobId
      transactionDate
      currencyCode
      tracerNumber
    }
  }`;

  static getCashActivityByBrokerCodesQuery = gql<any, any>`
  query CashActivityByBrokerCodes($brokerCodes: [String!], $cashActivityQuery: CashActivityQuery) {
    cashActivityByBrokerCodes(brokerCodes: $brokerCodes, cashActivityQuery: $cashActivityQuery) {
      docId
      accountDocId
      accountNumber
      clientDocId
      clientName
      fcm
      officeCode
      amount
      salesCode
      businessDate
      securityDescription
      transactionConversionJobId
      transactionDate
      currencyCode
      tracerNumber
    }
  }`;
}
