import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CommodityMap, Trade, Adjustment} from '@advance-trading/ops-data-lib';
import { Observable, of, Subject } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TradeService } from '../../../services/trade-service';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AdjustmentService } from 'src/app/services/adjustment-service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ObservableDataSource } from '@advance-trading/angular-common-services';

@Component({
  selector: 'atom-trade-detail',
  templateUrl: './trade-detail.component.html',
  styleUrls: ['./trade-detail.component.css']
})
export class TradeDetailComponent implements OnInit, OnDestroy {
  columnsToDisplay = [];
  isLoading = false;
  errorMessage: string;
  isSearching = false;
  dataSource = new ObservableDataSource<Adjustment>();
  showAdjustmentsTable = false;

  trade$: Observable<Trade>;

  commodityMap: CommodityMap;

  private unsubscribe$: Subject<void> = new Subject<void>();

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private activatedRoute: ActivatedRoute,
    private tradeService: TradeService,
    private router: Router,
    private adjustmentService: AdjustmentService,
    private breakPointObserver: BreakpointObserver,
  ) { }

  ngOnInit() {
    this.breakPointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      if (state.breakpoints[Breakpoints.XSmall]) {
        this.columnsToDisplay = [
          'businessDate', 'accountNumber', 'brokerCode'
        ];
      } else if (state.breakpoints[Breakpoints.Small]) {
        this.columnsToDisplay = [
          'businessDate', 'tradeDate', 'brokerCode', 'accountNumber', 'commission'
        ];
      } else {
        this.columnsToDisplay = [
          'businessDate', 'tradeDate', 'brokerCode', 'accountNumber', 'commission', 'amount', 'clearingFee', 'exchangeFee', 'nfaFee', 'otherCharges', 'securityDetail'
        ];
      }
    });
    this.isLoading = true;
    this.trade$ = this.activatedRoute.paramMap.pipe(
      switchMap((paramMap: ParamMap) => {
        const tradeAccountDocId = paramMap.get('accountDocId');
        const tradeDocId = paramMap.get('tradeDocId');
        return this.tradeService.getTradeByDocId(tradeAccountDocId, tradeDocId);
      }),
      tap(trade => {
        if (!trade) {
          this.errorMessage = 'Error retrieving trade data; this trade either does not exist or you do not have permission to view this information';
        }
        this.dataSource.data$ = this.adjustmentService.getAdjustmentsByTrade(trade.accountDocId, trade.docId).pipe(
          tap(adjustments => {
            this.showAdjustmentsTable = adjustments.length > 0;
          }),
          catchError(err => {
            console.error(`Error retrieving adjustments for trade: ${err}`);
            this.showAdjustmentsTable = false;
            return of([]);
          })
        );
        this.isLoading = false;
      }),
      catchError(err => {
        this.errorMessage = 'Error retrieving trade data; please try again later';
        console.error(`Error retrieving trade data: ${err}`);
        return of(undefined);
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getYearMonthDisplay(yearMonth: string) {
    return moment(yearMonth, 'YYYYMM').toISOString();
  }

  selectAdjustment(adjustment: Adjustment) {
    this.router.navigate(['/accounts', adjustment.accountDocId, 'adjustments', adjustment.docId]);
  }
}

