<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Trade Details</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>

    <div *ngIf="!errorMessage && trade$ | async as trade">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="card-title">Trade: {{ trade.securityDescription }}</h2>
        <mat-spinner class="loading-spinner" *ngIf="isLoading" diameter="40" color="accent"></mat-spinner>
      </div>

      <div fxLayout="column" fxLayoutGap="15px">
        <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Client Name" [data]="trade.clientName">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Account Number" [data]="trade.accountNumber">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Broker Code" [data]="trade.officeCode + trade.salesCode">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="FCM" [data]="trade.fcm">
          </ati-labeled-data>
        </div>

        <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Trade Date" [data]="trade.tradeDate | date: 'shortDate'">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Business Date" [data]="trade.tradeDate | date: 'shortDate'">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Last Trading Date" [data]="trade.lastTradingDate | date: 'shortDate'">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Option Expiration Date" [data]="trade.optionExpirationDate">
          </ati-labeled-data>
        </div>

        <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Security Type" [data]="trade.securityType">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Side" [data]="trade.side | titlecase">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Open/Close" [data]="trade.openCloseCode === 'O' ? 'Open' : '-' || trade.openCloseCode === 'C'? 'Closed' : '-'">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Round Half Turn Code" [data]="trade.roundHalfTurnCode">
          </ati-labeled-data>
        </div>

        <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Commission" [data]="trade.commission === 0 ? '0' : trade.commission ? (trade.commission | number : '1.2') : '-'">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Exchange Fee" [data]="trade.exchangeFee === 0 ? '0' : trade.exchangeFee ? (trade.exchangeFee | number : '1.2') : '-'">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="nfaFee" [data]="trade.nfaFee === 0 ? '0': trade.nfaFee ? (trade.nfaFee | number : '1.2') : '-'">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Clearing Fee" [data]="(trade.clearingFee === 0 ? '0' : trade.clearingFee ? (trade.clearingFee | number : '1.2') : '-')">
          </ati-labeled-data>
        </div>

        <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Pit Fee" [data]="trade.pitFee === 0 ? '0' : trade.pitFee ? (trade.pitFee | number : '1.2') : '-'">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Brokerage Charge" [data]="trade.brokerageCharge === 0 ? '0' : trade.brokerageCharge ? (trade.brokerageCharge | number : '1.2') : '-'">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Gross Amount" [data]="trade.grossAmount === 0 ? '0' : trade.grossAmount ? (trade.grossAmount | number : '1.2') : '-'">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="multiplication Factor" [data]="trade.multiplicationFactor | number : '1.0-2'">
          </ati-labeled-data>
        </div>

        <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Quantity" [data]="trade.quantity">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Fill Price" [data]="trade.price === 0 ? '0' : trade.price ? (trade.price | number : '1.2-5') : '-'">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Closing Market/Settlement Price" [data]="trade.closingMarketOrSettlementPrice === 0 ? '0' : trade.closingMarketOrSettlementPrice ? (trade.closingMarketOrSettlementPrice | number : '1.2') : '-'">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Market Value/Open Trade Equity" [data]="trade.marketValueOrOpenTradeEquity === 0 ? '0' : trade.marketValueOrOpenTradeEquity ? (trade.marketValueOrOpenTradeEquity | number : '1.2') : '-'">
          </ati-labeled-data>
        </div>

        <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Exchange Id" [data]="trade.exchangeId">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Contract Month Year" [data]="getYearMonthDisplay(trade.contractYearMonth) | date: 'MMM YY'">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Outright/Spread" [data]="trade.spreadCode == 'S' ? 'Spread' : 'Outright'">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Transaction Record Type" [data]="trade.tradeRecordType">
          </ati-labeled-data>
        </div>

        <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Tracer Number" [data]="trade.tracerNumber">
          </ati-labeled-data>
          <ati-labeled-data *ngIf="trade.securityType === 'OPTION'" fxFlex.gt-xs="25" fxFlex.xs="50" label="Put/Call" [data]="trade.securitySubType | titlecase">
          </ati-labeled-data>
          <ati-labeled-data *ngIf="trade.securityType === 'OPTION'" fxFlex.gt-xs="25" fxFlex.xs="50" label="Strike Price" [data]="trade.strikePrice ? (trade.strikePrice | number : '1.2-5') : '-'">
          </ati-labeled-data>
        </div>
      </div>
      <div *ngIf="showAdjustmentsTable">
        <h3>Adjustments</h3>
        <mat-table matSort matSortActive="businessDate" matSortDirection="desc" [dataSource]="dataSource" class="mat-elevation-z0">
          <ng-container matColumnDef="businessDate">
            <mat-header-cell *matHeaderCellDef fxFlex.xs="33" fxFlex.gt-sm="10">Business Date</mat-header-cell>
            <mat-cell *matCellDef="let adjustment" fxFlex.xs="33" fxFlex.gt-sm="10">{{ adjustment.transactionDate | date: 'shortDate' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="tradeDate">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="33" fxFlex.gt-sm="9">Trade Date</mat-header-cell>
            <mat-cell *matCellDef="let adjustment" fxFlex.xs="33" fxFlex.gt-sm="9">{{ adjustment.businessDate | date: 'shortDate' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="brokerCode">
            <mat-header-cell *matHeaderCellDef fxFlex.xs="30" fxFlex.gt-sm="9">Broker Code</mat-header-cell>
            <mat-cell *matCellDef="let adjustment" fxFlex.xs="30" fxFlex.gt-sm="9">{{ adjustment.officeCode + adjustment.salesCode}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="accountNumber">
            <mat-header-cell *matHeaderCellDef fxFlex.xs="33" fxFlex.gt-sm="9">Account</mat-header-cell>
            <mat-cell *matCellDef="let adjustment" fxFlex.xs="33" fxFlex.gt-sm="9">{{ adjustment.accountNumber }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="commission">
            <mat-header-cell *matHeaderCellDef fxFlex.xs="30" fxFlex.gt-sm="9">Commission</mat-header-cell>
            <mat-cell *matCellDef="let adjustment" fxFlex.xs="30" fxFlex.gt-sm="9">{{ adjustment.commission ? (adjustment.commission | number : '1.2-5') : 0 }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef fxFlex.xs="33" fxFlex.gt-sm="9">Amount</mat-header-cell>
            <mat-cell *matCellDef="let adjustment" fxFlex.xs="33" fxFlex.gt-sm="9">{{ adjustment.amount ? (adjustment.amount | number: '1.2-5') : 0 }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="clearingFee">
            <mat-header-cell *matHeaderCellDef fxFlex.xs="30" fxFlex.gt-sm="8">Clearing Fee</mat-header-cell>
            <mat-cell *matCellDef="let adjustment" fxFlex.xs="30" fxFlex.gt-sm="8">{{ adjustment.clearingFee ? (adjustment.clearingFee | number : '1.2-5') : 0}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="exchangeFee">
            <mat-header-cell *matHeaderCellDef fxFlex.xs="30" fxFlex.gt-sm="9">Exchange Fee</mat-header-cell>
            <mat-cell *matCellDef="let adjustment" fxFlex.xs="30" fxFlex.gt-sm="9">{{ adjustment.exchangeFee ? (adjustment.exchangeFee | number : '1.2-5') : 0 }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="nfaFee">
            <mat-header-cell *matHeaderCellDef fxFlex.xs="30" fxFlex.gt-sm="9">NFA Fee</mat-header-cell>
            <mat-cell *matCellDef="let adjustment" fxFlex.xs="30" fxFlex.gt-sm="9">{{ adjustment.nfaFee ? (adjustment.nfaFee | number : '1.2-5') : 0}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="securityDetail">
            <mat-header-cell *matHeaderCellDef fxFlex.gt-sm="10">Detail</mat-header-cell>
            <mat-cell *matCellDef="let adjustment" fxFlex.gt-sm="10">{{ adjustment.securityDescription }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="otherCharges">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="30" fxFlex.gt-sm="9">Other Charges</mat-header-cell>
            <mat-cell *matCellDef="let adjustment" fxFlex.xs="30" fxFlex.gt-sm="9">{{ adjustment.otherCharges ? (adjustment.otherCharges | number : '1.2-5') : 0}}</mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
          <mat-row  *matRowDef="let adjustment; columns: columnsToDisplay" (click)="selectAdjustment(adjustment)"></mat-row>
        </mat-table>
      </div>
    </div>
  </mat-card>
</div>
