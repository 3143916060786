import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Observable, of, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ObservableDataSource, StorageService } from '@advance-trading/angular-common-services';
import { AccountSeriesService } from '@advance-trading/angular-ops-data';
import { AccountSeries, Status } from '@advance-trading/ops-data-lib';
import { ExportService } from 'src/app/services/export.service';

const PAGE_SIZE_KEY = 'atom.accountSeriesPageSize';

@Component({
  selector: 'atom-account-series',
  templateUrl: './account-series.component.html',
  styleUrls: ['./account-series.component.css'],
  providers: [BreakpointObserver]
})
export class AccountSeriesComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {

  columnsToDisplay = [];
  errorMessage: string;
  dataSource = new ObservableDataSource<AccountSeries>();
  filterValue = new FormControl();
  exportable = false;

  private queryParams: Params;
  private tableState: { [key: string]: string | number } = {};
  private unsubscribe$: Subject<void> = new Subject<void>();

  @Input() selectedAccountSeries$: Observable<AccountSeries[]>;
  @Input() initialTableState: { [key: string]: string | number };

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  @Output() accountSeriesListChange: EventEmitter<any> = new EventEmitter();
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private changeDetector: ChangeDetectorRef,
    public exportService: ExportService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.isSearching.emit(true);
    this.breakpointObserver.observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        // display only range, sales code, and accounts icon columns for xsmall screens
        if (state.matches) {
          this.columnsToDisplay = [
            'range', 'salesCode', 'icon'
          ];
        // display range, size, status, sales code, and accounts icon columns for larger screens
        } else {
          this.columnsToDisplay = [
            'range', 'size', 'status', 'salesCode', 'icon'
          ];
        }
      });

    // if (!this.authzService.currentUserHasRole(ACCOUNT_ADMIN_ROLE)) {
    //   this.errorMessage = 'You do not have permission to view this information.';
    // } else {
    //   this.dataSource.data$ = this.accountSeriesService.getAllAccountSeries()
    //     .pipe(
    //       tap(val => this.setTableState()),
    //       catchError(err => {
    //         this.errorMessage = 'Error retrieving account series; please try again later';
    //         console.error(`Error retrieving account series: ${err}`);
    //         return of([]);
    //       })
    //     );
    // }

    this.filterValue.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((filter: string) => {
      if (filter) {
        this.queryParams.filter = filter;
        this.accountSeriesListChange.emit(this.tableState);
      } else if (this.queryParams.filter) {
        delete this.queryParams.filter;
        this.accountSeriesListChange.emit(this.tableState);
      }

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        replaceUrl: true,
        queryParams: this.queryParams
      });
    });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
      this.queryParams = Object.assign({}, params);
    }));

    const filterVal = this.queryParams.filter;
    if (filterVal) {
      this.filterValue.setValue(filterVal);
      this.applyFilter(this.filterValue.value);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = this.storageService.localStorage.get(PAGE_SIZE_KEY).value() || 10;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId): string | number => {
      if (sortHeaderId === 'range') {
        return data.start + '-' + data.end;
      } else {
        return (data as { [key: string]: any })[sortHeaderId];
      }
    };
    this.dataSource.sort = this.sort;
    this.filter.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialTableState'] && changes['selectedAccountSeries$']) {
      this.tableState = Object.assign({}, this.initialTableState);
      this.changeDetector.detectChanges();

      const sortDir = this.initialTableState.sortDir as SortDirection;
      const sortColName = this.initialTableState.sortColName as string;
      if (sortDir && sortColName) {
        this.sort.direction = sortDir;
        this.sort.active = sortColName;
      }

      if (this.initialTableState.filter) {
        this.filterValue.setValue(this.initialTableState.filter);
        this.applyFilter(this.filterValue.value);
      }

      this.dataSource.data$ = this.selectedAccountSeries$.pipe(
        tap((accountSeries) => {
          this.exportable = accountSeries.length > 0;
          this.isSearching.emit(false);

          const pageIndex = this.initialTableState.pageIndex as number;
          if (pageIndex !== undefined) {
            this.paginator.pageIndex = pageIndex;
          }
        }),
        catchError(err => {
          this.isSearching.emit(false);
          this.errorMessage = 'Error retrieving account series; please try again later';
          console.error(`Error retrieving account series: ${err}`);
          return of([]);
        })

      );
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  /**
   * Replaces a pending status with assigned
   * @param status Account Series status
   */
  displayStatus(status: string): string {
    return status === Status.PENDING ? 'Assigned' : status;
  }

  selectAccountSeries(accountSeries: AccountSeries) {
    if (accountSeries.status !== Status.NEW) {
      this.router.navigate(['/salescodes', accountSeries.salesCodeDocId]);
    }
  }

  selectAccountsIcon(accountSeries: AccountSeries) {
    const seriesRange = accountSeries.start + ' - ' + accountSeries.end;
    this.router.navigate(['/accounts'], { queryParams: { accountSeriesDocId: accountSeries.docId, accountSeriesRange: seriesRange } });
  }

  handlePageChange() {
    this.storageService.localStorage.set(PAGE_SIZE_KEY, this.paginator.pageSize);
    this.queryParams.pageIndex = this.paginator.pageIndex;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  handleSortChange() {
    this.queryParams.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.queryParams.sortColName = this.queryParams.sortDir ? this.sort.active : undefined;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  private setTableState() {
    if (this.filter) {
      const pageIndex = this.queryParams.pageIndex as number;
      if (pageIndex) {
        this.dataSource.paginator.pageIndex = pageIndex;
      }

      const sortDir = this.queryParams.sortDir as SortDirection;
      const sortColName = this.queryParams.sortColName as string;
      if (sortDir && sortColName) {
        this.dataSource.sort.direction = sortDir;
        this.dataSource.sort.active = sortColName;
      }
    }
  }
}
