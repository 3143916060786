import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';

import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ObservableDataSource, StorageService } from '@advance-trading/angular-common-services';
import { UserService } from '@advance-trading/angular-ops-data';
import { BrokerService } from '../services/broker-service';
import { AccountService } from '../services/account-service';
import { Account, Broker, User } from '@advance-trading/ops-data-lib';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExportService } from '../services/export.service';

interface NamedBroker extends Broker {
  firstName: string;
  lastName: string;
}

const BROKER_REQUESTER_ROLE = 'BrokerRequester';
const PAGE_SIZE_KEY = 'atom.brokersPageSize';

@Component({
  selector: 'atom-broker',
  templateUrl: './brokers.component.html',
  styleUrls: ['./brokers.component.css'],
  providers: [BreakpointObserver]
})
export class BrokersComponent implements AfterViewInit, OnInit, OnDestroy {

  columnsToDisplay = [];
  brokerRequester = false;
  errorMessage: string;
  dataSource = new ObservableDataSource<NamedBroker>();
  filterValue = new FormControl();
  exportable = false;

  private queryParams: Params;
  private unsubscribe$: Subject<void> = new Subject<void>();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private brokerService: BrokerService,
    private changeDetector: ChangeDetectorRef,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private snackBar: MatSnackBar,
    private storageService: StorageService,
    public exportService: ExportService
  ) { }

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        // display only name and type columns for xsmall screens
        if (state.matches) {
          this.columnsToDisplay = [
            'name', 'type'
          ];
          // display name, type, and status for larger screens
        } else {
          this.columnsToDisplay = [
            'name', 'type', 'status'
          ];
        }
      });

    this.brokerRequester = this.authzService.currentUserHasRole(BROKER_REQUESTER_ROLE);


    this.filterValue.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((filter: string) => {
      if (filter) {
        this.queryParams.filter = filter.trim();
      } else if (this.queryParams.filter) {
        delete this.queryParams.filter;
      }

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        replaceUrl: true,
        queryParams: this.queryParams
      });
    });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
      this.queryParams = Object.assign({}, params);
    }));

    const filterVal = this.queryParams.filter;
    if (filterVal) {
      this.filterValue.setValue(filterVal);
      this.applyFilter(this.filterValue.value);
    }

    this.dataSource.data$ = this.retrieveBrokers();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = this.storageService.localStorage.get(PAGE_SIZE_KEY).value() || 10;
    // Special sorting accessor to handle the combined first and last name field
    this.dataSource.sortingDataAccessor = (data, sortHeaderId): string | number => {
      if (sortHeaderId === 'name') {
        return data.firstName + ' ' + data.lastName;
      } else {
        return (data as { [key: string]: any })[sortHeaderId];
      }
    };
    this.dataSource.sort = this.sort;
    this.filter.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  selectBroker(broker: User) {
    this.router.navigate(['/brokers', broker.docId]);
  }

  addNewBroker() {
    this.router.navigate(['/brokers/new']);
  }

  handlePageChange() {
    this.storageService.localStorage.set(PAGE_SIZE_KEY, this.paginator.pageSize);
    this.queryParams.pageIndex = this.paginator.pageIndex;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  handleSortChange() {
    this.queryParams.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.queryParams.sortColName = this.queryParams.sortDir ? this.sort.active : undefined;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  retrieveBrokers(): Observable<NamedBroker[]> {

    const accountDocIdValue = this.queryParams.accountDocId;

    if (accountDocIdValue) {

      const serviceValue = this.accountService.getAccount(accountDocIdValue).pipe(
        switchMap((account: Account) => {
          return combineLatest(account.brokers.map((brokerDocId) => {
            return this.brokerService.getBrokerByDocId(brokerDocId);
          }));
        })
      );

      return serviceValue.pipe(
        switchMap((brokers: Broker[]) => {
          const filteredBrokers = brokers.filter((broker) => broker);
          if (filteredBrokers.length === 0) {
            this.errorMessage = 'Error retrieving brokers; these brokers either do not exist or you do not have permission to view the information';
            return of([]);
          }
          return combineLatest(brokers.map(broker => {
            return this.userService.getUserByDocId(broker.docId)
              .pipe(
                map((user: User) => {
                  return {
                    ...broker,
                    firstName: user.firstName,
                    lastName: user.lastName
                  } as NamedBroker;
                })
              );
          }));
        }),
        tap(brokers => {
          this.setTableState();
          this.exportable = brokers.length > 0;
        }),
        catchError(err => {
          this.errorMessage = 'Error retrieving brokers; please try again later';
          console.error(`Error retrieving brokers: ${err}`);
          return of([]);
        })
      );

    } else {
      return this.brokerService.getAllBrokers().pipe(
        switchMap((brokers: Broker[]) => {
          return combineLatest(brokers.map(broker => {
            return this.userService.getUserByDocId(broker.docId)
              .pipe(
                map((user: User) => {
                  return {
                    ...broker,
                    firstName: user.firstName,
                    lastName: user.lastName
                  } as NamedBroker;
                })
              );
          }));
        }),
        tap(brokers => {
          this.setTableState();
          this.exportable = brokers.length > 0;
        }),
        catchError(err => {
          this.errorMessage = 'Error retrieving brokers; please try again later';
          console.error(`Error retrieving brokers: ${err}`);
          return of([]);
        })
      );
    }
  }

  private setTableState() {
    if (this.filter) {
      const pageIndex = this.queryParams.pageIndex as number;
      if (pageIndex) {
        this.dataSource.paginator.pageIndex = pageIndex;
      }

      const sortDir = this.queryParams.sortDir as SortDirection;
      const sortColName = this.queryParams.sortColName as string;
      if (sortDir && sortColName) {
        this.dataSource.sort.direction = sortDir;
        this.dataSource.sort.active = sortColName;
      }
    }
  }

  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }
}
