<ng-template #loading>
  <mat-spinner diameter="15"></mat-spinner>
</ng-template>
<form [formGroup]="rateForm" fxLayout="column">
  <mat-list role="list" class="commission-rate-table">
    <mat-list-item>
      <div class="commission-row">
        <div>Future</div>
        <div>Hedge</div>
        <div></div>
        <div>
          <mat-form-field *ngIf="!readOnly" floatLabel="never" appearance="fill">
            <input type="number" class="rate" placeholder="Rate" required matInput formControlName="futureHedge" (blur)="blurField('futureHedge')">
            <mat-error *ngIf="rateForm.get('futureHedge').invalid">{{getErrorMessage(rateForm.get('futureHedge'))}}</mat-error>
          </mat-form-field>
          <div *ngIf="readOnly">
            <ng-container *ngIf="rateForm.get('futureHedge').value else loading">
              {{ rateForm.get('futureHedge').value | number:'1.2-2' }}
            </ng-container>
          </div>
        </div>
      </div>
    </mat-list-item>
    <mat-divider class="divider"></mat-divider>
    <mat-list-item>
      <div class="commission-row">
        <div></div>
        <div>Spread</div>
        <div></div>
        <div>
          <mat-form-field *ngIf="!readOnly" floatLabel="never" appearance="fill">
            <input type="number" class="rate" placeholder="Rate" required matInput formControlName="futureSpread" (blur)="blurField('futureSpread')">
            <mat-error *ngIf="rateForm.get('futureSpread').invalid">{{getErrorMessage(rateForm.get('futureSpread'))}}</mat-error>
          </mat-form-field>
          <div *ngIf="readOnly">
            <ng-container *ngIf="rateForm.get('futureSpread').value else loading">
              {{ rateForm.get('futureSpread').value | number:'1.2-2' }}
            </ng-container>
          </div>
        </div>
      </div>
    </mat-list-item>
    <mat-divider class="divider"></mat-divider>
    <mat-list-item>
      <div class="commission-row">
        <div>Option</div>
        <div>Hedge</div>
        <div>Open</div>
        <div>
          <mat-form-field *ngIf="!readOnly" floatLabel="never" appearance="fill">
            <input type="number" class="rate" placeholder="Rate" required matInput formControlName="optionHedgeOpen" (blur)="blurField('optionHedgeOpen')">
            <mat-error *ngIf="rateForm.get('optionHedgeOpen').invalid">{{getErrorMessage(rateForm.get('optionHedgeOpen'))}}</mat-error>
          </mat-form-field>
          <div *ngIf="readOnly">
            <ng-container *ngIf="rateForm.get('optionHedgeOpen').value else loading">
              {{ rateForm.get('optionHedgeOpen').value | number:'1.2-2' }}
            </ng-container>
          </div>
        </div>
      </div>
    </mat-list-item>
    <mat-divider class="divider"></mat-divider>
    <mat-list-item>
      <div class="commission-row">
        <div></div>
        <div>Hedge</div>
        <div>Close</div>
        <div>
          <mat-form-field *ngIf="!readOnly" floatLabel="never" appearance="fill">
            <input type="number" class="rate" placeholder="Rate" required matInput formControlName="optionHedgeClose" (blur)="blurField('optionHedgeClose')">
            <mat-error *ngIf="rateForm.get('optionHedgeClose').invalid">{{getErrorMessage(rateForm.get('optionHedgeClose'))}}</mat-error>
          </mat-form-field>
          <div *ngIf="readOnly">
            <ng-container *ngIf="rateForm.get('optionHedgeClose').value else loading">
              {{ rateForm.get('optionHedgeClose').value | number:'1.2-2' }}
            </ng-container>
          </div>
        </div>
      </div>
    </mat-list-item>
    <mat-divider class="divider"></mat-divider>
    <mat-list-item>
      <div class="commission-row">
        <div></div>
        <div>Spread</div>
        <div>Open</div>
        <div>
          <mat-form-field *ngIf="!readOnly" floatLabel="never" appearance="fill">
            <input type="number" class="rate" placeholder="Rate" required matInput formControlName="optionSpreadOpen" (blur)="blurField('optionSpreadOpen')">
            <mat-error *ngIf="rateForm.get('optionSpreadOpen').invalid">{{getErrorMessage(rateForm.get('optionSpreadOpen'))}}</mat-error>
          </mat-form-field>
          <div *ngIf="readOnly">
            <ng-container *ngIf="rateForm.get('optionSpreadOpen').value else loading">
              {{ rateForm.get('optionSpreadOpen').value | number:'1.2-2' }}
            </ng-container>
          </div>
        </div>
      </div>
    </mat-list-item>
    <mat-divider class="divider"></mat-divider>
    <mat-list-item>
      <div class="commission-row">
        <div></div>
        <div>Spread</div>
        <div>Close</div>
        <div>
          <mat-form-field *ngIf="!readOnly" floatLabel="never" appearance="fill">
            <input type="number" class="rate" placeholder="Rate" required matInput formControlName="optionSpreadClose" (blur)="blurField('optionSpreadClose')">
            <mat-error *ngIf="rateForm.get('optionSpreadClose').invalid">{{getErrorMessage(rateForm.get('optionSpreadClose'))}}</mat-error>
          </mat-form-field>
          <div *ngIf="readOnly">
            <ng-container *ngIf="rateForm.get('optionSpreadClose').value else loading">
              {{ rateForm.get('optionSpreadClose').value | number:'1.2-2' }}
            </ng-container>
          </div>
        </div>
      </div>
    </mat-list-item>
  </mat-list>
</form>
