import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'atom-commission-rate-table',
  templateUrl: './commission-rate-table.component.html',
  styleUrls: ['./commission-rate-table.component.css']
})
export class CommissionRateTableComponent implements OnInit {
  @Input() rateForm: FormGroup = this.fb.group({
    futureHedge: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
    futureSpread: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
    optionHedgeOpen: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
    optionHedgeClose: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
    optionSpreadOpen: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
    optionSpreadClose: ['', [Validators.required, Validators.min(0), Validators.max(999)]]
  });
  @Input() readOnly: boolean;

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Required';
    }
    if (control.hasError('min') || control.hasError('max')) {
      return 'Invalid';
    }
    return 'Error';
  }

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
  }

  blurField(fieldName: string) {
    const formControl = this.rateForm.get(fieldName);
    const value = parseFloat(formControl.value);

    if (isNaN(value)) {
      return;
    }

    const decimalLoc = String(value).indexOf('.');
    const numDecimalPlaces = decimalLoc > -1 ? String(value).length - decimalLoc - 1 : 0;
    const formattedValue = value.toFixed(Math.max(2, numDecimalPlaces));
    formControl.setValue(formattedValue);
  }
}
