import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { ActivatedRoute, Params, ParamMap, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';


import { ContactService } from '@advance-trading/angular-ops-data';
import { ClientService } from '../../services/client-service';
import { Contact, Client } from '@advance-trading/ops-data-lib';
import { ObservableDataSource, StorageService } from '@advance-trading/angular-common-services';
import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ConfirmDialogComponent } from '../../utilities/ui/confirm-dialog/confirm-dialog.component';
import { ExportService } from 'src/app/services/export.service';

const ACCOUNT_ADMIN_ROLE = 'AccountAdmin';
const PAGE_SIZE_KEY = 'atom.contactsPageSize';

@Component({
  selector: 'atom-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css'],
  providers: [BreakpointObserver]
})
export class ContactsComponent implements AfterViewInit, OnInit, OnDestroy {

  accountAdmin = false;
  columnsToDisplay = [];
  errorMessage: string;
  dataSource = new ObservableDataSource<Contact>();
  isLoading = true;
  exportable = false;

  private clientDocId: string;
  client$: Observable<Client>;
  private queryParams: Params;
  private client: Client;
  private unsubscribe$: Subject<void> = new Subject<void>();

  private readonly SHEET_NAME = 'ATOM Contact';

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private contactService: ContactService,
    private breakpointObserver: BreakpointObserver,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    public exportService: ExportService,
    private storageService: StorageService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
      this.queryParams = Object.assign({}, params);
    }));

    this.accountAdmin = this.authzService.currentUserHasRole(ACCOUNT_ADMIN_ROLE);
    if (this.accountAdmin) {
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(state => {
          if (state.breakpoints[Breakpoints.XSmall]) {
            this.columnsToDisplay = [
              'name', 'title', 'delete'
            ];
          } else if (state.breakpoints[Breakpoints.Small]) {
            console.log('here');
            this.columnsToDisplay = [
              'name', 'title', 'isAuthorizedTrader', 'isAuthorizedSigner', 'address', 'phone', 'delete'
            ];
          } else {
            this.columnsToDisplay = [
              'name', 'title', 'isAuthorizedTrader', 'isAuthorizedSigner', 'address', 'phone', 'email', 'delete'
            ];
          }
        });
    } else {
      this.breakpointObserver.observe([Breakpoints.XSmall])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(state => {
          // display only name and type columns for xsmall screens
          if (state.matches) {
            this.columnsToDisplay = [
              'name', 'title'
            ];
            // display name, type, and status for larger screens
          } else {
            this.columnsToDisplay = [
              'name', 'title', 'email'
            ];
          }
        });
    }

    this.client$ = this.route.paramMap.pipe(
      filter((params: ParamMap) => params.has('clientDocId')),
      switchMap((params: ParamMap) => {
        this.clientDocId = params.get('clientDocId');
        return this.clientService.getClient(params.get('clientDocId'));
      })
    );

    this.dataSource.data$ = this.route.paramMap
      .pipe(
        filter((params: ParamMap) => params.has('clientDocId')),
        switchMap((params: ParamMap) => {
          return this.clientService.getClient(params.get('clientDocId')).pipe(
            switchMap(client => {
              this.client = client;
              return this.contactService.getAllContactsByClientDocId(client.docId);
            })
          );
        }),
        tap(contacts => {
          this.isLoading = false;
          this.setTableState();
          this.exportable = contacts.length > 0;
        }),
        catchError(err => {
          this.isLoading = false;
          this.errorMessage = 'Error retrieving contacts; please try again later';
          console.error(`Error retrieving contacts: ${err}`);
          return of([]);
        })
      );
  }

  ngOnDestroy () {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = this.storageService.localStorage.get(PAGE_SIZE_KEY).value() || 10;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId): string | number => {
      if (sortHeaderId === 'name') {
        return data.firstName + ' ' + data.lastName;
      } else {
        return (data as { [key: string]: any })[sortHeaderId];
      }
    };
    this.dataSource.sort = this.sort;
    this.changeDetector.detectChanges();
  }

  setTableState() {
    const pageIndex = this.queryParams.pageIndex as number;
    if (pageIndex) {
      this.dataSource.paginator.pageIndex = pageIndex;
    }

    const sortDir = this.queryParams.sortDir as SortDirection;
    const sortColName = this.queryParams.sortColName as string;
    if (sortDir && sortColName) {
      this.dataSource.sort.direction = sortDir;
      this.dataSource.sort.active = sortColName;
    }
  }

  handlePageChange() {
    this.storageService.localStorage.set(PAGE_SIZE_KEY, this.paginator.pageSize);
    this.queryParams.pageIndex = this.paginator.pageIndex;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  handleSortChange() {
    this.queryParams.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.queryParams.sortColName = this.queryParams.sortDir ? this.sort.active : undefined;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  selectContact(contact: Contact) {
    this.router.navigate([contact.docId], { relativeTo: this.route });
  }

  addNewContact() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  deleteContact(contact: Contact) {
    this.contactService.deleteContact(this.clientDocId, contact);
  }

  deleteContactMessage(contact: Contact) {
    if (contact.isAuthorizedTrader || contact.isAuthorizedSigner) {
      const title = 'This contact needs to be removed from a clearing firm before deletion.';
      const message = 'Would you like to navigate to clearing firms?';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: { title, message },
        height: '250px',
        width: '400px',
      });

      dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(confirm => {
        if (!confirm) {
          return;
        } else {
          this.router.navigate(['clients', this.clientDocId]);
        }
      });
    } else {
      this.openConfirmDialog(contact, 'Delete Contact', 'This will permanently delete this contact, do you want to continue?');
    }
  }

  getXlsxExportItems() {
    const exportableItems: { [sheetName: string]: any[] } = {};
    exportableItems[this.SHEET_NAME] = this.getExportableItems();
    return exportableItems;
  }

  getExportableItems() {
    return this.dataSource.data.map(contact => {
      const exportableRow = {};
      // populate rows
      exportableRow['Name'] = `${contact.firstName} ${contact.lastName}`;
      exportableRow['Title'] = contact.title;
      exportableRow['Email'] = contact.email;
      exportableRow['Client Name'] = this.client.name;
      exportableRow['Managing Pod'] = this.client.managingPod.replace('_', ' ');
      const phoneNumbers = [];
      if (contact.phoneNumbers) {
        contact.phoneNumbers.forEach(phoneNumber => {
          phoneNumbers.push(phoneNumber.countryCode + phoneNumber.number);
        });
      }
      exportableRow['Phone Numbers'] = `${phoneNumbers.join(', ')}`;
      if (contact.address) {
        exportableRow['Mailing Address'] = contact.address.street1;
        exportableRow['City'] = contact.address.city;
        exportableRow['State'] = contact.address.region;
        exportableRow['Zip'] = contact.address.postalCode;
      }
      return exportableRow;
    });
  }

  displayAddress(contact: Contact) {
    let address = '';
    if (contact.address) {
      address = contact.address.street1 + ', ' + contact.address.city + ', ' + contact.address.region + ', ' + contact.address.postalCode;
    }
    return address;
  }

  private openConfirmDialog(contact: Contact, title: string, message: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title, message },
      height: '250px',
      width: '400px'
    });

    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(confirm => {
      if (!confirm) {
        return;
      } else {
        this.deleteContact(contact);
      }
    });
  }
}
