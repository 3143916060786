<div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="10">
  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
      <h6 *ngIf="filteredBy" class="card-brow">{{ filteredBy | uppercase }}</h6>

      <div *ngIf="errorMessage">
        <h2 class="card-title">Error Performing Account Series Search</h2>
        <p [innerHTML]="errorMessage"></p>
        <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
      </div>

        <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
          <h2 class="card-title">Account Series</h2>
        </div>

        <form *ngIf="!errorMessage" [formGroup]="accountSeriesSearchForm" (ngSubmit)="searchAccountSeries(true)" fxLayout="column">
          <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
            <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="70" (click)="salesCodeFieldClicked()">
              <mat-label>Search Sales Code</mat-label>
              <input matInput formControlName="salesCode" trim="blur" maxLength="3">
              <mat-error *ngIf="accountSeriesSearchForm.get('salesCode').invalid" >{{ getErrorMessage(accountSeriesSearchForm.get('salesCode')) }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="70" (click)="statusFieldClicked()">
              <mat-label>Status</mat-label>
              <mat-select [disableOptionCentering]="true" formControlName="status">
                <mat-option value="ALL">All</mat-option>
                <mat-option value="NEW">New</mat-option>
                <mat-option value="PENDING">Pending</mat-option>
                <mat-option value="ACTIVE">Active</mat-option>
                <mat-option value="CLOSED">Closed</mat-option>
            </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="!isSearching" fxLayout="row" fxLayoutGap="10px">
            <button id="submit-account-series-search" [disabled]="accountSeriesSearchForm.invalid" aria-label="Search" type="submit"
              mat-raised-button color="accent">Search</button>
            <button id="clear-account-series-search" *ngIf="!accountSeriesSearchForm.pristine"  aria-label="Reset" (click)="reset()"
              mat-stroked-button color="accent">Reset</button>
          </div>

          <div *ngIf="isSearching" fxLayout="row" fxLayoutGap="10px">
            <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
          </div>

      </form>

      <div *ngIf="showAccountSeries && !errorMessage" class="account-series-list">
        <atom-account-series (accountSeriesListChange)="handleAccountSeriesListChange($event)" (accountSeriesSearchError)="handleAccountSeriesError($event)" (isSearching)="handleIsSearching($event)"
          [selectedAccountSeries$]="selectedAccountSeries$" [initialTableState]="tableState"></atom-account-series>
      </div>

    </mat-card>
  </div>
</div>
