import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Account, Trade, AccountPurpose } from '@advance-trading/ops-data-lib';
import { shareReplay, map, switchMap } from 'rxjs/operators';
import { Observable, of, combineLatest } from 'rxjs';
import { TradeSearchCriteria } from './service-interfaces/trade-search-interface';
import { AuthService } from '@advance-trading/angular-ati-security';
import { HttpHeaders } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { TradeQueries } from '../services/graphql-queries/trade-queries';
import { AccountService } from './account-service';

@Injectable({
  providedIn: 'root'
})
export class TradeService {

  constructor(
    private authService: AuthService,
    private db: AngularFirestore,
    private accountService: AccountService,
    private apollo: Apollo
  ) { }

  /**
   * Return the specified Trade document
   * @param accountDocId the docId of the Account containing the Trade to  return
   * @param transactionDocId the docId of the Trade to return
   */
  getTradeByDocId(accountDocId: string, tradeDocId: string): Observable<Trade> {
    return this.db.doc<Trade>(`${Account.getDataPath(accountDocId)}/${Trade.getDataPath(tradeDocId)}`)
      .valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  updateTrade(accountDocId: string, trade: Trade): Promise<void> {
    trade.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.db.doc(Account.getDataPath(accountDocId)).collection(Trade.getDataPath())
      .doc<Trade>(trade.docId).update(trade);
  }

  // GraphQL Queries

  /**
   * Returns Trade objects by accounts or accounts associated with a margin group account and other search criteria specified
   * @param gqlParams an interface that has been populated with all search query criteria
   * @param accountNumbers account numbers to return trades for
   */
  getTradesByAccountNumbers(accountNumbers: string[], gqlParams: any): Observable<Trade[]> {
    if (accountNumbers.length === 0) {
      return this.getTradesByAccountsQuery(accountNumbers, gqlParams);
    }
    return combineLatest(
      accountNumbers.map((accountNumber) => {
        return this.accountService.retrieveActiveAccountsByNumber(accountNumber);
      })
    ).pipe(map((accounts) => accounts.flat()))
      .pipe(switchMap((accounts) => {
        if (accounts.length === 0) {
          return of([]);
        }
        const observableAccounts = [];
        accounts.forEach((account: Account) => {
          // For margin group accounts fetch all accounts that are associated with the margin group account
          if (account.purpose === AccountPurpose.MARGIN_GROUP) {
            observableAccounts.push(this.accountService.retrieveAccountsByMarginGroupAccountDocId(account.marginGroupAccountDocId));
          }
        });

        if (observableAccounts.length) {
          return combineLatest(observableAccounts).pipe(
            map(accts => accts.flat()),
            switchMap((accts: Account[]) => {

              // Remove duplicate accounts before getting Trades for each account
              const duplicates = [];
              accts = accts.filter(acct => duplicates.includes(acct.docId) ? false : duplicates.push(acct.docId));
              const acctNumbers = accts.map((account: Account): string => account.number);
              return this.getTradesByAccountsQuery(acctNumbers, gqlParams);
            })
          );
        }
        accountNumbers = accounts.map(account => account.number);
        return this.getTradesByAccountsQuery(accountNumbers, gqlParams);
      }));
  }

  /**
   * Returns selected fields of Trade objects by accounts and other search criteria specified by a graphql query
   * @param tradeQuery an interface that has been populated with all search query criteria
   */
  private getTradesByAccountsQuery(accounts: string[], tradeQuery: TradeSearchCriteria) {
    return this.apollo.query({
      query: TradeQueries.getTradesByAccountsQuery,
      variables: {
        accounts,
        tradeQuery
      },
      context: {
        withCredentials: true,
        headers: new HttpHeaders({ Authorization: `Bearer ${this.authService.accessToken}` })
      }
    }).pipe(
      map(results => {
        return results.data.tradesByAccounts;
      })
    );
  }

  /**
   * Returns selected fields of Trade objects by brokerCodes and other search criteria specified
   * @param tradeQuery an interface that has been populated with all search query criteria
   */
  getTradesByBrokerCodes(brokerCodes: string[], tradeQuery: TradeSearchCriteria) {
    return this.apollo.query({
      query: TradeQueries.getTradesByBrokerCodesQuery,
      variables: {
        brokerCodes,
        tradeQuery
      },
      context: {
        withCredentials: true,
        headers: new HttpHeaders({ Authorization: `Bearer ${this.authService.accessToken}` })
      }
    }).pipe(
      map(results => {
        return results.data.tradesByBrokerCodes;
      })
    );
  }
}
