<form *ngIf="payCodeForm" [formGroup]="payCodeForm" fxLayout="row wrap" fxFlex="100" class="pay-code-form">
  <div fxLayout="row wrap" fxFlex="100">
    <div *ngIf="(!editMode && !createMode) || (createMode && index !== 0) || (editMode && !userHasAccounting())">
      <h3 class="paycode-title">Pay Code: {{payCodeForm.get('id').value}} </h3>
    </div>
    <div fxFlex.xs="100" fxFlex.gt-xs="16.5" *ngIf="(editMode || (createMode && index === 0)) && userHasAccounting()">
      <mat-form-field fxFlex="70">
        <input matInput type="text" placeholder="Payroll ID" formControlName="id" required>
        <mat-error *ngIf="payCodeForm.get('id').invalid">
          Invalid Payroll ID
        </mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="(editMode || (createMode && index === 0)) && userHasAccounting()" fxFlex.xs="100" fxFlex.gt-xs="33">
      <mat-form-field fxFlex="80">
        <mat-label>Pay Division</mat-label>
        <mat-select [disableOptionCentering]="true" formControlName="payDivision">
          <mat-option *ngFor="let payDivision of payDivisions" value="{{payDivision}}">
            {{payDivision.length === 8 ? (payDivision | titlecase) : (payDivision.substr(0, 3) + (payDivision.substr(3) | replace | titlecase))}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex *ngIf="(editMode || (createMode && index === 0)) && userHasAccounting()">
      <mat-radio-group fxFlex="100" formControlName="isActive" required>
        <span class="check-radio-label">Status</span>
        <mat-radio-button value="ACTIVE">Active</mat-radio-button>
        <mat-radio-button value="INACTIVE">Inactive</mat-radio-button>
      </mat-radio-group>
    </div>
    <button class="remove-pay-code" id="remove" aria-label="Remove Pay Code" type="button"
      (click)="removePayCode()" *ngIf="editMode && userHasAccounting()" matTooltip="Remove Pay Code" mat-icon-button>
      <mat-icon color="warn" mat-list-icon>delete</mat-icon>
    </button>
  </div>
  <div fxLayout="row wrap" fxFlex="100">
    <div *ngIf="(editMode || (createMode && index === 0)) && userHasAccounting()" fxFlex.xs="100" fxFlex.gt-xs="80">
      <mat-form-field fxFlex="100">
        <input matInput type="text" placeholder="Comments" formControlName="comments">
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row wrap" fxFlex="100" *ngIf="((!editMode && !createMode) || (createMode && index !== 0) || (editMode && !userHasAccounting()))" >
    <ati-labeled-data fxFlex.xs="40" fxFlex.gt-xs="16.5" label="Payroll ID" data="{{ payCodeForm.get('id').value }}"></ati-labeled-data>
    <ati-labeled-data fxFlex.xs="40" fxFlex.gt-xs="16.5" label="Pay Division"
      data="{{payCodeForm.get('payDivision').value.length === 8 ? (payCodeForm.get('payDivision').value | titlecase) : (payCodeForm.get('payDivision').value.substr(0, 3) + (payCodeForm.get('payDivision').value.substr(3) | replace | titlecase))}}">
    </ati-labeled-data>
    <ati-labeled-data fxFlex.xs="40" fxFlex.gt-xs="16.5" label="Status" data="{{ payCodeForm.get('isActive').value | titlecase }}">
    </ati-labeled-data>
    <ati-labeled-data fxFlex.xs="100" fxFlex.gt-xs="40" label="Comments" data="{{ payCodeForm.get('comments').value }}"></ati-labeled-data>
  </div>
  <div fxLayout="row wrap" fxFlex="100" *ngIf="(!editMode && !createMode) || (createMode && index !== 0) || (editMode && !userHasAccounting())">
    <ati-labeled-data  fxFlex.xs="40" fxFlex.gt-xs="16.5" label="AP Commission"
      data="{{ commissionSummary.apCommissionPercent | percent: '1.0-8'}}"></ati-labeled-data>
    <ati-labeled-data fxFlex.xs="40" fxFlex.gt-xs="16.5" label="Investor Commission"
      data="{{ commissionSummary.investorCommissionPercent | percent: '1.0-8'}}"></ati-labeled-data>
    <ati-labeled-data fxFlex.xs="40" fxFlex.gt-xs="16.5" label="Investor Overrides"
      data="{{ commissionSummary.investorOverridePercent| percent: '1.0-8' }}"></ati-labeled-data>
    <ati-labeled-data  fxFlex.xs="40" fxFlex.gt-xs="16.5" label="Mentoring"
      data="{{ commissionSummary.mentoringPercent| percent: '1.0-8'}}"></ati-labeled-data>
  </div>
  <mat-expansion-panel fxFlex="100" class="mat-elevation-z0">
    <mat-expansion-panel-header>
      GL Accounts
    </mat-expansion-panel-header>
    <div fxLayout="row wrap" fxFlex="100">
      <div *ngIf="(editMode || (createMode && index === 0)) && userHasAccounting()" fxFlex.xs="50" fxFlex.gt-xs="16.5">
        <mat-form-field fxFlex="80">
          <input matInput type="text" placeholder="Gross Commission" formControlName="glGrossCommissionAccount"
            required>
            <mat-error *ngIf="payCodeForm.get('glGrossCommissionAccount').invalid">
            Invalid Account Number
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="(editMode || (createMode && index === 0)) && userHasAccounting()" fxFlex.xs="50" fxFlex.gt-xs="16.5">
        <mat-form-field fxFlex="80">
          <input matInput type="text" placeholder="Retention" formControlName="glRetentionAccount"
            required>
            <mat-error *ngIf="payCodeForm.get('glRetentionAccount').invalid">
              Invalid Account Number
            </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="(editMode || (createMode && index === 0)) && userHasAccounting()" fxFlex.xs="50" fxFlex.gt-xs="16.5">
        <mat-form-field fxFlex="80">
          <input matInput type="text" placeholder="Trade Commission"
            formControlName="glAdjustmentTradeCommissionAccount" required>
            <mat-error *ngIf="payCodeForm.get('glAdjustmentTradeCommissionAccount').invalid">
              Invalid Account Number
            </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="(editMode || (createMode && index === 0)) && userHasAccounting()" fxFlex.xs="50" fxFlex.gt-xs="16.5">
        <mat-form-field fxFlex="80">
          <input matInput type="text" placeholder="Mentoring" formControlName="glMentoringAccount"
            required>
            <mat-error *ngIf="payCodeForm.get('glMentoringAccount').invalid">
              Invalid Account Number
            </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="(editMode || (createMode && index === 0)) && userHasAccounting()" fxFlex.xs="50" fxFlex.gt-xs="16.5">
        <mat-form-field fxFlex="80">
          <input matInput type="text" placeholder="Override" formControlName="glOverrideAccount" required>
          <mat-error *ngIf="payCodeForm.get('glOverrideAccount').invalid">
            Invalid Account Number
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="(editMode || (createMode && index === 0)) && userHasAccounting()" fxFlex.xs="50" fxFlex.gt-xs="16.5">
        <mat-form-field fxFlex="80">
          <input matInput type="text" placeholder="Contracts" formControlName="glContractsAccount"
            required>
            <mat-error *ngIf="payCodeForm.get('glContractsAccount').invalid">
              Invalid Account Number
            </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="(!editMode && !createMode) || (createMode && index !== 0) || (editMode && !userHasAccounting())" fxFlex.xs="40" fxFlex.gt-xs="16.5">
        <ati-labeled-data label="Gross Commission" data="{{ payCodeForm.get('glGrossCommissionAccount').value}}">
        </ati-labeled-data>
      </div>
      <div *ngIf="(!editMode && !createMode) || (createMode && index !== 0) || (editMode && !userHasAccounting())" fxFlex.xs="40" fxFlex.gt-xs="16.5">
        <ati-labeled-data label="Retention" data="{{ payCodeForm.get('glRetentionAccount').value }}">
        </ati-labeled-data>
      </div>
      <div *ngIf="(!editMode && !createMode) || (createMode && index !== 0) || (editMode && !userHasAccounting())" fxFlex.xs="40" fxFlex.gt-xs="16.5">
        <ati-labeled-data label="Trade Commission"
          data="{{ payCodeForm.get('glAdjustmentTradeCommissionAccount').value }}"></ati-labeled-data>
      </div>
      <div *ngIf="(!editMode && !createMode) || (createMode && index !== 0) || (editMode && !userHasAccounting())" fxFlex.xs="40" fxFlex.gt-xs="16.5">
        <ati-labeled-data label="Mentoring" data="{{ payCodeForm.get('glMentoringAccount').value }}">
        </ati-labeled-data>
      </div>
      <div *ngIf="(!editMode && !createMode) || (createMode && index !== 0) || (editMode && !userHasAccounting())" fxFlex.xs="40" fxFlex.gt-xs="16.5">
        <ati-labeled-data label="Override" data="{{ payCodeForm.get('glOverrideAccount').value }}">
        </ati-labeled-data>
      </div>
      <div *ngIf="(!editMode && !createMode) || (createMode && index !== 0) || (editMode && !userHasAccounting())" fxFlex.xs="40" fxFlex.gt-xs="16.5">
        <ati-labeled-data label="Contracts" data="{{ payCodeForm.get('glContractsAccount').value }}">
        </ati-labeled-data>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel fxFlex="100" fxFlex="100" class="mat-elevation-z0">
    <mat-expansion-panel-header>
      Splits
    </mat-expansion-panel-header>
    <mat-table *ngIf="(!editMode && !createMode) || (createMode && index !== 0) || (editMode && !userHasAccounting())" [dataSource]="dataSource" class="mat-elevation-z0">
      <ng-container matColumnDef="brokerName">
        <mat-header-cell *matHeaderCellDef fxFlex.xs="70" fxFlex.gt-xs="50">Name</mat-header-cell>
        <mat-cell *matCellDef="let payCodeSplit" fxFlex.xs="70" fxFlex.gt-xs="50">{{ payCodeSplit.brokerName }}</mat-cell>
        <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="payCodeId">
        <mat-header-cell *matHeaderCellDef fxFlex.xs="70" fxFlex.gt-xs="50">Payroll ID</mat-header-cell>
        <mat-cell *matCellDef="let payCodeSplit" fxFlex.xs="70" fxFlex.gt-xs="50">{{ payCodeSplit.payCodeId }}</mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="investorCommissionPercent">
        <mat-header-cell *matHeaderCellDef fxFlex.xs="70" fxFlex.gt-xs="50">Investor Commission</mat-header-cell>
        <mat-cell *matCellDef="let payCodeSplit" fxFlex.xs="70" fxFlex.gt-xs="50">{{ payCodeSplit.investorCommissionPercent | percent: '1.0-8'}}</mat-cell>
        <mat-footer-cell *matFooterCellDef>{{ commissionSummary.investorCommissionPercent | percent: '1.0-8'}}</mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="investorOverridePercent">
        <mat-header-cell *matHeaderCellDef fxFlex.xs="70" fxFlex.gt-xs="50">Investor Overrides</mat-header-cell>
        <mat-cell *matCellDef="let payCodeSplit" fxFlex.xs="70" fxFlex.gt-xs="50">{{ payCodeSplit.investorOverridePercent | percent: '1.0-8'}}</mat-cell>
        <mat-footer-cell *matFooterCellDef>{{ commissionSummary.investorOverridePercent | percent: '1.0-8'}}</mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="mentoringPercent">
        <mat-header-cell *matHeaderCellDef fxFlex.xs="70" fxFlex.gt-xs="50">Mentoring</mat-header-cell>
        <mat-cell *matCellDef="let payCodeSplit" fxFlex.xs="70" fxFlex.gt-xs="50">{{ payCodeSplit.mentoringPercent | percent: '1.0-8' }}</mat-cell>
        <mat-footer-cell *matFooterCellDef>{{ commissionSummary.mentoringPercent | percent: '1.0-8'}}</mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="apCommissionPercent">
        <mat-header-cell *matHeaderCellDef fxFlex.xs="70" fxFlex.gt-xs="50">AP Commission</mat-header-cell>
        <mat-cell *matCellDef="let payCodeSplit" fxFlex.xs="70" fxFlex.gt-xs="50">{{ payCodeSplit.apCommissionPercent | percent: '1.0-8' }}</mat-cell>
        <mat-footer-cell *matFooterCellDef>{{ commissionSummary.apCommissionPercent | percent: '1.0-8'}}</mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="totalSplit">
        <mat-header-cell *matHeaderCellDef fxFlex.xs="70" fxFlex.gt-xs="50">Total</mat-header-cell>
        <mat-cell *matCellDef="let payCodeSplit" fxFlex.xs="70" fxFlex.gt-xs="50">{{ payCodeSplit.mentoringPercent + payCodeSplit.investorOverridePercent + payCodeSplit.investorCommissionPercent + payCodeSplit.apCommissionPercent | percent: '1.0-8'}}</mat-cell>
        <mat-footer-cell *matFooterCellDef>{{ commissionSummary.total | percent: '1.0-8'}}</mat-footer-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let payCodeSplit; columns: columnsToDisplay"></mat-row>
      <mat-footer-row *matFooterRowDef="columnsToDisplay; sticky: true"></mat-footer-row>
    </mat-table>
    <div *ngIf="((editMode && payCodeForm.value.payCodeSplits) || createMode && index === 0 && payCodeForm.value.payCodeSplits) && userHasAccounting()" fxLayout="row wap" fxFlex="100">
    <atom-pay-code-split fxLayout="row wrap" fxFlex="100" *ngFor="let payCodeSplitForm of payCodeSplitForms.controls; let i = index"
     [index]="i" [payCodeSplitForm]="payCodeSplitForm" [payCodeSplitForms]="payCodeSplitForms" [createMode]="createMode" [editMode]="editMode" [broker]="broker">
    </atom-pay-code-split>
    <div fxLayout="row wrap" fxFlex="100">
      <button id="addPayCodeSplit" aria-label="Add Pay Code Split" type="button" class="add-pay-code-button" color="primary"
        (click)="addPayCodeSplit()" *ngIf="(editMode || (createMode && index === 0)) && userHasAccounting()" matTooltip="Add Pay Code Split" mat-stroked-button>
        Add Split
      </button>
    </div>
    </div>
  </mat-expansion-panel>
  <mat-error *ngIf="!payCodeForm.pristine && payCodeForm.hasError('invalidSplits')" class="splits-error">
    Combined splits cannot exceed 100%</mat-error>
</form>
