<div fxLayout="row wrap" fxLayoutAlign="space-between start" fxLayoutGap="10">
  <!-- Broker Relationships Detail Card -->
  <div class="card-wrapper" fxFlex="100" fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
      <!-- Error Message -->
      <div *ngIf="errorMessage">
        <h2 class="card-title">Error Retrieving Broker Relationship Details</h2>
        <p [innerHTML]="errorMessage"></p>
        <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
      </div>
      <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title" *ngIf="!createMode && brokerRel$ | async as brokerRel">Broker Relationship: {{brokerRel.brokerCode}}
        <ng-container *ngIf="brokerRelForm.get('isSecondary').value">{{ brokerRel.secondaryLabel }} </ng-container>
        <ng-container *ngIf="brokerRel.extendedCode">({{ brokerRel.extendedCode }})</ng-container>
      </h2>
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
      <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
      <button aria-label="Edit Broker Relationship" *ngIf="!editMode && brokerRelEditor && brokerRelForm.get('status').value !== 'CLOSED'"  (click)="setEditMode(true)" mat-mini-fab color="accent" matTooltip="Edit Broker Relationship">
        <mat-icon>edit</mat-icon>
      </button>
      <button aria-label="Update Broker Relationship" *ngIf="!createMode && editMode && updateComplete" [disabled]="brokerRelForm.invalid || brokerRelForm.pristine"  (click)="saveForm()" mat-mini-fab color="accent" id="broker-relationship-detail-save" matTooltip="Update Broker Relationship">
        <mat-icon>done</mat-icon>
      </button>
      <button aria-label="Discard Changes" *ngIf="updateComplete && editMode && !createMode" (click)="cancelChanges()" mat-mini-fab color="" id="broker-relationship-detail-clear" matTooltip="Cancel Changes">
        <mat-icon>clear</mat-icon>
      </button>
      </div>
      </div>
      <form *ngIf="!errorMessage" [formGroup]="brokerRelForm" (ngSubmit)="saveForm()" fxLayout="column">
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <ati-labeled-data fxFlex.xs="100" fxFlex.gt-xs="25" *ngIf="!editMode"  label="Broker Code" data="{{brokerRelForm.get('brokerCode').value}} {{brokerRelForm.get('secondaryLabel').value}} {{brokerRelForm.get('extendedCode').value }}"></ati-labeled-data>
          <ati-labeled-data *ngIf="!editMode" fxFlex.xs="100" fxFlex.gt-xs="25" label="FCM" data="{{ brokerRelForm.get('fcm').value }}"></ati-labeled-data>
          <ati-labeled-data *ngIf="!editMode" fxFlex.xs="100" fxFlex.gt-xs="25" label="status" data="{{ brokerRelForm.get('status').value }}"></ati-labeled-data>
          <div *ngIf="!editMode" class="data" fxLayout="column" fxFlex.xs="100" fxFlex.gt-xs="25">
            <span class="mat-small mat-hint">Accounts</span>
            <span>
              <mat-icon class="icon-button" (click)="selectAccountsIcon(brokerRel.brokerCode)" mat-icon-button matTooltip="Broker Relationship Accounts" color="primary">folder</mat-icon>
            </span>
          </div>
          <ati-labeled-data *ngIf="!editMode" fxFlex.xs="100" fxFlex.gt-xs="20" label="Managing Pod" data="{{ brokerRelForm.get('managingPod').value | replace | titlecase }}"></ati-labeled-data>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
        </div>
        <!-- Progress Indicators for PENDING status Broker Relationship -->
        <div *ngIf="brokerRelForm.get('status').value === 'PENDING'" class="checkbox-group" fxLayout.gt-xs="row wrap" fxLayout.xs="column" fxLayoutGap="5">
          <span class="check-radio-label">Setup Progress</span>
          <div fxLayout.xs="column" fxLayoutAlign="start space-between" fxFlex="100">
            <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="FCM Approved"
              data="{{ brokerRelForm.get('isFcmApproved').value | yesNo }}">
            </ati-labeled-data>
            <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="Platforms Setup"
              data="{{ brokerRelForm.get('isPlatformsSetupComplete').value | yesNo }}">
            </ati-labeled-data>
            <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="Error & Accommodation Accounts"
              data="{{ brokerRelForm.get('hasErrorAndAccommodationAccounts').value | yesNo }}">
            </ati-labeled-data>
            <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="Error Account Limits"
              data="{{ brokerRelForm.get('hasErrorAccountLimits').value | yesNo }}">
            </ati-labeled-data>
          </div>
          <mat-checkbox *ngIf="editMode" formControlName="isFcmApproved">FCM Approved</mat-checkbox>
          <mat-checkbox *ngIf="editMode" formControlName="isPlatformsSetupComplete">Platforms Setup</mat-checkbox>
          <mat-checkbox *ngIf="editMode" formControlName="hasErrorAndAccommodationAccounts">Error & Accommodation Accounts</mat-checkbox>
          <mat-checkbox *ngIf="editMode" formControlName="hasErrorAccountLimits">Error Account Limits</mat-checkbox>
        </div>
        <!-- Edit Extended Code for RCG -->
        <div *ngIf="editMode && brokerRelForm.get('fcm').value === 'RCG' && brokerRelForm.get('status').value === 'PENDING'" fxLayout="row wrap">
          <mat-form-field fxFlex.xs="45" fxFlex.gt-xs="20">
            <input matInput type="text" placeholder="Extended Code" formControlName="extendedCode">
            <mat-error *ngIf="brokerRelForm.get('extendedCode').invalid">Must be 5 characters</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="5">
        <!-- Edit Broker Relationship Status -->
          <div *ngIf="editMode && (brokerRelForm.get('status').value === 'ACTIVE' || brokerRelForm.get('status').value === 'INACTIVE')" fxFlex.xs="100" fxFlex.gt-xs="30">
            <mat-radio-group formControlName="status" required>
              <span class="check-radio-label">Broker Relationship Status</span>
              <mat-radio-button value="ACTIVE">Active</mat-radio-button>
              <mat-radio-button value="INACTIVE">Inactive</mat-radio-button>
              <mat-radio-button value="CLOSED">Closed</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="20">
            <mat-select [disableOptionCentering]="true" placeholder="Managing Pod" formControlName="managingPod" required>
              <mat-option *ngFor="let pod of podNames" [value]="pod">{{ pod | replace | titlecase }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Display Broker Names and Splits for Existing Broker Relationships -->
        <div *ngIf="!editMode && brokers?.length">
          <h4>Brokers</h4>
          <div *ngFor="let broker of brokers; let i = index" fxLayout="row">
            <div *ngIf="canUserViewSplits()" fxFlex.xs="15" fxFlex.gt-xs="10">
              <p class="list-data">{{ displaySplit(broker.split) }}%</p>
            </div>
            <div>
            <p (click) = navigateBroker(broker.broker) class="link-list-data">{{ broker.broker.firstName }} {{ broker.broker.lastName}} ({{ broker.broker.brokerType | replace | titlecase | replace: ' ' : '-' }})</p>
            </div>
          <div>
            <div class="list-data" *ngIf="isLeadBroker(broker.broker.docId)"> (Lead Broker) </div>
          </div>
        </div>
        </div>
        <div *ngIf="editMode && updateComplete">
        <!-- Add broker Button -->
        <div fxLayout="row" fxLayoutAlign="start center">
          <span class="section-label">Brokers</span>
          <button *ngIf="brokerForms.controls.length < 10" aria-label="Add Broker" type="button" mat-icon-button color="primary" (click)="addBroker()" matTooltip="Add Broker">
            <mat-icon class="add-broker-icon">add_circle</mat-icon>
          </button>
        </div>
        <!-- Equal Splits Checkbox -->
          <div fxLayout="row wrap" *ngIf="brokerForms.controls.length > 1" >
            <mat-checkbox class="splits-checkbox" aria-label="Assign Equal Splits" formControlName="equalSplits" [checked]="checked" (change)="onEqualSplitsChange($event)">Equal Splits</mat-checkbox>
          </div>
          <!-- Brokers and Splits -->
          <div formArrayName="brokers" *ngFor="let broker of brokerForms.controls; let i = index;">
            <div [formGroupName]="i" fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
              <!-- Broker Name -->
              <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="50">
                <input matInput type="text" placeholder="Select broker by name" formControlName="brokerUser" [matAutocomplete]="autoBroker" (blur)="refreshAvailableBrokers()" required>
                <mat-error *ngIf="brokerForms.at(i).get('brokerUser').invalid">You must select a valid broker</mat-error>
                <button *ngIf="brokerForms.at(i).get('brokerUser').value" matSuffix mat-icon-button aria-label="Clear" (click)="brokerForms.at(i).get('brokerUser').setValue('')">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete #autoBroker="matAutocomplete" [displayWith]="displayBroker">
                  <mat-option *ngFor="let broker of filteredBrokers[i] | async" [value]="broker">
                    {{ broker.firstName }} {{ broker.lastName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <!-- Split Input -->
              <mat-form-field fxFlex.xs="40" fxFlex.gt-xs="20">
                <input matInput type="text" placeholder="Split Percentage" formControlName="split" required>
                <mat-error *ngIf="brokerForms.at(i).get('split').invalid">
                  {{ getErrorMessage(brokerForms.at(i).get('split')) }}</mat-error>
                </mat-form-field>
              <div>
                <mat-checkbox fxFlex.xs="25" fxFlex.gt-xs="10" *ngIf="brokerForms.controls.length > 1" class="leadBroker" formControlName="leadBroker">
                  Lead Broker
                </mat-checkbox>
              </div>
              <!-- Remove Broker Icon -->
              <div>
                <button fxFlex.xs="25" fxFlex.gt-xs="10" class="clear-broker-button" *ngIf="brokerForms.controls.length > 0" mat-icon-button
                  aria-label="Remove Broker" (click)="removeBroker(i)" color="" matTooltip="Remove Broker">
                  <mat-icon>clear</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <mat-error *ngIf="!brokerForms.pristine && brokerRelForm.hasError('invalidSplits')" class="splits-error">
            Combined splits must equal 100%</mat-error>
          <mat-error *ngIf="!brokerForms.pristine && brokerForms.hasError('invalidLeadBroker')" class="splits-error">
            There must be 1 lead broker</mat-error>
          <mat-error *ngIf="!brokerForms.pristine && brokerRelForm.hasError('invalidEqualSplits')" class="splits-error">
            Equal splits selected with non-equal splits </mat-error>
          <mat-error *ngIf="!brokerRelForm.pristine && brokerRelForm.hasError('invalidBrokerPod')" class="splits-error">
            Add broker or set managing pod to House </mat-error>
        </div>
      </form>
    </mat-card>
  </div>
</div>
