import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { of, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ObservableDataSource, StorageService } from '@advance-trading/angular-common-services';
import { SalesCodeService } from '@advance-trading/angular-ops-data';
import { SalesCode } from '@advance-trading/ops-data-lib';
import { ExportService } from 'src/app/services/export.service';

const ACCOUNT_ADMIN_ROLE = 'AccountAdmin';
const PAGE_SIZE_KEY = 'atom.saleCodesPageSize';

@Component({
  selector: 'atom-sales-codes',
  templateUrl: './sales-codes.component.html',
  styleUrls: ['./sales-codes.component.css']
})
export class SalesCodesComponent implements AfterViewInit, OnInit, OnDestroy {

  columnsToDisplay = ['salesCode', 'isAssigned', 'assignmentDate'];
  errorMessage: string;
  dataSource = new ObservableDataSource<SalesCode>();
  filterValue = new FormControl();
  exportable = false;

  private queryParams: Params;
  private unsubscribe$: Subject<void> = new Subject<void>();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private salesCodesService: SalesCodeService,
    private authzService: Auth0AuthzService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    public exportService: ExportService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.dataSource.filterPredicate = (data, filter) => {
      const dataStr = data.salesCode + data.isAssigned;
      return dataStr.indexOf(filter) !== -1;
    };

    if (!this.authzService.currentUserHasRole(ACCOUNT_ADMIN_ROLE)) {
      this.errorMessage = 'You do not have permission to view this information.';
    } else {
      this.dataSource.data$ = this.salesCodesService.getAllSalesCodes()
        .pipe(
          tap(salesCodes => {
            this.setTableState();
            this.exportable = salesCodes.length > 0;
          }),
          catchError(err => {
            this.errorMessage = 'Error retrieving sales codes; please try again later';
            console.error(`Error retrieving sales codes: ${err}`);
            return of([]);
          })
        );
    }

    this.filterValue.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((filter: string) => {
      if (filter) {
        this.queryParams.filter = filter;
      } else if (this.queryParams.filter) {
        delete this.queryParams.filter;
      }

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        replaceUrl: true,
        queryParams: this.queryParams
      });
    });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
      this.queryParams = Object.assign({}, params);
    }));

    const filterVal = this.queryParams.filter;
    if (filterVal) {
      this.filterValue.setValue(filterVal);
      this.applyFilter(this.filterValue.value);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = this.storageService.localStorage.get(PAGE_SIZE_KEY).value() || 10;
    this.dataSource.sort = this.sort;
    this.filter.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase();
    if (filterValue === 'y' || filterValue === 'ye' || filterValue === 'yes') {
      filterValue = 'true';
    }
    if (filterValue === 'n' || filterValue === 'no') {
      filterValue = 'false';
    }
    this.dataSource.filter = filterValue;
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  selectSalesCode(salesCode: SalesCode) {
    if (salesCode.isAssigned) {
      this.router.navigate(['/salescodes', salesCode.docId]);
    }
  }

  handlePageChange() {
    this.storageService.localStorage.set(PAGE_SIZE_KEY, this.paginator.pageSize);
    this.queryParams.pageIndex = this.paginator.pageIndex;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  handleSortChange() {
    this.queryParams.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.queryParams.sortColName = this.queryParams.sortDir ? this.sort.active : undefined;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  private setTableState() {
    if (this.filter) {
      const pageIndex = this.queryParams.pageIndex as number;
      if (pageIndex) {
        this.dataSource.paginator.pageIndex = pageIndex;
      }

      const sortDir = this.queryParams.sortDir as SortDirection;
      const sortColName = this.queryParams.sortColName as string;
      if (sortDir && sortColName) {
        this.dataSource.sort.direction = sortDir;
        this.dataSource.sort.active = sortColName;
      }
    }
  }
}
