<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Broker Details</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title" *ngIf="user">Broker: {{ user.firstName }} {{ user.lastName }}</h2>
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="16.5px">
        <button id="edit-broker" aria-label="Edit Broker" *ngIf="!editMode && !createMode && updateComplete && canUserEditBroker()"
          (click)="setEditMode(true)" mat-mini-fab color="accent" matTooltip="Edit Broker">
          <mat-icon>edit</mat-icon>
        </button>
        <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
        <button id="update-broker" aria-label="Update Broker" *ngIf="(editMode && updateComplete) || createMode"
          [disabled]="!brokerForm.valid || brokerForm.pristine" (click)="submit()" mat-mini-fab color="accent"
          matTooltip="Update Broker">
          <mat-icon>done</mat-icon>
        </button>
        <button id="cancel-edit-broker" aria-label="Discard Changes" *ngIf="(editMode && updateComplete) || createMode"
          (click)="reset()" mat-mini-fab color="" matTooltip="Discard Changes">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <form *ngIf="!errorMessage" [formGroup]="brokerForm" (ngSubmit)="submit()" fxLayout="column">
      <mat-tab-group>
        <mat-tab label="Info">
          <div fxLayout="row wrap" fxFlex="100">
            <ati-labeled-data fxFlex="100" *ngIf="!editMode" label="Broker Type"
              data="{{ brokerForm.get('type').value | titlecase }}">
            </ati-labeled-data>
            <mat-radio-group fxFlex="100" *ngIf="editMode" formControlName="type" required>
              <span class="check-radio-label">Broker Type</span>
              <mat-radio-button *ngFor="let type of brokerTypes" [value]="type">
                {{ type | replace | titlecase | replace: ' ' : '-' }}</mat-radio-button>
            </mat-radio-group>
            <ati-labeled-data fxFlex="100" *ngIf="!editMode" label="Broker Status"
              data="{{ brokerForm.get('status').value | titlecase }}"></ati-labeled-data>
            <mat-radio-group fxFlex="100" *ngIf="editMode" formControlName="status" required>
              <span class="check-radio-label">Broker Status</span>
              <mat-radio-button value="ACTIVE">Active</mat-radio-button>
              <mat-radio-button value="INACTIVE">Inactive</mat-radio-button>
            </mat-radio-group>
            <!-- Status Dates -->
            <div class="status-dates" fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="100" fxLayoutGap="10">
              <ati-labeled-data *ngIf="broker?.activationDate" label="ActivationDate"
                data="{{ broker.activationDate | date }}" fxFlex.xs="45" fxFlex.gt-xs="30"></ati-labeled-data>
              <ati-labeled-data *ngIf="broker?.inactivationDate && broker.status !== 'ACTIVE'" label="Inactivation Date"
                data="{{ broker.inactivationDate | date }}" fxFlex.xs="45" fxFlex.gt-xs="30"></ati-labeled-data>
            </div>
            <ati-labeled-data *ngIf="brokerForm.get('type').value === 'NON_EMPLOYEE' && !editMode"
              label="Client Relationship" data="{{ displayClient(brokerForm.get('client').value) }}"></ati-labeled-data>
            <mat-form-field *ngIf="brokerForm.get('type').value === 'NON_EMPLOYEE' && editMode">
              <input matInput placeholder="Client Relationship" formControlName="client" [matAutocomplete]="autoClient">
              <mat-error *ngIf="brokerForm.get('client').invalid">You must select a valid client</mat-error>
              <button mat-button *ngIf="brokerForm.get('client').value && editMode && brokerForm.get('client').enabled"
                matSuffix mat-icon-button aria-label="Clear" (click)="removeClient()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-autocomplete #autoClient="matAutocomplete" [displayWith]="displayClient">
              <mat-option *ngFor="let client of filteredClients$ | async" [value]="client">{{ displayClient(client) }}
              </mat-option>
            </mat-autocomplete>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="99" fxLayoutGap="5">
              <mat-form-field *ngIf="canShowMentors()" fxFlex="100">
                <mat-chip-list [disabled]="!editMode" #mentorList>
                  <mat-chip *ngFor="let selectedMentor of selectedMentors" [disabled]="!editMode"
                    [selectable]="selectable" [removable]="removable" (removed)="removeMentor(selectedMentor)">
                    {{ selectedMentor.lastName }}, {{ selectedMentor.firstName }}
                    <mat-icon matChipRemove *ngIf="removable && editMode">cancel</mat-icon>
                  </mat-chip>
                  <input #mentorsInput placeholder="Assigned Mentors" formControlName="mentors"
                    [matAutocomplete]="autoMentor" [matChipInputFor]="mentorList"
                    [matChipInputSeparatorKeyCodes]="separatorKeyCodes">
                </mat-chip-list>
                <mat-autocomplete #autoMentor="matAutocomplete" [displayWith]="displayMentor"
                  (optionSelected)="addMentor()">
                  <mat-option *ngFor="let mentor of filteredMentors$ | async" [value]="mentor">
                    {{ mentor.lastName }}, {{ mentor.firstName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100"
              *ngIf="!editMode && userHasBrokerCodeViewerRole()">
              <h4>Broker Relationships</h4>
              <button type="button" id="brokerRelationships" aria-label="View Broker Relatioships"
                (click)="navigateBrokerRelationships()" mat-icon-button matTooltip="View Broker Relationships">
                <mat-icon color="primary" mat-list-icon>supervised_user_circle</mat-icon>
              </button>
            </div>
            <mat-expansion-panel [expanded]="editMode" class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-panel-title>Address</mat-panel-title>
              </mat-expansion-panel-header>
              <ati-address [addressForm]="brokerForm.get('address')"></ati-address>
            </mat-expansion-panel>
          </div>
        </mat-tab>
        <mat-tab *ngIf="userHasAccounting() || (this.broker && userIsBroker())" label="Pay Codes">
          <div fxLayout="row wrap" fxFlex="100" *ngIf="broker">
            <div fxLayout="row wrap" fxFlex="100">
              <div fxFlex="100">
                <button class="add-pay-code" id="addPayCode" aria-label="Add Pay Code" type="button" color="primary"
                  (click)="addPayCode()" *ngIf="!editMode && !createMode && userHasAccounting()" matTooltip="Add Pay Code" mat-raised-button>
                  Add Pay Code
                </button>
              </div>
              <atom-pay-code-detail fxLayout="row wrap" fxFlex="100" *ngFor="let payCodeForm of payCodeForms.controls; let i = index" [index]="i" [broker]="broker" [payCodeForms]="payCodeForms" [payCodeForm]="payCodeForm" [createMode]="createMode" [editMode]="editMode"></atom-pay-code-detail>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </mat-card>
</div>
