import { Injectable, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable, combineLatest } from 'rxjs';

import { Client, Status } from '@advance-trading/ops-data-lib';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '@advance-trading/angular-ati-security';
import { switchMap, shareReplay, map } from 'rxjs/operators';
import { UserService } from '@advance-trading/angular-ops-data';

const MAXIMUM_ARRAY_SIZE = 10;

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    @Inject('environment') private environment,
    private db: AngularFirestore,
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService
  ) {}

  /**
   * Retrieves a single Client document by Client docId
   */
  getClient(docId: string): Observable<Client> {
    return this.db.collection(Client.getDataPath()).doc<Client>(docId).valueChanges();
  }

  /**
   * Retrieves clients by status
   */
  getClientsBySearchParameters(searchParameters): Observable<Client[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AllClientViewer')) {
      return this.db.collection<Client>(Client.getDataPath(), ref => {
        let finalRef = ref as firebase.default.firestore.Query<firebase.default.firestore.DocumentData>;

        if (searchParameters.clientStatus) {
          finalRef = finalRef.where('status', '==', searchParameters.clientStatus);
        }
        if (searchParameters.clientPod) {
          finalRef = finalRef.where('managingPod', '==', searchParameters.clientPod);
        }
        return finalRef;

      }).valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.clients.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.clients.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getClientsByUserClientsAndSearchParameters(
                searchParameters,
                user.clients.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Client arrays into single array
              map(arrayOfClientArrays => (arrayOfClientArrays as Client[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getClientsByUserClientsAndSearchParameters(
              searchParameters,
              user.clients)
            .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getClientsByUserClientsAndSearchParameters(searchParameters, clients: string[]): Observable<Client[]> {
    return this.db.collection<Client>(Client.getDataPath(), ref => {
      let finalRef = ref as firebase.default.firestore.Query<firebase.default.firestore.DocumentData>;

      if (searchParameters.clientStatus) {
        finalRef = finalRef.where('status', '==', searchParameters.clientStatus);
      }
      if (searchParameters.clientPod) {
        finalRef = finalRef.where('managingPod', '==', searchParameters.clientPod);
      }
      finalRef = finalRef.where('docId', 'in', clients);
      return finalRef;
    })
    .valueChanges();
  }

  /**
   * Retrieves all clients
   */
  getAllClients(): Observable<Client[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AllClientViewer')) {
      return this.db.collection<Client>(Client.getDataPath()).valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.clients.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.clients.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getAllClientsByUserClients(
                user.clients.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Client arrays into single array
              map(arrayOfClientArrays => (arrayOfClientArrays as Client[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getAllClientsByUserClients(user.clients)
            .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getAllClientsByUserClients(clients: string[]): Observable<Client[]> {
    return this.db.collection<Client>(Client.getDataPath(), ref => ref
    .where('docId', 'in', clients)).valueChanges();
  }

  /**
   * Retrieves clients by status
   */
  getClientsByStatus(status: Status): Observable<Client[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AllClientViewer')) {
      return this.db.collection<Client>(Client.getDataPath()).valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.clients.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.clients.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getClientsByUserClientsAndStatus(
                status,
                user.clients.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Client arrays into single array
              map(arrayOfClientArrays => (arrayOfClientArrays as Client[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getClientsByUserClientsAndStatus(
              status,
              user.clients)
            .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getClientsByUserClientsAndStatus(status: Status, clients: string[]): Observable<Client[]> {
    return this.db.collection<Client>(Client.getDataPath(), ref => ref
    .where('status', '==', status)
    .where('docId', 'in', clients))
    .valueChanges();
  }

  /**
   * Creates a new client
   * @param client The client to create
   */
  createClient(client: Client): Promise<void> {
    client.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.db.collection(Client.getDataPath()).doc<Client>(client.docId).set(client.getPlainObject());
  }

  /**
   * Update an existing client
   * @param client The updated client
   */
  updateClient(client: Client): Promise<void> {
    client.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.db.collection(Client.getDataPath()).doc<Client>(client.docId).update(client);
  }

  /**
   * Encrypts and stores sensitive client data
   * @param clientDocId The doc id of the client
   * @param fieldName The fieldname to update
   * @param plaintext The value to encrypt and store
   */
  encrypt(clientDocId: string, fieldName: string, plaintext: string): Promise<string> {
    const endpoint = `${this.environment.services.httpClientSecureData}/${clientDocId}/${fieldName}`;

    return this.authService.stepUp().pipe(
      switchMap(accessToken => {
        // Call cloud function to encrypt using elevated access token
        return this.http.post<string>(endpoint, {plaintext}, {
          headers: new HttpHeaders({Authorization: `Bearer ${accessToken}`})
        });
      })
    ).toPromise();
  }

  /**
   * Retrieves the decrypted value for the specified client
   * @param clientDocId The doc id of the client
   * @param fieldName The field name to retrieve
   */
  decrypt(clientDocId: string, fieldName: string): Observable<{value: string}> {
    const endpoint = `${this.environment.services.httpClientSecureData}/${clientDocId}/${fieldName}`;

    return this.authService.stepUp().pipe(
      switchMap(accessToken => {
        return this.http.get<{value: string}>(endpoint, {
          headers: new HttpHeaders().set('Authorization', `Bearer ${accessToken}`),
        });
      })
    );
  }

}
