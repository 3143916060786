<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Broker Request List</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">Broker Requests</h2>
      <button id="add-new-broker-request" *ngIf="isBrokerRequester" aria-label="Request New Broker" (click)="addNewBroker()" mat-mini-fab color="accent" matTooltip="Request New Broker">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div *ngIf="!errorMessage" fxLayout="column">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" #filter>
        <button mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <button *ngIf="exportable" aria-label="Export" id="export-broker-requests" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
      <mat-menu #exportMenu="matMenu">
        <button mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Broker Requests'))">Excel</button>
        <button mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Broker Requests'))">CSV</button>
        <button mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Broker Requests'))">JSON</button>
      </mat-menu>

      <mat-table matTableExporter #exporter="matTableExporter" [hiddenColumns]="[3]" [dataSource]="dataSource" matSort class="mat-elevation-z0" (matSortChange)="handleSortChange()">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="75" fxFlex.gt-xs="35">Name</mat-header-cell>
          <mat-cell *matCellDef="let brokerRequest" fxFlex.xs="75" fxFlex.gt-xs="35">{{ brokerRequest.firstName }} {{ brokerRequest.lastName }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="20">Type</mat-header-cell>
          <mat-cell *matCellDef="let brokerRequest" fxFlex.gt-xs="20">{{ brokerRequest.type | replace | titlecase | replace: ' ' : '-' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.gt-xs="20">Status</mat-header-cell>
          <mat-cell *matCellDef="let brokerRequest" fxFlex.xs="25" fxFlex.gt-xs="20">{{ brokerRequest.status | titlecase }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="badges">
          <mat-header-cell *matHeaderCellDef fxFlex.gt-xs="25">Approvals</mat-header-cell>
          <mat-cell *matCellDef="let brokerRequest" fxFlex.gt-xs="25">
            <span class="badge-container" *ngIf="brokerRequest.status === 'PENDING'">
              <mat-icon
                mat-list-icon
                *ngFor="let badge of brokerRequest.badges"
                [ngClass]="['status-icon', badge.isApproved ? 'active' : 'inactive']"
                [matTooltip]="badge.description"
              >check_circle</mat-icon>
            </span>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row (click)="selectBrokerRequest(brokerRequest)" *matRowDef="let brokerRequest; columns: columnsToDisplay"></mat-row>
      </mat-table>
      <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50, 100]" (page)="handlePageChange()"></mat-paginator>
    </div>
  </mat-card>
</div>

