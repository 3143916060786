import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AccountPurpose, FCM } from '@advance-trading/ops-data-lib';

export class AccountNumberErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control.touched && control.invalid) || (form.enabled && form.hasError('invalidNumber'));
  }
}

export class AccountValidators {

  /**
   * Validates the account number value for an account.
   * Ensures the value matches the pattern expected for the account purpose, give up indicator, and FCM.
   * The value will be valid until a number is entered.
   */
  static accountNumberValidator(formGroup: FormGroup): {[key: string]: boolean} | null {
    const accountNumber = formGroup.value.number;
    const giveUp = formGroup.value.isGiveUpAcct;
    const purpose = formGroup.value.purpose;
    const fcm = formGroup.value.brokerRel ? formGroup.value.brokerRel.fcm : '';
    const isOtc = formGroup.value.isOtcAcct;

    const giveUpRegex = /^(G[A-Z0-9]{4}|[A-Z]{5})$/;
    const controllerRegex = /^CT[0-9]{3}$/;
    const rjoMarginGroupRegex = /^M[0-9]{4}$/;
    const marginGroupRegex = /^R[0-9]{4}$/;
    const otcAccountRegex = /^[A-Z0-9](([_-](?![_-]))?[A-Z0-9]){3,13}[A-Z0-9]?$/;

    if (!accountNumber) {
      return null;
    }
    // Need to move the length thing up here but have to make sure it can't be longer than 5... Maybe
    if (giveUp) {
      return giveUpRegex.test(accountNumber) ? null : { invalidNumber: true, invalidGiveUp: true };
    } else if (purpose === AccountPurpose.CONTROLLER) {
      return controllerRegex.test(accountNumber) ? null : { invalidNumber: true, invalidController: true };
    } else if (purpose === AccountPurpose.MARGIN_GROUP) {
      if (fcm === FCM.RJO) {
        return rjoMarginGroupRegex.test(accountNumber) ? null : { invalidNumber: true, invalidRJOMarginGroup: true };
      }
      return marginGroupRegex.test(accountNumber) ? null : { invalidNumber: true, invalidMarginGroup: true };
    } else if (purpose === AccountPurpose.OTHER && isOtc) {
      return otcAccountRegex.test(accountNumber) ? null : { invalidNumber: true, invalidOtcNumber: true };
    } else {
      return accountNumber.length === 5 ? null : { invalidNumber: true, length: true };
    }
  }

  static leadBrokerValidator(formGroup: FormGroup) {
    const brokerRel = formGroup.value.brokerRel;
    const leadBroker = formGroup.value.leadBroker;
    if (brokerRel && brokerRel.brokers && brokerRel.brokers.length === 0) {
      return null;
    } else {
      return leadBroker ? null : formGroup.get('leadBroker').setErrors({invalidLeadBroker: true});
    }
  }
}
