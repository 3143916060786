import { FormControl } from '@angular/forms';

export class SplitCharacterValidators {

  static splitCharacterValidator(formControl: FormControl): { [key: string]: boolean } | null {
    const splitNumber = formControl.value;
    const splitRegex = /^((\d+(\.\d*)?)|(\.\d+))$/;

    if (!splitNumber) {
      return null;
    }

    if ( splitNumber.length) {
      return splitRegex.test(splitNumber) ? null : { invalidCharacter: true };
    }

  }
}
