<div *ngIf="authorizedClients.length === 0 && (!editMode || !userHasUserAdmin)">No Authorized Clients</div>
<div class="chips" fxLayout="row wrap">
  <mat-form-field [formGroup]="hmsSettingsForm" *ngIf="authorizedClients.length > 0 || (editMode && userHasUserAdmin)" fxFlex.gt-xs="100">
    <ng-container *ngIf="clients$ | async"></ng-container>
    <mat-chip-list [disabled]="!editMode || !userHasUserAdmin" #clientChips>
      <mat-chip *ngFor="let client of authorizedClients; index as index;"
        [color]="(index + 1) % 2 === 0 ? 'accent' : 'primary'"
        [disabled]="!editMode || !userHasUserAdmin"
        selected
        [removable]="clientRemovable(client)"
        (removed)="removeClient(client)">
        {{ displayClient(client) }}
        <mat-icon matChipRemove *ngIf="clientRemovable(client) && editMode">cancel</mat-icon>
      </mat-chip>
      <input
        #clientInput
        matInput
        placeholder="HMS Authorized Clients"
        formControlName="authorizedClients"
        [matAutocomplete]="clientauto"
        [matChipInputFor]="clientChips"
        [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="addClient($event)">
    </mat-chip-list>
    <mat-autocomplete autoActiveFirstOption #clientauto="matAutocomplete" [displayWith]="displayClient" (optionSelected)="addClient()">
      <mat-option *ngFor="let client of filteredClients$ | async" [value]="client">{{ displayClient(client) }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
