import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { CommodityMap, CashActivity } from '@advance-trading/ops-data-lib';
import { Observable, of, Subject } from 'rxjs';
import { catchError, tap, switchMap, map, takeUntil } from 'rxjs/operators';
import { ObservableDataSource, StorageService } from '@advance-trading/angular-common-services';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OperationsDataService } from '@advance-trading/angular-ops-data';
import { ExportService } from '../../services/export.service';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

const maxRows = 20000;
const PAGE_SIZE_KEY = 'atom.cashActivityPageSize';

@Component({
  selector: 'atom-cash-activity',
  templateUrl: './cash-activity.component.html',
  styleUrls: ['./cash-activity.component.css']
})
export class CashActivityComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {

  @Input() initialTableState: { [key: string]: string | number };
  @Input() selectedCashActivity$: Observable<CashActivity[]>;

  columnsToDisplay = [];
  dataSource = new ObservableDataSource<CashActivity>();
  errorMessage: string;
  commodityMap: CommodityMap;
  exportable = false;
  filterValue = new FormControl();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  @Output() cashActivityListChange: EventEmitter<any> = new EventEmitter();
  @Output() cashActivityearchError: EventEmitter<string> = new EventEmitter();
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();

  private tableState: { [key: string]: string | number } = {};
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private operationsDataService: OperationsDataService,
    private snackBar: MatSnackBar,
    public exportService: ExportService,
    private router: Router,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.isSearching.emit(true);

    this.filterValue.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((filter: string) => {
      if (filter) {
        this.tableState.filterValue = filter.trim();
        this.cashActivityListChange.emit(this.tableState);
      } else if (this.tableState.filterValue) {
        delete this.tableState.filterValue;
        this.cashActivityListChange.emit(this.tableState);
      }
    });
    this.breakpointObserver.observe(
      [
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
      ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.breakpoints[Breakpoints.XSmall]) {
          this.columnsToDisplay = [
            'businessDate', 'accountNumber', 'amount', 'securityDescription',
          ];
        } else {
          this.columnsToDisplay = [
            'businessDate', 'brokerCode', 'accountNumber', 'clientName', 'amount', 'securityDescription'
          ];
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = this.storageService.localStorage.get(PAGE_SIZE_KEY).value() || 10;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (cashActivity, column) => {
      switch(column) {
        case 'brokerCode':
          return cashActivity.officeCode + cashActivity.salesCode;
        default:
          return cashActivity[column];
      }
    };
    if (this.filter) {
      this.filter.nativeElement.focus();
    }
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialTableState'] && changes['selectedCashActivity$']) {
      this.tableState = Object.assign({}, this.initialTableState);

      // detect MatSort and MatPaginator so it is defined
      this.changeDetector.detectChanges();

      const sortDir = this.initialTableState.sortDir as SortDirection;
      const sortColName = this.initialTableState.sortColName as string;
      if (sortDir && sortColName) {
        this.sort.direction = sortDir;
        this.sort.active = sortColName;
      }
      if (this.initialTableState.filterValue) {
        this.filterValue.setValue(this.initialTableState.filterValue);
        this.applyFilter(this.filterValue.value);
      }

      // initialize table
      this.dataSource.data$ = this.operationsDataService.getCommodityMap().pipe(
        switchMap((doc: CommodityMap) => {
          this.commodityMap = doc;
          return this.selectedCashActivity$;
        })
      ).pipe(
        catchError(err => {
          console.log(err);
          this.isSearching.emit(false);
          return of([]);
        }),
        tap((cashActivity => {
          this.exportable = !!cashActivity.length;
          this.isSearching.emit(false);

          // initialize pagination state when the datasource exist
          const pageIndex = this.initialTableState.pageIndex as number;
          if (pageIndex) {
            this.paginator.pageIndex = pageIndex;
          }

          if (cashActivity.length === 0) {
            this.openSnackBar('There is no cash activity that matches this search', 'DISMISS', true);
          } else if (cashActivity.length === maxRows) {
            this.openSnackBar(`Reached maximum number of rows (${maxRows.toLocaleString()})`, 'DISMISS', true);
          } else {
            catchError(err => {
              this.errorMessage = 'Error retrieving cash activity; please try again later';
              this.cashActivityearchError.emit(this.errorMessage);
              this.isSearching.emit(false);
              console.error(`Error retrieving cash activity: ${err}`);
              return of([]);
            });
          }
        }))
      );
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  getCommodityDisplayName(symbol: string) {
    if (!symbol) {
      return '';
    }
    const commodity = Object.values(this.commodityMap.commodities).find(cmd => cmd.id === symbol);
    if (commodity) {
      return commodity.name;
    } else {
      return '';
    }
  }

  handleSortChange() {
    this.tableState.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.tableState.sortColName = this.tableState.sortDir ? this.sort.active : undefined;
    this.cashActivityListChange.emit(this.tableState);
  }

  handlePageChange() {
    this.storageService.localStorage.set(PAGE_SIZE_KEY, this.paginator.pageSize);
    this.tableState.pageIndex = this.paginator.pageIndex;
    this.cashActivityListChange.emit(this.tableState);
  }
  selectCashActivity(cashActivity: CashActivity) {
    this.router.navigate(['/accounts', cashActivity.accountDocId, 'cashactivity', cashActivity.docId]);
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }
}
