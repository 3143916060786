import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class RateCodeErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control.touched && control.invalid) || (form.enabled && form.hasError('invalidRateCode'));
  }
}

export class CommissionScheduleValidators {

  /**
   * Validates the rate code value for a commission schedule.
   * Ensures the value matches the pattern expected for fixed rate and sliding scale commission schedules.
   * The value will be valid until a rate code is entered.
   */
  static rateCodeValidator(formGroup: FormGroup): {[key: string]: boolean} | null {
    const rateCode = formGroup.value.rateCode;
    const isSlidingScale = formGroup.value.isSlidingScale;

    const fixedRateRegex = /^[A-Z][A-Z0-9]{1}$/;
    const slidingScaleRegex = /^SS-[A-Z][A-Z0-9]{1}$/;

    if (!rateCode) {
      return null;
    }
    if (isSlidingScale) {
      return slidingScaleRegex.test(rateCode) ? null : { invalidRateCode: true };
    } else {
      return fixedRateRegex.test(rateCode) ? null : { invalidRateCode: true };
    }
  }
}
