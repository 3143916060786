import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Auth0AuthzService, AuthService } from '@advance-trading/angular-ati-security';
import { BrokerRequestService, UserService } from '@advance-trading/angular-ops-data';
import { BrokerService } from '../services/broker-service';
import { Broker, BrokerRequest, BrokerType, Client, Status, User, YesNoNA } from '@advance-trading/ops-data-lib';
import { ClientService } from '../services/client-service';

import { CommonValidators } from '@advance-trading/angular-common-services';

const BROKER_REQUESTER_ROLE = 'BrokerRequester';
const BRANCH_COMMITTEE_APPROVER_ROLE = 'BranchCommitteeApprover';
const BOARD_APPROVER_ROLE = 'BoardApprover';
const HR_APPROVER_ROLE = 'HRApprover';
const COMPLIANCE_APPROVER_ROLE = 'ComplianceApprover';

@Component({
  selector: 'atom-broker-request-detail',
  templateUrl: './broker-request-detail.component.html',
  styleUrls: ['./broker-request-detail.component.css']
})
export class BrokerRequestDetailComponent implements OnInit, OnDestroy {

  // navigation
  createMode: boolean;
  editMode: boolean;
  updateComplete = true;
  errorMessage: string;

  brokerRequestForm = new FormGroup({
    firstName: new FormControl('', [
      Validators.required,
      Validators.maxLength(100)
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.maxLength(100)
    ]),
    status: new FormControl(Status.NEW, [
      Validators.required
    ]),
    type: new FormControl('', [
      Validators.required
    ]),
    mentors: new FormControl(),
    client: new FormControl('', [
      CommonValidators.objectValidator
    ]),
    branchCommitteeReview: new FormControl(undefined),
    boardReview: new FormControl(undefined),
    hrReview: new FormControl(undefined),
    complianceReview: new FormControl(undefined),
    registered: new FormControl(undefined),
    denialComments: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(400)
    ]),
    address: new FormGroup({
      street1: new FormControl('', [
        Validators.required,
        Validators.maxLength(200)
      ]),
      street2: new FormControl('', [
        Validators.maxLength(200)
      ]),
      city: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      region: new FormControl('', [
        Validators.required
      ]),
      postalCode: new FormControl('', [
        Validators.required
      ]),
      country: new FormControl('', [
        Validators.required
      ]),
    }),
  });

  // mentors config
  selectable = true;
  removable = true;
  separatorKeyCodes: number[] = [];

  @ViewChild('mentorsInput', { static: false }) mentorsInput: ElementRef<HTMLInputElement>;

  // form data
  filteredClients$: Observable<Client[]>;
  filteredMentors$: Observable<User[]>;
  selectedMentors: User[] = [];
  brokerRequest: BrokerRequest;
  client: Client;

  brokerTypes = Object.keys(BrokerType);

  // data collections
  private activeBrokers$: Observable<Broker[]>;
  private availableMentors$: Observable<User[]>;
  private allClients$: Observable<Client[]>;
  private unsubscribe$: Subject<void> = new Subject<void>();

  // pending request states
  private isPendingBranchCommittee = false;
  private isPendingBoardOfDirectors = false;
  private isPendingHR = false;
  private isPendingCompliance = false;
  private isPendingRegistration = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private brokerRequestService: BrokerRequestService,
    private brokerService: BrokerService,
    private clientService: ClientService,
    private userService: UserService,
    private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    const brokerRequestMentors$: Observable<User[]> = this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          // display existing broker request for /brokerrequests/{docId}
          if (params.get('docId')) {
            this.createMode = false;
            this.setEditMode(false);
            return this.brokerRequestService.getBrokerRequestByDocId(params.get('docId'));
          }
          // display blank New Broker Request form for /brokers/new
          if (!this.authzService.currentUserHasRole(BROKER_REQUESTER_ROLE)) {
            return throwError('New broker requests can only be submitted by users with the BrokerRequester role');
          }
          this.createMode = true;
          this.setEditMode(true);
          this.prepForNewBrokerRequest();
          return of(undefined);
        }),
        switchMap(brokerRequest => {
          this.brokerRequest = brokerRequest;
          // broker request found matching docId from URL
          if (this.brokerRequest) {
            console.log('Retrieved Broker Request: ' + JSON.stringify(brokerRequest));
            // get client data if non-employee broker request
            if (this.brokerRequest.type === BrokerType.NON_EMPLOYEE && this.brokerRequest.clientDocId) {
              return this.clientService.getClient(this.brokerRequest.clientDocId);
            }
            return of(undefined);
          } else {
            if (!this.createMode) {
              this.openSnackBar('Broker Request Not Found', 'DISMISS', false);
              // navigate to Broker Requests list view
              this.router.navigate(['/brokerrequests']);
            }
            return of(undefined);
          }
        }),
        switchMap(client => {
          this.client = client;
          if (this.brokerRequest && this.brokerRequest.mentors.length > 0) {
            return combineLatest(this.brokerRequest.mentors.map(mentorDocId => this.userService.getUserByDocId(mentorDocId)));
          } else {
            return of(undefined);
          }
        }),
        tap(mentors => {
          if (mentors) {
            this.selectedMentors = mentors;
          }
          return of(undefined);
        }),
        takeUntil(this.unsubscribe$)
      );

    brokerRequestMentors$.subscribe(() => {
      if (this.brokerRequest) {
        this.mapRetrievedBrokerRequest();
        this.setRequestStates();
      }
    }, err => {
      this.errorMessage = 'This broker request either does not exist or you do not have permission to view the information.';
      console.error('Error occurred fetching broker request info: ' + JSON.stringify(err));
    });

    this.onBranchCommitteeReviewChanges();
    this.onBoardReviewChanges();
    this.onHrReviewChanges();
    this.onComplianceReviewChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Is the broker request in a state that allows the logged in user to update
   */
  canUserEditBrokerRequest() {
    return this.pendingUpdateAllowed() && this.userIsCurrentStateApprover();
  }

  /**
   * Called on submit of Create/Update Broker Request form
   */
  submit() {
    this.updateComplete = false;
    this.setEditMode(false);
    if (this.createMode) {
      this.createBrokerRequest();
    } else {
      this.updateBrokerRequest();
    }
  }

  /**
   * Sets editMode and enables or disables broker request form controls
   */
  setEditMode(mode: boolean) {
    this.editMode = mode;
    this.brokerRequestForm.disable();
    if (this.createMode) {
      this.brokerRequestForm.get('firstName').enable();
      this.brokerRequestForm.get('lastName').enable();
      this.brokerRequestForm.get('type').enable();
      this.brokerRequestForm.get('mentors').enable();
      this.brokerRequestForm.get('client').enable();
      this.brokerRequestForm.get('address').enable();
    } else if (this.editMode) {
      if (this.canBranchCommitteeApproverUpdate()) {
        this.brokerRequestForm.get('branchCommitteeReview').enable();
      }
      if (this.canBoardApproverUpdate()) {
        this.brokerRequestForm.get('boardReview').enable();
      }
      if (this.canHrApproverUpdate()) {
        this.brokerRequestForm.get('hrReview').enable();
      }
      if (this.canComplianceApproverUpdate()) {
        this.brokerRequestForm.get('complianceReview').enable();
      }
      if (this.canHRApproverUpdateRegistration()) {
        this.brokerRequestForm.get('registered').enable();
      }
    }
    this.brokerRequestForm.markAsPristine();
  }

  /**
   * Resets the broker request form.
   * When called during a new broker request, form is reset to default values.
   * When called while editing an existing broker request, sets editMode to false and
   * reloads the form with original values retrieved from Firestore for the current broker request.
   */
  reset() {
    if (this.createMode) {
      this.brokerRequestForm.reset(
        { firstName: '',
          lastName: '',
          status: Status.NEW,
          type: '',
          mentors: '',
          client: '',
          branchCommitteeReview: undefined,
          boardReview: undefined,
          hrReview: undefined,
          complianceReview: undefined,
          registered: undefined,
          denialComments: '',
          address: {
            street1: '',
            street2: '',
            city: '',
            region: '',
            postalCode: '',
            country: ''
          }
        }
      );
      this.selectedMentors = [];
      this.mentorsInput.nativeElement.value = '';
    } else {
      this.setEditMode(false);
      // map data for current broker request to values in the broker request form
      this.brokerRequestForm.reset(
        { firstName: this.brokerRequest.firstName,
          lastName: this.brokerRequest.lastName,
          status: this.brokerRequest.status,
          type: this.brokerRequest.type,
          mentors: this.selectedMentors,
          client: this.client,
          registered: this.brokerRequest.isRegistered === YesNoNA.YES ? true : undefined,
          denialComments: this.brokerRequest.denialComments,
          address: {
            street1: this.brokerRequest.address.street1,
            street2: this.brokerRequest.address.street2,
            city: this.brokerRequest.address.city,
            region: this.brokerRequest.address.region,
            postalCode: this.brokerRequest.address.postalCode,
            country: this.brokerRequest.address.country
          }
        }
      );
    }
    this.brokerRequestForm.markAsPristine();
  }

  /**
   * Displays "firstName lastName" for mentor autocomplete input field
   */
  displayMentor(user?: User): string | undefined {
    return user ? `${user.firstName} ${user.lastName}` : undefined;
  }

  /**
   * Adds a new mentor to the list of broker mentors.
   * Prevents the addition of duplicate mentors or the current broker.
   */
  addMentor() {
    const mentor = this.brokerRequestForm.value.mentors;
    // if mentor is not already in the array, push added mentor
    if (!this.selectedMentors.find(selectedMentor => selectedMentor.docId === mentor.docId)) {
      this.selectedMentors.push(mentor);
    }
    this.brokerRequestForm.get('mentors').setValue('');
    this.mentorsInput.nativeElement.value = '';
  }

  /**
   * Removes a selected mentor from the list of broker mentors.
   */
  removeMentor(mentor: User) {
    const index = this.selectedMentors.indexOf(mentor);
    if (index >= 0) {
      this.selectedMentors.splice(index, 1);
    }
    this.brokerRequestForm.get('mentors').setValue('');
    this.brokerRequestForm.get('mentors').markAsDirty();
  }

  /**
   * Displays name for client autocomplete input field
   */
  displayClient(client?: Client) {
    if (client) {
      return client.physicalAddress ? `${client.name} (${client.physicalAddress.city}, ${client.physicalAddress.region})` : client.name;
    }
    return '';
  }

  /**
   * Indicates if a pending request is being denied by the current update
   */
  get isBeingDenied() {
    return this.branchCommitteeDenying() || this.boardDenying() || this.hrDenying() || this.complianceDenying();
  }

  /**
   * Does the logged in user have access to view the broker's mentors
   * Board of Directors, Branch Committee, or originator
   */
  canShowMentors() {
    if (this.brokerRequest) {
      return this.authzService.currentUserHasRole(BRANCH_COMMITTEE_APPROVER_ROLE)
        || this.authzService.currentUserHasRole(BOARD_APPROVER_ROLE)
        || (this.brokerRequest.originator === this.authService.userProfile.app_metadata.firestoreDocId);
    }
    return false;
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('minlength')) {
      return `Value required to have a minimum of ${control.getError('minlength')['requiredLength']} characters`;
    } else if (control.hasError('maxlength')) {
      return `Value cannot exceed ${control.getError('maxlength')['requiredLength']} characters`;
    }
    return 'Unknown error';
  }

  // Is the broker request in a state that allows an update by a BranchCommitteeApprover
  private canBranchCommitteeApproverUpdate() {
    return this.isPendingBranchCommittee && this.authzService.currentUserHasRole(BRANCH_COMMITTEE_APPROVER_ROLE);
  }

  // Is the broker request in a state that allows an update by a BoardApprover
  private canBoardApproverUpdate() {
    return this.isPendingBoardOfDirectors && this.authzService.currentUserHasRole(BOARD_APPROVER_ROLE);
  }

  // Is the broker request in a state that allows an update by a HRApprover
  private canHrApproverUpdate() {
    return this.isPendingHR && this.authzService.currentUserHasRole(HR_APPROVER_ROLE);
  }

  // Is the broker request in a state that allows an update by a ComplianceApprover
  private canComplianceApproverUpdate() {
    return this.isPendingCompliance && this.authzService.currentUserHasRole(COMPLIANCE_APPROVER_ROLE);
  }

  private canHRApproverUpdateRegistration() {
    return this.isPendingRegistration && this.authzService.currentUserHasRole(HR_APPROVER_ROLE);
  }

  // Allow updates for PENDING broker requests that are not fully complete
  private pendingUpdateAllowed() {
    return this.brokerRequest && this.brokerRequest.status === Status.PENDING;
  }

  // Allow updates if logged in user an approver for the current state of the request
  private userIsCurrentStateApprover() {
    return this.canBranchCommitteeApproverUpdate() || this.canBoardApproverUpdate() || this.canHrApproverUpdate()
    || this.canComplianceApproverUpdate() || this.canHRApproverUpdateRegistration();
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  // Maps all data retrieved from Firestore for current broker request to values in the broker request form
  private mapRetrievedBrokerRequest() {
    this.brokerRequestForm.get('firstName').setValue(this.brokerRequest.firstName);
    this.brokerRequestForm.get('lastName').setValue(this.brokerRequest.lastName);
    this.brokerRequestForm.get('status').setValue(this.brokerRequest.status);
    this.brokerRequestForm.get('type').setValue(this.brokerRequest.type);
    this.brokerRequestForm.get('mentors').setValue(this.selectedMentors);
    this.brokerRequestForm.get('client').setValue(this.client || '');
    this.brokerRequestForm.get('registered').setValue(this.brokerRequest.isRegistered === YesNoNA.YES ? true : undefined);
    this.brokerRequestForm.get('denialComments').setValue(this.brokerRequest.denialComments || '');
    this.brokerRequestForm.get('address').get('street1').setValue(this.brokerRequest.address.street1);
    this.brokerRequestForm.get('address').get('street2').setValue(this.brokerRequest.address.street2);
    this.brokerRequestForm.get('address').get('city').setValue(this.brokerRequest.address.city);
    this.brokerRequestForm.get('address').get('region').setValue(this.brokerRequest.address.region);
    this.brokerRequestForm.get('address').get('postalCode').setValue(this.brokerRequest.address.postalCode);
    this.brokerRequestForm.get('address').get('country').setValue(this.brokerRequest.address.country);
  }

  // Determine the current state of the broker request
  private setRequestStates() {
    this.isPendingBranchCommittee = false;
    this.isPendingBoardOfDirectors = false;
    this.isPendingCompliance = false;
    this.isPendingHR = false;
    this.isPendingRegistration = false;

    // all states remain false for APPROVED or DENIED statuses
    if (this.brokerRequest.status === Status.PENDING) {
      // request is pending Branch Committee review
      if (this.brokerRequest.type === BrokerType.BRANCH && !this.brokerRequest.isBranchCommitteeApproved) {
        this.isPendingBranchCommittee = true;

      // request is pending Board of Directors review
      } else if (!this.brokerRequest.isBoardApproved && (this.brokerRequest.type !== BrokerType.BRANCH
        || (this.brokerRequest.type === BrokerType.BRANCH && this.brokerRequest.isBranchCommitteeApproved === YesNoNA.YES))) {
        this.isPendingBoardOfDirectors = true;

      // request is Board approved
      } else if (this.brokerRequest.isBoardApproved === YesNoNA.YES) {

        // request is pending Compliance review
        if (!this.brokerRequest.isComplianceApproved) {
          this.isPendingCompliance = true;
        }
        // request is pending HR review
        if (this.brokerRequest.type !== BrokerType.NON_EMPLOYEE && !this.brokerRequest.isHrApproved) {
          this.isPendingHR = true;
        }
      }
      if (this.brokerRequest.type !== BrokerType.NON_EMPLOYEE && !this.brokerRequest.isRegistered) {
        this.isPendingRegistration = !this.isPendingBranchCommittee && !this.isPendingBoardOfDirectors
          && !this.isPendingCompliance && !this.isPendingHR;
      }
    }
  }

  private branchCommitteeDenying() {
    return this.isPendingBranchCommittee && this.brokerRequestForm.get('branchCommitteeReview').value === 'NO';
  }

  private boardDenying() {
    return this.isPendingBoardOfDirectors && this.brokerRequestForm.get('boardReview').value === 'NO';
  }

  private hrDenying() {
    return this.isPendingHR && this.brokerRequestForm.get('hrReview').value === 'NO';
  }

  private complianceDenying() {
    return this.isPendingCompliance && this.brokerRequestForm.get('complianceReview').value === 'NO';
  }

  private onBranchCommitteeReviewChanges() {
    this.brokerRequestForm.get('branchCommitteeReview').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(val => {
      this.setDenialCommentsEnabled(this.branchCommitteeDenying());
    });
  }

  private onBoardReviewChanges() {
    this.brokerRequestForm.get('boardReview').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(val => {
      this.setDenialCommentsEnabled(this.boardDenying());
    });
  }

  private onHrReviewChanges() {
    this.brokerRequestForm.get('hrReview').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(val => {
      this.setDenialCommentsEnabled(this.hrDenying());
    });
  }

  private onComplianceReviewChanges() {
    this.brokerRequestForm.get('complianceReview').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(val => {
      this.setDenialCommentsEnabled(this.complianceDenying());
    });
  }

  private setDenialCommentsEnabled(enable: boolean) {
    if (enable) {
      this.brokerRequestForm.get('denialComments').enable();
    } else {
      this.brokerRequestForm.get('denialComments').disable();
    }
  }

  // Gets available clients and mentors (active brokers) for autocomplete
  private prepForNewBrokerRequest() {
    this.prepForMentorSelection();
    this.prepForClientSelection();
  }

  // Gets available mentors (active brokers) for mentor autocomplete
  private prepForMentorSelection() {
    if (!this.activeBrokers$) {
      this.activeBrokers$ = this.brokerService.getActiveBrokers();
    }
    this.mapBrokersToMentors();
    this.filterMentors();
  }

  // Populates an Observable<User[]> that includes all active brokers
  private mapBrokersToMentors() {
    this.availableMentors$ = this.activeBrokers$.pipe(
      switchMap(brokers => {
        return combineLatest(brokers.map(broker => this.userService.getUserByDocId(broker.docId)));
      })
    );
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting mentor brokers
  private filterMentors() {
    this.filteredMentors$ = this.brokerRequestForm.controls.mentors.valueChanges
      .pipe(
        debounceTime(300),
        startWith<string | User>(''),
        switchMap(term => typeof term === 'string' ? this.filterMentorsBySearchTerm(term) : of([term]))
      );
  }

  // Filters possible mentors (active brokers from Firestore) using the user input search term
  private filterMentorsBySearchTerm(searchTerm: string): Observable<User[]> {
    return this.availableMentors$
      .pipe(
        map(mentors => mentors.filter(
          mentor => this.selectedMentors.find(selectedMentor => selectedMentor.docId === mentor.docId) ? false : true)),
        map(mentors => mentors.filter(mentor => this.matchName(searchTerm, mentor)))
      );
  }

  // Checks for a partial match on user/mentor first or last name
  private matchName(searchTerm: string, user: User) {
    const searchValue = searchTerm.toLowerCase();
    const first = user.firstName.toLowerCase();
    const last = user.lastName.toLowerCase();

    const fullName = `${first} ${last}`;
    const lastFirst = `${last} ${first}`;
    const lastCommaFirst = `${last}, ${first}`;
    return fullName.includes(searchValue) || lastFirst.includes(searchValue) || lastCommaFirst.includes(searchValue);
  }

  private prepForClientSelection() {
    this.getAllClients();
    this.filterClients();
  }

  // Gets all clients from Firestore
  private getAllClients() {
    if (!this.allClients$) {
      this.allClients$ = this.clientService.getAllClients()
        .pipe(
          shareReplay(1)
        );
    }
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting a client for the broker
  private filterClients() {
    this.filteredClients$ = this.brokerRequestForm.controls.client.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        startWith<string | Client>(''),
        switchMap(term => typeof term === 'string' ? this.filterClientsBySearchTerm(term) : of([term]))
      );
  }

  // Filters clients from Firestore using the user input search term
  private filterClientsBySearchTerm(searchTerm: string): Observable<Client[]> {
    return this.allClients$
      .pipe(
        map(clients => clients.filter(client => client.name.toLowerCase().includes(searchTerm.toLowerCase())))
      );
  }

  // Maps values from broker request form to new Broker Request,
  // creates a new broker request in Firestore, and routes to broker requests list
  private createBrokerRequest() {
    const newBrokerRequest = new BrokerRequest();
    newBrokerRequest.firstName = this.brokerRequestForm.value.firstName;
    newBrokerRequest.lastName = this.brokerRequestForm.value.lastName;
    newBrokerRequest.status = Status.NEW;
    newBrokerRequest.type = this.brokerRequestForm.value.type;
    newBrokerRequest.mentors = this.selectedMentors.map(mentor => mentor.docId);
    if (newBrokerRequest.type === BrokerType.NON_EMPLOYEE && this.brokerRequestForm.value.client) {
      newBrokerRequest.clientDocId = this.brokerRequestForm.value.client.docId;
    }
    newBrokerRequest.originator = this.authService.userProfile.app_metadata.firestoreDocId;
    newBrokerRequest.address = {
      street1: this.brokerRequestForm.value.address.street1,
      street2: this.brokerRequestForm.value.address.street2,
      city: this.brokerRequestForm.value.address.city,
      region: this.brokerRequestForm.value.address.region,
      postalCode: this.brokerRequestForm.value.address.postalCode,
      country: this.brokerRequestForm.value.address.country
    };
    if (newBrokerRequest.type === BrokerType.COMMERCIAL) {
      newBrokerRequest.isBranchCommitteeApproved = YesNoNA.NA;
    }
    if (newBrokerRequest.type === BrokerType.NON_EMPLOYEE) {
      newBrokerRequest.isBranchCommitteeApproved = YesNoNA.NA;
      newBrokerRequest.isHrApproved = YesNoNA.NA;
      newBrokerRequest.isRegistered = YesNoNA.NA;
    }

    // insert new broker request data into Firestore
    this.brokerRequestService.createBrokerRequest(newBrokerRequest)
      .then(() => {
        console.log('Broker Request successfully created: ' + JSON.stringify(newBrokerRequest));
        this.updateComplete = true;
        this.openSnackBar('Broker request successfully created', 'DISMISS', true);
        this.router.navigate([`/brokerrequests`]);
      })
      .catch(err => {
        this.updateComplete = true;
        console.error('Broker Request creation failed: ' + JSON.stringify(err));
        let errorMessage = '';
        switch (err.code) {
          case 'permission-denied':
            errorMessage = 'Insufficient permissions';
            break;
          default:
            errorMessage = 'Unknown error occurred';
        }
        this.openSnackBar('Broker request creation failed: ' + errorMessage, 'DISMISS', false);
      });
  }

  // Maps all values from broker request form for existing broker request and updates broker request in Firestore
  private updateBrokerRequest() {
    const formValues = this.brokerRequestForm.getRawValue();
    if (this.isBeingDenied) {
      this.brokerRequest.status = Status.DENIED;
      this.brokerRequest.denialComments = formValues.denialComments;
    }
    if (this.isPendingBranchCommittee) {
      this.brokerRequest.isBranchCommitteeApproved = formValues.branchCommitteeReview;
      this.brokerRequest.branchCommitteeDecisionDate = new Date().toISOString();
    }
    if (this.isPendingBoardOfDirectors) {
      this.brokerRequest.isBoardApproved = formValues.boardReview;
      this.brokerRequest.boardDecisionDate = new Date().toISOString();
    }
    if (this.isPendingHR && formValues.hrReview) {
      this.brokerRequest.isHrApproved = formValues.hrReview;
      this.brokerRequest.hrDecisionDate = new Date().toISOString();
    }
    if (this.isPendingCompliance && formValues.complianceReview) {
      this.brokerRequest.isComplianceApproved = formValues.complianceReview;
      this.brokerRequest.complianceDecisionDate = new Date().toISOString();
    }
    if (this.isPendingRegistration && formValues.registered) {
      this.brokerRequest.isRegistered = YesNoNA.YES;
    }

    // update existing broker request data in Firestore
    this.brokerRequestService.updateBrokerRequest(this.brokerRequest)
      .then(() => {
        console.log('Broker Request successfully updated: ' + JSON.stringify(this.brokerRequest));
        this.updateComplete = true;
        this.createMode = false;
        this.setEditMode(false);
        this.openSnackBar('Broker request successfully updated', 'DISMISS', true);
      })
      .catch(err => {
        this.updateComplete = true;
        console.error('Broker Request update failed: ' + JSON.stringify(err));
        let errorMessage = '';
        switch (err.code) {
          case 'permission-denied':
            errorMessage = 'Insufficient permissions';
            break;
          default:
            errorMessage = 'Unknown error occurred';
        }
        this.openSnackBar('Broker request update failed: ' + errorMessage, 'DISMISS', false);
      });
  }

}
