import { ObservableDataSource, StorageService } from '@advance-trading/angular-common-services';
import { FundsTransferType, Margin } from '@advance-trading/ops-data-lib';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { ExportService } from '../services/export.service';
import { MarginService } from '../services/margin-service';

const maxRows = 20000;
const PAGE_SIZE_KEY = 'atom.marginsPageSize';

@Component({
  selector: 'atom-margins',
  templateUrl: './margins.component.html',
  styleUrls: ['./margins.component.css']
})
export class MarginsComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {

  @Input() initialTableState: { [key: string]: string | number };
  @Input() selectedMargins$: Observable<Margin[]>;

  @Output() marginListChange: EventEmitter<any> = new EventEmitter();
  @Output() marginSearchError: EventEmitter<string> = new EventEmitter();
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  columnsToDisplay = [];
  errorMessage: string;
  dataSource = new ObservableDataSource<Margin>();
  exportable = false;
  filterValue = new FormControl();
  private unsubscribe$: Subject<void> = new Subject<void>();

  private tableState: { [key: string]: string | number } = {};

  constructor(
    private breakpointObserver: BreakpointObserver,
    private changeDetector: ChangeDetectorRef,
    public exportService: ExportService,
    public marginService: MarginService,
    private router: Router,
    private snackBar: MatSnackBar,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.isSearching.emit(true);

    // Setup listener for filter value changes
    this.filterValue.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((filter: string) => {
      if (filter) {
        this.tableState.filterValue = filter.trim();
        this.marginListChange.emit(this.tableState);
      } else if (this.tableState.filterValue) {
        delete this.tableState.filterValue;
        this.marginListChange.emit(this.tableState);
      }
    });

    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
    ]).pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      if (state.breakpoints[Breakpoints.XSmall]) {
        this.columnsToDisplay = ['processDate', 'accountNumber', 'brokerCode'];
      } else if (state.breakpoints[Breakpoints.Small]) {
        this.columnsToDisplay = ['processDate', 'accountNumber', 'moneyAccount', 'clientName', 'brokerCode', 'marginType'];
      } else if (state.breakpoints[Breakpoints.Medium]) {
        this.columnsToDisplay = ['processDate', 'accountNumber', 'moneyAccount', 'clientName', 'brokerCode',
          'totalEquity', 'netLiq', 'marginType'];
      } else {
        this.columnsToDisplay = [
          'processDate', 'accountNumber', 'moneyAccount', 'clientName', 'brokerCode', 'longMarketValue',
          'totalEquity', 'netLiq', 'excessDeficit', 'debitAge', 'totalCall', 'owed',
          'marginCallAge', 'marginType', 'fundsTransferType', 'withdrawable'
        ];
      }
    });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = this.storageService.localStorage.get(PAGE_SIZE_KEY).value() || 10;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (margin, column) => {
      switch(column) {
        case 'brokerCode':
          return margin.officeCode + margin.salesCode;
        case 'owed':
          return this.marginService.calculateOwed(margin);
        default:
          return margin[column];
      }
    };
    if (this.filter) {
      this.filter.nativeElement.focus();
    }
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialTableState'] && changes['selectedMargins$']) {
      this.tableState = Object.assign({}, this.initialTableState);

      // Detect MatSort and matPaginator so it is defined
      this.changeDetector.detectChanges();

      const sortDir = this.initialTableState.sortDir as SortDirection;
      const sortColName = this.initialTableState.sortColName as string;
      if (sortDir && sortColName) {
        this.sort.direction = sortDir;
        this.sort.active = sortColName;
      }

      if (this.initialTableState.filterValue) {
        this.filterValue.setValue(this.initialTableState.filterValue);
        this.applyFilter(this.filterValue.value);
      }

      // Initialize Table
      this.dataSource.data$ = this.selectedMargins$.pipe(
        tap((margins) => {
          this.exportable = !!margins.length;
          this.isSearching.emit(false);

          // initialize pagination state when the datasource exist
          const pageIndex = this.initialTableState.pageIndex as number;

          if (pageIndex !== undefined) {
            this.paginator.pageIndex = pageIndex;
          }

          if (margins.length === 0) {
            this.openSnackBar('There are no margins that match this search', 'DISMISS', true);
          } else if (margins.length === maxRows) {
            this.openSnackBar(`Reached maximum number of rows (${maxRows.toLocaleString()})`, 'DISMISS', true);
          } else {
            catchError(err => {
              this.errorMessage = 'Error retrieving margins; please try again later';
              this.marginSearchError.emit(this.errorMessage);
              this.isSearching.emit(false);
              console.error(`Error retrieving margins: ${err}`);
              return of([]);
            });
          }
        })
      );
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  displayFundsType(type: FundsTransferType) {
    switch(type) {
      case FundsTransferType.ACH_PULL:
        return 'ACH Pull';
      case FundsTransferType.WIRE:
        return 'Wire';
      case FundsTransferType.CHECK:
        return 'Check';
      default:
        return '';
    }
  }

  handleSortChange() {
    this.tableState.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.tableState.sortColName = this.tableState.sortDir ? this.sort.active : undefined;
    this.marginListChange.emit(this.tableState);
  }

  handlePageChange() {
    this.storageService.localStorage.set(PAGE_SIZE_KEY, this.paginator.pageSize);
    this.tableState.pageIndex = this.paginator.pageIndex;
    this.marginListChange.emit(this.tableState);
  }

  selectMargin(margin: Margin) {
    this.router.navigate(['accounts', margin.accountDocId, 'margins', margin.docId]);
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }
}

