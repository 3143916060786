<!--Template displays sliding-scale header-->
<ng-template #slidingScaleHeader let-rate='rate' let-tier='tier' let-i='i' let-dialog="dialog" let-deletable="deletable">
  <h4 class="tier-label">
    Level {{rate.controls.length === 1 ? 'n/a' : i + 1}}
    <ng-container *ngIf="rate.controls.length > 1">
      &nbsp;
      {{tier.get('rangeBottom').value | number:'.0'}}
      <ng-container *ngIf="rate.controls.length === i + 1">
        or more
      </ng-container>
      <ng-container *ngIf="rate.controls.length > i + 1">
        - {{parseInt(rate.at(i + 1).get('rangeBottom').value) - 1 | number:'.0'}}
      </ng-container>
      round turn
    </ng-container>
    <mat-icon
      *ngIf="dialog && isEditMode"
      (click)="openRateDialog(tier)"
      class="commodity-icon tier-icon"
      matTooltip="Edit Tier"
    >edit</mat-icon>
    <mat-icon
      *ngIf="dialog && isEditMode && deletable"
      (click)="deleteRateTier(tier)"
      class="commodity-icon tier-icon"
      matTooltip="Delete Tier"
    >delete</mat-icon>
  </h4>
</ng-template>

<div fxLayout="row" fxLayoutAlign="space-around top" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <div *ngIf="errorMessage">
      <h2 class="card-title">{{docIdParam ? 'Error Retrieving Commission Schedule' : 'Error Creating Commission Schedule'}}</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage">
      <h2 *ngIf="!docIdParam">New Commission Schedule</h2>
      <div *ngIf="docIdParam" fxLayout="row" fxLayoutAlign="space-between center">
        <h2>Commission Schedule<ng-container *ngIf="rateCode">: {{rateCode}}<ng-container *ngIf="docIdParam && rateForm.get('isSlidingScale').value"> (Sliding Scale)</ng-container></ng-container></h2>
        <ng-container *ngIf="existingSchedule">
          <button id="edit-commission-schedule" aria-label="Edit Commission Schedule" matTooltip="Edit Commission Schedule" *ngIf="!isEditMode && !isRateCodeNotFound && existingSchedule.status === Status.PENDING" (click)="setEditMode(true)" mat-mini-fab color="accent" class="edit-icon">
            <mat-icon>edit</mat-icon>
          </button>
          <button id="cancel-edit-commission-schedule" aria-label="Discard Changes" matTooltip="Discard Changes" *ngIf="isEditMode && !isRateCodeNotFound && existingSchedule.status === Status.PENDING" (click)="setEditMode(false)" mat-mini-fab color="" class="cancel-icon">
            <mat-icon>clear</mat-icon>
          </button>
        </ng-container>
      </div>

      <h4 *ngIf="isRateCodeNotFound">A commission schedule does not exist for this rate code.</h4>

      <form *ngIf="!isRateCodeNotFound" [formGroup]="rateForm" (ngSubmit)="handleSave()" #rateFormDirective="ngForm" fxLayout="column" class="rate-form">

        <!-- Rate code input -->
        <mat-form-field *ngIf="isEditMode && (!existingSchedule || existingSchedule.status === Status.PENDING)">
          <input class="rate-code" matInput placeholder="Enter new rate code" formControlName="rateCode" trim="blur" upperCase [errorStateMatcher]="rateCodeErrorMatcher">
          <mat-error *ngIf="rateForm.get('rateCode').invalid || rateForm.hasError('invalidRateCode')">{{getErrorMessage(rateForm.get('rateCode'))}}</mat-error>
        </mat-form-field>

        <mat-slide-toggle formControlName="isAllIn">All In</mat-slide-toggle>

        <!-- Sliding scale toggle -->
        <mat-slide-toggle *ngIf="isEditMode" formControlName="isSlidingScale">Sliding Scale</mat-slide-toggle>

        <!-- Compliance officer message -->
        <div *ngIf="!isEditMode && existingSchedule && (existingSchedule.status === Status.PENDING || existingSchedule.status === Status.NEW)" fxLayoutAlign="center">
          <p class="action-message">Commission schedule is pending compliance officer approval.</p>
        </div>
        <div *ngIf="!isEditMode && isComplianceOfficer && existingSchedule && existingSchedule.status === Status.PENDING" class="actionButtonContainer">
          <button id="approve-commission-schedule" aria-label="Approve Commission Schedule" matTooltip="Approve Commission Schedule" type="button" mat-raised-button class="add-rate approve" (click)="approveCompliance()">Approve</button>
          <button id="deny-commission-schedule" aria-label="Deny Commission Schedule" matTooltip="Deny Commission Schedule" type="button" mat-raised-button class="add-rate deny" (click)="denyCompliance()">Deny</button>
        </div>

        <!-- AccountAdmin message -->
        <div *ngIf="!isEditMode && existingSchedule && existingSchedule.status === Status.APPROVED && !isAccountAdmin" fxLayoutAlign="center">
          <p class="action-message">Pending clearing firm confirmation</p>
        </div>
        <div style="width: 200px; margin-left: auto; margin-right: auto" *ngIf="!isEditMode && existingSchedule && existingSchedule.status === Status.APPROVED && isAccountAdmin">
          <mat-form-field>
            <input class="rate-code" matInput placeholder="Confirm new rate code" formControlName="rateCode" trim="blur" upperCase [errorStateMatcher]="rateCodeErrorMatcher" required>
            <mat-error *ngIf="rateForm.get('rateCode').invalid || rateForm.hasError('invalidRateCode')">{{getErrorMessage(rateForm.get('rateCode'))}}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="!isEditMode && existingSchedule && existingSchedule.status === Status.APPROVED && isAccountAdmin" class="actionButtonContainer">
          <button type="button" mat-raised-button class="add-rate activate" (click)="activateCommissionSchedule()" [disabled]="!rateForm.valid || isSaving || !rateForm.get('rateCode').value">Activate Commission Schedule</button>
        </div>

        <!-- Inactive commission schedule message -->
        <div *ngIf="!isEditMode && existingSchedule && existingSchedule.status === Status.INACTIVE" fxLayoutAlign="center">
          <h4>Commission schedule is inactive</h4>
        </div>

        <!-- Schedule accordion -->
        <mat-accordion [multi]="true">
          <mat-expansion-panel (opened)="this.handleOpenPanel('allExchanges')" (closed)="this.handleClosePanel()" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon *ngIf="doShowWarningIcon" class="pane-icon">warning</mat-icon>
                Rate applicable to all exchanges unless listed otherwise
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container
              *ngIf="rateForm.get('isSlidingScale').value"
              [ngTemplateOutlet]="slidingScaleHeader"
              [ngTemplateOutletContext]="{rate: rateForm.get('defaultRate'), tier: rateForm.get('defaultRate').at(0), i: 0, dialog: false}"
            ></ng-container>
            <atom-commission-rate-table [rateForm]="rateForm.get('defaultRate').at(0)" [readOnly]="!isEditMode"></atom-commission-rate-table>
            <ng-container *ngFor="let rateTier of rateForm.get('defaultRate').controls; let i = index">
              <ng-container *ngIf="i > 0">
                <ng-container
                  *ngIf="rateForm.get('isSlidingScale').value"
                  [ngTemplateOutlet]="slidingScaleHeader"
                  [ngTemplateOutletContext]="{rate: rateForm.get('defaultRate'), tier: rateTier, i: i, dialog: true, deletable: i > 0 || rate.controls.length === 1}"
                ></ng-container>
                <atom-commission-rate-table [rateForm]="rateTier" [readOnly]="true"></atom-commission-rate-table>
              </ng-container>
            </ng-container>
            <div *ngIf="isEditMode && rateForm.get('isSlidingScale').value">
              <mat-icon class="commodity-icon" (click)="addRateTier(rateForm.get('defaultRate'))">add</mat-icon>
            </div>
          </mat-expansion-panel>

          <!-- Expansion panels for specific exchanges -->
          <mat-expansion-panel *ngFor="let exchangeEntry of ratesByExchangeWithoutAll | keyvalue" (opened)="this.handleOpenPanel(exchangeEntry.key)" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{exchangeEntry.key | displayName:knownExchanges}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-tab-group dynamicHeight>

              <ng-container *ngFor="let rate of ratesByExchange[exchangeEntry.key]">
                <mat-tab [label]="rate.at(0).get('commodity').value | displayName:knownCommodities">
                  <ng-container *ngFor="let rateTier of rate.controls; let i = index">
                    <ng-container
                      *ngIf="rateForm.get('isSlidingScale').value"
                      [ngTemplateOutlet]="slidingScaleHeader"
                      [ngTemplateOutletContext]="{rate: rate, tier: rateTier, i: i, dialog: true, deletable: i > 0 || rate.controls.length === 1}"
                    ></ng-container>
                    <atom-commission-rate-table [rateForm]="rateTier" [readOnly]="true"></atom-commission-rate-table>
                  </ng-container>

                  <div *ngIf="isEditMode">
                    <mat-icon
                      *ngIf="rateForm.get('isSlidingScale').value"
                      (click)="addRateTier(rate)"
                      class="commodity-icon"
                      matTooltip="Add Rate Tier"
                    >add</mat-icon>
                    <mat-icon
                      *ngIf="!rateForm.get('isSlidingScale').value"
                      (click)="openRateDialog(rate.at(0))"
                      class="commodity-icon"
                      matTooltip="Edit"
                    >edit</mat-icon>
                    <mat-icon
                      *ngIf="!rateForm.get('isSlidingScale').value"
                      (click)="deleteRate(rate.at(0))"
                      class="commodity-icon"
                      matTooltip="Delete"
                    >delete</mat-icon>
                  </div>
                </mat-tab>
              </ng-container>
            </mat-tab-group>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="disclaimer">
          <p>
            All future rates are quoted as round turn.
          </p>
          <p>
            All option rates are quoted as half turn.
          </p>
          <p *ngIf="rateForm.get('isSlidingScale').value">
            Sliding Scale rates apply to full size contracts only.
          </p>
          <p *ngIf="!rateForm.get('isAllIn').value">
            Exchange, Clearing, N.F.A., and pit brokerage fees are assessed in addition to the rates quoted.
          </p>
          <p *ngIf="rateForm.get('isAllIn').value">
            Exchange, Clearing, N.F.A., and pit brokerage fees are included.
          </p>
          <p>
            Spread commissions are only applicable if the trade is put on and taken off as a spread.
          </p>
        </div>

        <!-- "Add Commodity" button -->
        <div *ngIf="isEditMode">
          <button type="button" mat-raised-button class="add-rate" (click)="openRateDialog()" [disabled]="isSaving">Add Exchange/Commodity</button>
        </div>

        <!-- Submit button -->
        <button id="save-commission-schedule" aria-label="Save Commission Schedule" matTooltip="Save Commission Schedule" *ngIf="isEditMode" class="submit-button" mat-raised-button color="accent" type="submit" [disabled]="!rateForm.valid || isSaving">
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-spinner *ngIf="isSaving" diameter="15"></mat-spinner>
            {{existingSchedule ? "Save Changes" : "Add Commission Schedule"}}
          </div>
        </button>
      </form>
    </div>
  </mat-card>
</div>
