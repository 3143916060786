import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

import { AuthService } from '@advance-trading/angular-ati-security';
import { Broker, Status } from '@advance-trading/ops-data-lib';
import { shareReplay, switchMap, map } from 'rxjs/operators';
import { UserService } from '@advance-trading/angular-ops-data';

const MAXIMUM_ARRAY_SIZE = 10;

@Injectable({
  providedIn: 'root'
})
export class BrokerService {

  constructor(
    private db: AngularFirestore,
    private authService: AuthService,
    private userService: UserService
  ) { }

  /**
   * across all helper functions @param brokers is the brokers on User.brokers
   */

  /**
   * Retrieves a single Broker document by Broker docId
   */
  getBrokerByDocId(docId: string): Observable<Broker> {
    return this.db.collection(Broker.getDataPath()).doc<Broker>(docId).valueChanges();
  }

  /**
   * Retrieves all brokers
   */
  getAllBrokers(): Observable<Broker[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('BrokerViewer')) {
      return this.db.collection<Broker>(Broker.getDataPath()).valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.brokers.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.brokers.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getAllBrokersHelper(
                user.brokers.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Broker arrays into single array
              map(arrayOfBrokerArrays => (arrayOfBrokerArrays as Broker[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getAllBrokersHelper(user.brokers)
            .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getAllBrokersHelper(brokers: string[]): Observable<Broker[]> {
    return this.db.collection<Broker>(Broker.getDataPath(), ref => ref.where('docId', 'in', brokers)).valueChanges();
  }

  /**
   * Retrieves all brokers where status == ACTIVE
   */
  getActiveBrokers(): Observable<Broker[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('BrokerCodeViewer')) {
      return this.db.collection<Broker>(Broker.getDataPath(), ref => ref.where('status', '==', Status.ACTIVE)).valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.brokers.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.brokers.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getActiveBrokersHelper(
                user.brokers.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Broker arrays into single array
              map(arrayOfBrokerArrays => (arrayOfBrokerArrays as Broker[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getActiveBrokersHelper(user.brokers)
            .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getActiveBrokersHelper(brokers: string[]): Observable<Broker[]> {
    return this.db.collection<Broker>(Broker.getDataPath(), ref => ref
    .where('status', '==', Status.ACTIVE)
    .where('docId', 'in', brokers)).valueChanges();
  }

  /**
   * Updates an existing Broker document
   */
  updateBroker(broker: Broker): Promise<void> {
    broker.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.db.collection(Broker.getDataPath()).doc<Broker>(broker.docId).update(broker);
  }

}
