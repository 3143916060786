<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Commission Schedules List</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div fxLayout="column" *ngIf="!errorMessage">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="card-title">Commission Schedules</h2>
        <button id="add-new-commission-schedule" aria-label="Request New Commission Schedule" (click)="addCommissionSchedule()" mat-mini-fab color="accent" class="edit-icon" matTooltip="Request New Commission Schedule"><mat-icon>add</mat-icon></button>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
        <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="70">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" class="filter-field" [formControl]="rateCodeFilterValue" #filter>
          <button mat-button *ngIf="rateCodeFilterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()" class="clear-filter">
          <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="25">
          <mat-select [disableOptionCentering]="true" placeholder="Status" [formControl]="statusFilter" #matSelect>
            <mat-option value="">All</mat-option>
            <mat-option value="PENDING">Pending Approval</mat-option>
            <mat-option value="APPROVED">Approved</mat-option>
            <mat-option value="ACTIVE">Active</mat-option>
            <mat-option value="INACTIVE">Inactive</mat-option>
            <mat-option value="DENIED">Denied</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <button *ngIf="exportable" aria-label="Export" id="export-commission-schedules" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
      <mat-menu #exportMenu="matMenu">
        <button mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Commission Schedules'))">Excel</button>
        <button mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Commission Schedules'))">CSV</button>
        <button mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Commission Schedules'))">JSON</button>
      </mat-menu>

      <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort class="mat-elevation-z0" (matSortChange)="handleSortChange()">
        <ng-container matColumnDef="rateCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Rate Code</mat-header-cell>
          <mat-cell *matCellDef="let sched" [matTooltip]="getTooltip(sched)" matTooltipClass="rate-tooltip">{{ sched.rateCode }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
          <mat-cell *matCellDef="let sched" [matTooltip]="getTooltip(sched)" matTooltipClass="rate-tooltip">{{ sched.status | titlecase }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="['rateCode', 'status']"></mat-header-row>
        <mat-row (click)="selectCommissionSchedule(sched)" *matRowDef="let sched; columns: ['rateCode', 'status']"></mat-row>
      </mat-table>
      <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50, 100]" (page)="handlePageChange()"></mat-paginator>
    </div>
  </mat-card>
</div>
