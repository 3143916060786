import { FormControl } from '@angular/forms';

export class BrokerSearchValidator {

  static BrokerSearchValidator(formControl: FormControl): {[key: string]: boolean} | null {
    const broker = formControl.value;

    if ( typeof broker === 'string' && broker.length > 0) {
      return { invalidBroker : true };
    }

  }
}
