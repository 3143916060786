import { gql } from 'apollo-angular';

export class TradeQueries {

  static getTradesByAccountsQuery = gql<any, any>`
  query TradesByAccounts($accounts: [String!], $tradeQuery: TradeQuery) {
    tradesByAccounts(accounts: $accounts, tradeQuery: $tradeQuery) {
      docId
      accountDocId
      accountNumber
      clearingFee
      clientDocId
      clientName
      commission
      commodityId
      contractYearMonth
      exchangeFee
      nfaFee
      officeCode
      price
      quantity
      salesCode
      securityDescription
      securitySubType
      securityType
      strikePrice
      side
      tradeDate
      transactionDate
      transactionRecordType
      openCloseCode
      spreadCode
      grossAmount
      otherCharges
      linkedCommissionAdjustmentDocId
      linkedCashAdjustmentDocId
    }
  }`;

  static getTradesByBrokerCodesQuery = gql<any, any>`
  query TradesByBrokerCodes($brokerCodes: [String!], $tradeQuery: TradeQuery) {
    tradesByBrokerCodes(brokerCodes: $brokerCodes, tradeQuery: $tradeQuery) {
      docId
      accountDocId
      accountNumber
      clearingFee
      clientDocId
      clientName
      commission
      commodityId
      contractYearMonth
      exchangeFee
      nfaFee
      officeCode
      price
      quantity
      salesCode
      securityDescription
      securitySubType
      securityType
      strikePrice
      side
      tradeDate
      transactionDate
      transactionRecordType
      openCloseCode
      spreadCode
      grossAmount
      otherCharges
      linkedCommissionAdjustmentDocId
      linkedCashAdjustmentDocId
    }
  }`;
}
