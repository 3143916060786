<ng-container [formGroup]="signersForm" *ngIf="contacts$ | async">
  <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
    <ng-container *ngIf="authorizedSigner && contact$ | async"></ng-container>
    <ati-labeled-data *ngIf="!editMode" fxFlex.xs="35" fxFlex.gt-xs="40" label="Authorized Signer" data="{{ displayFn(signersForm.get('contact').value) }}"></ati-labeled-data>
    <mat-form-field *ngIf="editMode" fxFlex.xs="35" fxFlex.gt-xs="40">
      <input type="text" matInput formControlName="contact" [matAutocomplete]="auto" placeholder="Authorized Signer">
      <mat-error *ngIf="signersForm.get('contact').invalid">{{ getErrorMessage(signersForm.get('contact')) }}</mat-error>
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let contact of filteredContacts$ | async" [value]="contact">{{ displayFn(contact) }}
      </mat-option>
    </mat-autocomplete>
    <ati-labeled-data *ngIf="!editMode" fxFlex.xs="35" fxFlex.gt-xs="40" label="Authorization Date " data="{{ signersForm.get('authorizationDate').value | date: 'MM/dd/yyyy' }}"></ati-labeled-data>
    <ati-date-picker *ngIf="editMode" [required]="true" fxFlex.xs="35" fxFlex.gt-xs="40"
      [dateControl]="signersForm.get('authorizationDate')" placeholder="Date Authorized"></ati-date-picker>
    <span fxFlex.xs="20" fxFlex.gt-xs="15">
      <div *ngIf="editMode">
        <button id="update-authorized-signer" type="button" aria-label="Update Authorized Signer" color="primary"
        [disabled]="signersForm.invalid || !editMode || signersForm.pristine" mat-icon-button
        (click)="onSignerUpdated()" matTooltip="Update Authorized Signer">
        <mat-icon>check_circle</mat-icon>
        </button>
        <button id="remove-authorized-signer" type="button" aria-label="Remove Authorized Signer" color="warn"
          [disabled]="!editMode" *ngIf="authorizedSigner?.authorizationDate" mat-icon-button (click)="onSignerRemoved()"
          matTooltip="Remove Authorized Signer">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </span>
  </div>
</ng-container>
