import { Margin, MarginType } from '@advance-trading/ops-data-lib';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { MarginService } from 'src/app/services/margin-service';

@Component({
  selector: 'atom-margin-detail',
  templateUrl: './margin-detail.component.html',
  styleUrls: ['./margin-detail.component.css']
})
export class MarginDetailComponent implements OnInit {

  isLoading = false;
  errorMessage: string;
  margin$: Observable<Margin>;

  private marginAccountDocId: string;
  private marginDocId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public marginService: MarginService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.margin$ = this.activatedRoute.paramMap.pipe(
      switchMap((paramMap: ParamMap) => {
        this.marginAccountDocId = paramMap.get('accountDocId');
        this.marginDocId = paramMap.get('marginDocId');
        return this.marginService.findMarginByDocId(this.marginAccountDocId, this.marginDocId);
      }),
      tap(val => this.isLoading = false)
    );
  }
}

