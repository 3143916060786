import { gql } from 'apollo-angular';

export class OrderQueries {

  static getOrdersByAccountsQuery = gql<any, any>`
  query getOrdersByAccounts($accounts: [String!], $orderQuery: OrderQuery) {
    ordersByAccounts(accounts: $accounts, orderQuery: $orderQuery) {
      docId
      status
      accountNumber
      accountDocId
      brokerCode
      side
      security
      symbol
      contractYearMonth
      quantity
      minimumQuantity
      unfilledQuantity
      price
      stopPrice
      strikePrice
      securityType
      securitySubType
      spreadStrategy
      type
      timeInForce
      expirationDate
      creationTimestamp
      creatorTraderId
      creatorName
      lastUpdatedTimestamp
      lastUpdatedByTraderId
      lastUpdatedByTraderName
      legs {
        security
        symbol
        contractYearMonth
        side
        ratioQuantity
        securityType
        securitySubType
        strikePrice
      }
      executionReports {
        price
        lastPrice
        security
        side
        stopPrice
        executionReportType
        lastQuantity
        leavesQuantity
        multiLegReportingType
        secondaryExecutionId
      }
    }
  }`;

  static getOrdersByBrokerCodesQuery = gql<any, any>`
  query getOrdersByBrokerCodes($brokerCodes: [String!], $orderQuery: OrderQuery) {
    ordersByBrokerCodes(brokerCodes: $brokerCodes, orderQuery: $orderQuery) {
      docId
      status
      accountNumber
      accountDocId
      brokerCode
      side
      security
      symbol
      contractYearMonth
      quantity
      minimumQuantity
      unfilledQuantity
      price
      stopPrice
      strikePrice
      securityType
      securitySubType
      spreadStrategy
      type
      timeInForce
      expirationDate
      creationTimestamp
      creatorTraderId
      creatorName
      lastUpdatedTimestamp
      lastUpdatedByTraderId
      lastUpdatedByTraderName
      legs {
        security
        symbol
        contractYearMonth
        side
        ratioQuantity
        securityType
        securitySubType
        strikePrice
      }
      executionReports {
        price
        lastPrice
        security
        side
        stopPrice
        executionReportType
        lastQuantity
        leavesQuantity
        multiLegReportingType
        secondaryExecutionId
      }
    }
  }`;
}
