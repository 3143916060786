import { AuthService } from '@advance-trading/angular-ati-security';
import { Account, Adjustment, AccountPurpose } from '@advance-trading/ops-data-lib';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { AdjustmentSearchCriteria } from './service-interfaces/adjustment-search-interface';
import * as moment from 'moment';
import { Apollo } from 'apollo-angular';
import { AdjustmentQueries } from './graphql-queries/adjustment-queries';
import { HttpHeaders } from '@angular/common/http';
import { AccountService } from './account-service';

@Injectable({
  providedIn: 'root'
})
export class AdjustmentService {

  constructor(
    private apollo: Apollo,
    private authService: AuthService,
    private db: AngularFirestore,
    private accountService: AccountService
  ) { }

  getAdjustmentByDocId(accountDocId: string, adjustmentDocId: string): Observable<Adjustment> {
    return this.db.doc<Adjustment>(`${Account.getDataPath(accountDocId)}/${Adjustment.getDataPath(adjustmentDocId)}`)
      .valueChanges(shareReplay({ bufferSize: 1, refCount: true }));
  }

  getAdjustmentsByTrade(accountDocId: string, tradeDocId): Observable<Adjustment[]> {
    return this.db.collection<Adjustment>(`${Account.getDataPath(accountDocId)}/Adjustments`, ref => ref.where('linkedTradeDocIds', 'array-contains', tradeDocId))
      .get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Adjustment) as Adjustment[])
      );
  }

  getAdjustments(accountDocId: string, searchParams: AdjustmentSearchCriteria): Observable<Adjustment[]> {
    return this.db.collection<Adjustment>(`${Account.getDataPath(accountDocId)}/Adjustments`)
      .get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Adjustment) as Adjustment[])
      ).pipe(
        map(adjustments => {
          if (searchParams.searchAssigned && !searchParams.searchUnassigned) {
            adjustments = adjustments.filter(adjustment => adjustment.linkedTradeDocIds.length > 0);
          } else if (searchParams.searchUnassigned && !searchParams.searchAssigned) {
            adjustments = adjustments.filter(adjustment => adjustment.linkedTradeDocIds.length === 0);
          }

          if (searchParams.startTradeDate) {
            adjustments = adjustments.filter(adjustment =>
              moment(adjustment.businessDate).isSameOrAfter(moment(searchParams.startTradeDate)));
          }

          if (searchParams.endTradeDate) {
            adjustments = adjustments.filter(adjustment =>
              moment(adjustment.businessDate).isSameOrBefore(moment(searchParams.endTradeDate)));
          }

          if (searchParams.startBusinessDate) {
            adjustments = adjustments.filter(adjustment =>
              moment(adjustment.transactionDate).isSameOrAfter(moment(searchParams.startBusinessDate)));
          }

          if (searchParams.endBusinessDate) {
            adjustments = adjustments.filter(adjustment =>
              moment(adjustment.transactionDate).isSameOrBefore(moment(searchParams.endBusinessDate)));
          }
          return adjustments;
        })
      );
  }

  updateAdjustment(accountDocId: string, adjustment: Adjustment): Promise<void> {
    adjustment.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.db.doc(Account.getDataPath(accountDocId)).collection(Adjustment.getDataPath())
      .doc<Adjustment>(adjustment.docId).update(adjustment);
  }

  // GraphQL Queries

  /**
   * Gets all of the Adjustments for given accounts or accounts associated with a margin group account and search parameters
   * @param accountNumbers The Account numbers of the Accounts containing Adjustments
   * @param gqlParams an interface populated with search criteria
   */
  getAdjustmentsyByAccountNumbers(accountNumbers: string[], gqlParams: any): Observable<Adjustment[]> {
    if (accountNumbers.length === 0) {
      return this.searchAdjustmentsbyAccountQuery(accountNumbers, gqlParams);
    }
    return combineLatest(
      accountNumbers.map((accountNumber) => {
        return this.accountService.retrieveActiveAccountsByNumber(accountNumber);
      })
    ).pipe(map((accounts) => accounts.flat()))
      .pipe(switchMap((accounts) => {
        if (accounts.length === 0) {
          return of([]);
        }
        const observableAccounts = [];
        accounts.forEach((account: Account) => {
          // For margin group accounts fetch all accounts that are associated with the margin group account
          if (account.purpose === AccountPurpose.MARGIN_GROUP) {
            observableAccounts.push(this.accountService.retrieveAccountsByMarginGroupAccountDocId(account.marginGroupAccountDocId));
          }
        });

        if (observableAccounts.length) {
          return combineLatest(observableAccounts).pipe(
            map(accts => accts.flat()),
            switchMap((accts: Account[]) => {

              // Remove duplicate accounts before getting adjustments for each account
              const duplicates = [];
              accts = accts.filter(acct => duplicates.includes(acct.docId) ? false : duplicates.push(acct.docId));
              const acctNumbers = accts.map((account: Account): string => account.number);
              return this.searchAdjustmentsbyAccountQuery(acctNumbers, gqlParams);
            })
          );
        }
        accountNumbers = accounts.map(account => account.number);
        return this.searchAdjustmentsbyAccountQuery(accountNumbers, gqlParams);
      }));
  }

  /**
   * Gets all of the Adjustments for given accounts and search parameters by a graphql query
   * @param accounts The Account numbers of the Accounts containing Adjustments
   */
  private searchAdjustmentsbyAccountQuery(accounts: string[], searchParams: AdjustmentSearchCriteria): Observable<Adjustment[]> {
    const adjustmentQuery = {
      businessDateStart: searchParams.startBusinessDate,
      businessDateEnd: searchParams.endBusinessDate,
      tradeDateStart: searchParams.startTradeDate,
      tradeDateEnd: searchParams.endTradeDate
    };
    return this.apollo.query({
      query: AdjustmentQueries.getAdjustmentsByAccounts,
      variables: {
        accounts,
        adjustmentQuery
      },
      context: {
        withCredentials: true,
        headers: new HttpHeaders({ Authorization: `Bearer ${this.authService.accessToken}` })
      }
    }).pipe(
      map(results => {
        let adjustments = results.data.adjustmentsByAccounts as Adjustment[];
        if (searchParams.searchAssigned && !searchParams.searchUnassigned) {
          adjustments = adjustments.filter(adjustment => adjustment.linkedTradeDocIds.length > 0);
        } else if (searchParams.searchUnassigned && !searchParams.searchAssigned) {
          adjustments = adjustments.filter(adjustment => adjustment.linkedTradeDocIds.length === 0);
        }
        return adjustments;
      })
    );
 }

 /**
  * Gets all Adjustments for the given broker codes and search parameters
  * @param brokerCodes The combined office codes and sales codes associated with the Adjustments
  */
 searchAdjustmentsByBrokerCode(brokerCodes: string[], searchParams: AdjustmentSearchCriteria): Observable<Adjustment[]> {
  const adjustmentQuery = {
    businessDateStart: searchParams.startBusinessDate,
    businessDateEnd: searchParams.endBusinessDate,
    tradeDateStart: searchParams.startTradeDate,
    tradeDateEnd: searchParams.endTradeDate
  };
  return this.apollo.query({
    query: AdjustmentQueries.getAdjustmentsByBrokerCodes,
    variables: {
      brokerCodes,
      adjustmentQuery
    },
    context: {
      withCredentials: true,
      headers: new HttpHeaders({ Authorization: `Bearer ${this.authService.accessToken}` })
    }
  }).pipe(
    map(results => {
      let adjustments = results.data.adjustmentsByBrokerCodes as Adjustment[];
      if (searchParams.searchAssigned && !searchParams.searchUnassigned) {
        adjustments = adjustments.filter(adjustment => adjustment.linkedTradeDocIds.length > 0);
      } else if (searchParams.searchUnassigned && !searchParams.searchAssigned) {
        adjustments = adjustments.filter(adjustment => adjustment.linkedTradeDocIds.length === 0);
      }
      return adjustments;
    })
  );
 }
}
