<div fxFlex="100">

  <div *ngIf="!errorMessage" fxLayout="column">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="margins-filter-field" #filter>
      <button mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <button *ngIf="selectedMargins$ | async" aria-label="Export" id="export-margins" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
    <mat-menu #exportMenu="matMenu">
      <button mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Margins'))">Excel</button>
      <button mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Margins'))">CSV</button>
      <button mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Margins'))">JSON</button>
    </mat-menu>

    <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort matSortActive="processDate" matSortDirection="desc" class="mat-elevation-z0" (matSortChange)="handleSortChange()">

      <!-- Business Date -->
      <ng-container matColumnDef="processDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="10">Business Date</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="10"><span class="forced-wrap">{{ margin.processDate | date: 'MM/dd/yy' }}</span></mat-cell>
      </ng-container>

      <!-- Account Number -->
      <ng-container matColumnDef="accountNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="5">Account</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="5">{{ margin.accountNumber ? margin.accountNumber : margin['account'] }}</mat-cell>
      </ng-container>

      <!-- Money Account Number -->
      <ng-container matColumnDef="moneyAccount">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="5">Cash Account</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="5">{{ margin.moneyAccount }}</mat-cell>
      </ng-container>

      <!-- Client Name -->
      <ng-container matColumnDef="clientName">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="10">Name</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="10">{{ margin.clientName }}</mat-cell>
      </ng-container>

      <!-- Broker Code -->
      <ng-container matColumnDef="brokerCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="6">Broker Code</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="6">{{ margin.officeCode + margin.salesCode }}</mat-cell>
      </ng-container>

      <!-- security balance -->
      <ng-container matColumnDef="longMarketValue">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="5">Security Balance</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="5">{{ margin.longMarketValue | number: '1.2' }}</mat-cell>
      </ng-container>

      <!-- Open Trade Equity -->
      <ng-container matColumnDef="openTradeEquity">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="7">Open Trade Equity</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="7">{{ margin.openTradeEquity | number: '1.2' }}</mat-cell>
      </ng-container>

      <!-- Total Equity -->
      <ng-container matColumnDef="totalEquity">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="8">Total Equity</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="8">{{ margin.totalEquity | number: '1.2' }}</mat-cell>
      </ng-container>

      <!-- Net Liq Value -->
      <ng-container matColumnDef="netLiq">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="8">Net Liq Value</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="8">{{ margin.netLiq | number: '1.2' }}</mat-cell>
      </ng-container>

      <!-- Margin Exc Def -->
      <ng-container matColumnDef="excessDeficit">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="8">Margin Excess Deficit</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="8">{{ margin.excessDeficit | number: '1.2' }}</mat-cell>
      </ng-container>

      <!-- Debit Age -->
      <ng-container matColumnDef="debitAge">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="4">Debit Age</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="4">{{ margin.marginCallRecordsCount ? margin.marginCallRecordsCount : 0 }}</mat-cell>
      </ng-container>

      <!-- Total Call -->
      <ng-container matColumnDef="totalCall">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="8">Total Call</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="8">{{ margin.totalCall | number: '1.2' }}</mat-cell>
      </ng-container>

      <!-- owed -->
      <ng-container matColumnDef="owed">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="8">Owed</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="8">{{ marginService.calculateOwed(margin) | number: '1.2' }}</mat-cell>
      </ng-container>

      <!-- Age -->
      <ng-container matColumnDef="marginCallAge">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="3">Age</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="3">{{ margin.marginCallAge }}</mat-cell>
      </ng-container>

      <!-- Margin Type -->
      <ng-container matColumnDef="marginType">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="8">Margin Type</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="8">{{ margin.marginType | titlecase }}</mat-cell>
      </ng-container>

      <!-- Funds Type -->
      <ng-container matColumnDef="fundsTransferType">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="7">Funds Type</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="7">{{ displayFundsType(margin.fundsTransferType) }}</mat-cell>
      </ng-container>

      <!-- Withdrawal -->
      <ng-container matColumnDef="withdrawable">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="7">Withdrawal</mat-header-cell>
        <mat-cell *matCellDef="let margin" fxFlex.gt-md="7">{{ margin.withdrawable | number: '1.2' }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let margin; columns: columnsToDisplay" (click)="selectMargin(margin)"></mat-row>
    </mat-table>
    <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50, 100]" (page)="handlePageChange()"></mat-paginator>
  </div>
</div>

