import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { ENTER } from '@angular/cdk/keycodes';

import { Observable, of, Subject, Subscription } from 'rxjs';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ClientFcmService, ContactService } from '@advance-trading/angular-ops-data';
import { ClientService } from '../../services/client-service';
import { Client, ClientFCM, Contact, FCM, FCMAuthorizedSigner } from '@advance-trading/ops-data-lib';


// Security roles
const UPDATE_ROLE = 'AccountAdmin';

@Component({
  selector: 'atom-fcm-setup',
  templateUrl: './fcm-setup.component.html',
  styleUrls: ['./fcm-setup.component.css']
})
export class FcmSetupComponent implements OnDestroy, OnInit, OnDestroy {

  clientFcmForm = new FormGroup({
    fcm: new FormControl('', [Validators.required]),
    financialsOnFile: new FormControl(undefined, [Validators.required]),
    financialsDate: new FormControl(''),
    securityAgreementOnFile: new FormControl(undefined, [Validators.required]),
    securityAgreementBank: new FormControl(''),
    securityAgreementDate: new FormControl(''),
    arbitrationSigned: new FormControl(undefined, [Validators.required]),
    copyOfCheckOnFile: new FormControl(undefined, [Validators.required]),
    achWireInstructionOnFile: new FormControl(undefined, [Validators.required]),
    copyOfLicenseOnFile: new FormControl(undefined, [Validators.required]),
    additionalRiskDisclosuresOnFile: new FormControl(undefined, [Validators.required]),
    authorizedTradersOnFile: new FormControl(undefined, [Validators.required]),
    authorizedTraders: new FormControl('')
  });

  private allFcms = [
    FCM.RCG,
    FCM.RJO,
    FCM.EDF_MAN,
    FCM.STRAITS,
    FCM.CUNNINGHAM
  ];

  fcms = [];

  client$: Observable<Client>;
  clientFcm$: Observable<ClientFCM>;
  clientDocId: string;
  private clientFcm: ClientFCM; // Used to store original from Firestore
  allClientFcms: ClientFCM[] = [];
  authorizedSigners: FCMAuthorizedSigner[] = [];
  originalSigners: FCMAuthorizedSigner[] = []; // master copy to revert uncommitted changes
  authorizedTraderIds: string[] = [];
  authorizedTradersInOtherFcms: string[] = [];
  authorizedSignersInOtherFcms: string[] = [];
  contacts$: Observable<Contact[]>;
  filteredContacts$: Observable<Contact[]>;
  addOnBlur = false;
  separatorKeyCodes: number[] = [ENTER];
  authorizedTraders: Contact[] = [];
  private updatedAuthorizations: Contact[] = [];

  private contacts: Contact[];
  private originalTraderIds: string[]; // master copy to revert uncommitted changes
  private originalTraders: Contact[]; // master copy to revert uncommitted changes
  private traderSub: Subscription;
  private unsubscribe$: Subject<void> = new Subject<void>();

  errorMessage: string;
  updateComplete = true;
  editMode = false;
  createMode = false;
  canUpdate = false;

  @ViewChild('traderauto', { static: false }) private traderMatAuto: MatAutocomplete;
  @ViewChild('traderInput', { static: false }) private traderInput: ElementRef<HTMLInputElement>;

  constructor(
    public snackBar: MatSnackBar,
    private clientService: ClientService,
    private clientFcmService: ClientFcmService,
    private contactService: ContactService,
    private auth0Service: Auth0AuthzService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.canUpdate = this.auth0Service.currentUserHasRole(UPDATE_ROLE);

    this.route.paramMap.pipe(takeUntil(this.unsubscribe$)).subscribe((params: ParamMap) => {
      this.editMode = !params.has('fcmDocId') || params.get('fcmDocId') === 'new';
      this.createMode = !params.has('fcmDocId') || params.get('fcmDocId') === 'new';
      if (params.get('fcmDocId') !== 'new') {
        this.clientFcmForm.disable();
      }

      if (params.get('fcmDocId') === 'new' && !this.canUpdate) {
        this.errorMessage = 'You do not have permission to create a new Clearing Firm Setup for this Client.';
      }
    });

    this.client$ = this.route.paramMap.pipe(
      filter((params: ParamMap) => params.has('clientDocId')),
      switchMap((params: ParamMap) => {
        this.clientDocId = params.get('clientDocId');
        return this.clientService.getClient(params.get('clientDocId'));
      })
    );

    this.clientFcm$ = this.route.paramMap.pipe(
      filter((params: ParamMap) => params.has('clientDocId')),
      switchMap((params: ParamMap) => {
        this.clientDocId = params.get('clientDocId');
        // Check for existing clientFCMs to ensure they can't select same fcm again
        return this.clientFcmService.getAllClientFcmsByClientDocId(params.get('clientDocId'));
      }),
      switchMap(allClientFcms => {
        this.allClientFcms = allClientFcms;
        return this.route.paramMap;
      }),
      filter((params: ParamMap) => params.has('fcmDocId')),
      switchMap((params: ParamMap) => {
        if (params.get('fcmDocId') === 'new') {
          return of(new ClientFCM().getPlainObject());
        }
        return this.clientFcmService.getClientFcmByDocId(params.get('clientDocId'), params.get('fcmDocId'));
      }),
      tap(newClientFcm => {
        this.handleClientFcmObject(newClientFcm);
      })
    );

    this.contacts$ = this.route.paramMap.pipe(
      filter((params: ParamMap) => params.has('clientDocId')),
      switchMap((params: ParamMap) => {
        return this.contactService.getAllContactsByClientDocId(params.get('clientDocId'));
      }),
      tap(contacts => {
        this.contacts = contacts || [];
      })
    );

    this.filteredContacts$ = this.clientFcmForm.get('authorizedTraders').valueChanges
      .pipe(
        startWith<string | Contact>(''),
        filter(value => typeof value === 'string'),
        map((contactName: string) => {
          if (!this.contacts) {
            return [];
          } else if (contactName) {
            return this.contacts.filter(contact => {
              const searchValue = contactName.toLowerCase();
              const first = contact.firstName.toLowerCase();
              const last = contact.lastName.toLowerCase();

              const fullName = `${first} ${last}`;
              return fullName.includes(searchValue) &&
                !this.authorizedTraders.find((trader) => contact.docId === trader.docId);
            });
          } else {
            return this.contacts.filter(contact =>
              !this.authorizedTraders.find((trader) => contact.docId === trader.docId));
          }
        })
      );
  }

  ngOnDestroy() {
    if (this.traderSub) {
      this.traderSub.unsubscribe();
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setEditMode(mode) {
    this.editMode = mode;
    if (this.editMode) {
      this.clientFcmForm.get('authorizedTraders').setValue('');
      this.clientFcmForm.enable();
      this.originalSigners = this.authorizedSigners.slice();
    } else { // Abandon an update
      this.clientFcmForm.disable();
      this.clientFcmForm.reset(this.clientFcm);
      this.authorizedTraderIds = this.originalTraderIds;
      this.authorizedTraders = this.originalTraders;
    }
  }

  cancelForm() {
    // revert authorizedSigners list to original list
    this.authorizedSigners = this.originalSigners;
    this.setEditMode(false);
  }

  reset() {
    this.clientFcmForm.reset();
    this.authorizedSigners = [];
    this.authorizedTraderIds = [];
    this.authorizedTraders = [];
  }

  async saveForm() {
    const isNewClientFcm = this.createMode;
    const clientFcm = Object.assign(isNewClientFcm ? new ClientFCM() : {}, this.clientFcm, this.clientFcmForm.getRawValue());
    clientFcm.authorizedSigners = this.authorizedSigners;
    clientFcm.authorizedTraders = this.authorizedTraderIds;
    this.originalTraderIds = this.authorizedTraderIds;
    this.originalTraders = this.authorizedTraders;

    if (isNewClientFcm) {
      this.clientFcmService.createClientFcm(this.clientDocId, clientFcm)
        .then(response => {
          const promise = Promise.all(
            this.updatedAuthorizations.map((contact) => {
              return this.contactService.updateContact(this.clientDocId, contact);
            })
          );
          return promise;
        })
        .then((response) => {
          console.log('ClientFCM successfully created');
          this.updateComplete = true;
          this.router.navigate(['../', clientFcm.docId], { relativeTo: this.route, replaceUrl: true });
          this.openSnackBar('Clearing Firm Setup successfully created', 'DISMISS', true);
          this.updatedAuthorizations = [];
          return response;
        })
        .catch(err => {
          this.updateComplete = true;
          console.error('Clearing Firm Setup creation failed: ' + JSON.stringify(err));
          let errorMessage = '';
          switch (err.code) {
            case 'permission-denied':
              errorMessage = 'Insufficient permissions';
              break;
            default:
              errorMessage = 'Unknown error occurred';
          }
          this.openSnackBar('Clearing Firm Setup creation failed: ' + errorMessage, 'DISMISS', false);
        });
    } else {
      this.clientFcmService.updateClientFcm(this.clientDocId, clientFcm)
        .then(response => {
          const promise = Promise.all(
            this.updatedAuthorizations.map((contact) => {
              return this.contactService.updateContact(this.clientDocId, contact);
            })
          );
          return promise;
        })
        .then((response) => {
          console.log('ClientFCM successfully updated');
          this.updateComplete = true;
          this.setEditMode(false);
          this.openSnackBar('Clearing Firm Setup successfully updated', 'DISMISS', true);
          this.updatedAuthorizations = [];
          return response;
        })
        .catch(err => {
          this.updateComplete = true;
          console.error('Clearing Firm Setup update failed: ' + JSON.stringify(err));
          let errorMessage = '';
          switch (err.code) {
            case 'permission-denied':
              errorMessage = 'Insufficient permissions';
              break;
            default:
              errorMessage = 'Unknown error occurred';
          }
          this.openSnackBar('Clearing Firm Setup update failed: ' + errorMessage, 'DISMISS', false);
        });
    }
  }

  displayContact(contact: Contact) {
    if (contact) {
      return contact.firstName + ' ' + contact.lastName;
    }
    return '';
  }

  traderRemovable(contact: Contact) {
    const removable = this.authorizedTraders && this.authorizedTraders.filter(trader => trader.docId === contact.docId).length > 0;
    return this.editMode && removable;
  }

  addTrader(event: MatChipInputEvent) {
    let contact: Contact = this.clientFcmForm.get('authorizedTraders').value;
    if (contact) {
      const matOption = this.traderMatAuto.options.find(value => value.value.docId === contact.docId);
      contact = matOption ? matOption.value : undefined;
    }
    if (!contact) {
      return;
    }

    const index = this.alreadyAuthorized(contact.docId);

    if (!this.authorizedTradersInOtherFcms.includes(contact.docId) && index === -1) {
      contact.isAuthorizedTrader = true;
      this.updatedAuthorizations.push(contact);
    } else if (!this.authorizedTradersInOtherFcms.includes(contact.docId)) {
      this.updatedAuthorizations[index].isAuthorizedTrader = true;
    }

    this.authorizedTraderIds = this.authorizedTraderIds.filter(removeTrader => removeTrader !== contact.docId);
    this.authorizedTraderIds.push(contact.docId);
    this.authorizedTraders = this.authorizedTraders.filter(removeTrader => removeTrader.docId !== contact.docId);
    this.authorizedTraders.push(contact);
    // Reset the group value to ensure we get a dirty form
    this.traderInput.nativeElement.value = '';
    this.clientFcmForm.get('authorizedTraders').setValue('');
    // blur and focus on autocomplete to refresh list
    this.traderInput.nativeElement.blur();
    setTimeout(() => {
      this.traderInput.nativeElement.focus();
    });
  }

  removeTrader(contact: Contact) {
    this.authorizedTraders = this.authorizedTraders.filter(removeTrader => removeTrader.docId !== contact.docId);
    this.authorizedTraderIds = this.authorizedTraderIds.filter(removeTrader => removeTrader !== contact.docId);
    // Reset the group value to ensure we get a dirty form
    this.clientFcmForm.get('authorizedTraders').markAsDirty();
    this.clientFcmForm.get('authorizedTraders').setValue('');

    const index = this.alreadyAuthorized(contact.docId);

    if (!this.authorizedTradersInOtherFcms.includes(contact.docId) && index === -1) {
      contact.isAuthorizedTrader = false;
      this.updatedAuthorizations.push(contact);
    } else if (!this.authorizedTradersInOtherFcms.includes(contact.docId)) {
      this.updatedAuthorizations[index].isAuthorizedTrader = false;
    }
  }

  onSignerCreated(authorizedSigner: FCMAuthorizedSigner) {
    this.authorizedSigners.push(authorizedSigner);
    this.clientFcmForm.markAsDirty();

    if (!this.authorizedSignersInOtherFcms.includes(authorizedSigner.contactDocId)) {
      this.contactService.getContactByDocId(this.clientDocId, authorizedSigner.contactDocId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(contact => {
        const index = this.alreadyAuthorized(contact.docId);
        if (index === -1) {
          contact.isAuthorizedSigner = true;
          this.updatedAuthorizations.push(contact);
        } else {
          this.updatedAuthorizations[index].isAuthorizedSigner = true;
        }
      });
    }
  }

  onSignerRemoved(index: number) {
    const signer = this.authorizedSigners[index];
    const contactDocId = signer.contactDocId;

    if (!this.authorizedSignersInOtherFcms.includes(signer.contactDocId)) {
      this.contactService.getContactByDocId(this.clientDocId, contactDocId).subscribe(contact => {
        const authorizedIndex = this.alreadyAuthorized(contactDocId);
        if (authorizedIndex === -1) {
          contact.isAuthorizedSigner = false;
          this.updatedAuthorizations.push(contact);
        } else {
          this.updatedAuthorizations[authorizedIndex].isAuthorizedSigner = false;
        }
      });
    }

    this.authorizedSigners.splice(index, 1);
    this.clientFcmForm.markAsDirty();
  }

  getFilingsAtFcmDisplayText(value: string) {
    switch (value) {
      case 'YES':
        return 'Yes';
      case 'NO':
        return 'No';
      case 'NA':
      default:
        return '-';
    }
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private handleClientFcmObject(clientFcm: ClientFCM) {
    this.clientFcm = clientFcm;
    this.hideExistingFcms();
    this.clientFcmForm.patchValue(clientFcm);
    this.authorizedSigners = Array.from(clientFcm.authorizedSigners || []);
    this.authorizedTraderIds = Array.from(clientFcm.authorizedTraders || []);
    this.originalTraderIds = Array.from(clientFcm.authorizedTraders || []);
    this.authorizedTraders = this.getTraders(this.authorizedTraderIds);
    this.originalTraders = this.getTraders(this.originalTraderIds);
  }

  private getTraders(authorizedTraderIds: string[]) {
    const authorizedTraders = [];
    authorizedTraderIds.map(traderId => {
      this.traderSub = this.contactService.getContactByDocId(this.clientDocId, traderId).pipe(
        take(1),
        map(contact => {
          authorizedTraders.push(contact);
        })
      ).subscribe();
    });
    return authorizedTraders;
  }

  private hideExistingFcms() {
    const justFcms = this.allClientFcms.map(clientFcm => clientFcm.fcm);
    this.fcms = this.allFcms.filter(fcm => !justFcms.includes(fcm) || fcm === this.clientFcm.fcm);

    const mostFcms = this.allClientFcms.filter(fcm => !this.fcms.includes(fcm.fcm));
    for (const fcm of mostFcms) {
      if (fcm.authorizedTraders) {
        for (const trader of fcm.authorizedTraders) {
          this.authorizedTradersInOtherFcms.push(trader);
        }
      }

      if (fcm.authorizedSigners) {
        for (const signer of fcm.authorizedSigners) {
          this.authorizedSignersInOtherFcms.push(signer.contactDocId);
        }
      }
    }
  }

  private alreadyAuthorized(contactDocId: string) {
    return this.updatedAuthorizations.findIndex(contact => contact.docId === contactDocId);
  }
}
