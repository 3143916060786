import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { AccountService } from '../../../services/account-service';
import { Account, AccountPurpose, Adjustment } from '@advance-trading/ops-data-lib';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AdjustmentService } from '../../../services/adjustment-service';
import { AdjustmentSearchFormValidator } from './adjustments-form-validator';
import { MatCheckbox } from '@angular/material/checkbox';
import { AdjustmentSearchCriteria } from 'src/app/services/service-interfaces/adjustment-search-interface';
import { DefaultDatePicker } from '../../../utilities/defaultDatePicker';
import * as moment from 'moment';

const DEFAULT_END_DATE = new Date('12/31/2099').toISOString();
const DEFAULT_START_DATE = new Date('1/1/2000').toISOString();

@Component({
  selector: 'atom-adjustment-search',
  templateUrl: './adjustment-search.component.html',
  styleUrls: ['./adjustment-search.component.css']
})
export class AdjustmentSearchComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  assignmentStatuses = ['Assigned', 'Unassigned'];
  filteredBy = '';
  dataSource = new ObservableDataSource<Adjustment>();
  selectedAdjustments$: Observable<Adjustment[]>;
  isSearching = false;
  errorMessage: string;
  showData = false;
  tableState: { [key: string]: string | number } = {};
  formValidator = new AdjustmentSearchFormValidator();

  adjustmentsForm: FormGroup = this.formBuilder.group({
    brokerCodes: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.brokerCodesValidator()),
    accounts: this.formBuilder.control({ value: '', disabled: false, }, this.formValidator.accountsValidator()),
    startTradeDate: [''],
    endTradeDate: [''],
    startBusinessDate: [''],
    endBusinessDate: [''],
    minStrike: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.strikeValidator()),
    maxStrike: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.strikeValidator()),
    assignmentBoxes: [],
  });

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChildren('assignmentBoxes') assignmentBoxes: QueryList<MatCheckbox>;

  private queryParams: Params;
  private selectedAssignments = ['Unassigned'];
  private routeSubscription: Subscription;
  private lastBusinessDay = DefaultDatePicker.getLastBusinessDay();

  constructor(
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private adjustmentService: AdjustmentService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {

    if (this.activatedRoute.queryParams) {
      this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
        this.queryParams = Object.assign({}, params);
        this.adjustmentsForm.get('accounts').setValue(this.queryParams.accounts);
        this.adjustmentsForm.get('brokerCodes').setValue(this.queryParams.brokerCodes);
        this.adjustmentsForm.get('minStrike').setValue(this.queryParams.minStrike);
        this.adjustmentsForm.get('maxStrike').setValue(this.queryParams.maxStrike);
        if (this.queryParams.startTradeDate) {
          this.adjustmentsForm.get('startTradeDate').setValue(moment(this.queryParams.startTradeDate).toISOString());
        }
        if (this.queryParams.endTradeDate) {
          this.adjustmentsForm.get('endTradeDate').setValue(moment(this.queryParams.endTradeDate).toISOString());
        }
        if (this.queryParams.startBusinessDate) {
          this.adjustmentsForm.get('startBusinessDate').setValue(moment(this.queryParams.startBusinessDate).toISOString());
        } else {
          this.adjustmentsForm.get('startBusinessDate').setValue(this.lastBusinessDay);
        }
        if (this.queryParams.endBusinessDate) {
          this.adjustmentsForm.get('endBusinessDate').setValue(moment(this.queryParams.endBusinessDate).toISOString());
        } else {
          this.adjustmentsForm.get('endBusinessDate').setValue(this.lastBusinessDay);
        }

        if (this.queryParams.assignments) {
          this.selectedAssignments = this.queryParams.assignments.split(',');
          this.adjustmentsForm.get('assignmentBoxes').setValue(this.selectedAssignments);
        }

        if (Object.keys(params).length) {
          // Mark form as diry to reset button appears
          this.adjustmentsForm.markAsDirty();
          this.searchAdjustments();
        }
      }));
    }
  }

  ngAfterViewInit() {
    this.assignmentBoxes.forEach(assignmentBox => assignmentBox.checked = this.selectedAssignments.includes(assignmentBox.value));
    this.changeDetector.detectChanges();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  searchAdjustments(searchButtonClicked: boolean = false) {
    this.snackBar.dismiss();
    if (searchButtonClicked) {
      // clear initial table state if the user perform a new search
      this.clearQueryParams();
      this.tableState = {};
    } else {
      // set initial table state from query param if the user is back avigating from another page
      const sortDir = this.queryParams.sortDir;
      const sortColName = this.queryParams.sortColName;
      const pageSize = this.queryParams.pageSize;
      const pageIndex = this.queryParams.pageIndex;
      const filterValue = this.queryParams.filterValue;
      this.tableState = {
        sortDir,
        sortColName,
        pageSize,
        pageIndex,
        filterValue
      };
    }
    this.showData = false;
    this.changeDetector.detectChanges();

    this.selectedAdjustments$ = this.chooseQuery();
    this.showData = true;
  }

  onAssignmentChange(event) {
    if (event.checked) {
      this.selectedAssignments.push(event.source.value);
    } else {
      this.selectedAssignments = this.selectedAssignments.filter(assignment => assignment !== event.source.value);
    }
    this.adjustmentsForm.get('assignmentBoxes').setValue(this.selectedAssignments);
    if (this.selectedAssignments.length) {
      this.adjustmentsForm.markAsDirty();
    }
  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.changeDetector.detectChanges();
  }

  handleAdjustmentListError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  private chooseQuery(): Observable<Adjustment[]> {
    const accountValues = this.adjustmentsForm.get('accounts').value;
    const brokerCodeValues = this.adjustmentsForm.get('brokerCodes').value;
    let accountNumbers = [];
    let accountNumbersFiltered = [];
    let brokerCodes = [];
    let brokerCodesFiltered = [];
    let startTradeDate = this.adjustmentsForm.get('startTradeDate').value;
    let endTradeDate = this.adjustmentsForm.get('endTradeDate').value;
    let startBusinessDate = this.adjustmentsForm.get('startBusinessDate').value;
    let endBusinessDate = this.adjustmentsForm.get('endBusinessDate').value;

    this.queryParams = this.tableState as Params;

    if (startTradeDate) {
      startTradeDate= moment(startTradeDate).format('YYYY-MM-DD');
      this.queryParams.startTradeDate = startTradeDate;
    } else {
      startTradeDate = DEFAULT_START_DATE;
    }

    if (endTradeDate) {
      endTradeDate = moment(endTradeDate).format('YYYY-MM-DD');
      this.queryParams.endTradeDate = endTradeDate;
    } else {
      endTradeDate = DEFAULT_END_DATE;
    }

    if (startBusinessDate) {
      startBusinessDate = moment(startBusinessDate).format('YYYY-MM-DD');
    } else {
      startBusinessDate = DEFAULT_START_DATE;
    }
    this.queryParams.startBusinessDate = startBusinessDate;

    if (endBusinessDate) {
      endBusinessDate = moment(endBusinessDate).format('YYYY-MM-DD');
    } else {
      endBusinessDate = DEFAULT_END_DATE;
    }
    this.queryParams.endBusinessDate = endBusinessDate;

    if (accountValues) {
      accountNumbers = accountValues.split(',').map((accountNum: string) => {
        // allow accountNumbers with the officeCode prefixed
        accountNum = accountNum.length === 8 ? accountNum.substr(3) : accountNum;
        return accountNum.trim();
      });
      accountNumbersFiltered = Array.from(new Set(accountNumbers));
      this.queryParams.accounts = accountValues;

      this.filteredBy = `Account - ${accountNumbersFiltered.join(', ')}`;
    } else if (brokerCodeValues) {
      brokerCodes = brokerCodeValues.split(',').map(code => code.trim());
      brokerCodesFiltered = Array.from(new Set(brokerCodes));
      this.queryParams.brokerCodes = brokerCodeValues;

      this.filteredBy = `Broker Code - ${brokerCodesFiltered.join(', ')}`;
    }

    if (this.selectedAssignments.length > 0) {
      this.queryParams.assignments = this.selectedAssignments.join(',');
    }

    const adjustmentSearchCriteria: AdjustmentSearchCriteria = {
      startBusinessDate,
      endBusinessDate,
      startTradeDate,
      endTradeDate,
      searchAssigned: this.selectedAssignments.includes('Assigned'),
      searchUnassigned: this.selectedAssignments.includes('Unassigned')
    };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });

    let chosenAdjustments$: Observable<Adjustment[]>;
    if (accountValues) {
      chosenAdjustments$ = this.adjustmentService.getAdjustmentsyByAccountNumbers(accountNumbersFiltered, adjustmentSearchCriteria);
    } else if (brokerCodeValues) {
      chosenAdjustments$ = this.adjustmentService.searchAdjustmentsByBrokerCode(brokerCodesFiltered, adjustmentSearchCriteria);
    } else {
      chosenAdjustments$ = this.adjustmentService.getAdjustmentsyByAccountNumbers([], adjustmentSearchCriteria);
    }
    return chosenAdjustments$;
  }

  // Clears the value and disables broker code field and enables account field
  accountFieldClicked() {
    this.adjustmentsForm.get('brokerCodes').setValue('');
    this.adjustmentsForm.get('brokerCodes').disable();
    this.adjustmentsForm.get('accounts').enable();
  }

  // Clears the value and disables account field and enables broker code field
  brokerCodeFieldClicked() {
    this.adjustmentsForm.get('accounts').setValue('');
    this.adjustmentsForm.get('accounts').disable();
    this.adjustmentsForm.get('brokerCodes').enable();
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  reset() {
    this.filteredBy = '';
    this.adjustmentsForm.reset();
    this.adjustmentsForm.get('accounts').enable();
    this.adjustmentsForm.get('brokerCodes').enable();
    this.adjustmentsForm.get('startBusinessDate').setValue(this.lastBusinessDay);
    this.adjustmentsForm.get('endBusinessDate').setValue(this.lastBusinessDay);
    this.selectedAssignments = ['Unassigned'];
    this.assignmentBoxes.forEach(box => this.selectedAssignments.includes(box.value) ? box.checked = true : box.checked = false);
    this.showData = false;
  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }

  handleAdjustmentListChange(tableState: { [key: string]: string | number }) {
    if (tableState.sortDir && tableState.sortColName) {
      this.queryParams.sortDir = tableState.sortDir;
      this.queryParams.sortColName = tableState.sortColName;
    } else if (this.queryParams.sortDir && this.queryParams.sortColName) {
      // remove sorted direction and column in query para if there's no sort applied
      delete this.queryParams.sortDir;
      delete this.queryParams.sortColName;
    }
    if (tableState.pageSize) {
      this.queryParams.pageSize = tableState.pageSize;
    }
    if (tableState.pageIndex !== undefined) {
      this.queryParams.pageIndex = tableState.pageIndex;
    }

    if (tableState.filterValue) {
      this.queryParams.filterValue = tableState.filterValue;
    } else if (this.queryParams.filterValue) {
      // remove filter query param if there's no filter applied
      delete this.queryParams.filterValue;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }
}

