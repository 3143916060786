<div fxFlex="100">
  <div>
    <div *ngIf="!errorMessage" fxLayout="column">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="cashActivity-filter-field" #filter>
        <button mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <button *ngIf="exportable && selectedCashActivity$ | async" aria-label="Export" id="export-cashActivity" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
      <mat-menu #exportMenu="matMenu">
        <button mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Cash-Activity'))">Excel</button>
        <button mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Cash-Activity'))">CSV</button>
        <button mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Cash-Activity'))">JSON</button>
      </mat-menu>

    <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort matSortActive="businessDate" matSortDirection="desc" class="mat-elevation-z0" (matSortChange)="handleSortChange()">

      <ng-container matColumnDef="accountNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.gt-xs="15">Account</mat-header-cell>
        <mat-cell *matCellDef="let cashActivity" fxFlex.xs="33" fxFlex.gt-xs="15">{{ cashActivity.accountNumber }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="businessDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.gt-xs="15">Business Date </mat-header-cell>
        <mat-cell *matCellDef="let cashActivity" fxFlex.xs="33" fxFlex.gt-xs="15">{{ cashActivity.businessDate | date: 'shortDate' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="clientName">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.gt-xs="20">Client Name</mat-header-cell>
        <mat-cell *matCellDef="let cashActivity" fxFlex.xs="33" fxFlex.gt-xs="20">{{ cashActivity.clientName | titlecase }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="brokerCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="15">Broker Code</mat-header-cell>
        <mat-cell *matCellDef="let cashActivity" fxFlex.xs="30" fxFlex.gt-xs="15">{{ cashActivity.officeCode + cashActivity.salesCode }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="15">Amount</mat-header-cell>
        <mat-cell *matCellDef="let cashActivity" fxFlex.xs="30" fxFlex.gt-xs="15">{{cashActivity.amount | number : '1.2'}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="securityDescription">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="20">Description</mat-header-cell>
        <mat-cell *matCellDef="let cashActivity" fxFlex.xs="30" fxFlex.gt-xs="20">{{ cashActivity.securityDescription}}</mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row  *matRowDef="let cashActivity; columns: columnsToDisplay" (click)="selectCashActivity(cashActivity)"></mat-row>
    </mat-table>
    <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50, 100]" (page)="handlePageChange()"></mat-paginator>
  </div>
  </div>
</div>

