import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CommodityMap, SecurityType, Commodity, Side, OrderLeg, OrderStatus, TimeInForce, ExecutionReport } from '@advance-trading/ops-data-lib';

import { ObservableDataSource, StorageService } from '@advance-trading/angular-common-services';
import { ExportService } from '../services/export.service';
import { OperationsDataService, OrderFill, OrderLegFill } from '@advance-trading/angular-ops-data';
import { OrderLegDisplay } from '../utilities/order-fill-leg-display';

const maxRows = 4000;
const PAGE_SIZE_KEY = 'atom.ordersPageSize';

@Component({
  selector: 'atom-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css'],
  providers: [BreakpointObserver]
})
export class OrdersComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {

  @Input() initialTableState: { [key: string]: string | number };
  @Input() selectedOrders$: Observable<OrderLegDisplay[]>;
  @Input() selectedParameters: { [key: string]: any };

  columnsToDisplay = [];
  errorMessage: string;
  dataSource = new ObservableDataSource<OrderLegDisplay>();
  exportable = false;
  filterValue = new FormControl();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  @Output() orderListChange: EventEmitter<any> = new EventEmitter();
  @Output() orderSearchError: EventEmitter<string> = new EventEmitter();
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();

  private commodityMap: CommodityMap;
  private tableState: { [key: string]: string | number } = {};
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private changeDetector: ChangeDetectorRef,
    public exportService: ExportService,
    private operationsDataService: OperationsDataService,
    private router: Router,
    private snackBar: MatSnackBar,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.isSearching.emit(true);
    // setup listener for filter value changes
    this.filterValue.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((filter: string) => {
      if (filter) {
        this.tableState.filterValue = filter.trim();
        this.orderListChange.emit(this.tableState);
      } else if (this.tableState.filterValue) {
        delete this.tableState.filterValue;
        this.orderListChange.emit(this.tableState);
      }
    });

    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
    ]).pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.breakpoints[Breakpoints.XSmall]) {
          this.columnsToDisplay = [
            'accountNumber', 'spreadStrategy', 'creationTimestamp', 'lastUpdatedTimestamp'
          ];
        } else if (state.breakpoints[Breakpoints.Small]) {
          this.columnsToDisplay = [
            'accountNumber', 'docId', 'spreadStrategy', 'side', 'creationTimestamp', 'lastUpdatedTimestamp'
          ];
        } else if (state.breakpoints[Breakpoints.Medium]) {
          this.columnsToDisplay = [
            'accountNumber', 'docId', 'spreadStrategy', 'side', 'quantity', 'contractYearMonth', 'commodity', 'creationTimestamp', 'lastUpdatedTimestamp'
          ];
        } else {
          this.columnsToDisplay = [
            'accountNumber', 'brokerCode', 'docId', 'spreadStrategy', 'side', 'quantity', 'contractYearMonth',
            'strikePrice', 'commodity', 'securitySubType', 'price', 'fillPrice', 'type', 'timeInForce', 'status', 'creationTimestamp', 'lastUpdatedTimestamp'
          ];
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = this.storageService.localStorage.get(PAGE_SIZE_KEY).value() || 10;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (order, column) => {
      switch(column) {
        case 'commodity':
          return this.getCommodityDisplayName(order);
        default:
          return order[column];
      }
    };
    if (this.filter) {
      this.filter.nativeElement.focus();
    }
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialTableState'] && changes['selectedOrders$']) {
      this.tableState = Object.assign({}, this.initialTableState);

      // detect MatSort and MatPaginator so it is defined
      this.changeDetector.detectChanges();

      const sortDir = this.initialTableState.sortDir as SortDirection;
      const sortColName = this.initialTableState.sortColName as string;
      if (sortDir && sortColName) {
        this.sort.direction = sortDir;
        this.sort.active = sortColName;
      }
      if (this.initialTableState.filterValue) {
        this.filterValue.setValue(this.initialTableState.filterValue);
        this.applyFilter(this.filterValue.value);
      }
      // initialize table
      this.dataSource.data$ = this.operationsDataService.getCommodityMap().pipe(
        switchMap((doc: CommodityMap) => {
          this.commodityMap = doc;
          return this.selectedOrders$;
        }),
        map(orders => {
          if (orders.length === maxRows) {
            this.openSnackBar('Reached maximum number of rows', 'DISMISS', true);
          }
          const filteredOrders = [];
          orders.forEach(order => {
            if (order.legs.length > 0 || this.includeOutrightOrder(order)) {
              filteredOrders.push(order);
            }
          });
          return filteredOrders;
        }),
        map(orders => {
          return orders.map(order => {
            if (order.executionReports.length > 0) {
              const orderFills = this.getConsolidatedOrderFills(order.executionReports);
              if (orderFills.length >= 1) {
                // return an order object for each orderFill
                return orderFills.map(fill => {
                  // if the Fill has legs attach the leg fills to the order object
                  if (fill.legFills) {
                    const legFills = [];
                    const legFillKeys = Object.keys(fill.legFills);
                    legFillKeys.forEach(legFillKey => {
                      const matchingLeg = order.legs.find(leg =>
                        leg.security + leg.side === fill.legFills[legFillKey].security + fill.legFills[legFillKey].side);
                      if (matchingLeg) {
                        legFills.push({
                          symbol: matchingLeg.symbol,
                          security: fill.legFills[legFillKey].security,
                          quantity: fill.fillQuantity,
                          side: fill.legFills[legFillKey].side,
                          fillPrice: fill.legFills[legFillKey].fillPrice,
                        });
                      }
                    });
                    // spread order is filled so we return order and legFills
                    return {
                      ...order,
                      price: fill.targetPrice,
                      legFills
                    };
                  }
                  // outright order is filled so we return order and fill information
                  return {
                    ...order,
                    price: fill.targetPrice,
                    fillPrice: this.getDisplayPrice(fill.fillPrice, order.securityType, order.symbol),
                    fillQuantity: fill.fillQuantity,
                  };
                });
                // if the order is unfilled just return the order
              } else {
                return order;
              }
            }
            return order;
          }).flat();
        }),
        map(orders => {
          const orderLegs = [];
          orders.forEach(order => {
            // if the order has legs we break each leg into a separate object so they appear as their own rows
            if (order.legs && order.legs.length > 0) {
              order.legs.forEach(leg => {
                // if the spread order has fills we combine info with the leg and corresponding legFill
                  const matchingLegFill = order.legFills ? order.legFills.find(legFill =>
                    legFill.security + legFill.side === leg.security + leg.side): undefined;
                  const newOrder = {
                  ...order,
                  symbol: leg.symbol,
                  price: leg.side === order.side ? order.price : undefined,
                  legSide: leg.side,
                  contractYearMonth: leg.contractYearMonth,
                  fillQuantity: matchingLegFill ? (leg.ratioQuantity * matchingLegFill.quantity) : undefined,
                  securitySubType: leg.securitySubType,
                  strikePrice: leg.strikePrice,
                  security: leg.security,
                  securityType: leg.securityType,
                  fillPrice: matchingLegFill ? this.getDisplayPrice(matchingLegFill.fillPrice, leg.securityType, leg.symbol) : undefined
                  };
                  if (this.includeOrderLeg(newOrder)) {
                    orderLegs.push(newOrder);
                  }
              });
            }
          });
          return orders.filter(order => !order.spreadStrategy ? true : false).concat(orderLegs);
        }),
        tap(orders => {
          this.isSearching.emit(false);
          this.exportable = !!orders.length;

          // initialize pagination state when the datasource exist
          const pageIndex = this.initialTableState.pageIndex as number;
          if (pageIndex) {
            this.paginator.pageIndex = pageIndex;
          }
          if (orders.length === 0) {
            this.openSnackBar('There are no orders that match this search', 'DISMISS', true);
          }
        }),
        catchError(err => {
          this.errorMessage = 'Error retrieving orders; please try again later';
          this.orderSearchError.emit(this.errorMessage);
          this.isSearching.emit(false);
          console.error(`Error retrieving orders: ${err}`);
          return of([]);
        })
      );
    }
  }

  getLegFill(order: OrderLegDisplay, leg: OrderLeg) {
    if (order.fillBuyPrice && leg.side === 'BUY') {
      return order.fillBuyPrice;
    } else if (order.fillSellPrice && leg.side === 'SELL') {
      return order.fillSellPrice;
    } else {
      return order.fillPrice;
    }
  }

  displayTargetPrice(order: OrderLegDisplay) {
    return !(order.type === 'MARKET' && ((order.isSpread && order.side === order.legSide) || !order.isSpread));
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  selectOrder(order: OrderLegDisplay) {
    this.router.navigate(['/accounts', order.accountDocId, 'orders', order.docId]);
  }

  handleSortChange() {
    this.tableState.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.tableState.sortColName = this.tableState.sortDir ? this.sort.active : undefined;
    this.orderListChange.emit(this.tableState);
  }

  handlePageChange() {
    this.storageService.localStorage.set(PAGE_SIZE_KEY, this.paginator.pageSize);
    this.tableState.pageIndex = this.paginator.pageIndex;
    this.orderListChange.emit(this.tableState);
  }

  getDisplayPrice(price: number, securityType: string, symbol: string) {
    if (!price) {
      return price;
    }
    let priceDivisor;
    if (securityType === SecurityType.OPTION || securityType === SecurityType.UDS) {
      const commodity = Object.values(this.commodityMap.commodities).find(cmd => cmd.electronicOptionsSymbol === symbol);
      priceDivisor = commodity ? commodity.orderOptionPriceDivisor : 1;
    } else {
      priceDivisor = this.commodityMap.commodities[symbol] ? this.commodityMap.commodities[symbol].orderFuturePriceDivisor : 1;
    }
    return price / priceDivisor;
  }

  getDisplayStrike(price: number, order: OrderLegDisplay) {
    if (!price) {
      return undefined;
    }
    let priceDivisor;
    const commodity = Object.values(this.commodityMap.commodities).find(cmd =>
      cmd.electronicOptionsSymbol === order.symbol || cmd.electronicFuturesSymbol === order.symbol);
    priceDivisor = commodity ? commodity.strikePriceDivisor : 1;
    return price / priceDivisor;
  }

  getCommodityDisplayName(order: OrderLegDisplay) {
    let commodity: Commodity;
    if (order.securityType === SecurityType.FUTURE || order.securityType === SecurityType.FUTURE_SPREAD) {
      commodity = Object.values(this.commodityMap.commodities).find(cmd => cmd.electronicFuturesSymbol === order.symbol);
    } else if (order.securityType === SecurityType.OPTION || order.securityType === SecurityType.UDS) {
      commodity = Object.values(this.commodityMap.commodities).find(cmd => cmd.electronicOptionsSymbol === order.symbol);
    } else {
      commodity = Object.values(this.commodityMap.commodities).find(cmd => cmd.id === order.symbol);
    }
    if (commodity) {
      return commodity.name;
    } else {
      return '';
    }
  }

  getDisplayQuantity(order: OrderLegDisplay) {
    if (order.status === OrderStatus.FILLED || order.status === OrderStatus.PARTIALLY_FILLED) {
      return order.fillQuantity ? `${order.fillQuantity}/${order.quantity}` : order.quantity;
    } else {
      return order.quantity;
    }
  }

  getDisplayTIF(order: OrderLegDisplay) {
    if (order.timeInForce === TimeInForce.DAY) {
      return 'Day';
    } else if (order.timeInForce === TimeInForce.GTD) {
      return order.expirationDate;
    } else {
      return order.timeInForce;
    }
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  /**
   * Used to check if an outright orderLegDisplay matches the search parameters from the orderSearchComponent
   * @param order orderLegDisplay object that represents an outright order
   */
  private includeOutrightOrder(order: OrderLegDisplay) {
    const commodity =
      this.selectedParameters.symbol ? this.commodityMap.commodities[this.selectedParameters.symbol] : undefined;

    const symbols =
      this.selectedParameters.symbol ? [commodity.electronicFuturesSymbol, commodity.electronicOptionsSymbol, commodity.id] : undefined;

    // return false if the order monthYear does not match the parameter
    if (this.selectedParameters.contractMonthYear && order.contractYearMonth !== this.selectedParameters.contractMonthYear) {
      return false;
      // return false if the order symbol does not match the parameter
    } else if (this.selectedParameters.symbol && !symbols.includes(order.symbol)) {
      return false;
      // return false if the order subType is not in the parameters
    } else if ((this.selectedParameters.securitySubTypes
      && this.selectedParameters.securitySubTypes.split(',').length === 1
      && order.securityType === 'OPTION') && order.securitySubType !== this.selectedParameters.securitySubTypes) {
      return false;
      // return false if the order security Type is not in the parameters
    } else if (this.selectedParameters.securityTypes && !this.selectedParameters.securityTypes.split(',').includes(order.securityType)) {
      return false;
      // return false if the order strikePrice is less than the parameter strikePriceMin
    } else if (this.selectedParameters.strikePriceMin &&
      (this.getDisplayStrike(order.strikePrice, order) < this.selectedParameters.strikePriceMin || !order.strikePrice)) {
      return false;
      // return false if the order strikePrice is greater than the parameter strikePriceMax
    } else if (this.selectedParameters.strikePriceMax &&
      (this.getDisplayStrike(order.strikePrice, order) > this.selectedParameters.strikePriceMax || !order.strikePrice)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Used to check if an spread orderLegObject matches the search parameters from the orderSearchComponent
   * @param order orderLegDisplay object that represents an orderLeg
   */
  private includeOrderLeg(order: OrderLegDisplay) {

    const commodity =
      this.selectedParameters.symbol ? this.commodityMap.commodities[this.selectedParameters.symbol] : undefined;

    const symbols =
      this.selectedParameters.symbol ? [commodity.electronicFuturesSymbol, commodity.electronicOptionsSymbol, commodity.id] : undefined;

    // return false if the orderLeg monthYear does not match the parameter
    if (this.selectedParameters.contractMonthYear && order.contractYearMonth !== this.selectedParameters.contractMonthYear) {
      return false;
      // return false if the orderLeg symbol does not match the parameter
    } else if (this.selectedParameters.symbol && !symbols.includes(order.symbol)) {
      return false;
      // return false if orderLeg side does not match the search parameter and that side does not match the side of the overall order
    } else if (this.selectedParameters.side && (order.legSide !== this.selectedParameters.side || (order.legSide !== order.side))) {
      return false;
      // return false if the orderLeg subType is not in the parameters
    } else if ((this.selectedParameters.securitySubTypes
      && this.selectedParameters.securitySubTypes.split(',').length === 1
      && order.securityType === 'OPTION') && order.securitySubType !== this.selectedParameters.securitySubTypes) {
      return false;
      // return false if the orderLeg securityType is not in the parameters
    } else if (this.selectedParameters.securityTypes && !this.selectedParameters.securityTypes.split(',').includes(order.securityType)) {
      return false;
      // return false if the orderLeg status is not in the parameters
    } else if (this.selectedParameters.statuses && !this.selectedParameters.statuses.split(',').includes(order.status)) {
      return false;
      // return false if the orderLeg strikePrice is less than the parameter strikePriceMin
    } else if (this.selectedParameters.strikePriceMin &&
      (this.getDisplayStrike(order.strikePrice, order) < this.selectedParameters.strikePriceMin || !order.strikePrice)) {
      return false;
      // return false if the orderLeg strikePrice is greater than the parameter strikePriceMax
    } else if (this.selectedParameters.strikePriceMax &&
      (this.getDisplayStrike(order.strikePrice, order) > this.selectedParameters.strikePriceMax || !order.strikePrice)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * combines multiple execution reports that have been filled at the same price into one orderFill
   * @param filledReports orders execution reports
   */
  private getConsolidatedOrderFills(filledReports: ExecutionReport[]): OrderFill[] {
    const consolidatedOrderFills = [];
    const orderFills: { [key: string]: OrderFill } = {};
    filledReports.forEach((report: ExecutionReport) => {
      if (report.multiLegReportingType !== '2') {
        const key = report.secondaryExecutionId || report.docId;
        if (orderFills[key]) {
          orderFills[key].fillQuantity = report.lastQuantity;
          orderFills[key].fillPrice = report.lastPrice;
        } else {
          orderFills[key] = {
            fillQuantity: report.lastQuantity,
            targetPrice: report.price,
            fillPrice: report.lastPrice,
          } as OrderFill;
        }
        // multiLegReportingType === '2', skipping legs if no secondaryExecutionId for backward compatibility
      } else if (report.secondaryExecutionId) {
        const key = report.secondaryExecutionId;
        const orderLegFill = { security: report.security, side: report.side, fillPrice: report.lastPrice } as OrderLegFill;
        const orderLegKey: string = report.security + report.side;
        if (orderFills[key]) {
          if (orderFills[key].legFills) {
            orderFills[key].legFills[orderLegKey] = orderLegFill;
          } else {
            orderFills[key].legFills = {};
            orderFills[key].legFills[orderLegKey] = orderLegFill;
          }
        } else {
          const orderLegFills = {};
          orderLegFills[orderLegKey] = orderLegFill;
          orderFills[key] = {
            fillQuantity: report.lastQuantity,
            legFills: orderLegFills
          } as OrderFill;
        }
      }
    });

    Object.values(orderFills).forEach((orderFill: OrderFill) => {
      // Check if OrderFill exists for current price
      const fillIndex = consolidatedOrderFills.findIndex((fill: OrderFill) => this.isSameOrderFills(orderFill, fill));
      // Fill already exists for price, update fill quantity
      if (fillIndex !== -1) {
        consolidatedOrderFills[fillIndex].fillQuantity += orderFill.fillQuantity;
        // use the last consolidated fillTimestamp
        consolidatedOrderFills[fillIndex].fillTimestamp = orderFill.fillTimestamp;
        // Fill does not yet exist for price, create new OrderFill
      } else {
        if (orderFill.fillQuantity > 0) {
          consolidatedOrderFills.push({
            fillQuantity: orderFill.fillQuantity,
            targetPrice: orderFill.targetPrice,
            fillPrice: orderFill.fillPrice,
            legFills: orderFill.legFills
          } as OrderFill);
        }
      }
    });
    return consolidatedOrderFills;
  }

  private isSameOrderFills(orderFill1: OrderFill, orderFill2: OrderFill): boolean {
    const isSameOrderFillPrice = orderFill1.fillPrice === orderFill2.fillPrice;

    if (orderFill1.legFills) {
      let isSameLegFills = true;
      Object.keys(orderFill1.legFills).forEach((orderLegKey: string) => {
        if (!orderFill2.legFills[orderLegKey] ||
          orderFill1.legFills[orderLegKey].fillPrice !== orderFill2.legFills[orderLegKey].fillPrice) {
          isSameLegFills = false;
        }
      });
      return isSameLegFills && isSameOrderFillPrice;
    } else {
      return isSameOrderFillPrice;
    }
  }
}
