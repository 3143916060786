<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Client Details</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title" *ngIf="createMode">New Client</h2>
      <h2 class="card-title" *ngIf="!createMode">{{ (client$ | async)?.name }}</h2>
      <ng-container *ngIf="accounts$ | async"></ng-container>
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <button aria-label="Edit Client" *ngIf="!editMode && !createMode && updateComplete && canUpdateClient" (click)="setEditMode(true)" mat-mini-fab color="accent" matTooltip="Edit Client">
          <mat-icon>edit</mat-icon>
        </button>
        <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
        <button aria-label="Update Client" *ngIf="!createMode && editMode && updateComplete" [disabled]="!clientForm.valid || clientForm.pristine" (click)="saveForm()" mat-mini-fab color="accent" id="client-detail-save" matTooltip="Update Client">
          <mat-icon>done</mat-icon>
        </button>
        <button aria-label="Create Client" *ngIf="createMode && canUpdateClient && updateComplete" [disabled]="!clientForm.valid" (click)="saveForm()" mat-mini-fab color="accent" matTooltip="Create Client" id="client-detail-save">
          <mat-icon>done</mat-icon>
        </button>
        <button aria-label="Discard Changes" *ngIf="updateComplete && editMode && !createMode" (click)="setEditMode(false)" mat-mini-fab color="" id="client-detail-clear" matTooltip="Cancel Changes">
          <mat-icon>clear</mat-icon>
        </button>
        <button aria-label="Discard Changes" *ngIf="createMode && !clientForm.pristine && updateComplete" (click)="reset()" mat-mini-fab color="" id="client-detail-clear" matTooltip="Reset Form">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <form *ngIf="!errorMessage" [formGroup]="clientForm" (ngSubmit)="saveForm()" fxLayout="column">
      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="75" fxFlex.gt-xs="30" label="Name" data="{{ clientForm.get('name').value }}"></ati-labeled-data>
        <mat-form-field *ngIf="editMode" fxFlex.xs="75" fxFlex.gt-xs="30">
          <input required matInput placeholder="Name" formControlName="name" trim="blur">
          <mat-error *ngIf="clientForm.get('name').invalid">{{getErrorMessage(clientForm.get('name'))}}</mat-error>
        </mat-form-field>
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="75" fxFlex.gt-xs="30" label="DBA" data="{{ clientForm.get('dba').value }}"></ati-labeled-data>
        <mat-form-field *ngIf="editMode" fxFlex.xs="75" fxFlex.gt-xs="30">
          <input matInput placeholder="DBA" formControlName="dba" trim="blur">
          <mat-error *ngIf="clientForm.get('dba').invalid">{{getErrorMessage(clientForm.get('dba'))}}</mat-error>
        </mat-form-field>
        <div class="ssn-outer-container" *ngIf="!editMode && canViewSSN" fxFlex.xs="50" fxFlex.gt-xs="30">
          SSN/TIN
          <div class="ssn-inner-container">
            <div *ngIf="this.ssn$; else revealIcon" fxLayout="row" fxLayoutAlign="flex-start center">
              <ng-container *ngIf="(this.ssn$ | async) as ssn; else loadingSSN">
                <span [ngClass]="!ssn.value && 'emptyFieldText'">
                  {{ ssn.value || '(empty)' }}
                </span>
                &nbsp; <mat-icon (click)="hideSSN()" class="reveal-icon" matTooltip="Hide">visibility_off</mat-icon>
              </ng-container>
            </div>
            <ng-template #revealIcon>
              <div fxLayout="row" fxLayoutAlign="flex-start center">
                <div class="ssn-mask">*********</div>
                <mat-icon (click)="revealSSN()" class="reveal-icon" matTooltip="Reveal">visibility</mat-icon>
              </div>
            </ng-template>
            <ng-template #loadingSSN>
              <span class="ssn-loading-text">Verifying user</span> &nbsp; <mat-spinner diameter="15"></mat-spinner>
            </ng-template>
          </div>
        </div>
        <div *ngIf="editMode && canEditSSN" fxLayout="row" fxLayoutAlign="flex-start center" fxFlex.xs="50" fxFlex.gt-xs="30">
          <mat-form-field class="ssn-field">
            <input matInput placeholder="SSN/TIN" formControlName="ssn" class="ssn-field" (keypress)="handleSSNKeypress($event)">
            <button mat-button *ngIf="clientForm.get('ssn').enabled" matSuffix mat-icon-button aria-label="Cancel Edit" (click)="disableSSN()">
              <mat-icon class="reveal-icon" matTooltip="Cancel Edit">cancel</mat-icon>
            </button>
            <button mat-button *ngIf="clientForm.get('ssn').disabled" matSuffix mat-icon-button aria-label="Edit SSN" (click)="enableSSN()">
              <mat-icon class="reveal-icon" matTooltip="Edit SSN">edit</mat-icon>
            </button>
            <mat-error *ngIf="clientForm.get('ssn').invalid">{{getErrorMessage(clientForm.get('ssn'))}}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="50" fxFlex.gt-xs="30" label="Type" data="{{ clientForm.get('type').value | titlecase }}"></ati-labeled-data>
        <mat-radio-group *ngIf="editMode" required formControlName="type" fxFlex.xs="100" fxFlex.gt-xs="30">
          <span class="check-radio-label">Type</span>
          <mat-radio-button *ngFor="let clientType of clientTypes" [value]="clientType">
            {{(clientType.length > 3) ? (clientType | titlecase) : clientType}}
          </mat-radio-button>
        </mat-radio-group>
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="50" fxFlex.gt-xs="30" label="Margin Type" data="{{ clientForm.get('marginType').value | titlecase }}"></ati-labeled-data>
        <mat-radio-group *ngIf="editMode" required formControlName="marginType" fxFlex.xs="100" fxFlex.gt-xs="30">
          <span class="check-radio-label">Margin Type</span>
          <mat-radio-button *ngFor="let marginType of marginTypes" [value]="marginType">
            {{marginType | titlecase}}
          </mat-radio-button>
        </mat-radio-group>
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="75" fxFlex.gt-xs="30" label="Incorporation Type" data="{{ getIncorporationTypeDisplayText(clientForm.get('incorporationType').value) }}"></ati-labeled-data>
        <mat-form-field *ngIf="editMode" fxFlex.xs="75" fxFlex.gt-xs="30">
          <mat-select [disableOptionCentering]="true" required placeholder="Incorporation Type" formControlName="incorporationType">
            <mat-option *ngFor="let incType of incTypes" [value]="incType">
              {{(incType.length > 3) ? (incType | replace | titlecase) : incType}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="50" fxFlex.gt-xs="30" label="Status" data="{{ clientForm.get('status').value | titlecase }}"></ati-labeled-data>
        <mat-radio-group *ngIf="editMode" required formControlName="status" fxFlex.xs="50" fxFlex.gt-xs="30">
          <span class="check-radio-label">Status</span>
          <mat-radio-button value="ACTIVE">Active</mat-radio-button>
          <mat-radio-button value="INACTIVE">Inactive</mat-radio-button>
        </mat-radio-group>
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="50" fxFlex.gt-xs="30" label="Date Acquired" data="{{ clientForm.get('acquisitionDate').value | date: 'M/d/yyyy' }}"></ati-labeled-data>
        <ati-date-picker *ngIf="editMode" [required]="true" fxFlex.xs="45" fxFlex.gt-xs="30" [dateControl]="clientForm.get('acquisitionDate')" placeholder="Date Acquired"></ati-date-picker>
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="50" fxFlex.gt-xs="30" label="Date Inactivated" data="{{ clientForm.get('inactivationDate').value | date: 'M/d/yyyy' }}"></ati-labeled-data>
        <ati-date-picker *ngIf="editMode" [required]="this.clientForm.get('status').value === 'INACTIVE'" fxFlex.xs="45" fxFlex.gt-xs="30" [dateControl]="clientForm.get('inactivationDate')" placeholder="Date Inactivated"></ati-date-picker>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="50" fxFlex.gt-xs="30" label="Future Commssion" data="{{ getCommissionDisplayText(clientForm.get('futuresCommissionType').value) }}"></ati-labeled-data>
        <mat-radio-group *ngIf="editMode" required formControlName="futuresCommissionType" fxFlex.xs="50" fxFlex.gt-xs="30">
          <span class="check-radio-label">Futures Commission</span>
          <mat-radio-button value="HALF_TURN">Half Turn</mat-radio-button>
          <mat-radio-button value="ROUND_TURN">Round Turn</mat-radio-button>
        </mat-radio-group>
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="50" fxFlex.gt-xs="30" label="Options Commission" data="{{ getCommissionDisplayText(clientForm.get('optionsCommissionType').value) }}"></ati-labeled-data>
        <mat-radio-group *ngIf="editMode" required formControlName="optionsCommissionType" fxFlex.xs="50" fxFlex.gt-xs="30">
          <span class="check-radio-label">Options Commission</span>
          <mat-radio-button value="HALF_TURN">Half Turn</mat-radio-button>
          <mat-radio-button value="ROUND_TURN">Round Turn</mat-radio-button>
        </mat-radio-group>
        <div fxFlex.gt-xs="30"></div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
      </div>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>Mailing Address</mat-panel-title>
        </mat-expansion-panel-header>
        <ati-address [addressForm]="clientForm.get('mailingAddress')"></ati-address>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>Physical Address</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="expansion-panel-checkbox" *ngIf="editMode">
          <mat-checkbox class="mat-expansion-panel-body-checkbox" color="primary" [formControl]="isSameAddress">Same as Mailing Address</mat-checkbox>
        </div>
        <ati-address [addressForm]="clientForm.get('physicalAddress')"></ati-address>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>Phone Numbers</mat-panel-title>
        </mat-expansion-panel-header>
        <ati-phone-number *ngFor="let phoneNumber of phoneNumbers; let index = index" [editMode]="editMode" [phoneNumber]="phoneNumber" (numberUpdated)="phoneNumbers[index] = $event; clientForm.markAsDirty()" (numberRemoved)="onPhoneNumberRemoved(index)"></ati-phone-number>
        <ati-phone-number *ngIf="editMode" [editMode]="editMode" (numberUpdated)="onPhoneNumberCreated($event)"></ati-phone-number>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0" *ngIf="canViewATIOnlyData">
        <mat-expansion-panel-header>
          <mat-panel-title>ATI Use Only</mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <ati-labeled-data *ngIf="!editMode" fxFlex.xs="30" fxFlex.gt-xs="20" fxFlex.gt-md="15" label="Managing Pod" data="{{ getManagingPodDisplayText(clientForm.get('managingPod').value) }}"></ati-labeled-data>
          <mat-form-field *ngIf="editMode" fxFlex.xs="30" fxFlex.gt-xs="20" fxFlex.gt-md="10">
            <mat-select [disableOptionCentering]="true" required placeholder="Managing Pod" formControlName="managingPod">
              <mat-option *ngFor="let pod of podNames" [value]="pod">{{ pod | replace | titlecase }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div fxFlex.xs="65" fxFlex.gt-xs="75">
            <!-- Placeholder for spacing -->
          </div>
          <mat-form-field *ngIf="clientForm.get('type').value !== 'PRODUCER'" fxFlex.xs="65" fxFlex.gt-xs="75" fxFlex.gt-md="85">
            <mat-chip-list [disabled]="!editMode" #emailDomainsChips aria-label="Email Domains">
              <mat-chip *ngFor="let domain of emailDomains; let domainIndex = index"
                  [removable]="!clientForm.get('emailDomains').disabled"
                  [disabled]="!editMode"
                  selected
                  [color]="(domainIndex + 1) % 2 === 0 ? 'accent' : 'primary'"
                  (removed)="removeEmailDomain(domainIndex)">
                {{domain}}
                <mat-icon matChipRemove *ngIf="!clientForm.get('emailDomains').disabled">cancel</mat-icon>
              </mat-chip>
              <input matInput placeholder="Email Domains" formControlName="emailDomains"
                  [matChipInputFor]="emailDomainsChips"
                  [matChipInputSeparatorKeyCodes]="chipSeparatorKeyCodes"
                  [matChipInputAddOnBlur]="addEmailDomainOnBlur"
                  (matChipInputTokenEnd)="addEmailDomain($event)">
              <mat-error *ngIf="clientForm.get('emailDomains').invalid">{{getErrorMessage(clientForm.get('emailDomains'))}}</mat-error>
            </mat-chip-list>
          </mat-form-field>
          <div class="expansion-panel-checkbox" fxFlex.xs="40" fxFlex.gt-xs="30">
            <ati-labeled-data *ngIf="!editMode" label="Personal Account" data="{{ clientForm.get('isPersonal').value | yesNo }}"></ati-labeled-data>
            <mat-checkbox *ngIf="editMode" class="mat-expansion-panel-body-checkbox" color="primary" formControlName="isPersonal">Personal Account</mat-checkbox>
          </div>
          <div class="expansion-panel-checkbox" fxFlex.xs="40" fxFlex.gt-xs="30">
            <ati-labeled-data *ngIf="!editMode" label="Associated Person" data="{{ clientForm.get('isAssociatedPerson').value | yesNo }}"></ati-labeled-data>
            <mat-checkbox *ngIf="editMode" class="mat-expansion-panel-body-checkbox" color="primary" formControlName="isAssociatedPerson">Associated Person</mat-checkbox>
          </div>
          <div fxFlex.xs="10" fxFlex.gt-xs="30">
            <!-- Placeholder for spacing -->
          </div>
          <ati-labeled-data *ngIf="!editMode" fxFlex.xs="75" fxFlex.gt-xs="30" label="Associated Relationship" data="{{ clientForm.get('associatedPersonRelationship').value }}"></ati-labeled-data>
          <mat-form-field *ngIf="editMode" fxFlex.xs="75" fxFlex.gt-xs="30">
            <input required matInput placeholder="Associated Relationship" formControlName="associatedPersonRelationship" trim="blur">
            <mat-error *ngIf="clientForm.get('associatedPersonRelationship').invalid">{{getErrorMessage(clientForm.get('associatedPersonRelationship'))}}</mat-error>
          </mat-form-field>
          <ati-labeled-data *ngIf="!editMode" fxFlex.xs="75" fxFlex.gt-xs="30" label="Referral Source" data="{{ clientForm.get('referralSource').value }}"></ati-labeled-data>
          <mat-form-field *ngIf="editMode" fxFlex.xs="75" fxFlex.gt-xs="30">
            <input matInput placeholder="Referral Source" formControlName="referralSource" trim="blur">
            <mat-error *ngIf="clientForm.get('referralSource').invalid">{{getErrorMessage(clientForm.get('referralSource'))}}</mat-error>
          </mat-form-field>
          <div fxFlex.xs="65" fxFlex.gt-xs="30">
            <!-- Placeholder for spacing -->
          </div>
          <div  class="expansion-panel-checkbox" fxFlex.xs="40" fxFlex.gt-xs="30">
            <ati-labeled-data *ngIf="!editMode" label="Discretion To Roll" data="{{ clientForm.get('discretionToRoll').value | yesNo }}"></ati-labeled-data>
            <mat-checkbox *ngIf="editMode" class="mat-expansion-panel-body-checkbox" color="primary" formControlName="discretionToRoll">Discretion to Roll</mat-checkbox>
          </div>
          <div class="expansion-panel-checkbox" fxFlex.xs="40" fxFlex.gt-xs="30">
            <ati-labeled-data *ngIf="!editMode" label="Discretion to Trade" data="{{ clientForm.get('discretionToTrade').value | yesNo }}"></ati-labeled-data>
            <mat-checkbox *ngIf="editMode" class="mat-expansion-panel-body-checkbox" color="primary" formControlName="discretionToTrade">Discretion to Trade</mat-checkbox>
          </div>
          <div fxFlex.xs="10" fxFlex.gt-xs="30">
            <!-- Placeholder for spacing -->
          </div>
          <ati-labeled-data *ngIf="!editMode" fxFlex.xs="30" fxFlex.gt-xs="30" label="D to T First Name" data="{{ clientForm.get('discretionToTradeFirstName').value }}"></ati-labeled-data>
          <mat-form-field *ngIf="editMode" fxFlex.xs="30" fxFlex.gt-xs="30">
            <input matInput required placeholder="D to T First Name" formControlName="discretionToTradeFirstName" trim="blur">
            <mat-error *ngIf="clientForm.get('discretionToTradeFirstName').invalid">{{getErrorMessage(clientForm.get('discretionToTradeFirstName'))}}</mat-error>
          </mat-form-field>
          <ati-labeled-data *ngIf="!editMode" fxFlex.xs="40" fxFlex.gt-xs="30" label="D to T Last Name" data="{{ clientForm.get('discretionToTradeLastName').value }}"></ati-labeled-data>
          <mat-form-field *ngIf="editMode" fxFlex.xs="30" fxFlex.gt-xs="30">
            <input matInput required placeholder="D to T Last Name" formControlName="discretionToTradeLastName" trim="blur">
            <mat-error *ngIf="clientForm.get('discretionToTradeLastName').invalid">{{getErrorMessage(clientForm.get('discretionToTradeLastName'))}}</mat-error>
          </mat-form-field>
          <div fxFlex.xs="30" fxFlex.gt-xs="30">
            <!-- Placeholder for spacing -->
          </div>
        </div>
      </mat-expansion-panel>
      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
        <div fxLayout="column" fxLayoutAlign="start start" fxFlex.xs="100" fxFlex.gt-xs="33" >
          <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
            <h3>Accounts</h3>
            <button *ngIf="!editMode && !createMode && clientDocId" id="accounts" aria-label="View Accounts" (click)="selectAccountIcon(); $event.preventDefault()" mat-icon-button color="primary" matTooltip="View Accounts">
              <mat-icon>folder</mat-icon>
            </button>
          </div>
          <span *ngIf="canUpdateClient && createMode" class="mat-caption">Add after saving new Client</span>
          <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
            <h3>Contacts</h3>
            <button type="button" *ngIf="!editMode && !createMode && clientDocId" id="contacts" aria-label="View Contacts" (click)="navigateContacts()" mat-icon-button color="primary" matTooltip="View Contacts">
              <mat-icon>contacts</mat-icon>
            </button>
          </div>
          <span *ngIf="canUpdateClient && createMode" class="mat-caption ">Add after saving new Client</span>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" fxFlex.xs="100" fxFlex.gt-xs="33">
          <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
            <h3>Clearing Firm</h3>
            <button *ngIf="canUpdateClient && !createMode" id="new-client-fcm" type="button" aria-label="Add Clearing Firm" color="primary" mat-icon-button (click)="setupClientFcm()" matTooltip="Add Clearing Firm">
              <mat-icon>add_circle</mat-icon>
            </button>
          </div>
          <span *ngIf="canUpdateClient && createMode" class="mat-caption">Add after saving new Client</span>
          <div fxLayoutAlign="start start" class="client-child-link" *ngFor="let clientFcm of clientFcms$ | async">
            <span (click)="navigateClientFcm(clientFcm.docId)">{{ clientFcm.fcm | displayFcm }}</span>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" fxFlex.xs="100" fxFlex.gt-xs="33">
          <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
            <h3>Banks</h3>
            <button *ngIf="canUpdateClient && !createMode" id="new-client-bank" type="button" aria-label="Add Bank" color="primary" mat-icon-button (click)="setupBank()" matTooltip="Add Bank">
              <mat-icon>add_circle</mat-icon>
            </button>
          </div>
          <span *ngIf="canUpdateClient && createMode" class="mat-caption">Add after saving new Client</span>
          <div fxLayoutAlign="start start" class="client-child-link" *ngFor="let bank of banks$ | async">
            <span (click)="navigateBank(bank.docId)">{{bank.name}}</span>
          </div>
        </div>
      </div>
    </form>
  </mat-card>
</div>
