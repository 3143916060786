import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

import { CommodityMap, ContractMonth } from '@advance-trading/ops-data-lib';

const MONTH = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// Dictionary of contract abbreviations used on CME
const SPREAD_DICT = {
  BF: 'Futures Butterfly',
  BO: 'Options Butterfly',
  BX: 'Box',
  CF: 'Futures Condor',
  CO: 'Options Condor',
  CV: 'Conversion/Reversal',
  DB: 'Double',
  DG: 'Diagonal',
  DS: 'Diagonal Straddle',
  EQ: 'EQ Intra Commodity',
  FO: 'Future vs. Option',
  GN: 'Generic',
  GT: 'Gut',
  HG: 'Horizontal Strangle',
  HS: 'Horizontal Straddle',
  HZ: 'Horizontal',
  IB: 'Iron Butterfly',
  JR: 'Jelly Roll',
  MX: 'Mix',
  OB: 'Options Bundle',
  OR: 'Risk Reversal Bundle',
  RA: 'Ratio',
  RR: 'Risk Reversal',
  SG: 'Strangle',
  SP: 'Intra Commodity',
  SR: 'Strip',
  ST: 'Straddle',
  SY: 'Synthetic/Combo',
  VS: 'Vertical Straddle',
  VT: 'Vertical',
  XT: 'Xmas Tree'
};

@Pipe({name: 'orderSecurity'})
export class OrderSecurityPipe implements PipeTransform {
  /**
   * This converts the security referenced in an order into a plain English value
   * @param value the security listed on the order, e.g. ZCN0
   * @param commodityMap a CommodityMap must be passed in for the pipe to recognize commodities
   */

  transform(value: string, commodityMap: CommodityMap): string {
    let output = value;
    if (!commodityMap) {
      throw new Error('An object of type CommodityMap must be passed in as an argument for this pipe to function');
    }
    if (value.startsWith('UD:')) {
      // User-defined spread; format UD:<more stuff>
      output = 'User-Defined Spread';
    } else if (value.includes(':')) {
      // single commodity spread; format <CommodityCode>:<SpreadStrategy> <months>

      const commodityAndStrategy = value.split(' ')[0].split(':');

      const commodityCode = commodityAndStrategy[0];
      const strategyAbbreviation = commodityAndStrategy[1];

      const commodityName = this.getCommodityName(commodityCode, commodityMap);
      const strategy = SPREAD_DICT[strategyAbbreviation];

      output = `${commodityName} ${strategy} Spread`;
    } else if (value.includes('-')) {
      // spread between two commodity/month / year sets separated by a hyphen
      const spreadParts = value.split('-');

      const translations = spreadParts.map(part => {
        return this.parseCommodityFuturesMonth(part, commodityMap);
      });

      output = `${translations.join(' - ')} Spread`;
    } else if (value.includes(' ')) {
      // Option; consists of commodity/month/year set, space, P or C, and price

      const optionTokens = value.split(' ');
      const putOrCall = optionTokens[1].startsWith('P') ? 'Put' : 'Call';
      const price = parseInt(optionTokens[1].substr(1), 10);

      output = `${this.parseCommodityFuturesMonth(optionTokens[0], commodityMap)} ${putOrCall} ${price}`;
    } else {
      // Single commodity/month/year set
      output = this.parseCommodityFuturesMonth(value, commodityMap);
    }

    return output;
  }

  /**
   * Takes in single-character contract month code and translates it to three-character month abbreviation
   *
   * @param contractMonth Standard one-character ContractMonth month code (i.e. F-Z)
   * @returns string
   */
  private contractMonthToThreeCharMonth(contractMonth: string) {
    const contractMonthCodes = Object.keys(ContractMonth);
    const monthIndex = contractMonthCodes.indexOf(contractMonth);
    return MONTH[monthIndex];
  }

  /**
   * Takes in a commodity.id and returns a commodity.name. Needs commodity map for lookup.
   *
   * @param commodityId The ID of the commodity in the security, e.g. ZC for corn
   * @param commodityMap CommodityMap containing the map of all commodities.
   * @returns string
   */
  private getCommodityName(commodityId: string, commodityMap: CommodityMap) {
    const commodity = commodityMap.commodities[commodityId];
    return commodity ? commodity.name : commodityId;
  }

  /**
   * Takes in single character contract month code plus single digit year character and translates it to
   * three-character month abbreviation and four character year. Assumes year is in current decade.
   *
   * @param contractMonth Standard one-character ContractMonth month code (i.e. F-Z)
   * @param contractYear The single-digit year assuming current decade, e.g. "0" for 2020
   * @returns string
   */
  private getFuturesMonthYearDisplay(contractMonth: string, contractYear: string) {
    return `${this.contractMonthToThreeCharMonth(contractMonth)} ${this.getYearFromSingleDigit(contractYear)}`;
  }

  /**
   * Takes in single-digit year string and outputs 4-character year assuming current decade.
   *
   * @param contractYear The single-digit year assuming current decade, e.g. "0" for 2020
   * @returns string
   */
  private getYearFromSingleDigit(contractYear: string) {
    const currentYear = moment().format('YYYY');
    return `${currentYear.substr(0, 3)}${contractYear}`;
  }

  /**
   * Takes in a security definition consisiting of commodity id, month code, and year
   *  and returns three character month abbreviation, four character year (assumes current decade),
   *  and commodity name. Also works with options symbols.
   *
   * @param commodityFuturesMonth The security identifier, e.g. ZCZ0 for Dec 2020 Corn
   * @param commodityMap CommodityMap containing the map of all commodities.
   * @returns string
   */
  private parseCommodityFuturesMonth(commodityFuturesMonth: string, commodityMap: CommodityMap) {
    const year = commodityFuturesMonth.substr(commodityFuturesMonth.length - 1, 1);
    const month = commodityFuturesMonth.substr(commodityFuturesMonth.length - 2, 1);
    let commodityCode = commodityFuturesMonth.substr(0, commodityFuturesMonth.length - 2);
    const optionCodes = Object.values(commodityMap.commodities).map(commodity => commodity.electronicOptionsSymbol);
    if (optionCodes.includes(commodityCode)) {
        commodityCode = Object.values(commodityMap.commodities)[optionCodes.indexOf(commodityCode)].id;
    }
    return this.translateCommodityFuturesMonth(commodityCode, month, year, commodityMap);
  }

  /**
   * Combination of this.getFuturesMonthYearDisplay and this.getCommodityName. Takes in single character
   * contract month code, single digit year character, and a commodity.id and returns three character month
   * abbreviation, four character year (assumes current decade), and commodity name.
   *
   * @param commodityId The ID of the commodity in the security, e.g. ZC for corn
   * @param monthCode Standard one-character ContractMonth month code (i.e. F-Z)
   * @param year Single digit year. Assumes current decade
   * @param commodityMap CommodityMap containing the map of all commodities.
   * @returns string
   */
  private translateCommodityFuturesMonth(commodityId: string, monthCode: string, year: string, commodityMap: CommodityMap) {
    return `${this.getFuturesMonthYearDisplay(monthCode, year)} ${this.getCommodityName(commodityId, commodityMap)}`;
  }

}
