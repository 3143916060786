<div class="root-container" fxFlexFill>
  <mat-toolbar class="toolbar" fxLayout="row" color="primary">
    <button *ngIf="!isRegistering" mat-icon-button (click)="sidenav.toggle()" class="menu">
      <mat-icon>menu</mat-icon>
    </button>
    <img src="assets/atom_logo.png" class="ati-logo" alt="ATOM Logo">
    <span class="fill-remaining-space"></span>
    <span>
      <button type="button" *ngIf="isXSmall" id="profile-menu-btn" aria-label="Open Profile Menu" mat-icon-button [matMenuTriggerFor]="profile"><mat-icon>more_vert</mat-icon></button>
      <button type="button" *ngIf="!isXSmall" id="profile-menu-btn" aria-label="Open Profile Menu" mat-button [matMenuTriggerFor]="profile"><img class="profile-picture"
          [src]="authService?.userProfile?.picture || 'assets/nopicture.png'" alt="Profile Picture"></button>
      <mat-menu id="profile-menu" #profile="matMenu">
        <ng-container *ngIf="!isRegistering && (loggedInUser$ | async) as user">
          <h4 id="welcome-item">{{user.firstName}} {{user.lastName}}</h4>
          <mat-divider></mat-divider>
          <a mat-menu-item id="profile-item" (click)="navigateToProfile()">Profile</a>
        </ng-container>
        <a mat-menu-item id="about-item" (click)="showAbout()">About ATOM</a>
        <a mat-menu-item id="login-item" (click)="authService.login()" *ngIf="!authService?.isLoggedIn">Login</a>
        <a mat-menu-item id="logout-item" (click)="navigateToLogout()" *ngIf="authService?.isLoggedIn">Logout</a>
      </mat-menu>
    </span>
  </mat-toolbar>
  <mat-sidenav-container class="nav-container">
    <mat-sidenav #sidenav fxLayout="column" [(opened)]="sideNavOpened" [mode]="sideNavMode" class="nav-menu"
      fxFlex.xs="65vw" fxFlex.sm="35vw" fxFlex.md="25vw" fxFlex.lg="15vw" fxFlex.gt-lg="15vw">
      <mat-nav-list>
        <a routerLink="/home" *ngIf="authService?.userProfile" (click)="handleSideNavigation()">
          <mat-list-item routerLinkActive="active" class="nav-menu-item">
            <mat-icon color="primary" mat-list-icon>home</mat-icon>
            <mat-label matLine>Home</mat-label>
            <mat-divider></mat-divider>
          </mat-list-item>
        </a>
        <a routerLink="/accounts" *ngIf="authService?.userProfile" (click)="handleSideNavigation()">
          <mat-list-item routerLinkActive="active" class="nav-menu-item">
            <mat-icon color="primary" mat-list-icon>folder</mat-icon>
            <mat-label matLine>Accounts</mat-label>
            <mat-divider></mat-divider>
          </mat-list-item>
        </a>
        <a routerLink="/accountseries" *ngIf="authService?.userProfile && canViewAccountSeries()" (click)="handleSideNavigation()">
          <mat-list-item routerLinkActive="active" class="nav-menu-item">
            <mat-icon color="primary" mat-list-icon>list_alt</mat-icon>
            <mat-label matLine>Account Series</mat-label>
            <mat-divider></mat-divider>
          </mat-list-item>
        </a>
        <a routerLink="/brokerrelationships" *ngIf="authService?.userProfile && canViewBrokerRelationships()" (click)="handleSideNavigation()">
          <mat-list-item routerLinkActive="active" class="nav-menu-item">
            <mat-icon color="primary" mat-list-icon>supervised_user_circle</mat-icon>
            <mat-label matLine>Broker Relationships</mat-label>
            <mat-divider></mat-divider>
          </mat-list-item>
        </a>
        <a routerLink="/brokerrequests" *ngIf="authService?.userProfile && canViewBrokerRequests()" (click)="handleSideNavigation()">
          <mat-list-item routerLinkActive="active" class="nav-menu-item">
            <mat-icon color="primary" mat-list-icon>assignment</mat-icon>
            <mat-label matLine>Broker Requests</mat-label>
            <mat-divider></mat-divider>
          </mat-list-item>
        </a>
        <a routerLink="/brokers" *ngIf="authService?.userProfile" (click)="handleSideNavigation()">
          <mat-list-item routerLinkActive="active" class="nav-menu-item">
            <mat-icon color="primary" mat-list-icon>assignment_ind</mat-icon>
            <mat-label matLine>Brokers</mat-label>
            <mat-divider></mat-divider>
          </mat-list-item>
        </a>
        <a routerLink="/clients" *ngIf="authService?.userProfile" (click)="handleSideNavigation()">
          <mat-list-item routerLinkActive="active" class="nav-menu-item">
            <mat-icon color="primary" mat-list-icon>business_center</mat-icon>
            <mat-label matLine>Clients</mat-label>
            <mat-divider></mat-divider>
          </mat-list-item>
        </a>
        <a routerLink="/commissionschedules" *ngIf="authService?.userProfile && canViewCommissionSchedules()" (click)="handleSideNavigation()">
          <mat-list-item routerLinkActive="active" class="nav-menu-item">
            <mat-icon color="primary" mat-list-icon>receipt_long</mat-icon>
            <mat-label matLine>Commission Schedules</mat-label>
            <mat-divider></mat-divider>
          </mat-list-item>
        </a>
        <a routerLink="/margins" *ngIf="authService?.userProfile" (click)="handleSideNavigation()">
          <mat-list-item routerLinkActive="active" class="nav-menu-item">
            <mat-icon color="primary" mat-list-icon>paid</mat-icon>
            <mat-label matLine>Margins</mat-label>
            <mat-divider></mat-divider>
          </mat-list-item>
        </a>
        <a routerLink="/orders" *ngIf="authService?.userProfile" (click)="handleSideNavigation()">
          <mat-list-item routerLinkActive="active" class="nav-menu-item">
            <mat-icon color="primary" mat-list-icon>receipt</mat-icon>
            <mat-label matLine>Orders</mat-label>
            <mat-divider></mat-divider>
          </mat-list-item>
        </a>
        <a routerLink="/positions" *ngIf="authService?.userProfile" (click)="handleSideNavigation()">
          <mat-list-item routerLinkActive="active" class="nav-menu-item">
            <mat-icon color="primary" mat-list-icon>timeline</mat-icon>
            <mat-label matLine>Positions</mat-label>
            <mat-divider></mat-divider>
          </mat-list-item>
        </a>
        <a routerLink="/salescodes" *ngIf="authService?.userProfile && canViewSalesCodes()" (click)="handleSideNavigation()">
          <mat-list-item routerLinkActive="active" class="nav-menu-item">
            <mat-icon color="primary" mat-list-icon>local_offer</mat-icon>
            <mat-label matLine>Sales Codes</mat-label>
            <mat-divider></mat-divider>
          </mat-list-item>
        </a>
        <mat-expansion-panel *ngIf="authService?.userProfile" class="nav-menu-item mat-elevation-z0">
          <mat-expansion-panel-header class="nav-menu-expansion-panel-header" collapsedHeight="56px">
            <mat-panel-title>
              <mat-icon svgIcon="text-box-multiple" color="primary" mat-list-icon></mat-icon>
              <span class="mat-body nav-menu-expansion-title">
                Transactions
              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-divider></mat-divider>
          <mat-nav-list>
            <a routerLink="/trades" *ngIf="authService?.userProfile" matLine (click)="handleSideNavigation()">
              <mat-list-item routerLinkActive="active" class="nav-menu-item">
                <mat-icon color="primary" mat-list-icon>swap_horiz</mat-icon>
                <mat-label matLine>Trades</mat-label>
                <mat-divider></mat-divider>
              </mat-list-item>
            </a>
            <a routerLink="/adjustments" *ngIf="authService?.userProfile" matLine (click)="handleSideNavigation()">
              <mat-list-item routerLinkActive="active" class="nav-menu-item">
                <mat-icon color="primary" mat-list-icon>price_change</mat-icon>
                <mat-label matLine>Adjustments</mat-label>
                <mat-divider></mat-divider>
              </mat-list-item>
            </a>
            <a routerLink="/cashactivity" *ngIf="authService?.userProfile" matLine (click)="handleSideNavigation()">
              <mat-list-item routerLinkActive="active" class="nav-menu-item">
                <mat-icon fontSet="material-icons-outlined" svgIcon="cash-activity" color="primary" mat-list-icon></mat-icon>
                <mat-label matLine>Cash Activity</mat-label>
              </mat-list-item>
            </a>
          </mat-nav-list>
        </mat-expansion-panel>
        <mat-divider></mat-divider>
        <a routerLink="/users" *ngIf="authService?.userProfile" (click)="handleSideNavigation()">
          <mat-list-item routerLinkActive="active" class="nav-menu-item">
            <mat-icon color="primary" mat-list-icon>group</mat-icon>
            <mat-label matLine>Users</mat-label>
            <mat-divider></mat-divider>
          </mat-list-item>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxLayout="column" class="nav-content" fxFlex="grow">
      <router-outlet *ngIf="!errorMessage && (authGuard.loading$ | async) === false"></router-outlet>
      <div fxLayoutAlign="center center" fxLayout="column" fxFlex="90"
        *ngIf="!errorMessage && authGuard.loading$ | async">
        <mat-spinner diameter="60" color="accent"></mat-spinner>
        <p>Loading...</p>
      </div>
      <div fxLayoutAlign="space-around start" fxLayout="row" *ngIf="errorMessage">
        <mat-card fxFlex.lt-md="95" fxFlex.md="75" fxFlex.gt-md="65">
          <div>
            <h2 class="card-title">Error Loading ATOM</h2>
            <p [innerHTML]="errorMessage"></p>
            <p>You may try to <a id="error-logout" (click)="authService.logout()">logout</a> and log back in to see if the issue resolves.</p>
            <p>If your issue persists or you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
              or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
          </div>
        </mat-card>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
