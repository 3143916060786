<form [formGroup]="accountForm" (ngSubmit)="saveAccountForm()" fxLayout="column">
  <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxLayoutGap="5">
    <mat-form-field fxFlex.xs="25" fxFlex.gt-xs="20">
      <mat-select [disableOptionCentering]="true" required formControlName="type" placeholder="Type">
        <mat-option *ngFor="let accountType of accountTypes" [value]="accountType">
          {{accountType | replace | titlecase}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="accountForm.get('type').invalid">{{getErrorMessage(accountForm.get('type'))}}</mat-error>
    </mat-form-field>
    <div class="account-number-outer-container" *ngIf="!editMode && canViewAccountNumber" fxFlex.xs="25" fxFlex.gt-xs="25">
      Number
      <div class="account-number-inner-container" fxFlexAlign="center">
        <div *ngIf="this.accountNumber$; else revealIcon" fxLayout="row" fxLayoutAlign="flex-start center">
          <ng-container *ngIf="(this.accountNumber$ | async) as accountNumber; else loadingAccountNumber">
            <span [ngClass]="!accountNumber?.value && 'emptyFieldText'">
              {{ accountNumber?.value || '(empty)' }}
            </span>
            &nbsp; <mat-icon (click)="hideAccountNumber()" class="reveal-icon" matTooltip="Hide Account Number">visibility_off</mat-icon>
          </ng-container>
        </div>
        <ng-template #revealIcon>
          <div fxLayout="row" fxLayoutAlign="flex-start center">
            <div class="account-number-mask">********</div>
            <mat-icon (click)="revealAccountNumber()" class="reveal-icon" matTooltip="Reveal Account Number">visibility</mat-icon>
          </div>
        </ng-template>
        <ng-template #loadingAccountNumber>
          <span class="account-number-loading-text">Verifying user</span> &nbsp; <mat-spinner diameter="15"></mat-spinner>
        </ng-template>
      </div>
    </div>
    <mat-form-field class="account-number-field" *ngIf="editMode && canEditAccountNumber" fxFlex.xs="25" fxFlex.gt-xs="25">
      <input required matInput placeholder="Number" formControlName="accountNumber" class="account-number-field" (keypress)="handleAccountNumberKeypress($event)" mask="0*">
      <button mat-button *ngIf="accountForm.get('accountNumber').enabled" matSuffix mat-icon-button aria-label="Cancel Account Number" (click)="disableAccountNumber()">
        <mat-icon class="reveal-icon" matTooltip="Cancel Account Number">cancel</mat-icon>
      </button>
      <button mat-button *ngIf="accountForm.get('accountNumber').disabled" matSuffix mat-icon-button aria-label="Edit Account Number" (click)="enableAccountNumber()">
        <mat-icon class="reveal-icon" matTooltip="Edit Account Number">edit</mat-icon>
      </button>
      <mat-error *ngIf="accountForm.get('accountNumber').invalid">{{getErrorMessage(accountForm.get('accountNumber'))}}</mat-error>
    </mat-form-field>
    <mat-form-field fxFlex.xs="25" fxFlex.gt-xs="30">
      <input matInput placeholder="Description" formControlName="description" trim="blur">
      <mat-error *ngIf="accountForm.get('description').invalid">{{getErrorMessage(accountForm.get('description'))}}</mat-error>
    </mat-form-field>
    <span fxFlex.xs="10" fxFlex.gt-xs="10">
      <button id="update-bank-account" type="button" aria-label="Update Bank Account" color="primary" [disabled]="accountForm.invalid || !editMode || accountForm.pristine" mat-icon-button (click)="saveAccountForm()" matTooltip="Update Bank Account">
        <mat-icon>check_circle</mat-icon>
      </button>
      <!-- Not Used? -->
      <button id="remove-bank-account" type="button" aria-label="Remove Bank Account" color="warn" [disabled]="!editMode" *ngIf="account" mat-icon-button (click)="removeAccount(account)" matTooltip="Remove Bank Account">
        <mat-icon>delete</mat-icon>
      </button>
    </span>
  </div>
</form>
