import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Position, PositionSegment, CommodityMap, TransactionSecurityType } from '@advance-trading/ops-data-lib';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { PositionService } from '../../services/position-service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { OperationsDataService } from '@advance-trading/angular-ops-data';
import { CommodityDisplayUtility } from 'src/app/utilities/commodity-display-utility';

@Component({
  selector: 'atom-position-detail',
  templateUrl: './position-detail.component.html',
  styleUrls: ['./position-detail.component.css']
})
export class PositionDetailComponent implements OnInit, AfterViewChecked, AfterViewInit {

  @ViewChild(MatPaginator, { static: false }) set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  @ViewChild(MatSort, { static: false }) set sort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @ViewChild('filter', { static: false }) filter: ElementRef;

  isLoading = false;
  errorMessage: string;
  filterValue = new FormControl();

  position$: Observable<Position>;

  positionDocId: string;
  commodityMap: CommodityMap;
  private positionAccountDocId: string;
  positionSegments: PositionSegment[];
  dataSource = new ObservableDataSource<any>();
  columnsToDisplay = ['quantity', 'tradePrice', 'tradeDate'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private positionService: PositionService,
    private operationsDataService: OperationsDataService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.position$ = this.operationsDataService.getCommodityMap().pipe(
      switchMap((doc: CommodityMap) => {
        this.commodityMap = doc;
        return of([]);
      }),
      switchMap(() => {
        return this.activatedRoute.paramMap.pipe(
          switchMap((paramMap: ParamMap) => {
            this.positionAccountDocId = paramMap.get('accountDocId');
            this.positionDocId = paramMap.get('positionDocId');
            return this.positionService.getPositionByDocId(this.positionAccountDocId, this.positionDocId);
          }),
          tap(position => {
            if (!position) {
              this.errorMessage = 'Error retrieving position data; this position either does not exist or you do not have permission to view this information';
            }
            this.dataSource.data$ = of(position.segments);
            this.isLoading = false;
          }),
          catchError(err => {
            this.errorMessage = 'Error retrieving position data; please try again later';
            console.error(`Error retrieving position data: ${err}`);
            return of(undefined);
          })
        );
      })
    );
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit() {
    if (this.filter) {
      this.filter.nativeElement.focus();
    }
    this.changeDetector.detectChanges();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  getCommodityDisplayName(position: Position) {
    return CommodityDisplayUtility.getCommodityDisplayNameOnPosition(position, this.commodityMap);
  }
}

