<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Clearing Firm Setup Details</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <ng-container *ngIf="(clientFcm$ | async) as theClientFcm">
      <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="card-title" *ngIf="createMode">{{ (client$ | async)?.name }} - New Clearing Firm Setup</h2>
        <h2 class="card-title" *ngIf="!createMode">
          {{ (client$ | async)?.name }} - {{ theClientFcm?.fcm | displayFcm }} Setup
        </h2>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
          <button aria-label="Edit Clearing Firm" *ngIf="!editMode && !createMode && updateComplete && canUpdate" (click)="setEditMode(true)" mat-mini-fab color="accent" id="edit-client-fcm" matTooltip="Edit Clearing Firm">
            <mat-icon>edit</mat-icon>
          </button>
          <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
          <button aria-label="Update Clearing Firm" *ngIf="!createMode && editMode && updateComplete" [disabled]="!clientFcmForm.valid || clientFcmForm.pristine" (click)="saveForm()" mat-mini-fab color="accent" id="update-client-fcm" matTooltip="Update Clearing Firm">
            <mat-icon>done</mat-icon>
          </button>
          <button aria-label="Create Clearing Firm" *ngIf="createMode && canUpdate" [disabled]="!clientFcmForm.valid" (click)="saveForm()" mat-mini-fab color="accent" matTooltip="Create Clearing Firm" id="create-client-fcm">
            <mat-icon>done</mat-icon>
          </button>
          <button aria-label="Discard Clearing Firm Setup Changes" *ngIf="updateComplete && editMode && !createMode" (click)="cancelForm()" mat-mini-fab color="" id="discard-client-fcm" matTooltip="Cancel Changes">
            <mat-icon>clear</mat-icon>
          </button>
          <button aria-label="Discard Clearing Firm Setup Changes" *ngIf="createMode && !clientFcmForm.pristine" (click)="reset()" mat-mini-fab color="" id="discard-client-fcm" matTooltip="Reset Form">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
      <form *ngIf="!errorMessage" [formGroup]="clientFcmForm" (ngSubmit)="saveForm()" fxLayout="column">
        <ati-labeled-data *ngIf="!editMode" label="Clearing Firm" data="{{ clientFcmForm.get('fcm').value | displayFcm }}"></ati-labeled-data>
        <mat-radio-group *ngIf="editMode" required formControlName="fcm" fxFlex.xs="100" fxFlex.gt-xs="40">
          <span class="check-radio-label">Clearing Firm</span>
          <mat-radio-button *ngFor="let fcm of fcms" [value]="fcm">
            {{ fcm | displayFcm }}
          </mat-radio-button>
        </mat-radio-group>
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            Filings at FCM
          </mat-expansion-panel-header>
          <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxLayoutGap="5">
            <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="35" fxFlex.xs="55" label="Financials on File" data="{{ getFilingsAtFcmDisplayText(clientFcmForm.get('financialsOnFile').value) }}"></ati-labeled-data>
            <mat-radio-group *ngIf="editMode" required formControlName="financialsOnFile" fxFlex.gt-xs="35" fxFlex.xs="55">
              <span class="check-radio-label">Financials on File</span>
              <mat-radio-button value="YES">Yes</mat-radio-button>
              <mat-radio-button value="NO">No</mat-radio-button>
              <mat-radio-button value="NA">N/A</mat-radio-button>
            </mat-radio-group>
            <ati-labeled-data *ngIf="!editMode" fxFlex.xs="40" fxFlex.gt-xs="30" label="Filing Date" data="{{ clientFcmForm.get('financialsDate').value | date: 'MM/dd/yyyy' }}"></ati-labeled-data>
            <ati-date-picker *ngIf="editMode" [required]="false" fxFlex.xs="40" fxFlex.gt-xs="30" [dateControl]="clientFcmForm.get('financialsDate')" placeholder="Filing Date"></ati-date-picker>
            <div *ngIf="editMode" fxHide.xs fxFlex.gt-xs="33">
              <!-- Placeholder for spacing -->
            </div>
            <div *ngIf="!editMode" fxHide.xs fxFlex.gt-xs="35">
              <!-- Placeholder for spacing -->
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxLayoutGap="5">
            <ati-labeled-data *ngIf="!editMode" fxFlex.xs="55" fxFlex.gt-xs="35" label="Security Agreement on File" data="{{ getFilingsAtFcmDisplayText(clientFcmForm.get('securityAgreementOnFile').value) }}"></ati-labeled-data>
            <mat-radio-group *ngIf="editMode" required formControlName="securityAgreementOnFile" fxFlex.xs="55" fxFlex.gt-xs="35">
              <span class="check-radio-label">Security Agreement on File</span>
              <mat-radio-button value="YES">Yes</mat-radio-button>
              <mat-radio-button value="NO">No</mat-radio-button>
              <mat-radio-button value="NA">N/A</mat-radio-button>
            </mat-radio-group>
            <ati-labeled-data *ngIf="!editMode" fxFlex.xs="40" fxFlex.gt-xs="30" label="Filing Date" data="{{ clientFcmForm.get('securityAgreementDate').value | date: 'MM/dd/yyyy' }}"></ati-labeled-data>
            <ati-date-picker *ngIf="editMode" [required]="false" fxFlex.xs="40" fxFlex.gt-xs="30" [dateControl]="clientFcmForm.get('securityAgreementDate')" placeholder="Filing Date"></ati-date-picker>
            <ati-labeled-data *ngIf="!editMode" fxFlex.xs="60" fxFlex.gt-xs="35" label="Security Agreement Bank" data="{{ clientFcmForm.get('securityAgreementBank').value }}"></ati-labeled-data>
            <mat-form-field *ngIf="editMode" fxFlex.xs="60" fxFlex.gt-xs="30" >
              <input matInput placeholder="Security Agreement Bank" formControlName="securityAgreementBank" trim="blur">
            </mat-form-field>
          </div>
          <div class="checkbox-group" fxLayout="row wrap" fxLayoutAlign="start start">
            <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="35" fxFlex.xs="55" label="Arbitration Signed" data="{{ getFilingsAtFcmDisplayText(clientFcmForm.get('arbitrationSigned').value) }}"></ati-labeled-data>
            <mat-radio-group *ngIf="editMode" required formControlName="arbitrationSigned" fxFlex.gt-xs="35" fxFlex.xs="55">
              <span class="check-radio-label">Arbitration Signed</span>
              <mat-radio-button value="YES">Yes</mat-radio-button>
              <mat-radio-button value="NO">No</mat-radio-button>
              <mat-radio-button value="NA">N/A</mat-radio-button>
            </mat-radio-group>
            <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="35" fxFlex.xs="55" label="Check on File" data="{{ getFilingsAtFcmDisplayText(clientFcmForm.get('copyOfCheckOnFile').value) }}"></ati-labeled-data>
            <mat-radio-group *ngIf="editMode" required formControlName="copyOfCheckOnFile" fxFlex.gt-xs="35" fxFlex.xs="55">
              <span class="check-radio-label">Check on File</span>
              <mat-radio-button value="YES">Yes</mat-radio-button>
              <mat-radio-button value="NO">No</mat-radio-button>
              <mat-radio-button value="NA">N/A</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="checkbox-group" fxLayout="row wrap" fxLayoutAlign="start start">
            <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="35" fxFlex.xs="55" label="ACH Instructions" data="{{ getFilingsAtFcmDisplayText(clientFcmForm.get('achWireInstructionOnFile').value) }}"></ati-labeled-data>
            <mat-radio-group *ngIf="editMode" required formControlName="achWireInstructionOnFile" fxFlex.gt-xs="35" fxFlex.xs="55">
              <span class="check-radio-label">ACH Instructions</span>
              <mat-radio-button value="YES">Yes</mat-radio-button>
              <mat-radio-button value="NO">No</mat-radio-button>
              <mat-radio-button value="NA">N/A</mat-radio-button>
            </mat-radio-group>
            <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="35" fxFlex.xs="55" label="License on File" data="{{ getFilingsAtFcmDisplayText(clientFcmForm.get('copyOfLicenseOnFile').value) }}"></ati-labeled-data>
            <mat-radio-group *ngIf="editMode" required formControlName="copyOfLicenseOnFile" fxFlex.gt-xs="35" fxFlex.xs="55">
              <span class="check-radio-label">License on File</span>
              <mat-radio-button value="YES">Yes</mat-radio-button>
              <mat-radio-button value="NO">No</mat-radio-button>
              <mat-radio-button value="NA">N/A</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="checkbox-group" fxLayout="row wrap" fxLayoutAlign="start start">
            <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="35" fxFlex.xs="55" label="Risk Disclosures" data="{{ getFilingsAtFcmDisplayText(clientFcmForm.get('additionalRiskDisclosuresOnFile').value) }}"></ati-labeled-data>
            <mat-radio-group *ngIf="editMode" required formControlName="additionalRiskDisclosuresOnFile" fxFlex.gt-xs="35" fxFlex.xs="55">
              <span class="check-radio-label">Risk Disclosures</span>
              <mat-radio-button value="YES">Yes</mat-radio-button>
              <mat-radio-button value="NO">No</mat-radio-button>
              <mat-radio-button value="NA">N/A</mat-radio-button>
            </mat-radio-group>
            <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="35" fxFlex.xs="55" label="Authrozied Traders on File" data="{{ getFilingsAtFcmDisplayText(clientFcmForm.get('authorizedTradersOnFile').value) }}"></ati-labeled-data>
            <mat-radio-group *ngIf="editMode" required formControlName="authorizedTradersOnFile" fxFlex.gt-xs="35" fxFlex.xs="55">
              <span class="check-radio-label">Authorized Traders on File</span>
              <mat-radio-button value="YES">Yes</mat-radio-button>
              <mat-radio-button value="NO">No</mat-radio-button>
              <mat-radio-button value="NA">N/A</mat-radio-button>
            </mat-radio-group>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>Authorizations</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="empty-authorizations" *ngIf="authorizedSigners.length === 0 && !editMode">No Authorized Signers</div>
          <atom-fcm-authorized-signer [allSigners]="authorizedSigners" *ngFor="let authorizedSigner of authorizedSigners; let index = index" [editMode]="editMode" [authorizedSigner]="authorizedSigner" (signerUpdated)="authorizedSigners[index] = $event; this.clientFcmForm.markAsDirty()" (signerRemoved)="onSignerRemoved(index)"></atom-fcm-authorized-signer>
          <atom-fcm-authorized-signer *ngIf="editMode" [editMode]="editMode" (signerUpdated)="onSignerCreated($event)" [allSigners]="authorizedSigners" [authorizedSigner]="authorizedSigner"></atom-fcm-authorized-signer>
          <div class="empty-authorizations" *ngIf="authorizedTraders.length === 0 && !editMode">No Authorized Traders</div>
          <div class="chips" fxLayout="row wrap">
            <mat-form-field *ngIf="authorizedTraders.length > 0 || editMode" fxFlex.gt-xs="100">
              <ng-container *ngIf="contacts$ | async"></ng-container>
              <mat-chip-list [disabled]="!editMode" #traderChips class="trader-list">
                <mat-chip *ngFor="let trader of authorizedTraders; index as index;"
                  [color]="(index + 1) % 2 === 0 ? 'accent' : 'primary'"
                  [disabled]="!editMode"
                  selected
                  [removable]="traderRemovable(trader)"
                  (removed)="removeTrader(trader)">
                  {{ displayContact(trader) }}
                  <mat-icon matChipRemove *ngIf="traderRemovable(trader)">cancel</mat-icon>
                </mat-chip>
                <input
                  #traderInput
                  matInput
                  placeholder="Authorized Traders"
                  formControlName="authorizedTraders"
                  [matAutocomplete]="traderauto"
                  [matChipInputFor]="traderChips"
                  [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addTrader($event)">
              </mat-chip-list>
              <mat-autocomplete autoActiveFirstOption #traderauto="matAutocomplete" [displayWith]="displayContact" (optionSelected)="addTrader()">
                <mat-option *ngFor="let trader of filteredContacts$ | async" [value]="trader">{{ displayContact(trader) }}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </form>
    </ng-container>
  </mat-card>
</div>
