import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, shareReplay, take, tap, switchMap, takeUntil } from 'rxjs/operators';

import { Auth0AuthzService, AuthService } from '@advance-trading/angular-ati-security';
import { CommissionScheduleService, OperationsDataService } from '@advance-trading/angular-ops-data';
import { CommissionRate, CommissionSchedule, Commodity, CommodityMap, Status, ExchangeMap, Exchange } from '@advance-trading/ops-data-lib';

import { CommissionRateDialogComponent } from '../commission-rate-dialog/commission-rate-dialog.component';
import { CommissionScheduleValidators, RateCodeErrorMatcher } from '../commission-schedule.validator';

const numFields = ['futureHedge', 'futureSpread', 'optionHedgeOpen', 'optionHedgeClose', 'optionSpreadOpen', 'optionSpreadClose'];

@Component({
  selector: 'atom-commission-schedule-detail',
  templateUrl: './commission-schedule-detail.component.html',
  styleUrls: ['./commission-schedule-detail.component.css']
})
export class CommissionScheduleDetailComponent implements OnInit, OnDestroy {
  @ViewChild(MatHorizontalStepper, { static: false }) stepper: MatHorizontalStepper;
  @ViewChild(FormGroupDirective, { static: false }) rateFormDirective;

  private existingRateCodes$ = this.commissionScheduleService.getAllCommissionSchedules().pipe(
    shareReplay(1),
    catchError(err => {
      this.errorMessage = 'Error retrieving commodities; please try again later';
      console.error('Error retrieving commodities: ' + JSON.stringify(err));
      return of([]);
    })
  );

  existingSchedule: CommissionSchedule;
  isRateCodeNotFound = false;
  isEditMode = !this.route.snapshot.params.docId;
  isSaving = false;
  knownCommodities: {id: string, displayName: string}[];
  knownExchanges: {id: string, displayName: string}[];
  docIdParam = this.route.snapshot.params.docId;
  rateForm: FormGroup = this.fb.group({
    rateCode: [''],
    isSlidingScale: [false],
    isAllIn: [false],
    defaultRate: this.fb.array([this.fb.group({
      futureHedge: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
      futureSpread: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
      optionHedgeOpen: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
      optionHedgeClose: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
      optionSpreadOpen: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
      optionSpreadClose: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
      level: [''],
      rangeBottom: ['', [Validators.min(0), Validators.max(99999)]],
    })]),
    rates: this.fb.array([]),
    level: ['']
  }, { validators: [CommissionScheduleValidators.rateCodeValidator] });
  ratesByExchange = {};
  ratesByExchangeWithoutAll = {}; // Only contains the specific exchange rates (not the default)
  Status = Status;  // Template access
  openPanel = 'allExchanges';
  parseInt = parseInt;
  isComplianceOfficer = false;
  isAccountAdmin = false;
  commodities$: Observable<any>;
  exchanges$: Observable<any>;
  errorMessage = '';
  rateCode = '';

  private unsubscribe$: Subject<void> = new Subject<void>();

  // matcher for form validation error messages
  rateCodeErrorMatcher = new RateCodeErrorMatcher();

  get rates() {
    return this.rateForm.get('rates') as FormArray;
  }

  get doShowWarningIcon() {
    const defaultRate = this.rateForm.get('defaultRate') as FormArray;
    return this.isEditMode && (
      (defaultRate.at(0).get('futureHedge').dirty && defaultRate.at(0).get('futureHedge').invalid) ||
      (defaultRate.at(0).get('futureSpread').dirty && defaultRate.at(0).get('futureSpread').invalid) ||
      (defaultRate.at(0).get('optionHedgeOpen').dirty && defaultRate.at(0).get('optionHedgeOpen').invalid) ||
      (defaultRate.at(0).get('optionHedgeClose').dirty && defaultRate.at(0).get('optionHedgeClose').invalid) ||
      (defaultRate.at(0).get('optionSpreadOpen').dirty && defaultRate.at(0).get('optionSpreadOpen').invalid) ||
      (defaultRate.at(0).get('optionSpreadClose').dirty && defaultRate.at(0).get('optionSpreadClose').invalid)
    );
  }

  get routeSnapshot() {
    return this.route.snapshot;
  }

  get isSlidingScale() {
    return this.rateForm.get('isSlidingScale').value;
  }

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private commissionScheduleService: CommissionScheduleService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private operationsDataService: OperationsDataService,
  ) { }

  ngOnInit() {
    if (this.docIdParam) {
      this.rateForm.disable();
    }
    this.checkRoles();
    this.rateForm.get('rateCode').setAsyncValidators(this.validateUniqueRateCode);
    this.rateForm.get('isSlidingScale').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(newVal => {
      const defaultRate = this.rateForm.get('defaultRate') as FormArray;
      const rateCodeValue = this.rateForm.get('rateCode').value;
      if (!newVal) {
        if (rateCodeValue && rateCodeValue.startsWith('SS-')) {
          this.rateForm.get('rateCode').setValue(rateCodeValue.slice(3));
        }
        // Switch from sliding scale to fixed rate
        while (defaultRate.length > 1) {
          defaultRate.removeAt(1);
        }

        defaultRate.at(0).get('level').setValue('');
        defaultRate.at(0).get('rangeBottom').setValue('');

        for (const rate of (this.rates.controls as FormArray[])) {
          while (rate.length > 1) {
            rate.removeAt(1);
          }
          rate.at(0).get('level').setValue('');
          rate.at(0).get('rangeBottom').setValue('');
        }
      } else {
        // Switch from fixed-rate to sliding scale
        if (rateCodeValue && !rateCodeValue.startsWith('SS-')) {
          this.rateForm.get('rateCode').setValue(`SS-${rateCodeValue}`);
        }
        defaultRate.at(0).get('level').setValue('1');
        defaultRate.at(0).get('rangeBottom').setValue('1');
        for (const rate of (this.rates.controls as FormArray[])) {
          rate.at(0).get('level').setValue('1');
          rate.at(0).get('rangeBottom').setValue('1');
        }
      }
    });
    this.setupLiveFields();

    this.commodities$ = this.operationsDataService.getCommodityMap().pipe(
      map((doc: CommodityMap) => {
        return Object.values(doc.commodities).sort((c1, c2) => c1.name > c2.name ? 1 : -1);
      }),
      map((commodities: Commodity[]) => {
        return this.knownExchanges.map(exchange => {
          return {
            exchange: exchange.id,
            id: `VARIOUS_${exchange.id}`,
            name: 'Various Commodities',
          };
        }).concat(commodities);
      }),
      shareReplay(1),
      catchError(err => {
        this.errorMessage = 'Error retrieving commodities; please try again later';
        console.error('Error retrieving commodities: ' + err);
        return of([]);
      })
    );

    this.exchanges$ = this.operationsDataService.getExchangeMap().pipe(
      map((doc: ExchangeMap) => {
        return Object.values(doc.exchanges).sort((c1, c2) => c1.name > c2.name ? 1 : -1);
      }),
      tap((exchanges: Exchange[]) => {
        this.knownExchanges = exchanges.map(exchange => ({
          id: exchange.id,
          displayName: exchange.name
        }));
        return this.knownExchanges;
      }),
      shareReplay(1),
      switchMap(exchanges => {
        return this.commodities$;
      }),
      map((commodities: Commodity[]) => {
        return commodities.map(commodity => ({
          id: commodity.id,
          displayName: commodity.name,
          exchange: commodity.exchange
        }));
      }),
      map(commodities => {
        this.knownCommodities = commodities;
        return commodities;
      })
    );

    this.exchanges$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {}, err => {
      this.errorMessage = 'Error retrieving exchanges; please try again later';
      console.error('Error retrieving exchanges: ' + err);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openRateDialog(existingRate?: FormGroup) {
    const dialogRef = this.dialog.open(CommissionRateDialogComponent, {
      disableClose: true,
      data: {
        commodities$: this.commodities$,
        existingRate,
        isSlidingScale: this.rateForm.get('isSlidingScale').value,
        knownCommodities: this.knownCommodities,
        knownExchanges: this.knownExchanges,
        ratesByExchange: this.ratesByExchange,
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(newRate => {
      if (newRate instanceof Array) {
        newRate.forEach(rate => this.addRate(rate));
      } else {
        this.addRate(newRate, existingRate);
      }
    });
    return dialogRef;
  }

  deleteRate(existingRate: FormGroup) {
    if (confirm('Are you sure you want to delete this commodity?')) {
      const rateIndex = this.getFormArrayIndex(this.rates, existingRate);
      if (rateIndex > -1) {
        this.rates.removeAt(rateIndex);
      }
      this.updateRatesByExchange();
    }
  }

  setEditMode(newVal: boolean) {
    this.isEditMode = newVal;
    if (this.isEditMode === true) {
      this.rateForm.enable();
    } else {
      this.rateForm.disable();
    }

    if (!newVal && this.docIdParam) {
      this.setupLiveFields();
    }
  }

  approveCompliance() {
    const schedule: CommissionSchedule = this.getCommissionSchedule();
    schedule.status = Status.APPROVED;
    schedule.approvalDate = new Date().toISOString();
    this.commissionScheduleService.updateCommissionSchedule(schedule.getPlainObject())
      .then(() => {
        this.openSnackBar('Commission schedule successfully approved', 'DISMISS', true);
      })
      .catch(err => {
        this.handleFailedUpdate(err, 'Commission schedule approval failed: ');
      });
  }

  denyCompliance() {
    const schedule: CommissionSchedule = this.getCommissionSchedule();
    schedule.status = Status.DENIED;
    schedule.denialDate = new Date().toISOString();
    this.commissionScheduleService.updateCommissionSchedule(schedule.getPlainObject())
      .then(() => this.openSnackBar('Commission schedule successfully denied', 'DISMISS', true))
      .catch(err => {
        this.handleFailedUpdate(err, 'Commission schedule denial failed: ');
      });
  }

  activateCommissionSchedule() {
    const schedule: CommissionSchedule = this.getCommissionSchedule();
    schedule.status = Status.ACTIVE;
    schedule.activationDate = new Date().toISOString();
    this.commissionScheduleService.updateCommissionSchedule(schedule.getPlainObject())
      .then(() => this.openSnackBar('Commission schedule successfully activated', 'DISMISS', true))
      .catch(err => {
        this.handleFailedUpdate(err, 'Commission schedule activation failed: ');
      });
  }

  handleSave() {
    if (!this.isEditMode) {
      return;
    }

    this.isSaving = true;
    const schedule: CommissionSchedule = this.getCommissionSchedule();

    if (!this.existingSchedule) {
      // New document
      schedule.originator = this.authService.userProfile.app_metadata.firestoreDocId;
      this.commissionScheduleService.createCommissionSchedule(schedule)
        .then(() => {
          this.router.navigate(['/commissionschedules']);
          this.isSaving = false;
          this.setEditMode(false);
          this.existingSchedule = schedule;
          this.openSnackBar('Commission schedule successfully created', 'DISMISS', true);
        })
        .catch(err => {
          this.isSaving = false;
          this.handleFailedUpdate(err, 'Commission schedule creation failed: ');
        });
    } else {
      // Editing existing document
      this.commissionScheduleService.updateCommissionSchedule(schedule.getPlainObject())
        .then(() => {
          this.isSaving = false;
          this.setEditMode(false);
          this.openSnackBar('Commission schedule successfully updated', 'DISMISS', true);
        })
        .catch(err => {
          this.isSaving = false;
          this.handleFailedUpdate(err, 'Commission schedule update failed: ');
        });
    }
  }

  addRateTier(rateArr: FormArray) {
    const exchange = rateArr.at(0).get('exchange') ? rateArr.at(0).get('exchange').value : 'ALL';
    const commodity = rateArr.at(0).get('commodity') ? rateArr.at(0).get('commodity').value : 'VARIOUS';

    const newTier = this.fb.group({
      exchange: [exchange],
      commodity: [commodity],
      futureHedge: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
      futureSpread: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
      optionHedgeOpen: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
      optionHedgeClose: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
      optionSpreadOpen: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
      optionSpreadClose: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
      level: String(rateArr.length + 1),
      rangeBottom: ['', [Validators.required, Validators.min(0), , Validators.max(99999)]]
    });

    const rangeBottomArr = rateArr.controls.map(control => control.get('rangeBottom').value);  // Used to ensure unique bottomRange

    const dialogRef = this.dialog.open(CommissionRateDialogComponent, {
      disableClose: true,
      data: {
        rangeBottomArr,
        existingRate: newTier,
        isSlidingScale: this.rateForm.get('isSlidingScale').value,
        knownCommodities: this.knownCommodities,
        knownExchanges: this.knownExchanges,
        ratesByExchange: this.ratesByExchange,
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(newRate => {
      if (newRate) {
        let newPos = rangeBottomArr.findIndex(existingRate => parseInt(existingRate, 10) > parseInt(newRate.get('rangeBottom').value, 10));
        if (newPos === -1) {
          newPos = rangeBottomArr.length;
        }
        rateArr.insert(newPos, newRate);
      }
    });
    return dialogRef;
  }

  deleteRateTier(rate) {
    if (confirm('Are you sure you want to delete this tier?')) {
      const rateIndex = this.getFormArrayIndex(this.rates, rate);
      let rateTierArr = this.rateForm.get('defaultRate') as FormArray;

      if (rateIndex > -1) {
        rateTierArr = this.rates.at(rateIndex) as FormArray;
      }

      const tierIndex = rateTierArr.controls.findIndex(formControl => formControl.get('level').value === rate.get('level').value);

      if (tierIndex > -1) {
        rateTierArr.removeAt(tierIndex);
        this.updateRatesByExchange();
      }
    }

  }

  handleClosePanel() {
    const defaultRate = this.rateForm.get('defaultRate') as FormArray;
    defaultRate.at(0).get('futureHedge').markAsTouched();
    defaultRate.at(0).get('futureSpread').markAsTouched();
    defaultRate.at(0).get('optionHedgeOpen').markAsTouched();
    defaultRate.at(0).get('optionHedgeClose').markAsTouched();
    defaultRate.at(0).get('optionSpreadOpen').markAsTouched();
    defaultRate.at(0).get('optionSpreadClose').markAsTouched();
    defaultRate.at(0).get('futureHedge').markAsDirty();
    defaultRate.at(0).get('futureSpread').markAsDirty();
    defaultRate.at(0).get('optionHedgeOpen').markAsDirty();
    defaultRate.at(0).get('optionHedgeClose').markAsDirty();
    defaultRate.at(0).get('optionSpreadOpen').markAsDirty();
    defaultRate.at(0).get('optionSpreadClose').markAsDirty();
  }

  handleOpenPanel(panel) {
    this.openPanel = panel;
  }

  addRate(rate?: FormGroup, existingRate?: FormGroup) {
    if (!rate) {
      return;
    }

    if (!existingRate) {
      // Add new rate code
      if (this.rateForm.get('isSlidingScale').value && !rate.get('rangeBottom').value) {
        rate.get('rangeBottom').setValue('1');
      }
      this.rates.push(this.fb.array([rate]));
    } else {
      // Edit existing rate
      const rateIndex = this.getFormArrayIndex(this.rates, existingRate);
      let rateTierArr = this.rateForm.get('defaultRate') as FormArray;

      if (rateIndex > -1) {
        rateTierArr = this.rates.at(rateIndex) as FormArray;
      }

      const tierIndex = !this.rateForm.get('isSlidingScale').value ? 0 :
        rateTierArr.controls.findIndex(formControl => formControl.get('level').value === rate.get('level').value);

      if (tierIndex > -1) {
        rateTierArr.removeAt(tierIndex);

        let insertionLoc = tierIndex;

        if (this.isSlidingScale) {
          const bottomRange = parseInt(rate.get('rangeBottom').value, 10);
          insertionLoc = rateTierArr.controls.findIndex(tier => parseInt(tier.get('rangeBottom').value, 10) > bottomRange);
          if (insertionLoc === -1) {
            insertionLoc = rateTierArr.controls.length;
          }

          rateTierArr.insert(insertionLoc, rate);
          for (let i = 0; i < rateTierArr.controls.length; i++) {
            rateTierArr.at(i).get('level').setValue(String(i + 1));
          }
        } else {
          rateTierArr.insert(insertionLoc, rate);
        }
      }
    }

    this.updateRatesByExchange();
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'You must enter a value';
    } else if (control.hasError('rateCodeTaken')) {
      return `Rate code has already been used`;
    } else if (this.rateForm.hasError('invalidRateCode')) {
      return `Invalid rate code`;
    }
    return 'Unknown error';
  }

  setupLiveFields() {
    if (this.docIdParam && this.docIdParam !== 'new') {
      // Update form values when firestore document changes
      this.commissionScheduleService.getCommissionScheduleByDocId(this.docIdParam).pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter(_ => !this.isEditMode),
      ).pipe(takeUntil(this.unsubscribe$)).subscribe(this.handleUpdatedSchedule, err => {
        this.errorMessage = 'Error retrieving commission schedule; please try again later';
        console.error('Error retrieving commission schedule: ' + JSON.stringify(err));
      });
    }
  }

  private getFormGroup = (entry) => {
    return this.fb.group({
      exchange: [entry.exchange || '', entry.exchange ? Validators.required : undefined],
      commodity: [entry.commodity || ''],
      futureHedge: [entry.futureHedge, [Validators.required, Validators.min(0), Validators.max(999)]],
      futureSpread: [entry.futureSpread, [Validators.required, Validators.min(0), Validators.max(999)]],
      optionHedgeOpen: [entry.optionHedgeOpen, [Validators.required, Validators.min(0), Validators.max(999)]],
      optionHedgeClose: [entry.optionHedgeClose, [Validators.required, Validators.min(0), Validators.max(999)]],
      optionSpreadOpen: [entry.optionSpreadOpen, [Validators.required, Validators.min(0), Validators.max(999)]],
      optionSpreadClose: [entry.optionSpreadClose, [Validators.required, Validators.min(0), Validators.max(999)]],
      level: [String(entry.level || 1)],
      rangeBottom: [String(entry.rangeBottom || 1), [Validators.min(0), Validators.max(99999)]],
    });
  }

  private handleUpdatedSchedule = scheduleDoc => {
    if (!scheduleDoc) {
      this.isRateCodeNotFound = true;
      return;
    }

    if (this.isAccountAdmin && scheduleDoc.status === Status.APPROVED) {
      this.rateForm.get('rateCode').setValidators(Validators.required);
    }

    this.rateForm.get('rateCode').markAsTouched();

    this.rateCode = scheduleDoc.rateCode;
    const defaultRate = this.rateForm.get('defaultRate') as FormArray;
    const isSlidingScale = Array.isArray(scheduleDoc.defaultRate);
    const isAllIn = scheduleDoc.isAllIn;

    while (defaultRate.length > 0) {
      defaultRate.removeAt(defaultRate.length - 1);
    }

    if (isSlidingScale) {
      scheduleDoc.defaultRate.forEach(rate => {
        defaultRate.push(this.getFormGroup(this.fixRate(rate)));
      });
    } else {
      defaultRate.push(this.getFormGroup(this.fixRate(scheduleDoc.defaultRate)));
    }

    // Remove old rates
    while (this.rates.length > 0) {
      this.rates.removeAt(0);
    }

    // Update rates
    for (const commodityCode of Object.keys(scheduleDoc.rates)) {
      const rate = isSlidingScale ? scheduleDoc.rates[commodityCode] : [scheduleDoc.rates[commodityCode]];
      const formArray = this.fb.array(Object.values(rate).map((rateEntry: CommissionRate) => this.getFormGroup(this.fixRate(rateEntry))));
      this.rates.push(formArray);
    }

    if (scheduleDoc.rateCode !== this.rateForm.get('rateCode').value) {
      this.rateForm.get('rateCode').setValue(scheduleDoc.rateCode);
    }

    if (isSlidingScale && this.rateForm.get('isSlidingScale').value !== isSlidingScale) {
      this.rateForm.get('isSlidingScale').setValue(true, {emitEvent: false});
    }
    if (isAllIn && this.rateForm.get('isAllIn').value !== isAllIn) {
      this.rateForm.get('isAllIn').setValue(true, {emitEvent: false});
    }

    this.existingSchedule = scheduleDoc;
    this.updateRatesByExchange();
    this.isRateCodeNotFound = false;
  }

  private updateRatesByExchange() {
    this.ratesByExchange = this.rates.controls.slice(0).reduce((accumulator, rateArr: FormArray, i) => {
      if (rateArr.length === 0) {
        this.rates.removeAt(i);
        return accumulator;
      }

      const exchange = rateArr.at(0).get('exchange').value;

      if (!accumulator.hasOwnProperty(exchange)) {
        accumulator[exchange] = [];
      }

      accumulator[exchange].push(rateArr);
      return accumulator;
    }, {});

    // Sort by commodity
    for (const exchange of Object.keys(this.ratesByExchange)) {
      this.ratesByExchange[exchange] = this.ratesByExchange[exchange].sort((r1, r2) => {
        const commodity1 = this.knownCommodities.find(c => c.id === r1.at(0).get('commodity').value);
        const commodity2 = this.knownCommodities.find(c => c.id === r2.at(0).get('commodity').value);
        if (commodity1.id === 'VARIOUS_' + exchange) {
          return -1;
        } else if (commodity2.id === 'VARIOUS_' + exchange) {
          return 1;
        }
        return commodity1.displayName > commodity2.displayName ? 1 : -1;
      });
    }
    // Create a separate object that holds all specific exchange rates
    this.ratesByExchangeWithoutAll = Object.assign({}, this.ratesByExchange);
    delete this.ratesByExchangeWithoutAll['ALL'];
  }

  private validateUniqueRateCode = (control: AbstractControl) => {
    return this.existingRateCodes$.pipe(
      debounceTime(300),
      map((schedules: CommissionSchedule[]) => {
        const match = schedules.find(sched =>
          control.value &&
          sched.rateCode === control.value &&
          sched.docId !== this.docIdParam
        );

        return match ? { rateCodeTaken: true } : undefined;
      }),
      take(1)
    );
  }

  private getFormArrayIndex(arr: FormArray, rate: FormGroup) {
    if (!rate.get('exchange') || !rate.get('commodity')) {
      return -1;
    }

    const exchange = rate.get('exchange').value;
    const commodity = rate.get('commodity').value;

    for (let i = 0; i < arr.length; i++) {
      const formArr = arr.at(i) as FormArray;
      if (formArr.at(0).get('exchange').value === exchange && formArr.at(0).get('commodity').value === commodity) {
        return i;
      }
    }
    return -1;
  }

  private getCommissionSchedule(): CommissionSchedule {
    const defaultRate = this.rateForm.get('defaultRate') as FormArray;
    const sched: CommissionSchedule = Object.assign(new CommissionSchedule(), {
      rateCode: this.rateForm.get('rateCode').value,
      status: this.existingSchedule ? this.existingSchedule.status : Status.NEW,
      isAllIn: this.rateForm.get('isAllIn').value,
      defaultRate: defaultRate.controls.map(control => {
        const curRate: CommissionRate = {
          futureHedge: parseFloat(control.get('futureHedge').value),
          futureSpread: parseFloat(control.get('futureSpread').value),
          optionHedgeOpen: parseFloat(control.get('optionHedgeOpen').value),
          optionHedgeClose: parseFloat(control.get('optionHedgeClose').value),
          optionSpreadOpen: parseFloat(control.get('optionSpreadOpen').value),
          optionSpreadClose: parseFloat(control.get('optionSpreadClose').value)
        };

        if (this.isSlidingScale) {
          curRate.level = parseInt(control.get('level').value, 10);
          curRate.rangeBottom = parseInt(control.get('rangeBottom').value, 10);
        }

        return curRate;
      }),
      rates: this.rates.controls.reduce((accumulator, formArr: FormArray) => {
        accumulator[formArr.at(0).value.commodity] = formArr.controls.map((rate: FormArray) => {
          const curRate: CommissionRate = {
            exchange: rate.get('exchange').value,
            commodity: rate.get('commodity').value,
            futureHedge: parseFloat(rate.get('futureHedge').value),
            futureSpread: parseFloat(rate.get('futureSpread').value),
            optionHedgeOpen: parseFloat(rate.get('optionHedgeOpen').value),
            optionHedgeClose: parseFloat(rate.get('optionHedgeClose').value),
            optionSpreadOpen: parseFloat(rate.get('optionSpreadOpen').value),
            optionSpreadClose: parseFloat(rate.get('optionSpreadClose').value)
          };

          if (this.isSlidingScale) {
            curRate.level = parseInt(rate.get('level').value, 10);
            curRate.rangeBottom = parseInt(rate.get('rangeBottom').value, 10);
          }

          return curRate;
        });

        if (!this.isSlidingScale) {
          accumulator[formArr.at(0).value.commodity] = accumulator[formArr.at(0).value.commodity][0];
        }

        return accumulator;
      }, {})
    });

    if (!this.isSlidingScale) {
      sched.defaultRate = sched.defaultRate[0];
    }

    if (this.existingSchedule) {
      sched.docId = this.existingSchedule.docId;
      sched.originator = this.existingSchedule.originator;
      if (this.existingSchedule.approvalDate) {
        sched.approvalDate = this.existingSchedule.approvalDate;
      }
      if (this.existingSchedule.activationDate) {
        sched.activationDate = this.existingSchedule.activationDate;
      }
      if (this.existingSchedule.inactivationDate) {
        sched.inactivationDate = this.existingSchedule.inactivationDate;
      }
      if (this.existingSchedule.denialDate) {
        sched.denialDate = this.existingSchedule.denialDate;
      }
    }

    return sched;
  }

  private fixFloat(value: number | string) {
    const decimalLoc = String(value).indexOf('.');
    const numDecimalPlaces = decimalLoc > -1 ? String(value).length - decimalLoc - 1 : 0;
    return parseFloat(String(value)).toFixed(Math.max(2, numDecimalPlaces));
  }

  // Return a new object with floats fixed without mutating original object
  private fixRate(sched: CommissionRate) {
    const shallowClone = Object.assign({}, sched);

    return numFields.reduce((accumulator, val) => {
      accumulator[val] = this.fixFloat(sched[val]);
      return accumulator;
    }, shallowClone);
  }

  private checkRoles() {
    try {
      this.isComplianceOfficer = this.authzService.currentUserHasRole('ComplianceApprover');
      this.isAccountAdmin = this.authzService.currentUserHasRole('AccountAdmin');
    } catch (e) {
      this.isComplianceOfficer = false;
      this.isAccountAdmin = false;
      console.error('Caught error while checking roles', e);
      return false;
    }
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private handleFailedUpdate(error: any, message: string) {
    console.error(message + JSON.stringify(error));
    let errorMessage = '';
    switch (error.code) {
      case 'permission-denied':
        errorMessage = 'Insufficient permissions';
        break;
      default:
        errorMessage = 'Unknown error occurred';
    }
    this.openSnackBar(`${message} ${errorMessage}`, 'DISMISS', false);
  }
}
