import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'atom-confirm-dialog',
  template: `
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content>
    <p>{{ message }}</p>
  </div>
  <div mat-dialog-actions>
    <button color="primary" mat-stroked-button [mat-dialog-close]="false">No</button>
    <button color="primary" mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
  </div>`,
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {
  title: string;
  message: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data = {}) {
    this.title = data['title'];
    this.message = data['message'];
  }
}
