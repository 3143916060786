<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Sales Codes List</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="card-title">Sales Codes</h2>
      </div>
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="salescodes-filter-field" #filter>
        <button mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <button *ngIf="exportable" aria-label="Export" id="export-sales-codes" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
      <mat-menu #exportMenu="matMenu">
        <button mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Sales Codes'))">Excel</button>
        <button mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Sales Codes'))">CSV</button>
        <button mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Sales Codes'))">JSON</button>
      </mat-menu>

      <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort class="mat-elevation-z0" (matSortChange)="handleSortChange()">
        <ng-container matColumnDef="salesCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.gt-xs="30">Sales Code</mat-header-cell>
          <mat-cell *matCellDef="let salesCode" fxFlex.xs="25" fxFlex.gt-xs="30">{{ salesCode.salesCode }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="isAssigned">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.gt-xs="30">Assigned</mat-header-cell>
          <mat-cell *matCellDef="let salesCode" fxFlex.xs="25" fxFlex.gt-xs="30">{{ salesCode.isAssigned | yesNo }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="assignmentDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="50" fxFlex.gt-xs="40">Assignment Date</mat-header-cell>
          <mat-cell *matCellDef="let salesCode" fxFlex.xs="50" fxFlex.gt-xs="40">{{ salesCode.assignmentDate | date }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row [ngClass]="{ 'assigned-sales-code' : salesCode.isAssigned }" (click)="selectSalesCode(salesCode)" *matRowDef="let salesCode; columns: columnsToDisplay"></mat-row>
      </mat-table>
      <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50, 100]" (page)="handlePageChange()"></mat-paginator>
    </div>
  </mat-card>
</div>
