import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatListOption } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Observable, of, Subject } from 'rxjs';
import { catchError, shareReplay, takeUntil, tap } from 'rxjs/operators';

import { AccountSeries, Status } from '@advance-trading/ops-data-lib';
import { AccountSeriesService } from '@advance-trading/angular-ops-data';

interface AccountSeriesBlock {
  name: string;
  selectedSeries: AccountSeries[];
  availableSeries$: Observable<AccountSeries[]>;
  defaultStart: string;
  assignedStart: string;
  nextBlockStart: string;
}

const BLOCK_LIMIT = 15;

@Component({
  selector: 'atom-account-series-detail',
  templateUrl: './account-series-detail.component.html',
  styleUrls: ['./account-series-detail.component.css']
})
export class AccountSeriesDetailComponent implements OnInit, OnDestroy {

  // navigation
  addMode: boolean;
  updateComplete = true;
  errorMessage: string;

  assignedAccountSeries$: Observable<AccountSeries[]>;
  accountSeriesByBlocks: {[key: string]: AccountSeriesBlock} = {
    digit : { name: 'All Digits', selectedSeries: [], defaultStart: '00000', nextBlockStart: 'C0000' } as AccountSeriesBlock,
    c : { name: 'C Block', selectedSeries: [], defaultStart: 'C0000', nextBlockStart: 'F0000' } as AccountSeriesBlock,
    f : { name: 'F Block', selectedSeries: [], defaultStart: 'F0000', nextBlockStart: 'W0000' } as AccountSeriesBlock,
    w : { name: 'W Block', selectedSeries: [], defaultStart: 'W0000', nextBlockStart: 'ZZZZZ' } as AccountSeriesBlock
  };

  @Input() salesCodeDocId: string;
  @Input() salesCode: string;
  @Input() canAddNewAccountSeries: boolean;

  assignedAccountSeries: AccountSeries[];
  selectedAccountSeries: AccountSeries[] = [];
  combinedAccountSeries: AccountSeries[];

  columnsToDisplay = [];
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private accountSeriesService: AccountSeriesService,
    private snackBar: MatSnackBar,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        // display only range and accounts icon columns for xsmall screens
        if (state.matches) {
          this.columnsToDisplay = [
            'range', 'icon'
          ];
        // display range, size, status, and accounts icon columns for larger screens
        } else {
          this.columnsToDisplay = [
            'range', 'size', 'status', 'icon'
          ];
        }
      });

    this.addMode = false;
    this.assignedAccountSeries$ = this.accountSeriesService.getAccountSeriesBySalesCode(this.salesCodeDocId)
      .pipe(
        tap((series: AccountSeries[]) => {
          this.assignedAccountSeries = series;
          this.combinedAccountSeries = series;
          return series;
        }),
        catchError(err => {
          this.errorMessage = 'Error retrieving account series; please try again later';
          console.error(`Error retrieving account series: ${err}`);
          return of([]);
        })
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Sets addMode to true and assigned the available series stream for each block on first call
   */
  addAccountSeries() {
    this.addMode = true;
    this.setAssignedStart();
    Object.values(this.accountSeriesByBlocks).forEach(
      (block: AccountSeriesBlock) => block.availableSeries$ = this.getAvailableAccountSeries(block));
  }

  /**
   * Resets all account series selected for assignment but unsaved.
   */
  reset() {
    this.addMode = false;
    Object.values(this.accountSeriesByBlocks).forEach(block => block.selectedSeries = []);
    this.selectedAccountSeries = [];
    this.combinedAccountSeries = this.assignedAccountSeries;
  }

  /**
   * Prevents the default keyvalue pipe behavior of sorting by key order
   * Adding here to ensure tabs are displayed in the order added
   */
  asIsOrder() {
    return 1;
  }

  /**
   * Replaces a new status with 'Selected'
   * Replaces a pending status with 'Assigned'
   * @param status Account Series status
   */
  displayStatus(status: string): string {
    switch (status) {
      case Status.NEW:
        return 'Selected';
      case Status.PENDING:
        return 'Assigned';
      default:
        return status;
    }
  }

  /**
   * Tracks which account series have been selected for assignment from each block
   * Updates the master selectedAccountSeries array with all selected account series
   * Consolidates and udpates the master combinedAccountSeries array with all the assigned and selected account series
   * @param block key from the accountSeriesByBlocks map
   * @param selectedOptions the options that have been selected from the list
   */
  onSelection(block: string, selectedOptions: MatListOption[]) {
    this.accountSeriesByBlocks[block].selectedSeries = [];
    selectedOptions.forEach(option => {
      this.accountSeriesByBlocks[block].selectedSeries.push(option.value);
    });
    let acctSeries: AccountSeries[] = [];
    Object.values(this.accountSeriesByBlocks).forEach(acctSeriesBlock => acctSeries = acctSeries.concat(acctSeriesBlock.selectedSeries));
    this.selectedAccountSeries = acctSeries;
    this.combinedAccountSeries = this.assignedAccountSeries.concat(acctSeries).sort((a, b) => (a.start > b.start) ? 1 : -1);
  }

  /**
   * Updates all selected/unassigned account series with a pending status and sale code assignment details
   */
  submit() {
    this.addMode = false;
    this.updateComplete = false;

    Promise.all(
        this.selectedAccountSeries.map((series: AccountSeries) => {
          series.salesCode = this.salesCode;
          series.salesCodeDocId = this.salesCodeDocId;
          series.status = Status.PENDING;
          series.assignmentDate = new Date().toISOString();
          return this.accountSeriesService.updateAccountSeries(series);
        })
      )
      .then(() => {
        console.log(`Account series successfully updated: ${JSON.stringify(this.selectedAccountSeries)}`);
        this.updateComplete = true;
        this.openSnackBar('Account series successfully assigned', 'DISMISS', true);
      })
      .catch(err => {
        console.error(`Account series assignment failed: ${JSON.stringify(err)}`);
        this.updateComplete = true;
        let errorMessage = '';
        switch (err.code) {
          case 'permission-denied':
            errorMessage = 'Insufficient permissions';
            break;
          default:
            errorMessage = 'Unknown error occurred';
        }
        this.openSnackBar(`Account series assignment failed: ${errorMessage}`, 'DISMISS', false);
      });
  }

  selectAccountsIcon(accountSeries: AccountSeries) {
    const seriesRange = accountSeries.start + ' - ' + accountSeries.end;
    this.router.navigate(['/accounts'], { queryParams: { accountSeriesDocId: accountSeries.docId, accountSeriesRange: seriesRange } });
  }

  // Sets the assignedStart value for each block to be used to in the query for available account series.
  // If account series are already assigned, the list of available account series will begin with nearby ranges.
  // Otherwise the defaultStart value will be used starting at the beginning of each block.
  private setAssignedStart() {
    if (this.assignedAccountSeries.length === 0) {
      return;
    }
    const digitStart = this.assignedAccountSeries.find(series => !isNaN(parseInt(series.start.substring(0, 1), 10)));
    const cStart = this.assignedAccountSeries.find(series => series.start.substring(0, 1) === 'C');
    const fStart = this.assignedAccountSeries.find(series => series.start.substring(0, 1) === 'F');
    const wStart = this.assignedAccountSeries.find(series => series.start.substring(0, 1) === 'W');
    this.accountSeriesByBlocks['digit'].assignedStart = digitStart ? digitStart.start : undefined;
    this.accountSeriesByBlocks['c'].assignedStart = cStart ? cStart.start : undefined;
    this.accountSeriesByBlocks['f'].assignedStart = fStart ? fStart.start : undefined;
    this.accountSeriesByBlocks['w'].assignedStart = wStart ? wStart.start : undefined;
  }

  // Retrieves unassigned account series for a specific block starting at a given start value
  private getAvailableAccountSeries(block: AccountSeriesBlock): Observable<AccountSeries[]> {
    return this.accountSeriesService.getAccountSeriesByStatusAndBlock(
      Status.NEW, block.assignedStart || block.defaultStart, block.nextBlockStart, BLOCK_LIMIT)
        .pipe(
          shareReplay(1)
        );
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

}
