import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { Account, ExecutionReport, Order, OrderStatus, OrderType, Side } from '@advance-trading/ops-data-lib';

export interface OrderFill {
  orderDocId: string;
  accountNumber: string;
  orderType: OrderType;
  orderQuantity: number;
  fillQuantity: number;
  targetPrice: number;
  fillPrice: number;
  security: string;
  fillTimestamp: string;
  legFills?: {[key: string]: OrderLegFill};
}

export interface OrderLegFill {
  security: string;
  side: Side;
  fillPrice: number;
}

export interface OrderFillSummary {
  orderDocId: string;
  fillPrice: number;
  isSplitFilled: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ExecutionReportService {

  constructor(
    private db: AngularFirestore,
  ) { }

  // Queries Using GET

  /**
   * Return all Execution Report documents for a particular order
   *
   * @param accountDocId The docId of the Account containing the Execution Reports
   * @param orderDocId The docId of the Order containing the Execution Reports
   */
  getAllExecutionReportsByOrderDocId(accountDocId: string, orderDocId: string): Observable<ExecutionReport[]> {
    return this.db.collection<ExecutionReport>(`${Account.getDataPath(accountDocId)}/${Order.getDataPath(orderDocId)}/${ExecutionReport.getDataPath()}`)
      .get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as ExecutionReport) as ExecutionReport[])
      );
  }

  /**
   * Return `OrderFill`s for each unique fill price on an order and its corresponding legs
   * Partial fills that fill at the same price will be combined into a single `OrderFill`
   * mapped into its unique order id
   * @param clientDocId The docId of the Client containing the Execution Reports
   * @param accountDocId The docId of the Account containing the Execution Reports
   * @param startDate The date before or when the ExecutionReport is filled
   * @param endDate The date after or when the ExecutionReport is filled
   */
  getOrderFillsByAccountDocIdAndDate(clientDocId: string, accountDocId: string, startDate: string, endDate: string)
    : Observable<{[key: string]: OrderFill[]}> {
    return this.db.collectionGroup<ExecutionReport>(`${ExecutionReport.getDataPath()}`,
    ref => ref.where('clientDocId', '==', clientDocId)
    .where('accountDocId', '==', accountDocId)
    .where('orderStatus', 'in', [OrderStatus.PARTIALLY_FILLED, OrderStatus.FILLED])
    .where('transactTime', '>=', startDate).where('transactTime', '<=', endDate)).get().pipe(
      map(querySnap => querySnap.docs.map(doc => doc.data() as ExecutionReport) as ExecutionReport[]),
      map((filledReports: ExecutionReport[]) => {
        // get a grouped execution reports by its order doc id
        const mappedReports: {[key: string]: ExecutionReport[]} = {};
        filledReports.forEach((report: ExecutionReport) => {
          const orderDocId = report.exchangeOrderId;
          if (!mappedReports[orderDocId]) {
            mappedReports[orderDocId] = [];
          }
          mappedReports[orderDocId].push(report);
        });

        // get a mapped order fill
        const mappedOrderFill: {[key: string]: OrderFill[]} = {};
        Object.keys(mappedReports).forEach((orderDocId: string) => {
          mappedOrderFill[orderDocId] = this.getConsolidatedOrderFills(mappedReports[orderDocId]);
        });

        return mappedOrderFill;
      }),
    );
  }

  /**
   * Return `OrderFill`s for each unique fill price on an order and its corresponding legs
   * Partial fills that fill at the same price will be combined into a single `OrderFill`
   * @param accountDocId The docId of the Account containing the Execution Reports
   * @param orderDocId The docId of the Order containing the Execution Reports
   */
  getOrderFillsByOrderDocId(accountDocId: string, orderDocId: string): Observable<OrderFill[]> {
    return this.db.collection<ExecutionReport>
    (`${Account.getDataPath(accountDocId)}/${Order.getDataPath(orderDocId)}/${ExecutionReport.getDataPath()}`,
    ref => ref.where('orderStatus', 'in', [OrderStatus.PARTIALLY_FILLED, OrderStatus.FILLED])).get().pipe(
      map(querySnap => querySnap.docs.map(doc => doc.data() as ExecutionReport) as ExecutionReport[]),
      map((filledReports: ExecutionReport[]) => {
        return this.getConsolidatedOrderFills(filledReports);
      }),
    );
  }

  /**
   * Return the `OrderFillSummary` for an order
   * In the event of a split filled order, the `OrderFillSummary` will contain the price at which the greatest number of contracts filled
   * @param accountDocId The docId of the Account containing the Execution Reports
   * @param orderDocId The docId of the Order containing the Execution Reports
   */
  getOrderFillSummaryByOrderDocId(accountDocId: string, orderDocId: string): Observable<OrderFillSummary> {
    return this.getOrderFillsByOrderDocId(accountDocId, orderDocId).pipe(
      map((orderFills: OrderFill[]) => {
        if (orderFills.length === 1) {
          return { orderDocId: orderFills[0].orderDocId, fillPrice: orderFills[0].fillPrice, isSplitFilled: false };
        } else if (orderFills.length > 1) {
          // sort fills by quantity and use the fill with the greatest quantity for price in the event of a split filled order
          const fillWithGreatestQuantity = orderFills.sort((fillA, fillB) => fillA.fillQuantity < fillB.fillQuantity ? 1 : -1)[0];
          return { orderDocId: fillWithGreatestQuantity.orderDocId, fillPrice: fillWithGreatestQuantity.fillPrice, isSplitFilled: true};
        } else {
          // no fills on the order
          return undefined;
        }
      })
    );
  }

  // Queries Using ValueChanges

  /**
   * Return `OrderFill`s for each unique fill price on an order and its corresponding legs
   * Partial fills that fill at the same price will be combined into a single `OrderFill`
   * mapped into its unique order id
   * @param clientDocId The docId of the Client containing the Execution Reports
   * @param accountDocId The docId of the Account containing the Execution Reports
   * @param startDate The date before or when the ExecutionReport is filled
   * @param endDate The date after or when the ExecutionReport is filled
   */
  findOrderFillsByAccountDocIdAndDate(clientDocId: string, accountDocId: string, startDate: string, endDate: string)
    : Observable<{[key: string]: OrderFill[]}> {
    return this.db.collectionGroup<ExecutionReport>(`${ExecutionReport.getDataPath()}`,
    ref => ref.where('clientDocId', '==', clientDocId)
    .where('accountDocId', '==', accountDocId)
    .where('orderStatus', 'in', [OrderStatus.PARTIALLY_FILLED, OrderStatus.FILLED])
    .where('transactTime', '>=', startDate).where('transactTime', '<=', endDate)).valueChanges().pipe(
      map((filledReports: ExecutionReport[]) => {
        // get a grouped execution reports by its order doc id
        const mappedReports: {[key: string]: ExecutionReport[]} = {};
        filledReports.forEach((report: ExecutionReport) => {
          const orderDocId = report.exchangeOrderId;
          if (!mappedReports[orderDocId]) {
            mappedReports[orderDocId] = [];
          }
          mappedReports[orderDocId].push(report);
        });

        // get a mapped order fill
        const mappedOrderFill: {[key: string]: OrderFill[]} = {};
        Object.keys(mappedReports).forEach((orderDocId: string) => {
          mappedOrderFill[orderDocId] = this.getConsolidatedOrderFills(mappedReports[orderDocId]);
        });

        return mappedOrderFill;
      }),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }

  /**
   * Return `OrderFill`s for each unique fill price on an order and its corresponding legs
   * Partial fills that fill at the same price will be combined into a single `OrderFill`
   * @param accountDocId The docId of the Account containing the Execution Reports
   * @param orderDocId The docId of the Order containing the Execution Reports
   */
  findOrderFillsByOrderDocId(accountDocId: string, orderDocId: string): Observable<OrderFill[]> {
    return this.db.collection<ExecutionReport>
    (`${Account.getDataPath(accountDocId)}/${Order.getDataPath(orderDocId)}/${ExecutionReport.getDataPath()}`,
    ref => ref.where('orderStatus', 'in', [OrderStatus.PARTIALLY_FILLED, OrderStatus.FILLED])).valueChanges().pipe(
      map((filledReports: ExecutionReport[]) => {
        return this.getConsolidatedOrderFills(filledReports);
      }),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }

  private getConsolidatedOrderFills(filledReports: ExecutionReport[]): OrderFill[] {
    const consolidatedOrderFills: OrderFill[] = [];
    const orderFills: {[key: string]: OrderFill} = {};

    filledReports.forEach((report: ExecutionReport) => {
      if (report.multiLegReportingType !== '2') {
        const key = report.secondaryExecutionId || report.docId;
        if (orderFills[key]) {
          orderFills[key].fillPrice = report.lastPrice;
        } else {
          orderFills[key] = {
            orderDocId: report.exchangeOrderId,
            accountNumber: report.account.substring(3),
            orderType: report.orderType,
            orderQuantity: report.orderQuantity,
            fillQuantity: report.lastQuantity,
            targetPrice: report.price,
            fillPrice: report.lastPrice,
            security: report.security,
            fillTimestamp: report.transactTime
          } as OrderFill;
        }
      // multiLegReportingType === '2', skipping legs if no secondaryExecutionId for backward compatibility
      } else if (report.secondaryExecutionId) {
        const key = report.secondaryExecutionId;
        const orderLegFill = { security: report.security, side: report.side, fillPrice: report.lastPrice } as OrderLegFill;
        const orderLegKey: string = report.security + report.side;
        if (orderFills[key]) {
          if (orderFills[key].legFills) {
            orderFills[key].legFills[orderLegKey] = orderLegFill;
          } else {
            orderFills[key].legFills = {};
            orderFills[key].legFills[orderLegKey] = orderLegFill;
          }
        } else {
          const orderLegFills = {};
          orderLegFills[orderLegKey] = orderLegFill;
          orderFills[key] = {
            orderDocId: report.exchangeOrderId,
            accountNumber: report.account.substring(3),
            orderType: report.orderType,
            orderQuantity: report.orderQuantity,
            fillQuantity: report.lastQuantity,
            security: report.security,
            fillTimestamp: report.transactTime,
            legFills: orderLegFills
          } as OrderFill;
        }
      }
    });

    Object.values(orderFills).forEach((orderFill: OrderFill) => {
      // Check if OrderFill exists for current price
      const fillIndex = consolidatedOrderFills.findIndex((fill: OrderFill) => this.isSameOrderFills(orderFill, fill));

      // Fill already exists for price, update fill quantity
      if (fillIndex !== -1) {
        consolidatedOrderFills[fillIndex].fillQuantity += orderFill.fillQuantity;
        // use the last consolidated fillTimestamp
        consolidatedOrderFills[fillIndex].fillTimestamp = orderFill.fillTimestamp;
      // Fill does not yet exist for price, create new OrderFill
      } else {
        consolidatedOrderFills.push({
          orderDocId: orderFill.orderDocId,
          accountNumber: orderFill.accountNumber,
          orderType: orderFill.orderType,
          orderQuantity: orderFill.orderQuantity,
          fillQuantity: orderFill.fillQuantity,
          targetPrice: orderFill.targetPrice,
          fillPrice: orderFill.fillPrice,
          security: orderFill.security,
          fillTimestamp: orderFill.fillTimestamp,
          legFills: orderFill.legFills
        } as OrderFill);
      }
    });

    return consolidatedOrderFills;
  }

  private isSameOrderFills(orderFill1: OrderFill, orderFill2: OrderFill): boolean {
    const isSameOrderFillPrice =  orderFill1.fillPrice === orderFill2.fillPrice;

    if (orderFill1.legFills) {
      let isSameLegFills = true;
      Object.keys(orderFill1.legFills).forEach((orderLegKey: string) => {
        if (!orderFill2.legFills[orderLegKey] ||
            orderFill1.legFills[orderLegKey].fillPrice !== orderFill2.legFills[orderLegKey].fillPrice) {
          isSameLegFills = false;
        }
      });
      return isSameLegFills && isSameOrderFillPrice;
    } else {
      return isSameOrderFillPrice;
    }
  }

}
