<div fxFlex="100">

  <div *ngIf="!errorMessage" fxLayout="column">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="rders-filter-field" #filter>
      <button mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <button *ngIf="selectedOrders$ | async" aria-label="Export" id="export-orders" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
    <mat-menu #exportMenu="matMenu">
      <button mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Orders'))">Excel</button>
      <button mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Orders'))">CSV</button>
      <button mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Orders'))">JSON</button>
    </mat-menu>

    <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort matSortActive="lastUpdatedTimestamp" matSortDirection="desc" class="mat-elevation-z0" (matSortChange)="handleSortChange()">

      <ng-container matColumnDef="accountNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.md="15" fxFlex.gt-md="8">Account</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.md="15" fxFlex.gt-md="8">{{ order.accountNumber }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="brokerCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.md="15" fxFlex.gt-md="5">Broker Code</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.md="15" fxFlex.gt-md="5">{{ order.brokerCode }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="docId">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.md="15" fxFlex.gt-md="10">Order #</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.md="15" fxFlex.gt-md="10">{{ order.docId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="spreadStrategy">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.md="10" fxFlex.gt-md="5">Outright/<br>Spread</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.md="10" fxFlex.gt-md="5">{{ order.spreadStrategy ? 'Spread': 'Outright' }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="creationTimestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.md="10" fxFlex.gt-md="9">Created</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.md="10" fxFlex.gt-md="9"><span class="forced-wrap">{{ order.creationTimestamp | date: 'MM/dd/yyyy hh:mm:ss a' }}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastUpdatedTimestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.md="10" fxFlex.gt-md="9">Updated</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.md="10" fxFlex.gt-md="9"><span class="forced-wrap">{{ order.lastUpdatedTimestamp | date: 'MM/dd/yyyy hh:mm:ss a' }}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="side">
        <mat-header-cell *matHeaderCellDef mat-sort-header  fxFlex.gt-md="4">Side</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.gt-md="4">{{ order.spreadStrategy ? (order.legSide | titlecase) : (order.side | titlecase) }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="4">Qty</mat-header-cell>
        <mat-cell *matCellDef="let order"  fxFlex.gt-md="4">{{ getDisplayQuantity(order) }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="commodity">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="6">Commodity</mat-header-cell>
        <mat-cell *matCellDef="let order"  fxFlex.gt-md="6"> <span class="forced-wrap">{{ getCommodityDisplayName(order) }}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="contractYearMonth">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.md="10" fxFlex.gt-md="4">Future</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.md="10" fxFlex.gt-md="4"> <span class="forced-wrap">{{ order.contractYearMonth | codeToMonth: 'short' }}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="strikePrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="4">Strike</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.gt-md="4"><span>{{ getDisplayStrike(order.strikePrice, order) | number: '1.2' }}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="5">Target</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.gt-md="5">
          <ng-container>{{ !displayTargetPrice(order) ? 'Market' : (getDisplayPrice(order.price, order.securityType, order.symbol) | number:'1.2-5') }}</ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="fillPrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="5">Fill</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.gt-xs="5">
          <ng-container>{{ order.fillPrice | number:'1.2-5' }}</ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="5">Type</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.gt-md="5">{{ order.type | replace | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="securitySubType">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="4">P/C</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.gt-md="4">{{ order.securitySubType | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="timeInForce">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="6">TIF</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.gt-md="6">{{ getDisplayTIF(order) }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="7">Status</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.gt-md="7">{{ order.status | replace | titlecase }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row (click)="selectOrder(order)" *matRowDef="let order; columns: columnsToDisplay"></mat-row>
    </mat-table>
    <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50, 100]" (page)="handlePageChange()"></mat-paginator>

  </div>
</div>
