import { gql } from 'apollo-angular';

export class PositionQueries {

  static getPositionsByAccountsQuery = gql<any, any>`
  query PositionsByAccounts($accounts: [String!], $positionQuery: PositionQuery) {
    positionsByAccounts(accounts: $accounts, positionQuery: $positionQuery) {
      docId
      officeCode
      salesCode
      clientName
      clientDocId
      accountDocId
      accountNumber
      side
      contractYearMonth
      totalQuantity
      securityDescription
      securityTypeCode
      commodityId
      strikePrice
      securitySubType
      lastUpdatedTimestamp
      securityType
    }
  }`;

  static getPositionsByBrokerCodesQuery = gql<any, any>`
  query PositionsByBrokerCodes($brokerCodes: [String!], $positionQuery: PositionQuery) {
    positionsByBrokerCodes(brokerCodes: $brokerCodes, positionQuery: $positionQuery) {
      docId
      officeCode
      salesCode
      clientName
      clientDocId
      accountDocId
      accountNumber
      side
      contractYearMonth
      totalQuantity
      securityDescription
      securityTypeCode
      commodityId
      strikePrice
      securitySubType
      lastUpdatedTimestamp
      securityType
    }
  }`;
}
