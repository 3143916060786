import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Client, Contact, PhoneNumber } from '@advance-trading/ops-data-lib';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ContactService } from '@advance-trading/angular-ops-data';
import { ClientService } from '../../services/client-service';
import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { filter, switchMap, tap, catchError, takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent } from '../../utilities/ui/confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';

const UPDATE_ROLE = 'AccountAdmin';

@Component({
  selector: 'atom-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.css']
})
export class ContactDetailComponent implements OnInit, OnDestroy {
  contactForm = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    lastName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    email: new FormControl('', [Validators.email, Validators.maxLength(200)]),
    title: new FormControl('', [Validators.maxLength(100)]),
    birthDate: new FormControl(''),
    speedDial: new FormControl('', [Validators.maxLength(4), Validators.minLength(3)]),
    additionalNotes: new FormControl('', [Validators.maxLength(400)]),
    address: new FormGroup({
      street1: new FormControl('', [
        Validators.required,
        Validators.maxLength(200)
      ]),
      street2: new FormControl('', [
        Validators.maxLength(200)
      ]),
      city: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      region: new FormControl('', [
        Validators.required
      ]),
      postalCode: new FormControl('', [
        Validators.required
      ]),
      country: new FormControl('', [
        Validators.required
      ]),
    }),
  });

  client$: Observable<Client>;
  contact$: Observable<Contact>;
  clientDocId: string;
  contact: Contact; // Used to store original from Firestore
  phoneNumbers: PhoneNumber[] = [];

  errorMessage: string;
  updateComplete = true;
  editMode = false;
  createMode = false;
  canView = false;
  canUpdate = false;

  // Indicators for hide/show
  enableAddress = false;
  deleteAddress = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  @ViewChild('birthDatePicker', { static: false }) birthDateRef;

  constructor(
    public snackBar: MatSnackBar,
    private clientService: ClientService,
    private contactService: ContactService,
    private auth0Service: Auth0AuthzService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.canUpdate = this.auth0Service.currentUserHasRole(UPDATE_ROLE);

    this.route.paramMap.pipe(takeUntil(this.unsubscribe$)).subscribe((params: ParamMap) => {
      this.editMode = !params.has('contactDocId');
      this.createMode = !params.has('contactDocId');
      this.contactForm.get('address').disable();

      if (this.createMode && !this.canUpdate) {
        this.errorMessage = 'You do not have permission to create a new contact for this Client.';
      }
      if (params.has('contactDocId')) {
        this.contactForm.disable();
      }
    });

    this.client$ = this.route.paramMap.pipe(
      filter((params: ParamMap) => params.has('clientDocId')),
      switchMap((params: ParamMap) => {
        this.clientDocId = params.get('clientDocId');
        return this.clientService.getClient(params.get('clientDocId'));
      })
    );

    this.contact$ = this.route.paramMap.pipe(
      filter((params: ParamMap) => params.has('contactDocId')),
      switchMap((params: ParamMap) => {
        return this.contactService.getContactByDocId(params.get('clientDocId'), params.get('contactDocId'));
      }),
      tap(newContact => this.handleContactObject(newContact)),
      catchError((err) => {
        this.errorMessage = 'This contact either does not exist or you do not have permission to view the information.';
        console.error('Error occurred fetching client and contact info: ' + err);
        return this.contact$;
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectBirthDate(e: MatDatepickerInputEvent<Date>) {
    if (e.target.validate) {
      this.contactForm.get('birthDate').setValue(e.value);
      this.birthDateRef.close();
    }
  }

  setEditMode(mode) {
    this.editMode = mode;
    if (this.editMode) {
      this.contactForm.enable();
      if (!this.contact.address && !this.enableAddress) {
        this.contactForm.get('address').disable();
      }
    } else { // Abandon an update
      this.contactForm.reset(this.contact);
      this.contactForm.disable();
      this.deleteAddress = false;
      this.handleContactObject(this.contact);
    }
    this.contactForm.markAsPristine();
  }

  reset() {
    this.contactForm.reset();
    this.phoneNumbers = [];
  }

  async saveForm() {
    this.updateComplete = false;
    const isNewContact = !this.contact;

    const contact = Object.assign(isNewContact ? new Contact() : {}, this.contact, this.contactForm.value);
    contact.phoneNumbers = this.phoneNumbers;

    if (contact.birthDate) {
      contact.birthDate = moment(contact.birthDate).format('MM-DD');
    }

    if (this.deleteAddress) {
      delete contact.address;
      this.deleteAddress = false;
    }

    if (isNewContact) {
      this.contactService.createContact(this.clientDocId, contact)
        .then(response => {
          console.log('Contact successfully created');
          this.updateComplete = true;
          this.router.navigate(['../', contact.docId], { relativeTo: this.route, replaceUrl: true });
          this.openSnackBar('Contact successfully created', 'DISMISS', true);
        })
        .catch(err => {
          this.updateComplete = true;
          console.error('Contact creation failed: ' + JSON.stringify(err));
          let errorMessage = '';
          switch (err.code) {
            case 'permission-denied':
              errorMessage = 'Insufficient permissions';
              break;
            default:
              errorMessage = 'Unknown error occurred';
          }
          this.openSnackBar('Contact creation failed: ' + errorMessage, 'DISMISS', false);
        });
    } else {
      this.contactService.updateContact(this.clientDocId, contact)
        .then(response => {
          console.log('Contact successfully updated');
          this.updateComplete = true;
          this.setEditMode(false);
          this.openSnackBar('Contact successfully updated', 'DISMISS', true);
        })
        .catch(err => {
          this.updateComplete = true;
          console.error('Contact update failed: ' + JSON.stringify(err));
          let errorMessage = '';
          switch (err.code) {
            case 'permission-denied':
              errorMessage = 'Insufficient permissions';
              break;
            default:
              errorMessage = 'Unknown error occurred';
          }
          this.openSnackBar('Contact update failed: ' + errorMessage, 'DISMISS', false);
        });
    }
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('maxlength')) {
      return `Value cannot exceed ${control.getError('maxlength')['requiredLength']} characters`;
    } else if (control.hasError('email')) {
      return `Invalid email address`;
    } else if (control.hasError('matDatepickerParse')) {
      return `Invalid date`;
    } else if (control.hasError('minlength')) {
      return `Value must be at least ${control.getError('minlength')['requiredLength']} characters`;
    }
    return 'Unknown error';
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  onPhoneNumberCreated(phoneNumber: PhoneNumber) {
    this.phoneNumbers.push(phoneNumber);
    this.contactForm.markAsDirty();
  }

  onPhoneNumberRemoved(index?: number) {
    if (this.phoneNumbers.length > 1) {
      this.phoneNumbers.splice(index, 1);
      this.contactForm.markAsDirty();
    } else {
      this.openSnackBar(`Contact must contain at least one phone number`, 'DISMISS', false);
    }
  }

  toggleAddressForm() {
    this.enableAddress = !this.enableAddress;

    if (this.enableAddress) {
      this.contactForm.get('address').enable();
    } else {
      this.contactForm.get('address').disable();
    }
  }

  clearAddress() {
    this.contactForm.get('address').reset();
    this.toggleAddressForm();
    this.deleteAddress = true;
    this.contactForm.markAsDirty();
  }

  private handleContactObject(contact: Contact) {
    this.contact = Object.assign({}, contact);
    if (contact.address) {
      this.enableAddress = true;
    }

    if (!this.contactForm.get('address').get('street2').value) {
      this.contactForm.get('address').get('street2').setValue('');
    }

    this.phoneNumbers = Array.from(contact.phoneNumbers || []);
    this.contactForm.reset();
    this.contactForm.patchValue(contact);

    if (contact.birthDate) {
      this.contactForm.get('birthDate').patchValue(moment(contact.birthDate, 'MM-DD'));
    }
  }

  deleteContact(contact: Contact) {
    this.contactService.deleteContact(this.clientDocId, contact);
  }

  deleteContactMessage(contact: Contact) {
    if (contact.isAuthorizedTrader || contact.isAuthorizedSigner) {
      const title = 'This contact needs to be removed from a clearing firm before deletion.';
      const message = 'Would you like to navigate to clearing firms?';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: { title, message },
        height: '250px',
        width: '400px',
      });

      dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(confirm => {
        if (!confirm) {
          return;
        } else {
          this.router.navigate(['clients', this.clientDocId]);
        }
      });
    } else {
      this.openConfirmDialog(contact, 'Delete Contact', 'This will permanently delete this contact, do you want to continue?');
    }
  }

  displayDate(date: moment.Moment): string {
    if (date && date.isValid()) {
      return date.format('MM-DD');
    }
    return '-';
  }

  displaySpeedDial(dial: string): string {
    if (dial) {
      return '*' + dial;
    }
    return '-';
  }

  private openConfirmDialog(contact: Contact, title: string, message: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title, message },
      height: '250px',
      width: '400px'
    });

    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(confirm => {
      if (!confirm) {
        return;
      } else {
        this.router.navigate(['../'], { relativeTo: this.route }).then(() => {
          this.deleteContact(contact);
        });
      }
    });
  }
}
