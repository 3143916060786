import { FormControl, ValidatorFn, Validators } from '@angular/forms';

export class AdjustmentSearchFormValidator {

  constructor() { }

  accountsValidator(): ValidatorFn {
    return (control: FormControl) => {
      return control.validator = Validators.pattern('([a-zA-Z0-9]*[,]\\s?)*([a-zA-Z0-9]*){1}');
    };
  }

  brokerCodesValidator(): ValidatorFn {
    return (control: FormControl) => {
      return control.validator = Validators.pattern('([a-zA-Z0-9]{6}[,]\\s?)*([a-zA-Z0-9]{6}){1}');
    };
  }

  strikeValidator(): ValidatorFn {
    return (control: FormControl) => {
      return control.validator = Validators.pattern('[1-9][0-9]*[.]?[0-9]*');
    };
  }

  getErrorMessages(control: FormControl) {
    if (control === control.parent.get('accounts') && control.hasError('pattern')) {
      return 'Invalid Account Numbers';
    } else if (control === control.parent.get('brokerCodes') && control.hasError('pattern')) {
      return 'Invalid Broker Codes';
    } else if ((control === control.parent.get('startBusinessDate') || control === control.parent.get('endBusinessDate'))) {
      return 'Invalid Business Date';
    } else if ((control === control.parent.get('startTradeDate') || control === control.parent.get('endTradeDate'))) {
      return 'Invalid Trade Date';
    } else {
      return 'Invalid Value';
    }
  }
}

