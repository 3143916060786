<div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="10">
  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
      <h6 *ngIf="filteredBy" class="card-brow">{{ filteredBy | uppercase }}</h6>

      <div *ngIf="errorMessage">
        <h2 class="card-title">Error Performing Accounts Search</h2>
        <p [innerHTML]="errorMessage"></p>
        <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
      </div>

        <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
          <h2 class="card-title">Accounts</h2>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <button id="add-new-account" *ngIf="accountAdmin" aria-label="Add New Account" (click)="addNewAccount()"
              mat-mini-fab color="accent" matTooltip="Add New Account">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>

        <form *ngIf="!errorMessage" [formGroup]="accountSearchForm" (ngSubmit)="searchAccounts(true)" fxLayout="column">
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="70" (click)="accountSearchNumberFieldClicked()">
            <mat-label>Search Account Number</mat-label>
            <input matInput formControlName="accountSearchNumber" trim="blur" maxLength="5" upperCase mask="A*">
            <mat-error *ngIf="accountSearchForm.get('accountSearchNumber').invalid" >{{ getErrorMessage(accountSearchForm.get('accountSearchNumber')) }}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="70" (click)="brokerCodeFieldClicked()">
            <mat-label>Search Broker Code</mat-label>
            <input matInput formControlName="brokerCode" upperCase trim="blur" maxLength="6" mask="A*">
            <mat-error *ngIf="accountSearchForm.get('brokerCode').invalid" >{{ getBrokerCodeErrorMessage(accountSearchForm.get('brokerCode')) }}</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <mat-spinner *ngIf="isSearchingClientNames" [diameter]="30"></mat-spinner>
          <mat-form-field *ngIf="!isSearchingClientNames" fxFlex.gt-xs="45" fxFlex.xs="70" (click)="clientFieldClicked()">
            <input #clientNameSearch matInput type="text" placeholder="Search Client" formControlName="client" [matAutocomplete]="autoClient">
            <mat-error *ngIf="accountSearchForm.get('client').invalid">You must select a valid client</mat-error>
          </mat-form-field>
          <mat-autocomplete #autoClient="matAutocomplete" [displayWith]="displayClient">
            <mat-option *ngFor="let client of filteredClients$ | async" [value]="client">{{ displayClient(client) }}
            </mat-option>
          </mat-autocomplete>
          <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="70">
            <mat-label>Account Status</mat-label>
            <mat-select [disableOptionCentering]="true" formControlName="accountStatus">
              <mat-option value="ALL">All</mat-option>
              <mat-option value="ACTIVE">Active</mat-option>
              <mat-option value="INACTIVE">Inactive</mat-option>
              <mat-option value="PENDING">Pending</mat-option>
              <mat-option value="CLOSED">Closed</mat-option>
              <mat-option value="DENIED">Denied</mat-option>
          </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="!isSearching" fxLayout="row" fxLayoutGap="10px">
          <button id="submit-account-search" [disabled]="accountSearchForm.invalid" aria-label="Search" type="submit"
            mat-raised-button color="accent">Search</button>
          <button id="clear-account-search" *ngIf="!accountSearchForm.pristine"  aria-label="Reset" (click)="reset()"
            mat-stroked-button color="accent">Reset</button>
        </div>

        <div *ngIf="isSearching" fxLayout="row" fxLayoutGap="10px">
          <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
        </div>

      </form>

      <div *ngIf="showAccounts && !errorMessage" class="account-list">
        <atom-accounts (accountListChange)="handleAccountListChange($event)" (accountSearchError)="handleAccountError($event)" (isSearching)="handleIsSearching($event)"
          [selectedAccounts$]="selectedAccounts$" [initialTableState]="tableState"></atom-accounts>
      </div>

    </mat-card>
  </div>
</div>
