import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, OnDestroy, } from '@angular/core';
import { Contact, FCMAuthorizedSigner } from '@advance-trading/ops-data-lib';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ContactService } from '@advance-trading/angular-ops-data';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { filter, switchMap, map, tap, startWith } from 'rxjs/operators';

@Component({
  selector: 'atom-fcm-authorized-signer',
  templateUrl: './fcm-authorized-signer.component.html',
  styleUrls: ['./fcm-authorized-signer.component.css'],
})
export class FcmAuthorizedSignerComponent implements OnChanges, OnInit {
  @Input() authorizedSigner: FCMAuthorizedSigner;
  @Input() editMode = false;
  @Input() allSigners: FCMAuthorizedSigner[] = [];
  @Output() signerUpdated = new EventEmitter<FCMAuthorizedSigner>();
  @Output() signerRemoved = new EventEmitter<boolean>();

  signersForm = new FormGroup({
    authorizationDate: new FormControl('', [Validators.required]),
    contact: new FormControl('', [Validators.required])
  });

  contacts$: Observable<Contact[]>;
  contact$: Observable<Contact>;
  private contacts: Contact[];
  filteredContacts$: Observable<Contact[]>;

  constructor(
    private contactService: ContactService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {

    this.contacts$ = this.route.paramMap.pipe(
      filter((params: ParamMap) => params.has('clientDocId')),
      switchMap((params: ParamMap) => {
        return this.contactService.getAllContactsByClientDocId(params.get('clientDocId'));
      }),
      tap(contacts => {
        this.contacts = contacts || [];
      })
    );

    this.filteredContacts$ = this.signersForm.get('contact').valueChanges
      .pipe(
        startWith<string | Contact>(''),
        filter(value => typeof value === 'string'),
        map((contactName: string) => {
          if (!this.contacts) {
            return [];
          } else if (contactName) {
            return this.contacts.filter(contact => {
              const searchValue = contactName.toLowerCase();
              const first = contact.firstName.toLowerCase();
              const last = contact.lastName.toLowerCase();

              const fullName = `${first} ${last}`;
              return fullName.includes(searchValue) &&
                !this.allSigners.find((authorizedSigner) => contact.docId === authorizedSigner.contactDocId);
            });
          } else {
            return this.contacts.filter(contact =>
              !this.allSigners.find((authorizedSigner) => contact.docId === authorizedSigner.contactDocId));
          }
        })
      );

    if (this.authorizedSigner) {
      this.contact$ = this.route.paramMap.pipe(
        filter((params: ParamMap) => params.has('clientDocId')),
        switchMap((params: ParamMap) => {
          return this.contactService.getContactByDocId(params.get('clientDocId'), this.authorizedSigner.contactDocId);
        }),
        tap((contact: Contact) => {
          this.setupSignersForm(contact);
        })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editMode']) {
      if (this.editMode) {
        this.signersForm.enable();
      } else {
        this.signersForm.disable();
      }
    }
  }

  displayFn(contact: Contact): string {
    if (contact) {
      return contact.firstName + ' ' + contact.lastName;
    }
    return '';
  }

  onSignerRemoved() {
    this.signerRemoved.emit(true);
  }

  onSignerUpdated() {
    const contact = this.signersForm.get('contact').value;
    const id = contact['docId'];
    if (!id) {
      this.signersForm.get('contact').setValue('');
    } else {
      this.signerUpdated.emit({
        contactDocId: id,
        authorizationDate: this.signersForm.get('authorizationDate').value
      });
      if (!this.authorizedSigner) {
        this.signersForm.reset();
      }
      this.signersForm.get('contact').setValue('');
    }
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    }
    return 'Unknown error';
  }

  private setupSignersForm(contact) {
    if (this.authorizedSigner) {
      this.signersForm.get('contact').patchValue(contact);
      this.signersForm.get('authorizationDate').patchValue(this.authorizedSigner.authorizationDate);
    }

    this.signersForm.markAsPristine();
  }
}
