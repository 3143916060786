import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { PayDivision, PayCodeSplit, Broker } from '@advance-trading/ops-data-lib';
import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { BrokerService } from 'src/app/services/broker-service';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UserService } from '@advance-trading/angular-ops-data';
import { Auth0AuthzService, AuthService } from '@advance-trading/angular-ati-security';

@Component({
  selector: 'atom-pay-code-detail',
  templateUrl: './pay-code-detail.component.html',
  styleUrls: ['./pay-code-detail.component.css']
})
export class PayCodeDetailComponent implements OnInit, OnDestroy {

  payDivisions = Object.values(PayDivision);
  dataSource = new ObservableDataSource<PayCodeSplit>();
  columnsToDisplay: string[];

  filteredPayCodes = [];

  // splits table footer
  commissionSummary = {
    investorCommissionPercent: 0,
    mentoringPercent: 0,
    investorOverridePercent: 0,
    apCommissionPercent: 0,
    total: 0
  };

  private userSubscription: Subscription;
  private brokerSubscription: Subscription;
  private ACCOUNTING_ADMIN_ROLE = 'AccountingAdmin';

  @Input() payCodeForms: FormArray;
  @Input() payCodeForm: FormGroup;
  @Input() editMode: boolean;
  @Input() createMode: boolean;
  @Input() broker: Broker;
  @Input() index: number;

  constructor(
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private brokerService: BrokerService,
    private userService: UserService,
    private authService: AuthService,
    private authzService: Auth0AuthzService
  ) { }

  ngOnInit(): void {
    if (this.payCodeSplitForms && this.payCodeSplitForms.length > 0) {
      if (this.userHasAccounting()) {
        this.payCodeSplitForms.controls.map(control => {

          this.userSubscription = this.userService.getUserByDocId(control.value.brokerDocId).pipe(take(1)).subscribe(brokerUser => {
           control.get('brokerUser').setValue(brokerUser);

           this.brokerSubscription = this.brokerService.getBrokerByDocId(control.value.brokerDocId).pipe(take(1)).subscribe(broker => {
             if (broker.payCodes && broker.payCodes.length > 0) {
               this.filteredPayCodes.push(broker.payCodes.map(payCode => payCode.id));
             }
           });
         });
       });
      }

      const splits = [];
      this.payCodeSplitForms.controls.forEach(control => {
        const split: PayCodeSplit = {
          brokerDocId: control.get('brokerDocId').value,
          brokerName: control.get('brokerName').value,
          payCodeId: control.get('payCodeId').value,
          investorCommissionPercent: control.get('investorCommissionPercent').value / 100,
          investorOverridePercent: control.get('investorOverridePercent').value / 100,
          mentoringPercent: control.get('mentoringPercent').value / 100,
          apCommissionPercent: control.get('apCommissionPercent').value / 100
        };
        this.commissionSummary.investorCommissionPercent += split.investorCommissionPercent;
        this.commissionSummary.investorOverridePercent += split.investorOverridePercent;
        this.commissionSummary.mentoringPercent += split.mentoringPercent;
        this.commissionSummary.apCommissionPercent += split.apCommissionPercent;
        this.commissionSummary.total +=
          split.investorCommissionPercent +
          split.investorOverridePercent +
          split.mentoringPercent +
          split.apCommissionPercent;
        splits.push(split);
      });
      this.dataSource.data = splits;
      this.columnsToDisplay = ['brokerName', 'payCodeId', 'apCommissionPercent', 'investorCommissionPercent', 'investorOverridePercent', 'mentoringPercent', 'totalSplit'];
    }
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.brokerSubscription) {
      this.brokerSubscription.unsubscribe();
    }
  }

  // Does the logged in user have the accounting Admin role
  userHasAccounting(): boolean {
    return this.authzService.currentUserHasRole(this.ACCOUNTING_ADMIN_ROLE);
  }

  userIsBroker(): boolean {
    return this.authService.userProfile.app_metadata.firestoreDocId === this.broker.docId;
  }

  get payCodeSplitForms() {
    return this.payCodeForm.get('payCodeSplits') as FormArray;
  }

  removePayCode() {
    this.payCodeForms.removeAt(this.index);
    this.payCodeForms.markAsDirty();
  }

  addPayCodeSplit() {
    this.payCodeSplitForms.push(this.formBuilder.group({
      brokerUser: [''],
      payCodeId: [, [Validators.minLength(3), Validators.maxLength(6)]],
      investorCommissionPercent: [],
      investorOverridePercent: [],
      mentoringPercent: [],
      apCommissionPercent: []
    }));
    this.changeDetector.detectChanges();
  }
}
