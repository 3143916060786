<form *ngIf="payCodeSplitForm" [formGroup]="payCodeSplitForm" fxLayout="row wrap" fxFlex="100" fxLayoutGap="5" class="pay-code-form">
  <div fxFlex.xs="100" fxFlex.gt-xs="16.5">
    <mat-form-field fxFlex="80">
      <input matInput type="text" placeholder="Select Broker" formControlName="brokerUser" [matAutocomplete]="autoBroker" (blur)="refreshAvailableBrokers()" required>
      <button *ngIf="payCodeSplitForm.get('brokerUser').value" matSuffix mat-icon-button aria-label="Clear" (click)="resetBroker()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete #autoBroker="matAutocomplete" [displayWith]="displayBroker" (optionSelected)="onBrokerSelected($event.option.value)">
        <mat-option *ngFor="let broker of filteredBrokers$ | async" [value]="broker">
          {{ broker.firstName }} {{ broker.lastName }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="payCodeSplitForm.get('brokerUser').invalid">
        Must Select A Valid Broker
      </mat-error>
    </mat-form-field>
  </div>
  <!-- <div fxFlex.xs="100" fxFlex.gt-xs="16.5">
      <mat-form-field fxFlex.xs="80">
        <mat-label>Payroll ID</mat-label>
        <mat-select [disableOptionCentering]="true" formControlName="payCodeId" required>
          <mat-option *ngFor="let payCodeId of filteredPayCodes" value="{{payCodeId}}">
            {{payCodeId}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="payCodeSplitForm.get('payCodeId').invalid">
          Must Select A Valid Payroll ID
        </mat-error>
      </mat-form-field>
  </div> -->
  <div fxFlex.xs="100" fxFlex.gt-xs="16.5">
    <mat-form-field fxFlex="80">
      <input matInput type="text" placeholder="Payroll ID" formControlName="payCodeId"
        required>
        <mat-error *ngIf="payCodeSplitForm.get('payCodeId').invalid">
          Invalid Payroll ID
        </mat-error>
    </mat-form-field>
</div>
<div fxFlex.xs="100" fxFlex.gt-xs="16.5">
  <mat-form-field fxFlex="80">
    <input matInput type="text" placeholder="AP Commission" formControlName="apCommissionPercent">
      <mat-error *ngIf="payCodeSplitForm.get('apCommissionPercent').invalid">
        Invalid AP Commission Amount
      </mat-error>
  </mat-form-field>
  </div>
  <div fxFlex.xs="100" fxFlex.gt-xs="16.5">
    <mat-form-field fxFlex="80">
      <input matInput type="text" placeholder="Investor Commission" formControlName="investorCommissionPercent">
        <mat-error *ngIf="payCodeSplitForm.get('investorCommissionPercent').invalid">
          Invalid Investor Commission Amount
        </mat-error>
    </mat-form-field>
    </div>
    <div fxFlex.xs="100" fxFlex.gt-xs="16.5">
    <mat-form-field fxFlex="80">
      <input matInput type="text" placeholder="Investor Override" formControlName="investorOverridePercent">
        <mat-error *ngIf="payCodeSplitForm.get('investorOverridePercent').invalid">
          Invalid Investor Override Amount
        </mat-error>
    </mat-form-field>
    </div>
    <div fxFlex.xs="100" fxFlex.gt-xs="16.5">
    <mat-form-field fxFlex="80">
      <input matInput type="text" placeholder="Mentoring" formControlName="mentoringPercent">
        <mat-error *ngIf="payCodeSplitForm.get('mentoringPercent').invalid">
          Invalid Mentoring Amount
        </mat-error>
    </mat-form-field>
    <div *ngIf="editMode || createMode" fxFlex.xs="100" fxFlex.gt-xs="16.5">
      <button class="remove-pay-code-split" id="removePayCodeSplit" aria-label="Remove Pay Code Split" type="button"
        (click)="removePayCodeSplit()" *ngIf="editMode || createMode" matTooltip="Remove Pay Code Split" mat-icon-button>
        <mat-icon mat-list-icon>clear</mat-icon>
      </button>
    </div>
    </div>
</form>
