<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Broker Relationships List</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="column" fxFlex="100">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" #filter>
        <button mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <button *ngIf="exportable" aria-label="Export" id="export-broker-relationships" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
      <mat-menu #exportMenu="matMenu">
        <button mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Broker Relationships'))">Excel</button>
        <button mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Broker Relationships'))">CSV</button>
        <button mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Broker Relationships'))">JSON</button>
      </mat-menu>

      <mat-table matTableExporter #exporter="matTableExporter" [hiddenColumns]="[3,4]" [dataSource]="dataSource" matSort class="mat-elevation-z0" (matSortChange)="handleSortChange()">
        <ng-container matColumnDef="brokerNames">
          <mat-header-cell *matHeaderCellDef fxFlex.gt-xs="50">Brokers</mat-header-cell>
          <mat-cell *matCellDef="let brokerRel" fxFlex.gt-xs="50">{{ canUserViewSplits(brokerRel) ? brokerRel.brokerNamesAndSplit : brokerRel.brokerNames }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="brokerCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="75" fxFlex.gt-xs="13">Broker Code</mat-header-cell>
          <mat-cell *matCellDef="let brokerRel" fxFlex.xs="75" fxFlex.gt-xs="13">{{ displayBrokerCode(brokerRel) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="managingPod">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="13">Managing Pod</mat-header-cell>
          <mat-cell *matCellDef="let brokerRel" fxFlex.gt-xs="13">{{ brokerRel.managingPod | replace | titlecase }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="13">Status</mat-header-cell>
          <mat-cell *matCellDef="let brokerRel" fxFlex.gt-xs="13">{{ brokerRel.status | titlecase }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="icon">
          <mat-header-cell *matHeaderCellDef fxFlex.xs="25" fxFlex.gt-xs="10">Accounts</mat-header-cell>
          <mat-cell *matCellDef="let brokerRel" fxFlex.xs="25" fxFlex.gt-xs="10" >
            <div *ngIf="brokerRel.status !== 'NEW'" (click)="$event.stopPropagation()">
              <mat-icon (click)="selectAccountsIcon(brokerRel)" color="primary" matTooltip="Broker Relationship Accounts">folder</mat-icon>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row (click)="selectBrokerRel(brokerRel)" *matRowDef="let brokerRel; columns: columnsToDisplay"></mat-row>
      </mat-table>
      <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50, 100]" (page)="handlePageChange()"></mat-paginator>
    </div>
</div>
