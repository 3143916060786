<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="100" fxFlex.md="90" fxFlex.gt-md="90">
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving User Details</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
         or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">{{ (user$ | async)?.firstName }} {{ (user$ | async)?.lastName }}</h2>
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <button id="edit-user" aria-label="Edit User" matTooltip="Edit User" *ngIf="!editMode && (userCanAdminister() || isSelf()) && updateComplete" (click)="setEditMode(true)" mat-mini-fab color="accent">
          <mat-icon>edit</mat-icon>
        </button>
        <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
        <button id="update-user" aria-label="Update User" matTooltip="Update User" *ngIf="editMode && (userCanAdminister() || isSelf()) && updateComplete" [disabled]="!profileForm.valid || profileForm.pristine" (click)="updateUser()" mat-mini-fab color="accent">
          <mat-icon>done</mat-icon>
        </button>
        <button id="cancel-edit-user" aria-label="Discard Changes" matTooltip="Discard Changes" *ngIf="editMode && (userCanAdminister() || isSelf()) && updateComplete" (click)="setEditMode(false)" mat-mini-fab color="">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <form *ngIf="!errorMessage" [formGroup]="profileForm" (ngSubmit)="updateUser()" fxLayout="column">
      <mat-tab-group>
        <mat-tab label="Profile" fxFlex="100">
          <div class="tab-body" fxLayout="row wrap" fxLayoutAlign="space-between space-between" fxFlex="100" fxLayoutGap="5">
            <ati-labeled-data *ngIf="!editMode" label="First Name" [data]="profileForm.get('firstName').value" fxFlex.xs="100" fxFlex.gt-xs="45"></ati-labeled-data>
            <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45">
              <input required matInput placeholder="First Name" formControlName="firstName" trim="blur">
              <mat-error *ngIf="profileForm.get('firstName').invalid">{{getErrorMessage(profileForm.get('firstName'))}}</mat-error>
            </mat-form-field>
            <ati-labeled-data *ngIf="!editMode" label="Last Name" [data]="profileForm.get('lastName').value" fxFlex.xs="100" fxFlex.gt-xs="45"></ati-labeled-data>
            <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45">
              <input required matInput placeholder="Last Name" formControlName="lastName" trim="blur">
              <mat-error *ngIf="profileForm.get('lastName').invalid">{{getErrorMessage(profileForm.get('lastName'))}}</mat-error>
            </mat-form-field>
            <ati-labeled-data *ngIf="!editMode" label="Email Address" [data]="profileForm.get('email').value" fxFlex.xs="100" fxFlex.gt-xs="45"></ati-labeled-data>
            <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45">
              <input required matInput placeholder="Email Address" formControlName="email" trim="blur">
              <mat-error *ngIf="profileForm.get('email').invalid">{{getErrorMessage(profileForm.get('email'))}}</mat-error>
            </mat-form-field>
            <div fxFlex.xs="10" fxFlex.gt-xs="30">
              <!-- Placeholder for spacing -->
            </div>
            <ati-labeled-data *ngIf="!editMode" label="Mobile Phone" [data]="displayPhoneNumber('mobile')" fxFlex.xs="100" fxFlex.gt-xs="45"></ati-labeled-data>
            <ati-labeled-data *ngIf="!editMode" label="Office Phone" [data]="displayPhoneNumber('office')" fxFlex.xs="100" fxFlex.gt-xs="45"></ati-labeled-data>
            <ati-labeled-data *ngIf="!editMode" label="Toll-Free Phone" [data]="displayPhoneNumber('tollfree')" fxFlex.xs="100" fxFlex.gt-xs="45"></ati-labeled-data>
            <ati-labeled-data *ngIf="!editMode" label="Fax Number" [data]="displayPhoneNumber('fax')" fxFlex.xs="100" fxFlex.gt-xs="45"></ati-labeled-data>
            <div [class]="editMode ? 'phone-field' : 'not-displayed-but-rendered'" fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100">
              <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="45">
                <ngx-mat-intl-tel-input required [preferredCountries]="['us']" [enablePlaceholder]="true" [enableSearch]="false" name="mobileNumber" formControlName="mobileNumber" #mobileNumber></ngx-mat-intl-tel-input>
                <button mat-button *ngIf="profileForm.get('mobileNumber').value && editMode && userCanAdminister()" matSuffix mat-icon-button aria-label="Clear Mobile" matTooltip="Clear Mobile" (click)="clearPhoneNumber(profileForm.get('mobileNumber'), mobileNumber)">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-label>Mobile Phone</mat-label>
                <mat-error *ngIf="profileForm.get('mobileNumber').invalid">{{getErrorMessage(profileForm.get('mobileNumber'))}}</mat-error>
              </mat-form-field>
              <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="45">
                <ngx-mat-intl-tel-input [preferredCountries]="['us']" [enablePlaceholder]="true" [enableSearch]="false" name="officeNumber" formControlName="officeNumber" #officeNumber></ngx-mat-intl-tel-input>
                <button mat-button *ngIf="profileForm.get('officeNumber').value && editMode && userCanAdminister()" matSuffix mat-icon-button aria-label="Clear Office" matTooltip="Clear Office" (click)="clearPhoneNumber(profileForm.get('officeNumber'), officeNumber)">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-label>Office Phone</mat-label>
                <mat-error *ngIf="profileForm.get('officeNumber').invalid">{{getErrorMessage(profileForm.get('officeNumber'))}}</mat-error>
              </mat-form-field>
              <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="45">
                <ngx-mat-intl-tel-input [preferredCountries]="['us']" [enablePlaceholder]="true" [enableSearch]="false" name="tollFreeNumber" formControlName="tollFreeNumber" #tollFreeNumber></ngx-mat-intl-tel-input>
                <button mat-button *ngIf="profileForm.get('tollFreeNumber').value && editMode && userCanAdminister()" matSuffix mat-icon-button aria-label="Clear Toll-Free" matTooltip="Clear Toll-Free" (click)="clearPhoneNumber(profileForm.get('tollFreeNumber'), tollFreeNumber)">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-label>Toll-Free Phone</mat-label>
                <mat-error *ngIf="profileForm.get('tollFreeNumber').invalid">{{getErrorMessage(profileForm.get('tollFreeNumber'))}}</mat-error>
              </mat-form-field>
              <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="45">
                <ngx-mat-intl-tel-input [preferredCountries]="['us']" [enablePlaceholder]="true" [enableSearch]="false" name="faxNumber" formControlName="faxNumber" #faxNumber></ngx-mat-intl-tel-input>
                <button mat-button *ngIf="profileForm.get('faxNumber').value && editMode && userCanAdminister()" matSuffix mat-icon-button aria-label="Clear Fax" matTooltip="Clear Fax" (click)="clearPhoneNumber(profileForm.get('faxNumber'), faxNumber)">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-label>Fax Number</mat-label>
                <mat-error *ngIf="profileForm.get('faxNumber').invalid">{{getErrorMessage(profileForm.get('faxNumber'))}}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Account" fxFlex="100">
          <div class="tab-body" fxLayout="row wrap" fxLayoutAlign="space-between space-between" fxFlex="100" fxLayoutGap="5">
            <!-- Client -->
            <ati-labeled-data class="test" *ngIf="!editMode" label="Client" [data]="displayClient(profileForm.get('client').value)" fxFlex.xs="100" fxFlex.gt-xs="45"></ati-labeled-data>
            <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45">
              <input required matInput placeholder="Client" formControlName="client" [matAutocomplete]="auto">
              <button mat-button *ngIf="profileForm.get('client').value && editMode && userCanAdminister()" matSuffix mat-icon-button aria-label="Clear Client" matTooltip="Clear Client" (click)="profileForm.get('client').setValue(undefined)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="profileForm.get('client').invalid">You must select a valid client</mat-error>
            </mat-form-field>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayClient">
              <mat-option *ngFor="let client of filteredClients$ | async" [value]="client">{{displayClient(client)}}</mat-option>
            </mat-autocomplete>
            <!-- Broker Type -->
            <ati-labeled-data *ngIf="!editMode && userIsBroker()" fxFlex="100" fxFlex.gt-xs="45" label="Broker Type" data="{{ user.brokerType }}"></ati-labeled-data>
            <!-- Activation Date Picker -->
            <div *ngIf="editMode && userIsBroker()">
              <div fxLayoutAlign.gt-xs="start center" fxFlex="100" fxFlex.gt-xs="45">
                <mat-form-field>
                  <input matInput [matDatepicker]="activationDatePicker" placeholder="Activation Date" formControlName="brokerActivationDate" required>
                  <mat-datepicker-toggle matSuffix [for]="activationDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #activationDatePicker></mat-datepicker>
                  <mat-error *ngIf="profileForm.get('brokerActivationDate').invalid">Value invalid</mat-error>
                </mat-form-field>
            </div>
            </div>
            <!-- Status -->
            <ati-labeled-data *ngIf="!editMode && !userIsBroker()" fxFlex="100" label="Status" data="{{ profileForm.get('status').value | titlecase }}"></ati-labeled-data>
            <ati-labeled-data *ngIf="!editMode && userIsBroker()" fxFlex="100" fxFlex.gt-xs="45" label="Status" data="{{ profileForm.get('status').value | titlecase }}"></ati-labeled-data>
            <mat-radio-group required *ngIf="userCanAdminister() && editMode" formControlName="status" fxFlex="100">
              <span class="check-radio-label">Status</span>
              <mat-radio-button value="NEW">New</mat-radio-button>
              <mat-radio-button value="PENDING">Pending</mat-radio-button>
              <mat-radio-button value="ACTIVE">Active</mat-radio-button>
              <mat-radio-button value="INACTIVE">Inactive</mat-radio-button>
              <mat-radio-button value="DENIED">Denied</mat-radio-button>
            </mat-radio-group>
            <!-- Activation Date -->
            <ati-labeled-data *ngIf="!editMode && userIsBroker()" fxFlex="100" fxFlex.gt-xs="45" label="Activation Date" data="{{ user.brokerActivationDate | date: 'MM/dd/yyyy' }}"></ati-labeled-data>
            <!-- User Type -->
            <ati-labeled-data *ngIf="!editMode" fxFlex="100" label="User Type" data="{{ profileForm.get('type').value | titlecase }}"></ati-labeled-data>
            <mat-radio-group *ngIf="editMode" required formControlName="type" fxFlex="100">
              <span class="check-radio-label">User Type</span>
              <mat-radio-button value="CUSTOMER">Customer</mat-radio-button>
              <mat-radio-button value="EMPLOYEE">Employee</mat-radio-button>
              <mat-radio-button value="ASSOCIATE">Associate</mat-radio-button>
            </mat-radio-group>
            <!-- Market Data Frequency -->
            <ati-labeled-data *ngIf="!editMode" fxFlex="100" label="Market Data Frequency" data="{{ getMarketDataFrequencyDisplayText(profileForm.get('marketDataFrequency').value) }}"></ati-labeled-data>
            <mat-radio-group *ngIf="editMode" required formControlName="marketDataFrequency" fxFlex="100">
              <span class="check-radio-label">Market Data Frequency</span>
              <mat-radio-button value="NONE">None</mat-radio-button>
              <mat-radio-button value="DELAY_10">10 Min Delay</mat-radio-button>
              <mat-radio-button value="ON_DEMAND">On-Demand</mat-radio-button>
              <mat-radio-button value="REALTIME">Realtime</mat-radio-button>
            </mat-radio-group>
          </div>
        </mat-tab>
        <mat-tab label="Security" fxFlex="100" *ngIf="userCanAdminister() && (user$ | async)?.authId">
          <div class="tab-body" fxLayout="row wrap" fxLayoutAlign="space-between space-between" fxFlex="100" fxLayoutGap="5">
            <div class="checkbox-group" fxFlex="100" fxLayout.gt-xs="row wrap" fxLayout.xs="column" >
              <span class="check-radio-label">Enabled Applications</span>
              <div fxLayout="row wrap" fxLayout.xs="column" fxLayout.gt-xs="row wrap" fxLayoutAlign="start space-between" fxFlex="100" fxLayoutGap="5" >
                <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="20" label="ATOM" data="{{ user.atomEnabled | yesNo }}"></ati-labeled-data>
                <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="20" label="HMS" data="{{ user.hmsEnabled | yesNo }}"></ati-labeled-data>
                <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="20" label="PROSPER" data="{{ user.prosperEnabled | yesNo }}"></ati-labeled-data>
                <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="20" label="Advance Trading Mobile" data="{{ user.atiMobileEnabled | yesNo }}"></ati-labeled-data>
                <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="20" label="Salesforce" data="{{ user.salesforceEnabled | yesNo }}"></ati-labeled-data>
              </div>
              <mat-checkbox *ngIf="editMode" formControlName="atomEnabled">ATOM</mat-checkbox>
              <mat-checkbox *ngIf="editMode" formControlName="hmsEnabled">HMS</mat-checkbox>
              <mat-checkbox *ngIf="editMode" formControlName="prosperEnabled">PROSPER</mat-checkbox>
              <mat-checkbox *ngIf="editMode" formControlName="atiMobileEnabled">Advance Trading Mobile</mat-checkbox>
              <mat-checkbox *ngIf="editMode" formControlName="salesforceEnabled">Salesforce</mat-checkbox>
            </div>
            <mat-form-field *ngIf="profileForm.get('type').value === 'CUSTOMER'" fxFlex="100">
              <mat-chip-list [disabled]="!editMode" #accountChips class="account-list">
                <mat-chip *ngFor="let uAccount of userAccounts; index as index;"
                  [color]="(index + 1) % 2 === 0 ? 'accent' : 'primary'"
                  [disabled]="!editMode"
                  selected
                  [removable]="accountRemovable(uAccount)"
                  (removed)="removeAccount(uAccount)">
                  {{uAccount}}
                  <mat-icon matChipRemove *ngIf="accountRemovable(uAccount)">cancel</mat-icon>
                </mat-chip>
                <input
                  #accountInput
                  matInput
                  placeholder="Assigned Accounts"
                  formControlName="account"
                  [matAutocomplete]="accountauto"
                  [matChipInputFor]="accountChips"
                  [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addAccount($event)">
              </mat-chip-list>
              <mat-autocomplete autoActiveFirstOption #accountauto="matAutocomplete" (optionSelected)="addAccount()">
                <mat-option *ngFor="let account of filteredAccounts$ | async" [value]="account">{{account}}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field *ngIf="profileForm.get('type').value !== 'CUSTOMER'" fxFlex="100">
              <mat-chip-list [disabled]="!editMode" #brokerChips class="broker-list">
                <mat-chip *ngFor="let uBroker of userBrokers; index as index;"
                  [color]="(index + 1) % 2 === 0 ? 'accent' : 'primary'"
                  [disabled]="!editMode"
                  selected
                  [removable]="brokerRemovable(uBroker.docId)"
                  (removed)="removeBroker(uBroker.docId)">
                  {{uBroker.firstName}} {{uBroker.lastName}}
                  <mat-icon matChipRemove *ngIf="brokerRemovable(uBroker.docId)">cancel</mat-icon>
                </mat-chip>
                <input
                  #brokerInput
                  matInput
                  placeholder="Assigned Brokers"
                  formControlName="broker"
                  [matAutocomplete]="brokerauto"
                  [matChipInputFor]="brokerChips"
                  [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addBroker($event)">
              </mat-chip-list>
              <mat-autocomplete autoActiveFirstOption #brokerauto="matAutocomplete" [displayWith]="displayBroker" (optionSelected)="addBroker()">
                <mat-option *ngFor="let broker of filteredBrokers$ | async" [value]="broker">{{broker.firstName}} {{broker.lastName}}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field *ngIf="userGroups; else groupsLoading" fxFlex="100">
              <mat-chip-list [disabled]="!editMode" #groupChips class="group-list">
                <mat-chip *ngFor="let uGroup of userGroups; index as index;"
                  [color]="(index + 1) % 2 === 0 ? 'accent' : 'primary'"
                  [disabled]="!editMode"
                  selected
                  [removable]="groupRemovable(uGroup.name)"
                  (removed)="removeGroup(uGroup)">
                  {{uGroup.name}}
                  <mat-icon matChipRemove *ngIf="groupRemovable(uGroup.name)">cancel</mat-icon>
                </mat-chip>
                <input
                  #groupInput
                  matInput
                  placeholder="Security Groups"
                  formControlName="group"
                  [matAutocomplete]="groupauto"
                  [matChipInputFor]="groupChips"
                  [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addGroup($event)">
              </mat-chip-list>
              <mat-autocomplete autoActiveFirstOption #groupauto="matAutocomplete" [displayWith]="displayGroup" (optionSelected)="addGroup()">
                <mat-option *ngFor="let group of filteredGroups$ | async" [value]="group">{{group.name}}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <ng-template #groupsLoading>
              <mat-spinner diameter="40" color="primary"></mat-spinner>
            </ng-template>
          </div>
        </mat-tab>
        <mat-tab label="Apps" fxFlex="100" *ngIf="appSettingsVisible()">
          <div class="tab-body" fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
            <mat-expansion-panel *ngIf="profileForm.get('atiMobileEnabled').value" class="mat-elevation-z0" fxFlex="100">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Advance Trading Mobile Settings
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between start">
                Coming Soon...
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="profileForm.get('hmsEnabled').value" class="mat-elevation-z0" fxFlex="100">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  HMS Settings
                </mat-panel-title>
              </mat-expansion-panel-header>
              <atom-hms-user-settings (settingsUpdated)="handleSettingsUpdated($event)" [isAtiUser]="user.type === 'EMPLOYEE'" [userDocId]="user.docId" [editMode]="editMode" [updateComplete]="updateComplete" [userHasUserAdmin]="userHasUserAdmin()"></atom-hms-user-settings>
            </mat-expansion-panel>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </mat-card>
</div>
