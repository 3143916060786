import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';

import { AccountDetailComponent } from './accounts/account-detail.component';
import { AccountSearchComponent } from './accounts/account-search/account-search.component';
import { AccountSeriesSearchComponent } from './broker-codes/account-series/account-series-search/account-series-search.component';
import { AdjustmentDetailComponent } from './transactions/adjustments/adjustment-detail/adjustment-detail.component';
import { AdjustmentSearchComponent } from './transactions/adjustments/adjustment-search/adjustment-search.component';
import { BankComponent } from './clients/bank/bank.component';
import { BrokerDetailComponent } from './brokers/broker-detail.component';
import { BrokerRelationshipDetailComponent } from './broker-codes/broker-relationships/broker-relationship-detail/broker-relationship-detail.component';
import { BrokerRequestDetailComponent } from './brokers/broker-request-detail.component';
import { BrokerRequestsComponent } from './brokers/broker-requests.component';
import { BrokersComponent } from './brokers/brokers.component';
import { CallbackComponent } from './callback/callback.component';
import { CashActivitySearchComponent } from './transactions/cash-activity/cash-activity-search/cash-activity-search.component';
import { CashActivityDetailComponent } from './transactions/cash-activity/cash-activity-detail/cash-activity-detail.component';
import { ClientDetailComponent } from './clients/client-detail.component';
import { ClientSearchComponent } from './clients/client-search/client-search.component';
import { CommissionScheduleDetailComponent } from './rate-codes/commission-schedules/commission-schedule-detail.component';
import { CommissionSchedulesComponent } from './rate-codes/commission-schedules/commission-schedules.component';
import { ContactDetailComponent } from './clients/contact-detail/contact-detail.component';
import { ContactsComponent } from './clients/contacts/contacts.component';
import { FcmSetupComponent } from './clients/fcm-setup/fcm-setup.component';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { MarginDetailComponent } from './margins/margin-detail/margin-detail.component';
import { MarginSearchComponent } from './margins/margin-search/margin-search.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OrderDetailComponent } from './orders/order-detail/order-detail.component';
import { OrderSearchComponent } from './orders/order-search/order-search.component';
import { PositionDetailComponent } from './positions/position-detail/position-detail.component';
import { PositionSearchComponent } from './positions/position-search/position-search.component';
import { RegistrationComponent } from './registration/registration.component';
import { SalesCodeDetailComponent } from './broker-codes/sales-codes/sales-code-detail.component';
import { SalesCodesComponent } from './broker-codes/sales-codes/sales-codes.component';
import { TradeSearchComponent } from './transactions/trades/trade-search/trade-search.component';
import { TradeDetailComponent } from './transactions/trades/trade-detail/trade-detail.component';
import { UserDetailComponent } from './users/user-detail.component';
import { UsersComponent } from './users/users.component';
import { BrokerRelationshipsSearchComponent } from './broker-codes/broker-relationships/broker-relationships-search/broker-relationships-search.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'accounts/new',
    pathMatch: 'full',
    component: AccountDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'accounts',
    component: AccountSearchComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'accounts/:docId',
    component: AccountDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'accounts/:accountDocId/cashactivity/:cashActivityDocId',
    component: CashActivityDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'accounts/:accountDocId/orders/:orderDocId',
    component: OrderDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'accounts/:accountDocId/adjustments/:adjustmentDocId',
    component: AdjustmentDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'accounts/:accountDocId/margins/:marginDocId',
    component: MarginDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'accounts/:accountDocId/positions/:positionDocId',
    component: PositionDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'accounts/:accountDocId/trades/:tradeDocId',
    component: TradeDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'accountseries',
    component: AccountSeriesSearchComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'adjustments',
    component: AdjustmentSearchComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'brokerrelationships/new',
    pathMatch: 'full',
    component: SalesCodeDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'brokerrelationships/:brokerrelationshipDocId',
    pathMatch: 'full',
    component: BrokerRelationshipDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'brokerrelationships',
    component: BrokerRelationshipsSearchComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'brokerrequests',
    component: BrokerRequestsComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'brokerrequests/:docId',
    component: BrokerRequestDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'brokers/new',
    pathMatch: 'full',
    component: BrokerRequestDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'brokers',
    component: BrokersComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'brokers/:docId',
    component: BrokerDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'clients/new',
    pathMatch: 'full',
    component: ClientDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'clients',
    children: [
      {
        path: '',
        component: ClientSearchComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: ':clientDocId',
        children: [
          {
            path: '',
            component: ClientDetailComponent,
            canActivate: [
              AuthGuard
            ],
          },
          {
            path: 'fcmsetup',
            children: [
              {
                path: '',
                redirectTo: 'new',
                pathMatch: 'full'
              },
              {
                path: ':fcmDocId',
                component: FcmSetupComponent,
                canActivate: [
                  AuthGuard
                ]
              }
            ]
          },
          {
            path: 'contacts',
            children: [
              {
                path: 'new',
                pathMatch: 'full',
                component: ContactDetailComponent,
                canActivate: [
                  AuthGuard
                ]
              },
              {
                path: '',
                component: ContactsComponent,
                canActivate: [
                  AuthGuard
                ],
              },
              {
                path: ':contactDocId',
                component: ContactDetailComponent,
                canActivate: [
                  AuthGuard
                ]
              },
            ]
          },
          {
            path: 'banks',
            children: [
              {
                path: '',
                redirectTo: 'new',
                pathMatch: 'full'
              },
              {
                path: ':bankDocId',
                component: BankComponent,
                canActivate: [
                  AuthGuard
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'cashactivity',
    component: CashActivitySearchComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'commissionschedules/new',
    pathMatch: 'full',
    component: CommissionScheduleDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'commissionschedules',
    component: CommissionSchedulesComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'commissionschedules/:docId',
    component: CommissionScheduleDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'margins',
    component: MarginSearchComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'orders',
    component: OrderSearchComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'positions',
    component: PositionSearchComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'register',
    component: RegistrationComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'salescodes',
    component: SalesCodesComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'salescodes/:docId',
    component: SalesCodeDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'trades',
    component: TradeSearchComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'users/:docId',
    component: UserDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'notfound',
    component: NotFoundComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  providers: [AuthGuard],
  exports: [
    RouterModule
  ]
})
export class AtomRoutingModule { }
