import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Observable, of, Subject } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { Auth0AuthzService, AuthService } from '@advance-trading/angular-ati-security';
import { ObservableDataSource, StorageService } from '@advance-trading/angular-common-services';
import { UserService } from '@advance-trading/angular-ops-data';
import { ClientService } from '../services/client-service';
import { Client, User } from '@advance-trading/ops-data-lib';
import { ExportService } from '../services/export.service';

const ALL_USERS_ADMIN_ROLE = 'UserAdmin';
const PAGE_SIZE_KEY = 'atom.usersPageSize';

@Component({
  selector: 'atom-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  providers: [BreakpointObserver]
})
export class UsersComponent implements AfterViewInit, OnInit, OnDestroy {

  columnsToDisplay = [];
  errorMessage: string;
  dataSource = new ObservableDataSource<User>();
  filterValue = new FormControl();
  userClient: Client;
  exportable = false;

  private queryParams: Params;
  private unsubscribe$: Subject<void> = new Subject<void>();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private clientService: ClientService,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    public exportService: ExportService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.matches) {
          this.columnsToDisplay = [
            'name', 'status'
          ];
        } else {
          this.columnsToDisplay = [
            'name', 'email', 'type', 'status'
          ];
        }
      });

    let users$: Observable<User[]>;

    // Fetch only the user's client users if not full admin
    if (this.authzService.currentUserHasRole(ALL_USERS_ADMIN_ROLE)) {
      users$ = this.userService.getAllUsers();
    } else {
      users$ = this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          return this.clientService.getClient(user.clientDocId);
        }),
        switchMap(client => {
          this.userClient = client;
          return this.userService.getAllUsersByClientId(client.docId);
        }));
    }

    this.filterValue.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((filter: string) => {
      if (filter) {
        this.queryParams.filter = filter.trim();
      } else if (this.queryParams.filter) {
        delete this.queryParams.filter;
      }

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        replaceUrl: true,
        queryParams: this.queryParams
      });
    });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
      this.queryParams = Object.assign({}, params);
    }));

    this.dataSource.data$ = users$
      .pipe(
        tap(users => {
          this.setTableState();
          this.exportable = users.length > 0;
        }),
        catchError(err => {
          this.errorMessage = 'Error retrieving users; please try again later';
          console.error(`Error retrieving users: ${err}`);
          return of([]);
        })
      );

    const filterVal = this.queryParams.filter;
    if (filterVal) {
      this.filterValue.setValue(filterVal);
      this.applyFilter(this.filterValue.value);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = this.storageService.localStorage.get(PAGE_SIZE_KEY).value() || 10;
    // Special sorting accessor to handle the combined first and last name field
    this.dataSource.sortingDataAccessor = (data, sortHeaderId): string | number => {
      if (sortHeaderId === 'name') {
        return data.firstName + ' ' + data.lastName;
      } else {
        return (data as {[key: string]: any})[sortHeaderId];
      }
    };
    this.dataSource.sort = this.sort;
    this.filter.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  selectUser(user: User) {
    this.router.navigate(['/users', user.docId]);
  }

  handlePageChange() {
    this.storageService.localStorage.set(PAGE_SIZE_KEY, this.paginator.pageSize);
    this.queryParams.pageIndex = this.paginator.pageIndex;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  handleSortChange() {
    this.queryParams.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.queryParams.sortColName = this.queryParams.sortDir ? this.sort.active : undefined;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  private setTableState() {
    if (this.filter) {
      const pageSize = this.queryParams.pageSize as number;
      if (pageSize) {
        this.dataSource.paginator.pageSize = pageSize;
      }

      const pageIndex = this.queryParams.pageIndex as number;
      if (pageIndex) {
        this.dataSource.paginator.pageIndex = pageIndex;
      }

      const sortDir = this.queryParams.sortDir as SortDirection;
      const sortColName = this.queryParams.sortColName as string;
      if (sortDir && sortColName) {
        this.dataSource.sort.direction = sortDir;
        this.dataSource.sort.active = sortColName;
      }
    }
  }
}
