import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { ENTER, SPACE } from '@angular/cdk/keycodes';

import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';

import { ClientSettingsService, UserSettingsService } from '@advance-trading/angular-ops-data';
import { ClientService } from '../../services/client-service';
import { Client, HMSClientSettings, HMSUserSettings } from '@advance-trading/ops-data-lib';

@Component({
  selector: 'atom-hms-user-settings',
  templateUrl: './hms-user-settings.component.html',
  styleUrls: ['./hms-user-settings.component.css']
})
export class HmsUserSettingsComponent implements OnChanges {

  hmsSettingsForm = this.formBuilder.group({
    authorizedClients: new FormControl({ value: '', disabled: true })
  });

  addOnBlur = false;
  authorizedClients: Client[] = [];
  clients$: Observable<Client[]>;
  filteredClients$: Observable<Client[]>;
  separatorKeyCodes: number[] = [ENTER, SPACE];

  private authorizedClientDocIds: string[] = [];
  private clients: Client[] = [];
  private hmsUserSettings: HMSUserSettings;
  private originalClients: Client[] = []; // Master copy to revert changes
  private originalClientDocIds: string[] = []; // Master copy to revert changes
  private firstEdit: boolean;

  @Input() editMode = false;
  @Input() updateComplete = true;
  @Input() userDocId: string;
  @Input() userHasUserAdmin = false;
  @Input() isAtiUser = false;

  @Output() settingsUpdated: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('clientauto', { static: false }) private clientMatAuto: MatAutocomplete;
  @ViewChild('clientInput', { static: false }) private clientInput: ElementRef<HTMLInputElement>;

  constructor(
    private clientService: ClientService,
    private clientSettingsService: ClientSettingsService,
    private formBuilder: FormBuilder,
    private userSettingsService: UserSettingsService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    // If the userDocId changed, get the corresponding HmsUserSettings
    if (changes['userDocId']) {
      this.userSettingsService.getHmsSettingsByUserDocId(this.userDocId).pipe(
        take(1),
        map(hmsSetting => {
          this.setupHmsUserSettings(hmsSetting);
        })
      ).subscribe();
    }

    // If current user is not a user admin, none of the contained if statements should  be executed.
    if (this.userHasUserAdmin) {
      if (this.editMode && !this.firstEdit) {
        this.firstEdit = true;
        this.loadClientData();
      }

      if (this.editMode) {
        this.hmsSettingsForm.enable();
      } else {
        this.hmsSettingsForm.disable();
      }

      if (!this.editMode && this.updateComplete) {
        this.reset();
      } else if (!this.updateComplete) {
        this.submit();
      }
    }
  }

  clientRemovable(client: Client) {
    const removable = this.authorizedClients && this.authorizedClients
      .filter(authorizedClient => authorizedClient.docId === client.docId).length > 0;
    return this.editMode && removable && this.userHasUserAdmin;
  }

  addClient(event: MatChipInputEvent) {
    let client: Client = this.hmsSettingsForm.get('authorizedClients').value;
    if (client) {
      const matOption = this.clientMatAuto.options.find(value => value.value.docId === client.docId);
      client = matOption ? matOption.value : undefined;
    } else {
      return;
    }

    // Add client to authorizedClients and clientDocId to authorizedClientDocIds
    this.authorizedClients = this.authorizedClients.filter(removeClient => removeClient.docId !== client.docId);
    this.authorizedClients.push(client);
    this.authorizedClientDocIds = this.authorizedClientDocIds.filter(removeClientDocId => removeClientDocId !== client.docId);
    this.authorizedClientDocIds.push(client.docId);

    // Reset the group value to ensure we get a dirty form
    this.clientInput.nativeElement.value = '';
    this.hmsSettingsForm.get('authorizedClients').setValue('');
    this.settingsUpdated.emit(true);
  }

  removeClient(client: Client) {
    // Remove client from authorizedClients and authorizedclientDocIds
    this.authorizedClients = this.authorizedClients.filter(removeClient => removeClient.docId !== client.docId);
    this.authorizedClientDocIds = this.authorizedClientDocIds.filter(removeClientDocId => removeClientDocId !== client.docId);

    // Reset group value to ensure we get a dirty form
    this.hmsSettingsForm.get('authorizedClients').markAsDirty();
    this.hmsSettingsForm.get('authorizedClients').setValue('');
    this.settingsUpdated.emit(true);
  }

  displayClient(client: Client) {
    if (client) {
      return client.physicalAddress ? `${client.name} (${client.physicalAddress.city}, ${client.physicalAddress.region})` : client.name;
    }
    return '';
  }

  // Load and filter clients for mat-chip's will only load clients once editMode is true for the first time
  private loadClientData() {
    // Fetches all active commercial clients
    this.clients$ = this.clientSettingsService.findHmsClientSettings().pipe(
      take(1),
      switchMap((clientSettings: HMSClientSettings[]) => {
        return combineLatest(
          clientSettings.map((setting: HMSClientSettings) => {
            const clientDocId = setting.clientDocId;
            // If the client docId is not undefined return the associated client, else of(undefined)
            if (clientDocId) {
              return this.clientService.getClient(clientDocId);
            }
            return of(undefined);
          })
        );
      }),
      tap(clients => {
        this.clients = clients.filter(client => client !== undefined);
      }),
      catchError((err) => {
        console.log(err);
        return of([]);
      })
    );

    this.filteredClients$ = this.hmsSettingsForm.get('authorizedClients').valueChanges.pipe(
      startWith<string | Client>(''),
      filter(value => typeof value === 'string'),
      map((clientName: string) => {
        if (!this.clients) {
          return [];
        } else if (clientName) {
          return this.clients.filter(client => {
            return client.name.toLowerCase().includes(clientName.toLowerCase()) &&
              !this.authorizedClients.find((value) => client.name === value.name);
          });
        } else {
          return this.clients.filter(client => {
            return !this.authorizedClients.find(val => client.name === val.name);
          });
        }
      })
    );
  }

  // Creates a reference to the HMSUserSettings of user if there is one,
  // else creates a new HMSUserSettings object to be updated
  private setupHmsUserSettings(hmsSettings: HMSUserSettings) {
    this.hmsUserSettings = new HMSUserSettings();
    if (hmsSettings) { // The use already has HMSUserSettings document
      this.hmsUserSettings.accountingSystemId = hmsSettings.accountingSystemId || '';
      this.hmsUserSettings.authorizedClientDocIds = hmsSettings.authorizedClientDocIds || [];
      this.hmsUserSettings.authorizedLocationDocIds = hmsSettings.authorizedLocationDocIds || [];
      this.hmsUserSettings.contractLimits = hmsSettings.contractLimits || [];
      this.hmsUserSettings.userDocId = hmsSettings.userDocId || '';
      if (hmsSettings.baseLocationDocId) {
        this.hmsUserSettings.baseLocationDocId = hmsSettings.baseLocationDocId;
      }
    } else { // The user does not have an HMSUserSettings document
      this.hmsUserSettings.userDocId = this.userDocId;
      this.hmsUserSettings.authorizedClientDocIds = [];
    }
    // Set the isAuthorizedAtAllLocations to true for ATI users
    this.hmsUserSettings.isAuthorizedAtAllLocations = this.isAtiUser;
    this.populateAuthorizedClients();
  }

  private populateAuthorizedClients() {
    this.authorizedClients = [];
    for (const clientDocId of this.hmsUserSettings.authorizedClientDocIds) {
      this.clientService.getClient(clientDocId).pipe(
        take(1),
        map((client) => {
          this.authorizedClients.push(client);
        })
      ).subscribe();
    }
    this.originalClients = this.authorizedClients;
    this.originalClientDocIds = this.authorizedClientDocIds;
    this.authorizedClientDocIds = this.hmsUserSettings.authorizedClientDocIds;
  }

  private reset() {
    this.authorizedClientDocIds = this.originalClientDocIds;
    this.authorizedClients = this.originalClients;
    this.hmsSettingsForm.reset();
    this.settingsUpdated.emit(false);
  }

  private submit() {
    // Reset originalClients and originalClientDocIds after submission
    this.originalClients = this.authorizedClients;
    this.originalClientDocIds = this.authorizedClientDocIds;

    // Push changes to users HMSUserSettings document in Firestore
    this.hmsUserSettings.authorizedClientDocIds = this.authorizedClientDocIds;
    this.userSettingsService.setHmsSettingsByUserDocId(this.userDocId, this.hmsUserSettings);
    this.settingsUpdated.emit(false);
  }
}
