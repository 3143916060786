<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <h6 *ngIf="filteredBy" class="card-brow">{{ filteredBy | uppercase }}</h6>
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Adjustments List</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">Adjustments</h2>
    </div>
    <div *ngIf="!errorMessage" fxLayout="column">
      <form [formGroup]="adjustmentsForm" (ngSubmit)="searchAdjustments(true)">
        <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100" fxLayoutGap="25px">
          <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">

            <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="45" (click)="accountFieldClicked()">
              <input matInput placeholder="Accounts" formControlName="accounts" upperCase>
              <mat-error *ngIf="adjustmentsForm.get('accounts').invalid">{{ formValidator.getErrorMessages(adjustmentsForm.get('accounts')) }}</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="45" (click)="brokerCodeFieldClicked()">
              <input matInput placeholder="Broker Codes" formControlName="brokerCodes">
              <mat-error *ngIf="adjustmentsForm.get('brokerCodes').invalid">{{ formValidator.getErrorMessages(adjustmentsForm.get('brokerCodes')) }}</mat-error>
            </mat-form-field>

          </div>

          <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
            <div fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap.gt-xs="25px" fxLayoutGap.xs="5px" fxLayoutAlign.gt-xs="start center">

              <mat-form-field fxFlex="45">
                <input matInput [matDatepicker]="startDatePicker" placeholder="Trade Date (Start)" formControlName="startTradeDate" [max]="adjustmentsForm.get('endTradeDate').value">
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
                <mat-error *ngIf="adjustmentsForm.get('startTradeDate').invalid">{{ formValidator.getErrorMessages(adjustmentsForm.get('startTradeDate')) }}</mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="45">
                <input matInput [matDatepicker]="endDatePicker" placeholder="Trade Date (End)" formControlName="endTradeDate" [min]="adjustmentsForm.get('startTradeDate').value">
                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
                <mat-error *ngIf="adjustmentsForm.get('endTradeDate').invalid">{{ formValidator.getErrorMessages(adjustmentsForm.get('endTradeDate')) }}</mat-error>
              </mat-form-field>
            </div>

            <div fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap.gt-xs="25px" fxLayoutGap.xs="5px" fxLayoutAlign.gt-xs="start center">
              <mat-form-field fxFlex="45">
                <input matInput [matDatepicker]="businessStartDatePicker" placeholder="Business Date (Start)" formControlName="startBusinessDate" [max]="adjustmentsForm.get('endBusinessDate').value">
                <mat-datepicker-toggle matSuffix [for]="businessStartDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #businessStartDatePicker></mat-datepicker>
                <mat-error *ngIf="adjustmentsForm.get('startBusinessDate').invalid">{{ formValidator.getErrorMessages(adjustmentsForm.get('startBusinessDate')) }}</mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="45">
                <input matInput [matDatepicker]="businessEndDatePicker" placeholder="Business Date (end)" formControlName="endBusinessDate" [min]="adjustmentsForm.get('startBusinessDate').value">
                <mat-datepicker-toggle matSuffix [for]="businessEndDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #businessEndDatePicker></mat-datepicker>
                <mat-error *ngIf="adjustmentsForm.get('endBusinessDate').invalid">{{ formValidator.getErrorMessages(adjustmentsForm.get('endBusinessDate')) }}</mat-error>
              </mat-form-field>
            </div>
          </div>

          <span class="check-radio-label">Assignment</span>
          <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap.gt-xs="5">
            <div class="checkbox-group" fxFlex.xs="100" fxFlex.gt-xs="45">
              <mat-checkbox #assignmentBoxes *ngFor="let assignment of assignmentStatuses" (change)="onAssignmentChange($event)" [value]="assignment" labelPosition="after">
                {{ assignment }}
              </mat-checkbox>
            </div>
          </div>


          <div fxLayout="row" fxLayoutGap="10px">
            <button mat-raised-button color="accent" type="submit" [disabled]="!adjustmentsForm.valid">Search</button>
            <button id="clear-adjustments-search" *ngIf="!adjustmentsForm.pristine" aria-label="Reset" (click)="reset()" mat-stroked-button color="accent">Reset</button>
          </div>

        </div>
      </form>

      <div *ngIf="isSearching" fxLayout="row" fxLayoutGap="10px">
        <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
      </div>
      <div *ngIf="showData && !errorMessage" class="adjustment-list">
        <atom-adjustments (adjustmentListChange)="handleAdjustmentListChange($event)" (adjustmentSearchError)="handleAdjustmentSearchError($event)" (isSearching)="handleIsSearching($event)" [selectedAdjustments$]="selectedAdjustments$" [initialTableState]="tableState"></atom-adjustments>
      </div>

    </div>
  </mat-card>
</div>

