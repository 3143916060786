import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { Broker, BrokerRelationship, PodEnum, User } from '@advance-trading/ops-data-lib';
import { BrokerRelationshipSearchCriteria } from '@advance-trading/angular-ops-data/lib/service-interfaces/broker-relationship-search-criteria';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BrokerRelationshipService, UserService } from '@advance-trading/angular-ops-data';
import { BrokerService } from '../../../services/broker-service';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { BrokerSearchValidator } from './broker-search-validator';

interface NamedBroker extends Broker {
  firstName: string;
  lastName: string;
}

const BROKER_CODE_VIEWER_ROLE = 'BrokerCodeViewer';

@Component({
  selector: 'atom-broker-relationships-search',
  templateUrl: './broker-relationships-search.component.html',
  styleUrls: ['./broker-relationships-search.component.css']
})
export class BrokerRelationshipsSearchComponent implements OnInit, AfterViewChecked {

  brokerRelSearchForm: FormGroup = this.formBuilder.group({
    brokerCode: ['', [Validators.minLength(6)]],
    broker: ['', [BrokerSearchValidator.BrokerSearchValidator]],
    managingPod: ['']
  });

  selectedBrokerRels$: Observable<BrokerRelationship[]>;
  activeBrokers$: Observable<Broker[]>;
  filteredBrokers$: Observable<Broker[]>;
  tableState: { [key: string]: string | number } = {};
  podNames = Object.keys(PodEnum);

  private queryParams: Params;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private brokerRelationshipService: BrokerRelationshipService,
    private brokerService: BrokerService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) { }

  errorMessage: string;
  isSearching = false;
  showBrokerRels = false;
  filteredBy = '';

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(BROKER_CODE_VIEWER_ROLE)) {
      this.errorMessage = 'You do not have permission to search broker relationships';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.prepForBrokerSelection();

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.queryParams = Object.assign({}, params);

      this.brokerRelSearchForm.get('brokerCode').setValue(this.queryParams.brokerCode);
      this.brokerRelSearchForm.get('broker').setValue(this.queryParams.broker);

      if (!this.queryParams.managingPod) {
        this.queryParams.managingPod = 'ALL';
        this.brokerRelSearchForm.get('managingPod').setValue(this.queryParams.managingPod);
      } else {
        this.brokerRelSearchForm.get('managingPod').setValue(this.queryParams.managingPod);
      }

      if (Object.keys(params).length) {
        this.searchBrokerRels();
      }
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  displayBroker(broker?: User) {
    if (broker) {
      return `${broker.firstName || ''} ${broker.lastName || ''}`;
    } else {
      return '';
    }
  }

  addNewBrokerRel() {
    this.router.navigate(['/brokerrelationships/new']);
  }

  get accountAdmin() {
    return this.authzService.currentUserHasRole('AccountAdmin');
  }

  reset() {
    this.brokerRelSearchForm.get('broker').setValue('');
    this.brokerRelSearchForm.get('brokerCode').setValue('');
    this.brokerRelSearchForm.get('managingPod').setValue('ALL');

    this.filteredBy = '';
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });

    this.brokerRelSearchForm.enable();
    this.brokerRelSearchForm.markAsPristine();
    this.clearQueryParams();
    this.showBrokerRels = false;
  }

  searchBrokerRels(searchButtonClicked: boolean = false) {
    this.brokerRelSearchForm.markAsDirty();
    if (searchButtonClicked) {
      this.clearQueryParams();
      this.tableState = {};
      this.tableState.pageIndex = 0;
    } else {
      const sortDir = this.queryParams.sortDir;
      const sortColName = this.queryParams.sortColName;
      const pageSize = this.queryParams.pageSize;
      const pageIndex = this.queryParams.pageIndex;
      const filter = this.queryParams.filter;
      this.tableState = {
        sortDir,
        sortColName,
        pageSize,
        pageIndex,
        filter
      };
    }
    this.showBrokerRels = false;
    this.changeDetector.detectChanges();
    this.selectedBrokerRels$ = this.chooseQuery();
    this.showBrokerRels = true;
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('minlength')) {
      return 'Must be 6 characters';
    } else {
      return 'Unknown error';
    }
  }

  brokerCodeFieldClicked() {
    this.brokerRelSearchForm.get('broker').setValue('');
    this.brokerRelSearchForm.get('broker').disable();
    this.brokerRelSearchForm.get('brokerCode').enable();
  }

  brokerFieldClicked() {
    this.brokerRelSearchForm.get('brokerCode').setValue('');
    this.brokerRelSearchForm.get('brokerCode').disable();
    if (this.brokerRelSearchForm.get('broker').disabled) {
      this.brokerRelSearchForm.get('broker').enable();
    }
  }

  brokerRelListChange(tableState: { [key: string]: string | number }) {
    if (tableState.sortDir && tableState.sortColName) {
      this.queryParams.sortDir = tableState.sortDir;
      this.queryParams.sortColName = tableState.sortColName;
    } else if (this.queryParams.sortDir && this.queryParams.sortColName) {
      delete this.queryParams.sortDir;
      delete this.queryParams.sortColName;
    }
    if (tableState.pageSize) {
      this.queryParams.pageSize = tableState.pageSize;
    }
    if (tableState.pageIndex !== undefined) {
      this.queryParams.pageIndex = tableState.pageIndex;
    }
    if (tableState.filter) {
      this.queryParams.filter = tableState.filter;
    } else if (this.queryParams.filter) {
      delete this.queryParams.filter;
    }
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  handleBrokerRelError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.changeDetector.detectChanges();
  }

  private chooseQuery(): Observable<BrokerRelationship[]> {

    if (!this.authzService.currentUserHasRole(BROKER_CODE_VIEWER_ROLE)) {
      this.errorMessage = 'You do not have permission to search broker relationships';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    const brokerCodeValue = this.brokerRelSearchForm.get('brokerCode').value;
    const brokerValue = this.brokerRelSearchForm.get('broker').value;
    const managingPodValue = this.brokerRelSearchForm.get('managingPod').value !== 'ALL' ?
      this.brokerRelSearchForm.get('managingPod').value : undefined;
    let brokerDocId;

    this.queryParams = this.tableState as Params;

    if (brokerCodeValue) {
      this.queryParams.brokerCode = brokerCodeValue;
    }

    if (brokerValue) {
      brokerDocId = brokerValue.docId ? brokerValue.docId : brokerValue;
      this.queryParams.broker = brokerDocId;
    }

    if (managingPodValue) {
      this.queryParams.managingPod = managingPodValue;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });

    const searchParams: BrokerRelationshipSearchCriteria = {
      brokerCode: brokerCodeValue,
      brokerDocId,
      managingPod: managingPodValue
    };

    if (brokerCodeValue) {
      this.filteredBy = `Broker Code: ${this.queryParams.brokerCode}`;
      return this.brokerRelationshipService.getBrokerRelationshipsBySearchParameters(searchParams)
        .pipe(
          tap((brokerRels) => {
            if (brokerRels.length === 0) {
              this.openSnackBar('Broker code has no known relationships', 'DISMISS', true);
              return of([]);
            } else {
              return brokerRels;
            }
          })
        );
    } else if (brokerValue) {
      return this.brokerService.getBrokerByDocId(brokerDocId)
        .pipe(
          switchMap((broker: Broker) => {
            return this.userService.getUserByDocId(broker.docId)
              .pipe(
                map((user: User) => {
                  return {
                    ...broker,
                    firstName: user.firstName,
                    lastName: user.lastName
                  } as NamedBroker;
                }),
                tap((brokerVal) => {
                  this.brokerRelSearchForm.get('broker').setValue(brokerVal);
                  this.filteredBy = `Broker: ${brokerVal.firstName + ' ' + brokerVal.lastName}`;
                })
              )
              .pipe(
                switchMap(() => {
                  return this.brokerRelationshipService.getBrokerRelationshipsBySearchParameters(searchParams)
                    .pipe(
                      tap((brokerRels) => {
                        if (brokerRels.length === 0) {
                          this.openSnackBar('Broker has no known relationships', 'DISMISS', true);
                          return of([]);
                        } else {
                          return brokerRels;
                        }
                      })
                    );
                })
              );
          })
        );
    } else {
      return this.brokerRelationshipService.getBrokerRelationshipsBySearchParameters(searchParams);
    }

  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }

  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private prepForBrokerSelection() {
    this.getActiveBrokers();
    this.filterBrokers();
  }

  private getActiveBrokers() {
    this.activeBrokers$ = this.brokerService.getAllBrokers()
      .pipe(
        shareReplay(1),
        switchMap((brokers: Broker[]) => {
          return combineLatest(brokers.map(broker => {
            return this.userService.getUserByDocId(broker.docId)
              .pipe(
                map((user: User) => {
                  return {
                    ...broker,
                    firstName: user.firstName,
                    lastName: user.lastName
                  } as NamedBroker;
                })
              );
          }));
        }),
      );
  }

  private filterBrokers() {
    this.filteredBrokers$ = this.brokerRelSearchForm.controls.broker.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        startWith<string | Broker>(''),
        switchMap(term => typeof term === 'string' ? this.filterBrokersBySearchTerm(term) : of([term]))
      );
  }

  private filterBrokersBySearchTerm(searchTerm: string): Observable<Broker[]> {
    const brokerValues = searchTerm.split(' ').map((code) => code.trim());
    return this.activeBrokers$
      .pipe(
        map(brokers => brokers.filter((broker: NamedBroker) =>
          broker.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          broker.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (broker.firstName.toLowerCase().includes(brokerValues[0].toLowerCase())
            && broker.lastName.toLowerCase().includes(brokerValues[1].toLowerCase()))
        ))
      );
  }


}
