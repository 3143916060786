import {
  ChangeDetectorRef,
  Component,
  OnInit, ViewChild,
  OnDestroy,
} from '@angular/core';
import { Account, AccountPurpose, CashActivity , Commodity} from '@advance-trading/ops-data-lib';
import { AccountService } from '../../../services/account-service';
import { Observable, combineLatest, of, Subscription } from 'rxjs';
import { switchMap, map, take } from 'rxjs/operators';
import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { CashActivitySearchFormValidator } from '../cash-activity-search/cash-activity-form-validator';
import { CashActivitySearchCriteria } from '../../../services/service-interfaces/cash-activity-search-interface';
import { CashActivityService } from '../../../services/cash-activity-service';
import { DefaultDatePicker } from '../../../utilities/defaultDatePicker';

const DEFAULT_END_DATE = new Date('12/31/2099').toISOString();
const DEFAULT_START_DATE = new Date('1/1/2000').toISOString();

@Component({
  selector: 'atom-cash-activity-search',
  templateUrl: './cash-activity-search.component.html',
  styleUrls: ['./cash-activity-search.component.css']
})
export class CashActivitySearchComponent implements OnInit, OnDestroy {
  filteredBy = '';
  dataSource = new ObservableDataSource<CashActivity>();
  selectedCashActivity$: Observable<CashActivity[]>;
  isSearching = false;
  errorMessage: string;
  tableState: { [key: string]: string | number } = {};
  showData = false;
  formValidator = new CashActivitySearchFormValidator();

  cashActivityForm: FormGroup = this.formBuilder.group({
    brokerCodes: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.brokerCodesValidator()),
    accounts: this.formBuilder.control({ value: '', disabled: false, }, this.formValidator.accountsValidator()),
    startBusinessDate: [''],
    endBusinessDate: [''],
    minAmount: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.amountValidator()),
    maxAmount: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.amountValidator()),
  });

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  private routeSubscription: Subscription;
  private queryParams: Params;
  private searchAccounts$: Observable<Account[][] | Account[]>;
  private lastBusinessDay = DefaultDatePicker.getLastBusinessDay();

  constructor(
    private router: Router,
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private cashActivityService: CashActivityService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {

    if (this.activatedRoute.queryParams) {
      this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
        this.queryParams = Object.assign({}, params);
        this.cashActivityForm.get('accounts').setValue(this.queryParams.accounts);
        this.cashActivityForm.get('brokerCodes').setValue(this.queryParams.brokerCodes);
        if (this.queryParams.startBusinessDate) {
          this.cashActivityForm.get('startBusinessDate').setValue(moment(this.queryParams.startBusinessDate).toISOString());
        } else {
          this.cashActivityForm.get('startBusinessDate').setValue(this.lastBusinessDay);
        }
        if (this.queryParams.endBusinessDate) {
          this.cashActivityForm.get('endBusinessDate').setValue(moment(this.queryParams.endBusinessDate).toISOString());
        } else {
          this.cashActivityForm.get('endBusinessDate').setValue(this.lastBusinessDay);
        }
        this.cashActivityForm.get('minAmount').setValue(this.queryParams.minAmount);
        this.cashActivityForm.get('maxAmount').setValue(this.queryParams.maxAmount);

        if (Object.keys(params).length && (!Object.keys(params).includes('symbol'))) {
          // Mark form as dirty so reset button appears
          this.cashActivityForm.markAsDirty();
          this.searchCashActivity();
        }
      }));
    }
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  searchCashActivity(searchButtonClicked: boolean = false) {
    this.snackBar.dismiss();
    if (searchButtonClicked) {
      // clear initial table state if the user perform a new search
      this.clearQueryParams();
      this.tableState = {};
    } else {
      // set initial table state from query param if the user is back navigating from another page
      const sortDir = this.queryParams.sortDir;
      const sortColName = this.queryParams.sortColName;
      const pageSize = this.queryParams.pageSize;
      const pageIndex = this.queryParams.pageIndex;
      const filterValue = this.queryParams.filterValue;
      this.tableState = {
        sortDir,
        sortColName,
        pageSize,
        pageIndex,
        filterValue
      };
    }
    this.showData = false;
    this.changeDetector.detectChanges();

    this.selectedCashActivity$ = this.chooseQuery();
    this.showData = true;

  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.changeDetector.detectChanges();
  }


  handlecashActivityListError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }


  private chooseQuery(): Observable<CashActivity[]> {
    const accountValues = this.cashActivityForm.get('accounts').value;
    const brokerCodeValues = this.cashActivityForm.get('brokerCodes').value;
    let accountNumbers = [];
    let accountNumbersFiltered = [];
    let brokerCodes = [];
    let brokerCodesFiltered = [];
    let startBusinessDateValue = this.cashActivityForm.get('startBusinessDate').value;
    let endBusinessDateValue = this.cashActivityForm.get('endBusinessDate').value;
    const minAmountValue = parseFloat(this.cashActivityForm.get('minAmount').value);
    const maxAmountValue = parseFloat(this.cashActivityForm.get('maxAmount').value);

    this.queryParams = this.tableState as Params;

    if (startBusinessDateValue) {
      startBusinessDateValue = moment(startBusinessDateValue).format('YYYY-MM-DD');
    } else {
      startBusinessDateValue = DEFAULT_START_DATE;
    }
    this.queryParams.startBusinessDate = startBusinessDateValue;

    if (endBusinessDateValue) {
      endBusinessDateValue = moment(endBusinessDateValue).format('YYYY-MM-DD');
    } else {
      endBusinessDateValue = DEFAULT_END_DATE;
    }
    this.queryParams.endBusinessDate = endBusinessDateValue;

    if (minAmountValue) {
      this.queryParams.minAmount = minAmountValue;
    }

    if (maxAmountValue) {
      this.queryParams.maxAmount = maxAmountValue;
    }

    if (accountValues) {
      accountNumbers = accountValues.split(',').map((accountNum: string) => {
        // allow accountNumbers with the officeCode prefixed
        accountNum = accountNum.length === 8 ? accountNum.substr(3) : accountNum;
        return accountNum.trim();
      });
      accountNumbersFiltered = Array.from(new Set(accountNumbers));
      this.queryParams.accounts = accountValues;

      this.filteredBy = `Account - ${accountNumbersFiltered.join(', ')}`;
    } else if (brokerCodeValues) {
      brokerCodes = brokerCodeValues.split(',').map(code => code.trim());
      brokerCodesFiltered = Array.from(new Set(brokerCodes));
      this.queryParams.brokerCodes = brokerCodeValues;

      this.filteredBy = `Broker Code - ${brokerCodesFiltered.join(', ')}`;
    }


    const cashActivitySearchCriteria: CashActivitySearchCriteria = {
      businessDateStart: startBusinessDateValue,
      businessDateEnd: endBusinessDateValue,
      amountMin: minAmountValue,
      amountMax: maxAmountValue
    };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams,
    });

    if (accountValues) {
      return this.cashActivityService.getCashActivityByAccountNumbers(accountNumbersFiltered, cashActivitySearchCriteria);
    } else if (brokerCodeValues) {
      return this.cashActivityService.getCashActivityByBrokerCodes(brokerCodesFiltered, cashActivitySearchCriteria);
    } else {
      return this.cashActivityService.getCashActivityByAccountNumbers([], cashActivitySearchCriteria);
    }
  }

  // Clears the value and disables client field and enables account field
  accountFieldClicked() {
    this.cashActivityForm.get('brokerCodes').setValue('');
    this.cashActivityForm.get('brokerCodes').disable();
    this.cashActivityForm.get('accounts').enable();
  }

  // Clears the value and disables account field and enables client field
  clientFieldClicked() {
    this.cashActivityForm.get('accounts').setValue('');
    this.cashActivityForm.get('accounts').disable();
    this.cashActivityForm.get('brokerCodes').enable();
  }

  reset() {
    this.cashActivityForm.reset();
    this.cashActivityForm.get('accounts').enable();
    this.cashActivityForm.get('brokerCodes').enable();
    this.cashActivityForm.get('startBusinessDate').setValue(this.lastBusinessDay);
    this.cashActivityForm.get('endBusinessDate').setValue(this.lastBusinessDay);
    this.showData = false;
    this.filteredBy = '';
  }

  selectYearMonth(event: moment.Moment) {
    this.cashActivityForm.get('contractMonthYear').setValue(event);
    this.cashActivityForm.get('contractMonthYear').markAsDirty();
  }

  displayCommodity(commodity: Commodity): string {
    if (commodity) {
      return commodity.name;
    }
    return '';
  }

  clearSide() {
    this.cashActivityForm.get('side').setValue('');
  }

  handlecashActivityListChange(tableState: { [key: string]: string | number }) {
    if (tableState.sortDir && tableState.sortColName) {
      this.queryParams.sortDir = tableState.sortDir;
      this.queryParams.sortColName = tableState.sortColName;
    } else if (this.queryParams.sortDir && this.queryParams.sortColName) {
      // remove sorted direction and column in query param if there's no sort applied
      delete this.queryParams.sortDir;
      delete this.queryParams.sortColName;
    }
    if (tableState.pageSize) {
      this.queryParams.pageSize = tableState.pageSize;
    }
    if (tableState.pageIndex !== undefined) {
      this.queryParams.pageIndex = tableState.pageIndex;
    }

    if (tableState.filterValue) {
      this.queryParams.filterValue = tableState.filterValue;
    } else if (this.queryParams.filterValue) {
      // remove filter query param if there's no filter applied
      delete this.queryParams.filterValue;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }
}
