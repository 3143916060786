import { FormControl } from '@angular/forms';

export class SplitValidators {

  static splitNumberValidator(formControl: FormControl): { [key: string]: boolean } | null {
    const splitNumber = formControl.value;
    const splitRegex = /^\d*(\.\d{0,8})?$/;

    if (!splitNumber) {
      return null;
    }

    if ( splitNumber.length) {
      return splitRegex.test(splitNumber) ? null : { invalidSplitNumber: true };
    }

  }
}
