<form [formGroup]="rateForm" (ngSubmit)="onSave()" fxLayout="column">
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="select-container">
      <mat-form-field>
        <mat-select [disableOptionCentering]="true" required placeholder="Exchange" formControlName="exchange" [multiple]="!data.existingRate">
          <mat-option *ngFor="let exchange of knownExchanges" [value]="exchange.id">{{exchange.displayName}}</mat-option>
        </mat-select>
        <mat-error *ngIf="rateForm.get('exchange').invalid">{{getErrorMessage(rateForm.get('exchange'))}}</mat-error>
      </mat-form-field>
    </div>
    <div class="select-container">
      <mat-form-field>
        <mat-select [disableOptionCentering]="true" required placeholder="Commodity" formControlName="commodity"  [multiple]="!data.existingRate">
          <ng-container *ngFor="let commodity of commodities$ | async as commodities; index as i">
            <mat-option
              *ngIf="rateForm.get('exchange').value !== 'ALL' && shouldDisplayCommodity(commodity, i, commodities)"
              [value]="commodity.id"
            >{{commodity.name}}</mat-option>
          </ng-container>
        </mat-select>
        <mat-error *ngIf="rateForm.get('commodity').invalid">{{getErrorMessage(rateForm.get('commodity'))}}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <mat-form-field *ngIf="this.isSlidingScale && this.rateForm.get('level').value !== '' && this.rateForm.get('level').value !== '1'">
    <input matInput placeholder="Level Start (round turn)" formControlName="rangeBottom" mask="09999">
    <mat-error *ngIf="rateForm.get('rangeBottom').invalid">{{getErrorMessage(rateForm.get('rangeBottom'))}}</mat-error>
  </mat-form-field>

  <div *ngIf="!rateForm.get('exchange').invalid && !rateForm.get('commodity').invalid">
    <atom-commission-rate-table [rateForm]="rateForm"></atom-commission-rate-table>
  </div>
  <div class="bottom-button-container">
    <button mat-raised-button class="bottom-button add-rate" [disabled]="!rateForm.valid" (click)="onSave()" color="accent">
      {{!data.existingRate ? "Add Rate" : ""}}
      {{data.existingRate && data.existingRate.get('level').value ? "Save Tier" : ""}}
      {{data.existingRate && !data.existingRate.get('level').value ? "Save Changes" : ""}}
    </button>
    <button type="button" mat-raised-button class="bottom-button" (click)="onCancel()">Cancel</button>
  </div>
</form>
