import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Account, CashActivity, AccountPurpose } from '@advance-trading/ops-data-lib';
import { shareReplay, map, switchMap } from 'rxjs/operators';
import { Observable, of, combineLatest } from 'rxjs';
import { CashActivitySearchCriteria } from './service-interfaces/cash-activity-search-interface';
import * as moment from 'moment';
import { Apollo } from 'apollo-angular';
import { AuthService } from '@advance-trading/angular-ati-security';
import { CashActivityQueries } from '../services/graphql-queries/cash-activity-queries';
import { HttpHeaders } from '@angular/common/http';
import { AccountService } from './account-service';

@Injectable({
  providedIn: 'root'
})
export class CashActivityService {

  constructor(
    private db: AngularFirestore,
    private apollo: Apollo,
    private authService: AuthService,
    private accountService: AccountService
  ) { }

  /**
   * Return the specified CashActivity document
   * @param accountDocId the docId of the Account containing the CashActivity to  return
   * @param transactionDocId the docId of the CashActivity to return
   */
  getCashActivityByDocId(accountDocId: string, tradeDocId: string): Observable<CashActivity> {
    return this.db.doc<CashActivity>(`${Account.getDataPath(accountDocId)}/${CashActivity.getDataPath(tradeDocId)}`)
      .valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Return CashActivitys for a particular Account, contractYearMonth, side, strikePrice, commodityId, and securitySubType
   * @param accountDocId the docId of the Account containing the CashActivity to return
   * @param searchParams Interface with each of the search properties which can all be undefined
   * uses get()
   */
  getCashActivityByAccountAndSearchParameters(accountDocId: string, searchParams: CashActivitySearchCriteria) {
    return this.db.collection<CashActivity>(`${Account.getDataPath(accountDocId)}/${CashActivity.getDataPath()}`,
    ref => {
      let finalRef = ref as firebase.default.firestore.Query<firebase.default.firestore.DocumentData>;
      if (searchParams.amountMin) {
        finalRef = finalRef.where('amount', '>=', searchParams.amountMin);
      }
      if (searchParams.amountMax) {
        finalRef = finalRef.where('amount', '<=', searchParams.amountMax);
      }
      return finalRef;
    }).get().pipe(
      map(querySnap => querySnap.docs.map(doc => doc.data() as CashActivity) as CashActivity[])).pipe(
      map(cashActivity => {
        if (searchParams.businessDateStart) {
          cashActivity = cashActivity.filter(activity =>
            moment(activity.businessDate).isSameOrAfter(moment(searchParams.businessDateStart)));
        }
        if (searchParams.businessDateEnd) {
          cashActivity = cashActivity.filter(activity =>
            moment(activity.businessDate).isSameOrBefore(moment(searchParams.businessDateEnd)));
        }
        return cashActivity;
      }),
      shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Return CashActivitys for a particular Account, contractYearMonth, side, strikePrice, commodityId, and securitySubType
   * @param accountDocId the docId of the Account containing the CashActivity to return
   * @param searchParams Interface with each of the search properties which can all be undefined
   * uses valueChanges()
   */
  findCashActivityByAccountAndSearchParameters(accountDocId: string, searchParams: CashActivitySearchCriteria) {
    return this.db.collection<CashActivity>(`${Account.getDataPath(accountDocId)}/${CashActivity.getDataPath()}`,
    ref => {
      let finalRef = ref as firebase.default.firestore.Query<firebase.default.firestore.DocumentData>;
      if (searchParams.amountMin) {
        finalRef = finalRef.where('amount', '>=', searchParams.amountMin);
      }
      if (searchParams.amountMax) {
        finalRef = finalRef.where('amount', '<=', searchParams.amountMax);
      }
      return finalRef;
    }).valueChanges().pipe(
      map(cashActivity => {
        if (searchParams.businessDateStart) {
          cashActivity = cashActivity.filter(activity =>
            moment(activity.businessDate).isSameOrAfter(moment(searchParams.businessDateStart)));
        }
        if (searchParams.businessDateEnd) {
          cashActivity = cashActivity.filter(activity =>
            moment(activity.businessDate).isSameOrBefore(moment(searchParams.businessDateEnd)));
        }
        return cashActivity;
      }),
      shareReplay({ bufferSize: 1, refCount: true }));
  }

  // GraphQL Queries

  /**
   * Returns CashActivity objects by accounts or accounts associated with margin group accounts and other search criteria specified
   * @param accountNumbers accounts to return cash activity for
   * @param gqlParams an interface that has been populated with all search query criteria
   */
  getCashActivityByAccountNumbers(accountNumbers: string[], gqlParams: any): Observable<CashActivity[]> {
    if (accountNumbers.length === 0) {
      return this.getCashActivityByAccountsQuery(accountNumbers, gqlParams);
    }
    return combineLatest(
      accountNumbers.map((accountNumber) => {
        return this.accountService.retrieveActiveAccountsByNumber(accountNumber);
      })
    ).pipe(map((accounts) => accounts.flat()))
      .pipe(switchMap((accounts) => {
        if (accounts.length === 0) {
          return of([]);
        }
        const observableAccounts = [];
        accounts.forEach((account: Account) => {
          // For margin group accounts fetch all accounts that are associated with the margin group account
          if (account.purpose === AccountPurpose.MARGIN_GROUP) {
            observableAccounts.push(this.accountService.retrieveAccountsByMarginGroupAccountDocId(account.marginGroupAccountDocId));
          }
        });

        if (observableAccounts.length) {
          return combineLatest(observableAccounts).pipe(
            map(accts => accts.flat()),
            switchMap((accts: Account[]) => {

              // Remove duplicate accounts before getting cash activity for each account
              const duplicates = [];
              accts = accts.filter(acct => duplicates.includes(acct.docId) ? false : duplicates.push(acct.docId));
              const acctNumbers = accts.map((account: Account): string => account.number);
              return this.getCashActivityByAccountsQuery(acctNumbers, gqlParams);
            })
          );
        }
        accountNumbers = accounts.map(account => account.number);
        return this.getCashActivityByAccountsQuery(accountNumbers, gqlParams);
      }));
  }

  /**
   * Returns selected fields of CashActivity objects by accounts and other search criteria specified by a graphql query
   * @param cashActivityQuery an interface that has been populated with all search query criteria
   */
  private getCashActivityByAccountsQuery(accounts: string[], cashActivityQuery: CashActivitySearchCriteria) {
    return this.apollo.query({
      query: CashActivityQueries.getCashActivityByAccountsQuery,
      variables: {
        accounts,
        cashActivityQuery
      },
      context: {
        withCredentials: true,
        headers: new HttpHeaders({ Authorization: `Bearer ${this.authService.accessToken}` })
      }
    }).pipe(
      map(results => {
        return results.data.cashActivityByAccounts;
      })
    );
  }

  /**
   * Returns selected fields of CashActivity objects by brokerCodes and other search criteria specified
   * @param cashActivityQuery an interface that has been populated with all search query criteria
   */
  getCashActivityByBrokerCodes(brokerCodes: string[], cashActivityQuery: CashActivitySearchCriteria) {
    return this.apollo.query({
      query: CashActivityQueries.getCashActivityByBrokerCodesQuery,
      variables: {
        brokerCodes,
        cashActivityQuery
      },
      context: {
        withCredentials: true,
        headers: new HttpHeaders({ Authorization: `Bearer ${this.authService.accessToken}` })
      }
    }).pipe(
      map(results => {
        return results.data.cashActivityByBrokerCodes;
      })
    );
  }


}
