<div fxLayout="row wrap" fxLayoutAlign="space-between start" fxLayoutGap="10">
  <!-- Broker Relationships Detail Card -->
  <div class="card-wrapper" fxFlex="100" fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
      <!-- Error Message -->
      <div *ngIf="errorMessage">
        <h2 class="card-title">Error Retrieving Broker Relationship Details</h2>
        <p [innerHTML]="errorMessage"></p>
        <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
      </div>
      <!-- Title & Create / Edit / Update / Cancel Buttons -->
      <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="card-title" *ngIf="createMode && !isExisting">New Broker Relationship</h2>
        <h2 class="card-title" *ngIf="createMode && isExisting">Input Broker Relationship</h2>
        <ng-container *ngIf="secondaryLabel$ | async"></ng-container>
        <h2 class="card-title" *ngIf="!createMode">Sales Code {{ salesCode?.salesCode }} {{ secondaryLabel }}</h2>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
          <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
          <button id="create-broker-relationship" aria-label="Create Broker Relationship" *ngIf="createMode && updateComplete" [disabled]="!salesCodeForm.valid" (click)="submit()" mat-mini-fab color="accent" matTooltip="Create Broker Relationship">
            <mat-icon>done</mat-icon>
          </button>
          <button id="edit-broker-relationship" aria-label="Edit Broker Relationship" *ngIf="!editMode && !addFcmMode && !createMode && updateComplete && canUserEditBrokerRel()" (click)="setEditMode(true)" mat-mini-fab color="accent" matTooltip="Edit Broker Relationship">
            <mat-icon>edit</mat-icon>
          </button>
          <button id="update-broker-relationship" aria-label="Update Broker Relationship" *ngIf="!createMode && editMode && updateComplete" [disabled]="brokerRelForms.invalid || brokerRelForms.pristine" (click)="submit()" mat-mini-fab color="accent" matTooltip="Update Broker Relationship">
            <mat-icon>done</mat-icon>
          </button>
          <button id="add-broker-relationship" aria-label="Add Broker Relationship" *ngIf="addFcmMode && updateComplete" [disabled]="!salesCodeForm.valid || salesCodeForm.pristine" (click)="submit()" mat-mini-fab color="accent" matTooltip="Add Broker Relationship">
            <mat-icon>done</mat-icon>
          </button>
          <button id="cancel-edit-broker-relationship" aria-label="Discard Changes" *ngIf="updateComplete && (editMode || addFcmMode) && (!createMode || (createMode && !salesCodeForm.pristine))" (click)="reset()" mat-mini-fab color="" matTooltip="Discard Changes">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
      <!-- Broker Relationship Form -->
      <form *ngIf="!errorMessage" [formGroup]="salesCodeForm" #formDirective="ngForm" fxLayout="column">
        <!-- Create New Broker Relationship(s) -->
        <div *ngIf="createMode">
          <!-- Title / Add Another Broker Icon -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <span class="section-label">Brokers</span>
            <button *ngIf="brokerForms.controls.length < 10" aria-label="Add Broker" type="button" mat-icon-button color="primary" (click)="addBroker()" matTooltip="Add Broker">
              <mat-icon class="add-broker-icon">add_circle</mat-icon>
            </button>
          </div>
          <!-- Equal Splits Checkbox -->
          <div fxLayout="row wrap" *ngIf="brokerForms.controls.length > 1" >
            <mat-checkbox class="splits-checkbox" aria-label="Assign Equal Splits" formControlName="equalSplits" [checked]="checked" (change)="onEqualSplitsChange($event)">Equal Splits</mat-checkbox>
          </div>
          <!-- Brokers and Splits -->
          <div formArrayName="brokers" *ngFor="let broker of brokerForms.controls; let i = index;">
            <div [formGroupName]="i" fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
              <!-- Broker Name -->
              <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="50">
                <input matInput type="text" placeholder="Select broker by name" formControlName="brokerUser" [matAutocomplete]="autoBroker" (blur)="refreshAvailableBrokers()" required>
                <mat-error *ngIf="brokerForms.at(i).get('brokerUser').invalid">You must select a valid broker</mat-error>
                <button *ngIf="brokerForms.at(i).get('brokerUser').value" matSuffix mat-icon-button aria-label="Clear" (click)="brokerForms.at(i).get('brokerUser').setValue('')">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete #autoBroker="matAutocomplete" [displayWith]="displayBroker">
                  <mat-option *ngFor="let broker of filteredBrokers[i] | async" [value]="broker">
                    {{ broker.firstName }} {{ broker.lastName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <!-- Split Input -->
              <mat-form-field fxFlex.xs="40" fxFlex.gt-xs="20">
                <input matInput type="text" placeholder="Split Percentage" formControlName="split" required>
                  <mat-error *ngIf="brokerForms.at(i).get('split').invalid">
                      {{ getErrorMessage(brokerForms.at(i).get('split')) }}</mat-error>
              </mat-form-field>

              <!-- Spacing to match lead broker checkbox and remove button -->
              <div fxFlex.xs="25" fxFlex.gt-xs="10" *ngIf="brokerForms.controls.length === 1"></div>

              <div fxFlex.xs="25" fxFlex.gt-xs="10" *ngIf="brokerForms.controls.length > 1">
                <mat-checkbox  class="leadBroker" formControlName="leadBroker">
                  Lead Broker
                </mat-checkbox>
              </div>
              <!-- Remove Broker Icon -->
              <div fxFlex.xs="25" fxFlex.gt-xs="10" *ngIf="brokerForms.controls.length > 0">
                <button class="clear-broker-button" mat-icon-button
                  aria-label="Remove Broker" (click)="removeBroker(i)" color="" matTooltip="Remove Broker">
                  <mat-icon>clear</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <mat-error *ngIf="!brokerForms.pristine && brokerForms.hasError('invalidSplits')" class="splits-error">
            Combined splits must equal 100%</mat-error>
          <mat-error *ngIf="!brokerForms.pristine && brokerForms.hasError('invalidLeadBroker')" class="splits-error">
            There must be 1 lead broker</mat-error>
        </div>
        <ng-container *ngIf="!createMode && !brokerRelationships?.length">
          <p>No assigned broker relationships</p>
        </ng-container>
          <!-- FCMs -->
          <div *ngIf="!createMode && brokerRelationships?.length" fxLayout="row" fxLayoutAlign="start center">
            <h4>Clearing Firms</h4>
            <!-- Show Add Icon for existing broker relationships if additional FCMs are unassigned -->
            <button *ngIf="!addFcmMode && !editMode && brokerCodeRequester && !hasAllFcms" aria-label="Add Clearing Firm" type="button" mat-icon-button color="primary" (click)="addFcm()" matTooltip="Add Clearing Firm">
              <mat-icon class="add-fcm-icon">add_circle</mat-icon>
            </button>
          </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <!-- Show checkboxes for original create or for adding new FCMs -->
          <div *ngIf="createMode || addFcmMode" fxFlex.xs="100" fxFlex.gt-xs="50">
            <span *ngIf="createMode" class="check-radio-label">Clearing Firms</span>
            <mat-checkbox formControlName="fcmRCG">RCG</mat-checkbox>
            <mat-checkbox formControlName="fcmRJO">R.J. O'Brien</mat-checkbox>
            <mat-checkbox formControlName="fcmEDF">ED&F Man</mat-checkbox>
            <mat-checkbox formControlName="fcmSF">Straits</mat-checkbox>
            <mat-checkbox formControlName="fcmMac">Macquarie</mat-checkbox>
            <mat-checkbox formControlName="fcmCun">Cunningham</mat-checkbox>
          </div>
          <!-- Show current FCM list if not original create and not adding new FCMs -->
          <div *ngIf="!createMode && !addFcmMode">
            <p *ngIf="hasRCGBrokerRel" class="list-data">RCG</p>
            <p *ngIf="hasRJOBrokerRel" class="list-data">R.J. O'Brien</p>
            <p *ngIf="hasEDFBrokerRel" class="list-data">ED&F Man</p>
            <p *ngIf="hasSFBrokerRel" class="list-data">Straits</p>
            <p *ngIf="hasMacBrokerRel" class="list-data">Macquarie</p>
            <p *ngIf="hasCunBrokerRel" class="list-data">Cunningham</p>
          </div>
          <mat-form-field *ngIf="createMode" fxFlex.xs="40" fxFlex.gt-xs="20">
            <mat-select [disableOptionCentering]="true" placeholder="Managing Pod" formControlName="managingPod" (selectionChange)="onManagingPodChange($event)" required>
              <mat-option *ngFor="let pod of podNames" [value]="pod">{{ pod | replace | titlecase }}</mat-option>
            </mat-select>
            <mat-error *ngIf="salesCodeForm.get('managingPod').invalid">You must select a managing pod</mat-error>
          </mat-form-field>
          <!-- Spacing to match lead broker checkbox and remove button -->
          <div fxFlex.xs="25" fxFlex.gt-xs="10"></div>
          <div fxFlex.xs="25" fxFlex.gt-xs="10"></div>
        </div>
        <mat-error *ngIf="createMode && brokerForms.valid && salesCodeForm.hasError('invalidFCM')" class="section-error">You must select at least one Clearing Firm</mat-error>
        <!-- Display Existing Broker Relationships -->
        <div *ngIf="!createMode && brokerRelationships?.length">
          <h4>Broker Relationships</h4>
          <div formArrayName="brokerRels" *ngFor="let brokerRel of brokerRelForms.controls; let i = index; last as last">
            <!-- Broker Relationship Details -->
            <div [ngClass]="['broker-rel-detail', i === 0 ? 'first' : 'remaining']" fxLayout="column">
              <!-- Broker Code / Status / FCM / Accounts Icon -->
              <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100" fxLayoutGap="10">
                <div class="data" fxLayout="column" fxFlex.xs="100" fxFlex.gt-xs="100">
                  <div>
                    <span class="link-list-data" (click)=navigateBrokerRelationship(brokerRelationships[i])>
                      Broker Code: {{ brokerRelationships[i].brokerCode }}
                    </span>
                    <span class="link-list-data" (click)=navigateBrokerRelationship(brokerRelationships[i])>
                      <ng-container *ngIf="brokerRelationships[i].isSecondary">{{ brokerRelationships[i].secondaryLabel }} </ng-container>
                      <ng-container *ngIf="brokerRelationships[i].extendedCode">({{ brokerRelationships[i].extendedCode }})</ng-container>
                    </span>
                  </div>
                    <h5>Brokers</h5>
                    <div *ngFor="let broker of brokerRelBrokers[i] | async; let j = index; last as last">
                      <div *ngIf="canUserViewSplits()" fxFlex.xs="15" fxFlex.gt-xs="10">
                        {{ displaySplit(brokerRelationships[i].splits[j].split) }}%
                      </div>
                      <div class="link-list-data" (click)="navigateBroker(broker)" >
                        {{ broker.firstName }} {{ broker.lastName}} ({{ broker.brokerType | replace | titlecase | replace: ' ' : '-' }})
                      </div>
                      <div class="list-data" *ngIf="isLeadBroker(broker)"> (Lead Broker) </div>
                    </div>
                </div>
                <ati-labeled-data fxFlex.xs="45" fxFlex.gt-xs="20" label="Status" data="{{ brokerRelationships[i].status | titlecase }}"></ati-labeled-data>
                <ati-labeled-data fxFlex.xs="45" fxFlex.gt-xs="20" label="FCM" data="{{ brokerRelationships[i].fcm | displayFcm }}"></ati-labeled-data>
                <div class="data" fxLayout="column" fxFlex.xs="45" fxFlex.gt-xs="20">
                  <span class="mat-small mat-hint">Accounts</span>
                  <span>
                    <mat-icon class="icon-button" (click)="selectAccountsIcon(brokerRelationships[i].brokerCode)" mat-icon-button matTooltip="Broker Relationship Accounts" color="primary">folder</mat-icon>
                  </span>
                </div>
              </div>
              <!-- Status Dates -->
              <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100" fxLayoutGap="10">
                <ati-labeled-data *ngIf="brokerRelationships[i].activationDate" fxLayout="column" fxFlex.xs="45" fxFlex.gt-xs="20" label="Activation Date" data="{{ brokerRelationships[i].activationDate | date }}"></ati-labeled-data>
                <ati-labeled-data *ngIf="brokerRelationships[i].inactivationDate && brokerRelationships[i].status !== 'ACTIVE'" fxLayout="column" fxFlex.xs="45" fxFlex.gt-xs="20" label="Inactivation Date" data="{{ brokerRelationships[i].inactivationDate | date }}"></ati-labeled-data>
                <ati-labeled-data *ngIf="brokerRelationships[i].closureDate" fxLayout="column" fxFlex.xs="45" fxFlex.gt-xs="20" label="Closure Date" data="{{ brokerRelationships[i].closureDate | date }}"></ati-labeled-data>
              </div>
            </div>
            <div [formGroupName]="i">
              <!-- Edit Extended Code for RCG -->
              <div *ngIf="editMode && brokerRelationships[i].fcm === 'RCG' && brokerRelationships[i].status === 'PENDING'" fxLayout="row wrap">
                <mat-form-field fxFlex.xs="45" fxFlex.gt-xs="20">
                  <input matInput type="text" placeholder="Extended Code" formControlName="extendedCode">
                  <mat-error *ngIf="brokerRelForms.at(i).get('extendedCode').invalid">Must be 5 characters</mat-error>
                </mat-form-field>
              </div>
              <!-- Edit Broker Relationship Status -->
              <div *ngIf="editMode && (brokerRelationships[i].status === 'ACTIVE' || brokerRelationships[i].status === 'INACTIVE')">
                <mat-radio-group formControlName="status" required>
                  <span class="check-radio-label">Broker Relationship Status</span>
                  <mat-radio-button value="ACTIVE">Active</mat-radio-button>
                  <mat-radio-button value="INACTIVE">Inactive</mat-radio-button>
                  <mat-radio-button value="CLOSED">Closed</mat-radio-button>
                </mat-radio-group>
              </div>
              <!-- Progress Indicators for PENDING status Broker Relationship -->
              <div *ngIf="brokerRelationships[i].status === 'PENDING'" class="checkbox-group" fxLayout.gt-xs="row wrap" fxLayout.xs="column" fxLayoutGap="5">
                <span class="check-radio-label">Setup Progress</span>
                <div fxLayout.xs="column" fxLayoutAlign="start space-between" fxFlex="100">
                  <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="FCM Approved"
                    data="{{ getBrokerRelAtIndex(i).get('isFcmApproved').value | yesNo }}">
                  </ati-labeled-data>
                  <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="Platforms Setup"
                    data="{{ getBrokerRelAtIndex(i).get('isPlatformsSetupComplete').value | yesNo }}">
                  </ati-labeled-data>
                  <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="Error & Accommodation Accounts"
                    data="{{ getBrokerRelAtIndex(i).get('hasErrorAndAccommodationAccounts').value | yesNo }}">
                  </ati-labeled-data>
                  <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="Error Account Limits"
                    data="{{ getBrokerRelAtIndex(i).get('hasErrorAccountLimits').value | yesNo }}">
                  </ati-labeled-data>
                </div>
                <mat-checkbox *ngIf="editMode" formControlName="isFcmApproved">FCM Approved</mat-checkbox>
                <mat-checkbox *ngIf="editMode" formControlName="isPlatformsSetupComplete">Platforms Setup</mat-checkbox>
                <mat-checkbox *ngIf="editMode" formControlName="hasErrorAndAccommodationAccounts">Error & Accommodation Accounts</mat-checkbox>
                <mat-checkbox *ngIf="editMode" formControlName="hasErrorAccountLimits">Error Account Limits</mat-checkbox>
              </div>
            </div>
            <mat-divider inset *ngIf="!last"></mat-divider>
          </div>
        </div>
      </form>
    </mat-card>
  </div>
  <!-- Account Series Card -->
  <div *ngIf="!createMode && salesCode" class="card-wrapper" fxFlex="100" fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
      <atom-account-series-detail
        [salesCodeDocId]="salesCode.docId"
        [salesCode]="salesCode.salesCode"
        [canAddNewAccountSeries]="canAddNewAccountSeries()"></atom-account-series-detail>
    </mat-card>
  </div>
</div>
