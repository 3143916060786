<!-- Error Message -->
<div *ngIf="errorMessage">
  <h2 class="card-title">Error Retrieving Account Series Details</h2>
  <p [innerHTML]="errorMessage"></p>
  <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
    or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
</div>
<!-- Title & Add / Update / Cancel Buttons -->
<div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="start center">
    <h2 class="card-title">Account Series</h2>
    <button *ngIf="!addMode && canAddNewAccountSeries" aria-label="Add Account Series" type="button" mat-icon-button color="primary" (click)="addAccountSeries()" matTooltip="Add Account Series">
      <mat-icon class="add-icon">add_circle</mat-icon>
    </button>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
    <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
    <button id="add-account-series" aria-label="Add Account Series" *ngIf="addMode && updateComplete && selectedAccountSeries.length > 0 && selectedAccountSeries.length <= 10" (click)="submit()" mat-mini-fab color="accent" matTooltip="Add Account Series">
      <mat-icon>done</mat-icon>
    </button>
    <button id="cancel-add-account-series" aria-label="Discard Changes" *ngIf="addMode && updateComplete" (click)="reset()" mat-mini-fab color="" matTooltip="Discard Changes">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
<!-- Account Series -->
<div *ngIf="!errorMessage">
  <!-- Display Assigned and Selected Account Series -->
  <div *ngIf="assignedAccountSeries$ | async as assignedAccountSeries" fxLayout="column">
    <ng-container *ngIf="!assignedAccountSeries.length">
      <p fxFlex="100" class="no-assigned-series-message">No assigned account series</p>
    </ng-container>
    <div fxFlex="100">
      <ng-container *ngIf="combinedAccountSeries.length">
        <mat-table [dataSource]="combinedAccountSeries" fxFlex.xs="100" fxFlex.gt-xs="50" class="mat-elevation-z0">
          <ng-container matColumnDef="range">
            <mat-header-cell *matHeaderCellDef fxFlex.xs="75" fxFlex.gt-xs="45">Range</mat-header-cell>
            <mat-cell *matCellDef="let series" fxFlex.xs="75" fxFlex.gt-xs="45" [ngClass]="{ 'selected' : series.status === 'NEW' }">{{ series.start }} - {{ series.end }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="size">
            <mat-header-cell *matHeaderCellDef fxFlex.gt-xs="15">Size</mat-header-cell>
            <mat-cell *matCellDef="let series" fxFlex.gt-xs="15" [ngClass]="{ 'selected' : series.status === 'NEW' }">{{ series.size }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef fxFlex.gt-xs="25">Status</mat-header-cell>
            <mat-cell *matCellDef="let series" fxFlex.gt-xs="25" [ngClass]="{ 'selected' : series.status === 'NEW' }">{{ displayStatus(series.status) | titlecase }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="icon">
            <mat-header-cell *matHeaderCellDef fxFlex.xs="25" fxFlex.gt-xs="15">Accounts</mat-header-cell>
            <mat-cell *matCellDef="let series" fxFlex.xs="25" fxFlex.gt-xs="15" matTooltip="Account Series Accounts">
              <ng-container *ngIf="series.status !== 'NEW'">
                <mat-icon class="icon-button" (click)="selectAccountsIcon(series)" color="primary">folder</mat-icon>
              </ng-container>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
          <mat-row *matRowDef="let series; columns: columnsToDisplay"></mat-row>
        </mat-table>
      </ng-container>
    </div>
  </div>
  <!-- Assign Additional Account Series -->
  <div *ngIf="addMode" fxLayout="column">
    <p class="instruction" fxFlex="100">Select additional account series to be assigned: </p>
    <mat-error *ngIf="selectedAccountSeries.length > 10" class="selection-error" fxFlex="100">Selection must not exceed 10 account series</mat-error>
    <mat-tab-group>
      <ng-container *ngFor="let block of accountSeriesByBlocks | keyvalue: asIsOrder">
        <mat-tab [label]="block.value.name">
          <div *ngIf="block.value.availableSeries$ | async as availableSeries">
            <ng-container *ngIf="availableSeries.length; else noAvailableSeries">
              <mat-selection-list #selectedSeries fxFlex.xs="100" fxFlex.gt-xs="30" (selectionChange)="onSelection(block.key, selectedSeries.selectedOptions.selected)">
                <mat-list-option *ngFor="let series of availableSeries" [value]="series">
                  <div fxLayout="row">
                    <span fxFlex.xs="70" fxFlex.gt-xs="70">{{ series.start }} - {{ series.end }}</span>
                    <span fxFlex.xs="30" fxFlex.gt-xs="30">{{ (series.size) }}</span>
                  </div>
                </mat-list-option>
              </mat-selection-list>
            </ng-container>
            <ng-template #noAvailableSeries>
              <p class="no-available-series-message">No available {{ block.value.name }} account series</p>
            </ng-template>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>
</div>
