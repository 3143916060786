import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ClientService } from '../../services/client-service';
import { AccountService } from '../../services/account-service';
import { Account, Client, Status } from '@advance-trading/ops-data-lib';

import { ClientSearchValidator } from './client-search.validator';

const ACCOUNT_ADMIN_ROLE = 'AccountAdmin';

@Component({
  selector: 'atom-account-search',
  templateUrl: './account-search.component.html',
  styleUrls: ['./account-search.component.css']
})
export class AccountSearchComponent implements AfterViewChecked, OnInit {

  @ViewChild('clientNameSearch', { static: false }) clientNameSearch: ElementRef;

  accountSearchForm: FormGroup = this.formBuilder.group({
    accountSearchNumber: ['', [Validators.minLength(5)]],
    accountStatus: [''],
    brokerCode: ['', [Validators.minLength(6)]],
    client: ['', [ClientSearchValidator.ClientSearchValidator]],
  });

  filteredClients$: Observable<Client[]>;
  selectedAccounts$: Observable<Account[]>;
  tableState: { [key: string]: string | number } = {};

  private activeClients$: Observable<Client[]>;
  private queryParams: Params;
  private accountSeriesDocId = '';
  private accountSeriesRange = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private authzService: Auth0AuthzService,
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private clientService: ClientService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
  }

  errorMessage: string;
  isSearching = false;
  isSearchingClientNames = false;
  showAccounts = false;
  filteredBy = '';

  ngOnInit() {

    if (this.activatedRoute.queryParams) {
      this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
        this.queryParams = Object.assign({}, params);

        this.accountSearchForm.get('accountSearchNumber').setValue(this.queryParams.accountSearchNumber);
        this.accountSearchForm.get('brokerCode').setValue(this.queryParams.brokerCode);
        this.accountSeriesDocId = this.queryParams.accountSeriesDocId;
        this.accountSeriesRange = this.queryParams.accountSeriesRange;

        if (this.queryParams.client) {
          this.accountSearchForm.get('client').setValue(this.queryParams.client);
        }

        if (this.queryParams.accountStatus === undefined) {
          this.queryParams.accountStatus = 'ALL';
          this.accountSearchForm.get('accountStatus').setValue(this.queryParams.accountStatus);
        } else {
          this.accountSearchForm.get('accountStatus').setValue(this.queryParams.accountStatus);
        }

        if (Object.keys(params).length) {
          this.searchAccounts();
        }
      });
    }
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  displayClient(client?: Client) {
    if (client) {
      return client.physicalAddress ? `${client.name}` : '';
    }
    return '';
  }

  addNewAccount() {
    if (this.accountSearchForm.get('client').value) {
      this.router.navigate(['/accounts/new'], { queryParams: { clientDocId: this.accountSearchForm.get('client').value.docId } });
    } else {
      this.router.navigate(['/accounts/new']);
    }
  }

  get accountAdmin() {
    return this.authzService.currentUserHasRole(ACCOUNT_ADMIN_ROLE);
  }

  reset() {
    this.accountSearchForm.get('accountSearchNumber').setValue('');
    this.accountSearchForm.get('brokerCode').setValue('');
    this.accountSearchForm.get('accountStatus').setValue('ALL');
    this.accountSearchForm.get('client').setValue('');
    this.accountSeriesDocId = '';
    this.accountSeriesRange = '';

    this.filteredBy = '';
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });

    this.accountSearchForm.enable();
    this.accountSearchForm.markAsPristine();
    this.clearQueryParams();
    this.showAccounts = false;
  }

  searchAccounts(searchButtonClicked: boolean = false) {
    this.accountSearchForm.markAsDirty();
    if (searchButtonClicked) {
      this.clearQueryParams();
      this.tableState = {};
      this.tableState.pageIndex = 0;
    } else {
      const sortDir = this.queryParams.sortDir;
      const sortColName = this.queryParams.sortColName;
      const pageSize = this.queryParams.pageSize;
      const pageIndex = this.queryParams.pageIndex;
      const filter = this.queryParams.filter;
      this.tableState = {
        sortDir,
        sortColName,
        pageSize,
        pageIndex,
        filter
      };
    }
    this.showAccounts = false;
    this.changeDetector.detectChanges();
    this.selectedAccounts$ = this.chooseQuery();
    this.showAccounts = true;
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('minlength')) {
      return 'Must be 5 characters';
    } else {
      return 'Unknown error';
    }
  }

  getBrokerCodeErrorMessage(control: FormControl) {
    if (control.hasError('minlength')) {
      return 'Must be 6 characters';
    } else {
      return 'Unknown error';
    }
  }

  handleAccountListChange(tableState: { [key: string]: string | number }) {
    if (tableState.sortDir && tableState.sortColName) {
      this.queryParams.sortDir = tableState.sortDir;
      this.queryParams.sortColName = tableState.sortColName;
    } else if (this.queryParams.sortDir && this.queryParams.sortColName) {
      delete this.queryParams.sortDir;
      delete this.queryParams.sortColName;
    }
    if (tableState.pageSize) {
      this.queryParams.pageSize = tableState.pageSize;
    }
    if (tableState.pageIndex !== undefined) {
      this.queryParams.pageIndex = tableState.pageIndex;
    }
    if (tableState.filter) {
      this.queryParams.filter = tableState.filter;
    } else if (this.queryParams.filter) {
      delete this.queryParams.filter;
    }
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  handleAccountError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.changeDetector.detectChanges();
  }

  // Clears the value and disables client field and enables account field
  accountSearchNumberFieldClicked() {
    this.accountSearchForm.get('brokerCode').setValue('');
    this.accountSearchForm.get('brokerCode').disable();
    this.accountSearchForm.get('client').setValue('');
    this.accountSearchForm.get('client').disable();
    this.accountSearchForm.get('accountSearchNumber').enable();
  }

  clientFieldClicked() {
    this.accountSearchForm.get('accountSearchNumber').setValue('');
    this.accountSearchForm.get('accountSearchNumber').disable();
    this.accountSearchForm.get('brokerCode').setValue('');
    this.accountSearchForm.get('brokerCode').disable();
    this.accountSearchForm.get('client').enable();
    this.prepForClientSelection();
  }

  brokerCodeFieldClicked() {
    this.accountSearchForm.get('accountSearchNumber').setValue('');
    this.accountSearchForm.get('accountSearchNumber').disable();
    this.accountSearchForm.get('client').setValue('');
    this.accountSearchForm.get('client').disable();
    this.accountSearchForm.get('brokerCode').enable();
  }

  private chooseQuery(): Observable<Account[]> {

    const accountNumberValue = this.accountSearchForm.get('accountSearchNumber').value;
    const brokerCodeValue = this.accountSearchForm.get('brokerCode').value;
    const clientValue = this.accountSearchForm.get('client').value as Client;
    const accountStatusValue = this.accountSearchForm.get('accountStatus').value;
    let clientDocId;

    this.queryParams = this.tableState as Params;

    if (accountNumberValue) {
      this.queryParams.accountSearchNumber = accountNumberValue;
    }
    if (this.accountSeriesDocId) {
      this.queryParams.accountSeriesDocId = this.accountSeriesDocId;
    }
    if (this.accountSeriesRange) {
      this.queryParams.accountSeriesRange = this.accountSeriesRange;
    }
    if (accountStatusValue) {
      this.queryParams.accountStatus = accountStatusValue;
    }
    if (brokerCodeValue) {
      this.queryParams.brokerCode = brokerCodeValue;
    }
    if (clientValue) {
      this.queryParams.client = clientValue;
      this.queryParams.clientName = clientValue.name;
    }
    if (clientValue.docId !== undefined) {
      this.queryParams.client = clientValue.docId;
      clientDocId = clientValue.docId;
    } else if (this.queryParams.client !== undefined) {
      clientDocId = this.queryParams.client;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });

    if (accountStatusValue !== 'ALL') {
      if (accountNumberValue && accountStatusValue) {
        this.filteredBy = `Account Number: ${this.queryParams.accountSearchNumber}`;
        return this.accountService.getAllAccountsByAccountNumberAndStatus(accountNumberValue, accountStatusValue as Status).pipe(
          tap((accounts) => {
            if (accounts.length === 0) {
              this.openSnackBar('No accounts associated with this account number and status', 'DISMISS', true);
            }
          })
        );
      } else if (clientDocId && accountStatusValue) {
        return this.clientService.getClient(clientDocId)
          .pipe(
            tap(client => {
              this.accountSearchForm.get('client').setValue(client);
              this.filteredBy = `Client: ${this.displayClient(client)}`;
            }),
            switchMap(() => {
              return this.accountService.getAllAccountsByClientDocIdAndStatus(clientDocId, accountStatusValue as Status).pipe(
                tap((accounts) => {
                  if (accounts.length === 0) {
                    this.openSnackBar('No accounts associated with this client and status', 'DISMISS', true);
                  }
                })
              );
            })
          );
      } else if (brokerCodeValue && accountStatusValue) {
        this.filteredBy = `Broker Code: ${this.queryParams.brokerCode}`;
        return this.accountService.getAllAccountsByBrokerCodeAndStatus(brokerCodeValue, accountStatusValue as Status).pipe(
          tap((accounts) => {
            if (accounts.length === 0) {
              this.openSnackBar('No accounts associated with this broker code and status', 'DISMISS', true);
            }
          })
        );
      } else if (accountStatusValue) {
        return this.accountService.getAccountsByStatus(accountStatusValue).pipe(
          tap((accounts) => {
            if (accounts.length === 0) {
              this.openSnackBar('No accounts associated with this status', 'DISMISS', true);
            }
          })
        );
      }
    } else if (accountNumberValue) {
      this.filteredBy = `Account Number: ${this.queryParams.accountSearchNumber}`;
      return this.accountService.getAccountsByNumber(accountNumberValue).pipe(
        tap((accounts) => {
          if (accounts.length === 0) {
            this.openSnackBar('No accounts associated with this account number', 'DISMISS', true);
          }
        })
      );
    } else if (brokerCodeValue) {
      this.filteredBy = `Broker Code: ${this.queryParams.brokerCode}`;
      return this.accountService.getAccountsByBrokerCode(brokerCodeValue).pipe(
        tap((accounts) => {
          if (accounts.length === 0) {
            this.openSnackBar('No accounts associated with this broker code', 'DISMISS', true);
          }
        })
      );
    } else if (clientDocId) {
      return this.clientService.getClient(clientDocId)
        .pipe(
          tap(client => {
            this.accountSearchForm.get('client').setValue(client);
            this.filteredBy = `Client: ${this.displayClient(client)}`;
          }),
          switchMap(() => {
            return this.accountService.getAllAccountsByClientId(clientDocId).pipe(
              tap((accounts) => {
                if (accounts.length === 0) {
                  this.openSnackBar('No accounts associated with this client', 'DISMISS', true);
                }
              })
            );
          })
        );
    } else if (this.accountSeriesDocId && this.accountSeriesRange) {
      this.filteredBy = `Account Series: ${this.queryParams.accountSeriesRange}`;
      return this.accountService.getAccountsByAccountSeries(this.accountSeriesDocId, this.accountSeriesRange).pipe(
        tap((accounts) => {
          if (accounts.length === 0) {
            this.openSnackBar('No accounts associated with this account series', 'DISMISS', true);
          }
        })
      );
    } else {
      return this.accountService.getAllAccounts();
    }
  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }

  private prepForClientSelection() {
    this.getActiveClients();
    this.filterClients();
  }

  // Gets active clients from Firestore
  private getActiveClients() {
    if (!this.activeClients$) {
      this.isSearchingClientNames = true;
      this.activeClients$ = this.clientService.getClientsByStatus(Status.ACTIVE)
        .pipe(
          shareReplay(1),
          tap(() => {
            this.isSearchingClientNames = false;
            this.changeDetector.detectChanges();
            this.clientNameSearch.nativeElement.focus();
          })
        ).pipe(
          map(clients => {
            return clients.sort((a, b) => a.name.localeCompare(b.name));
          })
        );
    }
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting a client for the account
  private filterClients() {
    this.filteredClients$ = this.accountSearchForm.controls.client.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        startWith<string | Client>(''),
        switchMap(term => typeof term === 'string' ? this.filterClientsBySearchTerm(term) : of([term]))
      );
  }

  // Filters clients from Firestore using the user input search term
  private filterClientsBySearchTerm(searchTerm: string): Observable<Client[]> {
    return this.activeClients$
      .pipe(
        map(clients => clients.filter(client => client.name.toLowerCase().includes(searchTerm.toLowerCase())))
      );
  }

  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }
}
