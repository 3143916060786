import { Component, Input, OnInit } from '@angular/core';

import { CommodityMap, OrderLeg, SecurityType, Order } from '@advance-trading/ops-data-lib';

@Component({
  selector: 'atom-order-leg',
  templateUrl: './order-leg.component.html',
  styleUrls: ['./order-leg.component.css']
})
export class OrderLegComponent implements OnInit {

  @Input() leg: OrderLeg;
  @Input() commodityMap: CommodityMap;

  constructor() { }

  ngOnInit() {
  }

  getDisplayStrike(price: number, order: OrderLeg | Order) {
    if (!price) {
      return undefined;
    }
    let priceDivisor;
    const commodity = Object.values(this.commodityMap.commodities).find(cmd =>
      cmd.electronicOptionsSymbol === order.symbol || cmd.electronicFuturesSymbol === order.symbol);
    priceDivisor = commodity ? commodity.strikePriceDivisor: 1;
    return price / priceDivisor;
  }

}

