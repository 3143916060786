import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort, SortDirection } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { of, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';

import { ObservableDataSource, StorageService } from '@advance-trading/angular-common-services';
import { CommissionScheduleService } from '@advance-trading/angular-ops-data';
import { CommissionRate, CommissionSchedule, Status } from '@advance-trading/ops-data-lib';
import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ExportService } from 'src/app/services/export.service';

const BROKER_CODE_VIEWER_ROLE = 'BrokerCodeViewer';
const PAGE_SIZE_KEY = 'atom.commissionSchedulesPageSize';

@Component({
  selector: 'atom-commission-schedules',
  templateUrl: './commission-schedules.component.html',
  styleUrls: ['./commission-schedules.component.css']
})
export class CommissionSchedulesComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatSelect, { static: false }) public matSelect: MatSelect;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  rateCodeFilterValue = new FormControl();
  statusFilter = new FormControl();
  dataSource = new ObservableDataSource<CommissionSchedule>();
  errorMessage = '';
  exportable = false;

  private unsubscribe$: Subject<void> = new Subject<void>();
  private queryParams: Params;

  constructor(
    private activatedRoute: ActivatedRoute,
    private commissionScheduleService: CommissionScheduleService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private authzService: Auth0AuthzService,
    public exportService: ExportService,
    private storageService: StorageService
  ) { }

  ngOnInit() {

    if (!this.authzService.currentUserHasRole(BROKER_CODE_VIEWER_ROLE)) {
      this.errorMessage = 'Error retrieving commission schedules; you do not have permission to view this information';
      return of([]);
    } else {
      this.dataSource.data$ = this.commissionScheduleService.getAllCommissionSchedules()
      .pipe(
        tap(commissionSchedules => {
          this.setTableState();
          this.exportable = commissionSchedules.length > 0;
        }),
        catchError(err => {
          this.errorMessage = 'Error retrieving commission schedules; please try again later';
          console.error(`Error retrieving commission schedules: ${err}`);
          return of([]);
        })
      );

      this.rateCodeFilterValue.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((filter: string) => {
        this.applyFilter(filter);
        if (filter) {
          this.queryParams.filter = filter.trim();
        } else if (this.queryParams.filter) {
          delete this.queryParams.filter;
        }

        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          replaceUrl: true,
          queryParams: this.queryParams
        });
      });

      this.statusFilter.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((filterStatus: Status) => {
        this.applyFilter(undefined, filterStatus);
        if (filterStatus) {
          this.queryParams.status = filterStatus;
        } else if (this.queryParams.status) {
          delete this.queryParams.status;
        }

        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          replaceUrl: true,
          queryParams: this.queryParams
        });
      });

      this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
        this.queryParams = Object.assign({}, params);
      }));

      const filterVal = this.queryParams.filter;
      if (filterVal) {
        this.rateCodeFilterValue.setValue(filterVal);
        this.applyFilter(this.rateCodeFilterValue.value);
      }

      const status = this.queryParams.status as Status;
      if (status) {
        this.statusFilter.setValue(status);
        this.applyFilter(undefined, status);
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = this.storageService.localStorage.get(PAGE_SIZE_KEY).value() || 10;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.filterPredicate;
    if (this.filter) {
      this.filter.nativeElement.focus();
    }
    this.changeDetector.detectChanges();
  }

  applyFilter(searchStr?: string, status?: Status) {
    if (this.dataSource.filter) {
      const existingFilter = JSON.parse(this.dataSource.filter);
      this.dataSource.filter = JSON.stringify({
        searchStr: status === undefined ? searchStr.trim().toUpperCase() : existingFilter.searchStr,
        status: searchStr === undefined ? status : existingFilter.status
      });
    } else {
      this.dataSource.filter = JSON.stringify({
        searchStr: status === undefined ? searchStr.trim().toUpperCase() : '',
        status: searchStr === undefined ? status : ''
      });
    }
  }

  clearFilter() {
    this.rateCodeFilterValue.setValue('');
    this.applyFilter('');
  }

  selectCommissionSchedule(sched: CommissionSchedule) {
    this.router.navigate(['/commissionschedules', sched.docId]);
  }

  addCommissionSchedule() {
    this.router.navigate(['/commissionschedules/new']);
  }

  getTooltip(sched: CommissionSchedule) {
    const defaultRate = sched.defaultRate instanceof Array ? sched.defaultRate[0] : sched.defaultRate;

    return Object.values(sched.rates).reduce((accumulator, rateEntry) => {
      const rate = rateEntry instanceof Array ? rateEntry[0] : rateEntry;

      return `${accumulator}\n\n${rate.commodity.replace('_', ' ')}${this.getRateString(rate)}`;
    }, `DEFAULT RATE${this.getRateString(defaultRate)}`);
  }

  handlePageChange() {
    this.storageService.localStorage.set(PAGE_SIZE_KEY, this.paginator.pageSize);
    this.queryParams.pageIndex = this.paginator.pageIndex;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  handleSortChange() {
    this.queryParams.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.queryParams.sortColName = this.queryParams.sortDir ? this.sort.active : undefined;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  private filterPredicate = (obj: CommissionSchedule, filterValue: string) => {
    const filterObj = JSON.parse(filterValue);
    if (
      (filterObj.searchStr && obj.rateCode.indexOf(filterObj.searchStr) === -1 && !this.scheduleHasRate(obj, filterObj.searchStr)) ||
      (filterObj.status && obj.status !== filterObj.status)
    ) {
      return false;
    }

    return true;
  }

  private scheduleHasRate(schedule: CommissionSchedule, searchStr: string) {
    return this.checkRate(schedule.defaultRate, searchStr) || Object.values(schedule.rates).some(entry => this.checkRate(entry, searchStr));
  }

  private checkRate(rate: CommissionRate | CommissionRate[], searchStr: string) {
    if (rate instanceof Array) {
      return rate.some(entry => this.checkRate(entry, searchStr));
    } else {
      return (
        rate.futureHedge.toFixed(2).includes(searchStr) ||
        rate.futureSpread.toFixed(2).includes(searchStr) ||
        rate.optionHedgeOpen.toFixed(2).includes(searchStr) ||
        rate.optionHedgeClose.toFixed(2).includes(searchStr) ||
        rate.optionSpreadOpen.toFixed(2).includes(searchStr) ||
        rate.optionSpreadClose.toFixed(2).includes(searchStr)
      );
    }
  }

  private getRateString(rate: CommissionRate) {
    return `
      \tFuture Hedge\t\t${rate.futureHedge.toFixed(2)}
      \tFuture Spread\t\t${rate.futureSpread.toFixed(2)}
      \tOption Hedge Open\t${rate.optionHedgeOpen.toFixed(2)}
      \tOption Hedge Close\t${rate.optionHedgeClose.toFixed(2)}
      \tOption Spread Open\t${rate.optionSpreadOpen.toFixed(2)}
      \tOption Spread Close\t${rate.optionSpreadClose.toFixed(2)}`;
  }

  private setTableState() {
    if (this.filter) {
      const pageIndex = this.queryParams.pageIndex as number;
      if (pageIndex) {
        this.dataSource.paginator.pageIndex = pageIndex;
      }

      const sortDir = this.queryParams.sortDir as SortDirection;
      const sortColName = this.queryParams.sortColName as string;
      if (sortDir && sortColName) {
        this.dataSource.sort.direction = sortDir;
        this.dataSource.sort.active = sortColName;
      }
    }
  }
}
