import { COMMA, ENTER, SEMICOLON, SPACE } from '@angular/cdk/keycodes';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ClientFcmService, ContactService, BankService, ClientService } from '@advance-trading/angular-ops-data';
import { AccountService } from '../services/account-service';
import {
  Account,
  Bank,
  Client,
  ClientFCM,
  ClientType,
  Contact,
  IncorporationType,
  MarginType,
  PerTxCommissionType,
  PhoneNumber,
  PodEnum,
  Status
} from '@advance-trading/ops-data-lib';

import { forbiddenDomainValidator, invalidDomainValidator } from './email-domain.validators';

// Security roles
const DECRYPT_ROLE = 'AccountAdmin';
const ENCRYPT_ROLE = 'AccountAdmin';
const CLIENT_UPDATE_ROLE = 'AccountAdmin';
const ATI_ONLY_DATA_ROLE = 'AllClientViewer';

@Component({
  selector: 'atom-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent implements OnInit, OnDestroy {
  clientForm = new FormGroup({
    name: new FormControl('', [Validators.required,
    Validators.maxLength(100)]),
    dba: new FormControl('', [Validators.maxLength(100)]),
    status: new FormControl(Status.NEW, [Validators.required]),
    incorporationType: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    marginType: new FormControl('', [Validators.required]),
    acquisitionDate: new FormControl('', [Validators.required]),
    inactivationDate: new FormControl('', [Validators.required]),
    futuresCommissionType: new FormControl(PerTxCommissionType.HALF_TURN, [Validators.required]),
    optionsCommissionType: new FormControl(PerTxCommissionType.HALF_TURN, [Validators.required]),
    managingPod: new FormControl(PodEnum.HOUSE, [Validators.required]),
    emailDomains: new FormControl('', [
      forbiddenDomainValidator(),
      invalidDomainValidator(/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z]\.[a-zA-Z]{2,}$/)]),
    isPersonal: new FormControl(false),
    isAssociatedPerson: new FormControl(false),
    associatedPersonRelationship: new FormControl({value: '', disabled: true}, [Validators.required,
    Validators.maxLength(100)]),
    referralSource: new FormControl('', [Validators.maxLength(100)]),
    discretionToRoll: new FormControl(false),
    discretionToTrade: new FormControl(false),
    discretionToTradeFirstName: new FormControl({value: '', disabled: true}, [Validators.required, Validators.maxLength(100)]),
    discretionToTradeLastName: new FormControl({value: '', disabled: true}, [Validators.required, Validators.maxLength(100)]),
    physicalAddress: new FormGroup({
      street1: new FormControl('', [
        Validators.required,
        Validators.maxLength(200)
      ]),
      street2: new FormControl('', [
        Validators.maxLength(200)
      ]),
      city: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      region: new FormControl('', [
        Validators.required
      ]),
      postalCode: new FormControl('', [
        Validators.required
      ]),
      country: new FormControl('', [
        Validators.required
      ]),
    }),
    mailingAddress: new FormGroup({
      street1: new FormControl('', [
        Validators.required,
        Validators.maxLength(200)
      ]),
      street2: new FormControl('', [
        Validators.maxLength(200)
      ]),
      city: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      region: new FormControl('', [
        Validators.required
      ]),
      postalCode: new FormControl('', [
        Validators.required
      ]),
      country: new FormControl('', [
        Validators.required
      ]),
    }),
    ssn: new FormControl({value: '*********', disabled: true})
  });

  isSameAddress = new FormControl(false);

  client$: Observable<Client>;
  clientDocId: string;
  clientFcms$: Observable<ClientFCM[]>;
  banks$: Observable<Bank[]>;
  accounts$: Observable<Account[]>;
  accounts: Account[];
  errorMessage: string;
  emailDomains: string[] = [];
  phoneNumbers: PhoneNumber[] = [];
  contacts$: Observable<Contact[]>;

  updateComplete = true;
  editMode = false;
  createMode = false;
  ssn$: Observable<{value: string}>;
  canViewSSN = false;
  canEditSSN = false;
  canViewATIOnlyData = false;
  canUpdateClient = false;
  addEmailDomainOnBlur = false;
  chipSeparatorKeyCodes: number[] = [COMMA, ENTER, SEMICOLON, SPACE];

  clientTypes = Object.keys(ClientType);
  incTypes = Object.keys(IncorporationType);
  marginTypes = Object.keys(MarginType);
  podNames = Object.keys(PodEnum);

  private client: Client;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public snackBar: MatSnackBar,
    private accountService: AccountService,
    private bankService: BankService,
    private clientService: ClientService,
    private clientFcmService: ClientFcmService,
    private contactService: ContactService,
    private auth0Service: Auth0AuthzService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe$)).subscribe((params: ParamMap) => {
      this.editMode = !params.has('clientDocId') || params.get('clientDocId') === 'new';
      this.createMode = !params.has('clientDocId') || params.get('clientDocId') === 'new';
      if (params.has('clientDocId')) {
        this.clientForm.disable();
      }
    });

    this.clientForm.get('status').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(newVal => {
      if (newVal === Status.INACTIVE) {
        if (this.editMode) {
          this.clientForm.get('inactivationDate').enable();
        }
        if ((this.clientForm.get('inactivationDate').value) === '') {
          this.clientForm.get('inactivationDate').patchValue(new Date().toISOString());
        }
      } else {
        this.clientForm.get('inactivationDate').patchValue('');
        this.clientForm.get('inactivationDate').disable();
      }
    });

    this.isSameAddress.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(newVal => {
      if (newVal) {
        const mailingAddress = (this.clientForm.get('mailingAddress') as FormGroup).getRawValue();
        this.clientForm.get('physicalAddress').patchValue(mailingAddress);
        this.clientForm.get('physicalAddress').disable();
      } else {
        this.clientForm.get('physicalAddress').enable();
      }
    });

    this.clientForm.get('isAssociatedPerson').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(newVal => {
      if (newVal) {
        this.clientForm.get('associatedPersonRelationship').enable();
      } else {
        this.clientForm.get('associatedPersonRelationship').patchValue('');
        this.clientForm.get('associatedPersonRelationship').disable();
      }
    });

    this.clientForm.get('discretionToTrade').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(newVal => {
      if (newVal) {
        this.clientForm.get('discretionToTradeFirstName').enable();
        this.clientForm.get('discretionToTradeLastName').enable();
      } else {
        this.clientForm.get('discretionToTradeFirstName').patchValue('');
        this.clientForm.get('discretionToTradeLastName').patchValue('');

        this.clientForm.get('discretionToTradeFirstName').disable();
        this.clientForm.get('discretionToTradeLastName').disable();
      }
    });

    this.client$ = this.route.paramMap.pipe(
      filter((params: ParamMap) => params.has('clientDocId') && params.get('clientDocId') !== 'new'),
      switchMap((params: ParamMap) => {
        this.clientDocId = params.get('clientDocId');
        return this.clientService.getClient(params.get('clientDocId')).pipe(
          catchError(err => {
            this.errorMessage = 'This Client either does not exist or you do not have permission to view this information';
            throw new Error(err);
          })
        )
        .pipe(
          tap( (client) => {
            if (!client) {
              this.errorMessage = 'Error retrieving client; this client either does not exist or you do not have permission to view the information';
            }
          })
        );
      }),
      tap( (newClient) => {
        if (newClient) {
          this.handleClientObject(newClient);
        }
      })
    );

    this.clientFcms$ = this.route.paramMap.pipe(
      filter((params: ParamMap) => params.has('clientDocId') && params.get('clientDocId') !== 'new'),
      switchMap((params: ParamMap) => {
        return this.clientFcmService.getAllClientFcmsByClientDocId(params.get('clientDocId'));
      })
    );

    this.banks$ = this.route.paramMap.pipe(
      filter((params: ParamMap) => params.has('clientDocId') && params.get('clientDocId') !== 'new'),
      switchMap((params: ParamMap) => {
        return this.bankService.getAllBanksByClientDocId(params.get('clientDocId'));
      })
    );

    this.contacts$ = this.route.paramMap.pipe(
      filter((params: ParamMap) => params.has('clientDocId') && params.get('clientDocId') !== 'new'),
      switchMap((params: ParamMap) => {
        return this.contactService.getAllContactsByClientDocId(params.get('clientDocId'));
      })
    );

    this.accounts$ = this.route.paramMap.pipe(
      filter((params: ParamMap) => params.has('clientDocId') && params.get('clientDocId') !== 'new'),
      switchMap((params: ParamMap) => {
        return this.accountService.getAllAccountsByClientId(params.get('clientDocId'))
          .pipe(tap(accounts => this.accounts = accounts));
      })
    );

    this.clientForm.get('mailingAddress').valueChanges.pipe(
      filter(_ => this.isSameAddress.value),
      takeUntil(this.unsubscribe$)
    ).subscribe(newVal => this.clientForm.get('physicalAddress').patchValue(newVal));

    this.canViewSSN = this.auth0Service.currentUserHasRole(DECRYPT_ROLE);
    this.canEditSSN = this.auth0Service.currentUserHasRole(ENCRYPT_ROLE);
    this.canViewATIOnlyData = this.auth0Service.currentUserHasRole(ATI_ONLY_DATA_ROLE);
    this.canUpdateClient = this.auth0Service.currentUserHasRole(CLIENT_UPDATE_ROLE);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  revealSSN() {
    this.ssn$ = this.clientService.decrypt(this.client.docId, this.client.encryptedTaxIdKey).pipe(
      catchError(e => {
        this.ssn$ = undefined;
        console.log('caught', e);
        this.openSnackBar(`Access Denied`, 'DISMISS', false);
        return of({value: ''});
      })
    );
  }

  hideSSN() {
    this.ssn$ = undefined;
  }

  enableSSN() {
    this.clientForm.get('ssn').setValue('');
    this.clientForm.get('ssn').enable();
  }

  disableSSN() {
    this.clientForm.get('ssn').setValue('*********');
    this.clientForm.get('ssn').disable();
  }

  handleSSNKeypress(e) {
    // Need to prevent <ENTER> from triggering action
    if (e.which === 13) {
      e.preventDefault();
      e.target.blur();
    }
  }

  onPhoneNumberCreated(phoneNumber: PhoneNumber) {
    this.phoneNumbers.push(phoneNumber);
    this.clientForm.markAsDirty();
  }

  onPhoneNumberRemoved(index?: number) {
    if (this.phoneNumbers.length > 1) {
      this.phoneNumbers.splice(index, 1);
      this.clientForm.markAsDirty();
    } else {
      this.openSnackBar(`Client must contain at least one phone number`, 'DISMISS', false);
    }
  }

  getIncorporationTypeDisplayText(type: IncorporationType) {
    switch (type) {
      case IncorporationType.CORPORATION:
        return 'Corporation';
      case IncorporationType.DBA:
        return 'DBA';
      case IncorporationType.GENERAL_PARTNERSHIP:
        return 'General Partnership';
      case IncorporationType.INDIVIDUAL:
        return 'Individual';
      case IncorporationType.JOINT:
        return 'Joint';
      case IncorporationType.LIMITED_PARTNERSHIP:
        return 'Limited Partnership';
      case IncorporationType.LLC:
        return 'LLC';
      case IncorporationType.LLP:
        return 'LLP';
      case IncorporationType.SOLE_PROPRIETORSHIP:
        return 'Sole Proprietorship';
      case IncorporationType.TRUST:
        return 'Trust';
      case IncorporationType.UNKNOWN:
        return 'Unknown';
      default:
        return '-';
    }
  }

  getCommissionDisplayText(commission: PerTxCommissionType) {
    switch (commission) {
      case PerTxCommissionType.HALF_TURN:
        return 'Half Turn';
      case PerTxCommissionType.ROUND_TURN:
        return 'Round Turn';
      default:
        return '-';
    }
  }

  getManagingPodDisplayText(pod: PodEnum) {
    switch (pod) {
      case PodEnum.HOUSE:
        return 'House';
      case PodEnum.POD_1:
        return 'Pod 1';
      case PodEnum.POD_2:
        return 'Pod 2';
      case PodEnum.POD_3:
        return 'Pod 3';
      case PodEnum.POD_4:
        return 'Pod 4';
      case PodEnum.POD_5:
        return 'Pod 5';
      case PodEnum.POD_6:
        return 'Pod 6';
      default:
        return '-';
    }
  }

  async saveForm() {
    if (this.phoneNumbers.length === 0) {
      this.openSnackBar(`Client must contain at least one phone number`, 'DISMISS', false);
      return;
    }
    this.updateComplete = false;
    const isNewClient = !this.client;
    const serviceFunc = (isNewClient ? this.clientService.createClient : this.clientService.updateClient).bind(this.clientService);

    const client = Object.assign(isNewClient ? new Client() : {}, this.client, this.clientForm.getRawValue());
    delete client.ssn;
    client.emailDomains = this.emailDomains;
    client.phoneNumbers = this.phoneNumbers;

    const accountsToUpdate = [];
    if (!isNewClient && !client.discretionToTrade) {
      for (const account of this.accounts) {
        if (account.isDiscretionToTradeOverridden) {
          account.isDiscretionToTradeOverridden = false;
          accountsToUpdate.push(account);
        }
      }
    }

    if (this.clientForm.get('ssn').enabled) {
      try {
        await this.clientService.encrypt(client.docId, this.client.encryptedTaxIdKey, this.clientForm.get('ssn').value);
      } catch (e) {
        this.updateComplete = true;
        this.openSnackBar(`Error: Could not save SSN`, 'DISMISS', false);
        return;
      }
    }

    serviceFunc(client)
      .then(() => {
        this.client = client;
        this.updateComplete = true;
        this.openSnackBar('Client Saved', 'DISMISS', true);

        if (isNewClient) {
          this.router.navigate(['../', this.client.docId], {relativeTo: this.route, replaceUrl: true});
        } else {
          this.setEditMode(false);
        }
      })
      .then(() => {
        const promise = Promise.all(
          accountsToUpdate.map(account => {
            return this.accountService.updateAccount(account);
          })
        );
        return promise;
      })
      .catch(e => {
        this.updateComplete = true;
        console.log('Could not save client', e);
        this.openSnackBar('Error Saving', 'DISMISS', false);
      });

      this.clientForm.markAsPristine();
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('invalidDomainName')) {
      return 'Invalid domain name';
    } else if (control.hasError('forbiddenDomainName')) {
      return 'Forbidden domain name';
    } else if (control.hasError('maxlength')) {
      return `Value cannot exceed ${control.getError('maxlength')['requiredLength']} characters`;
    }
    return 'Unknown error';
  }

  setEditMode(mode) {
    this.editMode = mode;
    if (this.editMode) {
      this.clientForm.get('physicalAddress.country').markAsPristine();
      this.clientForm.get('mailingAddress.country').markAsPristine();
      this.clientForm.enable();
      this.ssn$ = undefined;
      this.clientForm.get('ssn').disable();

      const addressFields = Object.keys((this.clientForm.get('physicalAddress') as FormGroup).controls);
      const isSameAddress = addressFields.every(key =>
        this.clientForm.get(`physicalAddress.${key}`).value === this.clientForm.get(`mailingAddress.${key}`).value
      );
      this.isSameAddress.setValue(isSameAddress);

      if (!this.client.discretionToTrade) {
        this.clientForm.get('discretionToTradeFirstName').disable();
        this.clientForm.get('discretionToTradeLastName').disable();
      }

      if (!this.client.isAssociatedPerson) {
        this.clientForm.get('associatedPersonRelationship').disable();
      }
      if (this.client.status !== Status.INACTIVE) {
        this.clientForm.get('inactivationDate').disable();
      }

    } else { // Abandon an update
      this.clientForm.disable();
      this.handleClientObject(this.client);
      this.clientForm.markAsPristine();
    }
  }

  reset() {
    this.clientForm.reset();
    this.phoneNumbers = [];
    this.emailDomains = [];
  }

  addEmailDomain(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value.trim().toLowerCase();

    if (value && !this.emailDomains.includes(value)) {
      this.emailDomains.push(value);
    }

    if (input) {
      input.value = '';
    }
  }

  removeEmailDomain(index: number) {
    this.emailDomains.splice(index, 1);
    this.clientForm.get('emailDomains').setValue('');
    this.clientForm.markAsDirty();
  }

  setupClientFcm() {
    this.router.navigate(['fcmsetup', 'new'], {relativeTo: this.route});
  }

  navigateClientFcm(docId: string) {
    this.router.navigate(['fcmsetup', docId], {relativeTo: this.route});
  }

  setupBank() {
    this.router.navigate(['banks', 'new'], {relativeTo: this.route});
  }

  selectAccountIcon() {
    this.router.navigate(['/accounts'], { queryParams: { client: this.client.docId, clientName: this.client.name } });
  }

  navigateBank(docId: string) {
    this.router.navigate(['banks', docId], {relativeTo: this.route});
  }

  setupAccount() {
    this.router.navigate(['accounts', 'new'], {queryParams: {clientDocId: this.client.docId}});
  }

  navigateContacts() {
    this.router.navigate(['contacts'], {relativeTo: this.route});
  }

  private handleClientObject(newClient) {
    newClient.isAssociatedPerson = newClient.isAssociatedPerson || false;
    newClient.isPersonal = newClient.isPersonal || false;
    newClient.discretionToRoll = newClient.discretionToRoll || false;
    newClient.discretionToTrade = newClient.discretionToTrade || false;
    this.client = newClient;
    this.emailDomains = Array.from(newClient.emailDomains || []);
    this.phoneNumbers = Array.from(newClient.phoneNumbers || []);
    this.clientForm.patchValue(newClient);
    this.clientForm.get('emailDomains').setValue('');
    this.clientForm.get('ssn').setValue('*********');
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

}
