import { FormControl } from '@angular/forms';

export class ClientSearchValidator {

  static ClientSearchValidator(formControl: FormControl): {[key: string]: boolean} | null {
    const client = formControl.value;

    if ( typeof client === 'string' && client.length > 0) {
      return { invalidClient : true };
    }

  }
}
