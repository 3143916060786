<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <h6 *ngIf="filteredBy" class="card-brow">{{ filteredBy | uppercase }}</h6>
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Cash Activity List</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">Cash Activity</h2>
    </div>
    <div *ngIf="!errorMessage" fxLayout="column">
    <form [formGroup]="cashActivityForm" (ngSubmit)="searchCashActivity(true)">
      <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100" fxLayoutGap="25px">
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="45" (click)="accountFieldClicked()">
            <input matInput placeholder="Accounts" formControlName="accounts" upperCase>
            <mat-error *ngIf="cashActivityForm.get('accounts').invalid">{{formValidator.getErrorMessages(cashActivityForm.get('accounts'))}}</mat-error>
          </mat-form-field>
        <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="45" (click)="clientFieldClicked()">
          <input matInput placeholder="Broker Codes" formControlName="brokerCodes">
          <mat-error *ngIf="cashActivityForm.get('brokerCodes').invalid">{{formValidator.getErrorMessages(cashActivityForm.get('brokerCodes'))}}</mat-error>
        </mat-form-field>
      </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <div fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center">
            <mat-form-field fxFlex="45">
              <input matInput placeholder="Amount (Min)" formControlName="minAmount">
              <mat-error *ngIf="cashActivityForm.get('minAmount').invalid">{{formValidator.getErrorMessages(cashActivityForm.get('minAmount'))}}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <input matInput placeholder="Amount (Max)" formControlName="maxAmount">
              <mat-error *ngIf="cashActivityForm.get('maxAmount').invalid">{{formValidator.getErrorMessages(cashActivityForm.get('maxAmount'))}}</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center">
            <mat-form-field fxFlex="45">
              <input matInput [matDatepicker]="startDatePicker" placeholder="Business Date (Start)" formControlName="startBusinessDate" [max]="cashActivityForm.get('endBusinessDate').value">
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
              <mat-error *ngIf="cashActivityForm.get('startBusinessDate').invalid">{{formValidator.getErrorMessages(cashActivityForm.get('startBusinessDate'))}}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <input matInput [matDatepicker]="endDatePicker" placeholder="Business Date (End)" formControlName="endBusinessDate" [min]="cashActivityForm.get('startBusinessDate').value">
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
              <mat-error *ngIf="cashActivityForm.get('endBusinessDate').invalid">{{formValidator.getErrorMessages(cashActivityForm.get('endBusinessDate'))}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <button id="submit-cashActivity-search" aria-label="Search" mat-raised-button [disabled]="!cashActivityForm.valid" color="accent" type="submit">Search</button>
          <button id="clear-cashActivity-search" *ngIf="!cashActivityForm.pristine" aria-label="Reset" (click)="reset()" mat-stroked-button color="accent">Reset</button>
        </div>
      </div>
    </form>
    <div *ngIf="isSearching" fxLayout="row" fxLayoutGap="10px">
      <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
    </div>
    <div *ngIf="showData && !errorMessage" class="cashActivity-list">
      <atom-cash-activity (cashActivityListChange)="handlecashActivityListChange($event)" (cashActivitySearchError)="handlecashActivityListError($event)" (isSearching)="handleIsSearching($event)" [selectedCashActivity$]="selectedCashActivity$" [initialTableState]="tableState"></atom-cash-activity>
    </div>
    </div>
  </mat-card>
</div>
