<div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="10">
  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
      <h6 *ngIf="filteredBy" class="card-brow">{{ filteredBy | uppercase }}</h6>
      <div *ngIf="errorMessage">
        <h2 class="card-title">Error Performing Orders Search</h2>
        <p [innerHTML]="errorMessage"></p>
        <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="card-title">Orders</h2>
      </div>
      <mat-tab-group
      [selectedIndex]="selectedTab"
      (selectedIndexChange)="tabChanged($event)">
        <mat-tab label="Open" fxFlex="100">
          <atom-order-search-tab
          searchFields="[]"
          [selectedTab]="selectedTab"
          tabIndex="0"
          [tabStatuses]="openStatuses"
          (outputFilteredBy)="receiveFilteredBy($event)"></atom-order-search-tab>
        </mat-tab>
        <mat-tab label="Filled" fxFlex="100">
          <atom-order-search-tab
          searchFields="[date]"
          [selectedTab]="selectedTab"
          tabIndex="1"
          [tabStatuses]="filledStatuses"
          (outputFilteredBy)="receiveFilteredBy($event)"></atom-order-search-tab>
        </mat-tab>
        <mat-tab label="All" fxFlex="100">
          <atom-order-search-tab
          searchFields="[date, status]"
          [selectedTab]="selectedTab"
          tabIndex="2"
          (outputFilteredBy)="receiveFilteredBy($event)"></atom-order-search-tab>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </div>

</div>
