<div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="10">
  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
      <h6 *ngIf="filteredBy" class="card-brow">{{ filteredBy | uppercase }}</h6>
      <div *ngIf="errorMessage">
        <h2 class="card-title">Error Performing Margins Search</h2>
        <p [innerHTML]="errorMessage"></p>
        <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021"></a></p>
      </div>

      <form *ngIf="!errorMessage" [formGroup]="marginSearchForm" (ngSubmit)="searchMargins(true)" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h2 class="card-title">Margins</h2>
        </div>

        <!-- Account, Broker Code, Business Start and End Dates -->
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="100" (click)="accountFieldClicked()">
            <mat-label>Accounts</mat-label>
            <input matInput formControlName="accounts" trim="blur" upperCase>
            <mat-error *ngIf="marginSearchForm.get('accounts').invalid">{{ formValidator.getErrorMessages(marginSearchForm.get('accounts')) }}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="100" (click)="brokerCodeFieldClicked()">
            <mat-label>Broker Codes</mat-label>
            <input matInput formControlName="brokerCodes" trim="blur">
            <mat-error *ngIf="marginSearchForm.get('brokerCodes').invalid">{{ formValidator.getErrorMessages(marginSearchForm.get('brokerCodes')) }}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="100">
            <input matInput [matDatepicker]="startDatePicker" placeholder="Business Date (Start)" formControlName="startDate" [max]="marginSearchForm.get('endDate').value">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="marginSearchForm.get('startDate').invalid">{{ getErrorMessage(marginSearchForm.get('startDate')) }}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="100">
            <input matInput [matDatepicker]="endDatePicker" placeholder="Business Date (End)" formControlName="endDate" [min]="marginSearchForm.get('startDate').value">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
            <mat-error *ngIf="marginSearchForm.get('endDate').invalid">{{ getErrorMessage(marginSearchForm.get('endDate')) }}</mat-error>
          </mat-form-field>
        </div>

        <span class="check-radio-label">Options</span>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <mat-radio-group formControlName="option" fxFlex.gt-xs="45" fxFlex.xs="100" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center">
            <mat-radio-button *ngFor="let option of optionStatuses" [value]="option" labelPosition="after">
              {{ option }}
            </mat-radio-button>
            <button id="clear-option" mat-button *ngIf="marginSearchForm.get('option').value" matSuffix mat-icon-button aria-label="Clear" (click)="clearOption()" matTooltip="Clear Option">
              <mat-icon>close</mat-icon>
            </button>
          </mat-radio-group>
        </div>

        <div *ngIf="!isSearching" fxLayout="row" fxLayoutGap="10px">
          <button id="submit-order-search" [disabled]="!marginSearchForm.valid" aria-label="Search" type="submit"
            mat-raised-button color="accent">Search</button>
          <button id="clear-order-search" *ngIf="!marginSearchForm.pristine" aria-label="Reset" (click)="reset()"
            mat-stroked-button color="accent">Reset</button>
        </div>
        <div *ngIf="isSearching" fxLayout="row" fxLayoutGap="10px">
          <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
        </div>
      </form>

      <!-- Display Margins list screen below MarginSearch -->
      <div *ngIf="showMargins && !errorMessage" class="margin-list">
        <atom-margins (marginListChange)="handleMarginListChange($event)" (marginSearchError)="handleMarginListError($event)" (isSearching)="handleIsSearching($event)"
          [selectedMargins$]="selectedMargins$" [initialTableState]="tableState"></atom-margins>
      </div>
    </mat-card>
  </div>
</div>

