<div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="10">
  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
      <h6 *ngIf="filteredBy" class="card-brow">{{ filteredBy | uppercase }}</h6>

      <div *ngIf="errorMessage">
        <h2 class="card-title">Error Performing Broker Relationships Search</h2>
        <p [innerHTML]="errorMessage"></p>
        <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
      </div>

        <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
          <h2 class="card-title">Broker Relationships</h2>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <button id="add-new-broker-relationship" *ngIf="accountAdmin" aria-label="Add New Broker Relationship" (click)="addNewBrokerRel()"
              mat-mini-fab color="accent" matTooltip="Add New Broker Relationship">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>

        <form *ngIf="!errorMessage" [formGroup]="brokerRelSearchForm" (ngSubmit)="searchBrokerRels(true)" fxLayout="column">
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="70" (click)="brokerCodeFieldClicked()">
            <mat-label>Search Broker Code</mat-label>
            <input matInput formControlName="brokerCode" trim="blur" maxLength="6" upperCase mask="A*">
            <mat-error *ngIf="brokerRelSearchForm.get('brokerCode').invalid" >{{ getErrorMessage(brokerRelSearchForm.get('brokerCode')) }}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="70" (click)="brokerFieldClicked()">
            <input matInput type="text" placeholder="Search Broker Name" formControlName="broker" [matAutocomplete]="autoBroker">
            <mat-error *ngIf="brokerRelSearchForm.get('broker').invalid">You must select a valid broker</mat-error>
          </mat-form-field>
          <mat-autocomplete #autoBroker="matAutocomplete" [displayWith]="displayBroker">
            <mat-option *ngFor="let broker of filteredBrokers$ | async" [value]="broker">{{ displayBroker(broker) }}
            </mat-option>
          </mat-autocomplete>
          <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="70">
            <mat-label>Managing Pod</mat-label>
            <mat-select formControlName="managingPod" [disabledOptionCentering]="true">
              <mat-option value="ALL">All</mat-option>
              <mat-option *ngFor="let pod of podNames" [value]="pod">
                {{ pod | replace | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="!isSearching" fxLayout="row" fxLayoutGap="10px">
          <button id="submit-broker-relationship-search" [disabled]="brokerRelSearchForm.invalid" aria-label="Search" type="submit"
            mat-raised-button color="accent">Search</button>
          <button id="clear-broker-relationship-search" *ngIf="!brokerRelSearchForm.pristine"  aria-label="Reset" (click)="reset()"
            mat-stroked-button color="accent">Reset</button>
        </div>

        <div *ngIf="isSearching" fxLayout="row" fxLayoutGap="10px">
          <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
        </div>

      </form>

      <div *ngIf="showBrokerRels && !errorMessage" class="broker-rel-list">
        <atom-broker-relationships (brokerRelListChange)="handleBrokerRelListChange($event)" (brokerRelSearchError)="handleBrokerRelError($event)" (isSearching)="handleIsSearching($event)"
          [selectedBrokerRels$]="selectedBrokerRels$" [initialTableState]="tableState"></atom-broker-relationships>
      </div>

    </mat-card>
  </div>
</div>
