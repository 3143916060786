<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Adjustment Details</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div [hidden]="errorMessage">
      <div *ngIf="adjustment$ | async">
        <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
          <h2 class="card-title">Adjustment: {{ adjustment.securityDescription }}</h2>
          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
            <button id="edit-adjustment" aria-label="Edit Adjustment" *ngIf="!editMode && updateComplete" (click)="setEditMode(true)" mat-mini-fab color="accent" matTooltip="Edit Adjustment">
              <mat-icon>edit</mat-icon>
            </button>
            <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
            <button id="update-adjustment" aria-label="Update Adjustment" *ngIf="editMode && updateComplete" (click)="submit()" mat-mini-fab color="accent" matTooltip="Update Adjustment">
              <mat-icon>done</mat-icon>
            </button>
            <button id="cancel-edit-adjustment" aria-label="Discard Changes" *ngIf="updateComplete && editMode" (click)="reset()" mat-mini-fab color="" matTooltip="Discard Changes">
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="15px">
          <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
            <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Business Date" [data]="adjustment.transactionDate"></ati-labeled-data>
            <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Trade Date" [data]="adjustment.businessDate"></ati-labeled-data>
            <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Broker Code" [data]="adjustment.officeCode + adjustment.salesCode"></ati-labeled-data>
            <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Account" [data]="adjustment.accountNumber"></ati-labeled-data>
          </div>

          <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
            <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Commission" [data]=" adjustment.commission ? (adjustment.commission | number : '1.2') : '-'"></ati-labeled-data>
            <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Amount" [data]="adjustment.amount ? (adjustment.amount | number : '1.2') : '-'"></ati-labeled-data>
            <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Clearing Fee" [data]="adjustment.clearingFee ? (adjustment.clearingFee | number : '1.2') : '-'"></ati-labeled-data>
            <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Exchange Fee" [data]="adjustment.exchangeFee ? (adjustment.exchangeFee | number : '1.2') : '-'"></ati-labeled-data>
            <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="NFA Fee" [data]="adjustment.nfaFee ? (adjustment.nfaFee | number : '1.2') : '-'"></ati-labeled-data>
            <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Other Charges" [data]="adjustment.otherCharges ? (adjustment.otherCharges | number : '1.2') : '-'"></ati-labeled-data>
          </div>
        </div>
      </div>

      <div fxLayoutAlign="center center" fxLayout="column" *ngIf="assignedTradesLoading && unassignedTradesLoading">
        <mat-spinner diameter="50" color="accent"></mat-spinner>
        <p>Loading Trades...</p>
      </div>
      <div [hidden]="assignedTradesLoading && unassignedTradesLoading">
        <h3>Assigned Trades</h3>
        <mat-table [dataSource]="assignedDataSource" class="mat-elevation-z0">
          <ng-container matColumnDef="assignment">
            <mat-header-cell *matHeaderCellDef fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="7">Assigned</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="7">
              <div (click)="$event.stopPropagation()">
                <mat-checkbox (change)="unassignTrade($event, trade)" [checked]="trade.linkedCashAdjustmentDocId || trade.linkedCommissionAdjustmentDocId"></mat-checkbox>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="accountNumber">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="5">Account</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="5">{{ trade.accountNumber }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="transactionDate">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="8">Business Date </mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="8">{{ trade.transactionDate | date: 'shortDate' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="tradeDate">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="6">Trade Date </mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="6">{{ trade.tradeDate | date: 'shortDate' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="contractYearMonth">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="25" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="7">Month/Year</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="25" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="7">{{ trade.contractYearMonth | codeToMonth: 'long' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="side">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="25" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="3">Side</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="25" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="3">{{ trade.side | titlecase }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="commission">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="30" fxFlex.md="10" fxFlex.gt-xs="7.5">Commission</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.md="10" fxFlex.gt-xs="7.5">{{ trade.commission | number : '1.2' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="securitySubType">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="30" fxFlex.md="10" fxFlex.gt-xs="5">Put/Call</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.md="10" fxFlex.gt-xs="5">{{ trade.securitySubType ? (trade.securitySubType | titlecase) : '' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="clearingFee">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="30" fxFlex.gt-xs="6">Clearing Fee </mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.gt-xs="6">{{ trade.clearingFee | number : '1.2' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="nfaFee">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="30" fxFlex.gt-xs="5">NFA Fee </mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.gt-xs="5">{{ trade.nfaFee | number : '1.2' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="exchangeFee">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="30" fxFlex.gt-xs="6">Exchange Fee</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.gt-xs="6">{{ trade.exchangeFee | number : '1.2' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="brokerCode">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="30" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="6.5">Broker Code</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="6.5">{{ trade.officeCode + trade.salesCode }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="quantity">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="25" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="3">Qty</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="25" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="3">{{ trade.quantity | number : '1.0-2'}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="commodityId">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="33" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="6.5">Commodity</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="33" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="6.5">{{ getCommodityDisplayName(trade.commodityId) }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="strikePrice">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="4">Strike</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="4">{{ trade.strikePrice != 0 ? (trade.strikePrice | number : '1.2-5') : '' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="transactionRecordType">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="8.5">Type</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="8.5">{{ trade.transactionRecordType }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="spreadCode">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="6">Outright/<br>Spread</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="6">{{ trade.spreadCode == 'S' ? 'Spread' : 'Outright' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef  fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="6">Fill</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="6">{{ trade.price | number : '1.2-5' }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
          <mat-row *matRowDef="let trade; columns: columnsToDisplay" (click)="!editMode && selectTrade(trade)"></mat-row>
        </mat-table>
        <mat-paginator #assignedPaginator color="primary" [pageSizeOptions]="[5]"></mat-paginator>

        <h3>Unassigned Trades</h3>
        <mat-table [dataSource]="unassignedDataSource" matSort matSortActive="transactionDate" matSortDirection="desc" class="mat-elevation-z0">

          <ng-container matColumnDef="assignment">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="7">Assigned</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="7">
              <div (click)="$event.stopPropagation()">
                <mat-checkbox [checked]="adjustment.commission === 0 ? trade.linkedCashAdjustmentDocId : trade.linkedCommissionAdjustmentDocId" (change)="assignTrade($event, trade)"></mat-checkbox>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="accountNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="5">Account</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="5">{{ trade.accountNumber }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="transactionDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="8">Business Date</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="8">{{ trade.transactionDate | date: 'shortDate' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="tradeDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="6">Trade Date</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="33" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="6">{{ trade.tradeDate | date: 'shortDate' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="contractYearMonth">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="7">Month/Year</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="25" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="7">{{ trade.contractYearMonth | codeToMonth: 'long' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="side">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="3">Side</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="25" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="3">{{ trade.side | titlecase }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="commission">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.md="10" fxFlex.gt-xs="7.5">Commission</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.md="10" fxFlex.gt-xs="7.5">{{ trade.commission | number : '1.2' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="securitySubType">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.md="10" fxFlex.gt-xs="5">Put/Call</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.md="10" fxFlex.gt-xs="5">{{ trade.securitySubType ? (trade.securitySubType | titlecase) : '' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="clearingFee">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="6">Clearing Fee </mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.gt-xs="6">{{ trade.clearingFee | number : '1.2' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="nfaFee">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="5">NFA Fee </mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.gt-xs="5">{{ trade.nfaFee | number : '1.2' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="exchangeFee">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="6">Exchange Fee</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.gt-xs="6">{{ trade.exchangeFee | number : '1.2' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="brokerCode">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="6.5">Broker Code</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="15" fxFlex.md="10" fxFlex.gt-xs="6.5">{{ trade.officeCode + trade.salesCode }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="quantity">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="3">Qty</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="25" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="3">{{ trade.quantity | number : '1.0-2'}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="commodityId">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="33" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="6.5">Commodity</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="33" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="6.5">{{ getCommodityDisplayName(trade.commodityId) }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="strikePrice">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="4">Strike</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="4">{{ trade.strikePrice != 0 ? (trade.strikePrice | number : '1.2-5') : '' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="transactionRecordType">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="8.5">Type</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="8.5">{{ trade.transactionRecordType }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="spreadCode">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="6">Outright/<br>Spread</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="6">{{ trade.spreadCode == 'S' ? 'Spread' : 'Outright' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="6">Fill</mat-header-cell>
            <mat-cell *matCellDef="let trade" fxFlex.xs="30" fxFlex.sm="10" fxFlex.md="10" fxFlex.gt-xs="6">{{ trade.price | number : '1.2-5' }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
          <mat-row *matRowDef="let trade; columns: columnsToDisplay" (click)="!editMode && selectTrade(trade)"></mat-row>
        </mat-table>
        <mat-paginator #unassignedPaginator color="primary" [pageSizeOptions]="[10, 20, 50, 100]" (page)="handlePageChange()"></mat-paginator>
      </div>
    </div>
  </mat-card>
</div>
