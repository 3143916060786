import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { User, Broker } from '@advance-trading/ops-data-lib';
import { UserService } from '@advance-trading/angular-ops-data';
import { BrokerService } from 'src/app/services/broker-service';
import { AuthService, Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { map, switchMap, shareReplay, debounceTime, startWith, take } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';

@Component({
  selector: 'atom-pay-code-split',
  templateUrl: './pay-code-split.component.html',
  styleUrls: ['./pay-code-split.component.css']
})
export class PayCodeSplitComponent implements OnInit, OnDestroy {

  // Commented out sections are for the payCodeId autocomplete that will be implemented after payCodes have been entered into firestore.

  // filteredPayCodes: string[] = [];
  filteredBrokers$: Observable<User[]>;
  ACCOUNTING_ADMIN_ROLE = 'AccountingAdmin';

  // private brokerSubscription: Subscription;
  private activeBrokerSubscription: Subscription;
  private activeBrokers$: Observable<User[]>;

  @Input() payCodeSplitForm: FormGroup;
  @Input() payCodeSplitForms: FormArray;
  @Input() editMode: boolean;
  @Input() createMode: boolean;
  @Input() index: number;
  @Input() broker: Broker;

  constructor(
    private userService: UserService,
    private brokerService: BrokerService,
    private authService: AuthService,
    private authzService: Auth0AuthzService
  ) { }

  ngOnInit() {

    if (this.userHasAccounting()) {
      this.getActiveBrokers();
    }
    if (this.activeBrokers$) {
      this.filteredBrokers$ = this.filterBrokers(this.payCodeSplitForm.get('brokerUser'));
      this.refreshAvailableBrokers();
    }

    // if (this.payCodeSplitForm.get('brokerUser').value) {
    //   this.brokerSubscription = this.brokerService.getBrokerByDocId(this.payCodeSplitForm.get('brokerUser').value.docId)
    //     .pipe(take(1)).subscribe(broker => {
    //     this.filteredPayCodes = broker.payCodes.map(payCode => payCode.id);
    //   });
    // } else {
    //   this.payCodeSplitForm.get('payCodeId').disable();
    // }
  }

  ngOnDestroy() {
    if (this.activeBrokerSubscription) {
      this.activeBrokerSubscription.unsubscribe();
    }
    // if (this.brokerSubscription) {
    //   this.brokerSubscription.unsubscribe();
    // }
  }

  // Does the logged in user have the accounting Admin role
  userHasAccounting(): boolean {
    return this.authzService.currentUserHasRole(this.ACCOUNTING_ADMIN_ROLE);
  }

  displayBroker(broker?) {
    if (broker) {
      return broker.firstName + ' ' + broker.lastName;
    } else {
      return '';
    }
  }

  /**
   * Called on blur of any brokerUser FormControl.
   * Simply triggers valueChanges for each brokerUser control in order to properly update
   * the filtered list of available brokers.
   * ensures value entered is an user object
   */
  refreshAvailableBrokers() {
    this.payCodeSplitForms.controls.forEach(element => {
      const value = element.get('brokerUser').value;
      if (value.docId) {
        element.get('brokerUser').setValue(value);
      } else {
        element.get('brokerUser').setValue('');
      }

    });
    // if (!this.payCodeSplitForm.get('brokerUser').value) {
    //   this.payCodeSplitForm.get('payCodeId').setValue('');
    //   this.payCodeSplitForm.get('payCodeId').disable();
    // }
  }

  onBrokerSelected(broker) {
    // this.filteredPayCodes = broker.payCodes.flat();
    // this.payCodeSplitForm.get('payCodeId').enable();
  }

  resetBroker() {
    this.payCodeSplitForm.get('brokerUser').setValue('');
    // this.filteredPayCodes = [];
  }

  removePayCodeSplit() {
    this.payCodeSplitForms.removeAt(this.index);
    this.payCodeSplitForms.markAsDirty();
  }

  // Gets active brokers from Firestore
  private getActiveBrokers() {
    this.activeBrokers$ = this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId)
      .pipe(
        switchMap((loggedInUser: User) => {
          return this.userService.getBrokerUsersByClientDocId(loggedInUser.clientDocId);
        }),
        map(brokers => {
          return brokers.map(broker => {
            const payCodes = [];

            this.activeBrokerSubscription = this.brokerService.getBrokerByDocId(broker.docId).pipe(take(1)).subscribe(val => {
              if (val.payCodes) {
                payCodes.push(val.payCodes.map(payCode => payCode.id));
              }
            });
            // @ts-ignore
            return {
              ...broker,
              payCodes
            } as User;
          });
        }),
        shareReplay(1)
      );
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting brokers
  private filterBrokers(payCodeSplitForm: AbstractControl) {
    return payCodeSplitForm.valueChanges
      .pipe(
        debounceTime(300),
        startWith<string | User>(''),
        switchMap(term => typeof term === 'string' ? this.filterBrokersBySearchTerm(term) : of([term]))
      );
  }

  // Filters possible brokers using the user input search term
  private filterBrokersBySearchTerm(searchTerm: string): Observable<User[]> {
    return this.activeBrokers$
      .pipe(
        map(brokers => brokers.filter(
          // filter out brokers already selected
          broker => this.payCodeSplitForms.controls.find(control =>
            control.get('brokerUser').value.docId === broker.docId) ? false : true)),
        map(brokers => brokers.filter(broker => this.matchName(searchTerm, broker)))
      );
  }

  // Checks for a partial match on user first or last name
  private matchName(searchTerm: string, user: User) {
    const searchValue = searchTerm.toLowerCase();
    const firstName = user.firstName.toLowerCase();
    const lastName = user.lastName.toLowerCase();

    const fullName = `${firstName} ${lastName}`;
    const lastFirst = `${lastName} ${firstName}`;
    const lastCommaFirst = `${lastName}, ${firstName}`;
    return fullName.includes(searchValue) || lastFirst.includes(searchValue) || lastCommaFirst.includes(searchValue);
  }

}
