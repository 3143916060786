<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Contacts List</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="column">
      <h5>{{ (client$ | async)?.name }}</h5>
      <div fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">Contacts</h2>
      <button id="add-new-contact" *ngIf="accountAdmin" aria-label="Add New Contact" (click)="addNewContact()" mat-mini-fab color="accent" matTooltip="Add New Contact">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div *ngIf="isLoading" fxFlex="100" fxLayoutAlign="space-around center">
      <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
    </div>

    <button *ngIf="exportable" aria-label="Export" id="export-contacts" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
    <mat-menu #exportMenu="matMenu">
      <button mat-menu-item (click)="exportService.exportXlsx(getXlsxExportItems(), 'Contacts')">Excel</button>
      <button mat-menu-item (click)="exportService.exportCsv(getExportableItems(), 'Contacts')">CSV</button>
      <button mat-menu-item (click)="exportService.exportJson(getExportableItems(), 'Contacts')">JSON</button>
    </mat-menu>

    <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort class="mat-elevation-z0" (matSortChange)="handleSortChange()">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="50" fxFlex.sm="17" fxFlex.gt-sm="15">Name</mat-header-cell>
        <mat-cell *matCellDef="let contact" fxFlex.xs="50" fxFlex.sm="17" fxFlex.gt-sm="15">{{ contact.firstName + " " + contact.lastName }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="35" fxFlex.sm="10" fxFlex.gt-sm="10">Title</mat-header-cell>
        <mat-cell *matCellDef="let contact" fxFlex.xs="35" fxFlex.sm="10" fxFlex.gt-sm="10">{{ contact.title }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="isAuthorizedTrader">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.sm="8" fxFlex.gt-sm="8">Auth Trader</mat-header-cell>
        <mat-cell *matCellDef="let contact" fxFlex.sm="8" fxFlex.gt-sm="8"><mat-icon *ngIf="contact.isAuthorizedTrader">check</mat-icon></mat-cell>
      </ng-container>

      <ng-container matColumnDef="isAuthorizedSigner">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.sm="8" fxFlex.gt-sm="8">Auth Signer</mat-header-cell>
        <mat-cell *matCellDef="let contact" fxFlex.sm="8" fxFlex.gt-sm="8"><mat-icon *ngIf="contact.isAuthorizedSigner">check</mat-icon></mat-cell>
      </ng-container>

      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.sm="32" fxFlex.gt-sm="20">Address</mat-header-cell>
        <mat-cell *matCellDef="let contact" fxFlex.sm="32" fxFlex.gt-sm="20">{{ displayAddress(contact) }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.sm="15" fxFlex.gt-sm="10">Phone</mat-header-cell>
        <mat-cell *matCellDef="let contact" fxFlex.sm="15" fxFlex.gt-sm="10">{{ contact.phoneNumbers && contact.phoneNumbers.length? contact.phoneNumbers[0].number : '' }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-sm="20">Email</mat-header-cell>
        <mat-cell *matCellDef="let contact" fxFlex.gt-sm="20">{{ contact.email }}</mat-cell>
      </ng-container>

      <ng-container *ngIf="accountAdmin" matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="15" fxFlex.gt-xs="10"></mat-header-cell>
        <mat-cell *matCellDef="let contact" fxFlex.xs="15" fxFlex.gt-xs="10">
          <div (click)="$event.stopPropagation()">
            <mat-icon color="warn" (click)="deleteContactMessage(contact)" matTooltip="Delete Contact" id="contactDeleteIcon">delete</mat-icon>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row (click)="selectContact(contact)" *matRowDef="let contact; columns: columnsToDisplay"></mat-row>
    </mat-table>
    <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50, 100]" (page)="handlePageChange()"></mat-paginator>
  </div>
  </mat-card>
</div>
