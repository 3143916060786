export class DefaultDatePicker {

  /**
   * retrieves the date as an ISOString of the previous business day excluding weekends
   */
  static getLastBusinessDay() {
    const date = new Date();
    // if the day of the week is sunday, go to last friday
    if (date.getDay() === 0) {
      date.setDate(date.getDate() - 2);
      // if the day of the week is monday, go to last friday
    } else if (date.getDay() === 1) {
      date.setDate(date.getDate() - 3);
      // any other day of the week go to yesterday
    } else {
      date.setDate(date.getDate() - 1);
    }
    date.setHours(0, 0, 0, 0);
    return date.toISOString();
  }
}
