<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Cash Activity Details</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>

    <div *ngIf="!errorMessage && cashActivity$ | async as cashActivity">
      <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="card-title">Cash Activity: {{  cashActivity.securityDescription }}</h2>
        <mat-spinner class="loading-spinner" *ngIf="isLoading" diameter="40" color="accent"></mat-spinner>
      </div>

      <div fxLayout="column" fxLayoutGap="15px">
        <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="50" label="Client Name" [data]="cashActivity.clientName">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="50" label="Account Number" [data]="cashActivity.accountNumber">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="50" label="Broker Code" [data]="cashActivity.officeCode + cashActivity.salesCode">
          </ati-labeled-data>
        </div>

        <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="50" label="Gross Amount" [data]="cashActivity.amount == 0 ? '0' : cashActivity.amount ? (cashActivity.amount | number : '1.2') : '-'">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="50" label="Business Date" [data]="cashActivity.businessDate | date: 'shortDate'">
          </ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="50" label="Transaction Date" [data]="cashActivity.transactionDate | date: 'shortDate'">
          </ati-labeled-data>

        </div>
        <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="50" label="Tracer Number" [data]="cashActivity.tracerNumber">
          </ati-labeled-data>
        </div>
    </div>
  </div>
  </mat-card>
</div>
