import { Directive } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

const BIRTH_DATE_PICKER_DATE_FORMAT = {
  parse: {
    dateInput: 'MM-DD'
  },
  display: {
    dateInput: 'MM-DD',
    monthYearLabel: 'MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Directive({
  selector: '[atomBirthDatePicker]',
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: BIRTH_DATE_PICKER_DATE_FORMAT },
  ],
})
export class BirthDatePickerDirective {
}
