import { Pipe, PipeTransform } from '@angular/core';
import { FCM } from '@advance-trading/ops-data-lib';

/**
 * Returns the display name for a given FCM
 */
@Pipe({name: 'displayFcm'})
export class DisplayFcmPipe implements PipeTransform {

  private FCMs = [
    { id: FCM.RCG, displayName: 'RCG' },
    { id: FCM.RJO, displayName: 'RJ O\'Brien' },
    { id: FCM.EDF_MAN, displayName: 'ED&F Man' },
    { id: FCM.STRAITS, displayName: 'Straits' },
    { id: FCM.MACQUARIE, displayName: 'Macquarie' },
    { id: FCM.CUNNINGHAM, displayName: 'Cunningham' }
  ];

  transform(value: string): string {
    const matchingEntry = this.FCMs.find(fcm => fcm.id === value);
    return matchingEntry ? matchingEntry.displayName : value;
  }
}
