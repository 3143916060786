import { FormControl, ValidatorFn, Validators } from '@angular/forms';

export class MarginSearchFormValidator {

  constructor() { }

  accountValidator(): ValidatorFn {
    return (control: FormControl) => {
      return control.validator = Validators.pattern('([a-zA-Z0-9]*[,]\\s?)*([a-zA-Z0-9]*){1}');
    };
  }

  brokerCodesValidator(): ValidatorFn {
    return (control: FormControl) => {
      return control.validator = Validators.pattern('([a-zA-Z0-9]{6}[,]\\s?)*([a-zA-Z0-9]{6}){1}');
    };
  }

  getErrorMessages(control: FormControl) {
    if (control === control.parent.get('account') && control.hasError('pattern')) {
      return 'Invalid Account Numbers';
    } else if (control === control.parent.get('brokerCode') && control.hasError('pattern')) {
      return 'Invalid Broker Codes';
    } else {
      return 'Invalid Value';
    }
  }
}
