import { gql } from 'apollo-angular';

export class MarginQueries {

  static getMarginsByAccountsQuery = gql<any, any>`
  query getMarginsByAccounts($accounts: [String!], $marginQuery: MarginQuery) {
    marginsByAccounts(accounts: $accounts, marginQuery: $marginQuery) {
      docId
      officeCode
      accountDocId
      accountNumber
      clientName
      marginType
      fundsTransferType
      totalEquity
      netLiq
      excessDeficit
      withdrawable
      salesCode
      marginCallAge
      marginCallRecordsCount
      longMarketValue
      processDate
      totalCall
      moneyAccount
    }
  }
  `;

  static getMarginsByBrokerCodesQuery = gql<any, any>`
  query getMarginsByBrokerCodes($brokerCodes: [String!], $marginQuery: MarginQuery) {
    marginsByBrokerCodes(brokerCodes: $brokerCodes, marginQuery: $marginQuery) {
      docId
      officeCode
      accountDocId
      accountNumber
      clientName
      marginType
      fundsTransferType
      totalEquity
      netLiq
      excessDeficit
      withdrawable
      salesCode
      marginCallAge
      marginCallRecordsCount
      longMarketValue
      processDate
      totalCall
      moneyAccount
    }
  }
  `;
}
