<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Position Details</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>

    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">Position: {{ positionDocId ? positionDocId : '' }}</h2>
      <mat-spinner class="loading-spinner" *ngIf="isLoading" diameter="40" color="accent"></mat-spinner>
    </div>

    <!-- Client Name, Exchange Id, FCM -->
    <div *ngIf="!errorMessage && position$ | async as position" fxLayout="column" fxLayoutGap="15px">
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Client Name" [data]="position.clientName">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Account Number" [data]="position.accountNumber">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Exchange Id" [data]="position.exchangeId">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="FCM" [data]="position.fcm">
        </ati-labeled-data>
      </div>

      <!-- Contract Month Year, Security Description, Account Number -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Security Description" [data]="position.securityDescription">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Commodity" [data]="getCommodityDisplayName(position)">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Contract Month Year" [data]="position.contractYearMonth | codeToMonth: 'long'">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Side" [data]="position.side | titlecase">
        </ati-labeled-data>
      </div>

      <!-- Side, Total Quantity, Strike Price -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Total Quantity" [data]="position.totalQuantity">
        </ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Strike Price" [data]="position.strikePrice ? (position.strikePrice | number: '1.2'): '-'">
        </ati-labeled-data>
      </div>

      <!-- Segments -->
      <h3 fxFlex="100">Segments</h3>

      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="segmetns-filter-field" #filter>
        <button mat-button *ngIf="filterValue.value" matSuffix mat-icon-button mat-icon-button aria-label="Clear" (click)="clearFilter()"></button>
      </mat-form-field>

      <mat-table [dataSource]="dataSource" matSort matSortActive="tradeDate" matSortDirection="desc" class="mat-elevation-z0">

        <ng-container matColumnDef="quantity">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Quantity</mat-header-cell>
          <mat-cell *matCellDef="let segment">{{ segment.quantity }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="tradePrice">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Trade Price</mat-header-cell>
          <mat-cell *matCellDef="let segment">{{ segment.tradePrice }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="tradeDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Trade Date</mat-header-cell>
          <mat-cell *matCellDef="let segment">{{ segment.tradeDate }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let segment; columns: columnsToDisplay"></mat-row>
      </mat-table>
      <mat-paginator color="primary" [pageSizeOptions]="[10,20,50]"></mat-paginator>
    </div>
  </mat-card>
</div>
