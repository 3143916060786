import { Component, Inject, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService, Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { Router, NavigationEnd } from '@angular/router';

import { catchError, switchMap, tap } from 'rxjs/operators';

import { AboutDialogComponent } from './utilities/ui/about-dialog/about-dialog.component';
import { AuthGuard } from './auth/auth.guard';
import { Observable, of } from 'rxjs';
import { User } from '@advance-trading/ops-data-lib';
import { UserService } from '@advance-trading/angular-ops-data';
import { MatDialog } from '@angular/material/dialog';

const ACCOUNT_ADMIN_ROLE = 'AccountAdmin';
const BRANCH_COMMITTEE_APPROVER_ROLE = 'BranchCommitteeApprover';
const BROKER_CODE_VIEWER_ROLE = 'BrokerCodeViewer';
const BROKER_REQUESTER_ROLE = 'BrokerRequester';
const BROKER_REQUEST_VIEWER_ROLE = 'BrokerRequestViewer';

@Component({
  selector: 'atom-root',
  templateUrl: './atom.component.html',
  styleUrls: ['./atom.component.scss'],
  providers: [BreakpointObserver]
})
export class AtomComponent implements OnInit {
  title = 'Advance Trading Operations Management - ATOM';
  sideNavOpened: boolean;
  sideNavMode = 'over';
  errorMessage = '';
  isRegistering = false;
  isXSmall = false;

  loggedInUser$: Observable<User>;

  private loggedInUser: User;

  constructor(
    private router: Router,
    private authzService: Auth0AuthzService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    @Inject('environment') private environment,
    public authGuard: AuthGuard,
    public authService: AuthService,
    private userService: UserService
  ) {
    this.authGuard.authErrors$.subscribe(error => {
      this.errorMessage = error;
    });
    this.authService.localAuthSetup().pipe(
      catchError(err => {
        // Check for a Firebase login problem associated with email verification
        if (err.error && err.error.message.includes('not verified')) {
          // Show not verified email screen
          console.log('Your email is NOT yet verified');
          this.errorMessage = `Your email address is not yet verified. ` +
            `Please follow the instructions contained in the verification email and then login again.`;
        } else {
          console.error(`Error occurred in authService.localAuthSetup: ${err}`);
          this.errorMessage = 'An unknown login error has occurred. Please try again later.';
        }
        return of(undefined);
      })
    ).subscribe();
  }

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe(state => {
        if (state.breakpoints[Breakpoints.XLarge] || state.breakpoints[Breakpoints.Large]) {
          this.sideNavMode = 'side';
          this.sideNavOpened = true;
        } else {
          this.sideNavMode = 'over';
          this.sideNavOpened = false;
        }

        // Set an indicator of XSmall screen size to assist in customizing toolbar
        this.isXSmall = state.breakpoints[Breakpoints.XSmall];
      });

    this.loggedInUser$ = this.authService.userProfile$.pipe(
      switchMap(userProfile => {
        // Avoid an error when a new user is registered but their Auth0 token doesn't yet have a firestoreDocId
        if (userProfile.app_metadata['firestoreDocId']) {
          return this.userService.getUserByDocId(userProfile.app_metadata['firestoreDocId']);
        } else {
          return this.userService.getUserByAuthId(userProfile.sub);
        }
      }),
      tap(user => this.loggedInUser = user),
      catchError(err => {
        console.error(`Could not retrieve User by firestoreDocId or Auth0 authId: ${err}`);
        return of(undefined);
      })
    );

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.endsWith('/register')) {
          this.isRegistering = true;
          this.errorMessage = '';
          this.sideNavOpened = false;
        } else {
          this.isRegistering = false;
        }
      }
    });
  }

  handleSideNavigation() {
    if (this.sideNavMode !== 'side') {
      this.sideNavOpened = !this.sideNavOpened;
    }
  }

  navigateToProfile() {
    this.router.navigate(['/users', this.loggedInUser.docId]);
  }

  navigateToLogout() {
    this.router.navigate(['/logout']);
  }

  showAbout() {
    this.dialog.open(AboutDialogComponent, {
      data: {
        versionNumber: this.environment.versionNumber,
        versionDate: this.environment.versionDate
      },
      width: '600px'
    });
  }

  canViewAccountSeries() {
    return this.authzService.currentUserHasRole(ACCOUNT_ADMIN_ROLE);
  }

  canViewBrokerRelationships() {
    return this.authzService.currentUserHasRole(BROKER_CODE_VIEWER_ROLE);
  }

  canViewBrokerRequests() {
    return this.authzService.currentUserHasRole(BROKER_REQUEST_VIEWER_ROLE)
      || this.authzService.currentUserHasRole(BRANCH_COMMITTEE_APPROVER_ROLE)
      || this.authzService.currentUserHasRole(BROKER_REQUESTER_ROLE);
  }

  canViewCommissionSchedules() {
    // TODO update with CommissionScheduleViewer role??
    return this.authzService.currentUserHasRole(BROKER_CODE_VIEWER_ROLE);
  }

  canViewSalesCodes() {
    return this.authzService.currentUserHasRole(ACCOUNT_ADMIN_ROLE);
  }

}
