import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
  OnDestroy, } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';

import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { Auth0AuthzService, AuthService } from '@advance-trading/angular-ati-security';
import { ObservableDataSource, StorageService } from '@advance-trading/angular-common-services';
import { UserService } from '@advance-trading/angular-ops-data';
import { BrokerRelationship, User } from '@advance-trading/ops-data-lib';
import { ExportService } from 'src/app/services/export.service';

const COMMISSIONS_VIEWER_ROLE = 'CommissionsViewer';
const PAGE_SIZE_KEY = 'atom.brokerRelationshipsPageSize';

interface NamedBrokerRelationship extends BrokerRelationship {
  brokerNames?: string;
  brokerNamesAndSplit?: string;
}

@Component({
  selector: 'atom-broker-relationships',
  templateUrl: './broker-relationships.component.html',
  styleUrls: ['./broker-relationships.component.css'],
  providers: [BreakpointObserver]
})
export class BrokerRelationshipsComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {

  columnsToDisplay = [];
  brokerCodeRequester = false;
  errorMessage: string;
  dataSource = new ObservableDataSource<NamedBrokerRelationship>();
  filterValue = new FormControl();
  exportable = false;
  private queryParams: Params;
  private unsubscribe$: Subject<void> = new Subject<void>();

  private readonly SHEET_NAME = 'ATOM Broker Relationships';

  @Input() selectedBrokerRels$: Observable<BrokerRelationship[]>;
  @Input() initialTableState: { [key: string]: string | number };

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  @Output() brokerRelListChange: EventEmitter<any> = new EventEmitter();
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private changeDetector: ChangeDetectorRef,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public exportService: ExportService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.isSearching.emit(true);
    this.breakpointObserver.observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        // display only broker code and accounts icon columns for xsmall screens
        if (state.matches) {
          this.columnsToDisplay = [
            'brokerCode', 'icon'
          ];
        // display brokerNames, broker code, status, and accounts icon for larger screens
        } else {
          this.columnsToDisplay = [
            'brokerNames', 'brokerCode', 'managingPod', 'status', 'icon'
          ];
        }
      });

    this.filterValue.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((filter: string) => {
      if (filter) {
        this.queryParams.filter = filter.trim();
      } else if (this.queryParams.filter) {
        delete this.queryParams.filter;
      }

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        replaceUrl: true,
        queryParams: this.queryParams
      });
    });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
      this.queryParams = Object.assign({}, params);
    }));

    const filterVal = this.queryParams.filter;
    if (filterVal) {
      this.filterValue.setValue(filterVal);
      this.applyFilter(this.filterValue.value);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialTableState'] && changes['selectedBrokerRels$']) {
    this.dataSource.data$ = this.getNamedBrokerRels()
        .pipe(
          tap((brokerRelationships) => {
            this.exportable = brokerRelationships.length > 0;
            this.isSearching.emit(false);
            this.setTableState();
          }),
          catchError(err => {
            this.isSearching.emit(false);
            this.errorMessage = 'Error retrieving broker relationships; please try again later';
            console.error(`Error retrieving broker relationships: ${err}`);
            return of([]);
          })
        );
    }
  }

  get commissionsViewer() {
    return this.authzService.currentUserHasRole(COMMISSIONS_VIEWER_ROLE);
  }

  // assigns brokerRels and brokerNames to NamedBrokerRelationships
  private getNamedBrokerRels() {
    return this.selectedBrokerRels$
    .pipe(
      switchMap((brokerRels: BrokerRelationship[]) => {
        if (brokerRels[0] === undefined) {
          return of([]);
        }
        // retrieve User objects for each brokerDocId in the brokers[]
        return combineLatest(brokerRels.map((brokerRel: BrokerRelationship) => {
          if (brokerRel.brokers.length > 0) {
            return combineLatest(brokerRel.brokers.map((brokerDocId: string) => {
              return this.userService.getUserByDocId(brokerDocId);
            }))
              .pipe(
                map((brokerUsers: User[]) => {
                  const nameReducer = (names: string, user: User) => `${names}${user.firstName} ${user.lastName}, `;
                  const nameAndSplitReducer = (namesAndSplit: string, user: User) => `${namesAndSplit}${user.firstName} ${user.lastName} (${this.getSplitByUser(brokerRel, user)}), `;
                  return {
                    ...brokerRel,
                    brokerNames: brokerUsers.reduce(nameReducer, '').slice(0, -2),
                    brokerNamesAndSplit: brokerUsers.reduce(nameAndSplitReducer, '').slice(0, -2)
                  } as NamedBrokerRelationship;
                })
              );
          } else {
            return of({
              ...brokerRel,
              brokerNames: 'HOUSE',
              brokerNamesAndSplit: 'HOUSE'
            } as NamedBrokerRelationship);
          }
        }));
      })
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = this.storageService.localStorage.get(PAGE_SIZE_KEY).value() || 10;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data, header) => data[header];
    this.filter.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  selectBrokerRel(brokerRel: NamedBrokerRelationship) {
    this.router.navigate(['/brokerrelationships', brokerRel.docId]);
  }

  selectAccountsIcon(brokerRel: NamedBrokerRelationship) {
    this.router.navigate(['/accounts'], { queryParams: { brokerCode: brokerRel.brokerCode} });
  }

  addNewBrokerRel() {
    this.router.navigate(['/brokerrelationships/new']);
  }

  displayBrokerCode(brokerRel: NamedBrokerRelationship): string {
    return brokerRel.isSecondary ? `${brokerRel.brokerCode} ${brokerRel.secondaryLabel}` : brokerRel.brokerCode;
  }

  handlePageChange() {
    this.storageService.localStorage.set(PAGE_SIZE_KEY, this.paginator.pageSize);
    this.queryParams.pageIndex = this.paginator.pageIndex;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  handleSortChange() {
    this.queryParams.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.queryParams.sortColName = this.queryParams.sortDir ? this.sort.active : undefined;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  canUserViewSplits(brokerRel: BrokerRelationship) {
    return this.commissionsViewer ||
      brokerRel.splits.find(split => split.brokerDocId === this.authService.userProfile.app_metadata.firestoreDocId);
  }

  private setTableState() {
    if (this.filter) {
      const pageIndex = this.queryParams.pageIndex as number;
      if (pageIndex) {
        this.dataSource.paginator.pageIndex = pageIndex;
      }

      const sortDir = this.queryParams.sortDir as SortDirection;
      const sortColName = this.queryParams.sortColName as string;
      if (sortDir && sortColName) {
        this.dataSource.sort.direction = sortDir;
        this.dataSource.sort.active = sortColName;
      }
    }
  }

  private getSplitByUser(brokerRel: NamedBrokerRelationship, user: User): string {
    const brokerSplit = brokerRel.splits.find(split => split.brokerDocId === user.docId);
    const calcDec = Math.pow(10, 2);
    return (Math.trunc((brokerSplit.split * 100) * calcDec) / calcDec).toString() + '%';
  }
}
