import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns the display name for a given id
 */
@Pipe({name: 'displayName'})
export class DisplayNamePipe implements PipeTransform {
  transform(id: string, entries: {[key: string]: string}[]) {
    const matchingEntry = entries.find(entry => entry.id === id);
    return matchingEntry ? matchingEntry.displayName : id;
  }
}
