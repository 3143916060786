<div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Bank Details</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title" *ngIf="createMode">{{ (client$ | async)?.name }} - New Bank</h2>
      <h2 class="card-title" *ngIf="!createMode">{{ (client$ | async)?.name }} - {{ (bank$ | async)?.name}}</h2>
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <button aria-label="Edit Bank" *ngIf="!editMode && !createMode && updateComplete && canUpdate" (click)="setEditMode(true)" mat-mini-fab color="accent" id="edit-client-bank" matTooltip="Edit Bank">
          <mat-icon>edit</mat-icon>
        </button>
        <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
        <button aria-label="Update Bank" *ngIf="!createMode && editMode && updateComplete" [disabled]="!clientBankForm.valid || clientBankForm.pristine" (click)="saveForm()" mat-mini-fab color="accent" id="update-client-bank" matTooltip="Update Bank">
          <mat-icon>done</mat-icon>
        </button>
        <button aria-label="Create Bank" *ngIf="createMode && canUpdate && updateComplete" [disabled]="!clientBankForm.valid" (click)="saveForm()" mat-mini-fab color="accent" matTooltip="Create Bank" id="create-client-bank">
          <mat-icon>done</mat-icon>
        </button>
        <button aria-label="Delete Bank" *ngIf="!createMode && editMode && updateComplete" (click)="deleteBank(bank)" mat-mini-fab color="warn" id="client-bank-delete" matTooltip="Delete Bank">
          <mat-icon>delete</mat-icon>
        </button>
        <button aria-label="Discard Bank Changes" *ngIf="updateComplete && editMode && !createMode" (click)="setEditMode(false)" mat-mini-fab color="" id="discard-client-bank" matTooltip="Cancel Changes">
          <mat-icon>clear</mat-icon>
        </button>
        <button aria-label="Discard Bank Changes" *ngIf="createMode && !clientBankForm.pristine && updateComplete" (click)="reset()" mat-mini-fab color="" id="discard-client-bank" matTooltip="Reset Form">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <form *ngIf="!errorMessage" [formGroup]="clientBankForm" (ngSubmit)="saveForm()" fxLayout="column">
      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxLayoutGap="5">
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="75" fxFlex.gt-xs="40" label="Bank Name" data="{{ clientBankForm.get('name').value }}"></ati-labeled-data>
        <mat-form-field *ngIf="editMode" fxFlex.xs="75" fxFlex.gt-xs="40">
          <input required matInput placeholder="Bank Name" formControlName="name" trim="blur">
          <mat-error *ngIf="clientBankForm.get('name').invalid">{{getErrorMessage(clientBankForm.get('name'))}}</mat-error>
        </mat-form-field>
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="75" fxFlex.gt-xs="40" label="Routing Number/Swift Code" data="{{ clientBankForm.get('routingNumber').value }}"></ati-labeled-data>
        <mat-form-field *ngIf="editMode" fxFlex.xs="75" fxFlex.gt-xs="40">
          <input required matInput placeholder="Routing Number/Swift Code" formControlName="routingNumber" trim="blur" maxlength="11" upperCase>
          <mat-error *ngIf="clientBankForm.get('routingNumber').invalid">{{getErrorMessage(clientBankForm.get('routingNumber'))}}</mat-error>
        </mat-form-field>
        <div fxFlex.gt-xs="10">
          <!-- Placeholder for spacing -->
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <ati-labeled-data *ngIf="!editMode" fxFlex.xs="60" fxFlex.gt-xs="40" label="Phone Number" [data]="displayPhoneNumber()"></ati-labeled-data>
          <mat-form-field [class]="editMode ? 'phone-field' : 'not-displayed-but-rendered'" fxFlex.xs="60" fxFlex.gt-xs="40">
            <ngx-mat-intl-tel-input [preferredCountries]="['us']" [enablePlaceholder]="true" [enableSearch]="false" name="phoneNumber" formControlName="phoneNumberNumber" #phoneNumber></ngx-mat-intl-tel-input>
            <button mat-button *ngIf="clientBankForm.get('phoneNumberNumber').value && editMode" matSuffix mat-icon-button aria-label="Clear Phone Number" (click)="clearPhoneNumber(clientBankForm.get('phoneNumberNumber'), phoneNumber)">
              <mat-icon>close</mat-icon>
            </button>
            <mat-label>Phone Number</mat-label>
            <mat-error *ngIf="clientBankForm.get('phoneNumberNumber').invalid">{{getErrorMessage(clientBankForm.get('phoneNumberNumber'))}}</mat-error>
          </mat-form-field>
          <ati-labeled-data *ngIf="!editMode" fxFlex.xs="35" fxFlex.gt-xs="10" label="Ext" data="{{ clientBankForm.get('phoneNumberExtension').value }}"></ati-labeled-data>
          <mat-form-field *ngIf="editMode" fxFlex.xs="35" fxFlex.gt-xs="10">
            <input matInput placeholder="Ext" formControlName="phoneNumberExtension" trim="blur" mask="999999999">
          </mat-form-field>
          <div fxHide.xs fxFlex.gt-xs="40">
            <!-- Placeholder for spacing -->
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
          <h4>Address</h4>
          <button *ngIf="!bank?.address" [disabled]="clientBankForm.disabled" id="enable-bank-address" type="button" aria-label="Show/Hide Address" [color]="!enableAddress ? 'primary' : 'accent'" mat-icon-button (click)="toggleAddressForm()" matTooltip="Show/Hide Address">
            <mat-icon>{{!enableAddress ? 'add_circle' : 'cancel'}}</mat-icon>
          </button>
          <button *ngIf="!createMode && editMode && bank?.address && !deleteAddress" [disabled]="clientBankForm.disabled" id="delete-bank-address" type="button" aria-label="Delete Address" color="warn" mat-icon-button (click)="clearAddress()" matTooltip="Delete Address">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <ati-address *ngIf="enableAddress || bank?.address" [addressForm]="clientBankForm.get('address')"></ati-address>
      </div>
    </form>
  </mat-card>
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90" *ngIf="!errorMessage && !createMode">
    <h2 class="card-title">Bank Accounts</h2>
    <div fxLayout="column">
      <h4 *ngIf="(bankAccounts$ | async)?.length > 0">Existing Accounts</h4>
      <atom-bank-account *ngFor="let bankAccount of bankAccounts$ | async" [account]="bankAccount" [editMode]="editMode"></atom-bank-account>
      <h4 *ngIf="editMode">Add New Account</h4>
      <atom-bank-account *ngIf="editMode" [editMode]="editMode"></atom-bank-account>
    </div>
  </mat-card>
</div>
