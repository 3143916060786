import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  AfterViewChecked,
  AfterViewInit,
  ViewChildren,
  QueryList,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCheckbox } from '@angular/material/checkbox';

import { ActivatedRoute, Params, Router } from '@angular/router';

import { Observable, combineLatest, of, Subscription } from 'rxjs';
import { take, switchMap, map, startWith, filter } from 'rxjs/operators';
import {
  Account,
  Side,
  Commodity,
  SecurityType,
  SecuritySubType,
  OrderStatus,
  AccountPurpose
} from '@advance-trading/ops-data-lib';

import {
  OperationsDataService
} from '@advance-trading/angular-ops-data';
import { AccountService } from '../../../services/account-service';
import { ContractMonthUtility } from '@advance-trading/angular-common-services';
import { OrderSearchFormValidator } from '../../order-search/order-search-form-validator';
import { MatTabGroup } from '@angular/material/tabs';
import { OrderLegDisplay } from '../../../utilities/order-fill-leg-display';
import { OrderSearchCriteria, OrderSymbols } from '../../../services/service-interfaces/order-search-interface';
import { OrderService } from '../../../services/order-service';

const DEFAULT_END_DATE = new Date('12/31/2099').toISOString();
const DEFAULT_START_DATE = new Date('1/1/2000').toISOString();

@Component({
  selector: 'atom-order-search-tab',
  templateUrl: './order-search-tab.component.html',
  styleUrls: ['./order-search-tab.component.css']
})
export class OrderSearchTabComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit, OnChanges {

  @ViewChild('orderYearMonthPicker', { static: false }) orderYearMonthRef;
  @ViewChildren('subTypeBoxes') subTypeBoxes: QueryList<MatCheckbox>;
  @ViewChildren('securityBox') securityBox: QueryList<MatCheckbox>;
  @ViewChildren('statusBoxes') statusBoxes: QueryList<MatCheckbox>;
  @ViewChild('tab') tab: MatTabGroup;


  @Input() searchFields: string[]; // search fields to include on the form
  @Input() selectedTab: string; // current active tab
  @Input() tabIndex: string; // identifier for each tab
  @Input() tabStatuses: string[]; // statuses to search for status specific tabs (open, filled)
  @Output() outputFilteredBy: EventEmitter<string> = new EventEmitter(); // filteredBy message for parent component

  public formValidator = new OrderSearchFormValidator();

  orderSearchForm: FormGroup = this.formBuilder.group({
    brokerCodes: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.brokerCodesValidator()),
    accounts: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.accountsValidator()),
    side: this.formBuilder.control({ value: '', disabled: false }),
    contractMonthYear: [''],
    strikePriceMin: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.strikeValidator()),
    strikePriceMax: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.strikeValidator()),
    symbol: this.formBuilder.control({ value: '', disabled: false }),
    securityType: this.formBuilder.control({ value: '', disabled: false }),
    subTypeBoxes: [],
    securityBox: [],
    statusBoxes: [],
    startDate: this.formBuilder.control({ value: DEFAULT_START_DATE, disabled: false }),
    endDate: this.formBuilder.control({ value: DEFAULT_END_DATE, disabled: false }),
  });

  filteredBy = '';
  errorMessage: string;
  isSearching = false;
  showOrders = false;
  selectedOrders$: Observable<OrderLegDisplay[]>;
  tableState: { [key: string]: string | number } = {};
  orderSides = Object.keys(Side);
  filteredCommodities: Observable<Commodity[]>;
  commoditySubscription: Subscription;
  orderSecurityTypes = Object.keys(SecurityType);
  orderSubTypes = Object.keys(SecuritySubType);
  orderStatuses = Object.keys(OrderStatus).filter(status => status !== 'TRADE_CANCELLED' && status !== 'TRADE_CORRECTED');
  selectedParameters: { [key: string]: any } = {};
  searchMessage: string;

  private queryParams: Params;
  private commodities: Commodity[];
  private selectedSubTypes = [];
  private selectedTypes = [];
  private selectedStatuses = [];
  private chosenCommodity = '';
  private todaysDate = new Date();

  constructor(
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private monthCode: ContractMonthUtility,
    private operationsDataService: OperationsDataService,
    private orderService: OrderService
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.queryParams = Object.assign({}, params);
      // only setup form from query params for current tab
      if (this.tabIndex === this.selectedTab) {
        if (this.queryParams.symbol) {
          this.chosenCommodity = this.queryParams.symbol;
        }

        this.orderSearchForm.get('accounts').setValue(this.queryParams.accounts);
        this.orderSearchForm.get('brokerCodes').setValue(this.queryParams.brokerCodes);
        if (this.queryParams.contractMonthYear) {
          this.orderSearchForm.get('contractMonthYear')
            .setValue(this.monthCode.translateContractMonthToMoment(this.queryParams.contractMonthYear));
        }
        this.orderSearchForm.get('side').setValue(this.queryParams.side);
        this.orderSearchForm.get('strikePriceMin').setValue(this.queryParams.strikePriceMin);
        this.orderSearchForm.get('strikePriceMax').setValue(this.queryParams.strikePriceMax);

        if (this.queryParams.securitySubTypes) {
          this.selectedSubTypes = this.queryParams.securitySubTypes.split(',');
          this.orderSearchForm.get('subTypeBoxes').setValue(this.selectedSubTypes);
        }
        if (this.queryParams.statuses) {
          this.selectedStatuses = this.queryParams.statuses.split(',');
          this.orderSearchForm.get('statusBoxes').setValue(this.selectedStatuses);
        }
        if (this.queryParams.securityTypes) {
          if (this.queryParams.securityTypes.includes('FUTURE')) {
            this.selectedTypes = ['FUTURE'];
            this.orderSearchForm.get('securityBox').setValue(this.selectTypes);
          }
        }
      }
      // set up all tabs that have the date field
      if (this.queryParams.startDate && this.tabIndex === this.selectedTab) {
        this.orderSearchForm.get('startDate').setValue(this.queryParams.startDate);
      } else if (this.searchFields.includes('date')) {
        this.orderSearchForm.get('startDate').setValue(this.todaysDate);
      }
      if (this.queryParams.endDate && this.tabIndex === this.selectedTab) {
        this.orderSearchForm.get('endDate').setValue(this.queryParams.endDate);
      } else if (this.searchFields.includes('date')) {
        this.orderSearchForm.get('endDate').setValue(this.todaysDate);
      }

      if (Object.keys(params).length && this.tabIndex === this.selectedTab) {
        // Mark form as dirty so reset button appears
        this.orderSearchForm.markAsDirty();
        this.searchOrders();
      }
      this.commoditySubscription = this.operationsDataService.getCommodityMap().subscribe(doc => {

        this.commodities = Object.values(doc.commodities)
          .filter(commodity => commodity.id !== 'VARIOUS')
          .sort((a, b) => a.name.localeCompare(b.name));
        if (this.chosenCommodity) {
          const index = this.commodities.findIndex(val => val.id === this.chosenCommodity);
          this.orderSearchForm.get('symbol').setValue(this.commodities[index]);
        }
        this.filteredCommodities = this.orderSearchForm.get('symbol').valueChanges.pipe(
          startWith<string | Commodity>(''),
          filter(value => typeof value === 'string'),
          map((commodityName: string) => {
            const filterValue = commodityName.toLowerCase();
            return this.commodities.filter(commodity => commodity.name.toLowerCase().includes(filterValue.toLowerCase()));
          })
        );
      });
    });
  }

  ngOnDestroy() {
    if (this.commoditySubscription) {
      this.commoditySubscription.unsubscribe();
    }
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit() {
    // setting here as [checked] property on template was not setting inner input type="checkbox" to checked
    this.subTypeBoxes.forEach(subTypeBox => subTypeBox.checked = this.selectedSubTypes.includes(subTypeBox.value));
    this.securityBox.forEach(typeBox => typeBox.checked = this.selectedTypes.includes(typeBox.value));
    this.statusBoxes.forEach(box => box.checked = this.selectedStatuses.includes(box.value));
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedTab'] && changes['selectedTab'].firstChange === false) {
      this.selectedOrders$ = of([]);
      this.reset();
    }
  }

  reset() {
    this.isSearching = false;
    this.orderSearchForm.reset();
    this.clearQueryParams();
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
    });
    this.selectedSubTypes = [];
    this.selectedTypes = [];
    this.selectedStatuses = [];
    this.subTypeBoxes.forEach(subTypeBox => subTypeBox.checked = false);
    this.securityBox.forEach(box => box.checked = false);
    this.statusBoxes.forEach(box => box.checked = false);
    this.orderSearchForm.get('side').setValue(undefined);
    this.orderSearchForm.enable();
    this.orderSearchForm.get('symbol').setValue('');
    if (this.searchFields.includes('date')) {
      this.orderSearchForm.get('startDate').setValue(this.todaysDate);
      this.orderSearchForm.get('endDate').setValue(this.todaysDate);
    }
    this.chosenCommodity = '';
    this.filteredBy = '';
    this.outputFilteredBy.emit(this.filteredBy);
    this.orderSearchForm.markAsPristine();
    this.showOrders = false;
  }

  searchOrders(searchButtonClicked: boolean = false) {
    this.snackBar.dismiss();
    if (searchButtonClicked) {
      // clear initial table state if the user perform a new search
      this.clearQueryParams();
      this.tableState = {};
      this.selectedParameters = {};
    } else {
      // set initial table state from query param if the user is back navigating from another page
      const sortDir = this.queryParams.sortDir;
      const sortColName = this.queryParams.sortColName;
      const pageSize = this.queryParams.pageSize;
      const pageIndex = this.queryParams.pageIndex;
      const filterValue = this.queryParams.filterValue;
      this.tableState = {
        sortDir,
        sortColName,
        pageSize,
        pageIndex,
        filterValue,
      };
    }

    this.showOrders = false;
    this.changeDetector.detectChanges();
    this.selectedOrders$ = this.chooseQuery();
    this.showOrders = true;
  }

  selectMonthYear(event: moment.Moment) {
    this.orderSearchForm.get('contractMonthYear').setValue(event);
    this.orderSearchForm.get('contractMonthYear').markAsDirty();
    this.orderYearMonthRef.close();
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMax')) {
      return 'Value Invalid';
    }
    return 'Unknown Error';
  }

  handleOrderListChange(tableState: { [key: string]: string | number }) {
    if (tableState.sortDir && tableState.sortColName) {
      this.queryParams.sortDir = tableState.sortDir;
      this.queryParams.sortColName = tableState.sortColName;
    } else if (this.queryParams.sortDir && this.queryParams.sortColName) {
      // remove sorted direction and column in query param if there's no sort applied
      delete this.queryParams.sortDir;
      delete this.queryParams.sortColName;
    }
    if (tableState.pageSize) {
      this.queryParams.pageSize = tableState.pageSize;
    }
    if (tableState.pageIndex !== undefined) {
      this.queryParams.pageIndex = tableState.pageIndex;
    }

    if (tableState.filterValue) {
      this.queryParams.filterValue = tableState.filterValue;
    } else if (this.queryParams.filterValue) {
      // remove filter query param if there's no filter applied
      delete this.queryParams.filterValue;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams,
    });
  }

  handleOrderListError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.changeDetector.detectChanges();
  }

  onSubTypeChange(event) {
    if (event.checked) {
      this.selectedSubTypes.push(event.source.value);
    } else {
      this.selectedSubTypes = this.selectedSubTypes.filter(type => type !== event.source.value);
    }
    this.orderSearchForm.get('subTypeBoxes').setValue(this.selectedSubTypes);
    if (this.selectedSubTypes.length) {
      this.orderSearchForm.get('subTypeBoxes').markAsDirty();
    }
  }

  onTypeChange(event) {
    if (event.checked) {
      this.selectedTypes.push(event.source.value);
    } else {
      this.selectedTypes = this.selectedTypes.filter(type => type !== event.source.value);
    }
    this.orderSearchForm.get('securityBox').setValue(this.selectedTypes);
    if (this.selectedTypes.length) {
      this.orderSearchForm.get('securityBox').markAsDirty();
    }
  }

  onStatusChange(event) {
    if (event.checked) {
      this.selectedStatuses.push(event.source.value);
    } else {
      this.selectedStatuses = this.selectedStatuses.filter(type => type !== event.source.value);
    }
    this.orderSearchForm.get('statusBoxes').setValue(this.selectedStatuses);
    if (this.selectedStatuses.length) {
      this.orderSearchForm.get('statusBoxes').markAsDirty();
    }
  }

  private chooseQuery(): Observable<OrderLegDisplay[]> {
    const brokerCodeValues = this.orderSearchForm.get('brokerCodes').value;
    const accountValues = this.orderSearchForm.get('accounts').value;
    const sideValue = this.orderSearchForm.get('side').value;
    const symbolValue = this.orderSearchForm.get('symbol').value;
    const contractMonthYear = this.orderSearchForm.get('contractMonthYear').value;
    const strikePriceMax = parseFloat(this.orderSearchForm.get('strikePriceMax').value);
    const strikePriceMin = parseFloat(this.orderSearchForm.get('strikePriceMin').value);
    let startDate;
    let endDate;
    if (this.searchFields.includes('date')) {
      startDate = this.orderSearchForm.get('startDate').value;
      endDate = this.orderSearchForm.get('endDate').value;
    }
    const securityTypes = this.selectTypes();
    const hasAnySubType = this.selectedSubTypes.length > 0;
    const hasStatuses = this.selectedStatuses.length > 0;
    let brokerCodesFiltered;
    this.queryParams = this.tableState as Params;
    this.selectedParameters = this.queryParams;

    this.queryParams.selectedTab = this.selectedTab;

    if (symbolValue) {
      this.queryParams.symbol = this.getSymbolsObjectForQueryParams(symbolValue).id;
    } else if (this.chosenCommodity) {
      this.queryParams.symbol = this.chosenCommodity;
    }

    if (contractMonthYear) {
      this.queryParams.contractMonthYear = this.monthCode.translateMomentToContractMonth(contractMonthYear);
    }

    if (strikePriceMin) {
      this.queryParams.strikePriceMin = strikePriceMin;
    }

    if (strikePriceMax) {
      this.queryParams.strikePriceMax = strikePriceMax;
    }

    if (this.searchFields.includes('date')) {
      if (startDate) {
        startDate = new Date(startDate);
        startDate.setHours(0, 0, 0, 0);
        startDate = startDate.toISOString();
      } else {
        startDate = DEFAULT_START_DATE;
      }
      this.queryParams.startDate = startDate;


      if (endDate) {
        endDate = new Date(endDate);
        endDate.setHours(23, 59, 59, 999);
        endDate = endDate.toISOString();
      } else {
        endDate = DEFAULT_END_DATE;
      }
      this.queryParams.endDate = endDate;
    }



    if (sideValue) {
      this.queryParams.side = sideValue;
    }

    if (hasAnySubType) {
      this.queryParams.securitySubTypes = this.selectedSubTypes.join(',');
    }

    if (securityTypes && securityTypes.length > 0) {
      this.queryParams.securityTypes = securityTypes.join(',');
    }

    if (this.tabStatuses) {
      this.selectedStatuses = this.tabStatuses;
      this.queryParams.statuses = this.selectedStatuses.join(',');
    } else if (hasStatuses && this.searchFields.includes('status')) {
      this.queryParams.statuses = this.selectedStatuses.join(',');
    }

    const orderSearchCriteria: OrderSearchCriteria = {
        side: sideValue,
        statuses: this.selectedStatuses,
        lastUpdatedStart: startDate,
        lastUpdatedEnd: endDate
    };

    if (accountValues) {
      this.queryParams.accounts = accountValues;
    } else if (brokerCodeValues) {
      this.queryParams.brokerCodes = brokerCodeValues;
      brokerCodesFiltered = this.splitBrokerCodes(brokerCodeValues);
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams,
    });

    if (accountValues) {
      return this.retrieveOrdersByAccountNumber(accountValues, orderSearchCriteria);
    } else if (brokerCodeValues) {
      return this.orderService.getOrdersByBrokerCodes(brokerCodesFiltered, orderSearchCriteria);
    } else {
      return this.orderService.getOrdersByAccounts([], orderSearchCriteria);
    }
  }

  disableSubmit(orderSearchForm: FormGroup) {
    return !orderSearchForm.valid;
  }

  displayCommodity(commodity: Commodity): string {
    if (commodity) {
      return commodity.name;
    }
    return '';
  }

  clearSide() {
    this.orderSearchForm.get('side').setValue(undefined);
  }

  // Clears the value and disables client field and enables account field
  accountFieldClicked() {
    this.orderSearchForm.get('brokerCodes').setValue('');
    this.orderSearchForm.get('brokerCodes').disable();
    this.orderSearchForm.get('accounts').enable();
  }

  // Clears the value and disables account field and enables brokerCode field
  brokerCodeFieldClicked() {
    this.orderSearchForm.get('accounts').setValue('');
    this.orderSearchForm.get('accounts').disable();
    this.orderSearchForm.get('brokerCodes').enable();
  }

  private retrieveOrdersByAccountNumber(accountValue: string, gqlParams: any): Observable<OrderLegDisplay[]> {
    let accountNumbers = this.splitAccounts(accountValue);

    if (accountNumbers.length === 0) {
      return of([]);
    }
    return combineLatest(
      accountNumbers.map((accountNumber) => {
        return this.accountService.retrieveActiveAccountsByNumber(accountNumber);
      })
    ).pipe(map((accounts) => accounts.flat()))
      .pipe(switchMap((accounts) => {
        const observableAccounts = [];
        accounts.forEach((account: Account) => {
          if (account.purpose === AccountPurpose.MARGIN_GROUP) {
            observableAccounts.push(this.accountService.retrieveAccountsByMarginGroupAccountDocId(account.marginGroupAccountDocId));
          }
        });

        if (observableAccounts.length) {
          return combineLatest(observableAccounts).pipe(
            map(accts => accts.flat()),
            switchMap((accts: Account[]) => {
              accts.push(...accounts);

              // Remove duplicate accounts before getting Orders for each account
              const duplicates = [];
              accts = accts.filter(acct => duplicates.includes(acct.docId) ? false : duplicates.push(acct.docId));
              const acctNumbers = accts.map((account: Account): string => account.officeCode + account.number);
              return this.orderService.getOrdersByAccounts(acctNumbers, gqlParams);
            })
          );
        }
        accountNumbers = accounts.map(account => account.officeCode + account.number);
        return this.orderService.getOrdersByAccounts(accountNumbers, gqlParams);
      }));
  }

  private selectTypes() {
    const returnTypes = [];
    if (this.selectedTypes.includes('FUTURE')) {
      returnTypes.push('FUTURE', 'FUTURE_SPREAD');
    }
    if (this.selectedSubTypes.includes('PUT') || this.selectedSubTypes.includes('CALL')) {
      returnTypes.push('UDS', 'OPTION');
    }
    return returnTypes;
  }

  private splitAccounts(accountValue: string): string[] {
    const accountNumbers = accountValue.split(',').map((accountNum) => accountNum.trim());
    // filter duplicate account Numbers
    const accountNumbersFiltered = [...new Set(accountNumbers)];
    this.filteredBy = `Account Number - ${accountNumbersFiltered.join(', ')}`;
    this.outputFilteredBy.emit(this.filteredBy);
    return accountNumbersFiltered;
  }

  private splitBrokerCodes(brokerCodes: string): string[] {
    const brokerCodeValues = brokerCodes.split(',').map((code) => code.trim());
    let brokerCodesFiltered = [];

    // filter duplicate brokerCodes
    brokerCodesFiltered = [...new Set(brokerCodeValues)];
    this.filteredBy = `Broker Code - ${brokerCodesFiltered.join(', ')}`;
    this.outputFilteredBy.emit(this.filteredBy);
    return brokerCodesFiltered;
  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom',
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom',
      });
    }
  }

  private getSymbolsObjectForQueryParams(commodity: Commodity) {
    return {
      id: commodity.id,
      optionsSymbol: commodity.electronicOptionsSymbol,
      futuresSymbol: commodity.electronicFuturesSymbol
    } as OrderSymbols;
  }
}
