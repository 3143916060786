import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';

import { Broker, Client, Status, User, BrokerType, PayCode, PayCodeSplit } from '@advance-trading/ops-data-lib';
import { Auth0AuthzService, AuthService } from '@advance-trading/angular-ati-security';
import { UserService } from '@advance-trading/angular-ops-data';
import { ClientService } from '../services/client-service';
import { BrokerService } from '../services/broker-service';

import { CommonValidators } from '@advance-trading/angular-common-services';
import { MatTabGroup } from '@angular/material/tabs';

const BROKER_CODE_VIEWER_ROLE = 'BrokerCodeViewer';
const BROKER_ADMIN_ROLE = 'BrokerAdmin';
const COMPLIANCE_APPROVER_ROLE = 'ComplianceApprover';
const ACCOUNTING_ADMIN_ROLE = 'AccountingAdmin';

@Component({
  selector: 'atom-broker',
  templateUrl: './broker-detail.component.html',
  styleUrls: ['./broker-detail.component.css']
})
export class BrokerDetailComponent implements OnInit, OnDestroy {

  // navigation
  editMode = false;
  createMode = false;
  updateComplete = true;
  errorMessage: string;

  brokerForm: FormGroup = this.formBuilder.group({
    status: new FormControl('', [
      Validators.required
    ]),
    type: new FormControl('', [
      Validators.required
    ]),
    mentors: new FormControl(),
    client: new FormControl('', [
      CommonValidators.objectValidator
    ]),
    address: new FormGroup({
      street1: new FormControl('', [
        Validators.required,
        Validators.maxLength(200)
      ]),
      street2: new FormControl('', [
        Validators.maxLength(200)
      ]),
      city: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      region: new FormControl('', [
        Validators.required
      ]),
      postalCode: new FormControl('', [
        Validators.required
      ]),
      country: new FormControl('', [
        Validators.required
      ]),
    }),
    payCodes: this.formBuilder.array([]),
  });

  brokerTypes = Object.keys(BrokerType);

  // mentors config
  selectable = false;
  removable = false;
  separatorKeyCodes: number[] = [];

  // form data
  filteredMentors$: Observable<User[]>;
  filteredClients$: Observable<Client[]>;
  selectedMentors: User[] = [];
  broker: Broker;
  user: User;
  client: Client;
  selectedTab = 0;

  private brokerMentorSubscription: Subscription;

  // data collections
  private activeBrokers$: Observable<Broker[]>;
  private availableMentors$: Observable<User[]>;
  private allClients$: Observable<Client[]>;
  private originalMentors: User[] = [];

  @ViewChild('mentorsInput', { static: false }) mentorsInput: ElementRef<HTMLInputElement>;
  @ViewChild('payCodeTabs', { static: false }) payCodeTabs: MatTabGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private clientService: ClientService,
    private brokerService: BrokerService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit() {
    const brokerMentors$: Observable<User[]> = this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.setEditMode(false);
          return this.brokerService.getBrokerByDocId(params.get('docId'));
        }),
        switchMap(broker => {
          this.broker = broker;
          // broker found matching docId from URL
          if (this.broker) {
            return this.userService.getUserByDocId(broker.docId);
          } else {
            this.openSnackBar('Broker Not Found', 'DISMISS', false);
            // navigate to Brokers list view
            this.router.navigate(['/brokers']);
          }
        }),
        switchMap(user => {
          this.user = user;
          if (this.broker.mentors.length > 0) {
            return combineLatest(this.broker.mentors.map(mentorDocId => this.userService.getUserByDocId(mentorDocId)));
          } else {
            return of(undefined);
          }
        }),
        switchMap(mentors => {
          if (mentors) {
            this.selectedMentors = mentors;
            this.originalMentors = JSON.parse(JSON.stringify(mentors));
          }
          if (this.broker.clientDocId) {
            return this.clientService.getClient(this.broker.clientDocId);
          }
          return of(undefined);
        }),
        tap(client => {
          this.client = client;
        }),
      );

    this.brokerMentorSubscription = brokerMentors$.subscribe(() => {
      this.mapRetrievedBroker();
      this.brokerForm.disable();
    }, err => {
      this.errorMessage = 'This broker either does not exist or you do not have permission to view the information.';
      console.error('Error occurred fetching broker and user info: ' + JSON.stringify(err));
    });
  }

  ngOnDestroy() {
    if (this.brokerMentorSubscription) {
      this.brokerMentorSubscription.unsubscribe();
    }
  }

  get payCodeForms() {
    return this.brokerForm.get('payCodes') as FormArray;
  }

  /**
   * Does the logged in user have access to view the broker's mentors
   */
  canShowMentors() {
    if (this.broker) {
      return this.userIsBrokerOrBrokerAdmin() || this.userIsBrokerMentor();
    }
    return false;
  }

  /**
   * Does the logged in user have access to modify any broker data
   */
  canUserEditBroker() {
    if (this.broker) {
      return this.userHasComplianceApprover() || (this.broker.status === Status.ACTIVE && this.userHasBrokerAdmin());
    }
    return false;
  }

  /**
   * Sets editMode and enables or disables broker form controls
   */
  setEditMode(mode: boolean) {
    this.editMode = mode;
    if (this.editMode) {
      const status = this.broker.status;
      // only ComplianceApprover can update broker status
      if (this.userHasComplianceApprover()) {
        this.brokerForm.get('status').enable();
      }
      // only ComplianceApprover can update broker type and only when in an ACTIVE status
      // prevent type update if non-employeee broker with stub, non-registered User
      if (status === Status.ACTIVE && this.broker.type !== BrokerType.NON_EMPLOYEE && this.userHasComplianceApprover()) {
        this.brokerForm.get('type').enable();
      }
      // only BrokerAdmin can update broker mentors and only when in an ACTIVE status
      if (status === Status.ACTIVE && this.userHasBrokerAdmin()) {
        this.brokerForm.get('mentors').enable();
        // get available mentors for edit broker
        this.prepForMentorSelection();
        this.selectable = true;
        this.removable = true;
      }
      // only BrokerAdmin or ComplianceApprover can update address and only when in an ACTIVE status
      if (status === Status.ACTIVE && (this.userHasBrokerAdmin() || this.userHasComplianceApprover())) {
        this.brokerForm.get('address').enable();
      }
      // only BrokerAdmin or ComplianceApprover can update broker client
      if (status === Status.ACTIVE && (this.userHasBrokerAdmin() || this.userHasComplianceApprover())) {
        this.brokerForm.get('client').enable();
        this.prepForClientSelection();
      }
      this.payCodeForms.enable();
    } else {
      this.brokerForm.disable();
      this.selectable = false;
      this.removable = false;
    }
    this.brokerForm.markAsPristine();
  }

  /**
   * Resets the broker form
   * Sets editMode to false and reloads the form with original values retrieved from Firestore for the current broker
   */
  reset() {
    this.setEditMode(false);
    this.createMode = false;
    this.selectedMentors = JSON.parse(JSON.stringify(this.originalMentors));
    // map data for current broker to values in the broker form
    this.brokerForm.reset(
      {
        status: this.broker.status,
        type: this.broker.type,
        mentors: this.selectedMentors,
        client: this.client,
        payCodes: this.broker.payCodes,
        address: {
          street1: this.broker.address.street1,
          street2: this.broker.address.street2,
          city: this.broker.address.city,
          region: this.broker.address.region,
          postalCode: this.broker.address.postalCode,
          country: this.broker.address.country
        }
      }

    );
    while (this.payCodeForms.length) {
      this.payCodeForms.removeAt(0);
    }
    if (this.broker.payCodes) {
      this.broker.payCodes.forEach(payCode => {
        const splits = [];
        if (payCode.payCodeSplits.length > 0) {
          payCode.payCodeSplits.forEach(payCodeSplit => {
            const splitForm = this.formBuilder.group({
              brokerUser: {},
              brokerDocId: payCodeSplit.brokerDocId,
              brokerName: payCodeSplit.brokerName,
              payCodeId: payCodeSplit.payCodeId,
              investorCommissionPercent:
                (payCodeSplit.investorCommissionPercent * 100)
                  .toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 8 }),
              investorOverridePercent:
                (payCodeSplit.investorOverridePercent * 100)
                  .toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 8 }),
              mentoringPercent:
                (payCodeSplit.mentoringPercent * 100)
                  .toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 8 }),
              apCommissionPercent:
                payCodeSplit.apCommissionPercent ?
                  (payCodeSplit.apCommissionPercent * 100)
                    .toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 8 }) : 0
            });
            // ensure the split of the main broker is first in the formArray
            if (splitForm.controls.brokerDocId.value === this.broker.docId) {
              splits.unshift(splitForm);
            } else {
              splits.push(splitForm);
            }
          });
        }

        const payCodeForm = this.formBuilder.group({
          id: payCode.id,
          isActive: payCode.isActive ? 'ACTIVE' : 'INACTIVE',
          glAdjustmentTradeCommissionAccount: payCode.glAdjustmentTradeCommissionAccount,
          glContractsAccount: payCode.glContractsAccount,
          glGrossCommissionAccount: payCode.glGrossCommissionAccount,
          glMentoringAccount: payCode.glMentoringAccount,
          glOverrideAccount: payCode.glOverrideAccount,
          glRetentionAccount: payCode.glRetentionAccount,
          comments: payCode.comments,
          payDivision: payCode.payDivision,
          payCodeSplits: this.formBuilder.array(splits)
        });
        payCodeForm.setValidators(this.splitValidator);
        this.payCodeForms.push(payCodeForm);
      });
    }
    this.payCodeForms.markAsPristine();
    this.brokerForm.markAsPristine();

  }

  /**
   * Called on submit of updated Broker form
   * Maps all values from broker form for existing broker and updates broker in Firestore
   */
  submit() {
    this.updateComplete = false;
    this.setEditMode(false);
    this.createMode = false;
    const formValues = this.brokerForm.getRawValue();
    this.broker.type = formValues.type;
    this.broker.mentors = this.selectedMentors.map(mentor => mentor.docId);
    if (this.broker.type === BrokerType.NON_EMPLOYEE && formValues.client) {
      this.broker.clientDocId = formValues.client.docId;
    }
    this.broker.address.street1 = formValues.address.street1;
    this.broker.address.street2 = formValues.address.street2 || '';
    this.broker.address.city = formValues.address.city;
    this.broker.address.region = formValues.address.region;
    this.broker.address.postalCode = formValues.address.postalCode;
    this.broker.address.country = formValues.address.country;

    // update payCode information if user has access
    if (this.userHasAccounting()) {
      const payCodes = [];
      if (this.payCodeForms.controls) {
        this.payCodeForms.controls.forEach(form => {
          const payCodeSplits = [];
          form.get('payCodeSplits').value.forEach(payCodeSplitForm => {
            const split = {} as PayCodeSplit;
            split.brokerDocId = payCodeSplitForm.brokerUser.docId;
            split.brokerName = payCodeSplitForm.brokerUser.firstName + ' ' + payCodeSplitForm.brokerUser.lastName;
            split.investorCommissionPercent = payCodeSplitForm.investorCommissionPercent ?
              parseFloat((parseFloat(payCodeSplitForm.investorCommissionPercent) / 100).toFixed(10)) : 0;
            split.investorOverridePercent = payCodeSplitForm.investorOverridePercent ?
              parseFloat((parseFloat(payCodeSplitForm.investorOverridePercent) / 100).toFixed(10)) : 0;
            split.mentoringPercent = payCodeSplitForm.mentoringPercent ?
              parseFloat((parseFloat(payCodeSplitForm.mentoringPercent) / 100).toFixed(10)) : 0;
            split.apCommissionPercent = payCodeSplitForm.apCommissionPercent ?
              parseFloat((parseFloat(payCodeSplitForm.apCommissionPercent) / 100).toFixed(10)) : 0;
            split.payCodeId = payCodeSplitForm.payCodeId;
            payCodeSplits.push(split);
          });

          const payCode: PayCode = {
            id: form.get('id').value,
            payDivision: form.get('payDivision').value,
            glGrossCommissionAccount: form.get('glGrossCommissionAccount').value,
            glRetentionAccount: form.get('glRetentionAccount').value,
            glAdjustmentTradeCommissionAccount: form.get('glAdjustmentTradeCommissionAccount').value,
            glMentoringAccount: form.get('glMentoringAccount').value,
            glOverrideAccount: form.get('glOverrideAccount').value,
            glContractsAccount: form.get('glContractsAccount').value,
            comments: form.get('comments').value,
            isActive: form.get('isActive').value === 'ACTIVE' ? true : false,
            payCodeSplits
          };
          payCodes.push(payCode);
        });
        this.broker.payCodes = payCodes;
      }
    }

    if (formValues.status === Status.ACTIVE && this.broker.status !== Status.ACTIVE) {
      this.broker.activationDate = new Date().toISOString();
    }
    if (formValues.status === Status.INACTIVE && this.broker.status !== Status.INACTIVE) {
      this.broker.inactivationDate = new Date().toISOString();
    }
    this.broker.status = formValues.status;

    // update existing broker data in Firestore
    this.brokerService.updateBroker(this.broker)
      .then(() => {
        this.updateComplete = true;
        this.mapRetrievedBroker(); // Updates the form values with trimmed values after update
        this.openSnackBar('Broker successfully updated', 'DISMISS', true);
      })
      .catch(err => {
        this.updateComplete = true;
        console.error('Broker update failed: ' + JSON.stringify(err));
        let errorMessage = '';
        switch (err.code) {
          case 'permission-denied':
            errorMessage = 'Insufficient permissions';
            break;
          default:
            errorMessage = 'Unknown error occurred';
        }
        this.openSnackBar('Broker update failed: ' + errorMessage, 'DISMISS', false);
      });
  }

  /**
   * Displays "lastName, firstName" for mentor autocomplete input field
   */
  displayMentor(user?: User): string | undefined {
    return user ? `${user.lastName}, ${user.firstName}` : undefined;
  }

  /**
   * Displays name, city, and region for client autocomplete input field
   */
  displayClient(client?: Client) {
    if (client) {
      return client.physicalAddress ? `${client.name} (${client.physicalAddress.city}, ${client.physicalAddress.region})` : client.name;
    }
    return '';
  }

  /**
   * Adds a new mentor to the list of broker mentors.
   * Prevents the addition of duplicate mentors or the current broker.
   */
  addMentor() {
    const mentor = this.brokerForm.value.mentors;
    // if mentor is not already in the array, push added mentor
    if (!this.selectedMentors.find(selectedMentor => selectedMentor.docId === mentor.docId)) {
      this.selectedMentors.push(mentor);
    }
    this.brokerForm.get('mentors').setValue('');
    this.mentorsInput.nativeElement.value = '';
  }

  /**
   * Removes a selected mentor from the list of broker mentors.
   */
  removeMentor(mentor: User) {
    const index = this.selectedMentors.indexOf(mentor);
    if (index >= 0) {
      this.selectedMentors.splice(index, 1);
    }
    this.brokerForm.get('mentors').setValue('');
    this.brokerForm.get('mentors').markAsDirty();
  }

  /**
   * Removes the client from the broker
   */
  removeClient() {
    this.brokerForm.get('client').setValue('');
    this.brokerForm.get('client').markAsDirty();
  }

  navigateBrokerRelationships() {
    this.router.navigate(['/brokerrelationships'], { queryParams: { broker: this.broker.docId } });
  }

  tabChanged = (newTabIndex: number): void => {
    this.selectedTab = newTabIndex;
  }

  addPayCode() {
    this.createMode = true;
    const newPayCodeForm = this.formBuilder.group({
      id: [, [Validators.minLength(3), Validators.maxLength(6)]],
      isActive: [],
      glAdjustmentTradeCommissionAccount: [, [Validators.minLength(9), Validators.maxLength(9)]],
      glContractsAccount: [, [Validators.minLength(9), Validators.maxLength(9)]],
      glGrossCommissionAccount: [, [Validators.minLength(9), Validators.maxLength(9)]],
      glMentoringAccount: [, [Validators.minLength(9), Validators.maxLength(9)]],
      glOverrideAccount: [, [Validators.minLength(9), Validators.maxLength(9)]],
      glRetentionAccount: [, [Validators.minLength(9), Validators.maxLength(9)]],
      comments: [],
      payDivision: [''],
      payCodeSplits: this.formBuilder.array([])
    });
    newPayCodeForm.setValidators(this.splitValidator);
    this.payCodeForms.push(newPayCodeForm);
    // place new empty payCodeForm at front of list
    this.payCodeForms.controls.unshift(this.payCodeForms.controls.pop());
    this.changeDetector.detectChanges();
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  // Does the logged in user have the ComplianceApprover role
  private userHasComplianceApprover(): boolean {
    return this.authzService.currentUserHasRole(COMPLIANCE_APPROVER_ROLE);
  }

  // Does the logged in user have the accounting role
  userHasAccounting(): boolean {
    return this.authzService.currentUserHasRole(ACCOUNTING_ADMIN_ROLE);
  }

  userIsBroker(): boolean {
    return this.authService.userProfile.app_metadata.firestoreDocId === this.broker.docId;
  }

  userHasBrokerCodeViewerRole(): boolean {
    return this.authzService.currentUserHasRole(BROKER_CODE_VIEWER_ROLE);
  }

  // Does the logged in user have the BrokerAdmin role
  private userHasBrokerAdmin(): boolean {
    return this.authzService.currentUserHasRole(BROKER_ADMIN_ROLE);
  }

  // Does the logged in user have the BrokerAdmin role or is the logged in user the current broker
  private userIsBrokerOrBrokerAdmin(): boolean {
    return this.userHasBrokerAdmin()
      || (this.user && this.user.docId === this.authService.userProfile.app_metadata.firestoreDocId);
  }

  // Is the logged in user a mentor of the existing broker
  private userIsBrokerMentor(): boolean {
    const currentUser = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.originalMentors.find(mentor => mentor.docId === currentUser) ? true : false;
  }

  // Gets available mentors (active brokers) for mentor autocomplete
  private prepForMentorSelection() {
    if (!this.activeBrokers$) {
      this.activeBrokers$ = this.brokerService.getActiveBrokers();
    }
    this.mapBrokersToMentors();
    this.filterMentors();
  }

  private prepForClientSelection() {
    this.getAllClients();
    this.filterClients();
  }

  // Gets all clients from Firestore
  private getAllClients() {
    if (!this.allClients$) {
      this.allClients$ = this.clientService.getAllClients()
        .pipe(
          shareReplay(1)
        );
    }
  }

  // Maps all data retrieved from Firestore for current broker to values in the broker form
  private mapRetrievedBroker() {
    this.brokerForm.get('status').setValue(this.broker.status);
    this.brokerForm.get('type').setValue(this.broker.type);
    this.brokerForm.get('mentors').setValue(this.selectedMentors);
    this.brokerForm.get('client').setValue(this.client);
    this.brokerForm.get('address').get('street1').setValue(this.broker.address.street1);
    this.brokerForm.get('address').get('street2').setValue(this.broker.address.street2);
    this.brokerForm.get('address').get('city').setValue(this.broker.address.city);
    this.brokerForm.get('address').get('region').setValue(this.broker.address.region);
    this.brokerForm.get('address').get('postalCode').setValue(this.broker.address.postalCode);
    this.brokerForm.get('address').get('country').setValue(this.broker.address.country);
    if (this.broker.payCodes) {
      while (this.payCodeForms.length) {
        this.payCodeForms.removeAt(0);
      }
      this.broker.payCodes.forEach(payCode => {
        const splits = [];
        if (payCode.payCodeSplits.length > 0) {
          payCode.payCodeSplits.forEach(payCodeSplit => {
            const splitForm = this.formBuilder.group({
              brokerUser: payCodeSplit['brokerUser'],
              brokerDocId: payCodeSplit.brokerDocId,
              brokerName: payCodeSplit.brokerName,
              payCodeId: payCodeSplit.payCodeId,
              investorCommissionPercent:
                [(payCodeSplit.investorCommissionPercent * 100)
                  .toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 8 })],
              investorOverridePercent:
                (payCodeSplit.investorOverridePercent * 100)
                  .toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 8 }),
              mentoringPercent:
                (payCodeSplit.mentoringPercent * 100)
                  .toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 8 }),
              apCommissionPercent:
                payCodeSplit.apCommissionPercent ?
                  (payCodeSplit.apCommissionPercent * 100)
                    .toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 8 }) : 0,
            });
            // ensure the split of the main broker is first in the formArray
            if (splitForm.controls.brokerDocId.value === this.broker.docId) {
              splits.unshift(splitForm);
            } else {
              splits.push(splitForm);
            }
          });
        }
        const payCodeForm = this.formBuilder.group({
          id: [payCode.id, [Validators.minLength(3), Validators.maxLength(6)]],
          isActive: payCode.isActive ? 'ACTIVE' : 'INACTIVE',
          glAdjustmentTradeCommissionAccount:
            [payCode.glAdjustmentTradeCommissionAccount, [Validators.minLength(9), Validators.maxLength(9)]],
          glContractsAccount: [payCode.glContractsAccount, [Validators.minLength(9), Validators.maxLength(9)]],
          glGrossCommissionAccount: [payCode.glGrossCommissionAccount, [Validators.minLength(9), Validators.maxLength(9)]],
          glMentoringAccount: [payCode.glMentoringAccount, [Validators.minLength(9), Validators.maxLength(9)]],
          glOverrideAccount: [payCode.glOverrideAccount, [Validators.minLength(9), Validators.maxLength(9)]],
          glRetentionAccount: [payCode.glRetentionAccount, [Validators.minLength(9), Validators.maxLength(9)]],
          comments: payCode.comments,
          payDivision: payCode.payDivision,
          payCodeSplits: this.formBuilder.array(splits)
        });
        payCodeForm.setValidators(this.splitValidator);
        this.payCodeForms.push(payCodeForm);
      });
    }
  }

  // Populates an Observable<User[]> that includes all active brokers
  private mapBrokersToMentors() {
    this.availableMentors$ = this.activeBrokers$.pipe(
      switchMap(brokers => {
        return combineLatest(brokers.map(broker => this.userService.getUserByDocId(broker.docId)));
      })
    );
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting mentor brokers
  private filterMentors() {
    this.filteredMentors$ = this.brokerForm.controls.mentors.valueChanges
      .pipe(
        debounceTime(300),
        startWith<string | User>(''),
        switchMap(term => typeof term === 'string' ? this.filterMentorsBySearchTerm(term) : of([term]))
      );
  }

  // Filters possible mentors (active brokers from Firestore) using the user input search term
  private filterMentorsBySearchTerm(searchTerm: string): Observable<User[]> {
    return this.availableMentors$
      .pipe(
        map(mentors => mentors.filter(
          mentor => this.selectedMentors.find(selectedMentor => selectedMentor.docId === mentor.docId) ? false : true)),
        map(mentors => mentors.filter(mentor => this.matchName(searchTerm, mentor)))
      );
  }

  // Checks for a partial match on user/mentor first or last name
  private matchName(searchTerm: string, user: User) {
    const searchValue = searchTerm.toLowerCase();
    const first = user.firstName.toLowerCase();
    const last = user.lastName.toLowerCase();

    const fullName = `${first} ${last}`;
    const lastFirst = `${last} ${first}`;
    const lastCommaFirst = `${last}, ${first}`;
    return fullName.includes(searchValue) || lastFirst.includes(searchValue) || lastCommaFirst.includes(searchValue);
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting a client for the broker
  private filterClients() {
    this.filteredClients$ = this.brokerForm.controls.client.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        startWith<string | Client>(''),
        switchMap(term => typeof term === 'string' ? this.filterClientsBySearchTerm(term) : of([term]))
      );
  }

  // Filters clients from Firestore using the user input search term
  private filterClientsBySearchTerm(searchTerm: string): Observable<Client[]> {
    return this.allClients$
      .pipe(
        map(clients => clients.filter(client => client.name.toLowerCase().includes(searchTerm.toLowerCase())))
      );
  }

  private splitValidator(payCodeForm: FormGroup) {
    let splitTotal = 0.00;
    const payCodeSplits = payCodeForm.get('payCodeSplits').value;

    payCodeSplits.forEach(payCodeSplit => {
      const split = Number(payCodeSplit.investorCommissionPercent) +
        Number(payCodeSplit.investorOverridePercent) +
        Number(payCodeSplit.apCommissionPercent) +
        Number(payCodeSplit.mentoringPercent);
      splitTotal += split;
    });
    return splitTotal <= 100.00 ? null : { invalidSplits: true };
  }
}
