<div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Contact Details</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title" *ngIf="createMode">{{ (client$ | async)?.name }} - New Contact</h2>
      <h2 class="card-title" *ngIf="!createMode && contact$ | async as contact">{{ (client$ | async)?.name }} - {{ contact.firstName }} {{ contact.lastName }}</h2>
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
        <button aria-label="Edit Contact" *ngIf="!editMode && !createMode && updateComplete && canUpdate" (click)="setEditMode(true)" mat-mini-fab color="accent" matTooltip="Edit Contact">
          <mat-icon>edit</mat-icon>
        </button>
        <button aria-label="Update Contact" *ngIf="!createMode && editMode && updateComplete" [disabled]="!contactForm.valid || contactForm.pristine" (click)="saveForm()" mat-mini-fab color="accent" id="contact-detail-save" matTooltip="Update Contact">
          <mat-icon>done</mat-icon>
        </button>
        <button aria-label="Create Contact" *ngIf="createMode && canUpdate && updateComplete" [disabled]="!contactForm.valid" (click)="saveForm()" mat-mini-fab color="accent" matTooltip="Create Contact" id="contact-detail-save">
          <mat-icon>done</mat-icon>
        </button>
        <button aria-label="Delete Contact" *ngIf="!createMode && editMode && updateComplete" (click)="deleteContactMessage(contact)" mat-mini-fab color="warn" id="contact-detail-clear" matTooltip="Delete Contact" id="contact-detail-delete">
          <mat-icon>delete</mat-icon>
        </button>
        <button aria-label="Discard Changes" *ngIf="updateComplete && editMode && !createMode" (click)="setEditMode(false)" mat-mini-fab color="" id="contact-detail-clear" matTooltip="Cancel Changes">
          <mat-icon>clear</mat-icon>
        </button>
        <button aria-label="Discard Changes" *ngIf="createMode && !contactForm.pristine && updateComplete" (click)="reset()" mat-mini-fab color="" id="contact-detail-clear" matTooltip="Reset Form">
          <mat-icon>clear</mat-icon>
        </button>
        </div>
    </div>
    <form *ngIf="!errorMessage" [formGroup]="contactForm" (ngSubmit)="saveForm()" fxLayout="column">
      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="100" fxFlex.gt-xs="45" label="First Name" data="{{ contactForm.get('firstName').value }}"></ati-labeled-data>
        <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45">
          <input required matInput placeholder="First Name" formControlName="firstName" trim="blur">
          <mat-error *ngIf="contactForm.get('firstName').invalid">{{getErrorMessage(contactForm.get('firstName'))}}</mat-error>
        </mat-form-field>
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="100" fxFlex.gt-xs="45" label="Last Name" data="{{ contactForm.get('lastName').value }}"></ati-labeled-data>
        <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45">
          <input required matInput placeholder="Last Name" formControlName="lastName" trim="blur">
          <mat-error *ngIf="contactForm.get('lastName').invalid">{{getErrorMessage(contactForm.get('lastName'))}}</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="100" fxFlex.gt-xs="45" label="Title" data="{{ contactForm.get('title').value }}"></ati-labeled-data>
        <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45">
          <input matInput placeholder="Title" formControlName="title" trim="blur">
          <mat-error *ngIf="contactForm.get('title').invalid">{{getErrorMessage(contactForm.get('title'))}}</mat-error>
        </mat-form-field>
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="100" fxFlex.gt-xs="45" label="Email" data="{{ contactForm.get('email').value }}"></ati-labeled-data>
        <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45">
          <input matInput placeholder="Email" formControlName="email" trim="blur">
          <mat-error *ngIf="contactForm.get('email').invalid">{{getErrorMessage(contactForm.get('email'))}}</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="100" fxFlex.gt-xs="45" label="Birth Date (MM-DD)" data="{{ displayDate(contactForm.get('birthDate').value) }}"></ati-labeled-data>
        <mat-form-field *ngIf="editMode" atomBirthDatePicker fxFlex.xs="100" fxFlex.gt-xs="45">
          <input matInput [matDatepicker]="birthDatePicker" placeholder="Birth Date (MM-DD)" formControlName="birthDate" (dateChange)="selectBirthDate($event)">
          <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #birthDatePicker startView="year"></mat-datepicker>
          <mat-error *ngIf="contactForm.get('birthDate').invalid">{{getErrorMessage(contactForm.get('birthDate'))}}</mat-error>
        </mat-form-field>
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="100" fxFlex.gt-xs="45" label="Speed Dial" data="{{ displaySpeedDial(contactForm.get('speedDial').value) }}"></ati-labeled-data>
        <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45">
          <input matInput placeholder="Speed Dial" formControlName="speedDial" trim="blur" prefix="*" mask="0009" [specialCharacters]="[]">
          <mat-error *ngIf="contactForm.get('speedDial').invalid">{{getErrorMessage(contactForm.get('speedDial'))}}</mat-error>
        </mat-form-field>
      </div>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title required>Phone Numbers</mat-panel-title>
        </mat-expansion-panel-header>
        <ati-phone-number *ngFor="let phoneNumber of phoneNumbers; let index = index" [editMode]="editMode" [phoneNumber]="phoneNumber" (numberUpdated)="phoneNumbers[index] = $event; contactForm.markAsDirty()" (numberRemoved)="onPhoneNumberRemoved(index)"></ati-phone-number>
        <ati-phone-number *ngIf="editMode" [editMode]="editMode" (numberUpdated)="onPhoneNumberCreated($event)"></ati-phone-number>
      </mat-expansion-panel>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
        <h4>Address</h4>
        <button *ngIf="!contact?.address" class="address-btn" [disabled]="contactForm.disabled" id="enable-contact-address" type="button" aria-label="Show/Hide Address" [color]="!enableAddress ? 'primary' : 'accent'" mat-icon-button (click)="toggleAddressForm()" matTooltip="Show/Hide Address">
          <mat-icon>{{!enableAddress ? 'add_circle' : 'cancel'}}</mat-icon>
        </button>
        <button *ngIf="!createMode && editMode && contact?.address && !deleteAddress" class="address-btn" [disabled]="contactForm.disabled" id="delete-contact-address" type="button" aria-label="Delete Address" color="warn" mat-icon-button (click)="clearAddress()" matTooltip="Delete Address">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <ati-address *ngIf="enableAddress || contact?.address" [addressForm]="contactForm.get('address')"></ati-address>
      <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="100" fxFlex.gt-xs="45" label="Additional notes" data="{{ contactForm.get('additionalNotes').value }}"></ati-labeled-data>
        <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45">
          <input matInput placeholder="Additional Notes" formControlName="additionalNotes" trim ="blur">
          <mat-error *ngIf="contactForm.get('additionalNotes').invalid">{{getErrorMessage(contactForm.get('additionalNotes'))}}</mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-card>
</div>
