import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit,
         Input, SimpleChanges, ViewChild, OnChanges, Output, OnDestroy, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';

import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ObservableDataSource, StorageService } from '@advance-trading/angular-common-services';
import { Account, BrokerRelationship, Client, FundsTransfer, PodEnum, Status, User } from '@advance-trading/ops-data-lib';
import { ExportService } from '../services/export.service';
import { AccountService, BrokerRelationshipService, UserService } from '@advance-trading/angular-ops-data';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';

const ACCOUNT_ADMIN_ROLE = 'AccountAdmin';
const COMPLIANCE_APPROVER_ROLE = 'ComplianceApproverRole';
const PAGE_SIZE_KEY = 'atom.accountsPageSize';

interface AccountWithBrokers extends Account {
  brokerNames?: string;
}

@Component({
  selector: 'atom-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css'],
  providers: [BreakpointObserver]
})
export class AccountsComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {

  accountMoveForm: FormGroup = this.formBuilder.group({
    brokerCode: ['', [Validators.minLength(6), Validators.required]],
    managingPod: ['', Validators.required],
    leadBroker: [{value: '', disabled: true}, Validators.required]
  });

  columnsToDisplay = [];
  errorMessage: string;
  dataSource = new ObservableDataSource<Account>();
  filterValue = new FormControl();
  isLoading = true;
  exportable = false;
  editMode = false;
  updateComplete = true;
  podNames = Object.keys(PodEnum);
  brokers$: Observable<User[]>;
  filteredBrokers$: Observable<User[]>;
  filteredBrokerRels$: Observable<BrokerRelationship[]>;
  accountsToMove: Account[] = [];

  private activeBrokerRels$: Observable<BrokerRelationship[]>;
  private activeBrokers$: Observable<User[]>;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private client: Client;
  private tableState: { [key: string]: string | number } = {};

  @Input() selectedAccounts$: Observable<Account[]>;
  @Input() initialTableState: { [key: string]: string | number };

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  @Output() accountListChange: EventEmitter<any> = new EventEmitter();
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private accountService: AccountService,
    private authzService: Auth0AuthzService,
    private breakpointObserver: BreakpointObserver,
    private brokerRelService: BrokerRelationshipService,
    private formBuilder: FormBuilder,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    public exportService: ExportService,
    private snackBar: MatSnackBar,
    private storageService: StorageService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.isSearching.emit(true);
    this.filterValue.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((filter: string) => {
      if (filter) {
        this.tableState.filter = filter.trim();
        this.accountListChange.emit(this.tableState);

      } else if (this.tableState.filter) {
        delete this.tableState.filter;
        this.accountListChange.emit(this.tableState);
      }

    });

    this.breakpointObserver.observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (state.matches) {
          this.columnsToDisplay = [
            'name', 'number'
          ];
        } else {
          this.columnsToDisplay = [
            'name', 'number', 'brokerCode', 'brokerNames', 'managingPod', 'commissionRateCode', 'purpose', 'fundsTransfer', 'status', 'activationDate'
          ];
        }
      });

    this.onBrokerRelChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    this.prepForBrokerRelSelection();
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = this.storageService.localStorage.get(PAGE_SIZE_KEY).value() || 10;
    this.dataSource.sort = this.sort;

    if (this.filter) {
      this.filter.nativeElement.focus();
    }
    this.changeDetector.detectChanges();
  }

  get accountAdmin() {
    return this.authzService.currentUserHasRole(ACCOUNT_ADMIN_ROLE);
  }

  get complianceApprover() {
    return this.authzService.currentUserHasRole(COMPLIANCE_APPROVER_ROLE);
  }

  get canMoveAccounts() {
    return this.accountAdmin || this.complianceApprover;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialTableState'] && changes['selectedAccounts$']) {
      this.tableState = Object.assign({}, this.initialTableState);
      this.changeDetector.detectChanges();

      const sortDir = this.initialTableState.sortDir as SortDirection;
      const sortColName = this.initialTableState.sortColName as string;
      if (sortDir && sortColName) {
        this.sort.direction = sortDir;
        this.sort.active = sortColName;
      }

      if (this.initialTableState.filter) {
        this.filterValue.setValue(this.initialTableState.filter);
        this.applyFilter(this.filterValue.value);
      }

      this.dataSource.data$ = this.getAccountBrokers()
        .pipe(
          tap((accounts) => {
            this.isSearching.emit(false);
            this.exportable = accounts.length > 0;

            const pageIndex = this.initialTableState.pageIndex as number;
            const pageSize = this.initialTableState.pageSize as number;

            if (pageIndex !== undefined) {
              this.paginator.pageIndex = pageIndex;
            }
            if (pageSize !== undefined) {
              this.paginator.pageSize = pageSize;
            }
          }),
          catchError(err => {
            this.isSearching.emit(false);
            this.errorMessage = 'Error retrieving accounts; please try again later';
            console.error(`Error retrieving accounts: ${err}`);
            return of([]);
          })
        );
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  addNewAccount() {
    if (this.client) {
      this.router.navigate(['/accounts/new'], { queryParams: { clientDocId: this.client.docId } });
    } else {
      this.router.navigate(['/accounts/new']);
    }
  }

  selectAccount(account: Account) {
    this.router.navigate(['/accounts', account.docId]);
  }

  handleSortChange() {
    this.tableState.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.tableState.sortColName = this.tableState.sortDir ? this.sort.active : undefined;
    this.accountListChange.emit(this.tableState);
  }

  handlePageChange() {
    this.storageService.localStorage.set(PAGE_SIZE_KEY, this.paginator.pageSize);
    this.tableState.pageIndex = this.paginator.pageIndex;
    this.accountListChange.emit(this.tableState);
  }

  displayBrokerCode(account: Account): string {
    return account.brokerRelSecondaryLabel ? `${account.brokerCode} ${account.brokerRelSecondaryLabel}` : account.brokerCode;
  }

  displayBroker(broker: User) {
    if (broker) {
      return broker.firstName + ' ' + broker.lastName;
    }
    return '';
  }

  displayBrokerRel(brokerRel?: BrokerRelationship) {
    if (brokerRel && brokerRel.brokerCode) {
      return brokerRel.secondaryLabel ? `${brokerRel.brokerCode} ${brokerRel.secondaryLabel}` : brokerRel.brokerCode;
    }
    return brokerRel || '';
  }

  setEditMode(mode: boolean) {
    this.editMode = mode;
    const moveIncluded = this.columnsToDisplay.includes('move');
    if (this.editMode && !moveIncluded) {
      this.columnsToDisplay = this.columnsToDisplay.concat(['move']);
    } else if (moveIncluded) {
      this.columnsToDisplay.pop();
    }
    this.accountMoveForm.markAsPristine();
  }

  submit() {
    this.updateComplete = false;
    Promise.all(
      this.accountsToMove.map((account: Account) => {
        const leadBroker: User = this.accountMoveForm.get('leadBroker').value;
        const brokerRel: BrokerRelationship = this.accountMoveForm.get('brokerCode').value;
        const managingPod = this.accountMoveForm.get('managingPod').value;

        account.brokerCode = brokerRel.brokerCode;
        account.brokerRelDocId = brokerRel.docId;
        account.leadBrokerDocId = leadBroker.docId;
        account.managingPod = managingPod;
        return this.accountService.updateAccount(account);
      })
    ).then(response => {
      console.log('Accounts successfully updated');
      this.setEditMode(false);
      this.updateComplete = true;
      this.accountsToMove = [];
      this.accountMoveForm.reset();
      this.openSnackBar('Accounts successfully updated', 'DISMISS', true);
      return response;
    }).catch(err => {
      this.updateComplete = true;
      this.setEditMode(false);
      console.error('Account updates failed: ' + JSON.stringify(err));
      let errorMessage = '';
      switch (err.code) {
        case 'permission-denied':
          errorMessage = 'Insufficient permissions';
          break;
        default:
          errorMessage = 'Unknown error occurred';
      }
      this.openSnackBar('Account updates failed: ' + errorMessage, 'DISMISS', false);
    });

    this.accountMoveForm.markAsPristine();
  }

  reset() {
    this.setEditMode(false);
    this.accountsToMove = [];
    this.accountMoveForm.reset();
    this.accountMoveForm.markAsPristine();
  }

  prepareAccountMove($event: MatCheckboxChange, account: Account) {
    if ($event.checked && account.status === Status.ACTIVE) {
      this.accountsToMove.push(account);
    } else {
      this.accountsToMove = this.accountsToMove.filter(acct => acct.docId !== account.docId);
    }
  }

  isChecked(account: Account) {
    return this.accountsToMove.includes(account);
  }

  displayFundsTransferType(fundsTransfer: FundsTransfer) {
    if (fundsTransfer) {
      switch (fundsTransfer.type) {
        case 'WIRE':
          return 'Wire';
        case 'ACH_PULL':
          return 'ACH Pull';
        case 'CHECK':
          return 'Check';
        default:
          return '-';
      }
    }
    return '';
  }

  private openSnackBar(message: string, action?: string, success=true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private onBrokerRelChanges() {
    this.accountMoveForm.get('brokerCode').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if (this.editMode && value.docId && this.accountMoveForm.get('brokerCode').dirty) {
        this.accountMoveForm.get('leadBroker').enable();
        this.accountMoveForm.get('leadBroker').setValue('');
        this.prepForLeadBrokerSelection();
      } else {
        this.accountMoveForm.get('leadBroker').disable();
      }
    });
  }

  // ******* Broker relationship autocomplete *******

  private prepForBrokerRelSelection() {
    this.getActiveBrokerRels();
    this.filterBrokerRels();
  }

  // Gets active broker relationships from Firestore
  private getActiveBrokerRels() {
    if (!this.activeBrokerRels$) {
      this.activeBrokerRels$ = this.brokerRelService.getBrokerRelationshipsByStatus(Status.ACTIVE)
        .pipe(
          shareReplay(1),
        );
    }
  }

  // Consumes a stream of input changes from the form to populate a list of matching autocomplete
  // options for selecting a broker relationship
  private filterBrokerRels() {
    this.filteredBrokerRels$ = this.accountMoveForm.controls.brokerCode.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        startWith<string | BrokerRelationship>(''),
        switchMap(term => typeof term === 'string' ? this.filterBrokerRelsBySearchTerm(term) : of([term]))
      );
  }

  // Filters broker relationships from Firestore using the user input search term
  private filterBrokerRelsBySearchTerm(searchTerm: string): Observable<BrokerRelationship[]> {
    return this.activeBrokerRels$
      .pipe(
        map(brokerRels => brokerRels.filter(brokerRel => this.matchBrokerRel(searchTerm, brokerRel)))
      );
  }

  // Checks for a partial match on broker code or secondary label
  private matchBrokerRel(searchTerm: string, brokerRel: BrokerRelationship) {
    const searchValue = searchTerm.toLowerCase();
    const brokerCode = brokerRel.brokerCode.toLowerCase();
    const secondaryLabel = brokerRel.secondaryLabel ? brokerRel.secondaryLabel.toLowerCase() : '';
    return brokerCode.includes(searchValue) || secondaryLabel.includes(searchValue);
  }

    // ******* Lead Broker autocomplete *******
    private prepForLeadBrokerSelection() {
      this.getActiveBrokers();
      this.filterBrokers();
    }

    // Gets active brokers from Firestore
    private getActiveBrokers() {
      const brokerRel: BrokerRelationship = this.accountMoveForm.get('brokerCode').value;
      // the 'house' brokerRels have no brokers so will disable lead broker
      if (brokerRel.brokers && brokerRel.brokers.length === 0) {
        this.accountMoveForm.get('leadBroker').disable();
      } else {
        this.accountMoveForm.get('leadBroker').enable();
      }
      this.activeBrokers$ = combineLatest(brokerRel.brokers.map(broker => {
        return this.userService.getUserByDocId(broker);
      }));
    }

    // Consumes a stream of input changes from the form to populate a list of matching autocomplete
    // options for selecting a lead broker
    private filterBrokers() {
      this.filteredBrokers$ = this.accountMoveForm.controls.leadBroker.valueChanges
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
          startWith<string | User>(''),
          switchMap(term => typeof term === 'string' ? this.filterBrokersBySearchTerm(term) : of([term]))
        );
    }

    // Filters Users from Firestore using the user input search term
    private filterBrokersBySearchTerm(searchTerm: string): Observable<User[]> {
      return this.activeBrokers$
        .pipe(
          map(brokers => brokers.filter(broker => this.matchBroker(searchTerm, broker)))
        );
    }

    // Checks for a partial match on brokers first name or last name
    private matchBroker(searchTerm: string, broker: User) {
      const searchValue = searchTerm.toLowerCase();
      const firstName = broker.firstName.toLowerCase();
      const lastName = broker.lastName.toLowerCase();
      return firstName.includes(searchValue) || lastName.includes(searchValue);
    }

    private getAccountBrokers() {
      return this.selectedAccounts$
        .pipe(
          switchMap((accounts: Account[]) => {
            if (!accounts[0]) {
              return of([]);
            }
            // Retrieve User objects for each brokerDocId in brokers
            return combineLatest(accounts.map((account: Account) => {
              if (account.brokers && account.brokers.length) {
                return combineLatest(account.brokers.map((brokerDocId: string) => {
                  return this.userService.getUserByDocId(brokerDocId);
                }))
                .pipe(
                  map((brokers: User[]) => {
                    brokers = brokers.filter(broker => !!broker);
                    const reducer = (names: string, user: User) => `${names}${user.firstName} ${user.lastName}, `;
                    return {
                      ...account,
                      brokerNames: brokers.length ? brokers.reduce(reducer, '').slice(0, -2) : ''
                    } as AccountWithBrokers;
                  })
                );
              } else {
                return of({
                  ...account,
                  brokerNames: ''
                } as AccountWithBrokers);
              }
            }));
          })
        );
    }
}



