import { ValidatorFn, AbstractControl } from '@angular/forms';

// Custom validators
export function invalidDomainValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (!control.value) {
      return null;
    }
    const valid = nameRe.test(control.value);
    return valid ? null : {invalidDomainName: {value: control.value}};
  };
}

function disallowedDomain(emailDomain: string) {
  if (typeof emailDomain !== 'string') {
    return false;
  }
  switch (emailDomain.toLowerCase()) {
    case 'gmail.com':
    case 'yahoo.com':
    case 'hotmail.com':
    case 'aol.com':
    case 'outlook.com':
    case 'frontier.com':
    case 'live.com':
    case 'netins.net':
    case 'facebook.com':
    case 'me.com':
    case 'icloud.com':
    case 'mac.com':
      return true;
    default:
      return false;
  }
}

export function forbiddenDomainValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const forbidden = disallowedDomain(control.value);
    return forbidden ? {forbiddenDomainName: {value: control.value}} : null;
  };
}
