<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Broker Request Details</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
        or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title" *ngIf="createMode">New Broker Request</h2>
      <h2 class="card-title" *ngIf="!createMode && brokerRequest">New Broker Candidate: {{ brokerRequest.firstName }} {{ brokerRequest.lastName }}</h2>
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <button id="edit-broker-request" aria-label="Edit Broker Request" *ngIf="!editMode && !createMode && updateComplete && canUserEditBrokerRequest()" (click)="setEditMode(true)" mat-mini-fab color="accent" matTooltip="Edit Broker Request">
          <mat-icon>edit</mat-icon>
        </button>
        <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
        <button id="update-broker-request" aria-label="Update Broker Request" *ngIf="!createMode && editMode && updateComplete" [disabled]="!brokerRequestForm.valid || brokerRequestForm.pristine" (click)="submit()" mat-mini-fab color="accent" matTooltip="Update Broker Request">
          <mat-icon>done</mat-icon>
        </button>
        <button id="create-broker-request" aria-label="Create Broker Request" *ngIf="createMode && updateComplete" [disabled]="!brokerRequestForm.valid" (click)="submit()" mat-mini-fab color="accent" matTooltip="Create Broker Request">
          <mat-icon>done</mat-icon>
        </button>
        <button id="cancel-edit-broker-request" aria-label="Discard Changes" *ngIf="updateComplete && editMode && (!createMode || (createMode && !brokerRequestForm.pristine))" (click)="reset()" mat-mini-fab color="" matTooltip="Discard Changes">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <form *ngIf="!errorMessage" [formGroup]="brokerRequestForm" (ngSubmit)="submit()" fxLayout="column">
      <div *ngIf="createMode" fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="10">
        <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="45">
          <input matInput placeholder="First Name" formControlName="firstName" trim="blur" required>
          <mat-error *ngIf="brokerRequestForm.get('firstName').invalid">{{getErrorMessage(brokerRequestForm.get('firstName'))}}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex.xs="100" fxFlex.gt-xs="45" >
          <input matInput placeholder="Last Name" formControlName="lastName" trim="blur" required>
          <mat-error *ngIf="brokerRequestForm.get('lastName').invalid">{{getErrorMessage(brokerRequestForm.get('lastName'))}}</mat-error>
        </mat-form-field>
      </div>
      <ati-labeled-data *ngIf="!editMode" label="Broker Request Status" data="{{ brokerRequestForm.get('status').value | titlecase }}"></ati-labeled-data>
      <mat-radio-group *ngIf="!createMode && editMode" formControlName="status">
        <span class="check-radio-label">Broker Request Status</span>
        <mat-radio-button disabled value="PENDING">Pending</mat-radio-button>
        <mat-radio-button disabled value="APPROVED">Approved</mat-radio-button>
        <mat-radio-button disabled value="DENIED">Denied</mat-radio-button>
      </mat-radio-group>
      <ati-labeled-data *ngIf="!editMode" label="Broker Type" data="{{ brokerRequestForm.get('type').value | replace | titlecase | replace: ' ' : '-' }}"></ati-labeled-data>
      <mat-radio-group *ngIf="editMode" formControlName="type" required>
        <span class="check-radio-label">Broker Type</span>
        <mat-radio-button *ngFor="let type of brokerTypes" [value]="type">{{ type | replace | titlecase | replace: ' ' : '-' }}</mat-radio-button>
      </mat-radio-group>
      <div *ngIf="createMode || canShowMentors()" fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="10">
        <mat-form-field fxFlex="100">
          <mat-chip-list [disabled]="!createMode" #mentorList>
            <mat-chip *ngFor="let selectedMentor of selectedMentors" [disabled]="!createMode" [selectable]="selectable" [removable]="removable" (removed)="removeMentor(selectedMentor)">
              {{ selectedMentor?.firstName }} {{ selectedMentor?.lastName }}
              <mat-icon matChipRemove *ngIf="removable && createMode">cancel</mat-icon>
            </mat-chip>
            <input #mentorsInput [placeholder]="createMode ? 'Select broker mentors' : 'Broker Mentors'" formControlName="mentors" [matAutocomplete]="autoMentor"
              [matChipInputFor]="mentorList" [matChipInputSeparatorKeyCodes]="separatorKeyCodes">
          </mat-chip-list>
          <mat-autocomplete #autoMentor="matAutocomplete" [displayWith]="displayMentor" (optionSelected)="addMentor()">
            <mat-option *ngFor="let mentor of filteredMentors$ | async" [value]="mentor">
              {{ mentor?.firstName }} {{ mentor?.lastName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div *ngIf="brokerRequestForm.get('type').value === 'NON_EMPLOYEE'" fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="10">
        <ati-labeled-data *ngIf="!editMode" label="Client Relationship" data="{{ displayClient(brokerRequestForm.get('client').value) }}"></ati-labeled-data>
        <mat-form-field *ngIf="editMode" fxFlex="100">
          <input matInput placeholder="Client Relationship" formControlName="client" [matAutocomplete]="autoClient">
          <mat-error *ngIf="brokerRequestForm.get('client').invalid">You must select a valid client</mat-error>
          <button mat-button *ngIf="brokerRequestForm.get('client').value && createMode" matSuffix mat-icon-button aria-label="Clear" (click)="brokerRequestForm.get('client').setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-autocomplete #autoClient="matAutocomplete" [displayWith]="displayClient">
          <mat-option *ngFor="let client of filteredClients$ | async" [value]="client">{{displayClient(client)}}</mat-option>
        </mat-autocomplete>
      </div>
      <mat-expansion-panel [expanded]="createMode" class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>Address</mat-panel-title>
        </mat-expansion-panel-header>
        <ati-address [addressForm]="brokerRequestForm.get('address')"></ati-address>
      </mat-expansion-panel>
      <!-- Approval Group Decisions -->
      <div *ngIf="!createMode" fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="10">
        <!-- Display Completed Group Decisions -->
        <div fxFlex="100" *ngIf="brokerRequest?.branchCommitteeDecisionDate" fxLayout="column">
          <span fxFlex="100" class="review">Branch Committee Review</span>
          <span fxFlex="100" class="review-decision">
            <ng-container *ngIf="brokerRequest?.isBranchCommitteeApproved === 'YES'"><mat-icon class="approved-icon">check_circle</mat-icon>Approved</ng-container>
            <ng-container *ngIf="brokerRequest?.isBranchCommitteeApproved === 'NO'"><mat-icon class="denied-icon">cancel</mat-icon>Denied</ng-container>
            ( {{ brokerRequest.branchCommitteeDecisionDate | date:'mediumDate' }} )
          </span>
        </div>
        <div fxFlex="100" *ngIf="brokerRequest?.boardDecisionDate" fxLayout="column">
          <span fxFlex="100" class="review">Board of Directors Review</span>
          <span fxFlex="100" class="review-decision">
            <ng-container *ngIf="brokerRequest?.isBoardApproved === 'YES'"><mat-icon class="approved-icon">check_circle</mat-icon>Approved</ng-container>
            <ng-container *ngIf="brokerRequest?.isBoardApproved === 'NO'"><mat-icon class="denied-icon">cancel</mat-icon>Denied</ng-container>
            ( {{ brokerRequest.boardDecisionDate | date:'mediumDate' }} )
          </span>
        </div>
        <div fxFlex="100" *ngIf="brokerRequest?.hrDecisionDate" fxLayout="column">
          <span fxFlex="100" class="review">Human Resources Review</span>
          <span fxFlex="100" class="review-decision">
            <ng-container *ngIf="brokerRequest?.isHrApproved === 'YES'"><mat-icon class="approved-icon">check_circle</mat-icon>Approved</ng-container>
            <ng-container *ngIf="brokerRequest?.isHrApproved === 'NO'"><mat-icon class="denied-icon">cancel</mat-icon>Denied</ng-container>
            ( {{ brokerRequest.hrDecisionDate | date:'mediumDate' }} )
          </span>
        </div>
        <div fxFlex="100" *ngIf="brokerRequest?.complianceDecisionDate" fxLayout="column">
          <span fxFlex="100" class="review">Compliance Review</span>
          <span fxFlex="100" class="review-decision">
            <ng-container *ngIf="brokerRequest?.isComplianceApproved === 'YES'"><mat-icon class="approved-icon">check_circle</mat-icon>Approved</ng-container>
            <ng-container *ngIf="brokerRequest?.isComplianceApproved === 'NO'"><mat-icon class="denied-icon">cancel</mat-icon>Denied</ng-container>
            ( {{ brokerRequest.complianceDecisionDate | date:'mediumDate' }} )
          </span>
        </div>
        <!-- Display Radio Button Options for Outstanding Group Decisions -->
        <mat-radio-group fxFlex="100" *ngIf="brokerRequest?.type === 'BRANCH' && !brokerRequest?.branchCommitteeDecisionDate" formControlName="branchCommitteeReview" required>
          <span class="check-radio-label">Branch Committee Review</span>
          <mat-radio-button value="YES">Approve</mat-radio-button>
          <mat-radio-button value="NO">Deny</mat-radio-button>
        </mat-radio-group>
        <mat-radio-group fxFlex="100" *ngIf="!brokerRequest?.boardDecisionDate" formControlName="boardReview" required>
          <span class="check-radio-label">Board of Directors Review</span>
          <mat-radio-button value="YES">Approve</mat-radio-button>
          <mat-radio-button value="NO">Deny</mat-radio-button>
        </mat-radio-group>
        <mat-radio-group fxFlex="100" *ngIf="brokerRequest?.type !== 'NON_EMPLOYEE' && !brokerRequest?.hrDecisionDate" formControlName="hrReview" required>
          <span class="check-radio-label">Human Resources Review</span>
          <mat-radio-button value="YES">Approve</mat-radio-button>
          <mat-radio-button value="NO">Deny</mat-radio-button>
        </mat-radio-group>
        <mat-radio-group fxFlex="100" *ngIf="!brokerRequest?.complianceDecisionDate" formControlName="complianceReview" required>
          <span class="check-radio-label">Compliance Review</span>
          <mat-radio-button value="YES">Approve</mat-radio-button>
          <mat-radio-button value="NO">Deny</mat-radio-button>
        </mat-radio-group>
        <ati-labeled-data *ngIf="!editMode" label="User Registration and Activation Complete" data="{{ brokerRequestForm.get('registered').value | yesNo }}"></ati-labeled-data>
        <div fxFlex="100" *ngIf="editMode && brokerRequest?.status === 'PENDING' && brokerRequest?.type !== 'NON_EMPLOYEE'" class="checkbox-group">
          <span class="check-radio-label">User Registration and Activation</span>
          <mat-checkbox formControlName="registered">Complete</mat-checkbox>
        </div>
        <!-- Display Denial Comments -->
        <ati-labeled-data fxFlex="100" *ngIf="brokerRequest?.status === 'DENIED' && !editMode" label="Reason for Denial" data="{{ brokerRequestForm.get('denialComments').value }}"></ati-labeled-data>
        <mat-form-field *ngIf="(editMode && isBeingDenied)" fxFlex="100">
          <mat-label>Reason for Denial</mat-label>
          <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10" formControlName="denialComments" trim="blur" required></textarea>
          <mat-error *ngIf="brokerRequestForm.get('denialComments').invalid">{{getErrorMessage(brokerRequestForm.get('denialComments'))}}</mat-error>
          <button mat-button *ngIf="brokerRequestForm.get('denialComments').value && editMode" matSuffix mat-icon-button aria-label="Clear" (click)="brokerRequestForm.get('denialComments').setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </form>
  </mat-card>
</div>
