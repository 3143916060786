import {
  ChangeDetectorRef,
  Component,
  OnInit, ViewChild,
  OnDestroy,
  AfterViewInit,
  AfterViewChecked,
  ViewChildren,
  QueryList
} from '@angular/core';
import { Account, AccountPurpose, Commodity, SecuritySubType, Side, Trade} from '@advance-trading/ops-data-lib';
import { OperationsDataService } from '@advance-trading/angular-ops-data';
import { combineLatest, of, Observable, Subscription } from 'rxjs';
import { map, startWith, filter, take, switchMap } from 'rxjs/operators';
import { ObservableDataSource, ContractMonthUtility } from '@advance-trading/angular-common-services';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { TradeSearchFormValidator } from './trades-form-validator';
import { TradeSearchCriteria } from '../../../services/service-interfaces/trade-search-interface';
import { TradeService } from '../../../services/trade-service';
import { DefaultDatePicker } from '../../../utilities/defaultDatePicker';
import { AccountService } from 'src/app/services/account-service';

const DEFAULT_END_DATE = new Date('12/31/2099').toISOString();
const DEFAULT_START_DATE = new Date('1/1/2000').toISOString();

@Component({
  selector: 'atom-trade-search',
  templateUrl: './trade-search.component.html',
  styleUrls: ['./trade-search.component.css']
})
export class TradeSearchComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  filteredBy = '';
  dataSource = new ObservableDataSource<Trade>();
  selectedTrades$: Observable<Trade[]>;
  isSearching = false;
  errorMessage: string;
  showData = false;
  tradeSides = Object.keys(Side);
  filteredCommodities: Observable<Commodity[]>;
  tradeSubTypes = Object.keys(SecuritySubType);
  tradeStatuses = ['OPEN', 'CLOSED'];
  formValidator = new TradeSearchFormValidator();
  tableState: { [key: string]: string | number } = {};

  tradesForm: FormGroup = this.formBuilder.group({
    brokerCodes: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.brokerCodesValidator()),
    accounts: this.formBuilder.control({ value: '', disabled: false, }, this.formValidator.accountsValidator()),
    side: this.formBuilder.control({ value: this.tradeSides, disabled: false, }),
    contractMonthYear: [''],
    startTradeDate: [''],
    endTradeDate: [''],
    startBusinessDate: [''],
    endBusinessDate: [''],
    minStrike: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.strikeValidator()),
    maxStrike: this.formBuilder.control({ value: '', disabled: false }, this.formValidator.strikeValidator()),
    symbol: this.formBuilder.control({ value: '', disabled: false }),
    securityType: this.formBuilder.control({ value: '', disabled: false }),
    subTypeBoxes: [],
    typeBoxes: [],
    statusBoxes: [],
    cropYear: [''],
  });

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('yearMonthPicker', { static: false }) yearMonthRef;
  @ViewChildren('subTypeBoxes') subTypeBoxes: QueryList<MatCheckbox>;
  @ViewChildren('typeBoxes') typeBoxes: QueryList<MatCheckbox>;
  @ViewChildren('statusBoxes') statusBoxes: QueryList<MatCheckbox>;

  private chosenCommodity = '';
  private selectedTypes = [];
  private selectedSubTypes = [];
  private selectedStatuses = [];
  private lastBusinessDay = DefaultDatePicker.getLastBusinessDay();
  private queryParams: Params;
  private commodities: Commodity[];
  private routeSubscription: Subscription;
  private commoditySubscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tradeService: TradeService,
    private operationsDataService: OperationsDataService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private monthCode: ContractMonthUtility,
    private accountService: AccountService
  ) { }

  ngOnInit() {

    if (this.activatedRoute.queryParams) {
      this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
        this.queryParams = Object.assign({}, params);
        this.chosenCommodity = this.queryParams.symbol;
        this.tradesForm.get('accounts').setValue(this.queryParams.accounts);
        this.tradesForm.get('brokerCodes').setValue(this.queryParams.brokerCodes);
        this.tradesForm.get('minStrike').setValue(this.queryParams.minStrike);
        this.tradesForm.get('maxStrike').setValue(this.queryParams.maxStrike);
        this.tradesForm.get('side').setValue(this.queryParams.side);
        if (this.queryParams.startTradeDate) {
          this.tradesForm.get('startTradeDate').setValue(moment(this.queryParams.startTradeDate).toISOString());
        }
        if (this.queryParams.endTradeDate) {
          this.tradesForm.get('endTradeDate').setValue(moment(this.queryParams.endTradeDate).toISOString());
        }
        if (this.queryParams.startBusinessDate) {
          this.tradesForm.get('startBusinessDate').setValue(moment(this.queryParams.startBusinessDate).toISOString());
        } else {
          this.tradesForm.get('startBusinessDate').setValue(this.lastBusinessDay);
        }
        if (this.queryParams.endBusinessDate) {
          this.tradesForm.get('endBusinessDate').setValue(moment(this.queryParams.endBusinessDate).toISOString());
        } else {
          this.tradesForm.get('endBusinessDate').setValue(this.lastBusinessDay);
        }

        if (this.queryParams.securitySubTypes) {
          this.selectedSubTypes = this.queryParams.securitySubTypes.split(',');
          this.tradesForm.get('subTypeBoxes').setValue(this.selectedSubTypes);
        }
        if (this.queryParams.securityTypes) {
          this.selectedTypes = this.queryParams.securityTypes.split(',');
          this.tradesForm.get('typeBoxes').setValue(this.selectedTypes);
        }

        if (this.queryParams.statuses) {
          this.selectedStatuses = this.queryParams.statuses.split(',');
          this.tradesForm.get('statusBoxes').setValue(this.selectedStatuses);
        }

        if (this.queryParams.contractMonthYear) {
          this.tradesForm.get('contractMonthYear')
            .setValue(this.monthCode.translateContractMonthToMoment(this.queryParams.contractMonthYear));
        }

        if (this.queryParams.cropYear) {
          this.tradesForm.get('cropYear').setValue(this.queryParams.cropYear);
        }

        this.commoditySubscription = this.operationsDataService.getCommodityMap().subscribe(doc => {

          this.commodities = Object.values(doc.commodities)
              .filter(commodity => !commodity.gmiSymbol.endsWith('TAS') && commodity.id !== 'VARIOUS')
              .sort((a, b) => a.name.localeCompare(b.name));
          if (this.chosenCommodity) {
            const index = this.commodities.findIndex(val => val.id === this.chosenCommodity);
            this.tradesForm.get('symbol').setValue(this.commodities[index]);
            this.tradesForm.markAsDirty();
            this.searchTrades();
          }
          this.filteredCommodities = this.tradesForm.get('symbol').valueChanges.pipe(
            startWith<string | Commodity>(''),
            filter(value => typeof value === 'string'),
            map((commodityName: string) => {
              const filterValue = commodityName.toLowerCase();
              return this.commodities.filter(commodity => commodity.name.toLowerCase().includes(filterValue.toLowerCase()));
            })
          );
        });
        if (Object.keys(params).length && (!Object.keys(params).includes('symbol'))) {
          // Mark form as dirty so reset button appears
          this.tradesForm.markAsDirty();
          this.searchTrades();
        }
      }));
    }
  }

  ngAfterViewInit() {
    // setting here as [checked] property on template was not setting inner input type="checkbox" to checked
    this.subTypeBoxes.forEach(subTypeBox => subTypeBox.checked = this.selectedSubTypes.includes(subTypeBox.value));
    this.typeBoxes.forEach(typeBox => typeBox.checked = this.selectedTypes.includes(typeBox.value));
    this.statusBoxes.forEach(statusBox => statusBox.checked = this.selectedStatuses.includes(statusBox.value));
    this.changeDetector.detectChanges();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.commoditySubscription) {
      this.commoditySubscription.unsubscribe();
    }
  }

  searchTrades(searchButtonClicked: boolean = false) {
    this.snackBar.dismiss();
    if (searchButtonClicked) {
      // clear initial table state if the user perform a new search
      this.clearQueryParams();
      this.tableState = {};
    } else {
      // set initial table state from query param if the user is back navigating from another page
      const sortDir = this.queryParams.sortDir;
      const sortColName = this.queryParams.sortColName;
      const pageSize = this.queryParams.pageSize;
      const pageIndex = this.queryParams.pageIndex;
      const filterValue = this.queryParams.filterValue;
      this.tableState = {
        sortDir,
        sortColName,
        pageSize,
        pageIndex,
        filterValue
      };
    }
    this.showData = false;
    this.changeDetector.detectChanges();

    this.selectedTrades$ = this.chooseQuery();
    this.showData = true;

  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.changeDetector.detectChanges();
  }

  onSubTypeChange(event) {
    if (event.checked) {
      this.selectedSubTypes.push(event.source.value);
    } else {
      this.selectedSubTypes = this.selectedSubTypes.filter(type => type !== event.source.value);
    }
    this.tradesForm.get('subTypeBoxes').setValue(this.selectedSubTypes);
    if (this.selectedSubTypes.length) {
      this.tradesForm.get('subTypeBoxes').markAsDirty();
    }
  }

  onTypeChange(event) {
    if (event.checked) {
      this.selectedTypes.push(event.source.value);
    } else {
      this.selectedTypes = this.selectedTypes.filter(type => type !== event.source.value);
    }
    this.tradesForm.get('typeBoxes').setValue(this.selectedTypes);
    if (this.selectedTypes.length) {
      this.tradesForm.get('typeBoxes').markAsDirty();
    }
  }

  onStatusChange(event) {
    if (event.checked) {
      this.selectedStatuses.push(event.source.value);
    } else {
      this.selectedStatuses = this.selectedStatuses.filter(status => status !== event.source.value);
    }
    this.tradesForm.get('statusBoxes').setValue(this.selectedStatuses);
    if (this.selectedStatuses.length) {
      this.tradesForm.get('statusBoxes').markAsDirty();
    }
  }

  handleTradeListError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  selectTypes() {
    const returnTypes = [];
    if (this.selectedTypes.includes('BOND')) {
      returnTypes.push('BOND');
    }
    if (this.selectedTypes.includes('FUTURE')) {
      returnTypes.push('FUTURE', 'FUTURE_SPREAD');
    }
    return returnTypes;
  }

  private chooseQuery(): Observable<Trade[]> {
    const accountValues = this.tradesForm.get('accounts').value;
    const brokerCodeValues = this.tradesForm.get('brokerCodes').value;
    const oneSide = this.tradesForm.get('side').value;
    const monthYearValue = this.tradesForm.get('contractMonthYear').value;
    let yearMonth = '';
    let accountNumbers = [];
    let accountNumbersFiltered = [];
    let brokerCodes = [];
    let brokerCodesFiltered = [];
    const minStrike = parseFloat(this.tradesForm.get('minStrike').value);
    const maxStrike = parseFloat(this.tradesForm.get('maxStrike').value);
    const symbolValue = this.tradesForm.get('symbol').value;
    const securityTypes = this.selectTypes();
    const cropYear = this.tradesForm.get('cropYear').value;
    let startTradeDate = this.tradesForm.get('startTradeDate').value;
    let endTradeDate = this.tradesForm.get('endTradeDate').value;
    let startBusinessDateValue = this.tradesForm.get('startBusinessDate').value;
    let endBusinessDateValue = this.tradesForm.get('endBusinessDate').value;

    if (this.selectedStatuses.length === 1) {
      status = this.selectedStatuses[0];
    }

    this.queryParams = this.tableState as Params;

    if (startTradeDate) {
      startTradeDate = moment(startTradeDate).format('YYYY-MM-DD');
      this.queryParams.startTradeDate = startTradeDate;
    } else {
      startTradeDate = DEFAULT_START_DATE;
    }

    if (endTradeDate) {
      endTradeDate = moment(endTradeDate).format('YYYY-MM-DD');
      this.queryParams.endTradeDate = endTradeDate;
    } else {
      endTradeDate = DEFAULT_END_DATE;
    }

    if (startBusinessDateValue) {
      startBusinessDateValue = moment(startBusinessDateValue).format('YYYY-MM-DD');
    } else {
      startBusinessDateValue = DEFAULT_START_DATE;
    }
    this.queryParams.startBusinessDate = startBusinessDateValue;

    if (endBusinessDateValue) {
      endBusinessDateValue = moment(endBusinessDateValue).format('YYYY-MM-DD');
    } else {
      endBusinessDateValue = DEFAULT_END_DATE;
    }
    this.queryParams.endBusinessDate = endBusinessDateValue;

    if (monthYearValue) {
      const year = monthYearValue.year().toString();
      let month = (monthYearValue.month() + 1).toString();
      if (month.length === 1) {
        month = '0' + month;
      }
      yearMonth = year + month;
      const yearMonthParam = this.monthCode.translateMomentToContractMonth(monthYearValue);
      this.queryParams.contractMonthYear = yearMonthParam;
    }

    if (cropYear) {
      this.queryParams.cropYear = cropYear;
    }

    if (accountValues) {
      accountNumbers = accountValues.split(',').map((accountNum: string) => {
        // allow accountNumbers with the officeCode prefixed
        accountNum = accountNum.length === 8 ? accountNum.substr(3) : accountNum;
        return accountNum.trim();
      });
      accountNumbersFiltered = Array.from(new Set(accountNumbers));
      this.queryParams.accounts = accountValues;

      this.filteredBy = `Account - ${accountNumbersFiltered.join(', ')}`;
    } else if (brokerCodeValues) {
      brokerCodes = brokerCodeValues.split(',').map(code => code.trim());
      brokerCodesFiltered = Array.from(new Set(brokerCodes));
      this.queryParams.brokerCodes = brokerCodeValues;

      this.filteredBy = `Broker Code - ${brokerCodesFiltered.join(', ')}`;
    }

    if (oneSide) {
      this.queryParams.side = oneSide;
    }

    if (minStrike) {
      this.queryParams.minStrike = minStrike;
    }

    if (maxStrike) {
      this.queryParams.maxStrike = maxStrike;
    }

    if (symbolValue) {
      this.queryParams.symbol = symbolValue.id;
    }

    if (this.selectedSubTypes.length > 0) {
      this.queryParams.securitySubTypes = this.selectedSubTypes.join(',');
    }

    if (securityTypes && securityTypes.length > 0) {
      this.queryParams.securityTypes = securityTypes.join(',');
    }

    if (this.selectedStatuses.length > 0) {
      this.queryParams.statuses = this.selectedStatuses.join(',');
    }

    const tradeSearchCriteria: TradeSearchCriteria= {
      symbol: symbolValue.id,
      contractYearMonth: yearMonth,
      side: oneSide,
      securityTypes: securityTypes.length > 0 ? securityTypes : undefined,
      securitySubTypes: this.selectedSubTypes.length > 0 ? this.selectedSubTypes : undefined,
      strikePriceMin: minStrike,
      strikePriceMax: maxStrike,
      businessDateStart: startBusinessDateValue,
      businessDateEnd: endBusinessDateValue,
      tradeDateStart: startTradeDate,
      tradeDateEnd: endTradeDate
    };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams,
    });

    if (accountValues) {
      return this.tradeService.getTradesByAccountNumbers(accountNumbersFiltered, tradeSearchCriteria);
    } else if (brokerCodeValues) {
      return this.tradeService.getTradesByBrokerCodes(brokerCodesFiltered, tradeSearchCriteria);
    } else {
      return this.tradeService.getTradesByAccountNumbers([], tradeSearchCriteria);
    }
  }

  // Clears the value and disables client field and enables account field
  accountFieldClicked() {
    this.tradesForm.get('brokerCodes').setValue('');
    this.tradesForm.get('brokerCodes').disable();
    this.tradesForm.get('accounts').enable();
  }

  // Clears the value and disables account field and enables client field
  clientFieldClicked() {
    this.tradesForm.get('accounts').setValue('');
    this.tradesForm.get('accounts').disable();
    this.tradesForm.get('brokerCodes').enable();
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  reset() {
    this.tradesForm.reset();
    this.tradesForm.get('side').setValue('');
    this.tradesForm.get('accounts').enable();
    this.tradesForm.get('brokerCodes').enable();
    this.tradesForm.get('symbol').setValue('');
    this.tradesForm.get('startBusinessDate').setValue(this.lastBusinessDay);
    this.tradesForm.get('endBusinessDate').setValue(this.lastBusinessDay);
    this.chosenCommodity = '';
    this.selectedSubTypes = [];
    this.selectedTypes = [];
    this.selectedStatuses = [];
    this.subTypeBoxes.forEach(subTypeBox => subTypeBox.checked = false);
    this.typeBoxes.forEach(box => box.checked = false);
    this.statusBoxes.forEach(box => box.checked = false);
    this.showData = false;
    this.filteredBy = '';
  }

  selectYearMonth(event: moment.Moment) {
    this.tradesForm.get('contractMonthYear').setValue(event);
    this.tradesForm.get('contractMonthYear').markAsDirty();
    this.yearMonthRef.close();
  }

  displayCommodity(commodity: Commodity): string {
    if (commodity) {
      return commodity.name;
    }
    return '';
  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }

  clearSide() {
    this.tradesForm.get('side').setValue('');
  }

  handleTradeListChange(tableState: { [key: string]: string | number }) {
    if (tableState.sortDir && tableState.sortColName) {
      this.queryParams.sortDir = tableState.sortDir;
      this.queryParams.sortColName = tableState.sortColName;
    } else if (this.queryParams.sortDir && this.queryParams.sortColName) {
      // remove sorted direction and column in query param if there's no sort applied
      delete this.queryParams.sortDir;
      delete this.queryParams.sortColName;
    }
    if (tableState.pageSize) {
      this.queryParams.pageSize = tableState.pageSize;
    }
    if (tableState.pageIndex !== undefined) {
      this.queryParams.pageIndex = tableState.pageIndex;
    }

    if (tableState.filterValue) {
      this.queryParams.filterValue = tableState.filterValue;
    } else if (this.queryParams.filterValue) {
      // remove filter query param if there's no filter applied
      delete this.queryParams.filterValue;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }
}
