import {Position, TransactionSecurityType, CommodityMap} from '@advance-trading/ops-data-lib';

export class CommodityDisplayUtility {

  static getCommodityDisplayNameOnPosition(position: Position, commodityMap: CommodityMap) {
    if (position.securityType === TransactionSecurityType.BOND) {
      return 'Bond';
    } else if (!position.commodityId) {
      return position.docId.includes('-') ? position.docId.substr(0, position.docId.length - 2) : position.docId;
    } else {
      const commodity = Object.values(commodityMap.commodities).find(cmd => cmd.id === position.commodityId);
      if (commodity) {
        return commodity.name;
      } else {
        return position.docId.includes('-') ? position.docId.substr(0, position.docId.length - 2) : position.docId;
      }
    }
  }
}
