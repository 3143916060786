import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { shareReplay, map } from 'rxjs/operators';

import { Observable, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from '@advance-trading/angular-ati-security';
import { Account, AccountPurpose, AccountSeries, FCM, Status } from '@advance-trading/ops-data-lib';

import { AccountSeriesService, UserService } from '@advance-trading/angular-ops-data';

const MAXIMUM_ARRAY_SIZE = 10;

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private db: AngularFirestore,
    private accountSeriesService: AccountSeriesService,
    private authService: AuthService,
    private userService: UserService
  ) { }

  /**
   * across all helper functions @param accounts is User.accounts
   */

  /**
   * Get Account by docId
   */
  getAccount(docId: string): Observable<Account> {
    return this.db.collection(Account.getDataPath(), ref => ref.where('docId', '==', docId)).doc<Account>(docId).valueChanges()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  // Queries using get()

  /**
   * Get All Account documents
   * uses get()
   */
  retrieveAllAccounts(): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath()).get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Account) as Account[]));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.retrieveAllAccountsHelper(
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.retrieveAllAccountsHelper(user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private retrieveAllAccountsHelper(accounts: string[]) {
    return this.db.collection<Account>(Account.getDataPath(), ref => ref
      .where('docId', 'in', accounts)).get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Account) as Account[]));
  }

  /**
   * Retrieve all Account documents for a given account number
   * @param accountNumber The account number
   * uses get()
   */
  retrieveAccountsByNumber(accountNumber: string): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      if (accountNumber.length === 8) {
        return this.db.collection<Account>(Account.getDataPath(), ref => ref.where('number', '==', accountNumber.slice(3))
          .where('officeCode', '==', accountNumber.slice(0, 3)))
          .get().pipe(
            map(querySnap => querySnap.docs.map(doc => doc.data() as Account) as Account[])
          );
      } else {
        return this.db.collection<Account>(Account.getDataPath(), ref => ref.where('number', '==', accountNumber)).get().pipe(
          map(querySnap => querySnap.docs.map(doc => doc.data() as Account) as Account[])
        );
      }
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.retrieveAccountsByNumberHelper(
                accountNumber,
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.retrieveAccountsByNumberHelper(
              accountNumber,
              user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private retrieveAccountsByNumberHelper(accountNumber: string, accounts: string[]): Observable<Account[]> {
    if (accountNumber.length === 8) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref
        .where('number', '==', accountNumber.slice(3))
        .where('officeCode', '==', accountNumber.slice(0, 3))
        .where('docId', 'in', accounts)).get().pipe(
          map(querySnap => querySnap.docs.map(doc => doc.data() as Account) as Account[])
        );
    } else {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref
        .where('number', '==', accountNumber)
        .where('docId', 'in', accounts)).get().pipe(
          map(querySnap => querySnap.docs.map(doc => doc.data() as Account) as Account[])
        );
    }
  }

  /**
   * Retrieve all Active Account documents for a given account number
   * @param accountNumber The account number
   * uses get()
   */
  retrieveActiveAccountsByNumber(accountNumber: string): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      if (accountNumber.length === 8) {
        return this.db.collection<Account>(Account.getDataPath(), ref => ref.where('number', '==', accountNumber.slice(3))
          .where('officeCode', '==', accountNumber.slice(0, 3))
          .where('status', '==', Status.ACTIVE))
          .get().pipe(
            map(querySnap => querySnap.docs.map(doc => doc.data() as Account) as Account[])
          );
      } else {
        return this.db.collection<Account>(Account.getDataPath(), ref => ref.where('number', '==', accountNumber)
          .where('status', '==', Status.ACTIVE)).get().pipe(
            map(querySnap => querySnap.docs.map(doc => doc.data() as Account) as Account[])
          );
      }
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.retrieveActiveAccountsByNumberHelper(
                accountNumber,
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.retrieveActiveAccountsByNumberHelper(
              accountNumber,
              user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private retrieveActiveAccountsByNumberHelper(accountNumber: string, accounts: string[]): Observable<Account[]> {
    if (accountNumber.length === 8) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref
        .where('number', '==', accountNumber.slice(3))
        .where('officeCode', '==', accountNumber.slice(0, 3))
        .where('status', '==', Status.ACTIVE)
        .where('docId', 'in', accounts)).get().pipe(
          map(querySnap => querySnap.docs.map(doc => doc.data() as Account) as Account[])
        );
    } else {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref
        .where('number', '==', accountNumber)
        .where('status', '==', Status.ACTIVE)
        .where('docId', 'in', accounts)).get().pipe(
          map(querySnap => querySnap.docs.map(doc => doc.data() as Account) as Account[])
        );
    }
  }

  /**
   * Retrieve all Active Account documents for a given brokerCode
   * @param brokerCode The combined office code and sales code associated with an account
   * uses get()
   */
  retrieveActiveAccountsByBrokerCode(brokerCode: string): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref
        .where('brokerCode', '==', brokerCode)
        .where('status', '==', Status.ACTIVE))
        .get()
        .pipe(
          map(querySnap => querySnap.docs.map(doc => doc.data() as Account) as Account[])
        );
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.retrieveActiveAccountsByBrokerCodeHelper(
                brokerCode,
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.retrieveActiveAccountsByBrokerCodeHelper(
              brokerCode,
              user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private retrieveActiveAccountsByBrokerCodeHelper(brokerCode: string, accounts: string[]): Observable<Account[]> {
    return this.db.collection<Account>(Account.getDataPath(), ref => ref
      .where('brokerCode', '==', brokerCode)
      .where('docId', 'in', accounts)
      .where('status', '==', Status.ACTIVE))
      .get()
      .pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Account) as Account[])
      );
  }

  /**
   * Retrieve all Account documents for a given brokerCode
   * @param brokerCode The combined office code and sales code associated with an account
   * uses get()
   */
  retrieveAccountsByBrokerCode(brokerCode: string): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref
        .where('brokerCode', '==', brokerCode))
        .get()
        .pipe(
          map(querySnap => querySnap.docs.map(doc => doc.data() as Account) as Account[])
        );
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.retrieveAccountsByBrokerCodeHelper(
                brokerCode,
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.retrieveAccountsByBrokerCodeHelper(
              brokerCode,
              user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private retrieveAccountsByBrokerCodeHelper(brokerCode: string, accounts: string[]): Observable<Account[]> {
    return this.db.collection<Account>(Account.getDataPath(), ref => ref
      .where('brokerCode', '==', brokerCode)
      .where('docId', 'in', accounts))
      .get()
      .pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Account) as Account[])
      );
  }

  /**
   * Retrive all Accounts for a given marginGroupAccountDocId
   * @param marginGroupAccountDocId
   * uses get()
   */
  retrieveAccountsByMarginGroupAccountDocId(marginGroupAccountDocId: string): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
        this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
          return this.db.collection<Account>(Account.getDataPath(), ref => ref
            .where('marginGroupAccountDocId', '==', marginGroupAccountDocId))
            .get()
            .pipe(
              map(querySnap => querySnap.docs.map(doc => doc.data() as Account) as Account[])
            );
        } else {
          return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
            switchMap(user => {
              if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
                const subQueryObservables = [];
                for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
                  subQueryObservables.push(this.retrieveAccountsByMarginGroupAccountDocIdHelper(
                    marginGroupAccountDocId,
                    user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)
                  ));
                }
                return combineLatest(subQueryObservables).pipe(
                  // force potential array of Account arrays into single array
                  map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
                  shareReplay({ bufferSize: 1, refCount: true })
                );
              }
            })
          );
        }
  }

  private retrieveAccountsByMarginGroupAccountDocIdHelper(marginGroupAccountDocId: string, accounts: string[]): Observable<Account[]> {
    return this.db.collection<Account>(Account.getDataPath(), ref => ref
      .where('marginGroupAccountDocId', '==', marginGroupAccountDocId)
      .where('docId', 'in', accounts))
      .get()
      .pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Account) as Account[])
      );
  }

  // Queries using valueChanges()

  /**
   * Get All Account documents
   */
  getAllAccounts(): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath()).valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getAllAccountsHelper(
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getAllAccountsHelper(user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getAllAccountsHelper(accounts: string[]) {
    return this.db.collection<Account>(Account.getDataPath(), ref => ref
      .where('docId', 'in', accounts))
      .valueChanges()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Retrieve all Account documents for a given clientDocId
   * @param clientDocId The document id of a Client
   */
  getAllAccountsByClientId(clientDocId: string): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)).valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getAllAccountsByClientIdHelper(
                clientDocId,
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)
              ));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getAllAccountsByClientIdHelper(clientDocId, user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }


  private getAllAccountsByClientIdHelper(clientDocId: string, accounts: string[]): Observable<Account[]> {
    return this.db.collection<Account>(Account.getDataPath(), ref => ref
      .where('clientDocId', '==', clientDocId)
      .where('docId', 'in', accounts)).valueChanges()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Retrieve all Account documents where status is Status.ACTIVE
   */
  getAllActiveAccounts(): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref.where('status', '==', Status.ACTIVE)).valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getAllActiveAccountsHelper(
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getAllActiveAccountsHelper(user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getAllActiveAccountsHelper(accounts: string[]): Observable<Account[]> {
    return this.db.collection<Account>(Account.getDataPath(), ref => ref
      .where('status', '==', Status.ACTIVE)
      .where('docId', 'in', accounts)).valueChanges()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Retrieve all Account documents for a given clientDocId and account status
   * @param clientDocId The document id of a Client
   * @param status The account status
   */
  getAllAccountsByClientDocIdAndStatus(clientDocId: string, status: Status): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref
        .where('clientDocId', '==', clientDocId)
        .where('status', '==', status))
        .valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getAllAccountsByClientDocIdAndStatusHelper(
                clientDocId,
                status,
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getAllAccountsByClientDocIdAndStatusHelper(
              clientDocId,
              status,
              user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getAllAccountsByClientDocIdAndStatusHelper(clientDocId: string, status: Status, accounts: string[]): Observable<Account[]> {
    return this.db.collection<Account>(Account.getDataPath(), ref => ref
      .where('clientDocId', '==', clientDocId)
      .where('status', '==', status)
      .where('docId', 'in', accounts)).valueChanges()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Retrieve all Account documents for a given brokerCode
   * @param brokerCode The combined office code and sales code associated with an account
   */
  getAccountsByBrokerCode(brokerCode: string): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref
        .where('brokerCode', '==', brokerCode))
        .valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getAccountsByBrokerCodeHelper(
                brokerCode,
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getAccountsByBrokerCodeHelper(
              brokerCode,
              user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getAccountsByBrokerCodeHelper(brokerCode: string, accounts: string[]): Observable<Account[]> {
    return this.db.collection<Account>(Account.getDataPath(), ref => ref
      .where('brokerCode', '==', brokerCode)
      .where('docId', 'in', accounts)).valueChanges()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Retrieve all Account documents for a given brokerCode and account status
   * @param brokerCode The combined office code and sales code associated with an account
   * @param status The account status
   */
  getAllAccountsByBrokerCodeAndStatus(brokerCode: string, status: Status): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref
        .where('brokerCode', '==', brokerCode)
        .where('status', '==', status))
        .valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getAllAccountsByBrokerCodeAndStatusHelper(
                brokerCode,
                status,
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getAllAccountsByBrokerCodeAndStatusHelper(
              brokerCode,
              status,
              user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getAllAccountsByBrokerCodeAndStatusHelper(brokerCode: string, status: Status, accounts: string[]): Observable<Account[]> {
    return this.db.collection<Account>(Account.getDataPath(), ref => ref
      .where('brokerCode', '==', brokerCode)
      .where('status', '==', status)
      .where('docId', 'in', accounts)).valueChanges()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Retrieve all Account documents for a given account number
   * @param accountNumber The account number
   */
  getAccountsByNumber(accountNumber: string): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref.where('number', '==', accountNumber)).valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getAccountsByNumberHelper(
                accountNumber,
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getAccountsByNumberHelper(
              accountNumber,
              user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getAccountsByNumberHelper(accountNumber: string, accounts: string[]): Observable<Account[]> {
    return this.db.collection<Account>(Account.getDataPath(), ref => ref
      .where('number', '==', accountNumber)
      .where('docId', 'in', accounts)).valueChanges()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Retrieve all Account documents for a given account number and account status
   * @param accountNumber The account number
   * @param status The account status
   */
  getAllAccountsByAccountNumberAndStatus(accountNumber: string, status: Status): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref
        .where('number', '==', accountNumber)
        .where('status', '==', status))
        .valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getAllAccountsByAccountNumberAndStatusHelper(
                accountNumber,
                status,
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getAllAccountsByAccountNumberAndStatusHelper(
              accountNumber,
              status,
              user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getAllAccountsByAccountNumberAndStatusHelper(accountNumber: string, status: Status, accounts: string[]): Observable<Account[]> {
    return this.db.collection<Account>(Account.getDataPath(), ref => ref
      .where('number', '==', accountNumber)
      .where('status', '==', status)
      .where('docId', 'in', accounts)).valueChanges()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Retrieve all Account documents for a given account status
   * @param Status The account status
   */
  getAccountsByStatus(status: Status): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref
        .where('status', '==', status))
        .valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getAccountsByStatusHelper(
                status,
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getAccountsByStatusHelper(
              status,
              user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getAccountsByStatusHelper(status: Status, accounts: string[]): Observable<Account[]> {
    return this.db.collection<Account>(Account.getDataPath(), ref => ref
      .where('status', '==', status)
      .where('docId', 'in', accounts)).valueChanges()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Retrieve all Account documents for a given broker relationship
   * @param brokerRelDocId The document id of a BrokerRelationship
   */
  getAccountsByBrokerRelationship(brokerRelDocId: string): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref
        .where('brokerRelDocId', '==', brokerRelDocId))
        .valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getAccountsByBrokerRelationshipHelper(
                brokerRelDocId,
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getAccountsByBrokerRelationshipHelper(
              brokerRelDocId,
              user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getAccountsByBrokerRelationshipHelper(brokerRelDocId: string, accounts: string[]): Observable<Account[]> {
    return this.db.collection<Account>(Account.getDataPath(), ref => ref
      .where('brokerRelDocId', '==', brokerRelDocId)
      .where('docId', 'in', accounts))
      .valueChanges()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Retrieve all Account documents for a given broker
   * @param brokerDocId The document id of a Broker
   */
  getAccountsByBroker(brokerDocId: string): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref
        .where('brokers', 'array-contains', brokerDocId))
        .valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getAccountsByBrokerHelper(
                brokerDocId,
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getAccountsByBrokerHelper(
              brokerDocId,
              user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }
  private getAccountsByBrokerHelper(brokerDocId: string, accounts: string[]): Observable<Account[]> {
    return this.db.collection<Account>(Account.getDataPath(), ref => ref
      .where('brokers', 'array-contains', brokerDocId)
      .where('docId', 'in', accounts))
      .valueChanges()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Retrieve all Account documents for a given account series
   * @param accountSeriesDocId The document id of the AccountSeries
   */
  getAccountsByAccountSeries(accountSeriesDocId: string, accountSeriesRange: string): Observable<Account[]> {
    const accountSeriesRanges = accountSeriesRange.split(' - ');
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref
        .where('number', '>=', accountSeriesRanges[0])
        .where('number', '<=', accountSeriesRanges[1]))
        .valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getAccountsByAccountSeriesHelper(
                accountSeriesDocId,
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getAccountsByAccountSeriesHelper(
              accountSeriesDocId,
              user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getAccountsByAccountSeriesHelper(accountSeriesDocId: string, accounts: string[]): Observable<Account[]> {
    return this.accountSeriesService.getAccountSeriesByDocId(accountSeriesDocId)
      .pipe(
        switchMap((accountSeries: AccountSeries) => {
          return this.db.collection<Account>(Account.getDataPath(), ref => ref
            .where('number', '>=', accountSeries.start)
            .where('number', '<=', accountSeries.end)
            .where('docId', 'in', accounts).orderBy('number')).valueChanges()
            .pipe(shareReplay({ bufferSize: 1, refCount: true }));
        })
      );
  }

  /**
   * Retrieves active margin group accounts for a specified client and FCM
   * @param clientDocId The Client docId
   * @param fcm The FCM of the BrokerRelationship of the Account needing a margin group account
   */
  getMarginGroupAccountsByClientFCM(clientDocId: string, fcm: FCM): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref
        .where('status', '==', Status.ACTIVE)
        .where('clientDocId', '==', clientDocId)
        .where('purpose', '==', AccountPurpose.MARGIN_GROUP)
        .where('fcm', '==', fcm))
        .valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getMarginGroupAccountsByClientFCMHelper(
                clientDocId,
                fcm,
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getMarginGroupAccountsByClientFCMHelper(
              clientDocId,
              fcm,
              user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getMarginGroupAccountsByClientFCMHelper(clientDocId: string, fcm: FCM, accounts: string[]): Observable<Account[]> {
    return this.db.collection<Account>(Account.getDataPath(), ref =>
      ref.where('status', '==', Status.ACTIVE)
        .where('clientDocId', '==', clientDocId)
        .where('purpose', '==', AccountPurpose.MARGIN_GROUP).where('fcm', '==', fcm)
        .where('docId', 'in', accounts))
      .valueChanges()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Retrieves active hedge accounts for a specified client
   * @param clientDocId The Client docId
   */
  getActiveHedgeAccountsByClientDocId(clientDocId: string): Observable<Account[]> {
    if (this.authService.userProfile.app_metadata.authorization.roles.includes('AccountAdmin') ||
      this.authService.userProfile.app_metadata.authorization.roles.includes('ComplianceApprover')) {
      return this.db.collection<Account>(Account.getDataPath(), ref => ref
        .where('status', '==', Status.ACTIVE)
        .where('clientDocId', '==', clientDocId)
        .where('purpose', '==', AccountPurpose.HEDGE))
        .valueChanges()
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    } else {
      return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
        switchMap(user => {
          if (user.accounts.length > MAXIMUM_ARRAY_SIZE) {
            const subQueryObservables = [];
            for (let index = 0; index < user.accounts.length; index += MAXIMUM_ARRAY_SIZE) {
              subQueryObservables.push(this.getActiveHedgeAccountsByClientDocIdHelper(
                clientDocId,
                user.accounts.slice(index, index + MAXIMUM_ARRAY_SIZE)));
            }
            return combineLatest(subQueryObservables).pipe(
              // force potential array of Account arrays into single array
              map(arrayOfAccountArrays => (arrayOfAccountArrays as Account[][]).flat()),
              shareReplay({ bufferSize: 1, refCount: true })
            );
          } else {
            return this.getActiveHedgeAccountsByClientDocIdHelper(
              clientDocId,
              user.accounts)
              .pipe(shareReplay({ bufferSize: 1, refCount: true }));
          }
        })
      );
    }
  }

  private getActiveHedgeAccountsByClientDocIdHelper(clientDocId: string, accounts: string[]): Observable<Account[]> {
    return this.db.collection<Account>(Account.getDataPath(), ref =>
      ref.where('status', '==', Status.ACTIVE)
        .where('clientDocId', '==', clientDocId)
        .where('purpose', '==', AccountPurpose.HEDGE)).valueChanges()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Create a new Account document in Firestore
   * @param account The Account to create
   */
  createAccount(account: Account) {
    account.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.db.collection(Account.getDataPath()).doc<Account>(account.docId).set(account.getPlainObject());
  }

  /**
   * Update an existing Account document in Firestore
   * @param account The Account to update
   */
  updateAccount(account: Account) {
    account.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    if (!account.fundsTransfer) {
      // @ts-ignore
      account.fundsTransfer = firebase.firestore.FieldValue.delete();
    }
    if (!account.slidingScaleResetDate) {
      // @ts-ignore
      account.slidingScaleResetDate = firebase.firestore.FieldValue.delete();
    }
    if (!account.slidingScaleLevel) {
      // @ts-ignore
      account.slidingScaleLevel = firebase.firestore.FieldValue.delete();
    }
    if (!account.inactivationDate) {
      // @ts-ignore
      account.inactivationDate = firebase.firestore.FieldValue.delete();
    }
    if (!account.closureDate) {
      // @ts-ignore
      account.closureDate = firebase.firestore.FieldValue.delete();
    }
    if (!account.reactivationDate) {
      // @ts-ignore
      account.reactivationDate = firebase.firestore.FieldValue.delete();
    }
    return this.db.collection(Account.getDataPath()).doc<Account>(account.docId).update(account);
  }
}
