import { Injectable, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { Account, Order } from '@advance-trading/ops-data-lib';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OrderSearchCriteria } from '../services/service-interfaces/order-search-interface';

import { Apollo } from 'apollo-angular';
import { OrderQueries } from './graphql-queries/order-queries';
import { AuthService } from '@advance-trading/angular-ati-security';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    @Inject('environment') private environment,
    private db: AngularFirestore,
    private http: HttpClient,
    private apollo: Apollo,
    private authService: AuthService
  ) { }
  // Queries Using GET

  /**
   * Return a specific Order document
   *
   * @param accountDocId The docId of the Account containing the Order
   * @param orderDocId The docId of the Order being retrieved
   */
  getOrderByDocId(accountDocId: string, orderDocId: string): Observable<Order> {
    return this.db.doc<Order>(`${Account.getDataPath(accountDocId)}/${Order.getDataPath(orderDocId)}`).valueChanges()
      .pipe(
        shareReplay({ bufferSize: 1, refCount: true})
      );
  }

  /**
   * Returns all Order documents using an AccountDocId and any combination of the following
   * symbol, contractYearMonth, strikePrice, securityType, securitySubType or side.
   *
   * @param OrderSearch The OrderSearch interface that has been populated with all search query criteria
   */
  getOrdersWithoutLegsByAccountDocIdAndParameters(searchParameters: OrderSearchCriteria): Observable<Order[]> {
    return this.db.collection<Order>(`${Account.getDataPath(searchParameters.accountDocId)}/${Order.getDataPath()}`, ref => {
      let finalRef = ref as firebase.default.firestore.Query<firebase.default.firestore.DocumentData>;

      finalRef = finalRef.where('legs', '==', []);

      if (searchParameters.side) {
        finalRef = finalRef.where('side', '==', searchParameters.side);
      }
      if (searchParameters.contractYearMonth) {
        finalRef = finalRef.where('contractYearMonth', '==', searchParameters.contractYearMonth);
      }
      if (searchParameters.symbols && searchParameters.symbols.length > 0) {
        finalRef = finalRef.where('symbol', 'in', searchParameters.symbols);
      }
      if (searchParameters.securitySubType) {
        finalRef = finalRef.where('securitySubType', '==', searchParameters.securitySubType);
      }
      return finalRef;

    }).get().pipe(
      map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[]),
        map(orders => {
          if (searchParameters.securityTypes && searchParameters.securityTypes.length > 0) {
            orders = orders.filter(order => searchParameters.securityTypes.includes(order.securityType));
          }
          if (searchParameters.statuses && searchParameters.statuses.length > 0) {
            orders = orders.filter(order => searchParameters.statuses.includes(order.status));
          }
          return orders;
        })
      );
  }

  getOrdersWithLegsByAccount(accountDocId: string) {
    return this.db.collection<Order>(`${Account.getDataPath(accountDocId)}/${Order.getDataPath()}`, ref => {
      let finalRef = ref as firebase.default.firestore.Query<firebase.default.firestore.DocumentData>;
      finalRef = finalRef.where('legs', '!=', []);
      return finalRef;
    }).get().pipe(
      map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[]));
  }

  /**
   * Return all Order documents for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   */
  getAllClientOrders(clientDocId: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .orderBy('lastUpdatedTimestamp', 'desc')).get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
      );
  }

  /**
   * Return all Order documents for the specified Account
   *
   * @param accountDocId The docId of the Account containing the Orders
   */
  getAllOrdersByAccountDocId(accountDocId: string): Observable<Order[]> {
    return this.db.collection<Order>(`${Account.getDataPath(accountDocId)}/${Order.getDataPath()}`).get().pipe(
      map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
    );
  }

  // One Search Criteria

  /**
   * Return all Order documents with a particular orderDocId for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param orderDocId orderDocId of Orders to be returned
   */
  getClientOrdersByOrderDocId(clientDocId: string, orderDocId: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('docId', '==', orderDocId)).get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
      );
  }

  /**
   * Return all Order documents with a particular symbol for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param symbol symbol of Orders to be returned
   */
  getClientOrdersBySymbol(clientDocId: string, symbol: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('symbol', '==', symbol)
      .orderBy('lastUpdatedTimestamp', 'desc')).get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
      );
  }

  /**
   * Return all Order documents with a particular status value for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param status Status of Orders to be returned
   */
  getClientOrdersByStatus(clientDocId: string, status: string[]): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('status', 'in', status)
      .orderBy('lastUpdatedTimestamp', 'desc')).get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
      );
  }

  /**
   * Return all Order documents with a particular type value for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param type Type of Orders to be returned
   */
  getClientOrdersByType(clientDocId: string, type: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('type', '==', type)
      .orderBy('lastUpdatedTimestamp', 'desc')).get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
      );
  }

  /**
   * Return all Order documents with a particular date range for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param startDate The date before or when the Orders were last updated
   * @param endDate The date after or when the Orders were last updated
   */
  getClientOrdersByDate(clientDocId: string, startDate: string, endDate: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
      .orderBy('lastUpdatedTimestamp', 'desc')).get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
      );
  }

  // Two Search Criteria

  /**
   * Return all Order documents with a particular symbol and status for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param symbol symbol of Orders to be returned
   * @param status Status of Orders to be returned
   */
  getClientOrdersBySymbolAndStatus(clientDocId: string, symbol: string, status: string[]): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('symbol', '==', symbol).where('status', 'in', status)
      .orderBy('lastUpdatedTimestamp', 'desc')).get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
      );
  }

  /**
   * Return all Order documents with a particular symbol and type for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param symbol symbol of Orders to be returned
   * @param type Status of Orders to be returned
   */
  getClientOrdersBySymbolAndType(clientDocId: string, symbol: string, type: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('symbol', '==', symbol).where('type', '==', type)
      .orderBy('lastUpdatedTimestamp', 'desc')).get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
      );
  }

  /**
   * Return all Order documents with a particular symbol and date range for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param symbol symbol of Orders to be returned
   * @param startDate The date before or when the Orders were last updated
   * @param endDate The date after or when the Orders were last updated
   */
  getClientOrdersBySymbolAndDate(clientDocId: string, symbol: string, startDate: string, endDate: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref =>
      ref.where('clientDocId', '==', clientDocId).where('symbol', '==', symbol)
        .where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
        .orderBy('lastUpdatedTimestamp', 'desc')).get().pipe(
          map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
        );
  }

  /**
   * Return all Order documents with a particular status and type for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param status Status of Orders to be returned
   * @param type Type of Orders to be returned
   */
  getClientOrdersByStatusAndType(clientDocId: string, status: string[], type: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('status', 'in', status).where('type', '==', type)
      .orderBy('lastUpdatedTimestamp', 'desc')).get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
      );
  }

  /**
   * Return all Order documents with a particular status and date range for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param status Status of Orders to be returned
   * @param startDate The date before or when the Orders were last updated
   * @param endDate The date after or when the Orders were last updated
   */
  getClientOrdersByStatusAndDate(clientDocId: string, status: string[], startDate: string, endDate: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref =>
      ref.where('clientDocId', '==', clientDocId).where('status', 'in', status)
        .where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
        .orderBy('lastUpdatedTimestamp', 'desc')).get().pipe(
          map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
        );
  }

  /**
   * Return all Order documents with a particular type and date range for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param type Type of Orders to be returned
   * @param startDate The date before or when the Orders were last updated
   * @param endDate The date after or when the Orders were last updated
   */
  getClientOrdersByTypeAndDate(clientDocId: string, type: string, startDate: string, endDate: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('type', '==', type).where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
      .orderBy('lastUpdatedTimestamp', 'desc'))
      .get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
      );
  }

  // Three Search Criteria

  /**
   * Return all Order documents with a particular symbol, status, and type for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param symbol symbol of Orders to be returned
   * @param status Status of Orders to be returned
   * @param type Type of Orders to be returned
   */
  getClientOrdersBySymbolAndStatusAndType(clientDocId: string, symbol: string, status: string[], type: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('symbol', '==', symbol).where('status', 'in', status).where('type', '==', type)
      .orderBy('lastUpdatedTimestamp', 'desc')).get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
      );
  }

  /**
   * Return all Order documents with a particular symbol, status, and date range for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param symbol symbol of Orders to be returned
   * @param status Status of Orders to be returned
   * @param startDate The date before or when the Orders were last updated
   * @param endDate The date after or when the Orders were last updated
   */
  getClientOrdersBySymbolAndStatusAndDate(clientDocId: string, symbol: string, status: string[], startDate: string, endDate: string):
    Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref =>
      ref.where('clientDocId', '==', clientDocId).where('symbol', '==', symbol).where('status', 'in', status)
        .where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
        .orderBy('lastUpdatedTimestamp', 'desc')).get().pipe(
          map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
        );
  }

  /**
   * Return all Order documents with a particular symbol, type, and date range for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param symbol symbol of Orders to be returned
   * @param type Type of Orders to be returned
   * @param startDate The date before or when the Orders were last updated
   * @param endDate The date after or when the Orders were last updated
   */
  getClientOrdersBySymbolAndTypeAndDate(clientDocId: string, symbol: string, type: string, startDate: string, endDate: string):
    Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref =>
      ref.where('clientDocId', '==', clientDocId).where('symbol', '==', symbol).where('type', '==', type)
        .where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
        .orderBy('lastUpdatedTimestamp', 'desc')).get().pipe(
          map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
        );
  }

  /**
   * Return all Order documents with a particular status, type, and date range for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param status Status of Orders to be returned
   * @param type Type of Orders to be returned
   * @param startDate The date before or when the Orders were last updated
   * @param endDate The date after or when the Orders were last updated
   */
  getClientOrdersByStatusAndTypeAndDate(clientDocId: string, status: string[], type: string, startDate: string, endDate: string):
    Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref =>
      ref.where('clientDocId', '==', clientDocId).where('status', 'in', status).where('type', '==', type)
        .where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
        .orderBy('lastUpdatedTimestamp', 'desc')).get().pipe(
          map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
        );
  }

  // Four Search Criteria

  /**
   * Return all Order documents with a particular symbol, status, type, and date range for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param symbol symbol of Orders to be returned
   * @param status Status of Orders to be returned
   * @param type Type of Orders to be returned
   * @param startDate The date before or when the Orders were last updated
   * @param endDate The date after or when the Orders were last updated
   */
  getClientOrdersBySymbolAndStatusAndTypeAndDate(
    clientDocId: string, symbol: string, status: string[], type: string, startDate: string, endDate: string):
    Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('symbol', '==', symbol).where('status', 'in', status).where('type', '==', type)
      .where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
      .orderBy('lastUpdatedTimestamp', 'desc')).get().pipe(
        map(querySnap => querySnap.docs.map(doc => doc.data() as Order) as Order[])
      );
  }

  /**
   * This function is used exclusively to update the Order.clientOrderId during a cancel/replace scenario with
   * the QST integration; there is currently a problem where this clientOrderId is not sent to the exchange for
   * the cancel/replace scenario
   * @param accountDocId The accountDocId for the Order
   * @param orderDocId The docId for the Order
   * @param newClientOrderId The new value for Order.clientOrderId
   */
  updateClientOrderId(accountDocId: string, orderDocId: string, newClientOrderId: string, accessToken: string) {
    return this.http.patch(`${this.environment.services.httpExchangeOrders}/accounts/${accountDocId}/orders/${orderDocId}`,
      { clientOrderId: newClientOrderId },
      { headers: new HttpHeaders().set('Authorization', `Bearer ${accessToken}`) }
    ).toPromise()
      .catch(err => {
        const errMessage = err.message || err;
        const finalErr = new Error(errMessage);
        finalErr.name = 'ClientOrderIdUpdateError';
        throw finalErr;
      });
  }

  // Queries Using ValueChanges


  /**
   * Returns all Order documents using an AccountDocId and any combination of the following
   * symbol, contractYearMonth, strikePrice, securityType, securitySubType or side.
   *
   * @param OrderSearch The OrderSearch interface that has been populated with all search query criteria
   */
   findOrdersWithoutLegsByAccountDocIdAndParameters(searchParameters: OrderSearchCriteria): Observable<Order[]> {
    return this.db.collection<Order>(`${Account.getDataPath(searchParameters.accountDocId)}/${Order.getDataPath()}`, ref => {
      let finalRef = ref as firebase.default.firestore.Query<firebase.default.firestore.DocumentData>;

      finalRef = finalRef.where('legs', '==', []);

      if (searchParameters.side) {
        finalRef = finalRef.where('side', '==', searchParameters.side);
      }
      if (searchParameters.contractYearMonth) {
        finalRef = finalRef.where('contractYearMonth', '==', searchParameters.contractYearMonth);
      }
      if (searchParameters.symbols && searchParameters.symbols.length > 0) {
        finalRef = finalRef.where('symbol', 'in', searchParameters.symbols);
      }
      if (searchParameters.securitySubType) {
        finalRef = finalRef.where('securitySubType', '==', searchParameters.securitySubType);
      }
      return finalRef;

    }).valueChanges().pipe(
      shareReplay({ bufferSize: 1, refCount: true })).pipe(
        map(orders => {
          if (searchParameters.securityTypes && searchParameters.securityTypes.length > 0) {
            orders = orders.filter(order => searchParameters.securityTypes.includes(order.securityType));
          }
          if (searchParameters.statuses && searchParameters.statuses.length > 0) {
            orders = orders.filter(order => searchParameters.statuses.includes(order.status));
          }
          return orders;
        })
      );
  }

  findOrdersWithLegsByAccount(accountDocId: string): Observable<Order[]> {
    return this.db.collection<Order>(`${Account.getDataPath(accountDocId)}/${Order.getDataPath()}`, ref => {
      let finalRef = ref as firebase.default.firestore.Query<firebase.default.firestore.DocumentData>;
      finalRef = finalRef.where('legs', '!=', []);
      return finalRef;
    }).valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Return all Order documents for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   */
  findAllClientOrders(clientDocId: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  /**
   * Return all Order documents for the specified Account
   *
   * @param accountDocId The docId of the Account containing the Orders
   */
  findAllOrdersByAccountDocId(accountDocId: string): Observable<Order[]> {
    return this.db.collection<Order>(`${Account.getDataPath(accountDocId)}/${Order.getDataPath()}`).valueChanges().pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  // One Search Criteria

  /**
   * Return all Order documents with a particular orderDocId for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param orderDocId orderDocId of Orders to be returned
   */
  findClientOrdersByOrderDocId(clientDocId: string, orderDocId: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('docId', '==', orderDocId)).valueChanges().pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  /**
   * Return all Order documents with a particular symbol for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param symbol symbol of Orders to be returned
   */
  findClientOrdersBySymbol(clientDocId: string, symbol: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('symbol', '==', symbol)
      .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  /**
   * Return all Order documents with a particular status value for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param status Status of Orders to be returned
   */
  findClientOrdersByStatus(clientDocId: string, status: string[]): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('status', 'in', status)
      .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  /**
   * Return all Order documents with a particular type value for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param type Type of Orders to be returned
   */
  findClientOrdersByType(clientDocId: string, type: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('type', '==', type)
      .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  /**
   * Return all Order documents with a particular date range for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param startDate The date before or when the Orders were last updated
   * @param endDate The date after or when the Orders were last updated
   */
  findClientOrdersByDate(clientDocId: string, startDate: string, endDate: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
      .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  // Two Search Criteria

  /**
   * Return all Order documents with a particular symbol and status for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param symbol symbol of Orders to be returned
   * @param status Status of Orders to be returned
   */
  findClientOrdersBySymbolAndStatus(clientDocId: string, symbol: string, status: string[]): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('symbol', '==', symbol).where('status', 'in', status)
      .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  /**
   * Return all Order documents with a particular symbol and type for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param symbol symbol of Orders to be returned
   * @param type Status of Orders to be returned
   */
  findClientOrdersBySymbolAndType(clientDocId: string, symbol: string, type: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('symbol', '==', symbol).where('type', '==', type)
      .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  /**
   * Return all Order documents with a particular symbol and date range for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param symbol symbol of Orders to be returned
   * @param startDate The date before or when the Orders were last updated
   * @param endDate The date after or when the Orders were last updated
   */
  findClientOrdersBySymbolAndDate(clientDocId: string, symbol: string, startDate: string, endDate: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref =>
      ref.where('clientDocId', '==', clientDocId).where('symbol', '==', symbol)
        .where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
        .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
          shareReplay({ bufferSize: 1, refCount: true })
        );
  }

  /**
   * Return all Order documents with a particular status and type for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param status Status of Orders to be returned
   * @param type Type of Orders to be returned
   */
  findClientOrdersByStatusAndType(clientDocId: string, status: string[], type: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('status', 'in', status).where('type', '==', type)
      .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  /**
   * Return all Order documents with a particular status and date range for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param status Status of Orders to be returned
   * @param startDate The date before or when the Orders were last updated
   * @param endDate The date after or when the Orders were last updated
   */
  findClientOrdersByStatusAndDate(clientDocId: string, status: string[], startDate: string, endDate: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref =>
      ref.where('clientDocId', '==', clientDocId).where('status', 'in', status)
        .where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
        .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
          shareReplay({ bufferSize: 1, refCount: true })
        );
  }

  /**
   * Return all Order documents with a particular type and date range for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param type Type of Orders to be returned
   * @param startDate The date before or when the Orders were last updated
   * @param endDate The date after or when the Orders were last updated
   */
  findClientOrdersByTypeAndDate(clientDocId: string, type: string, startDate: string, endDate: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('type', '==', type).where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
      .orderBy('lastUpdatedTimestamp', 'desc'))
      .valueChanges().pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  // Three Search Criteria

  /**
   * Return all Order documents with a particular symbol, status, and type for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param symbol symbol of Orders to be returned
   * @param status Status of Orders to be returned
   * @param type Type of Orders to be returned
   */
  findClientOrdersBySymbolAndStatusAndType(clientDocId: string, symbol: string, status: string[], type: string): Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('symbol', '==', symbol).where('status', 'in', status).where('type', '==', type)
      .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  /**
   * Return all Order documents with a particular symbol, status, and date range for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param symbol symbol of Orders to be returned
   * @param status Status of Orders to be returned
   * @param startDate The date before or when the Orders were last updated
   * @param endDate The date after or when the Orders were last updated
   */
  findClientOrdersBySymbolAndStatusAndDate(clientDocId: string, symbol: string, status: string[], startDate: string, endDate: string):
    Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref =>
      ref.where('clientDocId', '==', clientDocId).where('symbol', '==', symbol).where('status', 'in', status)
        .where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
        .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
          shareReplay({ bufferSize: 1, refCount: true })
        );
  }

  /**
   * Return all Order documents with a particular symbol, type, and date range for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param symbol symbol of Orders to be returned
   * @param type Type of Orders to be returned
   * @param startDate The date before or when the Orders were last updated
   * @param endDate The date after or when the Orders were last updated
   */
  findClientOrdersBySymbolAndTypeAndDate(clientDocId: string, symbol: string, type: string, startDate: string, endDate: string):
    Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref =>
      ref.where('clientDocId', '==', clientDocId).where('symbol', '==', symbol).where('type', '==', type)
        .where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
        .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
          shareReplay({ bufferSize: 1, refCount: true })
        );
  }

  /**
   * Return all Order documents with a particular status, type, and date range for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param status Status of Orders to be returned
   * @param type Type of Orders to be returned
   * @param startDate The date before or when the Orders were last updated
   * @param endDate The date after or when the Orders were last updated
   */
  findClientOrdersByStatusAndTypeAndDate(clientDocId: string, status: string[], type: string, startDate: string, endDate: string):
    Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref =>
      ref.where('clientDocId', '==', clientDocId).where('status', 'in', status).where('type', '==', type)
        .where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
        .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
          shareReplay({ bufferSize: 1, refCount: true })
        );
  }

  // Four Search Criteria

  /**
   * Return all Order documents with a particular symbol, status, type, and date range for a particular client
   *
   * @param clientDocId The docId of the Client containing the Orders
   * @param symbol symbol of Orders to be returned
   * @param status Status of Orders to be returned
   * @param type Type of Orders to be returned
   * @param startDate The date before or when the Orders were last updated
   * @param endDate The date after or when the Orders were last updated
   */
  findClientOrdersBySymbolAndStatusAndTypeAndDate(
    clientDocId: string, symbol: string, status: string[], type: string, startDate: string, endDate: string):
    Observable<Order[]> {
    return this.db.collectionGroup<Order>(Order.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('symbol', '==', symbol).where('status', 'in', status).where('type', '==', type)
      .where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
      .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  // GraphQL queries

  /**
   * Returns selected fields of Order objects by accounts and other search criteria specified
   * @param orderQuery an interface that has been populated with all search query criteria
   */
  getOrdersByAccounts(accounts: string[], orderQuery: OrderSearchCriteria) {
    return this.apollo.query({
      query: OrderQueries.getOrdersByAccountsQuery,
      variables: {
        accounts,
        orderQuery
      },
      context: {
        withCredentials: true,
        headers: new HttpHeaders({ Authorization: `Bearer ${this.authService.accessToken}` })
      }
    }).pipe(
      map(results => {
        return results.data.ordersByAccounts;
      })
    );
  }

  /**
   * Returns selected fields of Order objects by brokerCodes and other search criteria specified
   * @param orderQuery an interface that has been populated with all search query criteria
   */
  getOrdersByBrokerCodes(brokerCodes: string[], orderQuery: OrderSearchCriteria) {
    return this.apollo.query({
      query: OrderQueries.getOrdersByBrokerCodesQuery,
      variables: {
        brokerCodes,
        orderQuery
      },
      context: {
        withCredentials: true,
        headers: new HttpHeaders({ Authorization: `Bearer ${this.authService.accessToken}` })
      }
    }).pipe(
      map(results => {
        return results.data.ordersByBrokerCodes;
      })
    );
  }

}
