import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';
import { MatTabGroup } from '@angular/material/tabs';


@Component({
  selector: 'atom-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.css']
})
export class OrderSearchComponent implements OnInit, AfterViewChecked {

  @ViewChild('tabs') tabs: MatTabGroup;

  errorMessage: string;
  selectedTab = '0';
  filteredBy: string;
  openStatuses = ['MODIFIED','PARTIALLY_FILLED', 'NEW']; // statuses for open tab
  filledStatuses = ['FILLED', 'PARTIALLY_FILLED']; // statuses for filled tab

  private queryParams: Params;

  constructor(
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.queryParams = Object.assign({}, params);
      if (this.queryParams.selectedTab) {
        this.selectedTab = this.queryParams.selectedTab;
      }
    });
  }


  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  tabChanged = (newTabIndex: number): void => {
    this.selectedTab = newTabIndex.toString();
  }

  receiveFilteredBy(filterValue) {
    this.filteredBy = filterValue;
  }
}
